import { HelperService } from '../helper.service';
import { AgentAdvancedAgent } from './agent-advanced-agent';
import { AgentAdvancedLicensePlan } from './agent-advanced-license-plan';
import { AgentAdvancedState } from './agent-advanced-state';

export class AgentAdvanced {

	public licensePlans: AgentAdvancedLicensePlan[];
	public parent: AgentAdvancedAgent;
	public brother: AgentAdvancedAgent;
	public state: AgentAdvancedState;
	public agentId: string;
	public brotherId: string;
	public parentId: string;
	public stateId: string;
	public name: string;
	public contactName: string;
	public email: string;
	public phone: string;
	public city: string;
	public commissionRate: number;
	public commissionRate2: number;
	public commissionRate3: number;
	public hasFailedPayment: number | boolean;
	public paymentIntentRequiresActionDate: string;
	public isActive: number | boolean;
	public showUtilityCheck: number | boolean;
	public addDate: string;
	public isNewBroker: boolean;

	public licensePlan: string;
	public displayName: string;

	constructor(agentAdvanced: AgentAdvanced) {
		Object.assign(this, agentAdvanced);

		this.parent = this.parent ? new AgentAdvancedAgent(this.parent) : this.parent;
		this.brother = this.brother ? new AgentAdvancedAgent(this.brother) : this.brother;
		this.licensePlan = this.licensePlans ? this.licensePlans.find(l => ['Business Lite', 'Business Full'].includes(l.name))?.name : '';

		this.displayName = HelperService.showAsteriskForBrokerFirstImpression(this.isNewBroker)
			? this.name + '*'
			: this.name;
	}

	public get agentLabel() {
		return `${this.name} [${this.email}]`;
	}
}

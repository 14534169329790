export class BusinessEntityTypeTaxClassification {

	public id: number;
	public name: string;
	public businessEntityTypeId: number;

	constructor(businessEntityTypeTaxClassification: BusinessEntityTypeTaxClassification) {
		Object.assign(this, businessEntityTypeTaxClassification);
	}
}

import { Product } from './product';

export class ServiceTypeProductObj {

	public name: string;
	public products: Product[];

	constructor(serviceTypeObj: any) {
		Object.assign(this, serviceTypeObj);
	}
}

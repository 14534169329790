import { EmailPreference } from './email-preference';

export class LeadEmailPreference {

	public leadId: number;
	public emailPreferenceId: number;

	public emailPreference: EmailPreference;

	constructor(leadEmailPreference?: LeadEmailPreference) {
		Object.assign(this, leadEmailPreference);
	}
}

import { BusinessEntityTypeTaxClassification } from './business-entity-type-tax-classification';

export class BusinessEntityType {
	public id: number;
	public name: string;

	public businessEntityTypeTaxClassifications: BusinessEntityTypeTaxClassification[];

	constructor(businessEntityType: BusinessEntityType) {
		Object.assign(this, businessEntityType);
	}
}

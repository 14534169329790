export class SupplierCommissionMapCommissionType {

	public id: number;
	public supplierCommissionMapId: number;
	public commissionTypeId: number;

	constructor(supplierCommissionMapCommissionType?: SupplierCommissionMapCommissionType) {
		Object.assign(this, supplierCommissionMapCommissionType);
	}
}

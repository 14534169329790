import { AgentEmailPreferenceSetting } from './agent-email-preference-setting';
import { EmailSection } from './email-section';
import { EmailSectionGroup } from './email-section-group';

export class ViewEmailPreference {

	public id: number;
	public name: string;
	public description: string;
	public slug: string;
	public sortOrder: number;
	public recipientType: string;
	public emailPreferenceSettingId: number;
	public emailCategoryId: number;
	public emailCategoryName: string;
	public emailCategoryDescription: string;
	public emailPreferenceTypeId: number;
	public emailPreferenceSettingTargetDateOptionId: number;
	public emailPreferenceType: string;
	public targetDateTypeOption: string;
	public sendDelayDirection: number;
	public sendDelay: number;
	public sendFrequency: number;
	public holidayTypeId: number;
	public holidayType: string;
	public isUSOnly: boolean;
	public isAdminOnly: boolean;
	public hasSections: boolean;

	public agentEmailPreferenceSetting: AgentEmailPreferenceSetting;
	public emailSections: EmailSection[];

	// internal grouping
	public emailSectionGroups?: EmailSectionGroup[];

	constructor(viewEmailPreference?: ViewEmailPreference) {
		Object.assign(this, viewEmailPreference);
	}
}

import * as moment from 'moment-timezone';

import { CommissionType } from './';

export class CommissionEstimate {

	public id: number;
	public date: Date | string;
	public amount: number;
	public contractLocationId: string;
	public commissionTypeId: number;
	public meterStartDate: Date | string;
	public meterEndDate: Date | string;
	public exchangeRate: number;

	public commissionType: CommissionType;

	constructor(commissionEstimate: CommissionEstimate) {
		Object.assign(this, commissionEstimate);

		this.date = this.date
			? new Date(moment(this.date).format('MMM DD, YYYY 00:00:00 a')) : this.date;
		this.meterStartDate = this.meterStartDate
			? new Date(moment(this.meterStartDate).format('MMM DD, YYYY 00:00:00 a')) : this.meterStartDate;
		this.meterEndDate = this.meterEndDate
			? new Date(moment(this.meterEndDate).format('MMM DD, YYYY 00:00:00 a')) : this.meterEndDate;
	}
}

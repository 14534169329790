import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	constructor() { }

	handleError(error: any): void {
		const chunkFailedMessage = /Loading chunk.*failed/;
		if (chunkFailedMessage.test(error.message)) {
			window.location.reload();
		}

		console.error(error);
	}
}

<pk-broker-modal>
	<ng-template #headerTemplate>
		<h5 class="modal-title">Reactivation</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<p>
			To initiate the reactivation of your account, kindly proceed to the Credit Card tab. Please re-enter your credit card information and click on
			the 'Reactivate My Account' button.
		</p>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" class="btn btn-secondary" (click)="close()">Close</button>
	</ng-template>
</pk-broker-modal>

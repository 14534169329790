export class AuctionMonitorEmailReportTotals {

	public numSentNeedsRefreshedPricing: number;
	public needsRefreshedPricingClickedRatio: number;

	public numSentNoBids: number;
	public noBidsClickedRatio: number;

	public numSentNotVisited: number;
	public notVisitedClickedRatio: number;

	public numSentNotDownloadedRFP: number;
	public notDownloadedRFPClickedRatio: number;

	public numSentWaitingOnLOEs: number;
	public waitingOnLOEsClickedRatio: number;

	public numSentWaitingOnLOAs: number;
	public waitingOnLOAsClickedRatio: number;

	public numSentWaitingOnSampleBills: number;
	public waitingOnSampleBillsClickedRatio: number;

	public numSentEmails: number;
	public interactedWithEmailRatio: number;
	public loginButtonClickedRatio: number;

	constructor(auctionMonitorEmailReportTotals?: AuctionMonitorEmailReportTotals) {
		Object.assign(this, auctionMonitorEmailReportTotals);
	}
}

export class PurchasingLayerAudit {

	public contractId!: string;
	public layersJson!: string;
	public wholesaleRate!: number;
	public addDate!: Date;

	constructor(purchasingLayerAudit?: PurchasingLayerAudit) {
		Object.assign(this, purchasingLayerAudit);
	}
}

import { NumberUtils } from '@pk/powerkioskutils';

import * as moment from 'moment-timezone';

import { HelperService } from '../helper.service';
import { Note } from './note';

export class AuctionReport {

	public startDate: Date;
	public lastBidTime: Date;
	public serviceTypeId: string;
	public commodity: string;
	public supplierId: string;
	public rfqSessionId: string;
	public supplierLastBidTime: Date;
	public annualUsage: string;
	public dbaName: string;
	public endDate: Date;
	public contractId: string;
	public status: number;
	public customerId: string;
	public supplierName: string;
	public contractNum: string;
	public sampleBillAttachmentIdList: string;
	public loaAttachmentIdList: string;
	public loeAttachmentIdList: string;
	public isOnHold: boolean;
	public isResidential: boolean;
	public isFraudulent: boolean;
	public depositRequiredAmount: number;
	public externalId: string;
	public stateId: string;
	public units: string;
	public releasedDate: Date;
	public lastSampleBillAddDate: Date;
	public lastLOAAddDate: Date;
	public lastLOEAddDate: Date;
	public isNewBrokerAuction: boolean;
	public agentIsNewBroker: boolean;

	public timeSinceReleasedTime: number;
	public timeToEndTime: number;

	public agentId: string;
	public agentName: string;
	public agentEmail: string;
	public parentName: string;
	public parentEmail: string;
	public parentBrotherName: string;

	public notes: Note[];

	constructor(auctionReport: AuctionReport) {
		Object.assign(this, auctionReport);

		this.startDate = this.startDate ? new Date(moment(this.startDate).format('MMM DD, YYYY hh:mm:ss a')) : this.startDate;
		this.lastBidTime = this.lastBidTime ? new Date(moment(this.lastBidTime).format('MMM DD, YYYY hh:mm:ss a')) : this.lastBidTime;
		this.supplierLastBidTime = this.supplierLastBidTime
			? new Date(moment(this.supplierLastBidTime).format('MMM DD, YYYY hh:mm:ss a')) : this.supplierLastBidTime;
		this.endDate = this.endDate ? new Date(moment(this.endDate).format('MMM DD, YYYY hh:mm:ss a')) : this.endDate;
		this.releasedDate = this.releasedDate
			? new Date(moment(this.releasedDate).format('MMM DD, YYYY hh:mm:ss a')) : this.releasedDate;
		this.lastSampleBillAddDate = this.lastSampleBillAddDate
			? new Date(moment(this.lastSampleBillAddDate).format('MMM DD, YYYY hh:mm:ss a')) : this.lastSampleBillAddDate;
		this.lastLOAAddDate = this.lastLOAAddDate
			? new Date(moment(this.lastLOAAddDate).format('MMM DD, YYYY hh:mm:ss a')) : this.lastLOAAddDate;
		this.lastLOEAddDate = this.lastLOEAddDate
			? new Date(moment(this.lastLOEAddDate).format('MMM DD, YYYY hh:mm:ss a')) : this.lastLOEAddDate;
		this.notes = this.notes ? this.notes.map(n => new Note(n)) : this.notes;

		const today = moment();
		this.timeSinceReleasedTime = this.releasedDate
			? today.diff(moment(this.releasedDate), 'seconds')
			: 0;
		this.timeToEndTime = this.releasedDate
			? moment(this.endDate).diff(moment(this.releasedDate), 'seconds')
			: 0;

		this.contractNum = HelperService.showAsteriskForContractFirstImpression(Number(this.status), this.isNewBrokerAuction)
			? this.contractNum + '*'
			: this.contractNum;

		this.agentName = HelperService.showAsteriskForBrokerFirstImpression(this.agentIsNewBroker)
			? this.agentName + '*'
			: this.agentName;
	}

	get displayAnnualUsage(): number {
		return Math.round(NumberUtils.annualUsageToUnits(this.annualUsage, this.units));
	}

}

export class NoteType {

	public noteTypeId: string;
	public name: string;
	public description: string;
	public emailToSupervisor: boolean;
	public contractRemoveUnderwriter: boolean;
	public contractSetUnderwriterToSupervisor: boolean;
	public isForPendingCommission: boolean;
	public isForSupplierReports: boolean;
	public noteTypeCategoryId: number;
	public imageIconPrefix: string;
	public imageIcon: string;
	public details: string;
	public sortOrder: number;

	constructor(noteType?: Subset<NoteType>) {
		Object.assign(this, noteType);
	}
}

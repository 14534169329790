import * as moment from 'moment-timezone';

import { User } from './user';

export class SupplierCommissionAttachmentStatus {

	public id: number;
	public name: string;
	public addDate: Date;
	public modifiedDate: Date;
	public userId: string;
	public modifiedUserId: string;
	public sortOrder: number;

	public user: User;
	public modifiedUser: User;


	constructor(supplierCommissionAttachment?: SupplierCommissionAttachmentStatus) {
		Object.assign(this, supplierCommissionAttachment);
		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
		this.modifiedDate = this.modifiedDate ? new Date(moment(this.modifiedDate).format('MMM DD, YYYY hh:mm:ss a')) : this.modifiedDate;
		this.user = this.user ? new User(this.user) : this.user;
		this.modifiedUser = this.modifiedUser ? new User(this.modifiedUser) : this.modifiedUser;
	}
}

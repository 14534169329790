import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'tel'
})
export class TelPipe implements PipeTransform {

	transform(tel: string, emptyDefault: string = ''): string {
		if (!tel) { return emptyDefault; }

		const value = tel.toString().trim().replace(/^\+/, '');

		if (value.match(/\D/)) {
			return tel;
		}

		let country: string | number;
		let city: string;
		let number: string;

		switch (value.length) {
			case 10: // +1PPP####### -> C (PPP) ###-####
				country = 1;
				city = value.slice(0, 3);
				number = value.slice(3);
				break;

			case 11: // +CPPP####### -> CCC (PP) ###-####
				country = value[0];
				city = value.slice(1, 4);
				number = value.slice(4);
				break;

			case 12: // +CCCPP####### -> CCC (PP) ###-####
				country = value.slice(0, 3);
				city = value.slice(3, 5);
				number = value.slice(5);
				break;

			default:
				return tel;
		}

		if (country === 1) {
			country = '';
		}

		number = number.slice(0, 3) + '-' + number.slice(3);

		return `(${country ? country + ' ' : ''}${city}) ${number}`.trim();
	}

}

import * as moment from 'moment-timezone';

import { Contract } from './contract';
import { ContractLocation } from './contract-location';
import { CustomerAvailability } from './customer-availability';
import { EmailPreference } from './email-preference';
import { ResidentialMarketRequirement } from './residential-market-requirement';
import { State } from './state';
import { User } from './user';

export class Customer {

	public customerId: string;
	public dbaName: string;
	public dbaName2: string;
	public email: string;
	public language: string;
	public contactFname: string;
	public contactMiddle: string;
	public contactLname: string;
	public contactTitle: string;
	public zipCode: string;
	public city: string;
	public address1: string;
	public address2: string;
	public stateId: string;
	public state: State;
	public billingStateId: string;
	public billingState: State;
	public billingAddress1: string;
	public billingAddress2: string;
	public billingCity: string;
	public billingZipCode: string;
	public previousStateId: string;
	public previousState: State;
	public previousAddress1: string;
	public previousAddress2: string;
	public previousCity: string;
	public previousZipCode: string;
	public phone: string;
	public phoneExt: string;
	public phone2: string;
	public phoneExt2: string;
	public mobile: string;
	public fax: string;
	public dob: Date | string;
	public taxId: string;
	public maritalStatus: number;
	public moveType: string;
	public movePlan: string;
	public moverType: string;
	public mailForwardDate: Date;
	public wasResidenceBuiltInLast6Months: boolean;
	public authorizedUser: string;
	public cardNumber: string;
	public cardExpires: Date;
	public cardCvv: string;
	public userId: string;
	public moveInType: string;
	public dwellingType: string;
	public ssn: string;
	public preferredContactMethod: string;
	public isEBill: boolean;
	public eclAccountUuid: string;
	public leadId: number;
	public phoneCountryId: number;
	public phone2CountryId: number;
	public mobileCountryId: number;
	public faxCountryId: number;
	public customerAvailabilityId: number;

	public logins: User[];
	public contracts: Contract[];
	public user: User;
	public emailPreferences: EmailPreference[];
	public customerAvailability: CustomerAvailability;
	public residentialMarketRequirement: ResidentialMarketRequirement;

	public communitySolarCustomerSetup: any;
	public locations: ContractLocation[];

	constructor(customer?: Customer) {
		Object.assign(this, customer);

		this.state = this.state ? new State(this.state) : this.state;
		this.billingState = this.billingState ? new State(this.billingState) : this.billingState;
		this.previousState = this.previousState ? new State(this.previousState) : this.previousState;
		this.dob = this.dob ? new Date(moment(this.dob).format('MMM DD, YYYY hh:mm:ss a')) : this.dob;
		this.logins = this.logins ? this.logins.map(l => new User(l)) : this.logins;
		this.contracts = this.contracts ? this.contracts.map(c => new Contract(c)) : this.contracts;
		this.customerAvailability = this.customerAvailability ?
			new CustomerAvailability(this.customerAvailability) :
			this.customerAvailability;
	}

	public hasLogin(): boolean {
		return !!(this.logins && this.logins.length);
	}

	public getContactFullName(): string {
		const contactMiddle = this.contactMiddle && this.contactMiddle.trim() ? this.contactMiddle.trim() + ' ' : '';
		return `${this.contactFname} ${contactMiddle}${this.contactLname}`;
	}

	public getContactNameLastFirst(): string {
		return `${this.contactLname}, ${this.contactFname} ${this.contactMiddle.trim()}`;
	}

	public getFullAddressLine(withBreak = false): string {
		let addressLine = '';

		if (!this.address1) { return addressLine; }

		addressLine += this.address1 + ' ';
		if (withBreak && addressLine) {
			addressLine += '<br>';
		}
		addressLine += this.address2 ? this.address2 + ' ' : '';
		if (withBreak && this.address2) {
			addressLine += '<br>';
		}
		addressLine += this.city + ', ';
		addressLine += this.state ? this.state.stateShort + ' ' : ' ';
		addressLine += this.zipCode ? this.zipCode : '';
		if (withBreak && this.zipCode) {
			addressLine += '<br>';
		}

		return addressLine;
	}

	public getFullBillingAddressLine(): string {
		if (!this.billingState) {
			return this.getFullAddressLine();
		}

		let addressLine = this.billingAddress1 + ' ';
		addressLine += this.billingAddress2 ? this.billingAddress2 + ' ' : '';
		addressLine += this.billingCity + ', ';
		addressLine += this.billingState ? this.billingState.stateShort + ' ' : ' ';
		addressLine += this.billingZipCode;

		return addressLine;
	}

	public getFullPreviousAddressLine(): string {
		let addressLine = '';

		if (this.previousState) {
			addressLine += this.previousAddress1 + ' ';
			addressLine += this.previousAddress2 ? this.previousAddress2 + ' ' : '';
			addressLine += this.previousCity + ', ';
			addressLine += this.previousState ? this.previousState.stateShort + ' ' : ' ';
			addressLine += this.previousZipCode;
		}

		return addressLine;
	}
}

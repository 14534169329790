export class CommissionType {

	public id: number;
	public name: string;
	public sortOrder: number;

	constructor(commissionType?: CommissionType) {
		Object.assign(this, commissionType);
	}
}

import * as moment from 'moment-timezone';

export class AgentListReport {

	public agentId: string;
	public name: string;
	public parentId: string;
	public parentName: string;
	public brotherId: string;
	public brotherName: string;
	public stateId: string;
	public onboardDate: Date;
	public numAuctions: number;
	public numAuctionsElec: number;
	public numAuctionsGas: number;
	public numAuctionsKwh: number;
	public numAuctionsTherms: number;
	public closingRatio: number;
	public termValue: number;
	public termValueMatrix: number;
	public termValueAuction: number;
	public contractsAwardedNotSigned: number;
	public contractsDroppedRatio: number;
	public timeSinceAddedYears: number;
	public timeSinceAddedMonths: number;
	public isActive: boolean;
	public addDate: Date;

	constructor(agentListReport?: AgentListReport) {
		Object.assign(this, agentListReport);

		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
		this.onboardDate = this.onboardDate ? new Date(moment(this.onboardDate).format('MMM DD, YYYY hh:mm:ss a')) : this.onboardDate;
	}
}

import { User } from 'src/app/shared/models';

export class Pet {

	public id: string;
	public breed: string;
	public biteHistory: boolean;
	public addDate: Date;
	public userId: string;

	public user: User;

	constructor(pet?: Pet) {
		Object.assign(this, pet);

		this.user = this.user ? new User(this.user) : this.user;
	}
}

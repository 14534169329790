import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import videojs from 'video.js';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { Education, EducationSearchResult, FaqPageVideo, FaqSearchResult } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-faq-search-modal',
	templateUrl: './faq-search-modal.component.html',
	styleUrls: ['./faq-search-modal.component.scss'],
})
export class FaqSearchModalComponent extends AbstractPageForm {

	// inputs
	public pageVideoMapKey: number;

	public modalView: 'search' | 'related-articles' | 'feedback' = 'search';

	public faqData: Subject<string> = new Subject();
	public faqResult: {
		siteSectionSearchResultsGrouped: { question: string; answers: FaqSearchResult[] }[];
		faqAuditId: number;
		faqSearchResults: FaqSearchResult[];
		educationAuditId: number;
		educationSearchResults: EducationSearchResult[];
	};
	public loadingResults = false;

	public selectedFeedback: 'positive' | 'negative' = null;
	public selectedFaqArticle: FaqSearchResult = null;
	public selectedEducationVideo: Education = null;

	public videoOptions: videojs.PlayerOptions = {
		controls: true,
		preload: 'auto',
		width: 1920,
		height: 1080,
		poster: '',
		fluid: true,
		sources: [{
			src: '',
			type: 'video/mp4',
		}],
	};
	public pageVideoMap = {
		[FaqPageVideo.InstantQuote]: {
			poster: 'https://assets.powerkiosk.com/includes/education/contract/instant-quote-advanced-terms.jpg',
			src: 'https://assets.powerkiosk.com/includes/education/contract/instant-quote-advanced-terms.mp4',
			title: 'Instant Quote Advanced Terms',
			id: 'instantQuoteVideoTour',
		},
		[FaqPageVideo.SupplierCompensationStructure]: {
			poster: 'https://assets.powerkiosk.com/includes/education/commission/supplier-compensation-structure.jpg',
			src: 'https://assets.powerkiosk.com/includes/education/commission/supplier-compensation-structure.mp4',
			title: 'Supplier Compensation Structure',
			id: 'supplierCompensationVideoTour',
		},
		[FaqPageVideo.MatrixPrice]: {
			poster: 'https://assets.powerkiosk.com/includes/education/contract/matrix-pricing.jpg',
			src: 'https://assets.powerkiosk.com/includes/education/contract/matrix-pricing.mp4',
			title: 'Matrix Pricing',
			id: 'matrixPricingTour',
		},
		[FaqPageVideo.ProductTypes]: {
			poster: 'https://assets.powerkiosk.com/includes/education/contract/product-types.jpg',
			src: 'https://assets.powerkiosk.com/includes/education/contract/product-types.mp4',
			title: 'Product Types',
			id: 'productTypesVideoTour',
		},
		[FaqPageVideo.LicenseManagement]: {
			poster: 'https://assets.powerkiosk.com/includes/education/account/license-management.jpg',
			src: 'https://assets.powerkiosk.com/includes/education/account/license-management.mp4',
			title: 'License Management',
			id: 'managingLicensesVideoTour',
		},
		[FaqPageVideo.BrandedPortal]: {
			poster: 'https://assets.powerkiosk.com/includes/education/account/branded-portal.jpg',
			src: 'https://assets.powerkiosk.com/includes/education/account/branded-portal.mp4',
			title: 'Branded Portal',
			id: 'brandedPortalVideoTour',
		},
		[FaqPageVideo.CommissionForecast]: {
			poster: 'https://assets.powerkiosk.com/includes/education/commission/commission-forecast.jpg',
			src: 'https://assets.powerkiosk.com/includes/education/commission/commission-forecast.mp4',
			title: 'Commission Forecast',
			id: 'commissionForecastVideoTour',
		},
		[FaqPageVideo.ActualPayables]: {
			poster: 'https://assets.powerkiosk.com/includes/education/commission/paid-commission-report.jpg',
			src: 'https://assets.powerkiosk.com/includes/education/commission/paid-commission-report.mp4',
			title: 'Actual Payables',
			id: 'paidCommissionVideoTour',
		},
	};

	constructor(
		public activeModal: BsModalRef,
		private fb: FormBuilder,
		private graphqlService: GraphqlService,
	) { super(); }

	public async loadPageData(): Promise<void> {
		if (this.pageVideoMapKey) {
			this.videoOptions.poster = this.pageVideoMap[this.pageVideoMapKey].poster;
			this.videoOptions.sources[0].src = this.pageVideoMap[this.pageVideoMapKey].src;
		}
	}

	public getForm() {
		return this.fb.group({
			criteria: '',
		});
	}

	public async onFormLoaded(): Promise<void> {
		this.form.criteria.valueChanges.subscribe(criteria => {
			if (criteria.length > 2) {
				this.modalView = 'search';
				this.loadingResults = true;
				this.faqResult = null;

				this.selectedFeedback = null;
				this.selectedFaqArticle = null;
				this.selectedEducationVideo = null;

				this.faqData.next(criteria);
			}
		});

		this.faqData.pipe(
			debounceTime(1200),
			switchMap(search => this.graphqlService.getSlideoutSearchSub(search, this.loggedInUser)),
		).subscribe({
			next: async response => {
				const faqResult = {
					siteSectionSearchResultsGrouped: _.chain(response.result.data.siteSectionSearchResults.message)
						.map(f => new FaqSearchResult(f))
						.groupBy(r => r.question)
						.toPairs()
						.map(g => ({
							question: g[0],
							answers: g[1],
						}))
						.take(3)
						.value(),
					faqAuditId: null,
					faqSearchResults: _.chain(response.result.data.faqSearchResults.message)
						.map(f => new FaqSearchResult(f))
						.take(8)
						.value(),
					educationAuditId: null,
					educationSearchResults: _.chain(response.result.data.educationSearchResults.message)
						.map(e => new EducationSearchResult(e))
						.take(3)
						.value(),
				};
				this.faqResult = faqResult;
				this.loadingResults = false;

				if (response.criteria && !this.loggedInUser.isAdmin) {
					const auditResults = await Promise.all([
						this.graphqlService.createFaqAudit({
							criteria: response.criteria.trim(),
							terms: _.chain(response.result.data.faqSearchResults.message)
								.map(f => f.terms.split(','))
								.flatMap()
								.uniq()
								.join(',')
								.value(),
							matches: response.result.data.faqSearchResults.message.length,
							matchedArticles: _.chain(response.result.data.faqSearchResults.message)
								.map(m => m.question)
								.uniq()
								.join(',')
								.value(),
						}),
						this.graphqlService.createEducationAudit({
							criteria: response.criteria.trim(),
							terms: _.chain(response.result.data.educationSearchResults.message)
								.map(f => f.terms.split(','))
								.flatMap()
								.uniq()
								.join(',')
								.value(),
							matches: response.result.data.educationSearchResults.message.length,
							matchedArticles: _.chain(response.result.data.educationSearchResults.message)
								.map(m => m.subTitle)
								.uniq()
								.join(',')
								.value(),
						}),
					]);
					faqResult.faqAuditId = auditResults[0].data.createFaqAudit.id;
					faqResult.educationAuditId = auditResults[1].data.createEducationAudit.id;
				}
			},
			error: () => this.loadingResults = false,
		});
	}

	get resultCount() {
		if (!this.faqResult) {
			return 0;
		} else {
			const { siteSectionSearchResultsGrouped, faqSearchResults, educationSearchResults } = this.faqResult;
			return siteSectionSearchResultsGrouped?.length + faqSearchResults?.length + educationSearchResults?.length ?? 0;
		}
	}

	get isAdminOrParent(): boolean {
		return (!this.loggedInUser.agentId || !this.loggedInUser.agent.parentId);
	}

	public back(): void {
		this.modalView = 'search';
	}

	public async chooseFaqArticle(faq: FaqSearchResult): Promise<void> {
		this.modalView = 'related-articles';
		this.selectedFaqArticle = faq;
		this.selectedEducationVideo = null;
		if (this.faqResult.faqAuditId) {
			this.graphqlService.createFaqAuditArticle(this.faqResult.faqAuditId, { faqId: faq.id });
		}
	}

	public showEducation(education: Education): void {
		if (education.educationTypeId === 1) {
			this.selectedEducationVideo = education;
			this.videoOptions.poster = this.environment.baseUrl + '/attachments/'
				+ education.thumbnailAttachmentId + '/view?access_token=' + this.environment.apiKey;
			this.videoOptions.sources[0].src = education.path;
		} else {
			window.open(education.path, '_blank');
		}

		if (this.faqResult.educationAuditId) {
			this.graphqlService.createEducationAuditArticle(this.faqResult.educationAuditId, { educationId: education.id });
		}
	}

	public getEducationTypeIcon(education: Education): string[] {
		if (education.educationTypeId === 1) {
			return ['fas', 'film'];
		}

		return ['far', 'file-alt'];
	}

	public answerFeedback(response: 'positive' | 'negative'): void {
		this.selectedFeedback = response;
		this.selectedEducationVideo = null;
		this.modalView = 'feedback';
	}
}

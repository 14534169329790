import { CONSTANTS } from '@pk/powerkioskutils';

import * as moment from 'moment-timezone';

import { Agent } from './agent';
import { AiGeneratedContent } from './ai-generated-content';
import { Attachment } from './attachment';
import { ContactMethod } from './contact-method';
import { Contract } from './contract';
import { TicketCategory } from './ticket-category';
import { TicketComment } from './ticket-comment';
import { User } from './user';

export class Ticket {

	public id: number;
	public assignedToUserId: string;
	public ticketCategoryId: number;
	public subject: string;
	public body: string;
	public completeDate: Date;
	public addDate: Date;
	public modifiedDate: Date;
	public userId: string;
	public email: string;
	public completeUserId: string;
	public inProgressDate: Date;
	public priority: number;
	public contractId: string;
	public emailId: string;
	public ticketStatusId: number;
	public suppressNotifications: boolean;

	public category: TicketCategory;
	public assignedToUser: User;
	public user: User;
	public agent: Agent;
	public completeUser: User;
	public attachments: Attachment[];
	public comments: TicketComment[];
	public contracts: Contract[];
	public contactMethods: ContactMethod[];
	public latestAiGeneratedContent: AiGeneratedContent;

	get isCompleted(): boolean {
		return this.ticketStatusId === CONSTANTS.ticketStatuses.complete;
	}

	constructor(ticket: Ticket) {
		Object.assign(this, ticket);

		this.assignedToUser = this.assignedToUser ? new User(this.assignedToUser) : this.assignedToUser;
		this.category = this.category ? new TicketCategory(this.category) : this.category;
		this.completeDate = this.completeDate ? new Date(moment(this.completeDate).format('MMM DD, YYYY hh:mm:ss a')) : this.completeDate;
		this.user = this.user ? new User(this.user) : this.user;
		this.inProgressDate = this.inProgressDate
			? new Date(moment(this.inProgressDate).format('MMM DD, YYYY hh:mm:ss a')) : this.inProgressDate;
		this.attachments = this.attachments ? this.attachments.map(a => new Attachment(a)) : this.attachments;
		this.comments = this.comments ? this.comments.map(c => new TicketComment(c)) : this.comments;
		this.contracts = this.contracts ? this.contracts.map(c => new Contract(c)) : this.contracts;
		this.agent = this.agent ? new Agent(this.agent) : this.agent;
	}
}

import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

import { HelperService } from '../helper.service';
import { ServiceType } from '../models/service-type';
import { Utility } from '../models/utility';

@Pipe({
	name: 'serviceTypeUtilities'
})
export class ServiceTypeUtilitiesPipe implements PipeTransform {
	constructor(private helperService: HelperService) { }

	transform(serviceTypes: ServiceType[], serviceTypeId?: string, stateId?: string): Utility[] {
		return serviceTypes ? this.getUtilitiesFromServiceType(serviceTypes,
			this.helperService.convertFromStringToPrimitive(serviceTypeId), this.helperService.convertFromStringToPrimitive(stateId)) : [];
	}

	private getUtilitiesFromServiceType(serviceTypes: ServiceType[], serviceTypeId?: string, stateId?: string): Utility[] {
		const matchedServiceTypes = serviceTypes.filter(s => !serviceTypeId || s.serviceTypeId === serviceTypeId);

		return _.flatMap(matchedServiceTypes, st => st.utilityServiceTypes.map(u => u.utility))
			.filter(u => !stateId || u.utilityStates.some(us => us.stateId === stateId));
	}
}

import gql from 'graphql-tag';

import * as fragments from './graphql.fragments';

export const setSystemClock = gql`
mutation SetSystemClock($date: String!) {
	updateSystemClock(date: $date)
}
`;

export const fileTransfer = gql`
mutation FileTransfer($serviceName: String!, $fileName: String!, $file: String!) {
	fileTransfer(serviceName: $serviceName, fileName: $fileName, file: $file) {
		response
	}
}
`;

export const sendAuctionMonitorReport = gql`
mutation SendAuctionMonitorReport($rfqSessionId: String!, $supplierId: String!) {
	sendAuctionMonitorReport(rfqSessionId: $rfqSessionId, supplierId: $supplierId) {
		response
	}
}
`;

export const rebuildContractCommissionPayableSchedule = gql`
mutation RebuildContractCommissionPayableSchedule($contractId: String!) {
	rebuildContractCommissionPayableSchedule(contractId: $contractId) {
		agentId
		contractLocationId
		isProcessed
		amount
		usage
		date
		rate
		margin
	}
}
`;

export const createCustomCommissionPayable = gql`
mutation CreateCustomCommissionPayable($contractId: String!, $commissionPayable: CommissionPayableInput!) {
	createCustomCommissionPayable(contractId: $contractId, commissionPayable: $commissionPayable) {
		agentId
		contractLocationId
		isProcessed
		amount
		usage
		date
	}
}
`;

export const rebuildEsignDocument = gql`
mutation RebuildEsignDocument($esignDocumentId: String!) {
	rebuildEsignDocument(esignDocumentId: $esignDocumentId) {
		esignId
		esignDocumentId
		documentId
		attachmentId
		esignDocumentId
		esign {
			signature
			isManualUpload
		}
	}
}
`;

export const deleteRedisKey = gql`
mutation DeleteRedisKey($name: String!) {
	deleteRedisKey(name: $name)
}
`;

export const updateOctipowerConfig = gql`
mutation UpdateOctipowerConfig($file: String!) {
	updateOctipowerConfig(file: $file)
}
`;

export const updateHoliday = gql`
mutation UpdateHoliday($id: Int!, $holiday: HolidayInput!) {
	updateHoliday(id: $id, holiday: $holiday) {
		id
	}
}
`;

export const createHoliday = gql`
mutation CreateHoliday($holiday: HolidayInput!) {
	createHoliday(holiday: $holiday) {
		id
	}
}
`;

export const enroll = gql`
mutation Enroll($contractId: String!) {
	enroll(contractId: $contractId) {
		contractId
		externalStatus
	}
}
`;

export const doSignedContractEmail = gql`
mutation DoSignedContractEmail($sendContractType: String!) {
	doSignedContractEmail(sendContractType: $sendContractType)
}
`;

export const sendSignedContract = gql`
mutation SendSignedContract($contractId: String!) {
	sendSignedContract(contractId: $contractId)
}
`;

export const createCustomerLogin = gql`
mutation CreateCustomerLogin($contractId: String!) {
	createCustomerLogin(contractId: $contractId)
}
`;

export const createCustomerAvailability = gql`
	mutation CreateCustomerAvailability($customerAvailability: CustomerAvailabilityInput!) {
		createCustomerAvailability(customerAvailability: $customerAvailability) {
			id
		}
	}
`;

export const updateCustomerAvailability = gql`
mutation UpdateCustomerAvailability($id: Int!, $customerAvailability: CustomerAvailabilityInput!) {
	updateCustomerAvailability(id: $id, customerAvailability: $customerAvailability) {
		id
		timezoneId
		mondayFrom
		mondayTo
		tuesdayFrom
		tuesdayTo
		wednesdayFrom
		wednesdayTo
		thursdayFrom
		thursdayTo
		fridayFrom
		fridayTo
	}
}
`;


export const updateContractsAdvanced = gql`
mutation UpdateContractsAdvanced($contractId: String!) {
	updateContractsAdvanced(contractId: $contractId)
}
`;

export const updateCustomer = gql`
mutation UpdateCustomer($customerId: String!, $customer: CustomerInput!) {
	updateCustomer(customerId: $customerId, customer: $customer) {
		customerId
	}
}
`;

export const sendErrorReport = gql`
mutation SendErrorReport($message: String!, $report: String!) {
	sendErrorReport(message: $message, report: $report)
}
`;

export const createFeedback = gql`
mutation CreateFeedback($feedback: FeedbackInput!) {
	createFeedback(feedback: $feedback) {
		id
		rating
		body
		addDate
		user {
			fname
			lname
		}
		supplier {
			name
		}
	}
}
`;

export const updateFeedback = gql`
mutation UpdateFeedback($id: Int!, $feedback: FeedbackInput!) {
	updateFeedback(id: $id, feedback: $feedback) {
		id
	}
}
`;

export const createFeedbackType = gql`
mutation CreateFeedbackType($feedbackType: FeedbackTypeInput!) {
	createFeedbackType(feedbackType: $feedbackType) {
		id
	}
}
`;

export const updateFeedbackType = gql`
mutation UpdateFeedbackType($id: Int!, $feedbackType: FeedbackTypeInput!) {
	updateFeedbackType(id: $id, feedbackType: $feedbackType) {
		id
	}
}
`;

export const createAgentDiscountProgram = gql`
mutation CreateAgentDiscountProgram($agentDiscountProgram: AgentDiscountProgramInput!) {
	createAgentDiscountProgram(agentDiscountProgram: $agentDiscountProgram) {
		id
	}
}
`;

export const updateAgentDiscountProgram = gql`
mutation UpdateAgentDiscountProgram($id: Int!, $agentDiscountProgram: AgentDiscountProgramInput!) {
	updateAgentDiscountProgram(id: $id, agentDiscountProgram: $agentDiscountProgram) {
		id
	}
}
`;

export const confirmCustomerEnrollments = gql`
mutation ConfirmCustomerEnrollments($contractIds: [String!]!) {
	confirmCustomerEnrollments(contractIds: $contractIds) {
		message {
			errorMessage
			contract {
				contractId
				contractNum
				customer {
					dbaName
				}
				locations {
					utilityAccountNum
				}
				margin
				annualUsage
				term
			}
		}
	}
}
`;

export const submitCustomerCallNoResponses = gql`
mutation SubmitCustomerCallNoResponses($contractIds: [String!]!) {
	submitCustomerCallNoResponses(contractIds: $contractIds) {
		message {
			contractId
		}
	}
}
`;

export const submitCustomerFrauds = gql`
mutation SubmitCustomerFrauds($contractIds: [String!]!) {
	submitCustomerFrauds(contractIds: $contractIds) {
		message {
			contractId
		}
	}
}
`;

export const deleteAccountAdjustment = gql`
	mutation deleteAccountAdjustment($id: Int!) {
		deleteAccountAdjustment(id: $id) {
			id
		}
	}
`;

export const createAccountAdjustment = gql`
	mutation CreateAccountAdjustment($accountAdjustment: AccountAdjustmentInput!) {
		createAccountAdjustment(accountAdjustment: $accountAdjustment) {
			id
			description
		}
	}
`;

export const updateAccountAdjustment = gql`
	mutation UpdateAccountAdjustment($id: Int!, $accountAdjustment: AccountAdjustmentInput!) {
		updateAccountAdjustment(id: $id, accountAdjustment: $accountAdjustment) {
			id
			description
		}
	}
`;

export const updateSecurityRule = gql`
	mutation UpdateSecurityRule($securityRuleId: String!, $securityRule: SecurityRuleInput!) {
		updateSecurityRule(securityRuleId: $securityRuleId, securityRule: $securityRule) {
			securityRuleId
			secureList
		}
	}
`;

export const updateUserApiKey = gql`
	mutation UpdateUserAPIKey($id: Int!, $userApiKey: UserAPIKeyInput!) {
		updateUserApiKey(id: $id, userApiKey: $userApiKey) {
			id
			userId
			key
			description
			referrer
			addDate
			limit
			allowsGraphQL
			allowsLogin
		}
	}
`;

export const createSecurityRule = gql`
	mutation CreateSecurityRule($securityRule: SecurityRuleInput!) {
		createSecurityRule(securityRule: $securityRule) {
			securityRuleId
			secureList
			isActive
		}
	}
`;

export const createUserApiKey = gql`
	mutation CreateUserAPIKey($userApiKey: UserAPIKeyInput!) {
		createUserApiKey(userApiKey: $userApiKey) {
			id
			userId
			key
			description
			referrer
			addDate
			limit
			allowsGraphQL
			allowsLogin
		}
}
`;

export const createDocument = gql`
	mutation CreateDocument($document: DocumentInput!) {
		createDocument(document: $document) {
			documentId
			name
			description
			isActive
		}
	}
`;

export const createUtilityRateClass = gql`
	mutation CreateUtilityRateClass($utilityRateClass: UtilityRateClassInput!) {
		createUtilityRateClass(utilityRateClass: $utilityRateClass) {
			utilityRateClassId
			name
			description
			isActive
		}
	}
`;

export const updateUtilityRateClass = gql`
	mutation UpdateUtilityRateClass($utilityRateClassId: String!, $utilityRateClass: UtilityRateClassInput!) {
		updateUtilityRateClass(utilityRateClassId: $utilityRateClassId, utilityRateClass: $utilityRateClass) {
			utilityRateClassId
			name
			description
			isActive
		}
	}
`;

export const createUtilityHub = gql`
	mutation CreateUtilityHub($utilityHub: UtilityHubInput!) {
		createUtilityHub(utilityHub: $utilityHub) {
			id
		}
	}
`;

export const updateUtilityHub = gql`
	mutation UpdateUtilityHub($id: Int!, $utilityHub: UtilityHubInput!) {
		updateUtilityHub(id: $id, utilityHub: $utilityHub) {
			id
		}
	}
`;

export const updateSupplierRateSetting = gql`
	mutation UpdateSupplierRateSetting($id: Int!, $supplierRateSetting: SupplierRateSettingInput!) {
		updateSupplierRateSetting(id: $id, supplierRateSetting: $supplierRateSetting) {
			id
		}
	}
`;

export const createSupplierRateSetting = gql`
	mutation CreateSupplierRateSetting($supplierRateSetting: SupplierRateSettingInput!) {
		createSupplierRateSetting(supplierRateSetting: $supplierRateSetting) {
			id
		}
	}
`;

export const deleteSupplierRateSetting = gql`
	mutation DeleteSupplierRateSetting($id: Int!) {
		deleteSupplierRateSetting(id: $id) {
			id
		}
	}
`;

export const createRole = gql`
	mutation CreateRole($role: RoleInput!) {
		createRole(role: $role) {
			roleId
			name
			description
			isActive
		}
	}
`;

export const createPostProcessingCommissionAuditStatus = gql`
	mutation CreatePostProcessingCommissionAuditStatus($postProcessingCommissionAuditStatus: PostProcessingCommissionAuditStatusInput!) {
		createPostProcessingCommissionAuditStatus(postProcessingCommissionAuditStatus: $postProcessingCommissionAuditStatus) {
			id
		}
	}
`;

export const updatePostProcessingCommissionAuditStatus = gql`
	mutation UpdatePostProcessingCommissionAuditStatus($id: Int!,
		$postProcessingCommissionAuditStatus: PostProcessingCommissionAuditStatusInput!) {
		updatePostProcessingCommissionAuditStatus(id: $id, postProcessingCommissionAuditStatus: $postProcessingCommissionAuditStatus) {
			id
		}
	}
`;

export const updateDocument = gql`
	mutation UpdateDocument($documentId: String!, $document: DocumentInput!) {
		updateDocument(documentId: $documentId, document: $document) {
			documentId
			name
			description
			isActive
		}
	}
`;

export const updateRole = gql`
	mutation UpdateRole($roleId: String!, $role: RoleInput!) {
		updateRole(roleId: $roleId, role: $role) {
			roleId
			name
			description
			isActive
		}
	}
`;

export const createNoteType = gql`
	mutation CreateNoteType($noteType: NoteTypeInput!) {
		createNoteType(noteType: $noteType) {
			noteTypeId
			name
			description
			noteTypeCategoryId
		}
	}
`;


export const updateNoteType = gql`
	mutation UpdateNoteType($noteTypeId: String!, $noteType: NoteTypeInput!) {
		updateNoteType(noteTypeId: $noteTypeId, noteType: $noteType) {
			noteTypeId
			name
			description
		}
	}
`;

export const createMenu = gql`
	mutation CreateMenu($menu: MenuInput!) {
		createMenu(menu: $menu) {
			menuId
			name
			description
			isActive
		}
	}
`;

export const updateMenu = gql`
	mutation UpdateMenu($menuId: String!, $menu: MenuInput!) {
		updateMenu(menuId: $menuId, menu: $menu) {
			menuId
			name
			description
			isActive
		}
	}
`;

export const createTicketCategory = gql`
	mutation CreateTicketCategory($ticketCategory: TicketCategoryInput!) {
		createTicketCategory(ticketCategory: $ticketCategory) {
			id
			name
			description
			assignedToUserId
			isActive
		}
	}
`;

export const updateTicketCategory = gql`
	mutation UpdateTicketCategory($id: Int!, $ticketCategory: TicketCategoryInput!) {
		updateTicketCategory(id: $id, ticketCategory: $ticketCategory) {
			id
			name
			description
			assignedToUserId
			isActive
		}
	}
`;

export const updateTicketComment = gql`
	mutation UpdateTicketComment($id: Int!, $ticketComment: TicketCommentInput!) {
		updateTicketComment(id: $id, ticketComment: $ticketComment) {
			id
		}
	}
`;

export const createEmailCriteria = gql`
	mutation CreateEmailCriteria($emailCriteria: EmailCriteriaInput!) {
		createEmailCriteria(emailCriteria: $emailCriteria) {
			emailCriteriaId
			name
			isActive
		}
	}
`;


export const updateEmailCriteria = gql`
	mutation UpdateEmailCriteria($emailCriteriaId: String!, $emailCriteria: EmailCriteriaInput!) {
		updateEmailCriteria(emailCriteriaId: $emailCriteriaId, emailCriteria: $emailCriteria) {
			emailCriteriaId
			name
			isActive
		}
	}
`;

export const createEmailAccount = gql`
	mutation CreateEmailAccount($emailAccount: EmailAccountInput!) {
		createEmailAccount(emailAccount: $emailAccount) {
			emailAccountId
		}
	}
`;


export const updateEmailAccount = gql`
	mutation UpdateEmailAccount($emailAccountId: String!, $emailAccount: EmailAccountInput!) {
		updateEmailAccount(emailAccountId: $emailAccountId, emailAccount: $emailAccount) {
			emailAccountId
		}
	}
`;

export const createUtility = gql`
	mutation createUtility($utility: UtilityInput!) {
		createUtility(utility: $utility) {
			utilityId
			name
			parentId
			isActive
		}
	}
`;
export const createSupplier = gql`
	mutation CreateSupplier($supplier: SupplierInput!) {
		createSupplier(supplier: $supplier) {
			supplierId
			name
			address1
			phone
			city
			stateId
			email
			isActive
		}
	}
`;

export const createUtilityRateClassMap = gql`
	mutation CreateUtilityRateClassMap($utilityRateClassMap: UtilityRateClassMapInput!) {
		createUtilityRateClassMap(utilityRateClassMap: $utilityRateClassMap) {
			id
		}
	}
`;

export const updateUtilityRateClassMap = gql`
	mutation UpdateUtilityRateClassMap($id: Int!, $utilityRateClassMap: UtilityRateClassMapInput!) {
		updateUtilityRateClassMap(id: $id, utilityRateClassMap: $utilityRateClassMap) {
			id
		}
	}
`;

export const deleteUtilityRateClassMap = gql`
	mutation DeleteUtilityRateClassMap($id: Int!) {
		deleteUtilityRateClassMap(id: $id) {
			id
		}
	}
`;

export const createUtilitySupplierMap = gql`
	mutation CreateUtilitySupplierMap($utilitySupplierMap: UtilitySupplierMapInput!) {
		createUtilitySupplierMap(utilitySupplierMap: $utilitySupplierMap) {
			id
		}
	}
`;

export const updateUtilitySupplierMap = gql`
	mutation UpdateUtilitySupplierMap($id: Int!, $utilitySupplierMap: UtilitySupplierMapInput!) {
		updateUtilitySupplierMap(id: $id, utilitySupplierMap: $utilitySupplierMap) {
			id
		}
	}
`;

export const deleteUtilitySupplierMap = gql`
	mutation DeleteUtilitySupplierMap($id: Int!) {
		deleteUtilitySupplierMap(id: $id) {
			id
		}
	}
`;

export const createUtilityMarketSetting = gql`
	mutation CreateUtilityMarketSetting($utilityMarketSetting: UtilityMarketSettingInput!) {
		createUtilityMarketSetting(utilityMarketSetting: $utilityMarketSetting) {
			id
		}
	}
`;

export const updateUtilityMarketSetting = gql`
	mutation UpdateUtilityMarketSetting($id: Int!, $utilityMarketSetting: UtilityMarketSettingInput!) {
		updateUtilityMarketSetting(id: $id, utilityMarketSetting: $utilityMarketSetting) {
			id
		}
	}
`;

export const deleteUtilityMarketSetting = gql`
	mutation DeleteUtilityMarketSetting($id: Int!) {
		deleteUtilityMarketSetting(id: $id) {
			id
		}
	}
`;

export const updateUtility = gql`
	mutation UpdateUtility($utilityId: String!, $utility: UtilityInput!) {
		updateUtility(utilityId: $utilityId, utility: $utility) {
			utilityId
			name
			parentId
			isActive
		}
	}
`;


export const updateISO = gql`
	mutation UpdateISO($id: Int!, $iso: ISOInput!) {
		updateISO(id: $id, iso: $iso) {
			id
			name
		}
	}
`;

export const createISO = gql`
	mutation UpdateISO($iso: ISOInput!) {
		createISO(iso: $iso) {
			id
			name
		}
	}
`;


export const updateSupplier = gql`
	mutation UpdateSupplier($supplierId: String!, $supplier: SupplierInput!) {
		updateSupplier(supplierId: $supplierId, supplier: $supplier) {
			supplierId
			name
			address1
			phone
			city
			stateId
			email
			isActive
		}
	}
`;

export const createProduct = gql`
	mutation CreateProduct($product: ProductInput!) {
		createProduct(product: $product) {
			productId
			id
			name
			term
			description
			isActive
			serviceTypeId
		}
	}
`;

export const updateProduct = gql`
	mutation UpdateProduct($productId: String!, $product: ProductInput!) {
		updateProduct(id: $productId, product: $product) {
			id
			name
			term
			description
			isActive
			serviceTypeId
		}
	}
`;

export const proposal = gql`
	mutation GenerateProposal($proposal: ProposalInput!) {
		proposal(proposal: $proposal)
	}
`;

export const createContract = gql`
	mutation CreateContract($contract: ContractInput!) {
		createContract(contract: $contract) {
			contractId
			annualUsage
			agent {
				emailPreferences {
					id
					slug
				}
				email
			}
			customer {
				emailPreferences {
					id
					slug
				}
				email
			}
		}
	}
`;

export const createAttachment = gql`
	mutation CreateAttachment($file: Upload!, $attachment: AttachmentInput!) {
		createAttachment(file: $file, attachment: $attachment) {
			attachmentId
			addDate
			name
			description
			attachmentType {
				name
			}
			user {
				fname
				lname
				middle
			}
		}
	}
`;

export const analyticsClickEmail = gql`
	mutation AnalyticsClickEmail($apiId: String!) {
		analyticsClickEmail(apiId: $apiId)
	}
`;

export const sendTestEmail = gql`
	mutation SendTestEmail($email: String!) {
		sendTestEmail(email: $email) {
			response
		}
	}
`;

export const createAlertType = gql`
	mutation CreateAlertType($alertType: AlertTypeInput!) {
		createAlertType(alertType: $alertType) {
			id
		}
	}
`;

export const updateAlertType = gql`
	mutation UpdateAlertType($id: Int!, $alertType: AlertTypeInput!) {
		updateAlertType(id: $id, alertType: $alertType) {
			id
		}
	}
`;

export const createContractLocationStatusReasonType = gql`
	mutation CreateContractLocationStatusReasonType($contractLocationStatusReasonType: ContractLocationStatusReasonTypeInput!) {
		createContractLocationStatusReasonType(contractLocationStatusReasonType: $contractLocationStatusReasonType) {
			id
		}
	}
`;

export const updateContractLocationStatusReasonType = gql`
	mutation UpdateContractLocationStatusReasonType($id: Int!, $contractLocationStatusReasonType: ContractLocationStatusReasonTypeInput!) {
		updateContractLocationStatusReasonType(id: $id, contractLocationStatusReasonType: $contractLocationStatusReasonType) {
			id
		}
	}
`;

export const createAttachmentType = gql`
	mutation CreateAttachmentType($attachmentType: AttachmentTypeInput!) {
		createAttachmentType(attachmentType: $attachmentType) {
			attachmentTypeId
		}
	}
`;

export const createPopUp = gql`
	mutation CreatePopUp($popUp: PopUpInput!) {
		createPopUp(popUp: $popUp) {
			id
		}
	}
`;

export const updatePopUp = gql`
	mutation UpdatePopUp($id: Int!, $popUp: PopUpInput!) {
		updatePopUp(id: $id, popUp: $popUp) {
			id
		}
	}
`;

export const deletePopUp = gql`
	mutation deletePopUp($id: Int!) {
		deletePopUp(id: $id) {
			id
		}
	}
`;

export const createFeatureTour = gql`
	mutation CreateFeatureTour($featureTour: FeatureTourInput!) {
		createFeatureTour(featureTour: $featureTour) {
			id
		}
	}
`;

export const updateFeatureTour = gql`
	mutation UpdateFeatureTour($id: Int!, $featureTour: FeatureTourInput!) {
		updateFeatureTour(id: $id, featureTour: $featureTour) {
			id
		}
	}
`;

export const deleteFeatureTour = gql`
	mutation deleteFeatureTour($id: Int!) {
		deleteFeatureTour(id: $id) {
			id
		}
	}
`;

export const createPurchasingLayerType = gql`
	mutation CreatePurchasingLayerType($purchasingLayerType: PurchasingLayerTypeInput!) {
		createPurchasingLayerType(purchasingLayerType: $purchasingLayerType) {
			id
		}
	}
`;

export const updatePurchasingLayer = gql`
	mutation UpdatePurchasingLayer($id: Int!, $purchasingLayer: PurchasingLayerInput!) {
		updatePurchasingLayer(id: $id, purchasingLayer: $purchasingLayer) {
			id
		}
	}
`;

export const deletePurchasingLayer = gql`
	mutation DeletePurchasingLayer($id: Int!) {
		deletePurchasingLayer(id: $id) {
			id
		}
	}
`;

export const updatePurchasingLayerType = gql`
	mutation UpdatePurchasingLayerType($id: Int!, $purchasingLayerType: PurchasingLayerTypeInput!) {
		updatePurchasingLayerType(id: $id, purchasingLayerType: $purchasingLayerType) {
			id
		}
	}
`;

export const createCommissionPayableIssueType = gql`
	mutation CreateCommissionPayableIssueType($commissionPayableIssueType: CommissionPayableIssueTypeInput!) {
		createCommissionPayableIssueType(commissionPayableIssueType: $commissionPayableIssueType) {
			id
		}
	}
`;
export const updateCommissionPayableIssueType = gql`
	mutation UpdateCommissionPayableIssueType($id: Int!, $commissionPayableIssueType: CommissionPayableIssueTypeInput!) {
		updateCommissionPayableIssueType(id: $id, commissionPayableIssueType: $commissionPayableIssueType) {
			id
			name
			description
		}
	}
`;

export const createPayableIssueType = gql`
	mutation CreatePayableIssueType($payableIssueType: PayableIssueTypeInput!) {
		createPayableIssueType(payableIssueType: $payableIssueType) {
			id
			name
			description
		}
	}
`;

export const createCommissionPayPlanType = gql`
	mutation CreateCommissionPayPlanType($commissionPayPlanType: CommissionPayPlanTypeInput!) {
		createCommissionPayPlanType(commissionPayPlanType: $commissionPayPlanType) {
			id
		}
	}
`;

export const updateCommissionPayPlanType = gql`
	mutation UpdateCommissionPayPlanType($id: Int!, $commissionPayPlanType: CommissionPayPlanTypeInput!) {
		updateCommissionPayPlanType(id: $id, commissionPayPlanType: $commissionPayPlanType) {
			id
		}
	}
`;

export const createCommissionType = gql`
	mutation CreateCommissionType($commissionType: CommissionTypeInput!) {
		createCommissionType(commissionType: $commissionType) {
			id
		}
	}
`;

export const updateCommissionType = gql`
	mutation UpdateCommissionType($id: Int!, $commissionType: CommissionTypeInput!) {
		updateCommissionType(id: $id, commissionType: $commissionType) {
			id
		}
	}
`;

export const createCommissionPayPlan = gql`
	mutation CreateCommissionPayPlan($commissionPayPlan: CommissionPayPlanInput!) {
		createCommissionPayPlan(commissionPayPlan: $commissionPayPlan) {
			id
			contractStartDate
			contractEndDate
		}
	}
`;

export const updateCommissionPayPlan = gql`
	mutation UpdateCommissionPayPlan($id: Int!, $commissionPayPlan: CommissionPayPlanInput!) {
		updateCommissionPayPlan(id: $id, commissionPayPlan: $commissionPayPlan) {
			id
			contractStartDate
			contractEndDate
		}
	}
`;

export const createCommissionPaymentApproval = gql`
	mutation CreateCommissionPaymentApproval($commissionPaymentApproval: CommissionPaymentApprovalInput!) {
		createCommissionPaymentApproval(commissionPaymentApproval: $commissionPaymentApproval) {
			id
		}
	}
`;

export const updateCommissionPaymentApproval = gql`
	mutation UpdateCommissionPaymentApproval($id: Int!, $commissionPaymentApproval: CommissionPaymentApprovalInput!) {
		updateCommissionPaymentApproval(id: $id, commissionPaymentApproval: $commissionPaymentApproval) {
			id
		}
	}
`;

export const deleteCommissionPayPlan = gql`
	mutation DeleteCommissionPayPlan($id: Int!) {
		deleteCommissionPayPlan(id: $id) {
			id
		}
	}
`;

export const updateAttachmentType = gql`
	mutation UpdateAttachmentType(
		$attachmentTypeId: String!
		$attachmentType: AttachmentTypeInput!
	) {
		updateAttachmentType(
			attachmentTypeId: $attachmentTypeId
			attachmentType: $attachmentType
		) {
			attachmentTypeId
		}
	}
`;

export const createServiceType = gql`
	mutation CreateServiceType($serviceType: ServiceTypeInput!) {
		createServiceType(serviceType: $serviceType) {
			serviceTypeId
		}
	}
`;

export const updateServiceType = gql`
	mutation UpdateServiceType(
		$serviceTypeId: String!
		$serviceType: ServiceTypeInput!
	) {
		updateServiceType(
			serviceTypeId: $serviceTypeId
			serviceType: $serviceType
		) {
			serviceTypeId
		}
	}
`;

export const createCompany = gql`
	mutation CreateCompany($company: CompanyInput!) {
		createCompany(company: $company) {
			companyId
		}
	}
`;

export const updateCompany = gql`
	mutation UpdateCompany($companyId: String!, $company: CompanyInput!) {
		updateCompany(companyId: $companyId, company: $company) {
			companyId
		}
	}
`;

export const createLicensePlan = gql`
	mutation CreateLicensePlan($licensePlan: LicensePlanInput!) {
		createLicensePlan(licensePlan: $licensePlan) {
			id
		}
	}
`;

export const updateLicensePlan = gql`
	mutation UpdateLicensePlan($id: Int!, $licensePlan: LicensePlanInput!) {
		updateLicensePlan(id: $id, licensePlan: $licensePlan) {
			id
		}
	}
`;

export const createMenuType = gql`
	mutation CreateMenuType($menuType: MenuTypeInput!) {
		createMenuType(menuType: $menuType) {
			menuTypeId
		}
	}
`;

export const updateMenuType = gql`
	mutation UpdateMenuType($menuTypeId: String!, $menuType: MenuTypeInput!) {
		updateMenuType(menuTypeId: $menuTypeId, menuType: $menuType) {
			menuTypeId
		}
	}
`;

export const createPendingCommission = gql`
	mutation CreatePendingCommission($pendingCommission: PendingCommissionInput!) {
		createPendingCommission(pendingCommission: $pendingCommission) {
			id
		}
	}
`;

export const updatePendingCommission = gql`
	mutation UpdatePendingCommission($id: Int!, $pendingCommission: PendingCommissionInput!) {
		updatePendingCommission(id: $id, pendingCommission: $pendingCommission) {
			id
		}
	}
`;

export const updateResolvePendingCommission = gql`
	mutation updateResolvePendingCommission($id: Int!, $pendingCommission: PendingCommissionInput!) {
		updateResolvePendingCommission(id: $id, pendingCommission: $pendingCommission) {
			id
		}
	}
`;

export const updatePendingConfirmation = gql`
	mutation UpdatePendingConfirmation($id: Int!, $pendingConfirmation: PendingConfirmationInput!) {
		updatePendingConfirmation(id: $id, pendingConfirmation: $pendingConfirmation) {
			id
		}
	}
`;

export const updatePendingInvoice = gql`
mutation UpdatePendingInvoice($id: Int!, $pendingInvoice: PendingInvoiceInput!) {
	updatePendingInvoice(id: $id, pendingInvoice: $pendingInvoice) {
		id
	}
}
`;

export const updateInvoicesStatus = gql`
mutation updateInvoicesStatus($id: IntString!, $invoiceStatusId: Int!) {
	updateInvoicesStatus(id: $id, invoiceStatusId: $invoiceStatusId) {
		invoiceStatusId
	}
}
`;

export const deleteInvoices = gql`
mutation deleteInvoices($id: IntString!) {
	deleteInvoices(id: $id) {
		response
	}
}
`;

export const createInvoice = gql`
mutation createInvoice($invoice: InvoiceInput!) {
	createInvoice(invoice: $invoice) {
		id
	}
}
`;

export const updateInvoice = gql`
mutation updateInvoice($id: Int!, $invoice: InvoiceInput!) {
	updateInvoice(id: $id, invoice: $invoice) {
		id
	}
}
`;

export const errorCheckPendingCommissions = gql`
	mutation ErrorCheckPendingCommissions($ids: [Int!]!) {
		errorCheckPendingCommissions(ids: $ids) {
			message {
				id
			}
		}
	}
`;

export const errorCheckPendingConfirmations = gql`
	mutation ErrorCheckPendingConfirmations($ids: [Int!]!) {
		errorCheckPendingConfirmations(ids: $ids) {
			message {
				id
			}
		}
	}
`;

export const errorCheckPendingInvoices = gql`
	mutation ErrorCheckPendingInvoices($ids: [Int!]!) {
		errorCheckPendingInvoices(ids: $ids) {
			message {
				id
			}
		}
	}
`;

export const destroyPendingConfirmations = gql`
	mutation DestroyPendingConfirmations($ids: [Int!]!) {
		destroyPendingConfirmations(ids: $ids) {
			message {
				id
			}
		}
	}
`;

export const destroyPendingInvoices = gql`
	mutation DestroyPendingInvoices($ids: [Int!]!) {
		destroyPendingInvoices(ids: $ids) {
			message {
				id
			}
		}
	}
`;

export const completeReviewPendingCommissions = gql`
	mutation CompleteReviewPendingCommissions($ids: [Int!]!) {
		completeReviewPendingCommissions(ids: $ids) {
			message {
				id
			}
		}
	}
`;

export const completeForceResolvePendingCommissions = gql`
	mutation CompleteForceResolvePendingCommissions($ids: [Int!]!) {
		completeForceResolvePendingCommissions(ids: $ids) {
			message {
				id
			}
		}
	}
`;

export const archivePendingCommissions = gql`
	mutation ArchivePendingCommissions($ids: [Int!]!) {
		archivePendingCommissions(ids: $ids) {
			message {
				id
			}
		}
	}
`;

export const unarchivePendingCommissions = gql`
	mutation UnarchivePendingCommissions($ids: [Int!]!) {
		unarchivePendingCommissions(ids: $ids) {
			message {
				id
			}
		}
	}
`;

export const updatePendingCommissionIssue = gql`
mutation UpdatePendingCommissionIssue($id: Int!, $pendingCommissionIssue: PendingCommissionIssueInput!) {
	updatePendingCommissionIssue(id: $id, pendingCommissionIssue: $pendingCommissionIssue) {
		id
		overrideUserId
	}
}
`;

export const completeReviewPendingInvoices = gql`
	mutation CompleteReviewPendingInvoices($ids: [Int!]!) {
		completeReviewPendingInvoices(ids: $ids) {
			message {
				id
			}
		}
	}
`;

export const incompleteReviewPendingCommissions = gql`
	mutation IncompleteReviewPendingCommission($ids: [Int!]!) {
		incompleteReviewPendingCommissions(ids: $ids) {
			message {
				id
			}
		}
	}
`;

export const completeSetStatusPostProcessingCommissionAudits = gql`
	mutation CompleteSetStatusPostProcessingCommissionAudits($ids: [Int!]!, $statusId: Int!) {
		completeSetStatusPostProcessingCommissionAudits(ids: $ids, postProcessingCommissionAuditStatusTypeId: $statusId) {
			id
		}
	}
`;

export const completeUnreviewPostProcessingCommissionAudits = gql`
	mutation CompleteUnreviewPostProcessingCommissionAudits($ids: [Int!]!) {
		completeUnreviewPostProcessingCommissionAudits(ids: $ids) {
			id
		}
	}
`;

export const completeReviewCommissionPayables = gql`
	mutation CompleteReviewCommissionPayables($ids: [Int!]!) {
		completeReviewCommissionPayables(ids: $ids) {
			response
		}
	}
`;

export const incompleteReviewCommissionPayables = gql`
	mutation IncompleteReviewCommissionPayables($ids: [Int!]!) {
		incompleteReviewCommissionPayables(ids: $ids) {
			response
		}
	}
`;

export const rejectCommissionPayables = gql`
	mutation RejectCommissionPayables($ids: [Int!]!) {
		rejectCommissionPayables(ids: $ids) {
			response
		}
	}
`;

export const completeReviewUnpaidPayables = gql`
	mutation CompleteReviewUnpaidPayables($ids: [String!]!) {
		completeReviewUnpaidPayables(ids: $ids) {
			response
		}
	}
`;

export const incompleteReviewUnpaidPayables = gql`
	mutation IncompleteReviewUnpaidPayables($ids: [String!]!) {
		incompleteReviewUnpaidPayables(ids: $ids) {
			response
		}
	}
`;

export const rejectReviewUnpaidPayables = gql`
	mutation RejectUnpaidPayables($ids: [String!]!) {
		rejectUnpaidPayables(ids: $ids) {
			response
		}
	}
`;

export const deleteUnpaidPayables = gql`
	mutation DeleteUnpaidPayables($ids: [String!]!) {
		deleteUnpaidPayables(ids: $ids) {
			response
		}
	}
`;

export const incompleteReviewPendingInvoices = gql`
	mutation IncompleteReviewPendingInvoices($ids: [Int!]!) {
		incompleteReviewPendingInvoices(ids: $ids) {
			message {
				id
			}
		}
	}
`;

export const processPendingCommissions = gql`
	mutation ProcessPendingCommissions($ids: [Int!]!) {
		processPendingCommissions(ids: $ids) {
			message {
				id
			}
		}
	}
`;

export const processPendingConfirmations = gql`
	mutation ProcessPendingConfirmations($ids: [Int!]!) {
		processPendingConfirmations(ids: $ids) {
			message {
				id
			}
		}
	}
`;

export const processPendingInvoices = gql`
	mutation ProcessPendingInvoices($ids: [Int!]!) {
		processPendingInvoices(ids: $ids) {
			message {
				id
			}
		}
	}
`;

export const createPermission = gql`
	mutation CreatePermission($permission: PermissionInput!) {
		createPermission(permission: $permission) {
			permissionId
		}
	}
`;

export const updatePermission = gql`
	mutation UpdatePermission($permissionId: String!, $permission: PermissionInput!) {
		updatePermission(permissionId: $permissionId, permission: $permission) {
			permissionId
		}
	}
`;

export const createReportType = gql`
	mutation CreateReportType($reportType: ReportTypeInput!) {
		createReportType(reportType: $reportType) {
			reportTypeId
		}
	}
`;

export const updateReportType = gql`
	mutation UpdateReportType($reportTypeId: String!, $reportType: ReportTypeInput!) {
		updateReportType(reportTypeId: $reportTypeId, reportType: $reportType) {
			reportTypeId
		}
	}
`;

export const createPriceElement = gql`
	mutation CreatePriceElement($priceElement: PriceElementInput!) {
		createPriceElement(priceElement: $priceElement) {
			priceElementId
		}
	}
`;

export const createReport = gql`
	mutation CreateReport($report: ReportInput!) {
		createReport(report: $report) {
			reportId
		}
	}
`;

export const updatePriceElement = gql`
	mutation UpdatePriceElement($priceElementId: String!, $priceElement: PriceElementInput!) {
		updatePriceElement(priceElementId: $priceElementId, priceElement: $priceElement) {
			priceElementId
		}
	}
`;

export const updateReport = gql`
	mutation UpdateReport($reportId: String!, $report: ReportInput!) {
		updateReport(reportId: $reportId, report: $report) {
			reportId
		}
	}
`;

export const createSetting = gql`
	mutation CreateSetting($setting: SettingInput!) {
		createSetting(setting: $setting) {
			settingId
		}
	}
`;

export const createRfqSessionOptOutReasonType = gql`
	mutation CreateRfqSessionOptOutReason($optOutReasonType: RfqSessionOptOutReasonTypeInput!) {
		createRfqSessionOptOutReason(rfqSessionOptOutReason: $optOutReasonType) {
			id
		}
	}
`;

export const createContractRenewal = gql`
mutation CreateContractRenewal($contractId: String!) {
	createContractRenewal(contractId: $contractId) {
		contractId
	}
}
`;

export const sendExpirationNoticeEmail = gql`
mutation SendExpirationNoticeEmail($contractId: String!) {
	sendExpirationNoticeEmail(contractId: $contractId)
}
`;

export const resendEnrollmentEmail = gql`
mutation ResendEnrollmentEmail($contractId: String!) {
	resendEnrollmentEmail(contractId: $contractId)
}
`;

export const sendUtilityUsageRequestEmail = gql`
mutation SendUtilityUsageRequestEmail($contractId: String!) {
	sendUtilityUsageRequestEmail(contractId: $contractId)
}
`;

export const sendAutomatedRenewalEmail = gql`
mutation SendAutomatedRenewalEmail($contractId: String!, $margin: Float!) {
	sendAutomatedRenewalEmail(contractId: $contractId, margin: $margin) {
		response
	}
}
`;

export const sendDisconnectionStatusEmail = gql`
mutation sendDisconnectionStatusEmail($contractId: String!, $disconnectionStatuses: [DisconnectionStatusInput!]!) {
	sendDisconnectionStatusEmail(contractId: $contractId, disconnectionStatuses: $disconnectionStatuses) {
		response
	}
}
`;

export const updateSetting = gql`
	mutation UpdateSetting($settingId: String!, $setting: SettingInput!) {
		updateSetting(settingId: $settingId, setting: $setting) {
			settingId
		}
	}
`;

export const updateRfqSessionOptOutReasonType = gql`
	mutation UpdateRfqSessionOptOutReasonType($id: Int!, $rfqSessionOptOutReason: RfqSessionOptOutReasonTypeInput!) {
		updateRfqSessionOptOutReason(id: $id, rfqSessionOptOutReason: $rfqSessionOptOutReason) {
			id
		}
	}
`;

export const completeEsign = gql`
mutation CompleteEsign($esignId: String!, $esign: EsignInput!) {
	completeEsign(esignId: $esignId, esign: $esign) {
		esignId
	}
}`;

export const generateLOE = gql`
mutation GenerateLOE($contractId: String!, $addLogo: Boolean) {
	generateLOE(contractId: $contractId, addLogo: $addLogo) {
		contractId
	}
}
`;

export const generateLOA = gql`
mutation GenerateLOA($contractId: String!) {
	generateLOA(contractId: $contractId) {
		contractId
	}
}
`;

export const generateLOALOE = gql`
mutation GenerateLOALOE($criteria: GenerateLOALOEInput!) {
	generateLOALOE(criteria: $criteria) {
		contractId
	}
}
`;

export const requestLOALOE = gql`
mutation RequestLOALOE($criteria: RequestLOALOEInput!) {
	requestLOALOE(criteria: $criteria) {
		contractId
	}
}
`;

export const sendPeakDemandEmails = gql`
mutation SendPeakDemandEmails($criteria: PeakDemandEmailInput!) {
	sendPeakDemandEmails(criteria: $criteria)
}
`;

export const requestBill = gql`
mutation RequestBill($contractId: String!, $supplierId: String!) {
	requestBill(contractId: $contractId, supplierId: $supplierId) {
		contractId
	}
}
`;

export const enrollResidential = gql`
mutation EnrollResidential($criteria: EnrollResidentialInput!) {
	enrollResidential(criteria: $criteria) {
		contractId
		creditCheckMessage
		rejectReason
		isResidential
		supplierId
		externalId
		status
		depositRequiredAmount
		depositPaidAmount
		locations {
			address1
			address2
			city
			stateId
			zipCode
			rateClass
		}
		customer {
			contactFname
			contactMiddle
			contactLname
			ssn
			dbaName
			phone
			email
		}
		agent {
			customerCompany
			agentDomainAlias
		}
		supplier {
			hasCreditCardAPI
		}
	}
}
`;

export const createPayment = gql`
mutation CreatePayment($payment: PaymentInput!) {
	createPayment(payment: $payment) {
		id
	}
}
`;

export const deleteSetting = gql`
	mutation DeleteSetting($settingId: String!) {
		deleteSetting(settingId: $settingId) {
			settingId
		}
	}
`;

export const createFaq = gql`
	mutation CreateFaq($faq: FaqInput!) {
		createFaq(faq: $faq) {
			id
		}
	}
`;

export const updateFaq = gql`
	mutation UpdateFaq($id: Int!, $faq: FaqInput!) {
		updateFaq(id: $id, faq: $faq) {
			id
		}
	}
`;

export const createFaqCategory = gql`
	mutation CreateFaqCategory($faqCategory: FaqCategoryInput!) {
		createFaqCategory(faqCategory: $faqCategory) {
			id
		}
	}
`;

export const updateFaqCategory = gql`
	mutation UpdateFaqCategory($id: Int!, $faqCategory: FaqCategoryInput!) {
		updateFaqCategory(id: $id, faqCategory: $faqCategory) {
			id
		}
	}
`;

export const createEducation = gql`
	mutation CreateEducation($education: EducationInput!) {
		createEducation(education: $education) {
			id
		}
	}
`;

export const updateEducation = gql`
	mutation UpdateEducation($id: Int!, $education: EducationInput!) {
		updateEducation(id: $id, education: $education) {
			id
		}
	}
`;

export const deleteEducation = gql`
	mutation DeleteEducation($id: Int!) {
		deleteEducation(id: $id) {
			id
		}
	}
`;

export const createEducationCategory = gql`
	mutation CreateEducationCategory($educationCategory: EducationCategoryInput!) {
		createEducationCategory(educationCategory: $educationCategory) {
			id
		}
	}
`;

export const updateEducationCategory = gql`
	mutation UpdateEducationCategory($id: Int!, $educationCategory: EducationCategoryInput!) {
		updateEducationCategory(id: $id, educationCategory: $educationCategory) {
			id
		}
	}
`;

export const createEducationType = gql`
	mutation CreateEducationType($educationType: EducationTypeInput!) {
		createEducationType(educationType: $educationType) {
			id
		}
	}
`;

export const updateEducationType = gql`
	mutation UpdateEducationType($id: Int!, $educationType: EducationTypeInput!) {
		updateEducationType(id: $id, educationType: $educationType) {
			id
		}
	}
`;

export const createApp = gql`
	mutation CreateApp($app: AppInput!) {
		createApp(app: $app) {
			id
		}
	}
`;

export const updateApp = gql`
	mutation UpdateApp($id: Int!, $app: AppInput!) {
		updateApp(id: $id, app: $app) {
			id
		}
	}
`;

export const refreshToken = gql`
${fragments.CORE_LOGGEDINUSER_FIELDS}
mutation RefreshToken {
	refreshToken {
		token
		refreshToken
		loggedInUser: user {
			...CoreLoggedInUserFields
		}
	}
}
`;

export const revokeToken = gql`
mutation RevokeToken {
	revokeToken
}
`;

export const createContractDirect = gql`
	mutation CreateContractDirect($contract: ContractInput!) {
		createContract(contract: $contract) {
			contractId
			creditCheckMessage
			rejectReason
			isResidential
			supplierId
			externalId
			status
			depositRequiredAmount
			depositPaidAmount
			customerId
			locations {
				address1
				address2
				city
				stateId
				zipCode
				rateClass
			}
			customer {
				contactFname
				contactMiddle
				contactLname
				ssn
				dbaName
				phone
				email
			}
			agent {
				customerCompany
				agentDomainAlias
			}
			supplier {
				hasCreditCardAPI
			}
		}
	}
`;

export const updateContractDirect = gql`
	mutation UpdateContractDirect($contractId: String!, $contract: ContractInput!) {
		updateContract(id: $contractId, contract: $contract) {
			contractId
			creditCheckMessage
			rejectReason
			isResidential
			isRenewal
			supplierId
			externalId
			status
			depositRequiredAmount
			depositPaidAmount
			annualUsage
			locations {
				address1
				address2
				city
				stateId
				zipCode
				rateClass
			}
			customer {
				contactFname
				contactMiddle
				contactLname
				ssn
				dbaName
				phone
				email
			}
			agent {
				customerCompany
				agentDomainAlias
			}
			supplier {
				hasCreditCardAPI
			}
			esigns {
				esignId
				isComplete
			}
		}
	}
`;

export const updateContract = gql`
	mutation UpdateContract($contractId: String!, $contract: ContractInput!) {
		updateContract(id: $contractId, contract: $contract) {
			contractId
			annualUsage
		}
	}
`;

export const createState = gql`
	mutation CreateState($state: StateInput!) {
		createState(state: $state) {
			stateId
		}
	}
`;

export const updateState = gql`
	mutation UpdateState($stateId: String!, $state: StateInput!) {
		updateState(stateId: $stateId, state: $state) {
			stateId
		}
	}
`;

export const createPricingAnalyticsSupplierSetting = gql`
	mutation CreatePricingAnalyticsSupplierSetting($setting: PricingAnalyticsSupplierSettingInput!) {
		createPricingAnalyticsSupplierSetting(pricingAnalyticsSupplierSetting: $setting) {
			id
		}
	}
`;

export const updatePricingAnalyticsSupplierSetting = gql`
	mutation UpdatePricingAnalyticsSupplierSetting($id: Int!, $setting: PricingAnalyticsSupplierSettingInput) {
		updatePricingAnalyticsSupplierSetting(id: $id, pricingAnalyticsSupplierSetting: $setting) {
			id
		}
	}
`;

export const createTask = gql`
	mutation CreateTask($task: TaskInput!) {
		createTask(task: $task) {
			taskId
			addDate
			title
			dueDate
			body
			completeDate
			isComplete
			assignedToUser {
				fname
				lname
				middle
				fullName
			}
		}
	}
`;

export const updateTask = gql`
	mutation UpdateTask($taskId: String!, $task: TaskInput!) {
		updateTask(taskId: $taskId, task: $task) {
			taskId
			addDate
			title
			dueDate
			body
			completeDate
			isComplete
		}
	}
`;

export const reassignContracts = gql`
	mutation ReassignContracts($oldAgentId: String!, $newAgentId: String!) {
		reassignContracts(oldAgentId: $oldAgentId, newAgentId: $newAgentId)
	}
`;

export const resetCommissionRates = gql`
	mutation ResetCommissionRates($agentId: String!) {
		resetCommissionRates(agentId: $agentId)
	}
`;

export const deleteNote = gql`
	mutation DeleteNote($noteId: String!) {
		deleteNote(noteId: $noteId) {
			noteId
		}
	}
`;

export const updateNote = gql`
	mutation UpdateNote($noteId: String!, $note: NoteInput!) {
		updateNote(noteId: $noteId, note: $note) {
			noteId
		}
	}
`;

export const createNote = gql`
	mutation CreateNote($note: NoteInput!) {
		createNote(note: $note) {
			noteId
			addDate
			subject
			body
			isInternal
			agentEngagementEvent {
				addDate
				agentEngagementEventCategory {
					name
				}
			}
			user {
				fname
				lname
				middle
				fullName
			}
			noteType {
				name
				imageIcon
				imageIconPrefix
			}
			supplierCommissionAttachmentId
		}
	}
`;

export const updateAddOns = gql`
	mutation UpdateAddOns($addOnRequest: AddOnRequest!) {
		updateAddOns(addOnRequest: $addOnRequest) {
			agentId
		}
	}
`;

export const createAgent = gql`
	mutation CreateAgent($agent: AgentInput!) {
		createAgent(agent: $agent) {
			agentId
		}
	}
`;

export const deletePromoCode = gql`
mutation DeletePromoCode($id: Int!) {
	deletePromoCode(id: $id) {
		id
	}
}
`;

export const updateSalesforceAgentByAgentId = gql`
mutation UpdateSalesforceAgentByAgentId($agentId: String!) {
	updateSalesforceAgent(agentId: $agentId) {
		errors
		id
		success
	}
}
`;

export const removeAllSubscriptions = gql`
mutation RemoveAllSubscriptions($agentId: String!) {
	removeAllSubscriptions(agentId: $agentId) {
		Data
		IsSuccess
	}
}
`;

export const updateAgent = gql`
	mutation UpdateAgent($id: String!, $agent: AgentInput!) {
		updateAgent(id: $id, agent: $agent) {
			agentId
		}
	}
`;

export const createUser = gql`
	mutation CreateUser($user: UserInput!) {
		createUser(user: $user) {
			userId
		}
	}
`;

export const sendQuoteEmail = gql`
	mutation SendQuoteEmail($quoteEmail: QuoteEmailInput!) {
		sendQuoteEmail(quoteEmail: $quoteEmail)
	}
`;

export const updateUser = gql`
	mutation UpdateUser($id: String!, $user: UserInput!) {
		updateUser(id: $id, user: $user) {
			userId
		}
	}
`;

export const createRfqSession = gql`
	mutation CreateRfqSession($rfqSession: RfqSessionInput!) {
		createRfqSession(rfqSession: $rfqSession) {
			id
			endDate2
		}
	}
`;

export const sendRfqSessionMessage = gql`
	mutation SendRfqSessionMessage(
		$rfqSessionId: String!
		$subject: String!
		$body: String
		$supplierId: String!,
		$template: String
	) {
		sendRfqSessionMessage(
			rfqSessionId: $rfqSessionId
			subject: $subject
			body: $body
			supplierId: $supplierId,
			template: $template
		)
	}
`;

export const createTicketComment = gql`
	mutation CreateTicketComment($ticketComment: TicketCommentInput!) {
		createTicketComment(ticketComment: $ticketComment) {
			id
			addDate
			isInternal
			body
			user {
				userId
				fname
				lname
			}
		}
	}
`;

export const addAgentCustomerCard = gql`
	mutation AddAgentCustomerCard($agentId: String!, $sourceToken: String!) {
		addAgentCustomerCard(agentId: $agentId, sourceToken: $sourceToken) {
			brand
			exp_month
			exp_year
			last4
			id
		}
	}
`;

export const addCustomerCard = gql`
	mutation AddCustomerCard($stripeCustomerId: String!, $sourceToken: String!) {
		addCustomerCard(stripeCustomerId: $stripeCustomerId, sourceToken: $sourceToken) {
			brand
			exp_month
			exp_year
			last4
			id
		}
	}
`;

export const deleteAttachment = gql`
	mutation DeleteAttachment($attachmentId: String!) {
		deleteAttachment(attachmentId: $attachmentId) {
			attachmentId
		}
	}
`;

export const createTicket = gql`
	mutation CreateTicket($ticket: TicketInput!) {
		createTicket(ticket: $ticket) {
			id
		}
	}
`;

export const createFeedbackTicket = gql`
	mutation CreateFeedbackTicket($ticket: TicketInput!) {
		createTicket(ticket: $ticket) {
			id
			subject
			body
			email
			priority
			addDate
			completeDate
			ticketCategoryId
			completeUserId
			assignedToUserId
			userId
			contractId
			ticketStatusId
			category {
				name
			}
			contactMethods {
				name
			}
			user {
				userId
				fname
				lname
				emails {
					email
				}
				isActive
				isAdmin
				isSaas
				role {
					name
				}
			}
			assignedToUser {
				fname
				lname
			}
			completeUser {
				fname
				lname
			}
			attachments {
				attachmentId
				originalName
				size
				name
				attachmentTypeId
				addDate
				user {
					fname
					lname
					emails {
						email
					}
				}
			}
			comments {
				id
				ticketId
				body
				addDate
				modifiedDate
				isInternal
				user {
					fname
					lname
				}
			}
		}
	}
`;

export const updateTicket = gql`
	mutation UpdateTicket($id: Int!, $ticket: TicketInput!) {
		updateTicket(id: $id, ticket: $ticket) {
			id
			ticketStatusId
			completeDate
		}
	}
`;

export const updateFeedbackTicket = gql`
	mutation UpdateFeedbackTicket($id: Int!, $ticket: TicketInput!) {
		updateTicket(id: $id, ticket: $ticket) {
			id
			subject
			body
			email
			priority
			addDate
			completeDate
			ticketCategoryId
			completeUserId
			assignedToUserId
			userId
			contractId
			ticketStatusId
			category {
				name
			}
			contactMethods {
				name
			}
			user {
				userId
				fname
				lname
				emails {
					email
				}
				isActive
				isAdmin
				isSaas
				role {
					name
				}
			}
			assignedToUser {
				fname
				lname
			}
			completeUser {
				fname
				lname
			}
			attachments {
				attachmentId
				originalName
				size
				name
				attachmentTypeId
				addDate
				user {
					fname
					lname
					emails {
						email
					}
				}
			}
			comments {
				id
				ticketId
				body
				addDate
				modifiedDate
				isInternal
				user {
					fname
					lname
				}
			}
		}
	}
`;

export const updateAttachment = gql`
mutation UpdateAttachment($attachmentId: String!, $attachment: AttachmentInput!) {
	updateAttachment(attachmentId: $attachmentId, attachment: $attachment) {
		attachmentId
		addDate
		name
		description
		attachmentType {
			name
		}
		user {
			fname
			lname
			middle
		}
	}
}
`;

export const deleteContractLocation = gql`
mutation DeleteContractLocation($contractLocationId: String!) {
	deleteContractLocation(contractLocationId: $contractLocationId) {
		contractLocationId
	}
}
`;

export const updateContractLocation = gql`
mutation UpdateContractLocation($contractLocationId: String!, $location: ContractLocationInput!) {
	updateContractLocation(contractLocationId: $contractLocationId, contractLocation: $location) {
		contractLocationId
	}
}
`;

export const createContractLocation = gql`
mutation CreateContractLocation($location: ContractLocationInput!) {
	createContractLocation(contractLocation: $location) {
		contractLocationId
		address1
		address2
		city
		stateId
		utilityId
		utilityAccountNum
		utilityNameKey
		utilityMeterNum
		annualUsage
		addDate
		state {
			stateShort
			stateLong
		}
		zipCode
		utility {
			abbreviation
			accountNumLabel
			meterNumLabel
			referenceNumLabel
			nameKeyLabel
		}
		personalPropertyLiability
		personalLiability
		schedulePersonalProperty
		deductible
	}
}
`;

export const deleteRfqSessionSupplier = gql`
mutation DeleteRfqSessionSupplier($rfqSessionSupplierId: String!) {
	deleteRfqSessionSupplier(rfqSessionSupplierId: $rfqSessionSupplierId){
		id
	}
}
`;

export const createRfqSessionSupplier = gql`
mutation CreateRfqSessionSupplier($rfqSessionSupplier: RfqSessionSupplierInput!) {
	createRfqSessionSupplier(rfqSessionSupplier: $rfqSessionSupplier) {
		id
	}
}
`;

export const updateRfqSessionSupplier = gql`
mutation UpdateRfqSessionSupplier($rfqSessionSupplierId: String!, $rfqSessionSupplier: RfqSessionSupplierInput!) {
	updateRfqSessionSupplier(rfqSessionSupplierId: $rfqSessionSupplierId, rfqSessionSupplier: $rfqSessionSupplier) {
		id
	}
}
`;

export const updateRfqSessionSupplierAuctionMonitorEmailAudit = gql`
mutation UpdateRfqSessionSupplierAuctionMonitorEmailAudit(
	$id: Int!, $rfqSessionSupplierAuctionMonitorEmailAudit: RfqSessionSupplierAuctionMonitorEmailAuditInput!) {
	updateRfqSessionSupplierAuctionMonitorEmailAudit(id: $id,
		rfqSessionSupplierAuctionMonitorEmailAudit: $rfqSessionSupplierAuctionMonitorEmailAudit) {
		id
	}
}
`;

export const createRfqSessionProduct = gql`
mutation CreateRfqSessionProduct($rfqSessionProduct: RfqSessionProductInput!) {
	createRfqSessionProduct(rfqSessionProduct: $rfqSessionProduct) {
		id
		term
		lag
		blockType
		blockSize
		greenPercentage
		initialFixedUsage
		product {
			name
			description
		}
	}
}
`;

export const createRfqSessionBid = gql`
mutation CreateRfqSessionBid($rfqSessionBid: RfqSessionBidInput!) {
	createRfqSessionBid(rfqSessionBid: $rfqSessionBid) {
		id
	}
}
`;

export const createRfqSessionBids = gql`
mutation createRfqSessionBids($rfqSessionBids: [RfqSessionBidInput!]!) {
	createRfqSessionBids(rfqSessionBids: $rfqSessionBids) {
		id
	}
}
`;

export const deleteRfqSessionBid = gql`
	mutation DeleteRfqSessionBid($rfqSessionBidId: String!) {
	deleteRfqSessionBid(rfqSessionBidId: $rfqSessionBidId) {
		id
	}
}
`;

export const deleteRfqSessionProduct = gql`
mutation DeleteRfqSessionProduct($rfqSessionProductId: String!) {
	deleteRfqSessionProduct(rfqSessionProductId: $rfqSessionProductId){
		id
	}
}
`;

export const updateRfqSession = gql`
mutation UpdateRfqSession($id: String!, $rfqSession: RfqSessionInput!) {
	updateRfqSession(id: $id, rfqSession: $rfqSession) {
		id
		endDate2
	}
}
`;

export const extendRfqSession = gql`
mutation ExtendRfqSession($id: String!, $rfqSession: RfqSessionInput!) {
	extendRfqSession(id: $id, rfqSession: $rfqSession) {
		id
		endDate2
	}
}
`;

export const createUserGridConfig = gql`
mutation CreateUserGridConfig($userGridConfig: UserGridConfigInput!) {
	createUserGridConfig(userGridConfig: $userGridConfig) {
		id
		states
	}
}
`;

export const deleteUserGridConfig = gql`
mutation DeleteUserGridConfig($id: Int!) {
	deleteUserGridConfig(id: $id) {
		id
	}
}
`;

export const sendSupplierSupportInquiry = gql`
mutation SendSupplierSupportInquiry($criteria: RfqSessionSupplierInquiryInput!) {
	sendRfqSessionSupplierInquiry(criteria: $criteria)
}
`;

export const sendSupplierSupportExtensionRequest = gql`
mutation SendRfqSessionSupplierExtensionRequest($criteria: RfqSessionSupplierExtensionRequestInput!) {
	sendRfqSessionSupplierExtensionRequest(criteria: $criteria)
}
`;

export const updateAlert = gql`
mutation UpdateAlert($id: Int!, $alert: AlertInput!) {
	updateAlert(id: $id, alert: $alert) {
		id
		isRead
		isOpened
	}
}
`;

export const createUtilityBaseRate = gql`
mutation CreateUtilityBaseRate($utilityBaseRate: UtilityBaseRateInput!) {
	createUtilityBaseRate(utilityBaseRate: $utilityBaseRate) {
		id
	}
}
`;

export const createUtilityBaseRates = gql`
mutation CreateUtilityBaseRates($utilityBaseRates: [UtilityBaseRateInput!]!) {
	createUtilityBaseRates(utilityBaseRates: $utilityBaseRates) {
		id
	}
}
`;

export const createUtilityServiceFee = gql`
mutation CreateUtilityServiceFee($utilityServiceFee: UtilityServiceFeeInput!) {
	createUtilityServiceFee(utilityServiceFee: $utilityServiceFee) {
		id
	}
}
`;

export const createUtilityServiceFees = gql`
mutation CreateUtilityServiceFees($utilityServiceFees: [UtilityServiceFeeInput!]!) {
	createUtilityServiceFees(utilityServiceFees: $utilityServiceFees) {
		id
	}
}
`;

export const updateUserGridConfig = gql`
mutation UpdateUserGridConfig($id: Int!, $userGridConfig: UserGridConfigInput!) {
	updateUserGridConfig(id: $id, userGridConfig: $userGridConfig) {
		id
	}
}
`;

export const updateUtilityBaseRate = gql`
mutation UpdateUtilityBaseRate($id: Int!, $utilityBaseRate: UtilityBaseRateInput!) {
	updateUtilityBaseRate(id: $id, utilityBaseRate: $utilityBaseRate) {
		id
	}
}
`;

export const deleteUtilityBaseRate = gql`
mutation DeleteUtilityBaseRate($id: Int!) {
	deleteUtilityBaseRate(id: $id) {
		id
	}
}
`;

export const deleteUtilityBaseRates = gql`
mutation DeleteUtilityBaseRates($id: String!) {
	deleteUtilityBaseRates(id: $id) {
		id
	}
}
`;

export const updateUtilityServiceFee = gql`
mutation UpdateUtilityServiceFee($id: Int!, $utilityServiceFee: UtilityServiceFeeInput!) {
	updateUtilityServiceFee(id: $id, utilityServiceFee: $utilityServiceFee) {
		id
	}
}
`;

export const deleteUtilityServiceFee = gql`
mutation DeleteUtilityServiceFee($id: Int!) {
	deleteUtilityServiceFee(id: $id) {
		id
	}
}
`;

export const deleteUtilityServiceFees = gql`
mutation DeleteUtilityServiceFees($id: String!) {
	deleteUtilityServiceFees(id: $id) {
		id
	}
}
`;

export const createRfqSessionExtendAuctionReason = gql`
mutation CreateRfqSessionExtendAuctionReason($rfqSessionExtendAuctionReason: RfqSessionExtendAuctionReasonTypeInput!) {
	createRfqSessionExtendAuctionReason(rfqSessionExtendAuctionReason: $rfqSessionExtendAuctionReason) {
		id
	}
}
`;

export const updateRfqSessionExtendAuctionReason = gql`
mutation UpdateRfqSessionExtendAuctionReason($id: Int!, $rfqSessionExtendAuctionReason: RfqSessionExtendAuctionReasonTypeInput!) {
	updateRfqSessionExtendAuctionReason(id: $id, rfqSessionExtendAuctionReason: $rfqSessionExtendAuctionReason) {
		id
	}
}
`;

export const createEmailPreference = gql`
mutation CreateEmailPreference($emailPreference: EmailPreferenceInput!) {
	createEmailPreference(emailPreference: $emailPreference) {
		id
	}
}
`;

export const updateEmailPreference = gql`
mutation UpdateEmailPreference($id: Int!, $emailPreference: EmailPreferenceInput!) {
	updateEmailPreference(id: $id, emailPreference: $emailPreference) {
		id
	}
}
`;

export const createEclLoadFactorBracket = gql`
mutation CreateEclLoadFactorBracket($eclLoadFactorBracket: EclLoadFactorBracketInput!) {
	createEclLoadFactorBracket(eclLoadFactorBracket: $eclLoadFactorBracket) {
		id
	}
}
`;

export const updateEclLoadFactorBracket = gql`
mutation UpdateEclLoadFactorBracket($id: Int!, $eclLoadFactorBracket: EclLoadFactorBracketInput!) {
	updateEclLoadFactorBracket(id: $id, eclLoadFactorBracket: $eclLoadFactorBracket) {
		id
	}
}
`;

export const createEclPackage = gql`
mutation CreateEclPackage($eclPackage: EclPackageInput!) {
	createEclPackage(eclPackage: $eclPackage) {
		id
	}
}
`;

export const updateEclPackage = gql`
mutation UpdateEclPackage($id: Int!, $eclPackage: EclPackageInput!) {
	updateEclPackage(id: $id, eclPackage: $eclPackage) {
		id
	}
}
`;

export const createEclCoupon = gql`
mutation CreateEclCoupon($eclCoupon: EclCouponInput!) {
	createEclCoupon(eclCoupon: $eclCoupon) {
		id
	}
}
`;

export const updateEclCoupon = gql`
mutation UpdateEclCoupon($id: Int!, $eclCoupon: EclCouponInput!) {
	updateEclCoupon(id: $id, eclCoupon: $eclCoupon) {
		id
	}
}
`;

export const createEclAnnualUsageBracket = gql`
mutation CreateEclAnnualUsageBracket($eclAnnualUsageBracket: EclAnnualUsageBracketInput!) {
	createEclAnnualUsageBracket(eclAnnualUsageBracket: $eclAnnualUsageBracket) {
		id
	}
}
`;

export const updateEclAnnualUsageBracket = gql`
mutation UpdateEclAnnualUsageBracket($id: Int!, $eclAnnualUsageBracket: EclAnnualUsageBracketInput!) {
	updateEclAnnualUsageBracket(id: $id, eclAnnualUsageBracket: $eclAnnualUsageBracket) {
		id
	}
}
`;

export const createAgentEclState = gql`
mutation CreateAgentEclState($agentId: String!, $agentEclState: AgentEclStateInput!) {
	createAgentEclState(agentId: $agentId, agentEclState: $agentEclState) {
		agentId
		stateId
	}
}
`;

export const deleteAgentEclState = gql`
mutation DeleteAgentEclState($agentId: String!, $agentEclState: AgentEclStateInput!) {
	deleteAgentEclState(agentId: $agentId, agentEclState: $agentEclState) {
		agentId
		stateId
	}
}
`;

export const createAgentEclAnnualUsageBracket = gql`
mutation CreateAgentEclAnnualUsageBracket($agentId: String!, $agentEclAnnualUsageBracket: AgentEclAnnualUsageBracketInput!) {
	createAgentEclAnnualUsageBracket(agentId: $agentId, agentEclAnnualUsageBracket: $agentEclAnnualUsageBracket) {
		agentId
		eclAnnualUsageBracketId
	}
}
`;

export const deleteAgentEclAnnualUsageBracket = gql`
mutation DeleteAgentEclAnnualUsageBracket($agentId: String!, $agentEclAnnualUsageBracket: AgentEclAnnualUsageBracketInput!) {
	deleteAgentEclAnnualUsageBracket(agentId: $agentId, agentEclAnnualUsageBracket: $agentEclAnnualUsageBracket) {
		agentId
		eclAnnualUsageBracketId
	}
}
`;

export const createAgentEclLoadFactorBracket = gql`
mutation CreateAgentEclLoadFactorBracket($agentId: String!, $agentEclLoadFactorBracket: AgentEclLoadFactorBracketInput!) {
	createAgentEclLoadFactorBracket(agentId: $agentId, agentEclLoadFactorBracket: $agentEclLoadFactorBracket) {
		agentId
		eclLoadFactorBracketId
	}
}
`;

export const deleteAgentEclLoadFactorBracket = gql`
mutation DeleteAgentEclLoadFactorBracket($agentId: String!, $agentEclLoadFactorBracket: AgentEclLoadFactorBracketInput!) {
	deleteAgentEclLoadFactorBracket(agentId: $agentId, agentEclLoadFactorBracket: $agentEclLoadFactorBracket) {
		agentId
		eclLoadFactorBracketId
	}
}
`;

export const createAgentEmailPreference = gql`
mutation CreateAgentEmailPreference($agentId: String!, $agentEmailPreference: AgentEmailPreferenceInput!) {
	createAgentEmailPreference(agentId: $agentId, agentEmailPreference: $agentEmailPreference) {
		agentId
		emailPreferenceId
	}
}
`;

export const deleteAgentEmailPreference = gql`
mutation DeleteAgentEmailPreference($agentId: String!, $agentEmailPreference: AgentEmailPreferenceInput!) {
	deleteAgentEmailPreference(agentId: $agentId, agentEmailPreference: $agentEmailPreference) {
		agentId
		emailPreferenceId
		emailPreference {
			name
		}
	}
}
`;

export const createCustomerEmailPreference = gql`
mutation CreateCustomerEmailPreference($customerId: String!, $customerEmailPreference: CustomerEmailPreferenceInput!) {
	createCustomerEmailPreference(customerId: $customerId, customerEmailPreference: $customerEmailPreference) {
		customerId
		emailPreferenceId
	}
}
`;

export const deleteCustomerEmailPreference = gql`
mutation DeleteCustomerEmailPreference($customerId: String!, $customerEmailPreference: CustomerEmailPreferenceInput!) {
	deleteCustomerEmailPreference(customerId: $customerId, customerEmailPreference: $customerEmailPreference) {
		customerId
		emailPreferenceId
		emailPreference {
			name
		}
	}
}
`;

export const createDisclaimer = gql`
mutation CreateDisclaimer($disclaimer: DisclaimerInput!) {
	createDisclaimer(disclaimer: $disclaimer) {
		disclaimerId
	}
}
`;

export const updateDisclaimer = gql`
mutation UpdateDisclaimer($disclaimerId: Int!, $disclaimer: DisclaimerInput!) {
	updateDisclaimer(disclaimerId: $disclaimerId, disclaimer: $disclaimer) {
		disclaimerId
	}
}
`;

export const createRateMatrixLoader = gql`
mutation CreateRateMatrixLoader($rateMatrixLoader: RateMatrixLoaderInput!) {
	createRateMatrixLoader(rateMatrixLoader: $rateMatrixLoader) {
		id
	}
}
`;

export const updateRateMatrixLoader = gql`
mutation UpdateRateMatrixLoader($id: Int!, $rateMatrixLoader: RateMatrixLoaderInput!) {
	updateRateMatrixLoader(id: $id, rateMatrixLoader: $rateMatrixLoader) {
		id
	}
}
`;

export const createEdiUtility = gql`
mutation CreateEdiUtility($ediUtility: EdiUtilityInput!) {
	createEdiUtility(ediUtility: $ediUtility) {
		id
	}
}
`;

export const updateEdiUtility = gql`
mutation UpdateEdiUtility($id: Int!, $ediUtility: EdiUtilityInput!) {
	updateEdiUtility(id: $id, ediUtility: $ediUtility) {
		id
	}
}
`;

export const createUtilityWebPortal = gql`
mutation CreateUtilityWebPortal($utilityWebPortal: UtilityWebPortalInput!) {
	createUtilityWebPortal(utilityWebPortal: $utilityWebPortal) {
		id
	}
}
`;

export const updateUtilityWebPortal = gql`
mutation UpdateUtilityWebPortal($id: Int!, $utilityWebPortal: UtilityWebPortalInput!) {
	updateUtilityWebPortal(id: $id, utilityWebPortal: $utilityWebPortal) {
		id
	}
}
`;

export const convertContractToResidential = gql`
mutation ConvertContractToResidential($contractId: String!) {
	convertContractToResidential(contractId: $contractId) {
		contractId
	}
}
`;

export const updateAudit = gql`
mutation UpdateAudit($id: Int!, $audit: AuditInput!) {
	updateAudit(id: $id, audit: $audit) {
		id
	}
}
`;

export const updateFlowCheck = gql`
mutation UpdateFlowCheck($id: Int!, $flowCheck: FlowCheckInput!) {
	updateFlowCheck(id: $id, flowCheck: $flowCheck) {
		id
	}
}
`;

export const createFlowCheck = gql`
mutation CreateFlowCheck($flowCheck: FlowCheckInput!) {
	createFlowCheck(flowCheck: $flowCheck) {
		id
	}
}
`;

export const sendFlowCheckEmail = gql`
mutation SendFlowCheckEmail($id: Int!) {
	sendFlowCheckEmail(id: $id) {
		response
	}
}
`;

export const sendAuditEmail = gql`
mutation SendAuditEmail($id: Int!, $supplierId: String!) {
	sendAuditEmail(id: $id, supplierId: $supplierId) {
		response
	}
}
`;

export const updateContractCommissionPayPlan = gql`
mutation UpdateContractCommissionPayPlan($contractId: String!, $commissionPayPlanId: Int!) {
	updateContractCommissionPayPlan(contractId: $contractId, commissionPayPlanId: $commissionPayPlanId) {
		contractId
	}
}
`;

/* eslint-disable */
export const updateContractPurchasingLayerPurchaseHistories = gql`
mutation UpdateContractPurchasingLayerPurchaseHistories($contractId: String!, $purchasingLayerPurchaseHistories: ContractPurchasingLayerPurchaseHistoriesInput!) {
	updateContractPurchasingLayerPurchaseHistories(contractId: $contractId, purchasingLayerPurchaseHistories: $purchasingLayerPurchaseHistories) {
		contractId
	}
}
`;
/* eslint-enable */

export const updateContractUsePurchasingLayer = gql`
mutation UpdateContractUsePurchasingLayer($contractId: String!, $usePurchasingLayer: Boolean!) {
	updateContractUsePurchasingLayer(contractId: $contractId, usePurchasingLayer: $usePurchasingLayer) {
		contractId
	}
}
`;

export const sendPurchasingLayerShareEmail = gql`
mutation SendPurchasingLayerShareEmail($id: Int!, $email: String!) {
	sendPurchasingLayerShareEmail(id: $id, email: $email)
}
`;

export const sendContractPerformanceShareEmail = gql`
mutation SendContractPerformanceShareEmail($contractId: String!, $email: String!) {
	sendContractPerformanceShareEmail(contractId: $contractId, email: $email)
}
`;

export const requestUsage = gql`
mutation RequestUsage($contractLocationId: String!) {
	requestUsage(contractLocationId: $contractLocationId) {
		response
	}
}
`;

export const updateCommission = gql`
mutation UpdateCommission($commissionId: String!, $commission: CommissionInput) {
	updateCommission(commissionId: $commissionId, commission: $commission) {
		commissionId
		commissionPayableStatusTypeId
		commissionPayableStatusTypeName
		payableIssues {
			id
			name
			description
			payableIssueTypeId
			payableIssueType {
				id
				name
				description
			}
		}
	}
}
`;

export const createCommission = gql`
mutation CreateCommission($commission: CommissionInput) {
	createCommission(commission: $commission) {
		commissionId
	}
}
`;

export const createFaqAudit = gql`
mutation CreateFaqAudit($faqAudit: FaqAuditInput!) {
	createFaqAudit(faqAudit: $faqAudit) {
		id
	}
}
`;

export const createFaqAuditArticle = gql`
mutation CreateFaqAuditArticle($id: Int!, $faqAuditArticle: FaqAuditArticleInput!) {
	createFaqAuditArticle(id: $id, faqAuditArticle: $faqAuditArticle) {
		faqId
	}
}
`;

export const createEducationAudit = gql`
mutation CreateEducationAudit($educationAudit: EducationAuditInput!) {
	createEducationAudit(educationAudit: $educationAudit) {
		id
	}
}
`;

export const createEducationAuditArticle = gql`
mutation CreateEducationAuditArticle($id: Int!, $educationAuditArticle: EducationAuditArticleInput!) {
	createEducationAuditArticle(id: $id, educationAuditArticle: $educationAuditArticle) {
		educationId
	}
}
`;

export const updateSupplierCommissionMap = gql`
mutation UpdateSupplierCommissionMap($id: Int!, $supplierCommissionMap: SupplierCommissionMapInput!) {
	updateSupplierCommissionMap(id: $id, supplierCommissionMap: $supplierCommissionMap) {
		id
	}
}
`;

export const deleteSupplierCommissionMap = gql`
mutation DeleteSupplierCommissionMap($id: Int!) {
	deleteSupplierCommissionMap(id: $id) {
		id
	}
}
`;

export const updateSupplierCommissionTypeMap = gql`
mutation UpdateSupplierCommissionTypeMap($id: Int!, $supplierCommissionTypeMap: SupplierCommissionTypeMapInput!) {
	updateSupplierCommissionTypeMap(id: $id, supplierCommissionTypeMap: $supplierCommissionTypeMap) {
		id
	}
}
`;

export const updateSupplierCommissionAttachment = gql`
mutation UpdateSupplierCommissionAttachment($id: Int!, $supplierCommissionAttachment: SupplierCommissionAttachmentInput!) {
	updateSupplierCommissionAttachment(id: $id, supplierCommissionAttachment: $supplierCommissionAttachment) {
		id
	}
}
`;

export const approveSupplierCommissionAttachmentDeposits = gql`
	mutation ApproveSupplierCommissionAttachmentDeposits($supplierCommissionAttachmentId: Int!) {
		approveSupplierCommissionAttachmentDeposits(supplierCommissionAttachmentId: $supplierCommissionAttachmentId) {
			id
		}
	}
`;

/* eslint-disable max-len */
export const bulkUpsertSupplierCommissionAttachmentDeposits = gql`
	mutation BulkUpsertSupplierCommissionAttachmentDeposits($removeCriteria: SupplierCommissionAttachmentDepositInput, $supplierCommissionAttachmentDeposits: [SupplierCommissionAttachmentDepositInput]) {
		bulkUpsertSupplierCommissionAttachmentDeposits(removeCriteria: $removeCriteria, supplierCommissionAttachmentDeposits: $supplierCommissionAttachmentDeposits) {
			id
		}
	}
`;
/* eslint-enable max-len */

export const deleteRateMatrix = gql`
mutation DeleteRateMatrix($criteria: DeleteRateMatrixInput!) {
	deleteRateMatrix(criteria: $criteria)
}
`;

export const updateCommissionEstimate = gql`
mutation UpdateCommissionEstimate($id: Int!, $commissionEstimate: CommissionEstimateInput!) {
	updateCommissionEstimate(id: $id, commissionEstimate: $commissionEstimate) {
		id
	}
}
`;

export const updateUserSurvey = gql`
mutation UpdateUserSurvey($id: Int!, $userSurvey: UserSurveyInput!) {
	updateUserSurvey(id: $id, userSurvey: $userSurvey) {
		id
	}
}
`;

export const updateUserSurveySetting = gql`
mutation UpdateUserSurveySetting($id: Int!, $userSurveySetting: UserSurveySettingInput!) {
	updateUserSurveySetting(id: $id, userSurveySetting: $userSurveySetting) {
		id
	}
}
`;

export const createSupplierCommissionMap = gql`
mutation CreateSupplierCommissionMap($supplierCommissionMap: SupplierCommissionMapInput!) {
	createSupplierCommissionMap(supplierCommissionMap: $supplierCommissionMap) {
		id
	}
}
`;

export const createCommissionEstimate = gql`
mutation CreateCommissionEstimate($commissionEstimate: CommissionEstimateInput!) {
	createCommissionEstimate(commissionEstimate: $commissionEstimate) {
		id
	}
}
`;

export const deleteCommissionEstimate = gql`
mutation DeleteCommissionEstimate($id: Int!) {
	deleteCommissionEstimate(id: $id) {
		id
	}
}
`;

export const createUserSurvey = gql`
mutation CreateUserSurvey($userSurvey: UserSurveyInput!) {
	createUserSurvey(userSurvey: $userSurvey) {
		id
	}
}
`;

export const createUserAudit = gql`
mutation CreateUserAudit($userAudit: UserAuditInput!) {
	createUserAudit(userAudit: $userAudit) {
		id
	}
}
`;

export const createUserAuditType = gql`
mutation CreateUserAuditType($userAuditType: UserAuditTypeInput!) {
	createUserAuditType(userAuditType: $userAuditType) {
		id
	}
}
`;

export const updateUserAuditType = gql`
mutation UpdateUserAuditType($id: Int!, $userAuditType: UserAuditTypeInput!) {
	updateUserAuditType(id: $id, userAuditType: $userAuditType) {
		id
	}
}
`;

export const createMarginChangeReasonType = gql`
mutation CreateMarginChangeReasonType($marginChangeReasonType: MarginChangeReasonTypeInput!) {
	createMarginChangeReasonType(marginChangeReasonType: $marginChangeReasonType) {
		id
	}
}
`;

export const updateMarginChangeReasonType = gql`
mutation UpdateMarginChangeReasonType($id: Int!, $marginChangeReasonType: MarginChangeReasonTypeInput!) {
	updateMarginChangeReasonType(id: $id, marginChangeReasonType: $marginChangeReasonType) {
		id
	}
}
`;

export const sendInvoiceEmail = gql`
mutation SendInvoiceEmail($criteria: InvoiceEmailInput!) {
	sendInvoiceEmail(criteria: $criteria) {
		response
	}
}
`;

export const updateAgentEcl = gql`
mutation UpdateAgentEcl($agentId: String!, $agentEcl: AgentEclInput!) {
	updateAgentEcl(agentId: $agentId, agentEcl: $agentEcl) {
		agentId
	}
}
`;

export const createAgentEclPackage = gql`
mutation CreateAgentEclPackage($agentId: String!, $agentEclPackage: AgentEclPackageInput!) {
	createAgentEclPackage(agentId: $agentId, agentEclPackage: $agentEclPackage) {
		agent {
			eclPackageDiscountExpirationDate
		}
	}
}
`;

export const createRejectReasonCategory = gql`
mutation CreateRejectReasonCategory($rejectReasonCategory: RejectReasonCategoryInput!) {
	createRejectReasonCategory(rejectReasonCategory: $rejectReasonCategory) {
		id
	}
}
`;

export const updateRejectReasonCategory = gql`
mutation UpdateRejectReasonCategory($id: Int!, $rejectReasonCategory: RejectReasonCategoryInput!) {
	updateRejectReasonCategory(id: $id, rejectReasonCategory: $rejectReasonCategory) {
		id
	}
}
`;

export const supplierConfirmResidentialContract = gql`
mutation SupplierConfirmResidentialContract($supplierId: String!, $criteria: SupplierConfirmResidentialContractInput!) {
	supplierConfirmResidentialContract(supplierId: $supplierId, criteria: $criteria) {
		contractId
		status
	}
}
`;

export const supplierRejectResidentialContract = gql`
mutation SupplierRejectResidentialContract($supplierId: String!, $criteria: SupplierRejectResidentialContractInput!) {
	supplierRejectResidentialContract(supplierId: $supplierId, criteria: $criteria) {
		contractId
		status
	}
}
`;

export const supplierDropResidentialContract = gql`
mutation SupplierDropResidentialContract($supplierId: String!, $criteria: SupplierDropResidentialContractInput!) {
	supplierDropResidentialContract(supplierId: $supplierId, criteria: $criteria) {
		contractId
		status
	}
}
`;

export const updateSupplierAuctionMonitorEmail = gql`
mutation UpdateSupplierAuctionMonitorEmail($id: Int!, $supplierAuctionMonitorEmail: SupplierAuctionMonitorEmailInput!) {
	updateSupplierAuctionMonitorEmail(id: $id, supplierAuctionMonitorEmail: $supplierAuctionMonitorEmail) {
		id
	}
}
`;

export const updateServiceTypeUnit = gql`
mutation UpdateServiceTypeUnit($id: Int!, $serviceTypeUnit: ServiceTypeUnitInput!) {
	updateServiceTypeUnit(id: $id, serviceTypeUnit: $serviceTypeUnit) {
		id
	}
}
`;

export const deleteServiceTypeUnit = gql`
mutation DeleteServiceTypeUnit($id: Int!) {
	deleteServiceTypeUnit(id: $id) {
		id
	}
}
`;

export const createServiceTypeUnit = gql`
mutation CreateServiceTypeUnit($serviceTypeUnit: ServiceTypeUnitInput!) {
	createServiceTypeUnit(serviceTypeUnit: $serviceTypeUnit) {
		id
	}
}
`;

export const sendWelcomeEmail = gql`
mutation SendWelcomeEmail($userId: String!) {
	sendWelcomeEmail(userId: $userId) {
		response
	}
}
`;

export const createUserRfqSetting = gql`
mutation CreateUserRfqSetting($userRfqSetting: UserRfqSettingInput!) {
	createUserRfqSetting(userRfqSetting: $userRfqSetting) {
		id
		serviceTypeId
		utilityId
		stateId
		userId
		supplierId
		minUsage
		maxUsage
		isActive
		isPaused
		pauseExpirationDate
		reviewedDate
		serviceType {
			name
			units
		}
		state {
			stateLong
		}
		utility {
			name
		}
	}
}
`;

export const updateUserRfqSetting = gql`
mutation UpdateUserRfqSetting($id: Int!, $userRfqSetting: UserRfqSettingInput!) {
	updateUserRfqSetting(id: $id, userRfqSetting: $userRfqSetting) {
		id
		serviceTypeId
		utilityId
		stateId
		userId
		supplierId
		minUsage
		maxUsage
		isActive
		isPaused
		pauseExpirationDate
		reviewedDate
		serviceType {
			name
			units
		}
		state {
			stateLong
		}
		utility {
			name
		}
	}
}
`;

export const updateUserRfqSettings = gql`
mutation UpdateUserRfqSetting($userRfqSettings: [UserRfqSettingInput!]!) {
	updateUserRfqSettings(userRfqSettings: $userRfqSettings) {
		id
	}
}
`;

export const deleteUserRfqSetting = gql`
mutation DeleteUserRfqSetting($id: Int!) {
	deleteUserRfqSetting(id: $id) {
		id
	}
}
`;

export const deleteUserRfqSettings = gql`
mutation DeleteUserRfqSettings($id: String!) {
	deleteUserRfqSettings(id: $id) {
		id
	}
}
`;

export const createCompanySupplier = gql`
mutation CreateCompanySupplier($companySupplier: CompanySupplierInput!) {
	createCompanySupplier(companySupplier: $companySupplier) {
		id
	}
}
`;

export const updateCompanySupplier = gql`
mutation UpdateCompanySupplier($id: Int!, $companySupplier: CompanySupplierInput!) {
	updateCompanySupplier(id: $id, companySupplier: $companySupplier) {
		id
	}
}
`;

export const processPaymentConfirmation = gql`
query ProcessPaymentConfirmation($contractId: String!, $confirmationNum: String!) {
	processPaymentConfirmation(contractId: $contractId, confirmationNum: $confirmationNum) {
		contractId
		contractNum
		depositPaidAmount
		supplier {
			name
		}
	}
}
`;

export const deleteContractLocationForecastOverrides = gql`
mutation DeleteContractLocationForecastOverrides($contractLocationId: String!) {
	deleteContractLocationForecastOverrides(contractLocationId: $contractLocationId) {
		id
	}
}
`;

export const createJob = gql`
	mutation CreateJob($job: JobInput!) {
		createJob(job: $job) {
			id
		}
	}
`;

export const updateJob = gql`
	mutation UpdateJob($id: Int!, $job: JobInput!) {
		updateJob(id: $id, job: $job) {
			id
		}
	}
`;

export const runJob = gql`
	mutation RunJob($id: Int!) {
		runJob(id: $id)
	}
`;

export const createPendingCommissionTimeLog = gql`
	mutation CreatePendingCommissionTimeLog($pendingCommissionTimeLog: PendingCommissionTimeLogInput!) {
		createPendingCommissionTimeLog(pendingCommissionTimeLog: $pendingCommissionTimeLog) {
			id
			startDate
			endDate
		}
	}
`;

export const updatePendingCommissionTimeLog = gql`
	mutation UpdatePendingCommissionTimeLog($id: Int!, $pendingCommissionTimeLog: PendingCommissionTimeLogInput!) {
		updatePendingCommissionTimeLog(id: $id, pendingCommissionTimeLog: $pendingCommissionTimeLog) {
			id
			startDate
			endDate
		}
	}
`;

export const createDeliveryType = gql`
	mutation CreateDeliveryType($deliveryType: DeliveryTypeInput!) {
		createDeliveryType(deliveryType: $deliveryType) {
			id
		}
	}
`;

export const updateDeliveryType = gql`
	mutation UpdateDeliveryType($id: Int!, $deliveryType: DeliveryTypeInput!) {
		updateDeliveryType(id: $id, deliveryType: $deliveryType) {
			id
		}
	}
`;

export const markAsPaid = gql`
	mutation MarkAsPaid($commissionId: String, $accountAdjustmentId: String) {
		markAsPaid(commissionId: $commissionId, accountAdjustmentId: $accountAdjustmentId) {
			response
		}
	}
`;

export const overrideAgentCommissionPayPlanOption = gql`
	mutation OverrideAgentCommissionPayPlanOption($id: Int!,
		$agentCommissionPayPlanOptionOverride: AgentCommissionPayPlanOptionOverrideInput!) {
		overrideAgentCommissionPayPlanOption(id: $id, agentCommissionPayPlanOptionOverride: $agentCommissionPayPlanOptionOverride) {
			id
		}
	}
`;

export const bulkOverrideAgentCommissionPayPlanOptions = gql`
	mutation BulkOverrideAgentCommissionPayPlanOptions($agentId: String!, $bulkOverrideRateParameters: BulkOverrideRateParametersInput!) {
		bulkOverrideAgentCommissionPayPlanOptions(agentId: $agentId, bulkOverrideRateParameters: $bulkOverrideRateParameters) {
			response
		}
	}
`;

export const revertAgentCommissionPayPlanOptionOverride = gql`
	mutation RevertAgentCommissionPayPlanOptionOverride($id: Int!) {
		revertAgentCommissionPayPlanOptionOverride(id: $id) {
			id
			rate
		}
	}
`;

export const updateCommissionPayable = gql`
mutation UpdateCommissionPayable($id: Int!, $commissionPayable: CommissionPayableInput) {
	updateCommissionPayable(id: $id, commissionPayable: $commissionPayable) {
		id
		commissionPayableStatusTypeId
		commissionPayableStatusTypeName
		payableIssues {
			id
			name
			description
			payableIssueTypeId
			payableIssueType {
				id
				name
				description
			}
		}
	}
}
`;

export const updateContractCommissionPayables = gql`
	mutation UpdateContractCommissionPayables($contractId: String!, $months: [CommissionPayableMonthInput!]!) {
		updateContractCommissionPayables(contractId: $contractId, months: $months) {
			id
			amount
			usage
			cycleStartDate
			cycleEndDate
		}
	}
`;

export const createSupplierAllowedCommissionPayPlanType = gql`
	mutation CreateSupplierAllowedCommissionPayPlanType($supplierAllowedCommissionPayPlanType: SupplierAllowedCommissionPayPlanTypeInput!) {
		createSupplierAllowedCommissionPayPlanType(supplierAllowedCommissionPayPlanType: $supplierAllowedCommissionPayPlanType) {
			id
		}
	}
`;

export const deleteSupplierAllowedCommissionPayPlanType = gql`
	mutation DeleteSupplierAllowedCommissionPayPlanType($id: Int!) {
		deleteSupplierAllowedCommissionPayPlanType(id: $id) {
			id
		}
	}
`;

export const createAgentAllowedCommissionPayPlanType = gql`
	mutation CreateAgentAllowedCommissionPayPlanType($agentAllowedCommissionPayPlanType: AgentAllowedCommissionPayPlanTypeInput!) {
		createAgentAllowedCommissionPayPlanType(agentAllowedCommissionPayPlanType: $agentAllowedCommissionPayPlanType) {
			id
		}
	}
`;

export const deleteAgentAllowedCommissionPayPlanType = gql`
	mutation DeleteAgentAllowedCommissionPayPlanType($id: Int!) {
		deleteAgentAllowedCommissionPayPlanType(id: $id) {
			id
		}
	}
`;

export const updateAgentEmailPreferenceSetting = gql`
mutation UpdateAgentEmailPreferenceSetting($id: Int!, $agentEmailPreferenceSetting: AgentEmailPreferenceSettingInput!) {
	updateAgentEmailPreferenceSetting(id: $id, agentEmailPreferenceSetting: $agentEmailPreferenceSetting) {
		id
	}
}
`;

export const updateAgentEmailSections = gql`
mutation UpdateAgentEmailSections($agentEmailSections: [AgentEmailSectionInput!]!) {
	updateAgentEmailSections(agentEmailSections: $agentEmailSections)
}
`;

export const convertAgentCommissionPayPlans = gql`
mutation ConvertAgentCommissionPayPlans($agentId: String!, $overrideCommissionPayPlanTypeId: Int) {
	convertAgentCommissionPayPlans(agentId: $agentId, overrideCommissionPayPlanTypeId: $overrideCommissionPayPlanTypeId) {
		response
	}
}
`;

export const postProcessingCommissionAuditsRefresh = gql`
mutation PostProcessingCommissionAuditsRefresh($addMonthDate: String!, $companyId: String!) {
	postProcessingCommissionAuditsRefresh(addMonthDate: $addMonthDate, companyId: $companyId) {
		response
	}
}
`;

export const deleteCommission = gql`
	mutation DeleteCommission($commissionId: String!) {
		deleteCommission(commissionId: $commissionId) {
			commissionId
		}
	}
`;

export const createBankAccount = gql`
	mutation CreateBankAccount($bankAccount: BankAccountInput!) {
		createBankAccount(bankAccount: $bankAccount) {
			id
		}
	}
`;

export const sendBrokerReferralEmail = gql`
mutation SendBrokerReferralEmail($criteria: BrokerReferralEmailInput!) {
	sendBrokerReferralEmail(criteria: $criteria) {
		response
	}
}
`;

export const sendAnnualMembershipEmail = gql`
mutation SendAnnualMembershipEmail {
	sendAnnualMembershipEmail {
		response
	}
}
`;

export const sendLeadLinkEmail = gql`
mutation SendLeadLinkEmail {
	sendLeadLinkEmail {
		response
	}
}
`;

export const sendCustomerReferralEmail = gql`
mutation SendCustomerReferralEmail($criteria: CustomerReferralsInput!) {
	sendCustomerReferralEmail(criteria: $criteria) {
		response
	}
}
`;

export const updateContractResidentialExternalId = gql`
	mutation UpdateContractResidentialExternalId($contractId: String!, $externalId: String!) {
		updateContractResidentialExternalId(contractId: $contractId, externalId: $externalId) {
			contractId
			externalStatus
		}
	}
`;

export const refreshContractResidentialExternalStatus = gql`
	mutation RefreshContractResidentialExternalStatus($contractId: String!) {
		refreshContractResidentialExternalStatus(contractId: $contractId) {
			contractId
			externalStatus
		}
	}
`;

export const bulkUpsertSupplierRateSettings = gql`
	mutation BulkUpsertSupplierRateSettings($supplierRateSetting: SupplierRateSettingInput!) {
		bulkUpsertSupplierRateSettings(supplierRateSetting: $supplierRateSetting) {
			response
		}
	}
`;

export const createEmailCriteriaType = gql`
	mutation CreateEmailCriteriaType($emailCriteriaType: EmailCriteriaTypeInput!) {
		createEmailCriteriaType(emailCriteriaType: $emailCriteriaType) {
			id
		}
	}
`;

export const updateEmailCriteriaType = gql`
	mutation UpdateEmailCriteriaType($id: Int!, $emailCriteriaType: EmailCriteriaTypeInput!) {
		updateEmailCriteriaType(id: $id, emailCriteriaType: $emailCriteriaType) {
			id
		}
	}
`;

export const updateAgentEngagementEvent = gql`
	mutation UpdateAgentEngagementEvent($id: Int!, $agentEngagementEvent: AgentEngagementEventInput!) {
		updateAgentEngagementEvent(id: $id, agentEngagementEvent: $agentEngagementEvent) {
			id
		}
	}
`;

export const updateAgentEngagementEvents = gql`
	mutation UpdateAgentEngagementEvents($agentEngagementEvents: [AgentEngagementEventsInput!]!) {
		updateAgentEngagementEvents(agentEngagementEvents: $agentEngagementEvents) {
			id
		}
	}
`;

export const updatePendingFlowCheck = gql`
	${fragments.VIEW_PENDING_FLOW_CHECK_FIELDS}
	mutation UpdatePendingFlowCheck($id: Int!, $pendingFlowCheck: PendingFlowCheckInput!) {
		updatePendingFlowCheck(id: $id, pendingFlowCheck: $pendingFlowCheck) {
			id
			viewPendingFlowCheck {
				...ViewPendingFlowCheckFields
			}
		}
	}
`;

export const updatePendingFlowChecks = gql`
	mutation UpdatePendingFlowChecks($pendingFlowChecks: [PendingFlowCheckInput!]!) {
		updatePendingFlowChecks(pendingFlowChecks: $pendingFlowChecks) {
			id
		}
	}
`;

export const confirmEnrollmentAfterDocusign = gql`
	mutation ConfirmEnrollmentAfterDocusign($contractId: String!) {
		confirmEnrollmentAfterDocusign(contractId: $contractId) {
			response
		}
	}
`;

export const createCompanyRateMatrixLoader = gql`
	mutation CreateCompanyRateMatrixLoader($companyRateMatrixLoader: CompanyRateMatrixLoaderInput!) {
		createCompanyRateMatrixLoader(companyRateMatrixLoader: $companyRateMatrixLoader) {
			id
		}
	}
`;

export const updateCompanyRateMatrixLoader = gql`
	mutation UpdateCompanyRateMatrixLoader($id: Int!, $companyRateMatrixLoader: CompanyRateMatrixLoaderInput!) {
		updateCompanyRateMatrixLoader(id: $id, companyRateMatrixLoader: $companyRateMatrixLoader) {
			id
		}
	}
`;

export const createLead = gql`
	mutation CreateLead($lead: LeadInput!) {
		createLead(lead: $lead) {
			id
		}
	}
`;

export const createLeads = gql`
	mutation CreateLeads($leads: [LeadInput!]!) {
		createLeads(leads: $leads) {
			response
		}
	}
`;

export const updateLeads = gql`
	mutation UpdateLeads($leads: [LeadInput!]!, $notifyAgents: Boolean) {
		updateLeads(leads: $leads, notifyAgents: $notifyAgents) {
			id
		}
	}
`;

export const updateLead = gql`
	mutation UpdateLead($id: Int!, $lead: LeadInput!) {
		updateLead(id: $id, lead: $lead) {
			id
			isIceBoxed
		}
	}
`;

export const deleteLead = gql`
	mutation DeleteLead($id: Int!) {
		deleteLead(id: $id) {
			id
		}
	}
`;

export const deleteLeads = gql`
	mutation DeleteLeads($id: IntString!) {
		deleteLeads(id: $id) {
			response
		}
	}
`;

export const updateLeadReminder = gql`
	mutation UpdateLeadReminder($id: Int!, $leadReminder: LeadReminderInput!) {
		updateLeadReminder(id: $id, leadReminder: $leadReminder) {
			id
			message
			details
			date
			addDate
		}
	}
`;

export const updateLeadDuplicates = gql`
	mutation UpdateLeadDuplicates($leadDuplicates: [LeadDuplicateInput!]!) {
		updateLeadDuplicates(leadDuplicates: $leadDuplicates) {
			id
		}
	}
`;

export const unsubscribeEmailAnalytics = gql`
	mutation UnsubscribeEmailAnalytics($apiId: Int!) {
		unsubscribeEmailAnalytics(apiId: $apiId) {
			id
		}
	}
`;

export const createAgentEngagementEventBufferMarginModification = gql`
	mutation CreateAgentEngagementEventBufferMarginModification(
		$agentEngagementEventBufferMarginModification: AgentEngagementEventBufferMarginModificationInput!
	) {
		createAgentEngagementEventBufferMarginModification(
			agentEngagementEventBufferMarginModification: $agentEngagementEventBufferMarginModification
		) {
			id
		}
	}
`;

export const createAgentEngagementEventDismissPostpone = gql`
	mutation CreateAgentEngagementEventDismissPostpone($agentEngagementEventDismissPostpone: AgentEngagementEventDismissPostponeInput!) {
		createAgentEngagementEventDismissPostpone(agentEngagementEventDismissPostpone: $agentEngagementEventDismissPostpone) {
			id
		}
	}
`;

export const createLeadStatusReasonType = gql`
	mutation CreateLeadStatusReasonType($leadStatusReasonType: LeadStatusReasonTypeInput!) {
		createLeadStatusReasonType(leadStatusReasonType: $leadStatusReasonType) {
			id
		}
	}
`;

export const createLeadAction = gql`
	mutation CreateLeadAction($leadId: Int!, $leadAction: LeadActionInput!) {
		createLeadAction(leadId: $leadId, leadAction: $leadAction) {
			id
			viewLead {
				leadStatusId
				leadStatusName
				cssClass
			}
		}
	}
`;

export const createAgentEngagementEventAction = gql`
	mutation CreateAgentEngagementEventAction(
		$agentEngagementEventId: Int!, $agentEngagementEventAction: AgentEngagementEventActionInput!
	) {
		createAgentEngagementEventAction(
			agentEngagementEventId: $agentEngagementEventId, agentEngagementEventAction: $agentEngagementEventAction
		) {
			id
			noteTypeId
			message
		}
	}
`;

export const createPurchasingLayerAction = gql`
	mutation CreatePurchasingLayerAction(
		$purchasingLayerId: Int!, $purchasingLayerAction: PurchasingLayerActionInput!
	) {
		createPurchasingLayerAction(
			purchasingLayerId: $purchasingLayerId, purchasingLayerAction: $purchasingLayerAction
		) {
			id
			noteTypeId
			message
		}
	}
`;

export const updateLeadEmailSections = gql`
mutation UpdateLeadEmailSections($leadEmailSections: [LeadEmailSectionInput!]!) {
	updateLeadEmailSections(leadEmailSections: $leadEmailSections) {
		id
		leadId
		emailSectionId
	}
}
`;

export const createLeadEmailCampaign = gql`
mutation CreateLeadEmailCampaign($leadEmailCampaign: LeadEmailCampaignInput) {
	createLeadEmailCampaign(leadEmailCampaign: $leadEmailCampaign) {
		id
	}
}
`;

export const deleteLeadEmailPreference = gql`
mutation DeleteLeadEmailPreference($id: Int!, $leadEmailPreference: LeadEmailPreferenceInput!) {
	deleteLeadEmailPreference(id: $id, leadEmailPreference: $leadEmailPreference) {
		leadId
		emailPreferenceId
		emailPreference {
			name
		}
	}
}
`;

export const updateLeadEmailCampaign = gql`
mutation UpdateLeadEmailCampaign($id: Int!, $leadEmailCampaign: LeadEmailCampaignInput) {
	updateLeadEmailCampaign(id: $id, leadEmailCampaign: $leadEmailCampaign) {
		id
	}
}
`;

export const createTinyMceCategory = gql`
mutation CreateTinyMceCategory($category: TinyMceCategoryInput) {
	createTinyMceCategory(category: $category) {
		id
	}
}
`;

export const updateTinyMceCategory = gql`
mutation UpdateTinyMceCategory($id: IntString!, $category: TinyMceCategoryInput) {
	updateTinyMceCategory(id: $id, category: $category) {
		id
		title
	}
}
`;

export const deleteTinyMceCategory = gql`
mutation DeleteTinyMceCategory($id: IntString!) {
	deleteTinyMceCategory(id: $id) {
		id
	}
}
`;

export const createTinyMceTemplate = gql`
mutation CreateTinyMceTemplate($template: TinyMceTemplateInput) {
	createTinyMceTemplate(template: $template) {
		id
	}
}
`;

export const updateTinyMceTemplate = gql`
mutation UpdateTinyMceTemplate($id: IntString!, $template: TinyMceTemplateInput) {
	updateTinyMceTemplate(id: $id, template: $template) {
		id
		title
		content
	}
}
`;

export const deleteTinyMceTemplate = gql`
mutation DeleteTinyMceTemplate($id: IntString!) {
	deleteTinyMceTemplate(id: $id) {
		id
	}
}
`;

export const updateTinyMceTemplatesByCategory = gql`
mutation UpdateTinyMceTemplate($template: TinyMceTemplateByCategoryInput) {
	updateTinyMceTemplatesByCategory(template: $template) {
		id
		categoryId
	}
}
`;

export const chooseMarketSuggestion = gql`
mutation ChooseMarketSuggestion($rfqSessionId: String!, $chooseMarketSuggestion: ChooseMarketSuggestionInput!) {
	chooseMarketSuggestion(rfqSessionId: $rfqSessionId, chooseMarketSuggestion: $chooseMarketSuggestion) {
		response
	}
}
`;

export const updateContractUsageOverrides = gql`
mutation UpdateContractUsageOverrides($contractId: String!, $usageOverrides: [ContractUsageOverrideInput!]!) {
	updateContractUsageOverrides(contractId: $contractId, usageOverrides: $usageOverrides) {
		date
	}
}
`;

export const updateContractBudgetDetail = gql`
mutation UpdateContractBudgetDetail($contractId: String!, $budgetDetail: ContractBudgetDetailInput!) {
	updateContractBudgetDetail(contractId: $contractId, budgetDetail: $budgetDetail) {
		monthlyUsage
	}
}
`;

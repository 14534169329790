import { Pipe, PipeTransform } from '@angular/core';

import { ContractLocationStatusReasonType } from '../models';

@Pipe({
	name: 'filterContractLocationStatusReasonTypes',
})
export class FilterContractLocationStatusReasonTypesPipe implements PipeTransform {

	transform(statusReasonTypes: ContractLocationStatusReasonType[], contractLocationStatus: number): ContractLocationStatusReasonType[] {
		if (!contractLocationStatus || !statusReasonTypes?.length) {
			return statusReasonTypes;
		}

		return statusReasonTypes.filter(s => s.contractLocationStatus === contractLocationStatus);
	}
}

<div dropdown #dropdown [insideClick]="insideClick" class="dropdown compact-select">
	<label *ngIf="label" class="compact-select-label">{{ label }}</label>
	<a
		dropdownToggle
		class="dropdown-toggle"
		[class.no-chevron]="hideChevron"
		href="javascript:void(0)"
		role="button"
		[ngClass]="dropdownToggleClasses">
		<ng-container *ngIf="!nameTemplate">{{ name }}</ng-container>
		<ng-container [ngTemplateOutlet]="nameTemplate"></ng-container>
	</a>
	<div *dropdownMenu #dropdownMenu class="dropdown-menu" [class.dropdown-menu-right]="alignRight(dropdown, dropdownMenu)">
		<ng-container [ngTemplateOutlet]="header"></ng-container>
		<div class="row scrolling-area">
			<div class="col container-column">
				<a *ngFor="let row of rows" (click)="onSelect(row)" class="dropdown-item" href="javascript:void(0)">
					<span (click)="$event.preventDefault()">
						<ng-container *ngIf="!rowRef">{{ row }}</ng-container>
						<ng-container [ngTemplateOutlet]="rowRef" [ngTemplateOutletContext]="{ $implicit: row }"></ng-container>
					</span>
				</a>
			</div>
		</div>
		<ng-container [ngTemplateOutlet]="footer"></ng-container>
	</div>
</div>

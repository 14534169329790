import { Pipe, PipeTransform } from '@angular/core';

import { MarketUpdateRateSummary, ServiceType } from '../models';

@Pipe({
	name: 'marketUpdateRateSummaryServiceTypeFilter'
})
export class MarketUpdateRateSummaryServiceTypeFilterPipe implements PipeTransform {
	transform(serviceTypes: ServiceType[], rateSummary: MarketUpdateRateSummary[]): ServiceType[] {
		return serviceTypes && rateSummary && rateSummary.length
			? serviceTypes.filter(s => rateSummary.some(r => r.serviceTypeId === s.serviceTypeId))
			: serviceTypes;
	}
}

import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { BsModalService } from 'ngx-bootstrap/modal';

import { HelperService } from 'src/app/shared/helper.service';
import { Contract } from 'src/app/shared/models';
import { ContractSelectorModalComponent } from './contract-selector-modal/contract-selector-modal.component';

@Component({
	selector: 'pk-broker-contract-selector',
	templateUrl: './contract-selector.component.html',
	styleUrls: ['./contract-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			useExisting: ContractSelectorComponent,
		},
	],
})
export class ContractSelectorComponent implements ControlValueAccessor {
	public contract: Contract = null;
	public inputId: string;

	public disabled = false;
	private touched = false;

	@Input()
	label = 'Contract';
	@Input()
	nullContractText = '-- None --';
	@Input()
	controlType: 'form' | 'filter' = 'form';
	@Input()
	initialValue: string;
	@Input()
	initialCriteria: any;

	constructor(
		private modalService: BsModalService,
	) {
		this.inputId = HelperService.getUniqueId();
	}

	onChange = (_contract: Contract) => { /* placeholder for interface */ };
	onTouched = () => { /* placeholder for interface */ };

	showLookupContract() {
		const modalRef = this.modalService.show(ContractSelectorModalComponent, {
			class: 'pk-modal modal-xl',
			initialState: {
				initialValue: this.initialValue,
				initialCriteria: this.initialCriteria,
			},
		});
		modalRef.content.onSubmit.subscribe((contract: Contract) => {
			this.markAsTouched();
			this.contract = contract;
			this.onChange(this.contract);
		});
	}

	public clearContract(): void {
		this.markAsTouched();
		this.contract = null;
		this.onChange(this.contract);
	}

	writeValue(contract: Contract) {
		this.contract = contract;
	}

	registerOnChange(onChange: (contract: Contract) => void) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: () => void) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}
}

<pk-broker-modal customBodyClass="p-3">
	<ng-template #headerTemplate>
		<h5 class="modal-title">
			<ng-container *ngIf="!loading">{{ title }}</ng-container>
			<ng-container *ngIf="loggedInUser && !loggedInUser.agentId && !loggedInUser.supplierId && agent">
				({{ agent.isActive ? 'Active' : 'Deactivated' }})
			</ng-container>
		</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<pk-broker-loader [show]="loading"></pk-broker-loader>
		<div class="container-fluid p-0" *ngIf="!loading">
			<div class="row">
				<div class="col-12 pb-2">
					<p>
						Time with Power Kiosk:
						<span class="semibold-text" *ngIf="stats?.timeSinceAddedYears || stats.timeSinceAddedYears === 0">
							{{ stats.timeSinceAddedYears }} year{{ stats.timeSinceAddedYears === 1 ? '' : 's' }}, {{ stats.timeSinceAddedMonths }} month{{
								stats.timeSinceAddedMonths === 1 ? '' : 's'
							}}
						</span>
					</p>
				</div>
			</div>
			<div class="row light-gray-bg" [ngClass]="{ 'pb-4': !totalAuctionsClosed }">
				<div class="col-12 pt-3">
					<p class="text-center font-size-085 semibold-text mb-0">
						This broker has closed
						<span class="semibold-text">{{ totalAuctionsClosed }} Pricing Session{{ totalAuctionsClosed === 1 ? '' : 's' }}</span> since
						{{ sinceDate.format('MMMM YYYY') }}
					</p>
				</div>
			</div>
			<div class="row light-gray-bg" *ngIf="totalAuctionsClosed">
				<div class="col-12 pb-sm-3">
					<pk-broker-agent-auction-info-modal-graph
						[elecAuctionsClosed]="getAuctionsClosed(CONSTANTS.serviceTypes.electricity.serviceTypeId)"
						[gasAuctionsClosed]="getAuctionsClosed(CONSTANTS.serviceTypes.naturalGas.serviceTypeId)">
					</pk-broker-agent-auction-info-modal-graph>
				</div>
			</div>
			<div class="row light-gray-bg">
				<div class="col-10 col-sm-6 col-md-5 offset-2 offset-sm-0 offset-md-1">
					<div class="row">
						<div class="col-auto commodity-icon">
							<img src="https://assets.powerkiosk.com/includes/images/electricity-icon-orange.svg" alt="Electricity" />
						</div>
						<div class="col pl-2">
							<p class="semibold-text orange-text mb-0">Electricity</p>
							<p class="font-size-085 semibold-text">
								{{ getAuctionsClosed(CONSTANTS.serviceTypes.electricity.serviceTypeId) }} Pricing Session{{
									getAuctionsClosed(CONSTANTS.serviceTypes.electricity.serviceTypeId) === 1 ? '' : 's'
								}}<sup class="medium-text">*</sup>
							</p>
						</div>
					</div>
				</div>
				<div class="col-10 col-sm-6 col-md-5 offset-2 offset-sm-0">
					<div class="row">
						<div class="col-auto commodity-icon pl-2">
							<img src="https://assets.powerkiosk.com/includes/images/gas-icon-blue.svg" alt="Natural Gas" />
						</div>
						<div class="col pl-2">
							<p class="semibold-text blue-text mb-0">Natural Gas</p>
							<p class="font-size-085 semibold-text">
								{{ getAuctionsClosed(CONSTANTS.serviceTypes.naturalGas.serviceTypeId) }} Pricing Session{{
									getAuctionsClosed(CONSTANTS.serviceTypes.naturalGas.serviceTypeId) === 1 ? '' : 's'
								}}<sup class="medium-text">*</sup>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="row light-gray-bg">
				<div class="col-10 col-sm-6 col-md-5 offset-2 offset-sm-0 offset-md-1 pl-5 pt-sm-2">
					<p class="semibold-text font-size-075 mb-0">Closing Ratio</p>
					<p class="font-size-1 semibold-text">{{ stats.closingRatio }}%<sup class="medium-text">*</sup></p>
				</div>
				<div class="col-10 col-sm-6 col-md-5 offset-2 offset-sm-0 pl-5 pt-sm-2" *ngIf="loggedInUser && !loggedInUser.supplierId">
					<p class="semibold-text font-size-075 mb-0">Awarded Not Closed</p>
					<p class="font-size-1 bold-text">{{ stats.contractsAwardedNotClosed }}<sup class="medium-text">*</sup></p>
				</div>
			</div>
			<ng-container *ngIf="loggedInUser && !loggedInUser.supplierId">
				<div class="row mt-sm-2">
					<div class="col-10 col-sm-5 col-md-4 offset-2 offset-sm-1 offset-md-2 pl-5 pl-sm-2">
						<p class="font-size-075 mb-0">Term Value Left on Flow</p>
						<p class="font-size-1 semibold-text">{{ stats.termValue | currency: 'USD':'symbol':'1.0-0' }}</p>
					</div>
					<div class="col-10 col-sm-6 offset-2 offset-sm-0 pl-5">
						<p class="font-size-075 mb-0">Net Profit</p>
						<p class="font-size-1 semibold-text">{{ stats.netProfit | currency: 'USD':'symbol':'1.0-0' }}<sup class="medium-text">*</sup></p>
					</div>
				</div>
				<div class="row mt-1 mt-sm-2">
					<div class="col-10 col-sm-5 col-md-4 offset-2 offset-sm-1 offset-md-2 pl-5 pl-sm-2">
						<p class="font-size-075 mb-0">Dropped Ratio</p>
						<p class="font-size-1 semibold-text">{{ stats.contractsDroppedRatio }}%<sup class="medium-text">*</sup></p>
					</div>
				</div>
			</ng-container>
			<div class="row">
				<div class="col-12">
					<p class="text-center font-size-075">* since {{ sinceDate.format('MMMM YYYY') }}</p>
				</div>
			</div>
		</div>
	</ng-template>
</pk-broker-modal>

import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { environment } from 'src/environments/environment';
import { PopUp } from '../../models';

@Component({
	selector: 'pk-broker-takeover-pop-up-modal',
	templateUrl: './takeover-pop-up-modal.component.html',
	styleUrls: ['./takeover-pop-up-modal.component.scss'],
})
export class TakeoverPopUpModalComponent {

	// inputs
	public popUp: Subset<PopUp>;

	public environment = environment;

	constructor(
		private activeModal: BsModalRef,
		private router: Router,
	) { }

	public close(): void {
		this.activeModal.hide();
	}

	public submit(): void {
		this.close();
		this.router.navigate([this.popUp.callToActionDestinationUrl]);
	}
}

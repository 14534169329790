<pk-broker-modal>
	<ng-template #headerTemplate>
		<h5 class="modal-title">Update Saved Table View</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<form [formGroup]="formGroup" *ngIf="!loading">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<p>
							Do you wish to update <em>{{ userGridConfig.name }}</em
							>?
						</p>
					</div>
				</div>
				<div class="row">
					<div class="col-12 form-element-container">
						<label for="customFilterName">Name for this custom view</label>
						<input type="text" class="form-control" id="customFilterName" formControlName="name" />
					</div>
				</div>
			</div>
		</form>
	</ng-template>
</pk-broker-modal>
<div class="modal-footer justify-content-center">
	<button type="button" class="btn btn-primary" (click)="submit()" [disabled]="submitDisabled">{{ submitText }}</button>
</div>

<div class="row">
	<div class="col mt-5">
		<p class="mb-1 font-size-1 blue-text semibold-text">
			Before we can start this auction, there are necessary requirements that need to be fulfilled.
		</p>
		<p *ngIf="!utilityMarketSetting.requireLoaOrBillOrManyLocations">Please upload the following document(s):</p>
		<p *ngIf="utilityMarketSetting.requireLoaOrBillOrManyLocations">Please upload at least one the following documents:</p>
	</div>
</div>
<ul>
	<div
		class="row d-flex align-items-start"
		*ngIf="utilityMarketSetting.requireBillForPricingSession || utilityMarketSetting.requireLoaOrBillOrManyLocations">
		<div class="col pt-1 pl-1">
			<li><p class="mb-0 semibold-text">Bill copies that are no older than 3 months.</p></li>
		</div>
	</div>
	<div
		class="row d-flex align-items-start"
		*ngIf="utilityMarketSetting.requireLoaForPricingSession || utilityMarketSetting.requireLoaOrBillOrManyLocations">
		<div class="col pt-1 pl-1">
			<li><p class="mb-0 semibold-text">A Letter of Authorization (LOA) file.</p></li>
		</div>
	</div>
	<div class="row d-flex align-items-start" *ngIf="utilityMarketSetting.requireLoeForPricingSession">
		<div class="col pt-1 pl-1">
			<li><p class="mb-0 semibold-text">A Letter of Exclusivity (LOE) file.</p></li>
		</div>
	</div>
	<div class="row d-flex align-items-start" *ngIf="utilityMarketSetting.requireHudForPricingSession">
		<div class="col pt-1 pl-1">
			<li><p class="mb-0 semibold-text">A Historical Usage Data (HUD) file.</p></li>
		</div>
	</div>
</ul>
<div class="row">
	<div class="col mt-4">
		<p class="semibold-text">You may close this window and return later when you collect the missing items.</p>
	</div>
</div>

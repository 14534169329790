

export class SupplierMarketMatrixAdjustmentOption {

	public selections: { text: string; min: number | null; minInclusive: boolean; max: number | null; maxInclusive: boolean }[];

	constructor(supplierMarketMatrixAdjustmentOption: SupplierMarketMatrixAdjustmentOption) {
		Object.assign(this, supplierMarketMatrixAdjustmentOption);
	}
}

import * as moment from 'moment-timezone';

export class ViewReceivableForecast {

	public contractId: string;
	public contractLocationId: string;
	public commissionTypeId: number;
	public date: Date;
	public amount: number;
	public usage: number;
	public margin: number;
	public meterStartDate: Date;
	public meterEndDate: Date;
	public originalDate: Date;
	public dateOffset: number;
	public supplierId: string;
	public supplierName: string;

	constructor(viewReceivableForecast?: ViewReceivableForecast) {
		Object.assign(this, viewReceivableForecast);

		this.meterStartDate = this.meterStartDate
			? new Date(moment(this.meterStartDate).format('MMM DD, YYYY 00:00:00 a')) : this.meterStartDate;
		this.meterEndDate = this.meterEndDate
			? new Date(moment(this.meterEndDate).format('MMM DD, YYYY 00:00:00 a')) : this.meterEndDate;
		this.originalDate = this.originalDate
			? new Date(moment(this.originalDate).format('MMM DD, YYYY 00:00:00 a')) : this.originalDate;
		this.date = this.date
			? new Date(moment(this.date).format('MMM DD, YYYY 00:00:00 a')) : this.date;
	}
}

import { Agent } from './agent';
import { AgentRateMatrix } from './agent-rate-matrix';

export class PromoCode {

	public id: number;
	public slug: string;
	public description: string;
	public isActive: boolean;
	public isDefault: boolean;
	public agentId: string;
	public isResidentialPowerKioskDirectAllowed: boolean;

	public agent: Agent;
	public hasContracts: boolean;
	public rateMatrixes: AgentRateMatrix[];

	constructor(promoCode: any) {
		Object.assign(this, promoCode);
	}
}

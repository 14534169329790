export class FinanceTotalValueDroppedReinstated {

	public totalTermValueDropped!: number;
	public thisMonthTermValueDropped!: number;
	public accountsDropped!: number;
	public reinstatedTermValue!: number;

	constructor(financeTotalValueDroppedReinstated?: FinanceTotalValueDroppedReinstated) {
		Object.assign(this, financeTotalValueDroppedReinstated);
	}
}

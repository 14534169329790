<pk-broker-modal customFooterClass="justify-content-end pt-0">
	<ng-template #headerTemplate>
		<h5 class="modal-title">{{ title }}</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<div class="container-fluid">
			<div class="row">
				<div class="col text-center">
					<p class="mb-0" *ngIf="body" [innerHtml]="body | safeHtml"></p>
					<p class="mb-0" *ngIf="body2" [innerHtml]="body2 | safeHtml"></p>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<ng-container *ngIf="isConfirm">
			<button type="button" class="btn btn-link" (click)="cancel()">Cancel</button>
			<button type="button" class="btn btn-primary" (click)="confirm()">{{ customConfirmationButtonText }}</button>
		</ng-container>
		<ng-container *ngIf="isYesNo">
			<button type="button" class="btn btn-link" (click)="cancel()">No</button>
			<button type="button" class="btn btn-primary" (click)="confirm()">Yes</button>
		</ng-container>
		<ng-container *ngIf="isOk">
			<button type="button" class="btn btn-primary" (click)="confirm()">OK</button>
		</ng-container>
	</ng-template>
</pk-broker-modal>

export class ContractMarketPerformance {

	public date: Date | string;
	public ancillary: number;
	public capacity: number;
	public energy: number;
	public congestion: number;
	public electricityBypass: number;
	public serviceFee: number;
	public nymex: number;
	public basis: number;
	public total: number;

	constructor(contractMarketPerformance?: ContractMarketPerformance) {
		Object.assign(this, contractMarketPerformance);
	}
}

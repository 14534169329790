export class CpanelReportType {

	public name: string;
	public description: string;
	public reportTypeId: string;
	public userId: string;

	constructor(cpanelReportType?: CpanelReportType) {
		Object.assign(this, cpanelReportType);
	}
}

import * as moment from 'moment-timezone';

import { Attachment } from './attachment';
import { EducationKeyword } from './education-keyword';
import { EducationTopic } from './education-topic';

export class Education {

	public id: number;
	public educationTypeId: number;
	public subTitle: string;
	public thumbnailAttachmentId: string;
	public path: string;
	public agentId: string;
	public isDefault: boolean;
	public isActive: boolean;
	public isGlobal: boolean;
	public addDate: Date;
	public pageId: string;
	public utilityId: string;

	public topics: EducationTopic[];
	public keywords: EducationKeyword[];
	public thumbnail: Attachment;

	constructor(education?: Education) {
		Object.assign(this, education);

		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
		this.thumbnail = this.thumbnail ? new Attachment(this.thumbnail) : this.thumbnail;
	}
}

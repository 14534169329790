import { Component } from '@angular/core';

@Component({
	selector: 'pk-broker-browser-denied',
	templateUrl: './browser-denied.component.html',
	styleUrls: ['./browser-denied.component.scss'],
})
export class BrowserDeniedModalComponent {

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { LoadOptions } from 'devextreme/data';
import CustomStore from 'devextreme/data/custom_store';
import DxDataGrid from 'devextreme/ui/data_grid';

import { GraphqlService } from '../../../graphql/graphql.service';
import { Contract, PagedEntity, ViewContractDashboard } from '../../models';
import { AbstractPageForm } from '../../pages/page-form.abstract';

@Component({
	selector: 'pk-broker-lookup-contract',
	templateUrl: './lookup-contract.component.html',
	styleUrls: ['./lookup-contract.component.scss'],
})
export class LookupContractComponent extends AbstractPageForm implements OnInit {

	@Output() selectedContract: EventEmitter<Contract> = new EventEmitter();

	@Input() initialValue: string;
	@Input() initialCriteria: any;

	public searchForm: UntypedFormGroup;
	private contracts = [];
	private contractsTotal = 0;
	public contractsDataSource: any = {};

	public isInitialLoad = true;
	private lookupContractGrid: DxDataGrid;

	constructor(
		private fb: UntypedFormBuilder,
		private graphqlService: GraphqlService) {
		super();
	}

	async ngOnInit(): Promise<void> {
		this.buildSearchForm();
		this.contractsDataSource.store = new CustomStore({
			key: 'contractId',
			load: async (loadOptions: LoadOptions) => {
				const trimmedSearch = this.search.value.trim();
				if (!this.isInitialLoad && (loadOptions['dataField'] || (trimmedSearch?.length < 4 && trimmedSearch?.length > 0))) {
					return { data: this.contracts, totalCount: this.contractsTotal };
				}
				const contractsResult = await this.listContracts(loadOptions, trimmedSearch);
				this.contracts = contractsResult ? contractsResult.data : [];
				this.contractsTotal = contractsResult ? contractsResult.totalCount : 0;
				return {
					data: this.contracts,
					totalCount: this.contractsTotal,
				};
			},
		});
	}

	private async listContracts(loadOptions: LoadOptions, search: string): Promise<PagedEntity<Contract>> {
		const loadOptionsSort = (Array.isArray(loadOptions.sort) ? loadOptions.sort : [loadOptions.sort]).filter(Boolean);
		const sort: string[] = loadOptionsSort?.length
			? loadOptionsSort.map(s => `${s['selector']} ${(s['desc'] ? 'desc' : 'asc')}`)
			: [];

		const criteria: any = {
			...this.initialCriteria,
			search: (!this.isInitialLoad && search) || (this.isInitialLoad && this.initialValue)
				? `%${search}%` : undefined,
		};
		const contractsResult =
			await this.graphqlService.getLookupContractSearchData(criteria, sort.join(', '), loadOptions.take, loadOptions.skip);

		this.isInitialLoad = false;
		return {
			data: contractsResult.data.viewContractDashboard.message.map(c => new ViewContractDashboard(c)),
			totalCount: contractsResult.data.viewContractDashboard.total,
		};
	}

	public onLookupContractGridInit(event: any): void {
		this.lookupContractGrid = event.component;
	}

	public select(contract: Contract): void {
		this.selectedContract.emit(contract);
	}

	public searchContracts(): void {
		const state = this.lookupContractGrid.state();
		this.lookupContractGrid.state(state);
	}

	private buildSearchForm(): void {
		this.searchForm = this.fb.group({
			search: this.initialValue || '',
		});
	}

	get search() { return this.searchForm.get('search'); }
	get isEnterpiseAgent(): boolean {
		return this.loggedInUser?.agent && this.loggedInUser?.agent?.isEnterprise;
	}
}

import { Pipe, PipeTransform } from '@angular/core';

import { MarketUpdateRateSummary } from '../models';

@Pipe({
	name: 'marketUpdateRateSummaryFilter'
})
export class MarketUpdateRateSummaryFilterPipe implements PipeTransform {
	transform(rateSummaries: MarketUpdateRateSummary[], serviceTypeId: string): MarketUpdateRateSummary[] {
		return rateSummaries ? rateSummaries.filter(r => r.serviceTypeId === serviceTypeId) : rateSummaries;
	}
}

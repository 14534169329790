import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'compensationFilter',
})
export class CompensationFilterPipe implements PipeTransform {

	transform(compensations: any[], supplier: string,
		commodity: string, upfrontOnly: boolean): any[] {
		return compensations
			? compensations.filter(c =>
				(!supplier || c.supplier === supplier) &&
				(!commodity || (commodity === 'Electricity' && (c.upfront.hasElec || c.upfront50.hasElec || c.residual.hasElec)) ||
					(commodity === 'Natural Gas' && (c.upfront.hasGas || c.upfront50.hasGas || c.residual.hasGas))) &&
				(!upfrontOnly || c.upfront.rules?.length || c.upfront50.rules?.length))
			: compensations;
	}

}

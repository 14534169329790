
export class ContractAdvancedServiceType {

	public id: string;
	public name: string;

	constructor(contractAdvancedServiceType: ContractAdvancedServiceType) {
		Object.assign(this, contractAdvancedServiceType);
	}
}

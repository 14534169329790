import * as moment from 'moment-timezone';

export class PeakLoadContribution {

	public startDate: Date;
	public endDate: Date;
	public value: number;

	constructor(peakLoadContribution?: PeakLoadContribution) {
		Object.assign(this, peakLoadContribution);

		this.startDate = this.startDate ? new Date(moment(this.startDate).format('MMM DD, YYYY hh:mm:ss a')) : this.startDate;
		this.endDate = this.endDate ? new Date(moment(this.endDate).format('MMM DD, YYYY hh:mm:ss a')) : this.endDate;
	}
}

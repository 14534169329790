export class PoaRateAutoSelectionOption {

	public id: number;
	public name: string;
	public minTerm: number;
	public minGreenPercentage: number;
	public checkBelowPtc: boolean;

	constructor(poaRateAutoSelectionOption?: PoaRateAutoSelectionOption) {
		Object.assign(this, poaRateAutoSelectionOption);
	}
}

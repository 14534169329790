import { Pipe, PipeTransform } from '@angular/core';

import { EmailCriteria } from '../models';

@Pipe({
	name: 'filterEmailCriterias'
})
export class FilterEmailCriteriasPipe implements PipeTransform {

	transform(emailCriterias: EmailCriteria[], companyId: string): EmailCriteria[] {
		return emailCriterias
			? emailCriterias.filter(e => !e.emailAccount || !companyId || e.emailAccount?.companyId === companyId)
			: emailCriterias;
	}

}

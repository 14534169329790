import { Component, ElementRef, ViewChild } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { environment } from 'src/environments/environment';
import { FeatureTour } from '../../models';

@Component({
	selector: 'pk-broker-feature-tour-modal',
	templateUrl: './feature-tour-modal.component.html',
	styleUrls: ['./feature-tour-modal.component.scss'],
})
export class FeatureTourModalComponent {

	@ViewChild('videoPlayer') videoplayer: ElementRef;

	// inputs
	public featureTour: Subset<FeatureTour>;

	public environment = environment;

	public activeIndex = 0;

	constructor(
		private activeModal: BsModalRef,
	) { }

	public close(): void {
		this.activeModal.hide();
	}

	public next(): void {
		this.videoplayer?.nativeElement.pause();
		this.activeIndex++;
		if (this.activeIndex >= this.featureTour.slides.length) {
			this.activeIndex = 0;
		}
	}

	public previous(): void {
		this.videoplayer?.nativeElement.pause();
		this.activeIndex--;
		if (this.activeIndex < 0) {
			this.activeIndex = this.featureTour.slides.length - 1;
		}
	}
}

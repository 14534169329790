import * as moment from 'moment-timezone';

export class Report {

	public reportId: string;
	public reportTypeId: string;
	public name: string;
	public eventName: string;
	public queryString: string;
	public description: string;
	public permissions: string;
	public sortOrder: number;
	public isActive: boolean;
	public apiEventName: string;

	constructor(report?: Report) {
		Object.assign(this, report);
	}

	get newFileName() {
		return `${this.name.replace(/\s+/g, '_')}_${moment().format('YYYY-MM-DD')}`;
	}
}

<div class="row feedback-entry-card">
	<div class="col-12">
		<div class="row top-row">
			<div class="col p-0">
				<ng-container *ngIf="feedback.type && feedback.page">
					<h4>
						{{ feedback.type.name }} on <a href="javascript:void(0)">{{ feedback.page }}</a>
					</h4>
				</ng-container>
			</div>
			<pk-broker-loader [show]="loadingStatus"></pk-broker-loader>
			<ng-container *ngIf="!loadingStatus">
				<div class="col-auto p-0 mr-2" *ngIf="feedback.ticketId">
					<a [routerLink]="['/ticket', feedback.ticketId, 'info']" target="_blank" class="semibold-text">
						<fa-icon [icon]="['fas', 'pencil-alt']" [classes]="['mr-1']"></fa-icon> <span class="feedback-notes">View Notes</span>
					</a>
				</div>
				<div class="col-auto p-0">
					<pk-broker-action-dropdown class="feedback-status" [actions]="statusActions" [hideChevron]="true">
						<ng-template #name>
							<span [ngClass]="feedback.getStatus().colorClass">
								<fa-icon [icon]="['fas', feedback.getStatus().icon]" [classes]="['mr-1']"></fa-icon>
								<span class="feedback-status-text">{{ feedback.getStatus().text }}</span>
							</span>
						</ng-template>
					</pk-broker-action-dropdown>
				</div>
			</ng-container>
		</div>
		<div class="row middle-row">
			<div class="col-12 col-lg-auto flex-fill px-0 pt-2 pb-0">
				<p>
					Posted: <span class="semibold-text">{{ feedback.addDate | date: 'MM/dd/yyyy hh:mm a' }}</span>
				</p>
			</div>
			<div class="col-12 col-lg-auto flex-fill px-0 pt-0 pt-lg-2 pb-0">
				<p>
					User: <a href="javascript:void(0)" class="semibold-text">{{ feedback.user.fullName }}</a>
				</p>
			</div>
			<div class="col-12 col-lg-auto flex-fill px-0 pt-0 pt-lg-2 pb-0">
				<p>
					Email: <a href="javascript:void(0)" class="semibold-text">{{ feedback.user.email }}</a>
				</p>
			</div>
			<div class="col-12 col-lg-auto flex-fill px-0 pt-0 pt-lg-2 pb-0">
				<p>
					Phone: <span class="semibold-text">{{ feedback.user.phone | phone }}</span>
				</p>
			</div>
			<div class="col-12 col-lg-auto flex-fill px-0 pt-0 pt-lg-2 pb-2 pb-lg-0">
				<p>
					Can Contact: <span class="semibold-text">{{ feedback.canContact ? 'Yes' : 'No' }}</span>
				</p>
			</div>
		</div>
		<div class="row middle-row">
			<div class="col-12 col-lg-auto flex-fill px-0 pt-0 pb-0">
				<p>
					Page URL:
					<span class="semibold-text"
						><a target="_blank" [attr.href]="feedback.url">{{ feedback.url }}</a></span
					>
				</p>
			</div>
		</div>
		<div class="row bottom-row">
			<div class="col-12 p-0">
				<p>{{ feedback.body }}</p>
			</div>
		</div>
	</div>
</div>

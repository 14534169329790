import { Contract } from './contract';

export class CustomerContractDashboard {

	public agentId: string;
	public layeredPurchasingContracts: Contract[];
	public contractPerformanceContracts: Contract[];

	constructor(customerContractDashboard?: CustomerContractDashboard) {
		Object.assign(this, customerContractDashboard);
	}
}



export class ReceivableAuditIssueType {
	public id: number;
	public name: string;

	constructor(receivableAuditIssueType: ReceivableAuditIssueType) {
		Object.assign(this, receivableAuditIssueType);
	}
}

import { AfterViewChecked, Component, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { AgentAdvanced } from 'src/app/shared/models/agent-advanced';
import { Agent } from '../../../shared/models';
import { AgentSearchService } from '../../services/agent-search.service';

@Component({
	selector: 'pk-broker-agent-lookup',
	templateUrl: './agent-lookup.component.html',
	styleUrls: ['./agent-lookup.component.scss'],
	providers: [AgentSearchService],
})
export class AgentLookupModalComponent implements OnInit, AfterViewChecked {

	// inputs
	public shownRoleId: string;
	public includeHouseAccounts: boolean;
	public includeSalesManagers: boolean;
	public includeIsInHouseIndirectSale: boolean;
	public companyId: string;

	public agentsTotal: number;
	public agents: AgentAdvanced[];

	public onSubmit: Subject<Agent> = new Subject();

	constructor(
		private agentSearchService: AgentSearchService,
		private activeModal: BsModalRef,
	) { }

	ngAfterViewChecked(): void {
		const emptyRows = document.getElementsByClassName('dx-freespace-row');
		Array.from(emptyRows).forEach(node => node.remove());
	}

	ngOnInit() {
		this.agentSearchService.buildSearchForm('', this.shownRoleId, this.companyId);
		this.agentSearchService.setupAgentGridCustomStore();

		if (this.includeHouseAccounts) {
			this.searchForm.get('includeHouseAccounts').setValue(this.includeHouseAccounts);
		}

		if (this.includeSalesManagers) {
			this.searchForm.get('includeSalesManagers').setValue(this.includeSalesManagers);
		}

		if (this.includeIsInHouseIndirectSale) {
			this.searchForm.get('includeIsInHouseIndirectSale').setValue(this.includeIsInHouseIndirectSale);
		}
	}

	get searchForm() { return this.agentSearchService.searchForm; }
	get agentsDataSource() { return this.agentSearchService.agentsDataSource; }
	get search() { return this.agentSearchService.search; }
	get dataGrid() { return this.agentSearchService.dataGrid; }
	get activeSelections() { return this.agentSearchService.activeSelections; }

	public onAgentsDataGridInit(event: any): void {
		this.agentSearchService.setupAgentDataGridFromGridInitEvent(event);
	}

	public close(): void {
		this.activeModal.hide();
	}

	public selectAgent(agent: Agent): void {
		this.onSubmit.next(agent);
		this.activeModal.hide();
	}

	public onSearch(): void {
		if (this.search.value.length > 3 || !this.search.value) {
			const state = this.dataGrid.state();
			this.dataGrid.state(state);
		}
	}
}

export class OperationsCompanySnapshot {

	public auctionsEndingToday!: number;
	public awardedNotSigned!: number;
	public contractsDropped!: number;
	public supplierParticipationRatio!: number;
	public brokerClosingRatio!: number;

	constructor(operationsCompanySnapshot?: OperationsCompanySnapshot) {
		Object.assign(this, operationsCompanySnapshot);
	}
}

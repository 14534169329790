export class EmailAddress {

	public id: number;
	public email: string;
	public emailAddressTypeId: number;

	constructor(emailAddress: EmailAddress) {
		Object.assign(this, emailAddress);
	}
}

import * as moment from 'moment-timezone';

export class SupplierLatencyReport {

	public supplierId: string;
	public serviceTypeId: string;
	public commodity: string;
	public name: string;
	public email: string;
	public stateId: string;
	public stateShort: string;
	public utilityId: string;
	public utilityName: string;
	public contractNum: string;
	public annualUsage: number;
	public auctionEndDate: Date;
	public rfqSessionBidAddDate: Date;
	public brokerEndDateLatency: number;
	public supplierEndDateLatency: number;
	public numLocations: number;

	constructor(supplierLatencyReport: SupplierLatencyReport) {
		Object.assign(this, supplierLatencyReport);

		this.auctionEndDate = this.auctionEndDate
			? new Date(moment(this.auctionEndDate).format('MMM DD, YYYY hh:mm:ss a')) : this.auctionEndDate;
		this.rfqSessionBidAddDate = this.rfqSessionBidAddDate
			? new Date(moment(this.rfqSessionBidAddDate).format('MMM DD, YYYY hh:mm:ss a')) : this.rfqSessionBidAddDate;
	}
}

import { Component } from '@angular/core';
import { StringUtils } from '@pk/powerkioskutils';

import { LoadOptions } from 'devextreme/data';
import dxDataGrid from 'devextreme/ui/data_grid';

import { ContractCriteria, PagedEntity, ViewContractDashboard } from 'src/app/shared/models';
import { AbstractContractTableComponent } from '../contract-table.component.abstract';

@Component({
	selector: 'pk-broker-general-contract-table',
	templateUrl: '../contract-table.component.html',
	styleUrls: ['../contract-table.component.scss'],
})
export class GeneralContractTableComponent extends AbstractContractTableComponent<ViewContractDashboard> {

	public async listContracts(loadOptions: LoadOptions, dataGrid: dxDataGrid): Promise<PagedEntity<ViewContractDashboard>> {
		const criteria: ContractCriteria = { ...this.extraCriteria };
		const loadOptionsSort = (Array.isArray(loadOptions.sort) ? loadOptions.sort : [loadOptions.sort]).filter(Boolean);
		const sort: string[] = loadOptionsSort?.length
			? loadOptionsSort.map(s => `${s['selector']} ${(s['desc'] ? 'desc' : 'asc')}`)
			: [];

		const filterMap = this.helperService.buildFilterMap(dataGrid, dataGrid.getCombinedFilter(), {});

		if (filterMap.hasOwnProperty('!displayStatus')) {
			filterMap['displayStatus'] = this.contractStatusList
				.filter(s => Array.isArray(filterMap['!displayStatus'])
					? !filterMap['!displayStatus'].some(st => st === s)
					: s !== filterMap['!displayStatus']);
		}

		if (filterMap.hasOwnProperty('displayStatus')) {
			criteria.displayStatus = Array.isArray(filterMap['displayStatus'])
				? filterMap['displayStatus'].join(',') : filterMap['displayStatus'];
		}

		if (filterMap.hasOwnProperty('!supplierId')) {
			filterMap['supplierId'] = this.suppliers
				.filter(s => Array.isArray(filterMap['!supplierId'])
					? !filterMap['!supplierId'].some(st => st === s.supplierId)
					: s.supplierId !== filterMap['!supplierId'])
				.map(s => s.supplierId);
		}

		if (filterMap.hasOwnProperty('supplierId')) {
			criteria.supplierId = Array.isArray(filterMap['supplierId'])
				? filterMap['supplierId'].join(',') : filterMap['supplierId'];
		}

		if (filterMap.hasOwnProperty('!isResidential')) {
			filterMap['isResidential'] = !filterMap['!isResidential'];
		}

		if (filterMap.hasOwnProperty('isResidential')) {
			criteria.isResidential = StringUtils.toBoolean(filterMap['isResidential']);
		}

		if (filterMap.hasOwnProperty('!isRenewal')) {
			filterMap['isRenewal'] = !filterMap['!isRenewal'];
		}

		if (filterMap.hasOwnProperty('isRenewal')) {
			criteria.isRenewal = StringUtils.toBoolean(filterMap['isRenewal']);
		}

		if (filterMap.hasOwnProperty('!serviceTypeId')) {
			filterMap['serviceTypeId'] = this.serviceTypes
				.filter(s => Array.isArray(filterMap['!serviceTypeId'])
					? !filterMap['!serviceTypeId'].some(st => st === s.serviceTypeId)
					: s.serviceTypeId !== filterMap['!serviceTypeId'])
				.map(s => s.serviceTypeId);
		}

		if (filterMap.hasOwnProperty('serviceTypeId')) {
			criteria.serviceTypeId = Array.isArray(filterMap['serviceTypeId'])
				? filterMap['serviceTypeId'].join(',') : filterMap['serviceTypeId'];
		}

		if (filterMap.hasOwnProperty('!term')) {
			filterMap['term'] = this.terms
				.filter(s => Array.isArray(filterMap['!term'])
					? !filterMap['!term'].some(st => Number(st) === s)
					: s !== Number(filterMap['!term']));
		}

		if (filterMap.hasOwnProperty('term')) {
			criteria.term = Array.isArray(filterMap['term'])
				? filterMap['term'].map(s => Number(s)).join(',') : String(Number(filterMap['term']));
		}

		if (filterMap.hasOwnProperty('effectiveDate')) {
			criteria.effectiveDate = JSON.stringify(filterMap['effectiveDate']);
		}

		if (filterMap.hasOwnProperty('auctionStartDate')) {
			criteria.auctionStartDate = JSON.stringify(filterMap['auctionStartDate']);
		}

		if (filterMap.hasOwnProperty('auctionEndDate')) {
			criteria.auctionEndDate = JSON.stringify(filterMap['auctionEndDate']);
		}

		if (filterMap.hasOwnProperty('confirmationDate')) {
			criteria.confirmationDate = JSON.stringify(filterMap['confirmationDate']);
		}

		if (filterMap.hasOwnProperty('contractDate')) {
			criteria.contractDate = JSON.stringify(filterMap['contractDate']);
		}

		if (filterMap.hasOwnProperty('modifiedDate')) {
			criteria.modifiedDate = JSON.stringify(filterMap['modifiedDate']);
		}

		if (filterMap.hasOwnProperty('dropDate')) {
			criteria.dropDate = JSON.stringify(filterMap['dropDate']);
		}

		if (filterMap.hasOwnProperty('expirationDate')) {
			criteria.expirationDate = JSON.stringify(filterMap['expirationDate']);
		}

		if (filterMap.hasOwnProperty('!stateId')) {
			filterMap['stateId'] = this.suppliers
				.filter(s => Array.isArray(filterMap['!stateId'])
					? !filterMap['!stateId'].some(st => st === s.stateId)
					: s.stateId !== filterMap['!stateId'])
				.map(s => s.stateId);
		}

		if (filterMap.hasOwnProperty('stateId')) {
			criteria.stateId = Array.isArray(filterMap['stateId'])
				? filterMap['stateId'].join(',') : filterMap['stateId'];
		}

		if (filterMap.hasOwnProperty('!agentId')) {
			filterMap['agentId'] = this.agents
				.filter(s => Array.isArray(filterMap['!agentId'])
					? !filterMap['!agentId'].some(st => st === s.agentId)
					: s.agentId !== filterMap['!agentId'])
				.map(s => s.agentId);
		}

		if (filterMap.hasOwnProperty('agentId')) {
			delete criteria.includeParentBrother;
			criteria.agentId = Array.isArray(filterMap['agentId'])
				? filterMap['agentId'].join(',') : filterMap['agentId'];
		}

		const result = await this.graphqlService.viewContractDashboard(criteria, sort.join(', '), loadOptions.take, loadOptions.skip);
		return {
			data: result.data.viewContractDashboard.message.map(c => new ViewContractDashboard(c)),
			totalCount: result.data.viewContractDashboard.total,
		};
	}
}

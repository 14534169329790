<pk-broker-loader [show]="loading"></pk-broker-loader>
<ng-container *ngIf="!loading">
	<div class="row">
		<div class="col-12">
			<div #contractGridTopScroll class="gridTopScroll">
				<div #contractGridTopScrollInner class="gridTopScrollInner"></div>
			</div>
			<dx-data-grid
				*ngIf="finishedInit"
				id="gridContainer"
				[dataSource]="contractsDataSource"
				[allowColumnResizing]="true"
				[allowColumnReordering]="true"
				[columnAutoWidth]="true"
				[showBorders]="true"
				[remoteOperations]="true"
				[rowAlternationEnabled]="true"
				[hoverStateEnabled]="true"
				(onInitialized)="onContractDataGridInit($event)"
				(onContentReady)="onContractDataGridReady($event)">
				<dxo-scrolling [useNative]="true"></dxo-scrolling>
				<dxo-state-storing [enabled]="true" type="custom" [customLoad]="tableStateLoad"></dxo-state-storing>
				<dxo-header-filter *ngIf="showFilters" [visible]="true"></dxo-header-filter>
				<dxi-column
					[caption]="actionColumnName"
					[visible]="!!actionColumnName"
					[fixed]="!isMobile && fixColumns"
					fixedPosition="left"
					dataField="actionColumn"
					dataType="string"
					cellTemplate="actionColumnCellTemplate"
					[allowReordering]="false"
					[allowHeaderFiltering]="false">
				</dxi-column>
				<dxi-column
					caption="Contract #"
					[fixed]="!isMobile && fixColumns"
					fixedPosition="left"
					dataField="contractNum"
					dataType="string"
					cellTemplate="contractNumCellTemplate"
					[allowReordering]="false"
					[allowHeaderFiltering]="false">
				</dxi-column>
				<dxi-column
					caption="DBA Name"
					[fixed]="!isMobile && fixColumns"
					fixedPosition="left"
					dataField="dbaName"
					dataType="string"
					cellTemplate="dbaNameCellTemplate"
					[allowReordering]="false"
					[allowHeaderFiltering]="false">
				</dxi-column>
				<dxi-column
					caption="Status"
					[fixed]="!isMobile && fixColumns"
					fixedPosition="left"
					dataField="displayStatus"
					dataType="string"
					cellTemplate="statusCellTemplate"
					[allowReordering]="false">
					<dxo-header-filter *ngIf="showFilters" [dataSource]="filterLists.displayStatus" id="contractStatusFilter"></dxo-header-filter>
				</dxi-column>
				<dxi-column
					caption="Residential"
					[allowSorting]="false"
					dataField="isResidential"
					dataType="string"
					[calculateCellValue]="calculateResidentialDisplay">
					<dxo-header-filter *ngIf="showFilters" [dataSource]="filterLists.isResidential"></dxo-header-filter>
				</dxi-column>
				<dxi-column caption="Renewal" [allowSorting]="false" dataField="isRenewal" dataType="string" [calculateCellValue]="calculateRenewalDisplay">
					<dxo-header-filter *ngIf="showFilters" [dataSource]="filterLists.isRenewal"></dxo-header-filter>
				</dxi-column>
				<dxi-column caption="Commodity" [allowSorting]="false" dataField="commodity" dataType="string">
					<dxo-header-filter *ngIf="showFilters" [dataSource]="filterLists.commodity"></dxo-header-filter>
				</dxi-column>
				<dxi-column caption="Dropped Term Value" dataField="droppedTermValue" cellTemplate="droppedTermValueCellTemplate" dataType="string">
				</dxi-column>
				<dxi-column caption="Term (Months)" dataField="term" dataType="string">
					<dxo-header-filter *ngIf="showFilters" [dataSource]="filterLists.term"></dxo-header-filter>
				</dxi-column>
				<dxi-column caption="Effective Date" dataField="effectiveDate" dataType="date">
					<dxo-header-filter *ngIf="showFilters" [dataSource]="filterLists.effectiveDate"></dxo-header-filter>
				</dxi-column>
				<dxi-column caption="Contract Date" dataField="contractDate" dataType="date" sortOrder="desc">
					<dxo-header-filter *ngIf="showFilters" [dataSource]="filterLists.contractDate"></dxo-header-filter>
				</dxi-column>
				<dxi-column caption="Supplier" dataField="supplierName" dataType="string">
					<dxo-header-filter *ngIf="showFilters" [dataSource]="filterLists.supplierName"></dxo-header-filter>
				</dxi-column>
				<dxi-column
					caption="Usage"
					dataField="annualUsage"
					dataType="string"
					cellTemplate="usageCellTemplate"
					[allowHeaderFiltering]="false"></dxi-column>
				<dxi-column caption="Rate" dataField="rate" dataType="string" cellTemplate="rateCellTemplate" [allowHeaderFiltering]="false"></dxi-column>
				<dxi-column caption="Agent" dataField="agentName" dataType="string" cellTemplate="agentCellTemplate" [allowHeaderFiltering]="!!agents">
					<dxo-header-filter *ngIf="showFilters" [dataSource]="filterLists.agentName"></dxo-header-filter>
				</dxi-column>
				<dxi-column
					caption="Underwriter"
					[allowSorting]="false"
					dataField="underwriterName"
					cellTemplate="underwriterCellTemplate"
					dataType="string"
					[allowHeaderFiltering]="false"></dxi-column>
				<dxi-column
					caption="HP"
					[allowSorting]="false"
					dataField="hasHappyCustomer"
					cellTemplate="hasHappyCustomerCellTemplate"
					dataType="boolean"
					[allowHeaderFiltering]="false"></dxi-column>
				<dxi-column
					caption="LVM"
					[allowSorting]="false"
					dataField="hasLVM"
					cellTemplate="hasLVMCellTemplate"
					dataType="boolean"
					[allowHeaderFiltering]="false"></dxi-column>
				<dxi-column
					caption="Issue"
					[allowSorting]="false"
					dataField="hasIssueFound"
					cellTemplate="hasIssueFoundCellTemplate"
					dataType="boolean"
					[allowHeaderFiltering]="false"></dxi-column>
				<dxi-column
					caption="Comer"
					[allowSorting]="false"
					dataField="hasCommercial"
					cellTemplate="hasCommercialCellTemplate"
					dataType="boolean"
					[allowHeaderFiltering]="false"></dxi-column>
				<dxi-column
					caption="Other"
					[allowSorting]="false"
					dataField="hasOthers"
					cellTemplate="hasOthersCellTemplate"
					dataType="boolean"
					[allowHeaderFiltering]="false"></dxi-column>
				<dxi-column
					caption="Notifications"
					[allowSorting]="false"
					dataField="notifications"
					dataType="string"
					[allowHeaderFiltering]="false"></dxi-column>
				<dxi-column
					caption="Add Note"
					dataField="addNote"
					[width]="135"
					[allowSorting]="false"
					dataType="string"
					cellTemplate="noteCellTemplate"
					[allowHeaderFiltering]="false"></dxi-column>
				<dxi-column caption="Pricing Session Start Date" dataField="auctionStartDate" dataType="date">
					<dxo-header-filter *ngIf="showFilters" [dataSource]="filterLists.auctionStartDate"></dxo-header-filter>
				</dxi-column>
				<dxi-column caption="Pricing Session End Date" dataField="auctionEndDate" dataType="date">
					<dxo-header-filter *ngIf="showFilters" [dataSource]="filterLists.auctionEndDate"></dxo-header-filter>
				</dxi-column>
				<dxi-column caption="Expiration Date" dataField="expirationDate" dataType="date">
					<dxo-header-filter *ngIf="showFilters" [dataSource]="filterLists.expirationDate"></dxo-header-filter>
				</dxi-column>
				<dxi-column caption="Confirmation Date" dataField="confirmationDate" dataType="date">
					<dxo-header-filter *ngIf="showFilters" [dataSource]="filterLists.confirmationDate"></dxo-header-filter>
				</dxi-column>
				<dxi-column caption="Drop Date" dataField="dropDate" dataType="date" cellTemplate="dropDateCellTemplate">
					<dxo-header-filter *ngIf="showFilters" [dataSource]="filterLists.dropDate"></dxo-header-filter>
				</dxi-column>
				<dxi-column
					caption="Reject Reason"
					[allowSorting]="false"
					dataField="rejectReason"
					dataType="string"
					cellTemplate="rejectReasonCellTemplate"
					[allowHeaderFiltering]="false"></dxi-column>
				<dxi-column
					caption="Previous Contract #"
					dataField="previousContractNum"
					dataType="string"
					cellTemplate="rewewalContractNumCellTemplate"
					[allowHeaderFiltering]="false"></dxi-column>
				<dxi-column
					caption="Previous Rate"
					dataField="previousContractRate"
					dataType="string"
					cellTemplate="rewewalContractRateCellTemplate"
					[allowHeaderFiltering]="false"></dxi-column>
				<dxi-column
					caption="Contact"
					dataField="customerFname"
					cellTemplate="contactTemplate"
					dataType="string"
					[allowHeaderFiltering]="false"></dxi-column>
				<dxi-column
					caption="Job Title"
					dataField="customerTitle"
					dataType="string"
					[allowHeaderFiltering]="false"
					cellTemplate="titleCellTemplate"></dxi-column>
				<dxi-column
					caption="Email"
					[allowSorting]="false"
					dataField="customerEmail"
					dataType="string"
					[allowHeaderFiltering]="false"
					cellTemplate="emailCellTemplate"></dxi-column>
				<dxi-column
					caption="Phone"
					[allowSorting]="false"
					dataField="customerPhone"
					dataType="string"
					cellTemplate="phoneCellTemplate"
					[allowHeaderFiltering]="false"></dxi-column>
				<dxi-column caption="State" dataField="stateLong" dataType="string">
					<dxo-header-filter *ngIf="showFilters" [dataSource]="filterLists.stateLong"></dxo-header-filter>
				</dxi-column>
				<dxi-column
					caption="Utility"
					[allowSorting]="false"
					dataField="utilityNameList"
					dataType="string"
					[allowHeaderFiltering]="false"></dxi-column>
				<dxi-column
					caption="Pay Plan"
					[allowSorting]="false"
					dataField="commissionPayPlanName"
					dataType="string"
					[allowHeaderFiltering]="false"></dxi-column>
				<dxo-paging [pageSize]="25"></dxo-paging>
				<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
				<div *dxTemplate="let d of 'actionColumnCellTemplate'">
					<div class="chart-cell text-center">
						<a (click)="actionColumnFunction(d.data)" class="no-right-margin" role="button"><fa-icon [icon]="actionColumnIcon"></fa-icon></a>
					</div>
				</div>
				<div *dxTemplate="let d of 'rejectReasonCellTemplate'">
					<div class="chart-cell">
						<span placement="top" container="body" [tooltip]="d.data.rejectReason">
							{{ d.data.rejectReason | shrink: 19 }}
						</span>
					</div>
				</div>
				<div *dxTemplate="let d of 'phoneCellTemplate'">
					<div class="chart-cell">
						{{ (!d.data.hideContractDetailsFromBrother && d.data.brotherId !== loggedInUser.agentId ? d.data.customerPhone : '') | phone }}
					</div>
				</div>
				<div *dxTemplate="let d of 'emailCellTemplate'">
					<div class="chart-cell">
						{{ !d.data.hideContractDetailsFromBrother && d.data.brotherId !== loggedInUser.agentId ? d.data.customerEmail : '' }}
					</div>
				</div>
				<div *dxTemplate="let d of 'titleCellTemplate'">
					<div class="chart-cell">
						{{ !d.data.hideContractDetailsFromBrother && d.data.brotherId !== loggedInUser.agentId ? d.data.customerTitle : '' }}
					</div>
				</div>
				<div *dxTemplate="let d of 'rateCellTemplate'">
					<div class="chart-cell">
						{{ d.data.rate || d.data.rate === 0 ? (d.data.getDisplayRate() | currency: 'USD':'symbol':'1.5-5') : d.data.getDisplayRate() }}
						<span *ngIf="d.data.getDisplayRate()"> / {{ d.data.units }}</span>
					</div>
				</div>
				<div *dxTemplate="let d of 'dropDateCellTemplate'">
					<div class="chart-cell">
						{{ d.data.displayStatus === 'Dropped' ? (d.data.dropDate | date: 'MM/dd/yyyy') : '' }}
					</div>
				</div>
				<div *dxTemplate="let d of 'rewewalContractRateCellTemplate'">
					<div class="chart-cell" *ngIf="d.data.previousRate">{{ d.data.getDisplayPreviousRate() }} / {{ d.data.units }}</div>
				</div>
				<div *dxTemplate="let d of 'contactTemplate'">
					<div class="chart-cell">
						{{ !d.data.hideContractDetailsFromBrother && d.data.brotherId !== loggedInUser.agentId ? d.data.getContactFullName() : '' }}
					</div>
				</div>
				<div *dxTemplate="let d of 'droppedTermValueCellTemplate'">
					<div class="chart-cell">
						{{ d.data.droppedTermValue | currency: 'USD':'symbol':'1.0-2' }}
					</div>
				</div>
				<div *dxTemplate="let d of 'usageCellTemplate'">
					<div class="chart-cell">{{ d.data.isResidential ? 'Residential' : (d.data.getDisplayAnnualUsage() | number) + ' ' + d.data.units }}</div>
				</div>
				<div *dxTemplate="let d of 'noteCellTemplate'">
					<div class="chart-cell">
						<a href="javascript:void(0)" (click)="openNoteAddModal(d.data)"> <fa-icon [icon]="['fas', 'plus']"></fa-icon> Add Note </a>
					</div>
				</div>
				<div *dxTemplate="let d of 'contractNumCellTemplate'">
					<div class="chart-cell">
						<a
							[routerLink]="['/contract', d.data.contractId, 'info']"
							target="_blank"
							container="body"
							tooltip="View and edit the details of this contract by clicking the highlighted account numbers."
							(click)="sendPageEvent('contract-num')">
							{{ d.data.displayContractNum }}
						</a>
					</div>
				</div>
				<div *dxTemplate="let d of 'rewewalContractNumCellTemplate'">
					<div class="chart-cell">
						<a
							*ngIf="d.data.previousContractId"
							[routerLink]="['/contract', d.data.previousContractId, 'info']"
							target="_blank"
							container="body"
							tooltip="View and edit the details of this contract by clicking the highlighted account numbers."
							(click)="sendPageEvent('previous-contract-num')">
							{{ d.data.previousContractNum }}
						</a>
					</div>
				</div>
				<div *dxTemplate="let d of 'dbaNameCellTemplate'">
					<div class="chart-cell">
						<a
							*ngIf="!d.data.hideContractDetailsFromBrother || d.data.brotherId !== loggedInUser.agentId"
							[routerLink]="['/customer', d.data.customerId, 'info']"
							[tooltip]="d.data.dbaName"
							placement="top"
							container="body"
							target="_blank"
							(click)="sendPageEvent('dba-name')">
							{{ d.data.dbaName | shrink: 19 }}
						</a>
						<a
							*ngIf="d.data.hideContractDetailsFromBrother && d.data.brotherId === loggedInUser.agentId"
							href="javascript:void(0)"
							[tooltip]="d.data.dbaName"
							placement="top"
							container="body">
							{{ d.data.dbaName | shrink: 19 }}
						</a>
					</div>
				</div>
				<div *dxTemplate="let d of 'statusCellTemplate'">
					<div class="chart-cell">
						<a
							*ngIf="d.data.isResidential && d.data.status === CONSTANTS.statuses.rejected && !d.data.showDepositRequiredMessage"
							href="javascript:void(0)"
							container="body"
							[adaptivePosition]="false"
							tooltip="Resend Enrollment Email"
							(click)="resendEnrollmentEmail(d.data)"
							id="contractTableStatusLink">
							<fa-icon [icon]="['fas', 'paper-plane']" class="resend-enrollment-email-icon ml-1"></fa-icon>
						</a>
						<a *ngIf="d.data.showStatusButton()" href="javascript:void(0)" (click)="openAuctionModal(d.data)" id="contractTableStatusLink">
							{{ d.data.displayStatus }}</a
						>
						<ng-container
							*ngIf="
								(![CONSTANTS.statuses.rejected, CONSTANTS.statuses.dropped, CONSTANTS.statuses.acomp, CONSTANTS.statuses.ainp].includes(
									d.data.status
								) &&
									!d.data.showStatusButton() &&
									!d.data.showDepositRequiredMessage) ||
								(([CONSTANTS.statuses.rejected, CONSTANTS.statuses.dropped].includes(d.data.status) || d.data.showDepositRequiredMessage) &&
									!d.data.rejectReason)
							">
							{{ d.data.displayStatus }}
							{{ displayStatusSuffix(d.data.showHoldSubmissionStatus, d.data.showAutoSubmissionStatus, d.data.isResidential) }}
						</ng-container>
						<a
							*ngIf="
								([CONSTANTS.statuses.rejected, CONSTANTS.statuses.dropped].includes(d.data.status) || d.data.showDepositRequiredMessage) &&
								d.data.rejectReason
							"
							href="javascript:void(0)"
							(click)="showRejectReason(d.data)"
							id="contractTableStatusLink"
							[ngClass]="{ 'deposit-required': d.data.showDepositRequiredMessage }">
							{{ d.data.displayStatus }}</a
						>
					</div>
				</div>
				<div *dxTemplate="let d of 'agentCellTemplate'">
					<div class="chart-cell">
						<a
							(click)="showBrokerBidInfoModal(d.data)"
							*ngIf="brokerBidInfoModalVisible"
							href="javascript:void(0)"
							[tooltip]="d.data.displayAgentName + (d.data.parentName ? ', ' + d.data.parentName : '')"
							placement="top"
							[adaptivePosition]="false"
							container="body">
							{{ d.data.displayAgentName + (d.data.parentName ? ', ' + d.data.parentName : '') | shrink: 19 }}
							<fa-icon [icon]="['fas', 'clone']"></fa-icon>
						</a>
						<a
							*ngIf="!brokerBidInfoModalVisible"
							href="javascript:void(0)"
							[tooltip]="d.data.displayAgentName + (d.data.parentName ? ', ' + d.data.parentName : '')"
							placement="top"
							[adaptivePosition]="false"
							container="body">
							{{ d.data.displayAgentName + (d.data.parentName ? ', ' + d.data.parentName : '') | shrink: 19 }}
						</a>
					</div>
				</div>
				<div *dxTemplate="let d of 'underwriterCellTemplate'">
					<div class="chart-cell">
						<span [tooltip]="d.data.underwriterName" placement="top" container="body" [adaptivePosition]="false">
							{{ d.data.underwriterName | shrink: 19 }}
						</span>
					</div>
				</div>
				<div *dxTemplate="let d of 'hasHappyCustomerCellTemplate'">
					<div class="chart-cell">
						<fa-icon [icon]="['fas', 'check']" *ngIf="d.data.hasHappyCustomer"></fa-icon>
					</div>
				</div>
				<div *dxTemplate="let d of 'hasLVMCellTemplate'">
					<div class="chart-cell">
						<fa-icon [icon]="['fas', 'check']" *ngIf="d.data.hasLVM"></fa-icon>
					</div>
				</div>
				<div *dxTemplate="let d of 'hasIssueFoundCellTemplate'">
					<div class="chart-cell">
						<fa-icon [icon]="['fas', 'check']" *ngIf="d.data.hasIssueFound"></fa-icon>
					</div>
				</div>
				<div *dxTemplate="let d of 'hasCommercialCellTemplate'">
					<div class="chart-cell">
						<fa-icon [icon]="['fas', 'check']" *ngIf="d.data.hasCommercial"></fa-icon>
					</div>
				</div>
				<div *dxTemplate="let d of 'hasOthersCellTemplate'">
					<div class="chart-cell">
						<fa-icon [icon]="['fas', 'check']" *ngIf="d.data.hasOthers"></fa-icon>
					</div>
				</div>
			</dx-data-grid>
		</div>
	</div>
</ng-container>

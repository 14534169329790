export class AgentFinancialProfileReport {

	public agentName: string;
	public parentName: number;
	public totalTermValue: number;
	public totalRemainingTermValue: number;
	public reversibleCommission: number;
	public negativeBalance: number;
	public futureCommissionAmount: number;
	public levelOneCommissionUpfrontRate: number;
	public levelTwoCommissionUpfrontRate: number;
	public levelThreeCommissionUpfrontRate: number;

	constructor(agentFinancialProfileReport: AgentFinancialProfileReport) {
		Object.assign(this, agentFinancialProfileReport);
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { CONSTANTS } from '@pk/powerkioskutils';

import * as _ from 'lodash';

import { Agent } from '../models';
import { LicensePlan } from '../models/license-plan';

@Pipe({
	name: 'licensePlanControlFilter',
})
export class LicensePlanControlFilterPipe implements PipeTransform {
	private businessFullIds = CONSTANTS.licensePlans.businessFullIds;
	private oldBusinessFullIds = CONSTANTS.licensePlans.oldBusinessFullIds;
	private newAgentBundleIds = CONSTANTS.licensePlans.newAgentBundleIds;
	private oldAgentBundleIds = CONSTANTS.licensePlans.oldAgentBundleIds;
	private agentBundleIds = this.newAgentBundleIds.concat(this.oldAgentBundleIds);
	private newTurnKeyIds = CONSTANTS.licensePlans.newTurnKeyIds;
	private oldTurnKeyIds = CONSTANTS.licensePlans.oldTurnKeyIds;
	private turnKeyIds = this.newTurnKeyIds.concat(this.oldTurnKeyIds);
	private newApiIds = CONSTANTS.licensePlans.newApiIds;
	private oldApiIds = CONSTANTS.licensePlans.oldApiIds;
	private apiIds = this.newApiIds.concat(this.oldApiIds);
	private newAffiliateIds = CONSTANTS.licensePlans.newAffiliateIds;
	private affiliateIds = this.newAffiliateIds;

	// only show the main new ones
	// all other should be considered separate prices
	private showAgentBundle(licensePlan: LicensePlan, agent: Agent) {
		const licensePlanId = licensePlan.id;
		return (!this.agentBundleIds.includes(licensePlanId) || // not a agent bundle, return it
			(this.newAgentBundleIds.includes(licensePlanId) &&
				(agent.hasNoAgentBundle() || agent.hasDefaultAgentBundle())) || // only new if none or new
			(this.oldAgentBundleIds.includes(licensePlanId) && agent.hasSeparateAgentBundle()));
	}

	// only show the main new ones
	// all other should be considered separate prices
	private showTurnKey(licensePlan: LicensePlan, agent: Agent) {
		const licensePlanId = licensePlan.id;
		return (!this.turnKeyIds.includes(licensePlanId) || // not a turn key, return it
			(this.newTurnKeyIds.includes(licensePlanId) &&
				(agent.hasNoTurnKey() || agent.hasDefaultTurnKey())) || // only new if none or new
			(this.oldTurnKeyIds.includes(licensePlanId) && agent.hasSeparateTurnKey()));
	}

	// only show the main new ones
	// all other should be considered separate prices
	private showAPI(licensePlan: LicensePlan, agent: Agent) {
		const licensePlanId = licensePlan.id;
		return (!this.apiIds.includes(licensePlanId) || // not a api, return it
			(this.newApiIds.includes(licensePlanId) && (agent.hasNoAPI() || agent.hasDefaultAPI())) || // only new if none or new
			(this.oldApiIds.includes(licensePlanId) && agent.hasSeparateAPI()));
	}

	// only show to admin
	private showAffiliatePlan(licensePlan: LicensePlan) {
		const licensePlanId = licensePlan.id;
		return (!this.affiliateIds.includes(licensePlanId) || // not a affiliate plan, return it
			false); // false because only admin can view, isImpersonatingOrAdmin already at top level of logic in transform()
	}

	// do not show unless already assigned it
	private showParentBusinessLicenses(licensePlan: LicensePlan, agent: Agent, isImpersonatingOrAdmin: boolean) {
		const licensePlanId = licensePlan.id;
		return (this.businessFullIds.includes(licensePlanId) || // return business full
			(isImpersonatingOrAdmin && this.oldBusinessFullIds.includes(licensePlanId)) || // return special business fulls
			(CONSTANTS.licensePlans.businessLiteIds.includes(licensePlanId) && (agent.hasSeparateBusinessLite())));
	}

	transform(licensePlans: AbstractControl[], parentId: number, agent: Agent, isImpersonatingOrAdmin: boolean): any {
		if (!licensePlans) { return licensePlans; }

		return _.orderBy(parentId
			? licensePlans.filter(l => l.value.entity.parents.some(p => p.id === parentId)
				&& (isImpersonatingOrAdmin ||
					(this.showAgentBundle(l.value.entity, agent) &&
						this.showTurnKey(l.value.entity, agent) &&
						this.showAPI(l.value.entity, agent) &&
						this.showAffiliatePlan(l.value.entity))))
			: licensePlans
				.filter(l => !l.value.entity.parents?.length &&
					this.showParentBusinessLicenses(l.value.entity, agent, isImpersonatingOrAdmin)),
			l => l.value.entity.name, 'desc');
	}
}

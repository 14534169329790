import { Component } from '@angular/core';

@Component({
	selector: 'pk-broker-auction-help',
	templateUrl: './auction-help.component.html',
	styleUrls: ['./auction-help.component.scss'],
})
export class AuctionHelpComponent {

}

export class ReportCommissionKpi {
	public startDate: Date;
	public endDate: Date;
	public issueCount: number;
	public duration: string;

	constructor(reportCommissionKpi: ReportCommissionKpi) {
		Object.assign(this, reportCommissionKpi);
	}
}

import { Component, Input } from '@angular/core';

import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';
import * as models from '../../models';

@Component({
	selector: 'pk-broker-rate-bar',
	templateUrl: './rate-bar.component.html',
	styleUrls: ['./rate-bar.component.scss'],
})
export class RateBarComponent extends AbstractPageForm {

	@Input() public rateBarData: models.RateBar;
	@Input() public symbol: string;
	@Input() public symbol2: string;
	@Input() public units: string;
	@Input() public marginUnits: string;
	@Input() public loadingRateBarData: string;
	@Input() public isDirect: string;
	@Input() public contract: models.Contract;
	@Input() public productIds: string[];

	public isCollapsed = true;

	constructor() {
		super();
	}

	get marketText(): string {
		if (!this.rateBarData?.minBuffer) { return ''; }

		let text = 'Prices are ';
		if (this.rateBarData && this.rateBarData.minBuffer) {
			text += `<span class="bold-text">currently ${this.rateBarData.barText}</span> `
				+ `for your ${this.CONSTANTS.serviceTypes[this.rateBarData.serviceTypeId]} quote`;
		}

		return text;
	}

	get leftPosition(): string {
		return 'calc(' + this.rateBarData.barPercentage + ' - 75px)';
	}
}

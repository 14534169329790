export class AgentActivityStatistics {

	public companyId: string;
	public companyName: string;
	public agentId: string;
	public agentName: string;
	public parentAgentId: string;
	public parentName: string;
	public contactName: string;
	public phone: string;
	public phoneExt: string;
	public phoneCountryId: number;
	public email: string;
	public isActive: boolean;
	public totalTermValue: number;
	public activeTermValue: number;
	public confirmedCommercialContractCount: number;
	public averageMargin: number | null;
	public retentionRate: number | null;
	public auctionClosingRatio: number | null;
	public matrixClosingRatio: number | null;
	public uniqueCommercialAccountsCount: number;
	public dropRatio: number | null;
	public averageTermLength: number | null;
	public reversibleCommission: number;
	public pctRank: number;
	public joinedDate: string | Date;
	public conversionDate: string | Date;
	public deactivationDate: string | Date;
	public winDate: string | Date;
	public dormantDate: string | Date;

	constructor(agentActivityStatistics?: AgentActivityStatistics) {
		Object.assign(this, agentActivityStatistics);
	}
}

import * as _ from 'lodash';

export class FaqEducationReport {

	public criteria!: string;
	public frequency!: number;
	public matches!: number;
	public terms!: string[];
	public matchedArticles!: string[];
	public clickedArticles!: string[];
	public searchers!: string[];

	// internal
	public firstTermsChunk: string[];
	public secondTermsChunk: string[];
	public thirdTermsChunk: string[];

	public firstMatchedArticlesChunk: string[];
	public secondMatchedArticlesChunk: string[];
	public thirdMatchedArticlesChunk: string[];

	public firstArticlesChunk: string[];
	public secondArticlesChunk: string[];
	public thirdArticlesChunk: string[];

	public firstSearchersChunk: string[];
	public secondSearchersChunk: string[];
	public thirdSearchersChunk: string[];

	constructor(faqEducationReport?: FaqEducationReport) {
		Object.assign(this, faqEducationReport);

		this.criteria = this.criteria?.toLowerCase();

		const termsChunks = this.totalTermsChunks();
		this.firstTermsChunk = this.getChunk(termsChunks, 0);
		this.secondTermsChunk = this.getChunk(termsChunks, 1);
		this.thirdTermsChunk = this.getChunk(termsChunks, 2);

		const matchedArticlesChunks = this.totalMatchedArticlesChunks();
		this.firstMatchedArticlesChunk = this.getChunk(matchedArticlesChunks, 0);
		this.secondMatchedArticlesChunk = this.getChunk(matchedArticlesChunks, 1);
		this.thirdMatchedArticlesChunk = this.getChunk(matchedArticlesChunks, 2);

		const articlesChunks = this.totalArticlesChunks();
		this.firstArticlesChunk = this.getChunk(articlesChunks, 0);
		this.secondArticlesChunk = this.getChunk(articlesChunks, 1);
		this.thirdArticlesChunk = this.getChunk(articlesChunks, 2);

		const searchersChunks = this.totalSearchersChunks();
		this.firstSearchersChunk = this.getChunk(searchersChunks, 0);
		this.secondSearchersChunk = this.getChunk(searchersChunks, 1);
		this.thirdSearchersChunk = this.getChunk(searchersChunks, 2);
	}

	private totalTermsChunks(): string[][] {
		return _.chunk(this.terms, Math.ceil(this.terms.length / 3));
	}

	private totalMatchedArticlesChunks(): string[][] {
		return _.chunk(this.matchedArticles, Math.ceil(this.matchedArticles.length / 3));
	}

	private totalArticlesChunks(): string[][] {
		return _.chunk(this.clickedArticles, Math.ceil(this.clickedArticles.length / 3));
	}

	private totalSearchersChunks(): string[][] {
		return _.chunk(this.searchers, Math.ceil(this.searchers.length / 3));
	}

	private getChunk(chunks: string[][], index: number): string[] {
		return chunks.length ? chunks[index] : [];
	}
}

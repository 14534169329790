export class EclPackageDistribution {

	public id: number;
	public eclPackageId: string;
	public numLeadsPercentage: number;
	public minUsage: number;
	public maxUsage: number;

	constructor(eclPackageDistribution?: EclPackageDistribution) {
		Object.assign(this, eclPackageDistribution);
	}
}

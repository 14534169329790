import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CONSTANTS } from '@pk/powerkioskutils';

import { LoadOptions } from 'devextreme/data';
import CustomStore from 'devextreme/data/custom_store';
import DxDataGrid from 'devextreme/ui/data_grid';

import { SecurityService } from 'src/app/security/security.service';
import { HelperService } from 'src/app/shared/helper.service';
import { AgentAdvanced } from 'src/app/shared/models';
import { GraphqlService } from '../../graphql/graphql.service';

// inject this class directly into each component that needs it
// we don't want this to be a global service
@Injectable()
export class AgentSearchService {

	public agentsDataSource: any = {};
	public dataGrid: DxDataGrid;
	public searchForm: FormGroup<{
		search: FormControl<string>;
		showRawQuery: FormControl<boolean>;
		includeParent: FormControl<boolean>;
		includeHouseAccounts: FormControl<boolean>;
		includeSalesManagers: FormControl<boolean>;
		includeIsInHouseIndirectSale: FormControl<boolean>;
		companyId: FormControl<string>;
	}>;

	public agentsTotal: number;
	public agents: AgentAdvanced[];
	public rawSearchQuery: string;
	public shownRoleId: string;
	public companyId: string;

	private licensePlanList = ['"None"', '"Business Lite"', '"Business Full"'];

	constructor(
		private fb: FormBuilder,
		private graphqlService: GraphqlService,
		private helperService: HelperService,
		private securityService: SecurityService,
	) { }

	public buildSearchForm(defaultSearch: string = '', shownRoleId?: string, companyId?: string): void {
		this.shownRoleId = shownRoleId;
		this.companyId = companyId;
		this.searchForm = this.fb.group({
			search: this.fb.control(defaultSearch, [Validators.required]),
			showRawQuery: false as boolean,
			includeParent: false as boolean,
			includeHouseAccounts: false as boolean,
			includeSalesManagers: false as boolean,
			includeIsInHouseIndirectSale: false as boolean,
			companyId,
		});
	}

	get search() { return this.searchForm.get('search'); }
	get showRawQuery() { return this.searchForm.get('showRawQuery'); }
	get includeParent() { return this.searchForm.get('includeParent'); }
	get includeHouseAccounts() { return this.searchForm.get('includeHouseAccounts'); }
	get includeSalesManagers() { return this.searchForm.get('includeSalesManagers'); }
	get includeIsInHouseIndirectSale() { return this.searchForm.get('includeIsInHouseIndirectSale'); }
	get loggedInUser() { return this.securityService.authFields?.loggedInUser; }

	public activeSelections(data: any) {
		data.dataSource.postProcess = () => [
			{ value: ['is_active', '=', 1], text: 'Yes' },
			{ value: ['is_active', '=', 0], text: 'No' },
		];
	}

	public paymentFailedSelections(data: any) {
		data.dataSource.postProcess = () => [
			{ value: ['has_failed_payment', '=', 1], text: 'Yes' },
			{ value: ['has_failed_payment', '=', 0], text: 'No' },
		];
	}

	public licensePlanSelections(data: any) {
		data.dataSource.postProcess = () => [
			{ value: ['license_plan_name', '=', '"None"'], text: 'None' },
			{ value: ['license_plan_name', '=', '"Business Lite"'], text: 'Business Lite' },
			{ value: ['license_plan_name', '=', '"Business Full"'], text: 'Business Full' },
		];
	}

	public setupAgentDataGridFromGridInitEvent(event: any): void {
		this.dataGrid = event.component;
	}

	public setupAgentGridCustomStore(): void {
		this.agentsDataSource.store = new CustomStore({
			key: 'agentId',
			load: async (loadOptions: LoadOptions) => {
				if (loadOptions['dataField']) {
					return Promise.resolve({ data: this.agents, totalCount: this.agentsTotal });
				}

				const type = 'lucene';
				let search = this.helperService
					.getAgentSearchQuery({
						search: this.search.value,
						includeParent: this.includeParent.value,
						defaultSearch: `*`,
					});
				this.rawSearchQuery = search;
				const loadOptionsSort = (Array.isArray(loadOptions.sort) ? loadOptions.sort : [loadOptions.sort]).filter(Boolean);
				const sort: string[] = loadOptionsSort?.length
					? loadOptionsSort.map(s => `${s['selector']} ${(s['desc'] ? 'desc' : 'asc')}`)
					: ['name asc'];
				const filterMap = this.helperService.buildFilterMap(this.dataGrid, this.dataGrid.getCombinedFilter(), {});

				if (filterMap.hasOwnProperty('!license_plan_name')) {
					filterMap['license_plan_name'] = this.licensePlanList
						.filter(s => Array.isArray(filterMap['!license_plan_name'])
							? 'NOT ' + filterMap['!license_plan_name'].some(st => st === s)
							: s !== filterMap['!license_plan_name']);
				}

				if (filterMap.hasOwnProperty('license_plan_name')) {
					const planNames = Array.isArray(filterMap['license_plan_name'])
						? filterMap['license_plan_name'] : [filterMap['license_plan_name']];
					const includeNegation = planNames.includes('"None"');
					const planNameQuery = planNames.filter(p => p !== '"None"').join(' OR ');

					// https://stackoverflow.com/a/37173197/924501
					const licensePlanName = (planNameQuery ? 'license_plan_name:(' + planNameQuery + ')' : '');
					const requiresOrInNegation = (planNameQuery ? ' OR ' : '');
					const licensePlanNameNegation = (includeNegation ? requiresOrInNegation + '(*:* OR -license_plan_name:*))' : ')');
					search += ' AND (' + licensePlanName + licensePlanNameNegation;
				}

				if (filterMap.hasOwnProperty('!is_active')) {
					filterMap['is_active'] = 'NOT ' + filterMap['!is_active'];
				}

				if (filterMap.hasOwnProperty('is_active')) {
					search += ' AND is_active:(' + filterMap['is_active'] + ')';
				}

				if (filterMap.hasOwnProperty('!has_failed_payment')) {
					filterMap['has_failed_payment'] = 'NOT ' + filterMap['!has_failed_payment'];
				}

				if (filterMap.hasOwnProperty('has_failed_payment')) {
					search += ' AND has_failed_payment:(' + filterMap['has_failed_payment'] + ')';
				}

				if (this.shownRoleId) {
					search += ` AND role_id:(${this.shownRoleId})`;
					if (this.loggedInUser.roleId === CONSTANTS.userRoles.salesManager.roleId) {
						search += ` AND brother_id:(${this.loggedInUser.agentId})`;
					}
				}

				if (this.companyId) {
					search += ` AND company_id:"${this.companyId}"`;
				}

				if (this.includeHouseAccounts.value) {
					search += ' AND NOT (parent_id:*) AND NOT (brother_id:*)';
				}

				if (this.includeSalesManagers.value) {
					search += ` AND (role_id:(${CONSTANTS.userRoles.salesManager.roleId}) AND NOT (parent_id:*))`;
				}

				if (this.includeIsInHouseIndirectSale.value) {
					search += ` AND is_inhouse_indirect_sale:1`;
				}

				// remove from the beginning if it has the "search all" operator
				if (search.startsWith('* AND ')) {
					search = search.replace('* AND ', '');
				}

				const agentResult = await this.graphqlService.getAgentAdvancedListData(
					{ search, type },
					sort.join(', '),
					loadOptions.take,
					loadOptions.skip,
				);

				this.agents = agentResult.data.agentsAdvanced.message.map(a => new AgentAdvanced(a));
				this.agentsTotal = agentResult.data.agentsAdvanced.total;
				return {
					data: this.agents,
					totalCount: this.agentsTotal,
				};
			},
		});
	}
}

import * as moment from 'moment-timezone';

export class LocationMonthlyUsage {

	public period: Date;
	public periodStr: string;
	public usage: number;
	public demand: number;

	constructor(monthlyUsage: LocationMonthlyUsage, useFuture = false) {
		Object.assign(this, monthlyUsage);

		this.periodStr = monthlyUsage.period as any;
		const yearAdd = useFuture ? 0 : 1;
		this.period = this.period ? new Date(moment(this.period).add(yearAdd, 'year').format('MMM DD, YYYY hh:mm:ss a')) : null;
	}
}

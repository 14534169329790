import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';
import * as moment from 'moment-timezone';

import { Commission } from '../models/commission';

@Pipe({
	name: 'agentCommissions',
})
export class AgentCommissionsPipe implements PipeTransform {
	transform(commissions: Commission[], agentId: string, parentId?: string, brotherId?: string): Commission[] {
		return _.orderBy(commissions && agentId
			? commissions.filter(c => c.agentId === agentId && moment(c.commissionDate).isBefore(moment()) && c.paidDate)
			: commissions, [
			c => c.commissionDate ? c.commissionDate.valueOf() : 0,
			c => c.cycleStartDate ? c.cycleStartDate.valueOf() : 0,
			c => c.agentId === parentId ? 2 : c.agentId === brotherId ? 3 : 1],
			['asc', 'asc', 'asc'])
			.filter(c => c.agentName); // agent name is set if the commission belongs to the agent(s) on the contract
	}
}

import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { UserGridConfig } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-contract-new-user-grid-config',
	templateUrl: './contract-new-user-grid-config.component.html',
	styleUrls: ['./contract-new-user-grid-config.component.scss'],
})
export class ContractNewUserGridConfigModalComponent extends AbstractPageForm {

	public onSuccess: Subject<UserGridConfig> = new Subject();

	public userGridConfig: UserGridConfig = new UserGridConfig();

	public columns: any[];

	constructor(
		private fb: FormBuilder,
		public activeModal: BsModalRef,
	) {
		super();
		this.submitText = 'Save';
		this.submittingText = 'Saving...';
		this.submitAudit = 'contract-new-user-grid-config:save';
	}

	public async loadPageData(): Promise<void> { }

	public getForm() {
		return this.fb.group({
			name: ['', [Validators.required]],
			isDefault: false,
		});
	}

	protected async onFormSubmitted() {
		this.userGridConfig.name = this.form.name.value;
		this.userGridConfig.isDefault = this.form.isDefault.value;
		this.onSuccess.next(this.userGridConfig);
		this.activeModal.hide();
	}
}

<pk-broker-modal>
	<ng-template #headerTemplate>
		<h5 class="modal-title">User Agent Lookup</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<form [formGroup]="formGroup" *ngIf="!loading" (submit)="submit()">
			<div class="input-group mb-3">
				<input type="text" class="form-control" placeholder="Search - (Minimum of 4 characters required)" formControlName="criteria" required />
				<div class="input-group-append">
					<button type="button" class="btn btn-default" [disabled]="!formGroup.valid" (click)="formGroup.reset()">
						<fa-icon [icon]="['fas', 'times']"></fa-icon>
					</button>
					<button type="button" class="btn btn-default" [disabled]="!formGroup.valid" (click)="submit()">
						<fa-icon [icon]="['fas', 'search']"></fa-icon>
					</button>
				</div>
			</div>
		</form>
		<dx-data-grid
			*ngIf="!loading"
			id="gridContainer"
			[dataSource]="userAgentsDataSource"
			[allowColumnResizing]="true"
			[columnAutoWidth]="true"
			[showBorders]="true"
			[remoteOperations]="true"
			[rowAlternationEnabled]="true"
			[hoverStateEnabled]="true"
			(onInitialized)="onUserAgentsDataGridInit($event)">
			<dxo-scrolling [useNative]="true"></dxo-scrolling>
			<dxo-header-filter [visible]="true"></dxo-header-filter>
			<dxi-column caption="Name" dataField="name" [allowHeaderFiltering]="false" sortOrder="asc"></dxi-column>
			<dxi-column caption="Email" dataField="email" cellTemplate="emailCellTemplate" dataType="string" [allowHeaderFiltering]="false"></dxi-column>
			<dxi-column
				caption="Active"
				dataField="isActive"
				cellTemplate="isActiveCellTemplate"
				dataType="boolean"
				[allowHeaderFiltering]="false"></dxi-column>
			<dxi-column caption="Actions" cellTemplate="actionsCellTemplate" [allowHeaderFiltering]="false"></dxi-column>
			<dxo-paging [pageSize]="25"></dxo-paging>
			<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
			<div *dxTemplate="let d of 'emailCellTemplate'">
				<div class="chart-cell">
					{{ getEmail(d.data.logins) }}
				</div>
			</div>
			<div *dxTemplate="let d of 'isActiveCellTemplate'">
				<div class="chart-cell">
					<fa-icon [icon]="['fas', 'check']" *ngIf="d.data.isActive"></fa-icon>
				</div>
			</div>
			<div *dxTemplate="let d of 'actionsCellTemplate'">
				<div class="chart-cell text-center">
					<button class="btn btn-primary px-4" (click)="selectUser(d.data)" [disabled]="selectingUser">Select</button>
				</div>
			</div>
		</dx-data-grid>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" class="btn btn-secondary" (click)="close()">Close</button>
	</ng-template>
</pk-broker-modal>

import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

@Pipe({
	name: 'join'
})
export class JoinPipe implements PipeTransform {

	transform(value: any[], property: string, delimiter: string): any {
		return value.map(v => _.get(v, property)).join(delimiter);
	}

}

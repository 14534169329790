import * as _ from 'lodash';

import { ReportCommissionPaid } from './report-commission-paid';

export class ReportAgentCommissionPaid {

	public name: string;
	public commissionTotal: number;
	public adjustmentTotal: number;
	public commissions: ReportCommissionPaid[];

	constructor(reportCommissionAudit: ReportAgentCommissionPaid) {
		Object.assign(this, reportCommissionAudit);

		this.commissions = this.commissions ? this.commissions.map(c => new ReportCommissionPaid(c)) : this.commissions;
		this.commissionTotal = _.sumBy(this.commissions.filter(c => c.commissionId), c => c.amount);
		this.adjustmentTotal = _.sumBy(this.commissions.filter(c => c.accountAdjustmentId), c => c.amount);
	}
}

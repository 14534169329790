import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filterStateColumns'
})
export class FilterStateColumnsPipe implements PipeTransform {

	transform(stateColumns: any[], sortedColumns: any[], adminColumns: string[]): any[] {
		const dataFields = sortedColumns.map(s => s.dataField);
		return stateColumns && sortedColumns
			? stateColumns.filter(s => !dataFields.includes(s.dataField) && !adminColumns.includes(s.dataField))
			: stateColumns;
	}

}

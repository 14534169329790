import * as moment from 'moment-timezone';

export class PostProcessingCommissionAudit {
	public id: number;
	public reportDate: Date;
	public customerId: string;
	public customerName: string;
	public contractId: string;
	public contractNum: string;
	public contractLocationId: string;
	public isResidential: boolean;
	public externalId: string;
	public utilityAccountNum: string;
	public supplierId: string;
	public supplierName: string;
	public forecasted: number;
	public forecastedUsage: number;
	public lateActual: number;
	public actualTotal: number;
	public recovered: number;
	public actual: number;
	public actualUsage: number;
	public issue: string;
	public status: string;
	public deviation: number;
	public receivableAuditIssueOperationStatusId: number;
	public forecastedDate: Date;
	public postProcessingStatus: string;
	public dateOffset: number;
	public originalDate: Date;
	public receivableOperationStatusTypeId: number;
	public receivableAuditIssueTypeId: number;
	public postProcessingCommissionAuditStatusId: number;
	public postProcessingCommissionAuditStatusTypeId: number;
	public description: string;
	public minPendingCommissionId: string;
	public commissionTypeId: number;
	public forecastedMeterStartDate: Date;
	public forecastedMeterEndDate: Date;
	public meterStartDate: Date;
	public meterEndDate: Date;
	public isReviewed: boolean;
	public flagReason: string;

	constructor(postProcessingCommissionAudit: PostProcessingCommissionAudit) {
		Object.assign(this, postProcessingCommissionAudit);

		this.reportDate = this.reportDate ? new Date(moment(this.reportDate).format('MMM DD, YYYY hh:mm:ss a')) : this.reportDate;
		this.forecastedDate = this.forecastedDate
			? new Date(moment(this.forecastedDate).format('MMM DD, YYYY hh:mm:ss a'))
			: this.forecastedDate;
	}
}

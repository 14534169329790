export class ViewPurchasingLayerAction {
	public id: string;
	public message: string;
	public details: string;
	public date: Date;
	public noteTypeId: string;
	public noteTypeName: string;
	public noteTypeDescription: string;
	public noteTypeImageIconPrefix: string;
	public noteTypeImageIcon: string;
	public addDate: Date;

	constructor(viewPurchasingLayerAction?: ViewPurchasingLayerAction) {
		Object.assign(this, viewPurchasingLayerAction);
	}
}

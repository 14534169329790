export class ComplianceMonitorAgent {

	public agentId: string;
	public name: string;
	public isOnHold: boolean;
	public dropRatio: number;
	public quoted: number;
	public quotedPercentage: number;
	public signed: number;
	public signedPercentage: number;
	public confirmed: number;
	public confirmedPercentage: number;
	public rejected: number;
	public rejectedPercentage: number;
	public dropped: number;
	public droppedPercentage: number;

	constructor(complianceMonitorAgent?: ComplianceMonitorAgent) {
		Object.assign(this, complianceMonitorAgent);
	}
}

import { NoteType } from './note-type';

export class AgentEngagementEventCategoryNoteType {
	public id: number;
	public agentEngagementEventCategoryId: number;
	public noteTypeId: string;
	public isSuggested: boolean;

	public noteType: NoteType;
}

<div class="row d-flex justify-content-center" *ngIf="supplierNote">
	<div class="col-12 col-md-10 col-lg-8 col-xl-6">
		<div #alert class="alert alert-notification alert-dismissible fade" [ngClass]="{ show: show }" role="alert">
			<span class="alert-icon"><fa-icon [icon]="['fas', 'comments-dollar']"></fa-icon></span>
			<p class="header">Please Note:</p>
			<p>{{ supplierNote }}</p>
			<a type="button" class="close" aria-label="Close" (click)="hideAlert()" *ngIf="!hideCloseButton">
				<fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
			</a>
		</div>
	</div>
</div>

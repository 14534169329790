import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { environment } from 'src/environments/environment';

@Component({
	selector: 'pk-broker-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {

	@Input() customBodyClass: string;
	@Input() customBodyStyle: any;
	@Input() customFooterClass: string;
	@Input() customHeaderStyle: any;
	@Input() customCloseButtonIcon = 'times';
	@Input() customCloseButtonStyle: any;

	@Input() showBody = true;
	@Input() showFooter = true;
	@Input() showHeaderCloseButton = true;
	@Input() useDuotoneCloseButtonIcon = false;

	@Output() onClose: EventEmitter<number> = new EventEmitter();

	public envName = `broker${environment.name}`;
	@ContentChild('headerTemplate') public headerTemplate: TemplateRef<void>;
	@ContentChild('bodyTemplate') public bodyTemplate: TemplateRef<void>;
	@ContentChild('footerTemplate') public footerTemplate: TemplateRef<void>;

	constructor(
		private activeModal: BsModalRef,
	) { }

	public close(): void {
		this.onClose.emit();
		this.activeModal.hide();
	}
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { RfqSession, RfqSessionMarketSuggestion } from 'src/app/shared/models';

@Component({
	selector: 'pk-broker-auction-market-suggestions',
	templateUrl: './auction-market-suggestions.component.html',
	styleUrls: ['./auction-market-suggestions.component.scss']
})
export class AuctionMarketSuggestionsComponent {
	@Input() rfqSession: RfqSession;
	@Input() showMarketSuggestionSuccess: boolean;

	@Output() selectedMarketSuggestion: EventEmitter<RfqSessionMarketSuggestion> = new EventEmitter();

	public chooseMarketSuggestion(marketSuggestion: RfqSessionMarketSuggestion): void {
		this.selectedMarketSuggestion.emit(marketSuggestion);
	}
}

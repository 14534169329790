import { Acknowledgement } from './acknowledgement';
import { CustomerDisclosureStatement } from './customer-disclosure-statement';
import { LetterOfAuthorization } from './letter-of-authorization';
import { OhepNotice } from './ohep-notice';
import { ServiceType } from './service-type';
import { SpecialNotice } from './special-notice';
import { State } from './state';
import { Supplier } from './supplier';
import { Utility } from './utility';

export class Disclaimer {

	public disclaimerId: number;
	public supplierId: string;
	public stateId: string;
	public serviceTypeId: string;
	public utilityId: string;
	public showSsn: boolean;
	public showDob: boolean;
	public heading: string;
	public body: string;
	public showTaxExemptReasons: boolean;
	public switchTypeCode: string;
	public rateClass: string;

	public letterOfAuthorization: LetterOfAuthorization;
	public ohepNotice: OhepNotice;
	public customerDisclosureStatement: CustomerDisclosureStatement;
	public specialNotice: SpecialNotice;
	public acknowledgements: Acknowledgement[];
	public supplier: Supplier;
	public serviceType: ServiceType;
	public utility: Utility;
	public state: State;

	constructor(disclaimer?: Disclaimer) {
		Object.assign(this, disclaimer);
	}
}

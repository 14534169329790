export class SupplierMarketMatrixRankingStats {
	public latestStatsReportStates: {
		stateLong: string; stateId: string;
		avgAdjustment: number; maxAdjustment: number; minAdjustment: number;
	}[];

	constructor(supplierMarketMatrixRankingStats: SupplierMarketMatrixRankingStats) {
		Object.assign(this, supplierMarketMatrixRankingStats);
	}
}

import * as moment from 'moment-timezone';

export class ReportQuoteLog {

	public addDate: Date;
	public stateShort: string;
	public commodity: string;
	public utilityName: string;
	public rateClass: string;
	public agentName: string;

	constructor(reportQuoteLog: ReportQuoteLog) {
		Object.assign(this, reportQuoteLog);

		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
	}
}

import { Pipe, PipeTransform } from '@angular/core';

import { LicensePlan } from '../models/license-plan';

@Pipe({
	name: 'formatLicensePlans'
})
export class FormatLicensePlansPipe implements PipeTransform {
	transform(licensePlans: LicensePlan[]): string {
		return licensePlans
			? licensePlans.filter(l => ['Business Full', 'Business Lite'].includes(l.name)).map(l => l.name).join(', ')
			: '';
	}
}

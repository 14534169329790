import * as _ from 'lodash';
import * as moment from 'moment-timezone';

import { LocationMonthlyUsage } from './location-monthly-usage';
import { PeakLoadContribution } from './peak-load-contribution';

export class LocationUsage {

	public contractLocationId: string;
	public plc: PeakLoadContribution;
	public networkPlc: PeakLoadContribution;
	public loadProfile: string;
	public customLoadFactor: string;
	public usageData: LocationMonthlyUsage[];
	public utilityErrorMessage: string;
	public utilityErrorMessageDetail: string;

	// used internally
	public cleanData: any;

	constructor(locationUsage?: LocationUsage) {
		Object.assign(this, locationUsage);

		this.plc = this.plc ? new PeakLoadContribution(this.plc) : this.plc;
		this.networkPlc = this.networkPlc ? new PeakLoadContribution(this.networkPlc) : this.networkPlc;

		this.usageData = _.chain(locationUsage.usageData)
			.groupBy(u => moment(u.period).format('YYYY-MM-DD'))
			.toPairs()
			.map(g => new LocationMonthlyUsage({
				period: g[0],
				demand: _.sum(g[1].map(g1 => g1.demand)),
				usage: _.sum(g[1].map(g1 => g1.usage)),
			} as any))
			.orderBy(g => g.period.getTime(), 'desc')
			.take(12)
			.reverse()
			.value();

		if (this.usageData && this.usageData.length) {
			this.cleanData = this.process(12);
		}
	}

	private process(nLimit: number): any {
		const months = this.getMonthArray(nLimit);
		const usages = this.getTotalUsage(nLimit);
		const totalDemands = this.getTotalDemand(nLimit);
		const rawDemands = this.getRawDemand(nLimit);
		const fullData = this.getCombinedData(months, usages, totalDemands, rawDemands);

		const sumConsumed = this.sumByKey('consumed', fullData);
		const objMaxDemand = this.maxByKey('rawDemand', fullData);
		const objMaxUsage = this.maxByKey('consumed', fullData);

		const fullDataByMonth = _.keyBy(fullData, 'month');

		return {
			labels: months,
			usage: usages,
			demand: totalDemands,
			fullData,
			fullDataByMonth,
			sumConsumed,
			objMaxDemand,
			objMaxUsage,
		};
	}

	public numToMonth(nMonth: number): string {
		switch (nMonth) {
			case 1:
				return 'Jan';
			case 2:
				return 'Feb';
			case 3:
				return 'Mar';
			case 4:
				return 'Apr';
			case 5:
				return 'May';
			case 6:
				return 'Jun';
			case 7:
				return 'Jul';
			case 8:
				return 'Aug';
			case 9:
				return 'Sep';
			case 10:
				return 'Oct';
			case 11:
				return 'Nov';
			case 12:
				return 'Dec';
			default:
				return 'None';
		}
	}

	public getCombinedData(arrMonths: string[], arrConsumed: number[], arrPurchased: number[], arrRawDemand: number[]): any[] {
		const arrOut = [];

		for (let i = 0; i < arrMonths.length; i++) {
			const objTmp = {};

			objTmp['month'] = arrMonths[i];
			objTmp['consumed'] = arrConsumed[i];
			objTmp['purchased'] = arrPurchased[i];
			objTmp['rawDemand'] = arrRawDemand[i];
			arrOut.push(objTmp);
		}

		return arrOut;
	}

	public getMonthArray(nLimit: number): string[] {
		const arrOut: string[] = [];
		for (let i = 0; i < this.usageData.length; i++) {
			if (i >= nLimit) {
				break;
			}
			const usage = this.usageData[i];
			const arrParts = usage.periodStr.split('T')[0].split('-');
			const strMonth = this.numToMonth(Number(arrParts[1])) + ' ' + Number(arrParts[0]);
			arrOut.push(strMonth);
		}

		return arrOut;
	}

	public getTotalUsage(nLimit: number): number[] {
		const arrOut = [];
		for (let i = 0; i < this.usageData.length; i++) {
			if (i >= nLimit) {
				break;
			}
			const nUsage = this.usageData[i]['usage'];
			arrOut.push(nUsage);
		}
		return arrOut;
	}

	public getTotalDemand(nLimit: number): number[] {
		const arrOut = [];
		for (let i = 0; i < this.usageData.length; i++) {
			if (i >= nLimit) {
				break;
			}
			let nDemand = this.usageData[i]['demand'] * 30 * 24;
			nDemand = Math.floor(nDemand);
			arrOut.push(isNaN(nDemand) ? 0 : nDemand);
		}
		return arrOut;
	}

	public getRawDemand(nLimit: number): number[] {
		const arrOut = [];
		for (let i = 0; i < this.usageData.length; i++) {
			if (i >= nLimit) {
				break;
			}
			const nDemand = this.usageData[i]['demand'];
			arrOut.push(nDemand);
		}
		return arrOut;
	}

	public sumByKey(strKey: string, objData: any): number {
		let nSum = 0;
		const arrData = objData;
		for (const data of arrData) {
			nSum += data[strKey];
		}
		return nSum;
	}

	public maxByKey(strKey: string, objData: any): number {
		let maxValue = objData[0][strKey];
		let objMax = objData[0];
		for (const data of objData) {
			if (data[strKey] > maxValue) {
				maxValue = data[strKey];
				objMax = data;
			}
		}
		return objMax;
	}
}

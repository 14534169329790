import { AgentAuctionClosedStat } from './agent-auction-closed-stat';

export class AgentBiddingStats {
	public closingRatio: number;
	public auctionsClosed: AgentAuctionClosedStat[];
	public termValue: number;
	public contractsAwardedNotClosed: number;
	public contractsDroppedRatio: number;
	public timeSinceAddedYears: number;
	public timeSinceAddedMonths: number;
}

export class UserSurveyStats {

	public score: number;
	public surveysShown: number;
	public engagements: number;
	public numNotClickedInitialLink: number;

	constructor(userSurveyStats?: UserSurveyStats) {
		Object.assign(this, userSurveyStats);
	}
}

<pk-broker-modal customFooterClass="justify-content-end pt-0">
	<ng-template #headerTemplate>
		<h5 class="modal-title">{{ title }}</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<div class="container-fluid">
			<div class="row">
				<div class="col text-center">
					<p class="mb-0" [innerHtml]="body"></p>
					<p class="mb-0" [innerHtml]="body2"></p>
					<br />
					<a href="javascript:void(0)" id="csatModalLink" (click)="showCsatFormModal()">
						<fa-icon [icon]="['far', 'comments']"></fa-icon>
						How did we do?
					</a>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" class="btn btn-primary" (click)="close()">OK</button>
	</ng-template>
</pk-broker-modal>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { StringUtils } from '@pk/powerkioskutils';

import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { AttachmentService } from 'src/app/attachment/attachment.service';
import { NotificationService } from 'src/app/notification/services/notification.service';
import { GenerateLoaLoeModalComponent } from 'src/app/shared/modals/generate-loa-loe-modal/generate-loa-loe-modal.component';
import { GraphqlService } from '../../graphql/graphql.service';
import { HelperService } from '../../shared/helper.service';
import { Attachment, BlockType, Contract, Document, ESign, EsignDocument, Holiday, Product, RfqSession, ServiceTypeUnit, Supplier } from '../../shared/models';
import { AbstractPageForm } from '../../shared/pages/page-form.abstract';
import { auctionAtLeastOneSupplierValidator } from '../../shared/validators/auction-at-least-one-supplier.validator';
import { productsValidator } from '../../shared/validators/products.validator';

@Component({
	selector: 'pk-broker-auction',
	templateUrl: './auction.component.html',
	styleUrls: ['./auction.component.scss'],
})
export class AuctionComponent extends AbstractPageForm {

	@Input() contract: Contract;
	@Input() products: Product[];
	@Input() blockTypes: BlockType[];
	@Input() suppliers: Supplier[];
	@Input() otherSuppliers: Supplier[];
	@Input() holidays: Holiday[];
	@Input() defaultProduct: Product;
	@Input() serviceTypeUnit: ServiceTypeUnit;

	@Output() auctionCreateCompleted: EventEmitter<RfqSession> = new EventEmitter();
	@Output() updateAuctionView: EventEmitter<'auction' | 'help' | 'requirement'> = new EventEmitter();

	public generatingLoaLoe = false;
	public uploadingDocument = false;

	public terms: number[];
	public lags: number[];
	public margines: number[];
	public maxBidsList: number[];
	public endTimes: moment.Moment[];
	public greenPercentages: number[];
	public initialFixedUsages: number[];

	public actualStartDate: moment.Moment;
	public maxAuctionDate: moment.Moment;
	public defaultDaysUntilAuctionEnd: number;
	private minAuctionEndDate: moment.Moment;

	public ignoreRequiredDocuments = false;

	constructor(
		private graphqlService: GraphqlService,
		private attachmentService: AttachmentService,
		private toastrService: ToastrService,
		private notificationService: NotificationService,
		private helperService: HelperService,
		private modalService: BsModalService,
		private fb: FormBuilder,
	) {
		super();
		this.submitText = 'Create Session';
		this.submitAudit = 'auction:createRfqSession';
	}

	public yesNoTextMap = (option: boolean) => option ? 'Yes' : 'No';
	public maxBidsTextMap = (option: number) => `${option} bid${option === 1 ? '' : 's'}`;
	public marginesTextMap = (option: number) => `${option} ${this.serviceTypeUnit.marginUnits}`;
	public endTimeTextMap = (option: moment.Moment) => `${option.format('hh:mm A')} CT`;
	public termTextMap = (option: number) => `${option} month${option === 1 ? '' : 's'}`;
	public greenPercentageTextMap = (option: number) => `${option}%`;
	public lagTextMap = (option: number) => `+${option} month${option === 1 ? '' : 's'}`;
	public initialFixedUsageTextMap = (option: number) => `${option}%`;

	get agentIsOnHold(): boolean {
		return this.loggedInUser.agent?.isOnHold;
	}

	get isEnterpiseAgent(): boolean {
		return this.loggedInUser?.agent && this.loggedInUser?.agent?.isEnterprise;
	}

	public async loadPageData(): Promise<void> {
		this.terms = this.helperService.generateNumberList(1, 59)
			.concat(this.helperService.generateNumberList(60, 120, 12));
		this.lags = this.helperService.generateNumberList(1, 60);
		this.margines = this.helperService.generateNumberList(this.loggedInUser.isSaas ? 15 : 9, 0, 0.5);
		this.maxBidsList = this.helperService.generateNumberList(1, this.loggedInUser.isSaas ? 5 : 3);
		this.greenPercentages = this.helperService.generateNumberList(0, 100, 10);
		this.initialFixedUsages = this.helperService.generateNumberList(0, 100, 5);
		this.endTimes = this.helperService.generateTimesListFromMoment(moment([1990, 1, 1, 10, 0, 0]), moment([1990, 1, 1, 17, 0, 0]), 30);
		this.defaultDaysUntilAuctionEnd = _.max(this.contract.locations.map(l => l.utility.defaultDaysUntilAuctionEnd));
		const startDate = moment().startOf('day');
		this.moveDayOffOfWeekendAndHoliday(startDate);
		this.actualStartDate = startDate;
		this.maxAuctionDate = moment().add(30, 'days');
		this.minAuctionEndDate = this.getDefaultEndDateFromStart(this.actualStartDate, this.defaultDaysUntilAuctionEnd);
	}

	public moveDayOffOfWeekendAndHoliday(date: moment.Moment): void {
		while ([0, 6].includes(date.day())) {
			date.add(1, 'day');
		}
		while (this.holidays.find(h => moment(h.date).isSame(date, 'day'))) {
			date.add(1, 'day');
		}
	}

	public getForm() {
		return this.fb.group({
			maxBids: 3,
			margin: [this.contract.margin / this.contract.serviceType.multiplier, [Validators.required]],
			endDate: [this.minAuctionEndDate, [Validators.required]],
			endTime: [this.endTimes[6], [Validators.required]],
			instructions: '',
			instructionsAdmin: '',
			isCustomerInvited: false,
			products: this.fb.array(this.initializeDefaultAuctionProducts(), [Validators.required]),
			isOnHold: true,
			suppliers: this.fb.array(this.suppliers?.map(
				s => this.fb.group({
					name: s.name,
					supplierId: s.supplierId,
					supplierSelected: true,
				}),
			) ?? []),
			otherSuppliers: this.fb.array(this.otherSuppliers?.map(
				s => this.fb.group({
					name: s.name,
					supplierId: s.supplierId,
					supplierSelected: false,
				}),
			) ?? []),
		}, { validators: [productsValidator, this.endDateTimeValidator, auctionAtLeastOneSupplierValidator] });
	}

	private initializeDefaultAuctionProducts() {
		return [
			this.getProductGroup(this.defaultProduct, 12),
			this.getProductGroup(this.defaultProduct, 24),
			this.getProductGroup(this.defaultProduct, 36),
			this.getProductGroup(this.defaultProduct, 48),
			this.getProductGroup(this.defaultProduct, 60),
		];
	}

	public getDefaultEndDateFromStart(start: moment.Moment, defaultDays: number): moment.Moment {
		let daysLeft = defaultDays;
		const daysAhead = start.clone();
		while (daysLeft) {
			if (![0, 6].includes(daysAhead.day())) {
				daysLeft--;
			}
			daysAhead.add(1, 'day');
		}
		this.moveDayOffOfWeekendAndHoliday(daysAhead);
		return daysAhead;
	}

	private endDateTimeValidator: ValidatorFn = (control: this['form']['control']): ValidationErrors | null => {
		const endTime = moment(control.get('endTime').value);
		const endDate = moment(control.get('endDate').value).startOf('day');
		endDate.add(endTime.hours(), 'hours');
		endDate.add(endTime.minutes(), 'minutes');
		const now = moment();

		if (endDate.isSameOrBefore(now, 'minutes')) {
			return { endDateInThePast: true };
		}

		if ([0, 6].includes(endDate.day())) {
			return { endDateOnWeekend: true };
		}

		if (this.holidays.find(h => moment(h.date).isSame(endDate, 'day'))) {
			return { endDateOnHoliday: true };
		}

		if (endDate.isAfter(this.maxAuctionDate, 'day')) {
			return { endDateAfterMaxDate: true };
		}

		if (endDate.toDate().getTime() < this.minAuctionEndDate.valueOf()) {
			return { endDateBeforeMinDate: true };
		}

		return null;
	};

	public disableAuctionDates = (dates: any): boolean => {
		const currDateInDates = dates.date as Date;
		const dayOfWeek = currDateInDates.getDay();

		return [0, 6].includes(dayOfWeek) || moment(currDateInDates).isBefore(this.minAuctionEndDate, 'day')
			|| currDateInDates.getTime() > this.maxAuctionDate.toDate().getTime()
			|| !!this.holidays.find(h => h.date.valueOf() === currDateInDates.valueOf());
	};

	private getDefaultProduct(): Product {
		let defaultProduct = this.products.find(p => p.name.toLowerCase().includes('fixed'));
		if (!defaultProduct) {
			defaultProduct = this.products[0];
		}

		return defaultProduct;
	}

	private getProductGroup(product: Subset<Product> = {}, defaultTerm?: number) {
		return this.fb.group({
			type: [product.id ?? this.getDefaultProduct().id, [Validators.required]],
			term: [defaultTerm ?? 12, [Validators.required]],
			lag: [this.lags[0], [Validators.required]],
			blockType: [this.blockTypes[0].name, [Validators.required]],
			blockSize: ['', [Validators.required]],
			greenPercentage: [this.greenPercentages[0], [Validators.required]],
			initialFixedUsage: [this.initialFixedUsages[0], [Validators.required]],
		});
	}

	public addProduct() {
		const group = this.getProductGroup(this.getDefaultProduct());
		this.setProductRowState(group);
		this.form.products.control.push(group);
	}

	private setProductRowState(product: this['form']['products'][0]['control']): void {
		switch (product.value.type) {
			case this.CONSTANTS.products.block: {
				product.get('lag').setValue(this.lags[0]);
				product.get('lag').enable();
				product.get('blockType').setValue(this.blockTypes[0].name);
				product.get('blockType').enable();
				product.get('blockSize').enable();

				product.get('initialFixedUsage').setValue(0);
				product.get('initialFixedUsage').disable();
				break;
			}
			case this.CONSTANTS.products.nymexPlus: {
				product.get('initialFixedUsage').setValue(this.initialFixedUsages[0]);
				product.get('initialFixedUsage').enable();

				product.get('lag').setValue(0);
				product.get('lag').disable();
				product.get('blockType').setValue('0');
				product.get('blockType').disable();
				product.get('blockSize').setValue('');
				product.get('blockSize').disable();
				break;
			}
			default: {
				product.get('lag').setValue(0);
				product.get('lag').disable();
				product.get('blockType').setValue('0');
				product.get('blockType').disable();
				product.get('blockSize').setValue('');
				product.get('blockSize').disable();
				product.get('initialFixedUsage').setValue(0);
				product.get('initialFixedUsage').disable();
			}
		}
	}

	public productChanged(product: this['form']['products'][0]): void {
		this.setProductRowState(product.control);
	}

	get hasBlockProduct() { return this.form.products.some(c => c.type.value === this.CONSTANTS.products.block); }

	public async onFormLoaded(): Promise<void> {
		this.form.products.forEach(p => this.setProductRowState(p.control));
	}

	protected async onFormSubmitted() {
		if (this.ignoreRequiredDocuments) {
			await this.createRfqSession();
			return;
		}

		// even admins should be shown the pricing session requirements
		if (this.contract.isSaveSessionAllowed()) {
			const renewalResult = await this.checkUtilityAccountNumRenewalValidation();
			if (renewalResult) {
				await this.createRfqSession();
			}
		} else {
			this.updateAuctionView.emit('requirement');
		}
	}

	private async createRfqSession(): Promise<void> {
		const isReleasingAuction = !StringUtils.toBoolean(this.form.isOnHold.value);
		const result = !isReleasingAuction || await this.notificationService
			.confirm({
				title: 'Create Pricing Session',
				body: 'You are about to notify the selected suppliers.  Do you want to continue?',
			});
		if (result) {
			const rfqSessionResult = await this.graphqlService.createRfqSession(this.rfqSessionDTO());
			this.auctionCreateCompleted.emit(new RfqSession(rfqSessionResult.data.createRfqSession));
		}
	}

	private async checkUtilityAccountNumRenewalValidation(): Promise<boolean> {
		const result = await this.graphqlService.utilityAccountNumRenewalValidation({
			utilityAccountNum: this.contract.locations[0].utilityAccountNum,
			utilityReferenceNum: this.contract.locations[0].utilityReferenceNum || '',
			utilityId: this.contract.locations[0].utilityId,
			serviceTypeId: this.contract.serviceTypeId,
			effectiveDate: moment(this.contract.effectiveDate).format('YYYY-MM-DDT00:00:00'),
			contractId: this.contract.contractId,
		});

		if (result.data.utilityAccountNumRenewalValidation.hasError) {
			if (this.loggedInUser.isAdmin) {
				return await this.notificationService.confirm({
					title: 'Start Pricing Session',
					body: result.data.utilityAccountNumRenewalValidation.errorMessage,
					customConfirmationButtonText: 'Continue',
				});
			} else {
				this.notificationService.info({
					title: 'Start Pricing Session',
					body: result.data.utilityAccountNumRenewalValidation.errorMessage,
				}, 'ok');
			}
			return false;
		} else {
			return true;
		}
	}

	private rfqSessionDTO() {
		const dto = {
			...this.formGroup.value,
			margin: this.form.margin.value * this.contract.serviceType.multiplier,
			startDate: this.actualStartDate.format('YYYY-MM-DDT00:00:00.000'),
			startTime: moment().format('YYYY-MM-DDTHH:mm:00.000'),
			endDate: moment(this.form.endDate.value).format('YYYY-MM-DDT00:00:00'),
			endTime: this.form.endTime.value.format('YYYY-MM-DDTHH:mm:00'),
			suppliers: this.form.suppliers.concat(this.form.otherSuppliers)
				.filter(s => s.supplierSelected.value === true)
				.map(s => ({ supplierId: s.supplierId.value })),
			products: this.form.products
				.map(p => ({
					productId: p.type.value,
					term: Number(p.term.value),
					lag: Number(p.lag.value) || undefined,
					blockType: p.blockType.value || undefined,
					blockSize: p.blockSize.value || undefined,
					greenPercentage: Number(p.greenPercentage.value) || 0,
					initialFixedUsage: Number(p.initialFixedUsage.value) || 0,
				})),
			agentId: this.contract.agentId,
			contractId: this.contract.contractId,
		};

		delete dto.otherSuppliers;

		return dto;
	}

	public async generateLOALOE(): Promise<void> {
		const modalRef = this.modalService.show(GenerateLoaLoeModalComponent, {
			class: 'pk-modal modal-dialog-centered',
			initialState: { contract: this.contract },
		});

		modalRef.content.submitted.subscribe(submitDetails => {
			if (submitDetails.addLOA) {
				this.onGeneratedLoa();
			}
			if (submitDetails.addLOE) {
				this.onGeneratedLoe();
			}
			this.toastrService.success(
				`${this.contract.hasLOA() ? 'LOA' : ''}
				${this.contract.hasLOA() && this.contract.hasLOE() ? '/' : ''}
				${this.contract.hasLOE() ? 'LOE' : ' '} has been successfully generated and esignature email sent.`, 'Pricing Session');
		});
	}

	private onGeneratedLoa(): void {
		this.contract.esigns.push(new ESign({
			isActive: true,
			isComplete: false,
			addDate: new Date(),
			documents: [
				new EsignDocument({ document: new Document({ name: 'LOA' }) } as EsignDocument),
			],
		} as ESign));
	}

	private onGeneratedLoe(): void {
		this.contract.esigns.push(new ESign({
			isActive: true,
			isComplete: false,
			addDate: new Date(),
			documents: [
				new EsignDocument({ document: new Document({ name: 'LOE' }) } as EsignDocument),
			],
		} as ESign));
	}

	public async uploadDocument(event: EventTarget, name: string, attachmentTypeId: string): Promise<void> {
		const files = this.helperService.getFilesFromEventTarget(event);
		try {
			if (files.length) {
				const file = files[0];

				if (!HelperService.isFileSize(file, 20)) {
					this.toastrService.warning('File must be less than 20 MB.');
					this.helperService.removeFileFromEventTarget(event);
					return;
				}

				this.uploadingDocument = true;
				const formData = new FormData();
				formData.append('files', file, file.name);
				formData.append('contractId', this.contract.contractId);
				formData.append('attachmentTypeId', attachmentTypeId);
				formData.append('description', file.name);
				const result = await this.attachmentService.upload(formData);
				if (result && result.message && result.message.some(f => f['error'])) {
					this.toastrService.warning(result.message.map(f => f['error']).join(' - '), `Add ${name}`);
				} else if (result && result.message) {
					this.toastrService.success(`Successfully Uploaded ${name}`, `Add ${name}`);
					this.contract.attachments.push({ attachmentTypeId } as Attachment);
				} else {
					this.toastrService.warning(`Unable to upload loa. We have been notified and are working to `
						+ `fix the issue. Please check back again in 30 minutes.`, `Add ${name}`);
				}
			}
		} catch (e) {
			this.toastrService.warning(`Unable to upload loa. We have been notified and are working to `
				+ `fix the issue. Please check back again in 30 minutes.`, `Add ${name}`);
		}
		this.uploadingDocument = false;
		this.helperService.removeFileFromEventTarget(event);
	}
}

import { ReportAgentCommissionBrokerPaid } from './report-agent-commission-broker-paid';

export class ReportParentCommissionBrokerPaid {

	public name: string;
	public agents: ReportAgentCommissionBrokerPaid[];

	constructor(reportParentCommissionBrokerPaid: ReportParentCommissionBrokerPaid) {
		Object.assign(this, reportParentCommissionBrokerPaid);

		this.agents = this.agents ? this.agents.map(c => new ReportAgentCommissionBrokerPaid(c)) : this.agents;
	}
}

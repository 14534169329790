import * as moment from 'moment-timezone';

export class MarketUpdateAuction {

	public isRenewal: boolean;
	public stateLong: string;
	public serviceTypeId: string;
	public zone: string;
	public commodity: string;
	public annualUsage: number;
	public bestBid: number;
	public bestBidFmt: string;
	public effectiveDate: Date;
	public rateClass: string;
	public agentId: string;
	public dbaName: string;
	public contractId: string;
	public contractNum: string;
	public utilityId: string;
	public stateId: string;
	public previousContractNum: string;
	public utility: string;
	public bestRate: number;
	public bestRateFmt: string;
	public difference: number;
	public differenceFmt: string;
	public units: string;
	public units2: string;

	constructor(marketUpdateAuction: MarketUpdateAuction) {
		Object.assign(this, marketUpdateAuction);

		this.effectiveDate = this.effectiveDate
			? new Date(moment(this.effectiveDate).format('MMM DD, YYYY 00:00:00 a')) : this.effectiveDate;
	}
}

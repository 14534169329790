import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

import { Rate } from '../models';

@Pipe({
	name: 'filterTerms'
})
export class FilterTermsPipe implements PipeTransform {
	transform(terms: number[], rates: Rate[]): any {
		const rateTerms = _.uniq(rates.map(r => r.term));
		return rateTerms && terms ? terms.filter(s => rateTerms.includes(s)) : terms;
	}
}

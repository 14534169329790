<pk-broker-modal customFooterClass="justify-content-center">
	<ng-template #headerTemplate>
		<h5 class="modal-title">Add Custom Filter</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<form [formGroup]="formGroup" *ngIf="!loading">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12 form-element-container" [class.invalid]="err.name">
						<label for="customFilterName required">Name for this filter preset</label>
						<input type="text" class="form-control" id="customFilterName" formControlName="name" />
						<p class="mb-0 invalid-feedback" *ngIf="err.name">
							<ng-container *ngIf="err.name.required">Name is required</ng-container>
						</p>
					</div>
				</div>
				<div class="row">
					<div class="col-12 form-element-container">
						<div class="custom-control custom-checkbox">
							<input class="custom-control-input" type="checkbox" id="defaultViewCheck" formControlName="isDefault" />
							<label class="custom-control-label" for="defaultViewCheck"> Make this your default view </label>
						</div>
					</div>
				</div>
			</div>

			<pk-broker-page-warnings [warnings]="warnings"></pk-broker-page-warnings>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" class="btn btn-primary" (click)="submit()" [disabled]="submitDisabled">{{ submitText }}</button>
	</ng-template>
</pk-broker-modal>

import { CpanelMenuType } from './cpanel-menu-type';
import { User } from './user';

export class Menu {

	public menuId: string;
	public name: string;
	public parentId: string;
	public menuTypeId: string;
	public eventName: string;
	public queryString: string;
	public idName: string;
	public className: string;
	public iconClassName: string;
	public description: string;
	public thumbnail: string;
	public sortOrder: number;
	public isActive: boolean;
	public addDate: string;
	public userId: string;
	public permissions: string;
	public parent: Menu;
	public menuType: CpanelMenuType[];
	public user: User;

	constructor(menu?: Menu) {
		Object.assign(this, menu);
	}
}

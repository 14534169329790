import { NumberUtils } from '@pk/powerkioskutils';

import * as moment from 'moment-timezone';

import { PurchasingLayerPurchaseHistory } from './purchasing-layer-purchase-history';

export class ViewPurchasingLayerContract {
	public contractId: string;
	public contractNum: string;
	public companyId: string;
	public customerId: string;
	public dbaName: string;
	public serviceTypeId: string;
	public commodity: string;
	public supplierId: string;
	public supplierName: string;
	public annualUsage: number;
	public units: string;
	public effectiveDate: Date;
	public expirationDate: Date;
	public percentageHedgedUnfulfilled: number;
	public alertTriggered: boolean;
	public targetPriceTriggered: boolean;

	public purchasingLayerPurchaseHistories: PurchasingLayerPurchaseHistory[];

	constructor(viewPurchasingLayerContract?: ViewPurchasingLayerContract) {
		Object.assign(this, viewPurchasingLayerContract);

		this.effectiveDate = this.effectiveDate
			? new Date(moment(this.effectiveDate).format('MMM DD, YYYY hh:mm:ss a')) : this.effectiveDate;
		this.expirationDate = this.expirationDate
			? new Date(moment(this.expirationDate).format('MMM DD, YYYY hh:mm:ss a')) : this.expirationDate;

		this.purchasingLayerPurchaseHistories = this.purchasingLayerPurchaseHistories
			? this.purchasingLayerPurchaseHistories.map(p => new PurchasingLayerPurchaseHistory(p)) : this.purchasingLayerPurchaseHistories;
	}

	public getDisplayAnnualUsage(): string {
		const annualUsage = NumberUtils.amountToUnits(this.annualUsage, this.units);

		return Math.round(annualUsage).toString();
	}
}

export class EducationKeyword {

	public id: number;
	public educationId: number;
	public name: string;

	constructor(educationKeyword?: EducationKeyword) {
		Object.assign(this, educationKeyword);
	}
}

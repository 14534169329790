<pk-broker-modal>
	<ng-template #headerTemplate>
		<h5 class="modal-title" id="welcomeModalHeader">Welcome to the Team</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<h5>We would like to remind you of a few critical items in order to avoid issues.</h5>
		<ul>
			<li>
				Residential sign up should be done using the eCommerce website. Please <span class="font-bold">do not</span> use this portal to sign up any
				residential customers.
			</li>
			<li>
				Please click <a [routerLink]="['/education']" target="_blank">here</a> to visit our education page containing multiple video tutorial about
				this portal and all of its features and more.
			</li>
		</ul>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" class="btn btn-secondary" (click)="close()">Close</button>
	</ng-template>
</pk-broker-modal>

<pk-broker-modal>
	<ng-template #headerTemplate>
		<h5 class="modal-title" id="newNoteHeader">New Note</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<pk-broker-loader [show]="loading"></pk-broker-loader>
		<pk-broker-note-add
			*ngIf="!loading"
			[contractId]="contract.contractId"
			[noteTypes]="noteTypes"
			(cancelled)="activeModal.hide()"
			(submitted)="onSubmitNote($event)">
		</pk-broker-note-add>
	</ng-template>
</pk-broker-modal>

export class EmailCriteriaType {
	public id: number;
	public name: string;
	public description: string;
	public sortOrder: number;

	constructor(emailCriteriaType?: EmailCriteriaType) {
		Object.assign(this, emailCriteriaType);
	}
}

import * as moment from 'moment-timezone';

export class AccountUsageReport {
	public contractId: string;
	public contractNum: string;
	public commodity: string;
	public term: number;
	public effectiveDate: Date | string;
	public dbaName: string;
	public annualUsage: number;
	public units: string;
	public utilityAccountNum?: string;
	public utilityReferenceNum?: string;
	public contractLocationId?: string;
	public firstConfirmationDate: Date | string;
	public address1?: string;
	public address2?: string;
	public isActive?: boolean;
	public utilityId?: string;
	public serviceTypeId?: string;

	// internal use
	public accounts: {
		contractLocationId: string;
		utilityAccountNum: string;
		utilityReferenceNum: string;
		address1: string;
		address2: string;
		isActive: boolean;
		utilityId: string;
		serviceTypeId: string;
	}[];
	public downloadButtonText = 'Export Data';
	public downloadButtonDisabled = false;

	constructor(accountUsageReport?: AccountUsageReport) {
		Object.assign(this, accountUsageReport);

		this.effectiveDate = this.effectiveDate
			? new Date(moment(this.effectiveDate).format('MMM DD, YYYY hh:mm:ss a'))
			: this.effectiveDate;
		this.firstConfirmationDate = this.firstConfirmationDate
			? new Date(moment(this.firstConfirmationDate).format('MMM DD, YYYY hh:mm:ss a'))
			: this.firstConfirmationDate;
	}
}



export class ViewSupplierAllowedCommissionPayPlanType {
	public id: number;
	public companyId: string;
	public supplierName: string;
	public supplierId: string;
	public commodity: string;
	public serviceTypeId: string;
	public supplierUpfrontPercentage: number;
	public receivablePayPlanName: string;
	public commissionPayPlanTypeName: string;
	public commissionPayPlanTypeId: string;

	// internal
	public allowedCommissionPayPlanTypes: { [key: string]: { id: number; isAllowed: boolean } };

	constructor(viewSupplierAllowedCommissionPayPlanType?: ViewSupplierAllowedCommissionPayPlanType) {
		Object.assign(this, viewSupplierAllowedCommissionPayPlanType);
	}
}

export class UserSurveySetting {

	public id: number;
	public userSurveyCategoryId: number;
	public show: boolean;

	constructor(userSurveySetting?: UserSurveySetting) {
		Object.assign(this, userSurveySetting);
	}
}

import { NumberUtils } from '@pk/powerkioskutils';

import { HelperService } from '../helper.service';
import { ViewContractDashboard } from './view-contract-dashboard';

export class ViewOperationsContractActivityDashboard extends ViewContractDashboard {

	public contractId: string;
	public contractNum: string;
	public effectiveDate: Date;
	public droppedTermValue: number;
	public rejectedTermValue: number;
	public term: number;
	public annualUsage: number;
	public status: number;
	public displayStatus: string;
	public isResidential: boolean;
	public dbaName: string;
	public companyId: string;
	public commodity: string;
	public supplierName: string;
	public units: string;
	public esignIsComplete: boolean;
	public submissionStatus: number;
	public latestDropDate: Date;

	public displayContractNum: string;

	constructor(contract: any) {
		super(contract);

		this.displayContractNum = HelperService.showAsteriskForContractFirstImpression(Number(this.status), this.isNewBrokerAuction)
			? this.contractNum + '*'
			: this.contractNum;
	}

	public getDisplayAnnualUsage(): string {
		const annualUsage = NumberUtils.amountToUnits(this.annualUsage, this.units);

		return Math.round(annualUsage).toString();
	}
}

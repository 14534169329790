export class EstimatedFutureRenewalTermValue {

	public directSaleTermValue: { month: Date; termValue: number }[];
	public legacyBrokerTermValue: { month: Date; termValue: number }[];
	public newBrokerTermValue: { month: Date; termValue: number }[];
	public totalTermValue: { month: Date; termValue: number }[];

	constructor(estimatedFutureRenewalTermValue: any) {
		Object.assign(this, estimatedFutureRenewalTermValue);
	}
}

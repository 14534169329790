import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { StringUtils } from '@pk/powerkioskutils';

import * as _ from 'lodash';

@Pipe({
	name: 'orderByControl'
})
export class OrderByControlPipe implements PipeTransform {
	transform(controls: AbstractControl[], orderBy: string, direction = 'asc', newestToTop = false): any {
		if (!controls) { return controls; }
		return _.orderBy(controls, [(control: AbstractControl) => orderBy.startsWith('is')
			? StringUtils.toBoolean(control.value[orderBy])
			: control.value[orderBy],
		(control: AbstractControl) => newestToTop ? !control.value['id'] : false],
			direction === 'desc' ? ['desc', 'desc'] : ['asc', 'desc']);
	}
}

import { Agent } from './agent';

export class AgentEclPackage {

	public eclPackageId: number;
	public eclCouponId: number;
	public quantity: number;
	public addDate: Date;

	public agent: Agent;

	constructor(agentEclPackage: AgentEclPackage) {
		Object.assign(this, agentEclPackage);

		this.agent = this.agent ? new Agent(this.agent) : this.agent;
	}
}

export class CpanelLicensePlanParent {

	public id: number;
	public name: string;
	public description: string;
	public licensePlanTypeId: number;

	constructor(cpanelLicensePlanParent?: CpanelLicensePlanParent) {
		Object.assign(this, cpanelLicensePlanParent);
	}
}

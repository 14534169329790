<div class="modal-header" [ngClass]="envName" [ngStyle]="customHeaderStyle">
	<ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
	<button type="button" class="close" (click)="close()" *ngIf="showHeaderCloseButton" [ngStyle]="customCloseButtonStyle">
		<span aria-hidden="true">
			<fa-icon [icon]="['fas', customCloseButtonIcon]" *ngIf="!useDuotoneCloseButtonIcon"></fa-icon>
			<fa-duotone-icon
				[icon]="['fad', customCloseButtonIcon]"
				*ngIf="useDuotoneCloseButtonIcon"
				primaryColor="#ffffff"
				secondaryColor="#1e2574"
				secondaryOpacity="1"></fa-duotone-icon>
		</span>
	</button>
</div>
<div class="modal-body" [ngClass]="customBodyClass" [ngStyle]="customBodyStyle" *ngIf="showBody && bodyTemplate">
	<ng-container [ngTemplateOutlet]="bodyTemplate"></ng-container>
</div>
<div class="modal-footer" [ngClass]="customFooterClass" *ngIf="showFooter && footerTemplate">
	<ng-container [ngTemplateOutlet]="footerTemplate"></ng-container>
</div>

export class EclCoupon {

	public id: number;
	public name: string;
	public stripeId: string;
	public discountFactor: number;
	public numPackagesForDiscount: number;

	constructor(eclCoupon?: EclCoupon) {
		Object.assign(this, eclCoupon);
	}
}

import { Pipe, PipeTransform } from '@angular/core';

import { State } from '../models/state';
import { Zone } from '../models/zone';

@Pipe({
	name: 'zoneFilter',
})
export class ZoneFilterPipe implements PipeTransform {
	transform(zones: Zone[], state: State): any {
		return zones?.length && state?.stateId
			? zones.filter(z => z.name === null || z.stateId === state.stateId)
			: zones;
	}
}

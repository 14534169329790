import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

@Pipe({
	name: 'filterPricingAnalyticsUsages',
})
export class FilterPricingAnalyticsUsagesPipe implements PipeTransform {
	transform(usages: number[],
		stateId: string,
		utilityId: string,
		allFields: { effectiveDate: string; term: number; usage: number; stateId: string; utilityId: string }[],
		effectiveDate?: string, term?: number,
	): unknown {

		return _.chain(allFields)
			.filter(f => (!effectiveDate || f.effectiveDate === effectiveDate)
				&& (!term || f.term === term)
				&& (!utilityId || f.utilityId === utilityId)
				&& (!stateId || f.stateId === stateId))
			.map(f => Number(f.usage)).uniq().orderBy(t => t).value();
	}
}

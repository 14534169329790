import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthResolver } from '../shared/resolvers/auth.resolver';
import { FaqResultsComponent } from './faq-results/faq-results.component';
import { FaqViewComponent } from './faq-view/faq-view.component';
import { FaqComponent } from './faq/faq.component';

const routes: Routes = [{
	path: '',
	component: FaqComponent,
	resolve: { auth: AuthResolver },
	data: { showFeedback: 'FAQ', allowInactive: true },
	children: [{
		path: '',
		component: FaqResultsComponent,
	}, {
		path: 'view/:faqId',
		component: FaqViewComponent,
	}, {
		path: ':type/:id',
		component: FaqResultsComponent,
	}, {
		path: ':type',
		component: FaqResultsComponent,
	}],
}];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class FaqRoutingModule { }

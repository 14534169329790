import { ContractBudgetDetail } from './contract-budget-detail';
import { ContractMarketPerformance } from './contract-market-performance';
import { ContractPrice } from './contract-price';
import { ContractUsage } from './contract-usage';

export class ContractPerformance {

	public contractRate: number;
	public utilityRates: { rate: number; date: Date }[];
	public utilitySavings: number;
	public marketSavings: number;
	public previousContractSavings: number;
	public marketPerformance: ContractMarketPerformance[];
	public contractPrices: ContractPrice[];
	public contractUsages: ContractUsage[];
	public contractUsageOverrides: ContractUsage[];
	public contractBudgetDetail: ContractBudgetDetail;

	constructor(contractPerformance?: ContractPerformance) {
		Object.assign(this, contractPerformance);

		this.contractPrices = this.contractPrices
			? this.contractPrices.map(u => new ContractPrice(u)) : this.contractPrices;
		this.contractUsages = this.contractUsages
			? this.contractUsages.map(u => new ContractUsage(u)) : this.contractUsages;
		this.contractUsageOverrides = this.contractUsageOverrides
			? this.contractUsageOverrides.map(u => new ContractUsage(u)) : this.contractUsageOverrides;
	}
}

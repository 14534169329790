export class AgentListReportTotals {

	public numAuctions: number;
	public numAuctionsElec: number;
	public numAuctionsGas: number;
	public closingRatio: number;
	public termValue: number;
	public termValueMatrix: number;
	public termValueAuction: number;
	public contractsAwardedNotSigned: number;
	public contractsDroppedRatio: number;

	constructor(agentListReportTotals?: AgentListReportTotals) {
		Object.assign(this, agentListReportTotals);
	}
}

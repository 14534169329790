export class LpPriceStat {
	public date: Date;
	public average: number;
}

export class LpPurchaseHistoryStat {
	public date: Date;
	public quantity: number;
	public floatingQuantity: number;
	public price: number;
	public nymexFloatingPrice: number;
	public energyFloatingPrice: number;
	public ancillaryFloatingPrice: number;
	public capacityFloatingPrice: number;
	public congestionFloatingPrice: number;
	public futureFloatingPrice: number;
	public purchaseHistory: LpPurchaseDateHistory[];
}
export class LpPurchaseDateHistory {
	public price: number;
	public quantity: number;
}

export class LpRatesComparisonStat {
	public average: number;
	public floatingPrice: number;
	public date: Date;
	public accAverage: number;
}

import { Component, ElementRef, Input, ViewChild } from '@angular/core';

import { HelperService } from 'src/app/shared/helper.service';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-supplier-note',
	templateUrl: './supplier-note.component.html',
	styleUrls: ['./supplier-note.component.scss']
})
export class SupplierNoteComponent extends AbstractPageForm {

	@Input() supplierId: string;
	@Input() serviceTypeId: string;
	@Input() stateId: string;
	@Input() hideCloseButton: boolean;

	@ViewChild('alert') private alert: ElementRef;

	public show = true;

	constructor(private helperService: HelperService) { super(); }

	get supplierNote(): string {
		if (this.supplierId === this.CONSTANTS.suppliers.directEnergy) {
			return this.helperService.getSupplierNote(this.serviceTypeId, this.stateId, this.supplierId);
		}
		return '';
	}

	public hideAlert(): void {
		this.show = false;
		setTimeout(() => {
			this.alert.nativeElement.remove();
		}, 1000);
	}

}

import * as moment from 'moment-timezone';

export class PricingAnalyticsUtilityPerformancesChartPoint {
	public minX: string;
	public dataPoints: {
		date: Date;
		data: number;
		hideTooltip: boolean;
	}[] = [];

	constructor(pricingAnalyticsUtilityPerformancesChartPoint: PricingAnalyticsUtilityPerformancesChartPoint) {
		Object.assign(this, pricingAnalyticsUtilityPerformancesChartPoint);

		if (this.dataPoints) {
			this.dataPoints.forEach(d => new Date(moment(d.date).format('MMM DD, YYYY 00:00:00 a')));
		}
	}
}

export class MonthlyCommission {

	public receivableForecastCommission!: { month: string; value: number }[];
	public receivableActualCommission!: { month: string; value: number }[];
	public payableForecastCommission!: { month: string; value: number }[];
	public payableActualCommission!: { month: string; value: number }[];

	constructor(monthlyCommission?: MonthlyCommission) {
		Object.assign(this, monthlyCommission);
	}
}

/* eslint-disable @typescript-eslint/naming-convention */

import { ContractLocation } from './contract-location';

export class PendingCommissionMatchedAccount {

	public pendingCommissionId: number;
	public contractLocationId: string;
	public partialMatchSimilarity: number;

	public contractLocation: ContractLocation;

	constructor(pendingCommissionMatchedAccount?: PendingCommissionMatchedAccount) {
		Object.assign(this, pendingCommissionMatchedAccount);
	}
}

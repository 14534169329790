import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filter'
})
export class FilterPipe implements PipeTransform {
	transform(items: any[], key: string, value: string | number, defaultItem = []): any {
		return items ? defaultItem.concat(items.filter(p => p[key] === value)) : items;
	}
}

import * as _ from 'lodash';

import { MarketUpdateAuction } from './market-update-auction';
import { MarketUpdateCompetitiveMatrixRate } from './market-update-competitive-matrix-rate';
import { MarketUpdateContract } from './market-update-contract';
import { MarketUpdateRateSummary } from './market-update-rate-summary';

export class MarketUpdate {

	public auctions: MarketUpdateAuction[];
	public contracts: MarketUpdateContract[];
	public rateSummary: MarketUpdateRateSummary[];
	public competitiveElecResiRates: MarketUpdateCompetitiveMatrixRate[];
	public competitiveGasResiRates: MarketUpdateCompetitiveMatrixRate[];
	public competitiveElecCommRates: MarketUpdateCompetitiveMatrixRate[];
	public competitiveGasCommRates: MarketUpdateCompetitiveMatrixRate[];

	constructor(json: string) {
		Object.assign(this, JSON.parse(json));

		this.auctions = this.auctions ? this.auctions.map(a => new MarketUpdateAuction(a)) : this.auctions;
		this.contracts = this.contracts ? this.contracts.map(c => new MarketUpdateContract(c)) : this.contracts;
		this.rateSummary = this.rateSummary ? this.rateSummary.map(r => new MarketUpdateRateSummary(r)) : this.rateSummary;
		this.competitiveElecResiRates = this.groupAndSortCompetitiveRates(this.competitiveElecResiRates
			? this.competitiveElecResiRates.map(r => new MarketUpdateCompetitiveMatrixRate(r)) : this.competitiveElecResiRates);
		this.competitiveGasResiRates = this.groupAndSortCompetitiveRates(this.competitiveGasResiRates
			? this.competitiveGasResiRates.map(r => new MarketUpdateCompetitiveMatrixRate(r)) : this.competitiveGasResiRates);
		this.competitiveElecCommRates = this.groupAndSortCompetitiveRates(this.competitiveElecCommRates
			? this.competitiveElecCommRates.map(r => new MarketUpdateCompetitiveMatrixRate(r)) : this.competitiveElecCommRates);
		this.competitiveGasCommRates = this.groupAndSortCompetitiveRates(this.competitiveGasCommRates
			? this.competitiveGasCommRates.map(r => new MarketUpdateCompetitiveMatrixRate(r)) : this.competitiveGasCommRates);
	}

	private groupAndSortCompetitiveRates(rates: MarketUpdateCompetitiveMatrixRate[]): MarketUpdateCompetitiveMatrixRate[] {
		return _.chain(rates)
			.groupBy(r => r.state + r.utility + r.bestRateFmt)
			.toPairs()
			.map(g => ({
				state: g[1][0].state,
				utility: g[1][0].utility,
				rateClass: _.sortedUniq(_.orderBy(g[1].map((m: MarketUpdateCompetitiveMatrixRate) => m.rateClass.trim()),
					s => s).filter(Boolean)).join(', '),
				zone: _.sortedUniq(_.orderBy(g[1].map((m: MarketUpdateCompetitiveMatrixRate) => m.zone.trim()),
					s => s).filter(Boolean)).join(', '),
				bestRate: g[1][0].bestRate,
				bestRateFmt: g[1][0].bestRateFmt,
				ptc: g[1][0].ptc,
				ptcFmt: g[1][0].ptcFmt,
				difference: g[1][0].difference,
				differenceFmt: g[1][0].differenceFmt,
				effectiveDate: g[1][0].effectiveDate,
				term: g[1][0].term,
				minUsage: g[1][0].minUsage,
			}) as MarketUpdateCompetitiveMatrixRate)
			.orderBy(['state', 'utility', 'difference'], ['asc', 'asc', 'desc'])
			.value();
	}
}

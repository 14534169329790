import * as moment from 'moment-timezone';

import { ServiceType } from './service-type';
import { State } from './state';
import { Supplier } from './supplier';
import { User } from './user';
import { Utility } from './utility';

export class UserRfqSetting {

	public id: number;
	public serviceTypeId: string;
	public utilityId: string;
	public stateId: string;
	public userId: string;
	public minUsage: number;
	public maxUsage: number;
	public supplierId: string;
	public isActive: boolean;
	public isPaused: boolean;
	public pauseExpirationDate: Date | string;
	public userRfqSettingRestrictionTypeId: number;
	public numRestrictedMonths: number;
	public restrictedStartDate: Date | string;
	public restrictedEndDate: Date | string;
	public reviewedDate: Date | string;

	public serviceType: ServiceType;
	public utility: Utility;
	public state: State;
	public supplier: Supplier;
	public user: User;

	constructor(userRfqSetting?: UserRfqSetting) {
		Object.assign(this, userRfqSetting);

		this.pauseExpirationDate = this.pauseExpirationDate
			? new Date(moment(this.pauseExpirationDate).format('MMM DD, YYYY hh:mm:ss a')) : this.pauseExpirationDate;
		this.restrictedStartDate = this.restrictedStartDate
			? new Date(moment(this.restrictedStartDate).format('MMM DD, YYYY hh:mm:ss a')) : this.restrictedStartDate;
		this.restrictedEndDate = this.restrictedEndDate
			? new Date(moment(this.restrictedEndDate).format('MMM DD, YYYY hh:mm:ss a')) : this.restrictedEndDate;
		this.reviewedDate = this.reviewedDate
			? new Date(moment(this.reviewedDate).format('MMM DD, YYYY hh:mm:ss a')) : this.reviewedDate;
	}

	get hasInviteRestrictions() { return !!this.userRfqSettingRestrictionTypeId; }
}

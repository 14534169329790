import { CONSTANTS } from '@pk/powerkioskutils';

import * as moment from 'moment-timezone';

import { environment } from 'src/environments/environment';
import { Agent } from './agent';
import { AiGeneratedContent } from './ai-generated-content';
import { Attachment } from './attachment';
import { ContactMethod } from './contact-method';
import { Contract } from './contract';
import { TicketComment } from './ticket-comment';
import { User } from './user';

export class ViewTicketDashboard {

	public id: number;
	public body: string;
	public subject: string;
	public ticketCategoryId: number;
	public ticketCategoryName: string;
	public inProgressDate: Date;
	public modifiedDate: Date;
	public priority: number;
	public priorityDisplay: string;
	public status: string;
	public brokerStatus: string;
	public statusId: number;
	public hasComment: number;
	public reporterId: string;
	public reporterFname: string;
	public reporterLname: string;
	public reporterFullName: string;
	public reporterAgentName: string;
	public reporterRole: string;
	public assignedToUserId: string;
	public assigneeFname: string;
	public assigneeLname: string;
	public assigneeFullName: string;
	public completeUserId: string;
	public completedFname: string;
	public completedLname: string;
	public completedFullName: string;
	public completeDate: Date;
	public addDate: Date;
	public email: string;
	public residentialContractRatio: number;
	public isHighImpactAgent: boolean;

	public attachments: Attachment[];
	public comments: TicketComment[];
	public contracts: Contract[];
	public contactMethods: ContactMethod[];
	public agent: Agent;
	public user: User;
	public latestAiGeneratedContent: AiGeneratedContent;

	get isCompleted(): boolean {
		return this.statusId === CONSTANTS.ticketStatuses.complete;
	}

	set ticketStatusId(value: number) {
		this.statusId = value;
	}

	constructor(ticket: ViewTicketDashboard) {
		Object.assign(this, ticket);

		this.completeDate = this.completeDate ? new Date(moment(this.completeDate).format('MMM DD, YYYY hh:mm:ss a')) : this.completeDate;
		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
		this.modifiedDate = this.modifiedDate ? new Date(moment(this.modifiedDate).format('MMM DD, YYYY hh:mm:ss a')) : this.modifiedDate;
		this.inProgressDate = this.inProgressDate
			? new Date(moment(this.inProgressDate).format('MMM DD, YYYY hh:mm:ss a')) : this.inProgressDate;
		this.attachments = this.attachments ? this.attachments.map(a => new Attachment(a)) : this.attachments;
		this.comments = this.comments ? this.comments.map(c => new TicketComment(c)) : this.comments;
		this.contracts = this.contracts ? this.contracts.map(c => new Contract(c)) : this.contracts;
		this.agent = this.agent ? new Agent(this.agent) : this.agent;
		this.user = this.user ? new User(this.user) : this.user;
	}

	public getCompletedFullName(loggedInUser: User): string {
		if (this.isCompleted) {
			return loggedInUser.isAdmin ? this.completedFullName : environment.powerKioskSupportTeamName;
		}

		return '';
	}
}

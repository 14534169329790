import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

import { GraphqlService } from '../graphql/graphql.service';

@Injectable({
	providedIn: 'root',
})
export class UserAuditService {

	public window = window;

	constructor(
		private graphqlService: GraphqlService,
	) { }

	public async captureEvent(
		userAuditTypeId: number,
		eventDetails: string | UntypedFormGroup,
		action: string,
		extraMessage?: string,
	): Promise<void> {
		try {
			let details = typeof eventDetails === 'string'
				? eventDetails
				: `Invalid: ${this.getInvalidControls(eventDetails).join(',')}`;

			if (extraMessage) {
				details += ';' + extraMessage;
			}

			const page = window.location.pathname;
			await this.graphqlService.createUserAudit({
				userAuditTypeId,
				details,
				page,
				action,
			});
		} catch (e) {
			console.error(e.message);
		}
	}

	private getInvalidControls(formGroup: UntypedFormGroup): string[] {
		const invalidControls = Object.keys(formGroup.errors || {});
		const controlKeys = Object.keys(formGroup.controls || {});
		for (const key of controlKeys) {
			if (formGroup.controls[key] instanceof UntypedFormArray) {
				const formArray = formGroup.controls[key] as UntypedFormArray;
				const formArrayKeys = Object.keys(formArray.controls || {});
				for (const formArrayKey of formArrayKeys) {
					if (formArray.controls[formArrayKey].controls) {
						invalidControls.push(...this.getInvalidControls(formArray.controls[formArrayKey] as UntypedFormGroup));
					}
				}
			} else {
				if (formGroup.controls[key].invalid) {
					invalidControls.push(key);
				}
			}
		}
		return invalidControls;
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { CONSTANTS } from '@pk/powerkioskutils';

@Pipe({
	name: 'priority'
})
export class PriorityPipe implements PipeTransform {

	transform(value: number): any {
		if (value) {
			return CONSTANTS.priorities[value] ? CONSTANTS.priorities[value] : value;
		}
	}

}

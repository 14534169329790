export class CommunitySolarSetting {

	public id: number;
	public repId: string;
	public repFullName: string;
	public repPhone: string;
	public repEmail: string;
	public signupPortalUrl: string;
	public signupPortalPromoCode: string;
	public signupConfirmationText: string;
	public statusCheckPortalUrl: string;
	public statusCheckPortalUsername: string;
	public statusCheckPortalPassword: string;

	constructor(communitySolarSetting: CommunitySolarSetting) {
		Object.assign(this, communitySolarSetting);
	}
}

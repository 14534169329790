export class AlertType {

	public id: number;
	public name: string;
	public description: string;
	public addDate: Date;

	constructor(alertType?: AlertType) {
		Object.assign(this, alertType);
	}
}

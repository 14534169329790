import * as moment from 'moment-timezone';

export class ViewCompanySupplierQuickBookDeposit {

	public depositId: number;
	public depositDate: Date;
	public amount: number;
	public balance: number;

	constructor(viewCompanySupplierQuickBookDeposit?: ViewCompanySupplierQuickBookDeposit) {
		Object.assign(this, viewCompanySupplierQuickBookDeposit);

		this.depositDate = this.depositDate ? new Date(moment(this.depositDate).format('MMM DD, YYYY hh:mm:ss a')) : this.depositDate;
	}
}

export class SupplierClass {

	public id: number;
	public name: string;
	public description: string;

	constructor(supplierClass?: SupplierClass) {
		Object.assign(this, supplierClass);
	}
}

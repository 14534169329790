import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

import { PlanLoadFactorSetting, Rate } from '../models';

@Pipe({
	name: 'filterPlanLoadFactorSettings',
})
export class FilterPlanLoadFactorSettingsPipe implements PipeTransform {
	transform(loadFactorSettings: PlanLoadFactorSetting[], rates: Rate[]): any {
		const loadFactorStatementsList = _.uniq(rates.map(r => r.loadFactorSetting?.statement).filter(l => l));
		return loadFactorSettings && loadFactorStatementsList
			? loadFactorSettings.filter(g => loadFactorStatementsList.includes(g.statement)) : loadFactorSettings;
	}
}

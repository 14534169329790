import { Attachment } from './attachment';

export class Logo {

	public logoId: string;
	public name: string;
	public path: string;
	public attachmentId: string;

	public attachment: Attachment;

	constructor(logo?: Logo) {
		Object.assign(this, logo);
	}
}

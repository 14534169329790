import { Acknowledgement } from './acknowledgement';

export class CustomerDisclosureStatement {

	public customerDisclosureStatementId: number;
	public heading: string;
	public body: string;

	public acknowledgements: Acknowledgement[];

	constructor(customerDisclosureStatement: CustomerDisclosureStatement) {
		Object.assign(this, customerDisclosureStatement);
	}
}

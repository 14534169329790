import { State } from './state';

export class ECommerceWebsite {

	public id: number;
	public domainName: string;
	public contactPhoneNumber: string;
	public contactEmail: string;
	public contactAddress1: string;
	public contactAddress2: string;
	public contactCity: string;
	public contactStateId: string;
	public contactZipCode: string;
	public heroLogoAttachmentId: string;
	public navbarLogoAttachmentId: string;
	public footerLogoAttachmentId: string;
	public contactPhoneNumberCountryId: number;
	public contactCountryId: number;
	public contactStateOther: string;

	public contactState: State;

	constructor(eCommerceWebsite?: ECommerceWebsite) {
		Object.assign(this, eCommerceWebsite);
	}
}

import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { buffer, debounceTime, filter, map } from 'rxjs/operators';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[pk-broker-dbl-click]'
})
export class DblClickDirective {

	private click$ = new Subject<MouseEvent>();

	@Output() dblClick = new EventEmitter<MouseEvent>();

	@HostListener("click", ["$event"])
	onClick(event) {
		this.click$.next(event);
	}

	ngOnInit() {
		this.click$
			.pipe(
				buffer(this.click$.pipe(debounceTime(250))),
				filter(list => list.length === 2),
				map(list => list[1])
			)
			.subscribe(this.dblClick);
	}

	ngOnDestroy() {
		this.click$.complete();
	}
}



import { NumberUtils } from '@pk/powerkioskutils';

import * as moment from 'moment-timezone';

import { Agent } from './agent';
import { User } from './user';

export class ContractLog {

	public addDate: Date;
	public status: string;
	public agentName: string;
	public userFullName: string;
	public rejectReason: string;
	public displayStatus: string;
	public externalStatus: string;
	public isResidential: boolean;
	public externalId: string;
	public userId: string;
	public agentId: string;
	public margin: number;
	public rate: number;
	public bufferMargin: number;
	public annualUsage: number;

	public user: User;
	public agent: Agent;

	constructor(contractLog: any) {
		Object.assign(this, contractLog);

		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
		this.agent = this.agent ? new Agent(this.agent) : this.agent;
		this.user = this.user ? new User(this.user) : this.user;
	}

	public getDisplayAnnualUsage(unit?: string): string {
		const annualUsage = NumberUtils.amountToUnits(this.annualUsage, unit);

		return Math.round(annualUsage).toString();
	}
}

import { MarketUpdate } from './market-update';

export class MarketUpdateLog {

	public id: string;
	public json: string;
	public marketUpdate: MarketUpdate;
	public addDate: Date;
	public isRead: boolean;

	constructor(marketUpdateLog: MarketUpdateLog, isRead = false) {
		Object.assign(this, marketUpdateLog);

		this.marketUpdate = this.json ? new MarketUpdate(this.json as string) : null;
		this.isRead = isRead;
	}
}

import { Component, Input } from '@angular/core';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { EducationSearchResult, Faq, FaqSearchResult } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-faq-search-modal-related-articles',
	templateUrl: './faq-search-modal-related-articles.component.html',
	styleUrls: ['./faq-search-modal-related-articles.component.scss'],
})
export class FaqSearchModalRelatedArticlesComponent extends AbstractPageForm {

	@Input() faqResult: {
		siteSectionSearchResultsGrouped: { question: string; answers: FaqSearchResult[] }[];
		faqAuditId: number;
		faqSearchResults: FaqSearchResult[];
		educationAuditId: number;
		educationSearchResults: EducationSearchResult[];
	};
	@Input() faqArticle: FaqSearchResult | Faq;

	public relatedArticles: Faq[];

	constructor(
		private graphqlService: GraphqlService,
	) { super(); }

	public async loadPageData(): Promise<void> {
		const result = await this.graphqlService.getFaqsByCategory(this.faqArticle.faqCategoryId, 5);
		this.relatedArticles = result.data.faqs.message.filter(f => f.id !== this.faqArticle.id);
	}

	public async chooseFaqArticle(faqArticle: Faq): Promise<void> {
		this.faqArticle = faqArticle;
		if (this.faqResult.faqAuditId) {
			this.graphqlService.createFaqAuditArticle(this.faqResult.faqAuditId, { faqId: faqArticle.id });
		}
		this.reloadPageData();
	}
}

import { Document } from './document';
import { ESign } from './esign';

export class EsignDocument {

	public esignDocumentId: string;
	public esignId: string;
	public attachmentId: string;
	public documentId: string;

	public accepted: boolean;
	public base64: string;

	public document: Document;
	public esign: ESign;

	// internal
	public downloading = false;

	constructor(esignDocument?: EsignDocument) {
		Object.assign(this, esignDocument);
	}
}

import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StringUtils } from '@pk/powerkioskutils';

import { ToastrModule } from 'ngx-toastr';

import { environment } from 'src/environments/environment';
import { AgentModule } from './agent/agent.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { CsatSurveyModule } from './csat-survey/csat-survey.module';
import { FaqModule } from './faq/faq.module';
import { FeedbackModule } from './feedback/feedback.module';
import { GraphqlModule } from './graphql/graphql.module';
import { NpsSurveyModule } from './nps-survey/nps-survey.module';
import { GlobalErrorHandler } from './shared/error-handlers/global-error-handler';
import { ServiceLocator } from './shared/service-locator.service';

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		CoreModule.forRoot(),
		ToastrModule.forRoot({
			positionClass: 'toast-top-center',
		}),
		GraphqlModule,
		FeedbackModule,
		NpsSurveyModule,
		CsatSurveyModule,
		AgentModule,
		FaqModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	declarations: [AppComponent],
	bootstrap: [AppComponent],
	providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandler }],
})
export class AppModule {
	constructor(injector: Injector) {
		ServiceLocator.injector = injector;
		// moment.tz.setDefault(environment.timezone);
		const origin = window.location.origin;
		environment.apiKey = environment.apiKeys[this.getRequester(origin)];
		environment.baseAPIUrl = environment.apiUrls[origin] || environment.baseAPIUrl;
		environment.baseUrl = environment.apiUrls[origin] || environment.baseUrl;
	}

	private getRequester(origin): string {
		return StringUtils.removeLastCharacter(origin, '/').replace('www.', '').split('/').splice(0, 3).join('/').toLowerCase();
	}
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'faqShortAnswer'
})
export class FaqShortAnswerPipe implements PipeTransform {

	transform(answer: string): string {
		return answer ? answer.replace(/<[^>]*>/g, '').substring(0, 82) + '...' : answer;
	}

}

import { DecimalPipe } from '@angular/common';
import { CONSTANTS, NumberUtils, RateUtils } from '@pk/powerkioskutils';

import * as _ from 'lodash';
import * as moment from 'moment-timezone';

import { environment } from '../../../environments/environment';
import { HelperService } from '../helper.service';
import { ServiceLocator } from '../service-locator.service';
import { CommissionEstimate, ContractMarketPerformance, ContractPrice, PoaRateAutoSelectionOption, SupplierCommissionRule, UtilityMarketSetting } from './';
import { AdditionalInsured } from './additional-insured';
import { Agent } from './agent';
import { Attachment } from './attachment';
import { CommissionPayPlan } from './commission-pay-plan';
import { CommissionPayable } from './commission-payable';
import { CommissionPayablesSchedule } from './commission-payables-schedule';
import { Company } from './company';
import { ContractCompliance } from './contract-compliance';
import { ContractLocation } from './contract-location';
import { ContractPerformance } from './contract-performance';
import { Customer } from './customer';
import { ESign } from './esign';
import { Feedback } from './feedback';
import { Invoice } from './invoice';
import { LossHistory } from './loss-history';
import { Note } from './note';
import { Package } from './package';
import { Payment } from './payment';
import { PendingCommission } from './pending-commission';
import { Pet } from './pet';
import { Product } from './product';
import { PromoCode } from './promo-code';
import { PurchasingLayer } from './purchasing-layer';
import { PurchasingLayerAudit } from './purchasing-layer-audit';
import { PurchasingLayerPurchaseHistory } from './purchasing-layer-purchase-history';
import { RfqSession } from './rfq-session';
import { ServiceType } from './service-type';
import { State } from './state';
import { Supplier } from './supplier';
import { Task } from './task';
import { User } from './user';
import { Utility } from './utility';

export class Contract {

	public contractId: string;
	public contractNum: string;
	public agentId: string;
	public contractDate: Date | string;
	public effectiveDate: Date | string;
	public expirationDate: Date | string;
	public confirmationDate: Date | string;
	public auctionEndDate: Date;
	public awardDate: Date;
	public uploadDate: Date | string;
	public status: number | string;
	public displayStatus: string;
	public supplierResidentialEnrollmentStatus: string;
	public customerId: string;
	public externalId: string;
	public externalStatus: string;
	public renewalId: string;
	public supplierId: string;
	public productId: string;
	public planId: string;
	public planName: string;
	public stateId: string;
	public serviceTypeId: string;
	public rate: number;
	public margin: number;
	public term: number;
	public bufferMargin: number;
	public depositRequiredAmount: number;
	public depositPaidAmount: number;
	public commissionRate: number;
	public commissionRate2: number;
	public commissionRate3: number;
	public ipAddress: string;
	public signIPAddress: string;
	public agentIPAddress: string;
	public promoCodeId: string;
	public underwriterId: string;
	public moveInType: string;
	public dwellingType: string;
	public originalTosPath: string;
	public tosPath: string;
	public disclaimerPath: string;
	public eflPath: string;
	public yracPath: string;
	public summaryPath: string;
	public annualUsage: number;
	public rejectReason: string;
	public creditCheckMessage: string;
	public greenPercentage: number;
	public isResidential: boolean;
	public isRenewal: boolean;
	public switchDate: Date | string;
	public switchTypeCode: string;
	public taxExemptReason: string;
	public rfqSessionId: string;
	public companyId: string;
	public documentsReceived: number;
	public commissionStatus: number;
	public submissionStatus: number;
	public previousRate: number;
	public rateCodeId: string;
	public salesTax: number;
	public signIpFraudScore: number;
	public loaRequested: boolean;
	public units: string;
	public isTaxExempt: boolean;
	public availableStatuses: number[];
	public cancellationPenaltyDesc: string;
	public productHeader: string;
	public productDescription: string;
	public previousAnnualUsage: number;
	public dailyServiceFee: number;
	public monthlyServiceFee: number;
	public bandwidthPercentage: number;
	public billingMethod: string;
	public loadFactor: number;
	public networkLoadFactor: number;
	public customLoadFactor: string;
	public hasUsage: boolean;
	public canGetUsage: boolean;
	public plc: number;
	public networkPlc: number;
	public tdspKwhCharge: number;
	public tdspBaseCharge: number;
	public baseFee1Dollars: number;
	public energyRate: number;
	public isFraudulent: boolean;
	public signedAnnualUsage: number;
	public payableAnnualUsage: number;
	public isSupplierApiSubmitted: boolean;
	public marginChangeReasonTypeId: number;
	public marginChangeReason: string;
	public isCommissionAuditPaused: boolean;
	public rejectReasonCategoryId: number;
	public lostProspectReason: string;
	public droppedTermValue: number;
	public rejectedTermValue: number;
	public isGuaranteePayment: boolean;
	public reinstatementTermValue: number;
	public usePurchasingLayerStartDate: Date;
	public isNewBrokerAuction: boolean;
	public rejectReasonSubCategoryId: number;
	public etfAmount: number;
	public hasCustomReceivableRule: boolean;
	public rateClass: string;
	public id: string;
	public award: boolean;
	public hasGUId: boolean;
	public hasExternalId: boolean;
	public rateMatrixId: string;
	public rfqSessionBidId: string;
	public confirmationQueueStatus: string;
	public confirmationErrorMessage: string;
	public commissionPayPlanId: number;
	public isSaas: boolean;
	public displayContractNum: string;
	public naturalGasUseTypeId: number;
	public commissionSplitBufferMargin: number;
	public riskScore: number;
	public isBlendAndExtend: boolean;
	public blendAndExtendRenewTerm: number;
	public showAutomatedRenewalNotification: boolean;
	public addClause: boolean;
	public sentAutomatedRenewalEmailDate: Date;
	public preventESignRequestEmails: boolean;
	public residentialSupplierEnrollmentProperties: any;
	public isSentToCustomer: boolean;
	public matrixAgentBufferMargin: number;
	public rateAddDate: Date;
	public submissionStatusAutoSubmittedDate: string;
	public baseRateOverride: number;
	public isReinstatementSent: boolean;
	public commissionPayoutDate: number;
	public isFirstResidentialDeal: boolean;
	public poaRateAutoSelectionOptionId: number;
	public poaRateAutoSelectionErrorMessage: string;

	public minAuctionEndDate: { businessDays: number; date: Date };
	public suggestedSensitivity: number;
	public latestAuctionCompleteDate: string;
	public previousContract: Contract;
	public agent: Agent;
	public underwriter: User;
	public user: User;
	public state: State;
	public serviceType: ServiceType;
	public promoCode: PromoCode;
	public customer: Customer;
	public supplier: Supplier;
	public product: Product;
	public locations: ContractLocation[];
	public attachments: Attachment[];
	public esigns: ESign[];
	public invoices: Invoice[];
	public notes: Note[];
	public tasks: Task[];
	public packages: Package[];
	public payments: Payment[];
	public feedbacks: Feedback[];
	public rfqSession: RfqSession;
	public renewalContract: Contract;
	public commissionPayablesSchedules: CommissionPayablesSchedule[];
	public overrideShowRequestPrice: boolean;
	public commissionPayables: CommissionPayable[];
	public commissionEstimates: CommissionEstimate[];
	public commissionPayPlan: CommissionPayPlan;
	public pendingCommissions: PendingCommission[];
	public purchasingLayer: PurchasingLayer;
	public purchasingLayerAudit: PurchasingLayerAudit;
	public company: Company;
	public purchasingLayerPurchaseHistories: PurchasingLayerPurchaseHistory[];
	public supplierCommissionRule: SupplierCommissionRule;
	public compliance: ContractCompliance;
	public poaRateAutoSelectionOption: PoaRateAutoSelectionOption;

	public contractPerformance: ContractPerformance;
	public purchasingLayerMarket: ContractMarketPerformance[];
	public monthlyContractPrice: ContractPrice[];

	constructor(contract?: Contract) {
		Object.assign(this, contract);

		this.customer = this.customer ? new Customer(this.customer) : this.customer;
		this.agent = this.agent ? new Agent(this.agent) : this.agent;
		this.supplier = this.supplier ? new Supplier(this.supplier) : this.supplier;
		this.serviceType = this.serviceType ? new ServiceType(this.serviceType) : this.serviceType;
		this.effectiveDate = this.effectiveDate
			? new Date(moment(this.effectiveDate).format('MMM DD, YYYY 00:00:00 a')) : this.effectiveDate;
		this.switchDate = this.switchDate ? new Date(moment(this.switchDate).format('MMM DD, YYYY hh:mm:ss a')) : this.switchDate;
		this.expirationDate = this.expirationDate
			? new Date(moment(this.expirationDate).format('MMM DD, YYYY hh:mm:ss a')) : this.expirationDate;
		this.auctionEndDate = this.auctionEndDate
			? new Date(moment(this.auctionEndDate).format('MMM DD, YYYY hh:mm:ss a')) : this.auctionEndDate;
		this.contractDate = this.contractDate ? new Date(moment(this.contractDate).format('MMM DD, YYYY hh:mm:ss a')) : this.contractDate;
		this.confirmationDate = this.confirmationDate
			? new Date(moment(this.confirmationDate).format('MMM DD, YYYY hh:mm:ss a')) : this.confirmationDate;
		this.locations = this.locations ? this.locations.map(l => new ContractLocation(l)) : this.locations;
		this.attachments = this.attachments ? this.attachments.map(l => new Attachment(l)) : this.attachments;
		this.notes = this.notes ? this.notes.map(n => new Note(n)) : this.notes;
		this.tasks = this.tasks ? this.tasks.map(t => new Task(t)) : this.tasks;
		this.underwriter = this.underwriter ? new User(this.underwriter) : this.underwriter;
		this.user = this.user ? new User(this.user) : this.user;
		this.awardDate = this.awardDate ? new Date(moment(this.awardDate).format('MMM DD, YYYY hh:mm:ss a')) : this.awardDate;
		this.uploadDate = this.uploadDate ? new Date(moment(this.uploadDate).format('MMM DD, YYYY hh:mm:ss a')) : this.uploadDate;
		this.rateAddDate = this.rateAddDate ? new Date(moment(this.rateAddDate).format('MMM DD, YYYY hh:mm:ss a')) : this.rateAddDate;
		this.rfqSession = this.rfqSession ? new RfqSession(this.rfqSession) : this.rfqSession;
		this.invoices = this.invoices ? this.invoices.map(i => new Invoice(i)) : this.invoices;
		this.originalTosPath = this.tosPath ? this.tosPath : '';
		this.tosPath = this.tosPath ? this.getPath(this.tosPath) : '';
		this.eflPath = this.eflPath ? this.getPath(this.eflPath) : '';
		this.yracPath = this.yracPath ? this.getPath(this.yracPath) : '';
		this.disclaimerPath = this.disclaimerPath ? this.getPath(this.disclaimerPath) : '';
		this.feedbacks = this.feedbacks ? this.feedbacks.map(f => new Feedback(f)) : this.feedbacks;
		this.esigns = this.esigns ? this.esigns.map(e => new ESign(e)) : this.esigns;
		if (this.minAuctionEndDate) {
			this.minAuctionEndDate.date = this.minAuctionEndDate.date
				? new Date(moment(this.minAuctionEndDate.date).format('MMM DD, YYYY hh:mm:ss a')) : this.minAuctionEndDate.date;
		}
		this.previousContract = this.previousContract ? new Contract(this.previousContract) : this.previousContract;
		this.renewalContract = this.renewalContract ? new Contract(this.renewalContract) : this.renewalContract;
		this.commissionPayablesSchedules = this.commissionPayablesSchedules
			? this.commissionPayablesSchedules.map(l => new CommissionPayablesSchedule(l)) : this.commissionPayablesSchedules;
		this.commissionPayables = this.commissionPayables
			? this.commissionPayables.map(l => new CommissionPayable(l)) : this.commissionPayables;
		this.commissionEstimates = this.commissionEstimates
			? this.commissionEstimates.map(l => new CommissionEstimate(l)) : this.commissionEstimates;
		this.pendingCommissions = this.pendingCommissions
			? this.pendingCommissions.map(l => new PendingCommission(l)) : this.pendingCommissions;
		this.purchasingLayerPurchaseHistories = this.purchasingLayerPurchaseHistories
			? this.purchasingLayerPurchaseHistories.map(p => new PurchasingLayerPurchaseHistory(p)) : this.purchasingLayerPurchaseHistories;
		this.company = this.company ? new Company(this.company) : this.company;
		this.supplierCommissionRule = this.supplierCommissionRule
			? new SupplierCommissionRule(this.supplierCommissionRule)
			: this.supplierCommissionRule;
		this.contractPerformance = this.contractPerformance
			? new ContractPerformance(this.contractPerformance)
			: this.contractPerformance;

		if (this.residentialSupplierEnrollmentProperties && typeof this.residentialSupplierEnrollmentProperties === 'string') {
			this.residentialSupplierEnrollmentProperties = JSON.parse(this.residentialSupplierEnrollmentProperties);
		}

		if (this.rfqSession && this.status === CONSTANTS.statuses.ainp) {
			setInterval(() => {
				if (moment(this.rfqSession.endDate2) < moment()) {
					this.status = CONSTANTS.statuses.acomp;
					this.displayStatus = CONSTANTS.statuses[CONSTANTS.statuses.acomp];
				}
			}, 1000);
		}

		this.displayContractNum = HelperService.showAsteriskForContractFirstImpression(Number(this.status), this.isNewBrokerAuction)
			? this.contractNum + '*'
			: this.contractNum;
	}

	get showDepositRequiredMessage(): boolean {
		return this.status !== CONSTANTS.statuses.confirmed && !!this.depositRequiredAmount;
	}

	get energyIcon(): string {
		return HelperService.getEnergyIcon(this.serviceTypeId);
	}

	private getPath(path: string): string {
		return !path.includes('http') ? environment.baseUrl + path : path;
	}

	public getInvoiceTotal(): number {
		return this.invoices && this.invoices.filter(i => !i.isPaid).length
			? this.invoices.filter(i => !i.isPaid).map(i => i.amount).reduce((total, amount) => total + amount)
			: 0;
	}

	public getPaymentTotal(): number {
		return this.payments && this.payments.length
			? this.payments.map(i => i.amount).reduce((total, amount) => total + amount)
			: 0;
	}

	public isMarginEditable(user: User): boolean {
		if (!(user.isAdmin || user.isOwner)) {
			return [CONSTANTS.statuses.dr].includes(Number(this.status));
		}

		return true;
	}

	public getMarginMultiplied(): number {
		return this.margin * this.serviceType.multiplier;
	}

	//Reverse calculates the broker selected margin based on the supplier commission rule
	//	and the final margins set on the contract
	public getInitialSelectedMargin() {
		let initialBrokerMargin = this.margin;
		let initialParentMargin = this.commissionSplitBufferMargin ?? 0;
		let initialMatrixMargin = this.matrixAgentBufferMargin ?? 0;
		const split = (this.supplierCommissionRule?.marginSplit * this.serviceType?.multiplier) ?? 0;
		if (!split) {
			return { brokerMargin: initialBrokerMargin, parentMargin: initialParentMargin, matrixMargin: initialMatrixMargin };
		} else {	//If there is a split, reverse the calculation to find initial margins for each margin type
			const brokerParentMargin = this.getTotalMargin(false, true, false);
			const totalMargin = this.getTotalMargin(true, true, true);
			if (split < totalMargin) {
				//Parent
				if (split < initialParentMargin) {
					initialParentMargin
						= Math.round(((2 * initialParentMargin) - split) * 1e12) / 1e12;
				}

				//Broker
				if (split < brokerParentMargin) {
					initialBrokerMargin
						= Math.round(((2 * brokerParentMargin) - split - initialParentMargin) * 1e12) / 1e12;
				}

				//Matrix Agent Buffer Margin
				initialMatrixMargin
					= Math.round(((2 * totalMargin) - split - initialParentMargin - initialBrokerMargin) * 1e12) / 1e12;
			}
		}

		return { brokerMargin: initialBrokerMargin, parentMargin: initialParentMargin, matrixMargin: initialMatrixMargin };
	}

	public getTotalMargin(
		includeBufferMargin = true, includeParentBufferMargin = false, includeMatrixAgentBufferMargin = false, unit?: string,
	): number {
		return HelperService.getTotalMargin(this, includeBufferMargin, includeParentBufferMargin, includeMatrixAgentBufferMargin, unit);
	}

	public getTotalMarginFormatted(
		includeBufferMargin = true, includeParentBufferMargin = false, includeMatrixAgentBufferMargin = false,
		marginUnits?: string, unit?: string,
	): string {
		return HelperService.getTotalMarginFormatted(
			this,
			includeBufferMargin, includeParentBufferMargin, includeMatrixAgentBufferMargin,
			marginUnits, unit
		);
	}

	public getCommissionSplitBufferMargin(unit?: string): number {
		return HelperService.getCommissionSplitBufferMargin(this, unit);
	}

	public getDisplayPayableAnnualUsage(unit?: string): string {
		const payableAnnualUsage = NumberUtils.amountToUnits(this.payableAnnualUsage, unit);

		return Math.round(payableAnnualUsage).toString();
	}

	public getDisplayAnnualUsage(unit?: string): string {
		const annualUsage = NumberUtils.amountToUnits(this.annualUsage, unit);

		return Math.round(annualUsage).toString();
	}

	public getDisplaySignedAnnualUsage(unit?: string): string {
		const signedAnnualUsage = NumberUtils.amountToUnits(this.signedAnnualUsage, unit);

		return Math.round(signedAnnualUsage).toString();
	}

	public getDisplayPreviousRate(unit?: string): string {
		if (!this.previousRate) { return ''; }
		const rate = NumberUtils.rateToUnits(this.previousRate, unit);

		return rate.toString();
	}

	public getDisplayRate(unit?: string): string {
		if (([CONSTANTS.statuses.ainp, CONSTANTS.statuses.dr].includes(Number(this.status)))
			|| (this.status === CONSTANTS.statuses.acomp && !this.awardDate)) {
			return '';
		}

		// - this only affects the case where an auction contract picks
		// a matrix price, so there is already a buffer margin attached
		// - if status is quote, then don't include buffer margin
		// once it's signed/confirmed/acomp, then it's go back to showing it, which will
		// give it the same effect as if setBufferMargin() was called
		const includeBufferMargin = (this.status === CONSTANTS.statuses.acomp && !!this.awardDate)
			|| this.status === CONSTANTS.statuses.signed
			|| !!this.confirmationDate;

		const rate = NumberUtils.rateToUnits(RateUtils
			.adjustedRateWithTax(Math.round(
				(this.rate + this.getTotalMargin(includeBufferMargin, true, true)) * 1e12) / 1e12,
				this.stateId,
				this.salesTax, this.supplierId, this.annualUsage),
			unit);

		return rate.toString();
	}

	public getDisplayRateValue(unit?: string): number {
		return Number(this.getDisplayRate(unit));
	}

	public getDepositAmount(): number {
		const amount = this.depositRequiredAmount - this.depositPaidAmount;
		return amount >= 0 ? amount : 0;
	}

	public getCommissionRate(): number {
		let commissionRate = 0;

		if (!isNaN(this.commissionRate) && this.commissionRate) {
			commissionRate = this.commissionRate;
		} else if (this.agent) {
			commissionRate = this.agent.commissionRate;
		}

		return commissionRate;
	}

	public getCommissionRate2(): number {
		let commissionRate2 = 0;

		if (!isNaN(this.commissionRate2) && this.commissionRate2) {
			commissionRate2 = this.commissionRate2;
		} else if (this.agent) {
			commissionRate2 = this.agent.commissionRate2;
		}

		return commissionRate2;
	}

	public getCommissionRate3(): number {
		let commissionRate3 = 0;

		if (!isNaN(this.commissionRate3) && this.commissionRate3) {
			commissionRate3 = this.commissionRate3;
		} else if (this.agent) {
			commissionRate3 = this.agent.commissionRate3;
		}

		return commissionRate3;
	}

	public canCreateRenewal(): boolean {
		return [
			CONSTANTS.statuses.dropped,
			CONSTANTS.statuses.confirmed,
		].includes(Number(this.status))
			&& !this.hasRenewal()
			&& this.hasSupplier();
	}

	public hasRenewal(): boolean {
		return !!this.renewalId;
	}

	public hasSupplier(): boolean {
		return !!this.supplierId;
	}

	public hasHomeowner(): boolean {
		return this.locations && this.locations.length && this.locations.some(l => !!l.homeownerId);
	}

	public getModalTitle(): string {
		if (!this.customer || !this.state) { return ''; }
		const titleParts = [this.customer.dbaName, this.serviceType.name, this.state.stateShort];

		if (this.locations && this.locations.length) {
			titleParts.push(this.locations[0].utility.name);
		}

		// cheat to statically get an instance of the number formatter (decimal pipe) for convenience here
		const decimalPipe = ServiceLocator.injector.get(DecimalPipe);

		const usage = NumberUtils.amountToUnits(this.annualUsage, this.units);

		titleParts.push(`${decimalPipe.transform(usage, '1.0-0')} ${this.units}`);

		return titleParts.join(' - ');
	}

	public isSaveSessionAllowed(user?: User): boolean {
		if (user?.isAdmin) { return true; }

		const hasLOA = this.attachments?.some(a => a.attachmentTypeId === CONSTANTS.attachmentTypes.loa);
		const hasLOE = this.attachments?.some(a => a.attachmentTypeId === CONSTANTS.attachmentTypes.loe);
		const hasHUD = this.attachments?.some(a => a.attachmentTypeId === CONSTANTS.attachmentTypes.hud);
		const hasBill = this.attachments?.some(a => a.attachmentTypeId === CONSTANTS.attachmentTypes.sampleBill);

		const hasFewLocations = this.locations?.length < 20;
		const utilityMarketSetting = this.getUtilityMarketSetting();

		if (!utilityMarketSetting) { return true; } // if nothing defined let it through for now

		return (!utilityMarketSetting.requireLoaForPricingSession || hasLOA)
			&& (
				!utilityMarketSetting.requireLoaOrBillOrManyLocations
				|| (utilityMarketSetting.requireLoaOrBillOrManyLocations && hasLOA)
				|| (utilityMarketSetting.requireLoaOrBillOrManyLocations && hasBill)
				|| (utilityMarketSetting.requireLoaOrBillOrManyLocations && !hasFewLocations)
			)
			&&
			// allow if none required, or have at least one of the required
			(
				(
					!utilityMarketSetting.requireLoeForPricingSession &&
					!utilityMarketSetting.requireHudForPricingSession &&
					!utilityMarketSetting.requireBillForPricingSession
				)
				|| (utilityMarketSetting.requireLoeForPricingSession && hasLOE)
				|| (utilityMarketSetting.requireHudForPricingSession && hasHUD)
				|| (utilityMarketSetting.requireBillForPricingSession && hasBill)
			);
	}

	public getUtilityMarketSetting(): UtilityMarketSetting {
		return this.getUtility()?.utilityMarketSettings
			.find(ust => ust.serviceTypeId === this.serviceTypeId && ust.stateId === this.stateId);
	}

	public getUtility(): Utility {
		return this.locations && this.locations.length && this.locations[0].utility
			? this.locations[0].utility
			: null;
	}

	public getUtilityId(): string {
		return this.locations && this.locations.length && this.locations[0].utilityId
			? this.locations[0].utilityId
			: '';
	}

	public getUtilityAbbreviation(): string {
		return this.locations && this.locations.length && this.locations[0].utility
			? this.locations[0].utility.abbreviation
			: '';
	}

	public getUtilityName(): string {
		return this.locations && this.locations.length && this.locations[0].utility
			? this.locations[0].utility.name
			: '';
	}

	public getLocationAnnualUsage(): number {
		return this.locations && this.locations.length && this.locations[0].annualUsage
			? this.locations[0].annualUsage
			: 0;
	}

	public getZipCode(): string {
		return this.locations && this.locations.length && this.locations[0].zipCode
			? this.locations[0].zipCode
			: '';
	}

	public getZone(): string {
		return this.locations && this.locations.length && this.locations[0].zone
			? this.locations[0].zone
			: '';
	}

	public getRateClass(): string {
		return this.locations && this.locations.length && this.locations[0].rateClass
			? this.locations[0].rateClass
			: '';
	}

	public getUtilityAccountNum(): string {
		return this.locations && this.locations.length && this.locations[0].utilityAccountNum
			? this.locations[0].utilityAccountNum
			: '';
	}

	public getBaseRate(): number {
		if (this.locations && this.locations.length && this.locations[0].utility && this.serviceTypeId) {
			if (this.locations[0].utility.baseRates) {
				const baseRate = this.locations[0].utility.baseRates
					.filter(b => b.stateId === this.stateId && b.serviceTypeId === this.serviceTypeId &&
						this.isResidential ? b.rateClass.toLowerCase() === 'rs' : b.rateClass.toLowerCase() !== 'rs')
					.find(b => (!this.getRateClass() || (b.rateClass.toLowerCase() === this.getRateClass().toLowerCase()))
						&& (!this.getZone() || (b.zone.toLowerCase() === this.getZone().toLowerCase())));
				return baseRate ? baseRate.ptc : 0;
			}
		}

		return 0;
	}

	public getAdditionalInsureds(): AdditionalInsured[] {
		return this.locations && this.locations.length
			? _.flatMap(this.locations, l => l.homeowner.additionalInsureds)
			: [];
	}

	public getPets(): Pet[] {
		return this.locations && this.locations.length
			? _.flatMap(this.locations, l => l.homeowner.pets)
			: [];
	}

	public getLossHistories(): LossHistory[] {
		return this.locations && this.locations.length
			? _.flatMap(this.locations, l => l.homeowner.lossHistories)
			: [];
	}

	public showGRTIncluded(): boolean {
		return [CONSTANTS.serviceTypes.electricity.serviceTypeId, CONSTANTS.serviceTypes.communitySolar.serviceTypeId]
			.includes(this.serviceTypeId)
			&& this.stateId === CONSTANTS.states.pennsylvania;
	}

	public canDeleteLocation(): boolean {
		return this.isEditable()
			|| (!this.isResidential && this.status === CONSTANTS.statuses.rejected);
	}

	public isEditable(): boolean {
		return [
			CONSTANTS.statuses.acomp,
			CONSTANTS.statuses.dr,
			CONSTANTS.statuses.quote,
			CONSTANTS.statuses.rejected,
		].find(s => s === this.status) != null
			&& !(this.isResidential && this.status === CONSTANTS.statuses.dr);
	}

	public isCMSUser(user: User): boolean {
		return HelperService.isCMSUserForContract(user, this);
	}

	public hasLOA(): boolean {
		return this.esigns
			&& this.esigns.some(esign => esign.documents &&
				esign.documents.some(document => document.document &&
					document.document.name.toLowerCase().includes('loa')));
	}

	public hasLOE(): boolean {
		return this.esigns
			&& this.esigns.some(esign => esign.documents &&
				esign.documents.some(document => document.document &&
					document.document.name.toLowerCase().includes('loe')));
	}

	private getMultiplier(): number {
		switch (this.serviceTypeId) {
			case CONSTANTS.serviceTypes.electricity.serviceTypeId:
			case CONSTANTS.serviceTypes.communitySolar.serviceTypeId:
			case CONSTANTS.serviceTypes.naturalGas.serviceTypeId:
				return 100.0;
			default:
				return 1;
		}
	}

	public getSavings(): number {
		const baseRate = this.previousRate ? this.previousRate : (this.getBaseRate() / this.getMultiplier());
		const usage = this.previousAnnualUsage ? this.previousAnnualUsage : this.annualUsage;
		const savings = Math.round(((usage * (baseRate - this.rate)) / 100) * this.term / 12.0) * 100;
		return savings > 0 ? savings / 12 : 0;
	}

	public serviceFee(): number {
		if (this.dailyServiceFee && this.dailyServiceFee > 0) {
			return this.dailyServiceFee;
		} else if (this.monthlyServiceFee > 0) {
			return this.monthlyServiceFee;
		}
	}

	public serviceFeeSuffix(): string {
		if (this.dailyServiceFee && this.dailyServiceFee > 0) {
			return 'per day';
		} else if (this.monthlyServiceFee > 0) {
			return 'per mo.';
		}
	}

	public bandwidthStatement(): string {
		if (this.bandwidthPercentage === 0) {
			return 'In case your current year usage deviates from the last year usage, ' +
				'the additional usage will be purchased at the market rate and will be a variable bill.';
		} else if (this.bandwidthPercentage === 100) {
			return 'No Bandwidth Clause applies.';
		} else {
			return 'This price will remain fixed as long as the consumption doesn\'t go below '
				+ (100 - this.bandwidthPercentage) +
				'% or above '
				+ (100 + this.bandwidthPercentage) +
				'% of customer\'s past year\'s usage.';
		}
	}

	public getPUCT(): string {
		return this.stateId === CONSTANTS.states.texas ? this.supplier.puct : '';
	}

	public hasIncompleteEsigns(): boolean {
		return this.esigns ? this.esigns.some(e => !e.isComplete) : false;
	}

	public showStatusButton(): boolean {
		return !this.isResidential && ([
			CONSTANTS.statuses.ainp,
			CONSTANTS.statuses.acomp].includes(Number(this.status))
			|| this.showRequestPrice());
	}

	get isAboveUsageThreshold(): boolean {
		return ([CONSTANTS.serviceTypes.electricity.serviceTypeId, CONSTANTS.serviceTypes.communitySolar.serviceTypeId]
			.includes(this.serviceTypeId)
			&& this.annualUsage >= 100000) ||
			(this.serviceTypeId === CONSTANTS.serviceTypes.naturalGas.serviceTypeId && this.annualUsage >= 5000);
	}

	get isAboveUsageThresholdForRecommendation(): boolean {
		return ([CONSTANTS.serviceTypes.electricity.serviceTypeId, CONSTANTS.serviceTypes.communitySolar.serviceTypeId]
			.includes(this.serviceTypeId)
			&& this.annualUsage >= 350000) ||
			(this.serviceTypeId === CONSTANTS.serviceTypes.naturalGas.serviceTypeId && this.annualUsage >= 5000);
	}

	public showRequestPrice(): boolean {
		// either already in Request Price
		// or you've been rejected for Load Factor
		return ((CONSTANTS.statuses.dr === Number(this.status) ||
			(CONSTANTS.statuses.rejected === Number(this.status)
				&& this.rejectReasonSubCategoryId === CONSTANTS.rejectReasonSubCategories.rejectedLoadFactor)) &&
			(this.isAboveUsageThreshold || this.overrideShowRequestPrice)
		);
	}

	public showResendRenewalButton(): boolean {
		return this.isResidential && this.status === CONSTANTS.statuses.dr && !!this.previousContract;
	}

	public getSupplierNote(): string {
		return HelperService.getSupplierNote(this.serviceTypeId, this.stateId, this.supplierId);
	}

	get showViewMatrixPrice(): boolean {
		const daysToAuctionEnd = this.rfqSession
			? (this.rfqSession.endDate2.getTime() - new Date().getTime()) / (24 * 60 * 60 * 1000) : 100;
		return this.status !== CONSTANTS.statuses.ainp && !(this.status === CONSTANTS.statuses.acomp && daysToAuctionEnd < 2);
	}

	public showAverageMonthlyUsageText(stateId: string): boolean {
		return this.isResidential &&
			stateId === CONSTANTS.states.texas &&
			this.supplierId === CONSTANTS.suppliers.directEnergy;
	}

	get showMarketRecommendations(): boolean {
		const minUsage = this.serviceTypeId === CONSTANTS.serviceTypes.electricity.serviceTypeId
			? 1500000 : 120000;

		return this.annualUsage >= minUsage ? true : false;
	}
}

export class AgentActivityEmerging {

	public companyId: string;
	public companyName: string;
	public agentId: string;
	public agentName: string;
	public parentAgentId: string;
	public parentName: string;
	public contactName: string;
	public phone: string;
	public phoneCountryId: number;
	public phoneExt: string;
	public email: string;
	public isActive: boolean;
	public totalTermValue: number;
	public lastContractDate: Date;
	public pctRank: number;

	constructor(agentActivityEmerging?: AgentActivityEmerging) {
		Object.assign(this, agentActivityEmerging);
	}
}

<pk-broker-modal>
	<ng-template #headerTemplate>
		<h5 class="modal-title">
			{{ ticket.subject ?? 'New Ticket' }} - {{ ticket.user ? ticket.user.fname + ' ' + ticket.user.lname : ticket.email }} - ({{
				ticket.user?.role.name ?? ''
			}})
		</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<pk-broker-loader [show]="loading"></pk-broker-loader>
		<form [formGroup]="formGroup" *ngIf="!loading">
			<div class="container-fluid">
				<div class="form-row">
					<div class="col-12 col-lg-7 col-xl-8 form-element-container" [class.invalid]="err.subject">
						<label for="subject" class="required">Subject</label>
						<input
							type="text"
							class="form-control required"
							id="subject"
							placeholder="Subject"
							formControlName="subject"
							autocomplete="off"
							maxlength="100" />
						<p class="mb-0 invalid-feedback" *ngIf="err.subject">
							<ng-container *ngIf="err.subject.maxlength">Subject must be 100 characters or fewer.</ng-container>
							<ng-container *ngIf="err.subject.required">Subject is required</ng-container>
						</p>
					</div>

					<div class="col-12 col-lg-5 col-xl-4 form-element-container" [class.invalid]="err.ticketCategoryId">
						<pk-broker-form-dropdown
							label="Category"
							[isRequired]="true"
							formControlName="ticketCategoryId"
							[options]="ticketCategories"
							textKey="name"
							valueKey="id"
							nullOption="-- Select Category --"
							[nullOptionSelectable]="false">
						</pk-broker-form-dropdown>
						<p class="mb-0 invalid-feedback" *ngIf="err.ticketCategoryId">
							<ng-container *ngIf="err.ticketCategoryId.required">Category is required</ng-container>
						</p>
					</div>
				</div>

				<div class="form-row" *ngIf="loggedInUser.hasPermission('ticket-admin')">
					<div class="col-12 col-lg-12 form-element-container" [class.invalid]="err.reporter">
						<pk-broker-user-selector
							label="Reporter"
							[isRequired]="true"
							[isTicketDashboard]="true"
							[defaultSearchAdmin]="!form.reporter.value?.agentId"
							formControlName="reporter"></pk-broker-user-selector>
						<p class="mb-0 invalid-feedback" *ngIf="err.reporter">
							<ng-container *ngIf="err.reporter.required">Reporter is required</ng-container>
						</p>
					</div>
				</div>
				<div class="form-row mb-4" *ngIf="loggedInUser.hasPermission('ticket-admin')">
					<div class="col-12 col-lg-5 form-element-container">
						<pk-broker-form-dropdown
							label="Assignee"
							formControlName="assignedToUserId"
							[options]="users"
							[textMap]="userTextMap"
							valueKey="userId"
							nullOption="-- Select Assignee --">
						</pk-broker-form-dropdown>
					</div>
				</div>

				<div class="form-row">
					<div class="col-12 col-lg-8 col-xl-6">
						<div class="row">
							<div class="col" [class.invalid]="startValidation && formGroup.errors?.noSelectedContract">
								<p class="semibold-text">What contract(s) does this relate to?</p>
							</div>
							<div class="col-auto">
								<p>
									<a href="javascript:void(0)" class="semibold-text" (click)="lookupContract(ticket)">
										<fa-icon [icon]="['far', 'square-plus']"></fa-icon>Add Contract
									</a>
								</p>
							</div>
						</div>
						<div class="form-row">
							<div class="col-12 col-lg-6">
								<div *ngFor="let contract of form.contracts; let i = index">
									<div class="row">
										<div class="col-10 pl-5">
											<p>
												<a [routerLink]="['/contract', contract.value.contractId, 'info']" target="_blank" class="semibold-text">
													{{ contract.value.displayContractNum }}
												</a>
											</p>
										</div>
										<div class="col-2 col-md-1">
											<p>
												<a href="javascript:void(0)" class="red-link" (click)="form.contracts.remove(contract)">
													<fa-icon [icon]="['fas', 'circle-xmark']"></fa-icon>
												</a>
											</p>
										</div>
									</div>
								</div>
								<p class="mb-0 invalid-feedback" *ngIf="startValidation && formGroup.errors?.noSelectedContract">
									Please link one or more contracts to this ticekt.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-lg-6 col-xl-7 form-element-container" [class.invalid]="err.email">
						<label for="email" class="required">Email</label>
						<input
							type="email"
							class="form-control required"
							id="email"
							placeholder="Email"
							formControlName="email"
							autocomplete="off"
							maxlength="200" />
						<p class="mb-0 invalid-feedback" *ngIf="err.email">
							<ng-container *ngIf="err.email.maxlength">Email must be 200 characters or fewer.</ng-container>
							<ng-container *ngIf="err.email.required">Email is required</ng-container>
						</p>
					</div>
				</div>
				<div class="form-row">
					<div class="col-12 form-element-container" [class.invalid]="err.body">
						<label class="title required" for="body">Details</label>
						<pk-broker-html-editor *ngIf="!form.body.disabled" formControlName="body" [invalid]="err.body"></pk-broker-html-editor>
						<p *ngIf="form.body.disabled" [innerHtml]="form.body.value"></p>
						<p class="mb-0 invalid-feedback" *ngIf="err.body">
							<ng-container *ngIf="err.body.required">Details is required</ng-container>
						</p>
					</div>
				</div>
				<div class="form-row">
					<div class="col-12 pb-0 form-element-container">
						<pk-broker-file-selector
							#fileSelector
							label="Attachment(s)"
							formControlName="attachments"
							[multiple]="true"
							[softDelete]="false"
							accept=".doc,.docx,.pdf,.txt,.bmp,.gif,.png,.jpg,.jpeg,.tiff,.xlsx,.xls,.xlsm,.xlsb,.csv,.zip"
							title="Ticket"
							helperText="You may select multiple files from the dialog."
							[applyImmediately]="false">
						</pk-broker-file-selector>
					</div>
				</div>
			</div>

			<pk-broker-page-warnings [warnings]="warnings"></pk-broker-page-warnings>

			<div class="row">
				<div class="col mt-4 text-right">
					<button type="button" class="btn btn-primary" (click)="submit()" [disabled]="submitDisabled">{{ submitText }}</button>
				</div>
			</div>
		</form>
	</ng-template>
</pk-broker-modal>

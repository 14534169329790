export class LendingModuleSettingQuickPayMarginThreshold {

	public id: number;
	public minDropRatio: number;
	public maxDropRatio: number;
	public maxAllowedMargin: number;

	constructor(lendingModuleSettingQuickPayMarginThreshold: LendingModuleSettingQuickPayMarginThreshold) {
		Object.assign(this, lendingModuleSettingQuickPayMarginThreshold);
	}
}

import { Product } from './product';
import { RfqSession } from './rfq-session';
import { RfqSessionBid } from './rfq-session-bid';
import { RfqSessionStat } from './rfq-session-stat';

export class RfqSessionProduct {

	public id: string;
	public maxBid: string;
	public term: number;
	public lag: number;
	public blockType: string;
	public blockSize: string;
	public productId: string;
	public rfqSessionId: string;
	public greenPercentage: number;
	public initialFixedUsage: number;

	public product: Product;
	public rfqSession: RfqSession;
	public bids: RfqSessionBid[];

	public bidsAnalysis: { [key: string]: { [key: string]: RfqSessionBid } }; // best bid of each last bid day { supplier: day: bid }
	public latestBids: { [key: string]: { [key: string]: RfqSessionBid } }; // latest bids of the supplier { supplier: day: bid }
	public latestBidDays: { [key: string]: string[] };

	public bestBids: RfqSessionStat[];
	public supplierBestBid: RfqSessionStat;

	constructor(rfqSessionProduct?: RfqSessionProduct) {
		Object.assign(this, rfqSessionProduct);

		this.blockType = this.blockType ? this.blockType : '0';
		this.product = this.product ? new Product(this.product) : this.product;
	}

	get description(): string {
		return `${this.product.name} - ${this.term} month${this.term > 1 ? 's' : ''}`
			+ `${this.blockType && this.blockType !== '0' ? ' - ' + this.blockType : ''} ${this.blockSize ? ' - ' + this.blockSize : ''}`;
	}
}

import * as moment from 'moment-timezone';

export class ContractBudgetDetail {
	public monthlyUsage: number;
	public totalMonthlyDeliveryFee: number;
	public totalMonthlyTaxFee: number;
	public addDate: Date;

	constructor(contractBudgetDetail?: ContractBudgetDetail) {
		Object.assign(this, contractBudgetDetail);

		this.addDate = this.addDate
			? new Date(moment(this.addDate).format('MMM DD, YYYY 00:00:00 a')) : this.addDate;
	}
}

export class UtilityAccountNumRenewalValidationRequest {
	public utilityAccountNum: string;
	public utilityReferenceNum?: string;
	public utilityId: string;
	public serviceTypeId: string;
	public effectiveDate: string;
	public contractId?: string;
}

export class UtilityAccountNumRenewalValidationResponse {
	public utilityAccountNum: string;
	public utilityReferenceNum: string;
	public hasError: boolean;
	public errorMessage: string;
}

export class UtilityAccountNumRenewalValidation {
	request: UtilityAccountNumRenewalValidationRequest;
	response: UtilityAccountNumRenewalValidationResponse;
}

export class EducationAudit {

	public id: number;
	public criteria: string;
	public matches: number;
	public terms: string;
	public matchedArticles: string;

	constructor(educationAudit?: EducationAudit) {
		Object.assign(this, educationAudit);
	}
}

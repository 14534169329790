import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { RfqSessionSupplier } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-auction-supplier-create-bids-note',
	templateUrl: './auction-supplier-create-bids-note.component.html',
	styleUrls: ['./auction-supplier-create-bids-note.component.scss'],
})
export class AuctionSupplierCreateBidsNoteComponent extends AbstractPageForm {

	@Input() rfqSessionId: string;
	@Input() rfqSessionSupplier: RfqSessionSupplier;

	constructor(
		private toastrService: ToastrService,
		private graphqlService: GraphqlService,
		private fb: FormBuilder,
	) {
		super();
		this.submitText = 'Save Note';
		this.submittingText = 'Saving...';
		this.submitAudit = 'auction-supplier-create-bids:submitSupplierNote';
	}

	public async loadPageData(): Promise<void> { }

	public getForm() {
		return this.fb.group({
			note: [this.rfqSessionSupplier.note, [Validators.maxLength(1000)]],
		});
	}

	protected async onFormSubmitted() {
		const result = await this.graphqlService.updateRfqSessionSupplier(this.rfqSessionSupplier.id, this.createRfqSessionSupplierDTO());
		if (result) {
			this.toastrService.success('Supplier Note Successfully Updated');
		}
	}

	private createRfqSessionSupplierDTO() {
		return {
			note: this.form.note.value,
			rfqSessionId: this.rfqSessionId,
		};
	}
}

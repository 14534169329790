<pk-broker-modal customFooterClass="justify-content-center">
	<ng-template #headerTemplate>
		<h5 class="modal-title">Delete Feedback Entry</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<p class="mb-0">Do you wish to delete this entry?</p>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" class="btn btn-primary" [disabled]="submitDisabled" (click)="deleteFeedback()">{{ submitText }}</button>
		<button type="button" class="btn btn-light" [disabled]="submitDisabled" (click)="activeModal.hide()">No</button>
	</ng-template>
</pk-broker-modal>

import { User } from './user';

export class AdditionalInsured {

	public id: string;
	public fname: string;
	public middle: string;
	public lname: string;
	public dob: Date;
	public ssn: string;
	public addDate: Date;
	public userId: string;

	public user: User;

	constructor(additionalInsured: AdditionalInsured) {
		Object.assign(this, additionalInsured);

		this.user = this.user ? new User(this.user) : this.user;
	}

	public getFullName(): string {
		const middle = this.middle && this.middle.trim() ? this.middle.trim() + ' ' : '';
		return `${this.fname} ${middle}${this.lname}`;
	}
}

import * as moment from 'moment-timezone';

export class UpcomingRenewalMonth {
	public date: string;
	public numTotalContracts: number;
	public termValue: number;

	// internal
	public isPastDue = false;
	public pastDueDate: string;
	public agentId: string;

	constructor(upcomingRenewalMonth?: UpcomingRenewalMonth) {
		Object.assign(this, upcomingRenewalMonth);

		this.date = moment(this.date).format('MMM YYYY');
		this.isPastDue = this.date === moment().startOf('month').format('MMM YYYY');
		this.pastDueDate = moment().startOf('month').subtract(5, 'months').format('MMM YYYY');
	}
}

export class UpcomingRenewals {

	public totalTermValue: number;
	public months: UpcomingRenewalMonth[];

	constructor(upcomingRenewals?: UpcomingRenewals) {
		Object.assign(this, upcomingRenewals);

		this.months = this.months.map(m => new UpcomingRenewalMonth(m));
	}
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';

import * as _ from 'lodash';

@Component({
	selector: 'pk-broker-options-filter-dropdown',
	templateUrl: './options-filter-dropdown.component.html',
	styleUrls: ['./options-filter-dropdown.component.scss'],
})
export class OptionsFilterDropdownComponent {

	@Input() options: any[];
	@Input() formArray: FormArray;
	@Input() showDefaultElementsOption = true;
	@Input() outerLabel = 'Filter';
	@Input() innerLabel = '';
	@Input() isDisabled = false;
	@Output() showDefaultElements: EventEmitter<boolean> = new EventEmitter();

	@Input()
	scrollAreaHeight: 'medium' | null = null;

	constructor(
		private fb: FormBuilder,
	) { }

	public selectElement(data: any) {
		const index = _.findIndex(this.formArray.value, e => _.isEqual(e, data));
		if (index < 0) {
			this.formArray.push(this.fb.control(data));
		} else {
			this.formArray.removeAt(index);
		}
	}
	public selectNoElements() {
		this.formArray.clear();
	}
	public selectAllElements() {
		this.formArray.clear();
		this.options.forEach(e => {
			this.formArray.push(this.fb.control(e));
		});
	}

	public filterDefaultElements() {
		this.showDefaultElements.emit(true);
	}

}

export class LendingModuleSettingQuickPayUpfrontThreshold {

	public id: number;
	public minTermValue: number;
	public maxTermValue: number;
	public maxUpfrontAmount: number;
	public maxCycleUpfrontAmount: number;

	constructor(lendingModuleSettingQuickPayUpfrontThreshold: LendingModuleSettingQuickPayUpfrontThreshold) {
		Object.assign(this, lendingModuleSettingQuickPayUpfrontThreshold);
	}
}

export class EclLoadFactorBracket {

	public id: number;
	public name: string;
	public minLoadFactor: number;
	public maxLoadFactor: number;
	public sortOrder: number;

	constructor(eclLoadFactorBracket?: EclLoadFactorBracket) {
		Object.assign(this, eclLoadFactorBracket);
	}
}

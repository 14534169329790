export class ViewCommissionReportStats {

	public forecasted: number;
	public actuals: number;
	public reportDeviation: number;
	public received: number;
	public receivableDeviation: number;

	constructor(viewCommissionReportStats?: ViewCommissionReportStats) {
		Object.assign(this, viewCommissionReportStats);
	}
}

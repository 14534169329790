import * as moment from 'moment-timezone';

export class ReportSystemLog {

	public addDate: Date;
	public severity: string;
	public category: string;
	public message: string;
	public extraInfo: any;

	constructor(reportSystemLog: ReportSystemLog) {
		Object.assign(this, reportSystemLog);

		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;

		try {
			this.extraInfo = JSON.parse(this.extraInfo);
		} catch (e) {
			console.log('Unable to parse extra info');
		}
	}
}

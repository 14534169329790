import { NumberUtils } from '@pk/powerkioskutils';

import * as moment from 'moment-timezone';

export class ViewComplianceMonitorContract {

	public contractId: string;
	public companyId: string;
	public agentId: string;
	public contractNum: string;
	public dbaName: string;
	public status: number;
	public contractDate: Date;
	public displayStatus: string;
	public serviceTypeId: string;
	public commodity: string;
	public effectiveDate: Date;
	public term: number;
	public margin: number;
	public units: string;
	public commissionPayPlanTypeId: number;
	public commissionPayPlanTypeName: string;
	public termValue: number;
	public riskScore: number;
	public riskScoreDisplay: string;
	public isoCode: string;
	public totalCommissionPaid: number;

	// internal
	public contractStartDate: string;
	public contractEndDate: string;

	constructor(viewComplianceMonitorContract?: ViewComplianceMonitorContract) {
		Object.assign(this, viewComplianceMonitorContract);

		this.effectiveDate = this.effectiveDate
			? new Date(moment(this.effectiveDate).format('MMM DD, YYYY 00:00:00 a')) : this.effectiveDate;
		this.contractDate = this.contractDate
			? new Date(moment(this.contractDate).format('MMM DD, YYYY 00:00:00 a')) : this.contractDate;
	}

	get marginDisplay(): number {
		return NumberUtils.rateToUnits(this.margin, this.units);
	}
}

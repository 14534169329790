import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { Note, NoteType } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-note-add',
	templateUrl: './note-add.component.html',
	styleUrls: ['./note-add.component.scss'],
})
export class NoteAddComponent extends AbstractPageForm {
	@Input() noteTypes: NoteType[];
	@Input() contractId: string;

	@Output() cancelled: EventEmitter<boolean> = new EventEmitter();
	@Output() submitted: EventEmitter<{ note?: Note; noteType?: NoteType }> = new EventEmitter();

	public someNonInternalNoteTypes = true;

	constructor(
		private graphqlService: GraphqlService,
		private toastrService: ToastrService,
		private fb: FormBuilder,
	) {
		super();
		this.submitAudit = 'note-add:submitNote';
	}

	getForm() {
		this.someNonInternalNoteTypes = this.noteTypes.filter(nt => nt.noteTypeCategoryId)
			.some(nt => !this.isAutomaticInternalNote(nt.noteTypeCategoryId));

		return this.fb.group({
			noteType: [null as NoteType, [Validators.required]],
			contractId: this.contractId,
			subject: ['', [Validators.required, Validators.maxLength(50)]],
			body: ['', [Validators.maxLength(500)]],
			isInternal: false,
		});
	}

	public isAutomaticInternalNote(noteTypeCategoryId: number): boolean {
		return [
			this.CONSTANTS.noteTypeCategories.compliance,
		].includes(noteTypeCategoryId);
	}

	public async onFormLoaded(): Promise<void> {
		this.form.noteType.valueChanges.subscribe(noteType => {
			if (this.isAutomaticInternalNote(noteType?.noteTypeCategoryId)) {
				this.form.isInternal.setValue(true);
			} else {
				this.form.isInternal.setValue(false);
			}

			this.form.isInternal.updateValueAndValidity();
		});

		if (this.noteTypes.length === 1) {
			this.form.noteType.setValue(this.noteTypes[0]);
		}
	}

	public cancel(): void {
		this.cancelled.emit(true);
	}

	protected async onFormSubmitted() {
		const dto = this.getNoteDto();
		const result = await this.graphqlService.createNote(dto);
		this.toastrService.success(`Successfully saved Note`, `Add Note`);
		this.submitted.emit({ note: new Note(result.data.createNote), noteType: this.form.noteType.value });
	}

	private getNoteDto() {
		const dto = {
			...this.formGroup.value,
			noteTypeId: this.form.noteType.value.noteTypeId,
		};

		delete dto.noteType;

		return dto;
	}
}

import { ServiceType } from './';
import { State } from './state';

export class UtilityMarketSetting {
	public id: number;
	public utilityId: string;
	public stateId: string;
	public serviceTypeId: string;
	public hasEcl: boolean;
	public requireLoaForPricingSession: boolean;
	public requireLoeForPricingSession: boolean;
	public requireHudForPricingSession: boolean;
	public requireBillForPricingSession: boolean;
	public requireLoaOrBillOrManyLocations: boolean;
	public isActive: boolean;

	public state: State;
	public serviceType: ServiceType;

	constructor(utilityMarketSetting?: Partial<UtilityMarketSetting>) {
		Object.assign(this, utilityMarketSetting);
	}
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'numberOrdinal',
})
export class NumberOrdinalPipe implements PipeTransform {
	transform(number: number): string {
		if (isNaN(number)) { return ''; }

		const lastTwo = number % 100;
		if (!(lastTwo > 9 && lastTwo < 20)) {
			switch (number % 10) {
				case 1:
					return number + 'st';
				case 2:
					return number + 'nd';
				case 3:
					return number + 'rd';
			}
		}

		return number + 'th';
	}
}

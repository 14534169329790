import { CONSTANTS } from '@pk/powerkioskutils';

import * as moment from 'moment-timezone';

import { HelperService } from '../helper.service';
import { ContractAdvancedAgent } from './contract-advanced-agent';
import { ContractAdvancedCustomer } from './contract-advanced-customer';
import { ContractAdvancedRfqSession } from './contract-advanced-rfq-session';
import { ContractAdvancedServiceType } from './contract-advanced-service-type';
import { ContractAdvancedSupplier } from './contract-advanced-supplier';

export class ContractAdvanced {

	public contractId: string;
	public contractNum: string;
	public displayStatus: string;
	public status: number;
	public agentId: string;
	public customerId: string;
	public serviceTypeId: string;
	public stateId: string;
	public locationAddress: string[];
	public locationUtilityAccountNum: string[];
	public locationUtilityMeterNum: string[];
	public locationUtilityReferenceNum: string[];
	public locationUtilityNameKey: string[];
	public isNewBrokerAuction: boolean;

	public displayContractNum: string;

	public agent: ContractAdvancedAgent;
	public customer: ContractAdvancedCustomer;
	public serviceType: ContractAdvancedServiceType;
	public supplier: ContractAdvancedSupplier;
	public lastSession: ContractAdvancedRfqSession;

	constructor(contractAdvanced: ContractAdvanced) {
		Object.assign(this, contractAdvanced);

		this.agent = this.agent ? new ContractAdvancedAgent(this.agent) : this.agent;
		this.customer = this.customer ? new ContractAdvancedCustomer(this.customer) : this.customer;
		this.serviceType = this.serviceType ? new ContractAdvancedServiceType(this.serviceType) : this.serviceType;
		this.supplier = this.supplier ? new ContractAdvancedSupplier(this.supplier) : this.supplier;
		this.lastSession = this.lastSession ? new ContractAdvancedRfqSession(this.lastSession) : this.lastSession;

		this.displayContractNum = HelperService.showAsteriskForContractFirstImpression(Number(this.status), this.isNewBrokerAuction)
			? this.contractNum + '*'
			: this.contractNum;

		if (this.lastSession && this.status === CONSTANTS.statuses[CONSTANTS.statuses.ainp]) {
			setInterval(() => {
				if (moment(this.lastSession.endDate2) < moment()) {
					this.status = CONSTANTS.statuses.acomp;
					this.displayStatus = CONSTANTS.statuses[CONSTANTS.statuses.acomp];
				}
			}, 1000);
		}
	}

	get energyIcon(): string {
		return HelperService.getEnergyIcon(this.serviceTypeId);
	}
}





import { Contract } from './contract';
import { Supplier } from './supplier';

export class PendingConfirmation {

	public id: number;
	public externalId: string;
	public supplierStatus: string;
	public supplierStatus2: string;
	public status: number;
	public displayStatus: string;
	public rejectReason: string;
	public contactFname: string;
	public contactLname: string;
	public address1: string;
	public address2: string;
	public city: string;
	public state: string;
	public zipCode: string;
	public email: string;
	public utilityAccountNum: string;
	public isProcessed: boolean;
	public warnings: string;
	public errors: string;
	public queueStatus: string;
	public contractId: string;
	public supplierId: string;
	public dbaName: string;
	public dropDate: Date;
	public addDate: Date;
	public modifiedDate: Date;

	public contract: Contract;
	public supplier: Supplier;

	public selectedForProcess: boolean;

	constructor(pendingConfirmation?: PendingConfirmation) {
		Object.assign(this, pendingConfirmation);

		this.contract = this.contract ? new Contract(this.contract) : this.contract;
		this.supplier = this.supplier ? new Supplier(this.supplier) : this.supplier;
	}

	get customerName(): string {
		return this.contract && this.contract.customer
			? this.contract.customer.dbaName
			: '';
	}

	get contact(): string {
		const contactName = `${this.contactFname || ''}${this.contactLname ? ' ' + this.contactLname : ''}`.trim();

		return `${contactName}${this.dbaName && contactName !== this.dbaName ? ' ' + this.dbaName : ''}`.trim();
	}

	get address(): string {
		if (!this.address1 && !this.address2 && !this.city && !this.state && !this.zipCode) {
			return null;
		} else {
			return `${this.address1} ${this.address2}<br>${this.city}, ${this.state} ${this.zipCode}`.trim();
		}
	}

	get supplierName(): string {
		return this.supplier
			? this.supplier.name
			: '';
	}

	get errorMessage(): string {
		return ((this.warnings ? '' : this.warnings) + (this.errors ? ' ' + this.errors : '')) || '';
	}
}

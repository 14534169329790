import { MailServerType } from './mail-server-type';

export class MailServer {

	public id: number;
	public server: string;
	public port: number;
	public username: string;
	public password: string;
	public useSSL: boolean;
	public useTLS: boolean;
	public runVerify: boolean;
	public fromEmail: string;
	public mailServerTypeId: number;

	public mailServerType: MailServerType;

	constructor(mailServer?: MailServer) {
		Object.assign(this, mailServer);

		this.mailServerType = this.mailServerType ? new MailServerType(this.mailServerType) : this.mailServerType;
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { RateUtils } from '@pk/powerkioskutils';

import { CommissionPayPlan, LendingModuleSetting } from '../models';

@Pipe({
	name: 'formatCommissionPayPlanDetails',
})
export class FormatCommissionPayPlanDetailsPipe implements PipeTransform {

	constructor() { }

	transform(
		details: string, symbol: string, commissionPayPlanTypeId: number,
		commissionPayPlans: CommissionPayPlan[], lendingModuleSetting: LendingModuleSetting,
	): string {
		const commissionPayPlan = commissionPayPlans.find(cp => cp.commissionPayPlanTypeId === commissionPayPlanTypeId);
		return RateUtils.formatCommissionPayPlanDetails(details, symbol, lendingModuleSetting, commissionPayPlan);
	}
}

import { CONSTANTS } from '@pk/powerkioskutils';

import * as moment from 'moment-timezone';

import { Contract } from './contract';
import { EsignDocument } from './esign-document';

export class ESign {

	public esignId: string;
	public isActive: boolean;
	public isComplete: boolean;
	public addDate: Date;

	public signature: string;
	public initials: string;
	public signatureDate: string | Date;

	public contract: Contract;
	public documents: EsignDocument[];

	public usePlural: boolean;
	public displayText: string;

	constructor(esign?: ESign) {
		Object.assign(this, esign);
		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
		this.contract = this.contract ? new Contract(this.contract) : this.contract;
	}

	// valid if it's active, not complete, and not more than 48 hours old
	public isValid(): boolean {
		const esignAge = (new Date().valueOf() - this.addDate.valueOf()) / (60 * 60 * 1000);
		return this.isActive &&
			!this.isComplete &&
			esignAge < CONSTANTS.esignTimeout;
	}
}

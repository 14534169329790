import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'salesTax'
})
export class SalesTaxPipe implements PipeTransform {

	transform(value: number): string {
		if (value) {
			return value + '%';
		}

		return 'No additional Sales Tax Applies';
	}

}

export class RateCalendar {

	public minRate: string;
	public minRateRaw: number;
	public effectiveDate: string | Date;
	public term: number;
	public searched: boolean;
	public supplierIdList: string;

	constructor(rate: RateCalendar) {
		Object.assign(this, rate);

		if (this.effectiveDate && typeof this.effectiveDate === 'string') {
			const dateParts = this.effectiveDate.split('-');
			this.effectiveDate = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2].split('T')[0]), 0, 0, 0);
		}
		this.minRateRaw = Number(this.minRate.replace(/[$¢]/g, ''));
	}
}

export class AgentDiscountProgram {

	public id: number;
	public name: string;
	public minVolume: number;
	public maxVolume: number;
	public rate: number;
	public className1: string;
	public className2: string;
	public className3: string;
	public addDate: string;

	constructor(agentDiscountProgram?: AgentDiscountProgram) {
		Object.assign(this, agentDiscountProgram);
	}

	public getCSSWidth(volume: number): number {
		if (volume >= this.maxVolume) {
			return 100;
		} else if (volume < this.minVolume) {
			return 0;
		} else {
			return Math.floor((volume - this.minVolume) / (this.maxVolume - this.minVolume) * 100);
		}
	}
}

import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';
import * as moment from 'moment-timezone';

import { ReportCommissionBrokerPaid } from '../models';

@Pipe({
	name: 'agentCommissionsPaidReport'
})
export class AgentCommissionsPaidReportPipe implements PipeTransform {

	transform(commissions: ReportCommissionBrokerPaid[], agentId: string, parentId?: string, brotherId?: string):
		ReportCommissionBrokerPaid[] {
		return _.orderBy(commissions && agentId
			? commissions.filter(commission => commission.agentId === agentId && moment(commission.addDate).isBefore(moment()))
			: commissions, [
			c => c.addDate ? c.addDate.valueOf() : 0,
			c => c.meterStartDate ? c.meterStartDate.valueOf() : 0,
			c => c.agentId === parentId ? 2 : c.agentId === brotherId ? 3 : 1],
			['asc', 'asc', 'asc']);
	}

}

import { ServiceType } from './service-type';
import { State } from './state';
import { Supplier } from './supplier';
import { Utility } from './utility';

export class ReportRateMatrix {

	public rateClass: string;
	public count: number;
	public minUsage: number;
	public maxEffectiveDate: string;
	public minAddDate: string;
	public maxAddDate: string;
	public minEffectiveDate: string;
	public zone: string;
	public maxUsage: number;

	public supplier: Supplier;
	public utility: Utility;
	public state: State;
	public serviceType: ServiceType;

	constructor(rateMatrix?: ReportRateMatrix) {
		Object.assign(this, rateMatrix);

		this.serviceType = this.serviceType ? new ServiceType(this.serviceType) : this.serviceType;
		this.supplier = this.supplier ? new Supplier(this.supplier) : this.supplier;
		this.state = this.state ? new State(this.state) : this.state;
	}
}

import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { Feedback, FeedbackType } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-feedback-modal',
	templateUrl: './feedback-modal.component.html',
	styleUrls: ['./feedback-modal.component.scss'],
})
export class FeedbackModalComponent extends AbstractPageForm {

	private page: string;
	public feedbackTypes: FeedbackType[];
	public showSuccess = false;

	constructor(
		public activeModal: BsModalRef,
		private fb: FormBuilder,
		private graphqlService: GraphqlService,
	) {
		super();
		this.submitText = 'Submit Feedback';
		this.submitAudit = 'feedback-modal:submit';
	}

	public init(page: string): void {
		this.page = page;
	}

	public async loadPageData(): Promise<void> {
		const pageResult = await this.graphqlService.getFeedbackModalData();
		this.feedbackTypes = pageResult.data.feedbackTypes.message;
	}

	public getForm() {
		return this.fb.group({
			feedbackTypeId: null as number,
			body: ['', [Validators.required]],
			canContact: [null as boolean, [Validators.required]],
			rating: 0,
		});
	}

	protected async onFormSubmitted() {
		await this.graphqlService.createFeedback(this.getFeedbackDTO());
		this.showSuccess = true;
	}

	private getFeedbackDTO(): Subset<Feedback> {
		const dto = {
			...this.formGroup.value,
			page: this.page,
			url: window.location.href,
		};

		return dto;
	}
}

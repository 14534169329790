import * as moment from 'moment-timezone';

import { ServiceType } from './service-type';
import { State } from './state';

export class UtilityBaseRate {

	public id: number;
	public stateId: string;
	public serviceTypeId: string;
	public utilityId: string;
	public zone: string;
	public rateClass: string;
	public effectiveDate: Date;
	public baseRate: number;
	public demandCharge: number;
	public adjustmentCharge: number;
	public customerCharge: number;
	public ptc: number;

	public sampleAnnualUsage: number;
	public samplePlc: number;

	public state: State;
	public serviceType: ServiceType;

	constructor(utilityBaseRate?: Subset<UtilityBaseRate>) {
		Object.assign(this, utilityBaseRate);

		this.effectiveDate = this.effectiveDate
			? new Date(moment(this.effectiveDate).format('MMM DD, YYYY 00:00:00 a')) : this.effectiveDate;
		this.rateClass = this.rateClass ? this.rateClass : '';
		this.zone = this.zone ? this.zone : '';
	}
}

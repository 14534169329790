import { Pipe, PipeTransform } from '@angular/core';
import { CONSTANTS } from '@pk/powerkioskutils';

import { Attachment } from '../models/attachment';

@Pipe({
	name: 'billAttachments'
})
export class BillAttachmentsPipe implements PipeTransform {
	transform(attachments: Attachment[]): any {
		return attachments ? attachments
			.filter(a => [CONSTANTS.attachmentTypes.document, CONSTANTS.attachmentTypes.sampleBill].includes(a.attachmentTypeId))
			: attachments;
	}
}

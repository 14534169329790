<pk-broker-filter-dropdown
	[scrollAreaHeight]="scrollAreaHeight"
	[insideClick]="true"
	[options]="options"
	(selection)="selectElement($event)"
	[holdOption]="false"
	[isDisabled]="isDisabled">
	<ng-template #name>{{ outerLabel }}</ng-template>
	<ng-template #headerTemplate>
		<div class="row" *ngIf="innerLabel">
			<div class="col">
				<h3>{{ innerLabel }}</h3>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<p>
					Select <a href="javascript:void(0)" (click)="selectAllElements()">All</a> /
					<ng-container *ngIf="showDefaultElementsOption">
						<a href="javascript:void(0)" (click)="filterDefaultElements()">
							Most Popular
							<a class="tooltip-icon" placement="top" container="body" tooltip="This will select months 6, 12, 18, 24, 30, 36, etc">
								<fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
							</a>
						</a>
						/
					</ng-container>
					<a href="javascript:void(0)" (click)="selectNoElements()">None</a>
				</p>
			</div>
		</div>
	</ng-template>
	<ng-template #row let-option>
		<input class="custom-control-input" [checked]="formArray.value.includes(option)" type="checkbox" />
		<label class="custom-control-label">{{ option === 0 ? 'Month-to-Month' : option }}</label>
	</ng-template>
</pk-broker-filter-dropdown>

import { Component, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { Breadcrumb, Feedback, FeedbackFilter, FeedbackType } from 'src/app/shared/models';
import { AbstractPage } from 'src/app/shared/pages/page.abstract';

@Component({
	selector: 'pk-broker-feedback',
	templateUrl: './feedback.component.html',
	styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent extends AbstractPage implements OnInit {

	public static breadcrumbs = [
		{ text: 'Dashboard', route: '/contract' },
		{ text: 'Feedback', isLast: true },
	] as Breadcrumb[];

	public rfqSessionSupplierId: string;
	public feedbacks: Feedback[];
	public feedbackTypes: FeedbackType[];

	public sortOptions: { name: string; value: string }[] = [
		{ name: 'Sort Ascending', value: 'addDate asc' },
		{ name: 'Sort Descending', value: 'addDate desc' },
	];

	public sort: { name: string; value: string };
	public filter = new FeedbackFilter();

	public statusOptions: string[] = [
		'Unresolved',
		'In Progress',
		'Resolved',
	];

	private defaultFeedbackType = {
		name: 'All',
		id: null,
	} as FeedbackType;

	public loadingFeedback = true;
	public listResult: { data: Feedback[]; totalCount: number };
	public currentPage: number;
	public pageCount: number;
	public listData: Subject<{ criteria: any; max: number; offset: number }> = new Subject();

	constructor(private graphqlService: GraphqlService) {
		super(FeedbackComponent.breadcrumbs, 'Feedback');
	}

	public canContactTextMap = (option: boolean) => option ? 'Yes' : 'No';

	ngOnInit() {
		this.setupFeedbackPage();
	}

	private async setupFeedbackPage(): Promise<void> {
		this.sort = this.sortOptions[1];
		this.loading = true;
		this.currentPage = 0;
		this.setupPagerListener();
		this.listFeedback();

		const result = await this.graphqlService.getFeedbackPageData(this.securityService.authFields.loggedInUser.userId);
		this.feedbackTypes = [this.defaultFeedbackType, ...result.data.feedbackTypes.message];
		this.filter.feedbackType = this.feedbackTypes[0];
		this.loading = false;
	}

	private setupPagerListener(): void {
		this.listData.pipe(
			switchMap(({ criteria, max, offset }) =>
				this.graphqlService.getFeedbackDataSub(criteria, this.sort.value, max, offset)),
			tap(() => this.loadingFeedback = false),
		).subscribe(res => {
			this.listResult = {
				data: res.data.feedbacks.message.map(c => new Feedback(c)),
				totalCount: res.data.feedbacks.total,
			};
			this.pageCount = Math.ceil(this.listResult.totalCount / 5);
		});
	}

	public listFeedback(): void {
		this.loadingFeedback = true;
		const criteria = {
			isReviewed: false,
		} as Feedback;

		if (this.filter.canContact !== null) {
			criteria.canContact = this.filter.canContact;
		}

		if (this.filter.feedbackType && this.filter.feedbackType.id) {
			criteria.feedbackTypeId = this.filter.feedbackType.id;
		}

		if (this.filter.status) {
			criteria.status = this.filter.status;
		}

		const max = 5;
		const offset = this.currentPage * 5;
		this.listData.next({ criteria, max, offset });
	}

	public async goToPage(page: number): Promise<void> {
		this.currentPage = page;
		this.listFeedback();
	}

	public selectFeedbackTypeOption(type: FeedbackType): void {
		this.filter.feedbackType = type;
		this.listFeedback();
	}

	public selectSortOption(sort: { name: string; value: string }): void {
		this.sort = sort;
		this.listFeedback();
	}

	public selectCanContactOption(canContact?: boolean): void {
		this.filter.canContact = canContact;
		this.listFeedback();
	}

	public selectStatusOption(status?: string): void {
		this.filter.status = status;
		this.listFeedback();
	}
}

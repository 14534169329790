

export class ViewPricingAnalyticsUtilityPerformance {
	public utilityNames: string;
	public stateShort: string;
	public optimalScore: number;
	public optimalScoreWholePercentage: number;
	public lostMargin: number;
	public marketShare: number;
	public marketShareWholePercentage: number;

	constructor(viewPricingAnalyticsUtilityPerformance: ViewPricingAnalyticsUtilityPerformance) {
		Object.assign(this, viewPricingAnalyticsUtilityPerformance);
	}
}

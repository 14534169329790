import * as moment from 'moment-timezone';

export class EmailAttachment {

	public finishedAt: Date;
	public amount: number;
	public errors: string;
	public warnings: string;
	public isProcessed: boolean;

	constructor(emailAttachment?: EmailAttachment) {
		Object.assign(this, emailAttachment);

		this.finishedAt = this.finishedAt
			? new Date(moment(this.finishedAt).format('MMM DD, YYYY hh:mm:ss a')) : this.finishedAt;
	}
}

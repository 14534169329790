import { HelperService } from '../helper.service';

export class AgentAdvancedAgent {

	public agentId: string;
	public name: string;
	public isNewBroker: boolean;

	public displayName: string;

	constructor(agentAdvancedAgent: AgentAdvancedAgent) {
		Object.assign(this, agentAdvancedAgent);

		this.displayName = HelperService.showAsteriskForBrokerFirstImpression(this.isNewBroker)
			? this.name + '*'
			: this.name;
	}
}

import * as moment from 'moment-timezone';

export class ViewAgentSalesReportLicenseDetail {
	public agentId: string;
	public agentName: string;
	public brotherId: string;
	public addDate: Date;
	public licenseName: string;
	public hasApi: boolean;
	public hasTurnKey: boolean;
	public hasAgentBundle: boolean;
	public hasSalesCodeBundle: boolean;
	public isInbound: boolean;
	public isOutbound: boolean;

	constructor(viewAgentSalesReportLicenseDetail: ViewAgentSalesReportLicenseDetail) {
		Object.assign(this, viewAgentSalesReportLicenseDetail);

		this.addDate = this.addDate
			? new Date(moment(this.addDate).format('MMM DD, YYYY 00:00:00 a')) : this.addDate;
	}
}

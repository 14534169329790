import { Pipe, PipeTransform } from '@angular/core';

import { Utility } from '../models/utility';

@Pipe({
	name: 'utilityServiceTypeMapFilter'
})
export class UtilityServiceTypeMapFilterPipe implements PipeTransform {

	transform(utilities: Utility[]): Utility[] {
		return utilities ? utilities.filter(u => u.utilityId === 'any'
			|| (u.utilityServiceTypes && u.utilityServiceTypes.length)) : utilities;
	}

}

<pk-broker-modal customFooterClass="justify-content-center">
	<ng-template #headerTemplate>
		<h5 class="modal-title">Opt Out</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<pk-broker-loader [show]="loading"></pk-broker-loader>
		<form [formGroup]="formGroup" *ngIf="!loading">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<p>
							We're sorry to see you opting out of this bidding session, and would love to gain some insight as to why. This is completely optional.
						</p>
						<hr class="divider" />
						<p>
							Bidding Session: <span class="semibold-text">{{ contractNum }}</span>
						</p>
						<p>
							Legal Entity Name: <span class="semibold-text">{{ dbaName }}</span>
						</p>
					</div>
				</div>
				<div class="row">
					<div class="col-12 form-element-container" [class.invalid]="err.optOutReason">
						<pk-broker-form-dropdown
							label="Reason for opting out"
							formControlName="optOutReason"
							[options]="optOutReasons"
							textKey="name"
							nullOption="Please Select"
							[nullOptionSelectable]="false">
						</pk-broker-form-dropdown>
						<p class="mb-0 invalid-feedback" *ngIf="err.optOutReason">
							<ng-container *ngIf="err.optOutReason.required">Reason for opting out is required</ng-container>
						</p>
					</div>
				</div>
				<div class="row">
					<div class="col-12 form-element-container" [class.invalid]="err.note">
						<label for="additionalInfo">Additional Information you wish to share <br />(required if Other is selected)</label>
						<textarea class="form-control" id="additionalInfo" rows="3" formControlName="note"></textarea>
						<p class="mb-0 invalid-feedback" *ngIf="err.note">
							<ng-container *ngIf="err.note.required">Additional Information you wish to share is required</ng-container>
						</p>
					</div>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" class="btn btn-primary" (click)="submit()" [disabled]="submitDisabled">{{ submitText }}</button>
	</ng-template>
</pk-broker-modal>

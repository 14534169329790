import { Pipe, PipeTransform } from '@angular/core';

import { EmailPreferenceSettingTargetDateOption } from '../models';

@Pipe({
	name: 'emailPreferenceSettingTargetDateOptionsFilter',
})
export class EmailPreferenceSettingTargetDateOptionsFilterPipe implements PipeTransform {

	transform(options: EmailPreferenceSettingTargetDateOption[], emailPreferenceTypeId: number): unknown {
		return options?.length
			? options.filter(o => !o.id || !emailPreferenceTypeId || o.emailPreferenceTypeId === emailPreferenceTypeId)
			: options;
	}

}

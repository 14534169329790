import { Pipe, PipeTransform } from '@angular/core';

import { RfqSessionBid } from '../models/rfq-session-bid';

@Pipe({
	name: 'productBids'
})
export class ProductBidsPipe implements PipeTransform {
	transform(bids: RfqSessionBid[], rfqSessionProductId: string): any {
		return bids ? bids.filter(b => b.rfqSessionProductId === rfqSessionProductId) : bids;
	}
}

import * as moment from 'moment-timezone';

export class PricingAnalyticsKPIHistoricalViewChartPoint {
	public dataPoints: {
		date: Date;
		data: number;
	}[] = [];

	constructor(pricingAnalyticsKPIHistoricalViewChartPoint: PricingAnalyticsKPIHistoricalViewChartPoint) {
		Object.assign(this, pricingAnalyticsKPIHistoricalViewChartPoint);

		if (this.dataPoints) {
			this.dataPoints.forEach(d => new Date(moment(d.date).format('MMM DD, YYYY 00:00:00 a')));
		}
	}
}

import { ServiceType } from './service-type';
import { State } from './state';
import { Supplier } from './supplier';
import { Utility } from './utility';

export class Market {
	public stateId: string;
	public serviceTypeId: string;
	public supplierId: string;
	public utilityId: string;
	public isResidential: boolean;

	public state: State;
	public supplier: Supplier;
	public serviceType: ServiceType;
	public utility: Utility;
}

import { Component, Input } from '@angular/core';

@Component({
	selector: 'pk-broker-page-warnings',
	templateUrl: './page-warnings.component.html',
	styleUrls: ['./page-warnings.component.scss']
})
export class PageWarningsComponent {

	@Input() warnings: string[];

}

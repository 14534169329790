import { CONSTANTS } from '@pk/powerkioskutils';

import { PayableIssueType } from './payable-issue-type';

export class PayableIssue {
	public id: number;
	public payableIssueTypeId: number;
	public name: string;
	public description: string;

	public payableIssueType?: PayableIssueType;

	get payableIssueTypeKey() {
		return this.payableIssueTypeId + (this.name ?? this.payableIssueType?.name ?? '');
	}
	get isCustomOtherTypeIssue() {
		return this.payableIssueTypeId === CONSTANTS.payableIssueTypes.other && this.name;
	}

	constructor(payableIssue?: PayableIssue) {
		Object.assign(this, payableIssue);
	}
}

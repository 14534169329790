import { ViewPendingFlowCheck } from './view-pending-flow-check';

export class PendingFlowCheck {
	public id: number;
	public supplierId: string;
	public contractId: string;
	public contractLocationId: string;
	public supplierAccountNumber: string;
	public supplierStatus: string;
	public status: number;
	public supplierDropDate: Date;
	public supplierStatusDescription: string;
	public supplierReinstatementDate: Date;
	public pendingFlowCheckStatusId: number;
	public supplierCustomerName: string;
	public errors: string;

	public viewPendingFlowCheck?: ViewPendingFlowCheck;

	constructor(pendingFlowCheck?: PendingFlowCheck) {
		Object.assign(this, pendingFlowCheck);
	}
}

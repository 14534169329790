import * as moment from 'moment-timezone';

import { RfqSessionProduct } from './rfq-session-product';
import { RfqSessionSupplier } from './rfq-session-supplier';

export class RfqSessionStat {

	public greenPercentage: number;
	public rfqSessionSupplierId: string;
	public bidCount: number;
	public bandwidthPercentage: number;
	public supplierName: string;
	public salesTax: number;
	public billingMethod: string;
	public rfqSessionId: string;
	public lastBid: number;
	public lastBidDate: Date;
	public rfqSessionProductId: string;
	public bestBid: number;
	public isGRTIncluded: string;
	public rank: number;
	public units: string;

	public rfqSessionProduct: RfqSessionProduct;
	public rfqSessionSupplier: RfqSessionSupplier;

	constructor(rfqSessionStat: RfqSessionStat) {
		Object.assign(this, rfqSessionStat);

		this.lastBidDate = this.lastBidDate ? new Date(moment(this.lastBidDate).format('MMM DD, YYYY 00:00:00 a')) : this.lastBidDate;
	}
}

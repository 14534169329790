<pk-broker-modal>
	<ng-template #headerTemplate>
		<h5 class="modal-title">Customize the Contracts Table</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<div class="container-fluid" *ngIf="state">
			<div class="row">
				<div class="col-12">
					<p>
						Here you can easily rearrange and sort the Contracts table as you see fit. You can drag and drop the columns into a desired order, and set
						up one or more sorting rules for the data.
					</p>
					<p><strong>Please Note:</strong> Status, Contract #, and DBA Name cannot be rearranged.</p>
				</div>
			</div>
			<div class="row widget-container">
				<div class="col-12 col-xl-6 left-column pr-xl-5 pb-xl-3">
					<div class="row main-column-header">
						<div class="col-12">Reorder the table columns</div>
					</div>
					<dx-list [dataSource]="stateColumns" pageLoadMode="scrollBottom" itemTemplate="columnTemplate" height="100%">
						<dxo-item-dragging
							group="contractColumns"
							[data]="stateColumns"
							[allowReordering]="true"
							[onDragStart]="onDragStart"
							[onDragEnd]="onDragEnd">
						</dxo-item-dragging>
						<div *dxTemplate="let column of 'columnTemplate'">
							<div class="row column-choice">
								<div class="col-8">{{ column.caption }}</div>
								<div class="col-3 form-threshold form-element-container" *ngIf="!column.fixed">
									<div class="custom-control custom-switch show-hide-switch" (click)="toggleVisible(column)">
										<input
											type="checkbox"
											class="custom-control-input"
											[attr.id]="column.caption"
											[attr.name]="column.caption"
											[(ngModel)]="column.columnVisible"
											[ngModelOptions]="{ standalone: true }" />
										<label class="custom-control-label" for="showHideStatus"><span></span></label>
									</div>
								</div>
							</div>
						</div>
					</dx-list>
				</div>
				<div class="col-12 col-xl-6 right-column pl-xl-4 pt-4 pt-xl-0 pb-xl-3">
					<div class="row main-column-header">
						<div class="col-12">Sort the data in the table</div>
					</div>
					<div class="row">
						<div class="col-12">
							<button type="button" class="btn btn-wide btn-secondary" (click)="addSortingLevel()">
								<fa-icon [icon]="['fas', 'plus']"></fa-icon> Add Level
							</button>
						</div>
					</div>
					<div class="row column-sort-headers">
						<div class="col-2"></div>
						<div class="col-5">Column</div>
						<div class="col-4">Order</div>
						<div class="col-1"></div>
					</div>
					<div class="row d-flex align-items-center column-sort-row" *ngFor="let sortedColumn of sortedColumns; let i = index">
						<div class="col-12 col-sm-2 text-sm-right px-sm-1 px-lg-3">{{ i === 0 ? 'Sort By' : 'Then By' }}</div>
						<!-- Column Choice Dropdown -->
						<div class="col-6 col-sm-5 px-1 px-lg-3 form-element-container">
							<pk-broker-form-dropdown
								[options]="stateColumns | filterStateColumns: sortedColumns:adminColumns"
								textKey="caption"
								(selection)="chooseSortedColumn(sortedColumn, $event)">
								<ng-template #name>{{ sortedColumn.caption || '-- Choose Column --' }}</ng-template>
							</pk-broker-form-dropdown>
						</div>
						<!-- Sorting Order Dropdown -->
						<div class="col-5 col-sm-4 px-1 px-lg-3 form-element-container">
							<pk-broker-form-dropdown
								[options]="['Ascending', 'Descending']"
								(selection)="sortColumn(sortedColumn, $event === 'Ascending' ? 'asc' : 'desc')">
								<ng-template let-option>{{ option }}</ng-template>
								<ng-template #name>{{ sortedColumn.sortOrder === 'asc' ? 'Ascending' : 'Descending' }}</ng-template>
							</pk-broker-form-dropdown>
						</div>
						<div class="col-1 px-1 px-lg-3">
							<a href="javascript:void(0)" (click)="removeSort(i)">
								<fa-icon [icon]="['fas', 'times']"></fa-icon>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="alert alert-warning mt-2" *ngIf="startValidation && errorMessage">
			{{ errorMessage }}
		</div>
	</ng-template>
</pk-broker-modal>
<div class="modal-footer justify-content-center">
	<button type="button" class="btn btn-primary" (click)="apply()" [disabled]="applying">{{ applyText }}</button>
</div>

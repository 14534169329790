import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { lastValueFrom } from 'rxjs';

import { environment } from 'src/environments/environment';
import * as models from '../shared/models';

@Injectable({
	providedIn: 'root',
})
export class AttachmentService {

	private endpoint = environment.baseUrl + '/attachments';

	constructor(private http: HttpClient) { }

	public upload(formData: FormData): Promise<models.ApiResponse<models.Attachment[]>> {
		return lastValueFrom(this.http.post<models.ApiResponse<models.Attachment[]>>(this.endpoint, formData));
	}

	public uploadCustom(fileUploadUrl: string, formData: FormData): Promise<models.ApiResponse<any>> {
		return lastValueFrom(this.http.post<models.ApiResponse<any>>(fileUploadUrl, formData));
	}
}

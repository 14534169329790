import gql from 'graphql-tag';

export const VIEW_PENDING_FLOW_CHECK_FIELDS = gql`
	fragment ViewPendingFlowCheckFields on ViewPendingFlowCheck {
		supplierId
		contractId
		contractNum
		contractLocationId
		supplierAccountNumber
		supplierStatus
		currentStatus
		status
		currentDisplayStatus
		supplierDropDate
		dropDate
		supplierStatusDescription
		supplierReinstatementDate
		reinstatementDate
		pendingFlowCheckStatusId
		pendingFlowCheckStatusName
		supplierCustomerName
		errors
		units
		effectiveDate
		expirationDate
		accountDisplayStatus
		utilityAccountNums
		supplierName
	}
`;

export const CORE_LOGGEDINUSER_FIELDS = gql`
  fragment CoreLoggedInUserFields on User {
    userId
		agentId
		roleId
		defaultRateSort
		defaultGasUnit
		defaultElectricityUnit
		isPasswordExpired
		supplierId
		phone
		fname
		middle
		lname
		isActive
		isAdmin
		isSaas
		isOwner
		isFinance
		isOperations
		companyId
		defaultUserGridConfigId
		defaultDashboardId
		showTermsOfService
		fullName
		showCompliance
		showIdDocument
		isCommissionUser
		dashboards {
			id
			name
		}
		gridConfigs {
			id
			name
			gridId
			isGlobal
			isDefault
			states
			sortOrder
		}
		emails {
			email
		}
		contactMethods {
			id
		}
		role {
			roleId
			name
			level
			permissions {
				name
			}
		}
		supplier {
			licensePlans {
				id
			}
		}
		agent {
			name
			supportEmail
			addDate
			agentId
			parentId
			brotherId
			hasFailedPayment
			paymentIntentRequiresActionDate
			showLeadLink
			showTrainingVideos
			showEducation
			useSupportSystem
			useReports
			commissionCheckMethod
			isActive
			isFraudulent
			isEnterprise
			isEmployee
			isOnHold
			isNewBroker
			isInHouseIndirectSale
			commissionRate
			commissionRate2
			residentialCommissionRate
			residentialCommissionRate2
			showUtilityCheck
			showCapSplitWarning
			commissionPayPlanTypeId
			eclPackageDiscountExpirationDate
			marketOpportunitiesAlertEffectiveDate
			residentialContractRatio
			commissionSplitTypeId
			useAgentDiscountProgram
			darkDisplayLogo: displayLogo(criteria: { defaultAgentLogoType: DARK, defaultCompanyLogoType: EMAIL }) {
				attachmentId
			}
			lightDisplayLogo: displayLogo(criteria: { defaultAgentLogoType: LIGHT, defaultCompanyLogoType: EMAIL }) {
				attachmentId
			}
			customerCards {
				id
			}
			eclStates {
				stateId
				stateLong
			}
			eclAnnualUsageBrackets {
				id
				name
				minUsage
				maxUsage
				sortOrder
			}
			eclLoadFactorBrackets {
				id
				name
				minLoadFactor
				maxLoadFactor
				sortOrder
			}
			eclPackages {
				addDate
			}
			licensePlans {
				id
				name
				years
				parents {
					id
				}
			}
			subAgents(sort: "name") {
				agentId
				name
				showLeadLink
				isNewBroker
			}
			parent {
				agentId
				name
				showTrainingVideos
				showEducation
				customerCompany
				useSupportSystem
				useReports
				supportEmail
				isNewBroker
				isEnterprise
				commissionSplitTypeId
				useAgentDiscountProgram
				logins {
					fname
					middle
					lname
					isPrimary
					addDate
					emails {
						email
					}
				}
				themes {
					agentThemeCategoryId
					primary
					secondary
					tertiary1
					tertiary2
					tertiary3
					logoAttachmentId
					useParentNameAsCompanyNameForProposal
				}
			}
			logins {
				fname
				middle
				lname
				isPrimary
				addDate
				emails {
					email
				}
			}
			themes {
				agentThemeCategoryId
				primary
				secondary
				tertiary1
				tertiary2
				tertiary3
				logoAttachmentId
				useParentNameAsCompanyNameForProposal
			}
			bankAccounts {
				id
				isInvalid
			}
		}
		company {
			isDecoupled
			isSaas
			houseAccount {
				agentId
				name
			}
			licensePlans {
				id
			}
		}
		surveySettings {
			id
			userSurveyCategoryId
			show
		}
  }
`;

export const CORE_QUOTE_PAGE_AGENT_FIELDS = gql`
	fragment CoreQuotePageAgentFields on Agent {
		agentId
		name
		commissionRate
		commissionRate2
		residentialCommissionRate
		residentialCommissionRate2
		showUtilityCheck
		showCapSplitWarning
		commissionPayPlanId
		isNewBroker
		company {
			isSaas
			isDecoupled
			licensePlans {
				id
			}
			houseAccount {
				agentId
				name
			}
		}
		parent {
			commissionSplitTypeId
		}
		logins {
			userId
			fname
			middle
			lname
			isPrimary
			addDate
			emails {
				email
			}
		}
	}
`;

export const CORE_QUOTE_PAGE_CONTRACT_FIELDS = gql`
	fragment CoreQuotePageContractFields on Contract {
		contractId
		contractNum
		rate
		term
		margin
		bufferMargin
		effectiveDate
		expirationDate
		annualUsage
		serviceTypeId
		awardDate
		uploadDate
		supplierId
		stateId
		previousRate
		agentId
		rfqSessionId
		customerId
		status
		confirmationDate
		switchTypeCode
		loadFactor
		networkLoadFactor
		customLoadFactor
		hasUsage
		plc
		networkPlc
		moveInType
		isNewBrokerAuction
		sentAutomatedRenewalEmailDate
		commissionPayPlanId
		isRenewal
		baseRateOverride
		dwellingType
		previousContract {
			supplierId
			term
		}
	}
`;

export const CORE_QUOTE_PAGE_CUSTOMER_FIELDS = gql`
	fragment CoreQuotePageCustomerFields on Customer {
		customerId
		dbaName
		contactFname
		contactMiddle
		contactLname
		contactTitle
		email
		address1
		address2
		zipCode
		stateId
		city
		phone
		phone2
		fax
		mobile
		taxId
		dob
		language
		ssn
		phoneCountryId
		phone2CountryId
		mobileCountryId
		faxCountryId
		billingAddress1
		billingAddress2
		billingZipCode
		billingCity
		billingStateId
		customerAvailabilityId
		billingState {
			stateShort
			stateLong
			countryId
		}
		state {
			stateShort
			stateLong
			countryId
			country {
				symbol
				isoCode
				symbol2
			}
		}
		emailPreferences {
			slug
		}
		customerAvailability {
			mondayFrom
			mondayTo
			tuesdayFrom
			tuesdayTo
			wednesdayFrom
			wednesdayTo
			thursdayFrom
			thursdayTo
			fridayFrom
			fridayTo
			timezone {
				id
				name
				abbreviation
			}
		}
	}
`;

export const CORE_CONTRACT_EDIT_AGENT = gql`
	fragment CoreContractEditAgentFields on Agent {
		agentId
		parentId
		name
		discountFactor
		isNewBroker
		parent {
			discountFactor
			commissionSplitTypeId
		}
		promoCodes(sort: "isActive desc, slug") {
			id
			isActive
			slug
		}
		logins {
			userId
			fname
			middle
			lname
			isPrimary
			addDate
			emails {
				email
			}
		}
	}
`;

export const CORE_VIEW_LEAD_FIELDS = gql`
	fragment CoreViewLeadFields on ViewLead {
		id
		dbaName
		address1
		address2
		city
		stateId
		stateLong
		stateShort
		zipCode
		priority
		tierLevel
		website
		profileDescription
		leadStatusId
		leadStatusName
		leadStatusReasonTypeId
		leadStatusReasonTypeName
		leadStatusReasonNote
		leadStatusCategoryId
		leadBusinessTypeId
		leadBusinessTypeName
		linkedInUrl
		cssClass
		assignedToAgentId
		assignedToAgentName
		contactFname
		contactLname
		contactMobile
		contactMobileCountryId
		contactEmail
		reminderCount
		reminderFutureCount
		reminderPastCount
		agentId
		agentName
		amtHasDuplicate
		amtIsDuplicate
		addDate
		isIceBoxed
		leadCategoryId
		leadCategoryName
		leadSource
	}
`;

export const CORE_VIEW_AGENT_REFERRAL_SERVICE_FIELDS = gql`
	fragment CoreViewAgentReferralServiceFields on ViewAgentReferralService {
		id
		serviceId
		serviceName
		serviceStates
		agentReferralServiceProfileId
		dbaName
		title
		summary
		description
		imageIconAttachmentId
	}
`;

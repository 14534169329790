<div #alert class="row" *ngIf="notificationText && !hide && !loading">
	<div class="col">
		<div
			class="alert alert-notification fade"
			[ngClass]="{
				show: show,
				'alert-width-limit': isSmall,
				'alert-dismissible': !hideCloseButton,
				'red-alert': hasDroppedAccounts
			}"
			role="alert">
			<div class="row d-flex align-items-center justify-content-center alert-layout">
				<div class="col-auto alert-icon">
					<fa-icon [icon]="['fas', alertIcon]" [classes]="[iconClass]"></fa-icon>
				</div>
				<div class="col pl-0 alert-content">
					<p class="header" [ngClass]="headerClass" *ngIf="header">{{ header }}</p>
					<p [innerHtml]="notificationText"></p>
					<div class="row mt-2">
						<div class="col-auto form-element-container" *ngIf="hasDroppedAccounts">
							<button class="btn btn-primary btn-sm" type="button" (click)="reviewAndSendReinstatement()" [disabled]="submitDisabled">
								{{ reinstatementText }}
							</button>
						</div>
						<div class="col-auto form-element-container" *ngIf="hasDroppedAccounts && contract.isReinstatementSent">
							<button type="button" (click)="reinstatementDownloadForm()" [disabled]="esignDocumentDownloading" class="btn btn-link btn-sm">
								{{ esignDocumentDownloading ? 'Downloading' : 'Download Form' }}
							</button>
						</div>
					</div>
					<div class="form-element-container mt-3" *ngIf="showAutomatedRenewal && !isEnterpiseAgent">
						<form [formGroup]="formGroup">
							<label>Set Your Margin</label>
							<div class="input-group">
								<div class="dropdown flex-fill pseudo-form-control">
									<pk-broker-form-dropdown formControlName="margin" valueKey="value" textKey="label" [options]="marginAdjustments">
										<ng-template #name>
											<ng-container *ngIf="marginAdjustments?.length">
												{{ margin | marginDisplay: contract.serviceType:serviceTypeUnit.units:serviceTypeUnit.marginUnits }}
												{{
													serviceTypeUnit.marginUnits === 'mils'
														? serviceTypeUnit.marginUnits
														: serviceTypeUnit.marginUnits === 'dollars'
														? contract.state.country.symbol
														: contract.state.country.symbol2
												}}/{{ serviceTypeUnit.units }}
											</ng-container>
											<ng-container *ngIf="!marginAdjustments?.length">--</ng-container>
										</ng-template>
										<ng-template #row let-option>
											{{ option.value | marginDisplay: contract.serviceType:serviceTypeUnit.units:serviceTypeUnit.marginUnits }}
											{{
												serviceTypeUnit.marginUnits === 'mils'
													? serviceTypeUnit.marginUnits
													: serviceTypeUnit.marginUnits === 'dollars'
													? contract.state.country.symbol
													: contract.state.country.symbol2
											}}/{{ serviceTypeUnit.units }}
										</ng-template>
									</pk-broker-form-dropdown>
								</div>
								<div class="input-group-append">
									<button class="btn btn-primary" type="button" (click)="submit()" [disabled]="submitDisabled">{{ submitText }}</button>
								</div>
							</div>
						</form>
					</div>
					<p [innerHtml]="helperLink" *ngIf="helperLink"></p>
				</div>
				<a type="button" class="close" aria-label="Close" (click)="hideAlert()" *ngIf="!hideCloseButton">
					<fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
				</a>
			</div>
		</div>
	</div>
</div>

export class RfqSessionOptOutReasonType {

	public id: number;
	public name: string;
	public adddate: Date;

	constructor(rfqSessionOptOutReasonType?: RfqSessionOptOutReasonType) {
		Object.assign(this, rfqSessionOptOutReasonType);
	}
}

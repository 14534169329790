import { ContractLocation } from './contract-location';

export class Invoice {

	public id: string;
	public isPaid: boolean;
	public amount: number;
	public contractLocationId: string;

	public location: ContractLocation;

	public sentDate: Date;
	public invoiceNum: string;
	public rate: number;
	public invoiceStatusId: number;
	public companySupplierId: string;
	public countryId: number;
	public serviceTypeId: string;
	public brokerId: string;
	public claimReferenceNumber: string;

	constructor(invoice?: any) {
		Object.assign(this, invoice);

		this.location = this.location ? new ContractLocation(this.location) : this.location;
	}
}

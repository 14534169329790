export class ReportEmailCriteriaLog {

	public attachmentId: string;
	public attachmentName: string;
	public criteriaAttachment: string;
	public criteriaFrom: string;
	public criteriaName: string;
	public criteriaSubject: string;
	public dateSent: string;
	public emailAttachmentId: string;
	public emailCriteriaId: string;
	public emailCriteriaFrom: string;
	public emailId: string;
	public emailSubject: string;
	public isProcessed: boolean;

	constructor(emailCriteriaLog?: ReportEmailCriteriaLog) {
		Object.assign(this, emailCriteriaLog);
	}
}

import * as moment from 'moment-timezone';

export class LeadEmailCampaignSchedule {
	public id: number;
	public leadEmailCampaignId: number;
	public emailPreferenceId: number;
	public date: Date;

	constructor(leadEmailCampaignSchedule?: Partial<LeadEmailCampaignSchedule>) {
		Object.assign(this, leadEmailCampaignSchedule);

		this.date = this.date
			? new Date(moment(this.date).format('MMM DD, YYYY 00:00:00 a')) : this.date;
	}
}

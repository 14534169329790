export class RateBar {

	public minBuffer: number;
	public maxBuffer: number;
	public overallMinRate: number;
	public overallMaxRate: number;
	public bestRate: number;
	public serviceTypeId: string;
	public barPercentage: string;
	public barText: string;

	constructor(rateBar?: RateBar) {
		Object.assign(this, rateBar);
		this.setBarPercentage();
		this.setBarText();
	}

	private setBarPercentage(): void {
		// Formula to calculate percentage between ranges taken from https://stackoverflow.com/questions/25835591/
		// percentage = ((input - min) * 100) / (max - min)

		const rangeDifference = ((this.bestRate - this.overallMinRate) * 100) / (this.overallMaxRate - this.overallMinRate);

		if (rangeDifference < 11) {
			this.barPercentage = '11%';
		} else if (rangeDifference > 87) {
			this.barPercentage = '87%';
		} else {
			this.barPercentage = `${rangeDifference}%`;
		}

		this.minBuffer = ((2 / 12) * (this.overallMaxRate - this.overallMinRate)) + this.overallMinRate;
		this.maxBuffer = ((10 / 12) * (this.overallMaxRate - this.overallMinRate)) + this.overallMinRate;
	}

	private setBarText(): void {
		if (this.bestRate < this.minBuffer) {
			this.barText = 'Low';
		} else if (this.bestRate > this.maxBuffer) {
			this.barText = 'High';
		} else {
			this.barText = 'Typical';
		}
	}
}

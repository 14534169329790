import * as moment from 'moment-timezone';

export class NightlyProcessLog {

	public id: number;
	public stepName: string;
	public startDate: Date | string;
	public endDate: Date | string;
	public duration: number;

	constructor(nightlyProcessLog?: NightlyProcessLog) {
		Object.assign(this, nightlyProcessLog);
	}
}

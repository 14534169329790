import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CONSTANTS, NumberUtils, RateUtils, StringUtils } from '@pk/powerkioskutils';

import { LoadOptions } from 'devextreme/data';
import DxDataGrid from 'devextreme/ui/data_grid';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { Observable, Subject, switchMap, throttleTime } from 'rxjs';

import { UsageVerificationType } from 'src/app/shared/models/usage-verification-type.enum';
import { environment } from '../../environments/environment';
import { GraphqlService } from '../graphql/graphql.service';
import { SecurityService } from '../security/security.service';
import { Contract, ContractMarketPerformance, Country, Customer, ReportCommissionProcessingIssuesRow, UnpaidPayable, User, ViewContractLogChanges, ViewPayableForecastDashboard } from './models';
import { Rate } from './models/rate';
import { WINDOW } from './models/window';
import { ServiceLocator } from './service-locator.service';

declare global {
	interface Window {
		opera: any;
	}

	/**
	 * Similar to `Partial<K>`, but object properties are also marked as `Partial`.
	 */
	type Subset<K> = {
		[attr in keyof K]?: K[attr] extends object ? Subset<K[attr]> : K[attr];
	};
}

@Injectable({
	providedIn: 'root',
})
export class HelperService {

	/* eslint-disable */
	public static phoneValidatorMap: { [key: number]: RegExp } = {
		[CONSTANTS.countries.unitedStates]: CONSTANTS.validators.phoneUS,
		[CONSTANTS.countries.canada]: CONSTANTS.validators.phoneUS,
		[CONSTANTS.countries.unitedKingdom]: CONSTANTS.validators.phoneGB,
		[CONSTANTS.countries.australia]: CONSTANTS.validators.phoneAU,
		[CONSTANTS.countries.singapore]: CONSTANTS.validators.phoneSG,
	};

	public static phoneMasksMap: { [key: number]: (string | RegExp)[] } = {
		[CONSTANTS.countries.unitedStates]: CONSTANTS.masks.phoneUS,
		[CONSTANTS.countries.canada]: CONSTANTS.masks.phoneUS,
		[CONSTANTS.countries.unitedKingdom]: CONSTANTS.masks.phoneGB,
		[CONSTANTS.countries.australia]: CONSTANTS.masks.phoneAU,
		[CONSTANTS.countries.singapore]: CONSTANTS.masks.phoneSG,
	};
	/* eslint-enable */

	public static rateSortTypes = [
		CONSTANTS.sort.ascDefault,
		CONSTANTS.sort.ascDisplayRate,
		CONSTANTS.sort.descResidualCommission,
		CONSTANTS.sort.descSavings,
		CONSTANTS.sort.descTerm,
		CONSTANTS.sort.ascTerm,
	];

	public static popularTerms = [6, 12, 18, 24, 36, 48, 60];

	public static ticketPriorities = [
		{ id: 1, name: 'Low' },
		{ id: 2, name: 'Medium' },
		{ id: 3, name: 'High' },
		{ id: 4, name: 'Highest' },
		{ id: 5, name: 'Blocker' },
	];
	public static ticketStatuses = [
		{ id: 1, name: 'To Do' },
		{ id: 2, name: 'In Progress' },
		{ id: 3, name: 'Waiting on Reporter' },
		{ id: 4, name: 'New Response' },
		{ id: 5, name: 'Complete' },
	];
	public static ticketStatusesBroker = [
		{ id: 1, name: 'Submitted' },
		{ id: 2, name: 'In Progress' },
		{ id: 3, name: 'New Response' },
		{ id: 5, name: 'Complete' },
	];

	constructor(
		@Inject(WINDOW) private window: Window,
		private securityService: SecurityService,
		private graphqlService: GraphqlService,
	) { }

	// http://detectmobilebrowsers.com
	public static isMobile(): boolean {
		let check = false;
		((a: string) => {
			// eslint-disable-next-line max-len
			if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
				check = true;
			}
		})(navigator.userAgent || navigator.vendor || window.opera);
		return check;
	}

	public static isPossibleTest(input: { [key: string]: any }): boolean {
		if (environment.name !== 'production') { return false; }

		return Object.keys(input)
			.some(key =>
				typeof input[key] === 'string' &&
				this.matches(input[key].trim(), /t+[^a-z]*e+[^a-z]*s+[^a-z]*t+/gi, false),
			);
	}

	public static matches(value: string, regExp: string | RegExp, isExact = true): boolean {
		const regExpMatchArray = value ? value.match(regExp) : null;
		return !!regExpMatchArray && !!regExpMatchArray.length && (!isExact || regExpMatchArray[0] === value);
	}

	public static serialize(obj: any): URLSearchParams {
		const params: URLSearchParams = new URLSearchParams();

		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				const element = obj[key];
				if (element !== undefined && element !== 'undefined') {
					params.set(key, element);
				}
			}
		}
		return params;
	}

	public static convertTwentyFourHourToTwelveHour(time: number): string {
		if (!time) {
			return '12:00 AM';
		} else if (time < 12) {
			return time + ':00 AM';
		} else if (time === 12) {
			return '12:00 PM';
		} else {
			return (time - 12) + ':00 PM';
		}
	}

	public static getWeekdayAvailability(dayOfWeek: number, customer: Customer, abbreviation = 'CT'): string {
		const availability = customer?.customerAvailability;
		if (!availability) { return 'Not Available'; }
		switch (dayOfWeek) {
			case 0:
				return availability.mondayFrom && availability.mondayTo ?
					this.convertTwentyFourHourToTwelveHour(availability.mondayFrom)
					+ ' ' + abbreviation + ' - '
					+ this.convertTwentyFourHourToTwelveHour(availability.mondayTo)
					+ ' ' + abbreviation :
					'Not Available';
			case 1:
				return availability.tuesdayFrom && availability.tuesdayTo ?
					this.convertTwentyFourHourToTwelveHour(availability.tuesdayFrom)
					+ ' ' + abbreviation + ' - '
					+ this.convertTwentyFourHourToTwelveHour(availability.tuesdayTo)
					+ ' ' + abbreviation :
					'Not Available';
			case 2:
				return availability.wednesdayFrom && availability.wednesdayTo ?
					this.convertTwentyFourHourToTwelveHour(availability.wednesdayFrom)
					+ ' ' + abbreviation + ' - '
					+ this.convertTwentyFourHourToTwelveHour(availability.wednesdayTo)
					+ ' ' + abbreviation :
					'Not Available';
			case 3:
				return availability.thursdayFrom && availability.thursdayTo ?
					this.convertTwentyFourHourToTwelveHour(availability.thursdayFrom)
					+ ' ' + abbreviation + ' - '
					+ this.convertTwentyFourHourToTwelveHour(availability.thursdayTo)
					+ ' ' + abbreviation :
					'Not Available';
			case 4:
				return availability.fridayFrom && availability.fridayTo ?
					this.convertTwentyFourHourToTwelveHour(availability.fridayFrom)
					+ ' ' + abbreviation + ' - '
					+ this.convertTwentyFourHourToTwelveHour(availability.fridayTo)
					+ ' ' + abbreviation :
					'Not Available';
			default:
				return 'Not Available';
		}
	}

	public static calculateTimezoneOffset(fromId: number, toId: number, timezones: any[]): number {
		const from = timezones.filter(z => z.id === fromId)[0]?.name;
		const to = timezones.filter(z => z.id === toId)[0]?.name;
		if (!from || !to) {
			return 0;
		}
		const fromUtc = Math.abs(moment.tz(from).utcOffset());
		const toUtc = Math.abs(moment.tz(to).utcOffset());
		return (fromUtc - toUtc) / 60;
	}

	public async getCalendlyOptions() {
		const companyId = this.securityService.authFields.loggedInUser.companyId || CONSTANTS.companies.powerKiosk;
		const result = await this.graphqlService.getCompanyCalendlySettingsData(companyId);
		return result.data.company.calendlySettings;
	};

	public static getProductMinBidAmount(productId: string, serviceTypeId: string, units: string): string {
		const factor = CONSTANTS.units[serviceTypeId]?.find(u => u.text === units)?.factor || 1;

		let minBid: number;

		switch (productId) {
			case CONSTANTS.products.fixedAllInElectric:
			case CONSTANTS.products.energyOnly:
			case CONSTANTS.products.capacityUnbundled:
			case CONSTANTS.products.congestionPassThrough:
			case CONSTANTS.products.block: {
				minBid = 0.02;
				break;
			}
			case CONSTANTS.products.indexAdderOnly:
			case CONSTANTS.products.indexPlus: {
				minBid = 0.0001;
				break;
			}
			case CONSTANTS.products.indexPlusExcludesCapacityEnergyTrans: {
				minBid = 0.0008;
				break;
			}
			case CONSTANTS.products.fixedAllInGas: {
				if (units.includes('kWh')) { // UK units
					minBid = 0.00198;
				} else {
					minBid = 0.1;
				}
				break;
			}
			case CONSTANTS.products.nymexPlus: {
				if (units.includes('kWh')) { // UK units
					minBid = 0.0;
				} else {
					minBid = 0.0;
				}
				break;
				break;
			}
			case CONSTANTS.products.indexAdderLocalHub: {
				minBid = 0.001;
				break;
			}
			case CONSTANTS.products.nymexAdder: {
				minBid = -0.4;
				break;
			}
			default: {
				minBid = 0.02;
				break;
			}
		}

		const decimalPipe = ServiceLocator.injector.get(DecimalPipe);
		return decimalPipe.transform(minBid * factor, '1.0-3');
	}

	public static getProductMaxBidAmount(productId: string, serviceTypeId: string, stateId: string, units: string): string {
		const factor = CONSTANTS.units[serviceTypeId]?.find(u => u.text === units)?.factor || 1;

		let maxBid: number;

		switch (productId) {
			case CONSTANTS.products.fixedAllInElectric:
			case CONSTANTS.products.energyOnly:
			case CONSTANTS.products.capacityUnbundled:
			case CONSTANTS.products.congestionPassThrough:
			case CONSTANTS.products.block: {
				maxBid = 0.3;
				break;
			}
			case CONSTANTS.products.indexAdderOnly:
			case CONSTANTS.products.indexPlus: {
				maxBid = 0.08;
				break;
			}
			case CONSTANTS.products.fixedAllInGas:
			case CONSTANTS.products.nymexPlus: {
				if (units.includes('kWh')) { // UK units
					maxBid = 0.3;
				} else {
					maxBid = 2.5;
				}
				break;
			}
			case CONSTANTS.products.indexAdderLocalHub: {
				maxBid = 0.5;
				break;
			}
			case CONSTANTS.products.nymexAdder: {
				maxBid = 0.5;
				if (stateId === CONSTANTS.states.newHampshire) {
					maxBid = 0.65;
				}
				break;
			}
			default: {
				maxBid = 0.3;
				break;
			}
		}

		const decimalPipe = ServiceLocator.injector.get(DecimalPipe);
		return decimalPipe.transform(maxBid * factor, '1.0-3');
	}

	public static getErrorMessage(res: any): string[] {
		if (res) {
			if (res.graphQLErrors?.length) {
				return this.sanitizeErrorMessage(res.graphQLErrors[0].message);
			} else if (res.networkError?.body?.errors) {
				return this.sanitizeErrorMessage(res.networkError.body.errors.map(e => e.message).join('|'));
			} else if (res.networkError?.message) {
				return this.sanitizeErrorMessage(res.networkError.message);
			} else if (res.error?.message) {
				if (res.error.message?.response) {
					return this.sanitizeErrorMessage(res.error.message.response);
				} else {
					return this.sanitizeErrorMessage(typeof res.error.message === 'string'
						? res.error.message
						: JSON.stringify(res.error.message));
				}
			} else if (res.message) {
				return this.sanitizeErrorMessage(res.message);
			}
		} else {
			return ['Unknown Error'];
		}
	}

	public static sanitizeErrorMessage(message: string): string[] {
		return message.includes('Unexpected token < in JSON')
			? ['The request failed. We have been notified and are working to fix the issue. Please check back again in 30 minutes.']
			: message
				.replace('Unexpected error value: ', '')
				.replace('Network error: Http failure response for (unknown url): 0 Unknown Error', 'Service Timeout')
				.replace(/\"/g, '')
				.replace('{ error: ', '')
				.replace('. }', '.')
				.split('|');
	}

	public static toFriendlyRate(rate: any): Rate {
		const friendlyRate = rate;
		friendlyRate.matrixPrice = rate.matrixPrice === undefined ? true : rate.matrixPrice;
		friendlyRate.displayRateRaw = RateUtils.getDisplayRateRaw(friendlyRate.displayRate);

		const outputRate = _.extend(friendlyRate, friendlyRate.extra ? JSON.parse(friendlyRate.extra) : {});

		outputRate.productDescription = outputRate.productDescription ? outputRate.productDescription : undefined;
		outputRate.productHeader = outputRate.productHeader ? outputRate.productHeader : undefined;
		outputRate.tdspKwhCharge = outputRate.tdspKwhCharge ? Number(outputRate.tdspKwhCharge) * 100 : undefined;
		outputRate.tdspBaseCharge = outputRate.tdspBaseCharge ? Number(outputRate.tdspBaseCharge).toFixed(2) : undefined;
		outputRate.baseFee1Dollars = outputRate.baseFee1Dollars ? Number(outputRate.baseFee1Dollars).toFixed(2) : undefined;
		outputRate.energyRate = outputRate.energyRate ? Number(outputRate.energyRate) * 100 : undefined;

		outputRate.supplierName = outputRate.name;
		if (!outputRate.planName) {
			outputRate.planName = outputRate.termName;
			if (!outputRate.planName) {
				outputRate.planName = outputRate.term + ' months';
			}
		}

		return outputRate;
	}

	public static customEvent(event: string, params?: any) {
		params = params || { bubbles: false, cancelable: false, detail: null };
		const evt = document.createEvent('CustomEvent');
		evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
		return evt;
	}

	public static getPath(path: string, withToken = false): string {
		const token = withToken ? '?access_token=' + environment.apiKey : '';
		return !path.includes('http')
			? environment.baseUrl + path + token
			: path;
	}

	public static isSameDate(date: Date, today: Date): boolean {
		return date.getDate() === today.getDate()
			&& date.getMonth() === today.getMonth()
			&& date.getFullYear() === today.getFullYear();
	}

	// is a tomorrow to b
	public static isTomorrow(a: Date, b: Date): boolean {
		const date1 = new Date(a);
		const date2 = new Date(b);

		// same month and year
		if (date1.getMonth() === date2.getMonth() &&
			date1.getFullYear() === date2.getFullYear()) {
			if (date2.getDay() === 5) {
				date2.setDate(date2.getDate() + 2); // make it Sunday to check as business day
				return this.isTomorrow(date1, date2);
			}

			return date1.getDate() - date2.getDate() === 1;
		}

		// everything else, if we add one day it will
		// account for month difference and year difference
		date1.setDate(date1.getDate() + 1);
		date2.setDate(date2.getDate() + 1);
		if (date1.getMonth() === date2.getMonth() &&
			date1.getFullYear() === date2.getFullYear()) {
			if (date2.getDay() === 5) {
				date2.setDate(date2.getDate() + 2); // make it Sunday to check as business day
				return this.isTomorrow(date1, date2);
			}

			return date1.getDate() - date2.getDate() === 1;
		}
		return false;
	}

	public static getSupplierNote(serviceTypeId: string, stateId: string, supplierId: string): string {
		if (CONSTANTS.states.texas === stateId && [CONSTANTS.suppliers.sparkEnergy, CONSTANTS.suppliers.verdeEnergy,
		CONSTANTS.suppliers.providerPower, CONSTANTS.suppliers.majorEnergy].includes(supplierId)) {
			return 'This supplier does not accept Priority Move In customers. If you need power ' +
				'immediately, please select another supplier.';
		}

		if (CONSTANTS.states.illinois === stateId && CONSTANTS.serviceTypes.electricity.serviceTypeId === serviceTypeId &&
			[CONSTANTS.suppliers.publicPower, CONSTANTS.suppliers.usge].includes(supplierId)) {
			return 'Any account with an annual usage of less than 15,000 kWh may NOT be included in this supplier\'s contract.';
		}

		if (supplierId === CONSTANTS.suppliers.frontierUtilities) {
			return 'DISCLAIMER: Your actual price for electricity service may be different depending on how ' +
				'and when you use electricity. See the Electricity Facts Label for additional pricing information.';
		}

		return '';
	}

	public static generateDateSelections(dates: Date[]): any[] {
		return _.chain(dates)
			.groupBy(e => e.getFullYear())
			.toPairs()
			.map(g => ({
				key: g[0],
				text: g[0],
				value: g[0],
				items: g[1].map(d => ({
					key: moment.utc(d).format('MMM'),
					text: moment.utc(d).format('MMM'),
					value: `${g[1][0].getFullYear()}/${d.getMonth() + 1}`,
				})),
			}))
			.value();
	}

	// add any additional CMS-type users here, like Mike Grimes who just needs to edit
	// and maintain certain contracts that he deals with outside of our system flow
	public static isCMSUserForContract(user: User, contract: Contract): boolean {
		// admin can always view
		if (user.isAdmin || user.isSaas) { return true; }

		// brokers can only view if it's their contract or their sub agent's contract.
		if (contract.agentId !== user.agentId && contract.agent.parentId !== user.agentId) { return false; }

		if (user.agentId === CONSTANTS.agents.mikeGrimes) {
			return [
				CONSTANTS.suppliers.santannaEnergy,
				CONSTANTS.suppliers.realgyEnergy,
				CONSTANTS.suppliers.vanguardEnergy,
				CONSTANTS.suppliers.nordicEnergy,
			].includes(contract.supplierId) ||
				[
					CONSTANTS.states.missouri,
				].includes(contract.stateId);
		}

		return false;
	}

	public static isMikeGrimes(contract: Contract): boolean {
		if (contract.agentId === CONSTANTS.agents.mikeGrimes) {
			return [
				CONSTANTS.suppliers.santannaEnergy,
				CONSTANTS.suppliers.realgyEnergy,
				CONSTANTS.suppliers.vanguardEnergy,
				CONSTANTS.suppliers.nordicEnergy,
			].includes(contract.supplierId) ||
				[
					CONSTANTS.states.missouri,
				].includes(contract.stateId);
		}
	}

	public static isFileSize(files: File[] | File | string, maxMBSize: number): boolean {
		switch (files) {
			case 'null':
				files = null;
				break;
			case 'undefined':
				files = undefined;
				break;
		}

		if (typeof files === 'string') { return false; }
		if (!!files && (Array.isArray(files) ? files : [files]).some(f => NumberUtils.bytesToMegabytes(f.size) > maxMBSize)) {
			return false;
		}
		return true;
	}

	public static isFileType(files: File[] | string, expectedTypes: string): boolean {
		switch (files) {
			case 'null':
				files = null;
				break;
			case 'undefined':
				files = undefined;
				break;
		}

		if (!!files && (Array.isArray(files) ? files : [files]).some(f => !expectedTypes.split(',').includes(f.name.split('.').pop()))) {
			return false;
		}
		return true;
	}

	public static showAsteriskForBrokerFirstImpression(isNewBroker: boolean): boolean {
		const securityService = ServiceLocator.injector.get(SecurityService);
		return securityService.authFields?.loggedInUser?.isAdmin && isNewBroker;
	}

	public static showAsteriskForContractFirstImpression(contractStatus: number, isNewBrokerAuction: boolean): boolean {
		const securityService = ServiceLocator.injector.get(SecurityService);
		return securityService.authFields?.loggedInUser?.isAdmin && contractStatus === CONSTANTS.statuses.ainp && isNewBrokerAuction;
	}

	public static getEnergyIcon(serviceTypeId: string): string {
		if (serviceTypeId === CONSTANTS.serviceTypes.electricity.serviceTypeId) {
			return 'https://assets.powerkiosk.com/includes/images/electricity-icon.svg';
		} else if (serviceTypeId === CONSTANTS.serviceTypes.naturalGas.serviceTypeId) {
			return 'https://assets.powerkiosk.com/includes/images/gas-icon.svg';
		} else if (serviceTypeId === CONSTANTS.serviceTypes.communitySolar.serviceTypeId) {
			return 'https://assets.powerkiosk.com/includes/images/sun-icon.svg';
		}

		return 'https://assets.powerkiosk.com/includes/images/electricity-icon.svg';
	}

	public static getUniqueId(length = 16) {
		return Math.ceil(Math.random() * Date.now()).toPrecision(length).replace('.', '');
	}

	public static padShortZipCode(zipCode: string): string {
		if (zipCode?.length < 5 && zipCode?.length > 1) {
			return _.padStart(zipCode, 5, '0');
		}

		return zipCode;
	}

	// https://stackoverflow.com/a/10474055/924501
	public static getTotalMargin(
		input: Contract | ViewContractLogChanges,
		includeBufferMargin = true,
		includeParentBufferMargin = false,
		includeMatrixAgentBufferMargin = false,
		unit?: string
	): number {
		let totalMargin = input.margin;

		if (includeParentBufferMargin && input.commissionSplitBufferMargin) {
			totalMargin = Math.round((totalMargin + input.commissionSplitBufferMargin) * 1e12) / 1e12;
		}

		if (includeBufferMargin && input.bufferMargin) {
			totalMargin = Math.round((totalMargin + input.bufferMargin) * 1e12) / 1e12;
		}

		if (includeMatrixAgentBufferMargin && input.matrixAgentBufferMargin) {
			totalMargin = Math.round((totalMargin + input.matrixAgentBufferMargin) * 1e12) / 1e12;
		}

		totalMargin = NumberUtils.rateToUnits(totalMargin, unit);

		return totalMargin;
	}

	public static getTotalMarginFormatted(contract: Contract,
		includeBufferMargin = true, includeParentBufferMargin = false, includeMatrixAgentBufferMargin = false,
		marginUnits?: string, unit?: string): string {
		const totalMargin
			= this.getTotalMargin(contract, includeBufferMargin, includeParentBufferMargin, includeMatrixAgentBufferMargin, unit);

		const currencyPipe = ServiceLocator.injector.get(CurrencyPipe);
		return `${currencyPipe.transform(totalMargin, 'USD', '', '1.2-5')} ${marginUnits} / ${unit}`;
	}

	public static getCommissionSplitBufferMargin(contract: Contract, unit?: string): number {
		return NumberUtils.rateToUnits(contract.commissionSplitBufferMargin, unit);
	}

	public static getUsageVerification(input: UnpaidPayable | ViewPayableForecastDashboard | ReportCommissionProcessingIssuesRow): {
		accountUsage: {
			label: string;
			text: string;
			iconPrefix: string;
			icon: string;
			classes: string;
		};
		receivableUsage: {
			label: string;
			text: string;
			iconPrefix: string;
			icon: string;
			classes: string;
		};
	} {
		const decimalPipe = ServiceLocator.injector.get(DecimalPipe);

		// can view rule defintions and logic in getUsageVerification.sql table function
		switch (input.ruleId) {
			case 1:
				if (input.receivableUsageCheckId === UsageVerificationType.NOT_VERIFIED) {
					return {
						accountUsage: null,
						receivableUsage: {
							label: 'Actual Receivable Residual Mismatch',
							text: input.receivableUsage
								? `Actual Receivable Residual Mismatch: `
								+ `${decimalPipe.transform(input.receivableUsage, '1.0-0')} ${input.units}`
								: 'Actual Receivable Residual Missing',
							iconPrefix: 'fas',
							icon: 'circle-exclamation',
							classes: 'font-size-125 red-text',
						},
					};
				}
				break;
			case 2:
				const usageToCompare = input.accountUsage ?? (input.confirmedAnnualUsage / 12);
				if (input.ruleTierId === 1 && input.accountUsageCheckId === UsageVerificationType.NOT_VERIFIED) {
					return {
						accountUsage: {
							label: 'Confirmed Annual Usage Mismatch',
							text: `${decimalPipe.transform(usageToCompare, '1.0-0')} ${input.units}`,
							iconPrefix: 'fas',
							icon: 'circle-exclamation',
							classes: 'font-size-125 red-text',
						},
						receivableUsage: null,
					};
				} else if (input.ruleTierId === 2 && input.receivableUsageCheckId === UsageVerificationType.NOT_VERIFIED) {
					return {
						accountUsage: null,
						receivableUsage: {
							label: 'Confirmed Residual Mismatch',
							text: `Confirmed Residual Mismatch: ${decimalPipe.transform(usageToCompare, '1.0-0')} ${input.units}`,
							iconPrefix: 'fas',
							icon: 'circle-exclamation',
							classes: 'font-size-125 red-text',
						},
					};
				}
				break;
			case 3:
				if (input.receivableUsageCheckId === UsageVerificationType.NOT_VERIFIED) {
					return {
						accountUsage: null,
						receivableUsage: {
							label: 'Confirmed Annual Usage Mismatch',
							text: input.confirmedAnnualUsage
								? `Confirmed Annual Usage Mismatch: `
								+ `${decimalPipe.transform(input.confirmedAnnualUsage, '1.0-0')} ${input.units}`
								: 'Confirmed Annual Usage Missing',
							iconPrefix: 'fas',
							icon: 'circle-exclamation',
							classes: 'font-size-125 red-text',
						},
					};
				}
				break;
			case 4:
				if (
					input.accountUsageCheckId === UsageVerificationType.MANUAL_VERIFICATION_NEEDED ||
					input.receivableUsageCheckId === UsageVerificationType.MANUAL_VERIFICATION_NEEDED
				) {
					return {
						accountUsage: {
							label: 'Manual Verification',
							text: `Usage must be verified manually`,
							iconPrefix: 'far',
							icon: 'circle-exclamation',
							classes: 'font-size-125 orange-text',
						},
						receivableUsage: {
							label: 'Manual Verification',
							text: `Usage must be verified manually`,
							iconPrefix: 'far',
							icon: 'circle-exclamation',
							classes: 'font-size-125 orange-text',
						},
					};
				}
				break;
			case 5:
				if (input.receivableUsageCheckId === UsageVerificationType.NOT_VERIFIED) {
					return {
						accountUsage: null,
						receivableUsage: {
							label: 'Actual Receivable Upfront Mismatch',
							text: input.receivableUsage
								? `Actual Receivable Upfront Mismatch: `
								+ `${decimalPipe.transform(input.receivableUsage, '1.0-0')} ${input.units}`
								: 'Actual Receivable Upfront Missing',
							iconPrefix: 'fas',
							icon: 'circle-exclamation',
							classes: 'font-size-125 red-text',
						},
					};
				}
				break;
			case 6:
				if (input.receivableUsageCheckId === UsageVerificationType.NOT_VERIFIED) {
					return {
						accountUsage: null,
						receivableUsage: {
							label: 'Actual Receivable Residual Mismatch',
							text: input.receivableUsage
								? `Actual Receivable Residual Mismatch: `
								+ `${decimalPipe.transform(input.receivableUsage, '1.0-0')} ${input.units}`
								: 'Actual Receivable Residual Missing',
							iconPrefix: 'fas',
							icon: 'circle-exclamation',
							classes: 'font-size-125 red-text',
						},
					};
				}
				break;
			case 7:
				if (input.receivableUsageCheckId === UsageVerificationType.NOT_VERIFIED) {
					return {
						accountUsage: null,
						receivableUsage: {
							label: 'Actual Receivable Upfront Mismatch',
							text: input.receivableUsage
								? `Actual Receivable Upfront Mismatch: `
								+ `${decimalPipe.transform(input.receivableUsage, '1.0-0')} ${input.units}`
								: 'Actual Receivable Upfront Missing',
							iconPrefix: 'fas',
							icon: 'circle-exclamation',
							classes: 'font-size-125 red-text',
						},
					};
				}
				break;
			case 8:
				if (input.ruleTierId === 1 && input.receivableUsageCheckId === UsageVerificationType.NOT_VERIFIED) {
					return {
						accountUsage: null,
						receivableUsage: {
							label: 'Actual Receivable Residual Mismatch',
							text: input.receivableUsage
								? `Actual Receivable Residual Mismatch: `
								+ `${decimalPipe.transform(input.receivableUsage, '1.0-0')} ${input.units}`
								: 'Actual Receivable Residual Missing',
							iconPrefix: 'fas',
							icon: 'circle-exclamation',
							classes: 'font-size-125 red-text',
						},
					};
				} else if (input.ruleTierId === 2 && input.accountUsageCheckId === UsageVerificationType.NOT_VERIFIED) {
					return {
						accountUsage: {
							label: input.accountUsage && input.accountUsage !== 0
								? 'Utility Account Usage Mismatch' : 'Utility Account Usage Missing',
							text: input.accountUsage && input.accountUsage !== 0
								? `Utility Account Usage Mismatch: ${decimalPipe.transform(input.accountUsage, '1.0-0')} ${input.units}`
								: 'Utility Account Usage Missing',
							iconPrefix: 'fas',
							icon: 'circle-exclamation',
							classes: 'font-size-125 red-text',
						},
						receivableUsage: null,
					};
				}
				break;
			case 9: {
				if (input.receivableUsageCheckId === UsageVerificationType.NOT_VERIFIED) {
					return {
						accountUsage: null,
						receivableUsage: {
							label: 'Actual Receivable Missing',
							text: 'Actual Receivable Missing',
							iconPrefix: 'fas',
							icon: 'circle-exclamation',
							classes: 'font-size-125 red-text',
						},
					};
				}
				break;
			}
			case 12: {
				if (input.ruleTierId === 1 && input.receivableUsageCheckId === UsageVerificationType.NOT_VERIFIED) {
					return {
						accountUsage: null,
						receivableUsage: {
							label: 'Actual Receivable Upfront Mismatch',
							text: input.receivableUsage
								? `Actual Receivable Upfront Mismatch: `
								+ `${decimalPipe.transform(input.receivableUsage, '1.0-0')} ${input.units}`
								: 'Actual Receivable Upfront Missing',
							iconPrefix: 'fas',
							icon: 'circle-exclamation',
							classes: 'font-size-125 red-text',
						},
					};
				} else if (input.ruleTierId === 2 && input.receivableUsageCheckId === UsageVerificationType.NOT_VERIFIED) {
					return {
						accountUsage: null,
						receivableUsage: {
							label: 'Actual Receivable Residual Mismatch',
							text: input.receivableUsage
								? `Actual Receivable Residual Mismatch: `
								+ `${decimalPipe.transform(input.receivableUsage, '1.0-0')} ${input.units}`
								: 'Actual Receivable Residual Missing',
							iconPrefix: 'fas',
							icon: 'circle-exclamation',
							classes: 'font-size-125 red-text',
						},
					};
				}
				break;
			}
		}

		return { accountUsage: null, receivableUsage: null };
	}

	public parseBase64<T>(value: string): T {
		try {
			return JSON.parse(window.atob(value)) as T;
		} catch (e) {
			return null;
		}
	}

	public encBase64(value: any): string {
		try {
			return window.btoa(value);
		} catch (e) {
			return null;
		}
	}

	public isSSL(): boolean {
		return this.window.location.protocol === 'https:';
	}

	public getColumnFieldName(dataGrid: DxDataGrid, getter: any): string {
		if (this.isFunction(getter)) {
			const column = dataGrid.columnOption(getter.columnIndex);
			return column.dataField;
		} else {
			return getter;
		}
	}

	public getColumnFieldType(dataGrid: DxDataGrid, getter: any): string {
		if (this.isFunction(getter)) {
			const column = dataGrid.columnOption(getter.columnIndex);
			return column.dataType;
		} else if (this.isString(getter)) {
			const column = dataGrid.columnOption(getter.replace(/^!/, ''));
			if (column) {
				return column.dataType;
			}

			return null;
		}

		return null;
	}

	public isNullOrUndefined(value: any): boolean {
		return value === undefined || value === null;
	}

	public isFunction(variable: any): boolean {
		return variable instanceof Function;
	}

	public isString(variable: any): boolean {
		return typeof variable === 'string';
	}

	public buildFilterMap(dataGrid: DxDataGrid, filterValues: any, filterMapRoot?: any): any {
		let filterMap = filterMapRoot;
		if (filterValues) {
			// extract bang operator
			if (filterValues.length && filterValues[0] === '!') {
				if (Array.isArray(filterValues[1]) && Array.isArray(filterValues[1][0])) {
					for (const filterValue of filterValues[1]) {
						if (Array.isArray(filterValue)) {
							filterValue[0] = '!' + filterValue[0];
						}
					}
				} else {
					filterValues[1][0] = '!' + filterValues[1][0];
				}
				filterValues.shift();
			}
			// organize into filter map
			for (let i = 0; i < filterValues.length; i++) {
				if (i === 0 && (this.isString(filterValues[i]) || this.isFunction(filterValues[i]))) {
					const columnFieldName = this.getColumnFieldName(dataGrid, filterValues[0]);
					const columnFieldNameOpposite = '!' + columnFieldName;
					const columnFieldType = this.getColumnFieldType(dataGrid, columnFieldName);
					if (columnFieldName.includes('Date') && columnFieldType === 'date') {
						if (!filterMap[columnFieldName]) {
							filterMap[columnFieldName] = [];
						}
						if (filterValues[1] === '>=') {
							filterMap[columnFieldName].push([filterValues]);
						} else {
							filterMap[columnFieldName][filterMap[columnFieldName].length - 1].push(filterValues);
						}
					} else if (!this.isNullOrUndefined(filterMap[columnFieldNameOpposite])) {
						if (Array.isArray(filterMap[columnFieldNameOpposite])) {
							filterMap[columnFieldNameOpposite].push(!isNaN(filterValues[2])
								? filterValues[2] : StringUtils.toBoolean(`${filterValues[2]}`));
						} else {
							filterMap[columnFieldNameOpposite] = [filterMap[columnFieldNameOpposite], filterValues[2]];
						}
					} else if (!this.isNullOrUndefined(filterMap[columnFieldName])) {
						if (Array.isArray(filterMap[columnFieldName])) {
							filterMap[columnFieldName].push(!isNaN(filterValues[2])
								? filterValues[2] : StringUtils.toBoolean(`${filterValues[2]}`));
						} else {
							filterMap[columnFieldName] = [filterMap[columnFieldName], filterValues[2]];
						}
					} else {
						if (Array.isArray(filterValues[2])) {
							filterMap[columnFieldName] = !isNaN(filterValues[2][2])
								? filterValues[2][2] : StringUtils.toBoolean(`${filterValues[2][2]}`);
						} else {
							filterMap[columnFieldName] = !isNaN(filterValues[2])
								? filterValues[2] : StringUtils.toBoolean(`${filterValues[2]}`);
						}
					}
				} else if (Array.isArray(filterValues[i])) {
					filterMap = this.buildFilterMap(dataGrid, filterValues[i], filterMap);
				}
			}

			return filterMap;
		} else {
			return filterMap;
		}
	}

	public convertFromStringToPrimitive(value: string): any {
		if (value === 'null') {
			return null;
		}
		if (value === 'undefined') {
			return undefined;
		}
		return value;
	}

	public removeFileFromEventTarget(event: EventTarget): void {
		const eventObj = event as any;
		const target = eventObj.target as HTMLInputElement;
		target.value = '';
	}

	public getFilesFromEventTarget(event: EventTarget): FileList {
		const eventObj = event as any;
		const target = eventObj.target as HTMLInputElement;
		return target.files;
	}

	public getLanguages(): { value: string; text: string }[] {
		return [
			{ value: 'english', text: 'English' },
			{ value: 'spanish', text: 'Spanish' },
		];
	}

	public getDwellingTypes(stateId: string, isResidential: boolean): string[] {
		if (isResidential && stateId === CONSTANTS.states.texas) {
			return [
				'Apartment',
				'House',
			];
		}

		return [];
	}

	public sanitizeUtilityAccountNumber(utilityAccountNum: string, isAGL: boolean, isResidential: boolean, switchOption: string): string {
		const isMoveIn = switchOption.includes('move');

		if (!utilityAccountNum || (isAGL && isResidential && isMoveIn)) { return ''; }

		let formattedAccountNumber = utilityAccountNum;
		formattedAccountNumber = formattedAccountNumber.replace(/[-\.,]/g, '');

		if (isAGL) {
			while (formattedAccountNumber.length < 10) {
				formattedAccountNumber = '0' + formattedAccountNumber;
			}
		}

		return formattedAccountNumber;
	}

	public formatMeterNum(meterNum: string, isNipscoResidentialGas: boolean): string {
		if (meterNum) {
			let trimmedMeterNum = meterNum.trim();

			if (trimmedMeterNum) {
				// NIPSCO Residential Gas requires meter number to be padded with 0, max length is 8
				if (isNipscoResidentialGas) {
					trimmedMeterNum = trimmedMeterNum.padEnd(8, '0');
				}
			}

			return trimmedMeterNum;
		}

		return '';
	}

	public generateDatesList(start: Date, end: Date, step = 'day'): Date[] {
		const list = [];
		if (start <= end) {
			for (const date = start; date.valueOf() <= end.valueOf(); this.dateListStep(date, step, 1)) {
				list.push(new Date(date));
			}
		} else {
			for (const date = start; date.valueOf() >= end.valueOf(); this.dateListStep(date, step, -1)) {
				list.push(new Date(date));
			}
		}

		return list;
	}

	private dateListStep(date: Date, step: string, direction: number): void {
		switch (step) {
			case 'day': {
				date.setDate(date.getDate() + (direction * 1));
				break;
			}
			case 'month': {
				date.setMonth(date.getMonth() + (direction * 1));
				break;
			}
			case 'year': {
				date.setFullYear(date.getFullYear() + (direction * 1));
				break;
			}
		}
	}

	public generateNumberList(start: number, end: number, step = 1): number[] {
		const list = [];
		if (start <= end) {
			for (let i = start; i <= end; i += step) {
				list.push(i);
			}
		} else {
			for (let i = start; i >= end; i -= step) {
				list.push(i);
			}
		}

		return list;
	}

	public generateYearsList(start: Date, end: Date, step = 1): Date[] {
		const list = [];
		let current = new Date(start);
		do {
			list.push(current);
			current = new Date(start.setFullYear(start.getFullYear() + step));
		} while (start.getTime() <= end.getTime());

		return list;
	}

	public generateTimesList(start: Date, end: Date, step = 60): Date[] {
		const list = [];
		let current = new Date(start);
		do {
			list.push(current);
			current = new Date(start.setMinutes(start.getMinutes() + step));
		} while (start.getTime() <= end.getTime());

		return list;
	}

	public generateTimesListFromMoment(start: moment.Moment, end: moment.Moment, step = 60): moment.Moment[] {
		const list = [];
		const current = start.clone();
		do {
			list.push(current.clone());
			current.add(step, 'minutes');
		} while (current.unix() <= end.unix());

		return list;
	}

	public getMonths() {
		return [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];
	}

	public convertArrayToObjectMap(arr: any[]): any {
		const obj = {} as any;
		arr.forEach(item => {
			obj[item] = true;
		});
		return obj;
	}

	public isZipCode(value: any, country: Country): boolean {
		return StringUtils.matches(String(value), country.zipCodeFormat);
	}

	public getErrorMessage(res: any): string[] {
		return HelperService.getErrorMessage(res);
	}

	public getPath(path: string): string {
		return HelperService.getPath(path);
	}

	public sanitizeApolloError(message: string): string {
		return message
			.replace(/Network error:\s+/g, '')
			.replace(/Unexpected error value:\s+/g, '');
	}

	public isSameDate(date: Date, today: Date): boolean {
		return HelperService.isSameDate(date, today);
	}

	public getProductMinBidAmount(productId: string, serviceTypeId: string, units: string): string {
		return HelperService.getProductMinBidAmount(productId, serviceTypeId, units);
	}

	public getProductMaxBidAmount(productId: string, serviceTypeId: string, stateId: string, units: string): string {
		return HelperService.getProductMaxBidAmount(productId, serviceTypeId, stateId, units);
	}

	public s2ab(s: string): ArrayBuffer {
		const buf = new ArrayBuffer(s.length);
		const view = new Uint8Array(buf);
		// eslint-disable-next-line no-bitwise
		for (let i = 0; i !== s.length; ++i) { view[i] = s.charCodeAt(i) & 0xFF; }
		return buf;
	}

	// add any additional CMS-type users here, like Mike Grimes who just needs to edit
	// and maintain certain contracts that he deals with outside of our system flow
	public isCMSUserForContract(user: User, contract: Contract): boolean {
		return HelperService.isCMSUserForContract(user, contract);
	}

	public isMikeGrimes(contract: Contract): boolean {
		return HelperService.isMikeGrimes(contract);
	}

	public generateDateSelections(dates: Date[]): any[] {
		return HelperService.generateDateSelections(dates);
	}

	public isFileSize(files: string | File[], maxMBSize: number): boolean {
		return HelperService.isFileSize(files, maxMBSize);
	}

	public getSupplierNote(serviceTypeId: string, stateId: string, supplierId: string): string {
		return HelperService.getSupplierNote(serviceTypeId, stateId, supplierId);
	}

	public getAgentSearchQuery(options: {
		search: string; includeParent?: boolean; defaultSearch?: string;
	}): string {
		const sanitizedSearch = this.sanitizeAdvancedSearchParameter(options.search || '');

		if (!sanitizedSearch) { return options.defaultSearch; }

		const exactMatch = `"${sanitizedSearch}"`;
		const containsMatch = `*${sanitizedSearch.split(' ').join('* *')}*`;
		const containsParentInclude = options.includeParent ? ` OR parent_name:(${containsMatch})` : '';
		const exactParentInclude = options.includeParent ? ` OR parent_name:(${exactMatch})` : '';

		let search = `(name:(${containsMatch})${containsParentInclude} OR name:(${exactMatch})${exactParentInclude} `
			+ `OR contact_name:(${containsMatch}) OR contact_name:(${exactMatch}) `
			+ `OR email:(${containsMatch}) OR email:(${exactMatch})`
			+ `OR agent_id:(${exactMatch}))`;

		search += ' AND is_active:(1 OR 0)';
		return search;
	}

	public getContractSearchQuery(options: {
		search: string; dbaName: string; utilityAccountNum: string;
	}): string {

		let search = '';

		if (options.search) {
			const sanitizedSearch = this.sanitizeAdvancedSearchParameter(options.search || '');

			search += sanitizedSearch.split(' ').join(' OR ');
		} else {
			if (options.dbaName) {
				const dbaName = options.dbaName || '';
				const sanitizedDbaName = this.sanitizeAdvancedSearchParameter(dbaName.replace(/'/g, ''));

				const exactMatchDbaName = `"${sanitizedDbaName}"`;
				const containsMatchDbaName = `*${sanitizedDbaName.split(' ').join('* *')}*`;

				search += `customer_dba_name:(${containsMatchDbaName}) OR customer_dba_name:(${exactMatchDbaName})`;
				search += ` OR customer_dba_name2:(${containsMatchDbaName}) OR customer_dba_name2:(${exactMatchDbaName})`;
			}

			if (options.utilityAccountNum) {
				const utilityAccountNum = options.utilityAccountNum || '';
				const sanitizedUtilityAccountNum = this.sanitizeAdvancedSearchParameter(utilityAccountNum.replace(/-/g, ''));

				const exactMatchUtilityAccountNum = `"${sanitizedUtilityAccountNum}"`;
				const containsMatchUtilityAccountNum = `*${sanitizedUtilityAccountNum.split(' ').join('* *')}*`;

				if (options.dbaName) {
					search += ` OR `;
				}

				search += `location_utility_account_num:(${containsMatchUtilityAccountNum})`
					+ ` OR location_utility_account_num:(${exactMatchUtilityAccountNum})`;
				search += ` OR location_utility_account_num2:(${containsMatchUtilityAccountNum})`
					+ ` OR location_utility_account_num2:(${exactMatchUtilityAccountNum})`;
			}
		}

		return search;
	}

	public sanitizeAdvancedSearchParameter(query: string): string {
		return query
			.replace(/\\/g, '')    // ignore slashes
			.replace(/\]|\[/g, '') // ignore brackets
			.replace(/-/g, '\\-')  // dashes mean 'not' so we need to escape with double slash for cloud-search
			.replace(/,/g, ' ')    // ignore commas
			.replace(/:/g, '\\:')  // colons mean 'operator next' so we need to escape with double slash for cloud-search
			.replace(/\(/g, '\\(') // parentheses groups query operators, escape with double slash for cloud-search
			.replace(/\)/g, '\\)') // parentheses groups query operators, escape with double slash for cloud-search
			.replace(/\./g, ' ')   // ignore periods
			.replace(/\s\s+/g, ' ')
			.replace(/\bAND\s\b/gi, '\\AND ')
			.replace(/\bOR\s\b/gi, '\\OR ')
			.trim();
	}

	public showAsteriskForBrokerFirstImpression(isNewBroker: boolean): boolean {
		return HelperService.showAsteriskForBrokerFirstImpression(isNewBroker);
	}

	public showAsteriskForContractFirstImpression(contractStatus: number, isNewBrokerAuction: boolean): boolean {
		return HelperService.showAsteriskForContractFirstImpression(contractStatus, isNewBrokerAuction);
	}

	public getEnergyIcon(serviceTypeId: string): string {
		return HelperService.getEnergyIcon(serviceTypeId);
	}

	public padShortZipCode(zipCode: string): string {
		return HelperService.padShortZipCode(zipCode);
	}

	public convertTwentyFourHourToTwelveHour(time: number): string {
		return HelperService.convertTwentyFourHourToTwelveHour(time);
	}

	public calculateTimezoneOffset(fromId: number, toId: number, timezones: any[]): number {
		return HelperService.calculateTimezoneOffset(fromId, toId, timezones);
	}

	public getWeekdayAvailability(dayOfWeek: number, customer: Customer, abbreviation = 'CT'): string {
		return HelperService.getWeekdayAvailability(dayOfWeek, customer, abbreviation);
	}

	public getTotalMargin(
		contract: Contract,
		includeBufferMargin = true, includeParentBufferMargin = false, includeMatrixAgentBufferMargin = false, unit?: string,
	): number {
		return HelperService.getTotalMargin(contract, includeBufferMargin, includeParentBufferMargin, includeMatrixAgentBufferMargin, unit);
	}

	public getTotalMarginFormatted(
		contract: Contract,
		includeBufferMargin = true, includeParentBufferMargin = false, includeMatrixAgentBufferMargin = false,
		marginUnits?: string, unit?: string,
	): string {
		return HelperService.getTotalMarginFormatted(contract,
			includeBufferMargin, includeParentBufferMargin, includeMatrixAgentBufferMargin,
			marginUnits, unit
		);
	}

	public getCommissionSplitBufferMargin(contract: Contract, unit?: string): number {
		return HelperService.getCommissionSplitBufferMargin(contract, unit);
	}

	/**
	 * @returns A loader function to be used in a DxDataGrid's CustomStore. The loader function will use the output of `func`
	 * as the data. However, if the loader function is called less than `delay` milliseconds after the previous call and
	 * the previous call hasn't resolved yet, the previous call's request will be canceled and its promise will instead be
	 * resolved with the latest call's data instead.
	 */
	public throttledLoader<D>(func: (loadOptions: LoadOptions) => Observable<{ data: D[]; totalCount: number }>, delay = 300):
		(loadOptions: LoadOptions) => Promise<{ data: D[]; totalCount: number }> {
		let pendingResolvers: ((data: { data: D[]; totalCount: number }) => void)[] = [];
		let latestData = { data: [] as D[], totalCount: 0 };

		const subject = new Subject<LoadOptions>();
		subject.pipe(
			throttleTime(delay, undefined, { leading: true, trailing: true }),
			switchMap(loadOptions => func(loadOptions)),
		).subscribe(data => {
			pendingResolvers.forEach(resolve => resolve(data));
			pendingResolvers = [];
		});

		return async (loadOptions: LoadOptions) => {
			if (loadOptions['dataField']) {
				return Promise.resolve(latestData);
			}

			const resultPromise = new Promise<{ data: D[]; totalCount: number }>(resolve => pendingResolvers.push(resolve));
			subject.next(loadOptions);
			const result = await resultPromise;

			latestData = {
				data: result.data ?? [],
				totalCount: result.totalCount,
			};
			return latestData;
		};
	}

	public getExcelColRange(colLength: number): string {
		let endCol: string;
		if (colLength <= 26) {
			endCol = String.fromCharCode('A'.charCodeAt(0) + colLength);
		} else {
			endCol = String.fromCharCode('A'.charCodeAt(0) + colLength / 26 - 1) + String.fromCharCode('A'.charCodeAt(0) + colLength % 26);
		}
		return 'A1:' + endCol + '1';
	}

	public getUsageVerification(input: UnpaidPayable | ViewPayableForecastDashboard): {
		accountUsage: {
			text: string;
			iconPrefix: string;
			icon: string;
			classes: string;
		};
		receivableUsage: {
			text: string;
			iconPrefix: string;
			icon: string;
			classes: string;
		};
	} {
		return HelperService.getUsageVerification(input);
	}

	public static getPriceByProduct(
		rates: ContractMarketPerformance,
		productId: string,
		isTexas: boolean,
	) {
		let rate = null;
		switch (productId) {
			case CONSTANTS.products.indexPlus:
				rate = rates.energy;
				break;
			case CONSTANTS.products.capacityUnbundled:
				rate = rates.capacity;
				break;
			case CONSTANTS.products.congestionPassThrough:
				rate = rates.congestion;
				break;
			case CONSTANTS.products.energyOnly:
				rate = rates.ancillary !== null && (isTexas ? rates.congestion : rates.capacity) !== null
					? rates.ancillary + (isTexas ? rates.congestion : rates.capacity)
					: null;
				break;
			case CONSTANTS.products.indexAdderOnly:
				rate = rates.energy !== null && rates.ancillary !== null && (isTexas ? rates.congestion : rates.capacity) !== null
					? rates.energy + rates.ancillary + (isTexas ? rates.congestion : rates.capacity)
					: null;
				break;
			case CONSTANTS.products.indexPlusExcludesCapacityEnergy:
				rate = rates.energy !== null && rates.capacity !== null
					? rates.energy + rates.capacity
					: null;
				break;
			case CONSTANTS.products.nymexPlus:
				rate = rates.nymex;
				break;
			case CONSTANTS.products.indexAdderLocalHub:
			case CONSTANTS.products.nymexAdder:
				rate = rates.nymex !== null && rates.basis !== null
					? rates.nymex + rates.basis
					: null;
				break;
			default:
				//To distinguish between not having a component vs fixed all in products
				rate = 0;
				break;
		}

		return rate;
	}
}



export class ReceivableAuditIssueOperationStatusType {
	public id: number;
	public name: string;

	constructor(receivableAuditIssueOperationStatusType: ReceivableAuditIssueOperationStatusType) {
		Object.assign(this, receivableAuditIssueOperationStatusType);
	}
}

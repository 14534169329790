import * as moment from 'moment-timezone';

import { ServiceType } from './service-type';
import { State } from './state';
import { Supplier } from './supplier';
import { Utility } from './utility';

export class SupplierRateSetting {

	public id: number;
	public supplierId: string;
	public utilityId: string;
	public serviceTypeId: string;
	public stateId: string;
	public loadFactor: number;
	public loadFactorNote: string;
	public billingMethod: string;
	public taxInfo: string;
	public cancellationPolicy: string;
	public addDate: string | Date;
	public showTaxId: boolean;
	public tosPath: string;
	public disclaimerPath: string;
	public yracPath: string;
	public eflPath: string;
	public showDepositDisclaimer: boolean;
	public depositDisclaimerNote: string;
	public depositPhone: string;
	public restrictionsNote: string;
	public captureSignatureAtRSEnrollment: boolean;
	public showOhepNotice: boolean;

	public supplier: Supplier;
	public utility: Utility;
	public serviceType: ServiceType;
	public state: State;

	constructor(supplierRateSetting?: SupplierRateSetting) {
		Object.assign(this, supplierRateSetting);

		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
	}
}

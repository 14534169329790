import * as moment from 'moment-timezone';

export class ReportCommissionBrokerPaid {

	public agentId: string;
	public dbaName: string;
	public accountAdjustmentId: string;
	public actualUsage: number;
	public addDate: Date;
	public adjustmentDescription: string;
	public amount: number;
	public rate: number;
	public className: string;
	public commissionCheckMethod: number;
	public commissionId: string;
	public commodity: string;
	public contractId: string;
	public contractNum: string;
	public accountUnderAudit: boolean;
	public accountUnderAuditReason: string;
	public description: string;
	public estimatedUsage: number;
	public isActive: number;
	public margin: number;
	public commissionSplitBufferMargin: number;
	public meterEndDate: Date;
	public meterStartDate: Date;
	public promoCode: string;
	public stateLong: string;
	public status: number;
	public supplierName: string;
	public term: number;
	public units: string;
	public utilityAccountNum: number;
	public commissionType: string;
	public isGuaranteePayment: boolean;
	public purchasingLayerHistoryId: number;
	public forecastedDate: Date;
	public forecastedUsage: number;
	public forecastedAmount: number;
	public dropDate: Date;

	constructor(reportCommissionBrokerPaid: ReportCommissionBrokerPaid) {
		Object.assign(this, reportCommissionBrokerPaid);

		this.addDate = this.addDate
			? new Date(moment(this.addDate).format('MMM DD, YYYY 00:00:00 a')) : this.addDate;
		this.forecastedDate = this.forecastedDate
			? new Date(moment(this.forecastedDate).format('MMM DD, YYYY 00:00:00 a')) : this.forecastedDate;
		this.meterStartDate = this.meterStartDate
			? new Date(moment(this.meterStartDate).format('MMM DD, YYYY 00:00:00 a')) : this.meterStartDate;
		this.meterEndDate = this.meterEndDate
			? new Date(moment(this.meterEndDate).format('MMM DD, YYYY 00:00:00 a')) : this.meterEndDate;
		this.dropDate = this.dropDate
			? new Date(moment(this.dropDate).format('MMM DD, YYYY 00:00:00 a')) : this.dropDate;
	}

	public getTotalMargin(): number {
		return Math.round((this.margin + this.commissionSplitBufferMargin) * 1e12) / 1e12;
	}
}

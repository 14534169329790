import { Pipe, PipeTransform } from '@angular/core';
import { CONSTANTS } from '@pk/powerkioskutils';

import * as _ from 'lodash';

import { Agent } from '../models';
import { LicensePlan } from '../models/license-plan';

@Pipe({
	name: 'licensePlanFilter',
})
export class LicensePlanFilterPipe implements PipeTransform {
	private businessFullIds = CONSTANTS.licensePlans.businessFullIds;
	private oldBusinessFullIds = CONSTANTS.licensePlans.oldBusinessFullIds;

	// do not show unless already assigned it
	private showParentBusinessLicenses(licensePlan: LicensePlan, agent: Agent, isImpersonatingOrAdmin: boolean) {
		const licensePlanId = licensePlan.id;
		return (this.businessFullIds.includes(licensePlanId) || // return business full
			(isImpersonatingOrAdmin && this.oldBusinessFullIds.includes(licensePlanId)) || // return special business fulls
			(CONSTANTS.licensePlans.businessLiteIds.includes(licensePlanId) && (agent.hasSeparateBusinessLite())));
	}

	transform(licensePlans: LicensePlan[], agent: Agent, isImpersonatingOrAdmin: boolean): any {
		if (!licensePlans) { return licensePlans; }

		return _.orderBy(
			licensePlans.filter(l => !l.parents?.length && this.showParentBusinessLicenses(l, agent, isImpersonatingOrAdmin)),
			l => l.name, 'desc'
		);
	}
}

import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';
import * as moment from 'moment-timezone';

@Pipe({
	name: 'filterPricingAnalyticsEffectiveDates',
})
export class FilterPricingAnalyticsEffectiveDatesPipe implements PipeTransform {
	transform(effectiveDates: number[],
		stateId: string,
		utilityId: string,
		allFields: { effectiveDate: string; term: number; usage: number; stateId: string; utilityId: string }[],
		term?: number, usage?: number,
	): unknown {

		return _.chain(allFields)
			.filter(f => (!term || f.term === term)
				&& (!usage || f.usage === usage)
				&& (!utilityId || f.utilityId === utilityId)
				&& (!stateId || f.stateId === stateId))
			.map(f => f.effectiveDate).uniq().sort((a, b) => {
				const dateA = moment(a, 'MMMM YYYY');
				const dateB = moment(b, 'MMMM YYYY');
				return dateA.diff(dateB, 'month');
			}).value();
	}
}

import * as moment from 'moment-timezone';

export class QuickBookDeposit {

	public id: number;
	public amount: number;
	public date: Date;

	constructor(quickBookDeposit: QuickBookDeposit) {
		Object.assign(this, quickBookDeposit);

		this.date = this.date
			? new Date(moment(this.date).format('MMM DD, YYYY hh:mm:ss a')) : this.date;
	}
}

import { FactoryProvider } from '@angular/core';

import { WINDOW } from './window';

const windowProvider: FactoryProvider = {
	provide: WINDOW,
	useFactory: () => window
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const WINDOW_PROVIdERS = [
	windowProvider
];

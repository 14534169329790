import * as moment from 'moment-timezone';

import { Role } from './role';
import { User } from './user';

export class TicketCategory {

	public id: number;
	public modifiedDate: Date;
	public name: string;
	public description: string;
	public isActive: boolean;
	public addDate: Date;
	public priority: number;
	public modifiedUserId: string;
	public userId: string;
	public assignedToUserId: string;
	public user: User;
	public modifiedUser: User;
	public assignedToUser: User;
	public roles: Role[];

	constructor(ticketCategory?: TicketCategory) {
		Object.assign(this, ticketCategory);

		this.modifiedDate = this.modifiedDate ? new Date(moment(this.modifiedDate).format('MMM DD, YYYY hh:mm:ss a')) : this.modifiedDate;
		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
		this.assignedToUser = this.assignedToUser ? new User(this.assignedToUser) : this.assignedToUser;
	}
}

import { CONSTANTS, NumberUtils, RateUtils } from '@pk/powerkioskutils';

import { HelperService } from '../helper.service';

export class ViewContractLogChanges {

	public id: number;
	public addDate: string;
	public status: number;
	public displayStatus: string;
	public rejectReason: string;
	public externalStatus: string;
	public isResidential: boolean;
	public externalId: string;
	public rate: number;
	public margin: number;
	public bufferMargin: number;
	public commissionSplitBufferMargin: number;
	public matrixAgentBufferMargin: number;
	public term: number;
	public annualUsage: number;
	public agentName: string;
	public userFullName: string;
	public isStatusChange: boolean;
	public isUsageChange: boolean;
	public isRateChange: boolean;
	public salesTax: number;
	public stateId: string;
	public supplierId: string;
	public confirmationDate: Date;
	public awardDate: Date;

	constructor(viewContractLogChanges?: ViewContractLogChanges) {
		Object.assign(this, viewContractLogChanges);
	}

	public getTotalMargin(
		includeBufferMargin = true, includeParentBufferMargin = false, includeMatrixAgentBufferMargin = false, unit?: string,
	): number {
		return HelperService.getTotalMargin(this, includeBufferMargin, includeParentBufferMargin, includeMatrixAgentBufferMargin, unit);
	}

	public getDisplayRate(unit?: string): string {
		if (([CONSTANTS.statuses.ainp, CONSTANTS.statuses.dr].includes(Number(this.status)))
			|| (this.status === CONSTANTS.statuses.acomp && !this.awardDate)) {
			return '';
		}

		// - this only affects the case where an auction contract picks
		// a matrix price, so there is already a buffer margin attached
		// - if status is quote, then don't include buffer margin
		// once it's signed/confirmed/acomp, then it's go back to showing it, which will
		// give it the same effect as if setBufferMargin() was called
		const includeBufferMargin = (this.status === CONSTANTS.statuses.acomp && !!this.awardDate)
			|| this.status === CONSTANTS.statuses.signed
			|| !!this.confirmationDate;

		const rate = NumberUtils.rateToUnits(RateUtils
			.adjustedRateWithTax(Math.round(
				(this.rate + this.getTotalMargin(includeBufferMargin, true, true)) * 1e12) / 1e12,
				this.stateId,
				this.salesTax, this.supplierId, this.annualUsage),
			unit);

		return rate.toString();
	}
}

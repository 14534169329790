
export class PostProcessingStats {
	public forecasted: number;
	public actual: number;
	public forecastedAssets: number;
	public forecastedLiability: number;
	public forecastedMonthToDate: number;
	public currentDeviationMonthToDate: number;
	public issueDeviationTotals: { issue: string; deviation: number }[];
	public issueDeviation: number;
	public noIssueDeviation: number;

	constructor(postProcessingStats: PostProcessingStats) {
		Object.assign(this, postProcessingStats);
	}
}

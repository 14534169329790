import * as moment from 'moment-timezone';

export class TicketStatus {

	public id: number;
	public name: string;
	public addDate: Date;
	public modifiedDate: Date;
	public modifiedUserId: string;
	public userId: string;

	constructor(ticketStatus: TicketStatus) {
		Object.assign(this, ticketStatus);

		this.modifiedDate = this.modifiedDate ? new Date(moment(this.modifiedDate).format('MMM DD, YYYY hh:mm:ss a')) : this.modifiedDate;
		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
	}
}

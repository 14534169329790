import * as moment from 'moment-timezone';

import { MarketTrends } from './market-trends';
import { ServiceType } from './service-type';
import { UtilityBaseRate } from './utility-base-rate';

export class MarginalPricesStatisticsRequest {
	public startDate: string;
	public utilityAbbreviation: string;
	public stateShort: string;
	public serviceTypeId: string;
	public zone: string;
	public rateClass: string;
	public blockType: string;
	public subCategory: string;
	public hub: string;
	public component: string;
	public endDate: string;
	public daySlide: number;
	public usage: number;
	public demand: number;
}

export class MarginalPricesStatisticsCongestionRequest {
	public utilityId: string;
	public stateId: string;
	public rateClass: string;
	public zone: string;
	public term: number;
	public startDate: string;
	public endDate: string;
}

export class Stat {
	public date: Date;
	public hour: string;
	public average: number;
	public max: number;
	public min: number;
	public percentile: number;

	constructor(stat: Stat) {
		Object.assign(this, stat);

		this.date = this.date
			? new Date(moment(this.date).format('MMM DD, YYYY 00:00:00 a')) : this.date;
	}
}

export class RateStats {
	public stats: Stat[];
	public max: number;
	public min: number;
	public average: number;
	public stdDeviation: number;
	public pricePercentile: number;

	constructor(rateStats: RateStats) {
		Object.assign(this, rateStats);

		this.stats = this.stats ? this.stats.map(s => new Stat(s)) : this.stats;
	}
}

export class GasStat {
	public dateTime: string;
	public value: number;
}

export class RateStatResponse {
	public rates: RateStats;

	constructor(rates: RateStatResponse) {
		Object.assign(this, rates);

		this.rates = this.rates ? new RateStats(this.rates) : this.rates;
	}
}

export class CapacityRateStatResponse {
	public capacity: RateStats;
	public billableCapacity: RateStats;

	constructor(capacityRateStatResponse: CapacityRateStatResponse) {
		Object.assign(this, capacityRateStatResponse);

		this.capacity = this.capacity ? new RateStats(this.capacity) : this.capacity;
		this.billableCapacity = this.billableCapacity ? new RateStats(this.billableCapacity) : this.billableCapacity;
	}
}

export class CongestionRateStatResponse {
	public congestion: RateStats;

	constructor(congestionRateStatResponse: CongestionRateStatResponse) {
		Object.assign(this, congestionRateStatResponse);

		this.congestion = this.congestion ? new RateStats(this.congestion) : this.congestion;
	}
}

export class AncillaryRateStatResponse {
	public ancillaries: RateStats;

	constructor(ancillaryRateStatResponse: AncillaryRateStatResponse) {
		Object.assign(this, ancillaryRateStatResponse);

		this.ancillaries = this.ancillaries ? new RateStats(this.ancillaries) : this.ancillaries;
	}
}

export class GlobalAdjRateStatResponse {
	public globalAdjustments: RateStats;

	constructor(globalAdjRateStatResponse: GlobalAdjRateStatResponse) {
		Object.assign(this, globalAdjRateStatResponse);

		this.globalAdjustments = this.globalAdjustments ? new RateStats(this.globalAdjustments) : this.globalAdjustments;
	}
}

export class MarginalRate {
	public date: string;
	public value: number;
	public percentile: number;
}

export class RateResponse {
	public rates: MarginalRate[];
}

export class GasStatsResponse {
	public gasStats: GasStat[];
}

export class SideStat {
	public value: number;
	public color: string;
	public name: string;
}

export class TopStat {
	public label: string;
	public showSuggestion?: boolean;
	public suggestion?: string;
	public value: string | number;
	public unit: string;
	public show: boolean;
	public color?: string;
	public sortOrder: number;
}

export class TopStatsOptions {
	showPercentile: boolean;
	showMin: boolean;
	showMax: boolean;
	showAverage: boolean;
	showStdDeviation: boolean;
	showNymex: boolean;
}

export class StatisticsCharts {
	public serviceType: ServiceType;

	public nymexPricingStatisticsUnits: string;
	public nymexPricingStatistics: RateStatResponse;
	public nymexPricingStatisticsMovingAverages: RateResponse;

	public supplyPricingStatisticsUnits: string;
	public supplyPricingStatistics: RateStatResponse;
	public supplyPricingStatisticsMovingAverages: RateResponse;

	public basisPricingStatisticsUnits: string;
	public basisPricingStatistics: RateStatResponse;
	public basisPricingStatisticsMovingAverages: RateResponse;

	public energyTitle: string;
	public energyPricingStatisticsUnits: string;
	public energyPricingStatistics: RateStatResponse;
	public energyPricingStatisticsMovingAverages: RateResponse;

	public hourlyPricingStatisticsUnits: string;
	public hourlyPricingStatistics: RateStatResponse;

	public capacityStatisticsUnits: string;
	public billableCapacityStatisticsUnits: string;
	public capacityStatistics: RateStats;
	public billableCapacityStatistics: RateStats;

	public ancillaryStatisticsUnits: string;
	public ancillaryStatistics: AncillaryRateStatResponse;

	public globalAdjStatisticsUnits: string;
	public globalAdjStatistics: GlobalAdjRateStatResponse;

	public marketTrends: MarketTrends;
	public utilityBaseRates: UtilityBaseRate[];

	public nymexIntradayPricingStatistics: GasStat[];
}

import { Pipe, PipeTransform } from '@angular/core';

import { MarketUpdateAuction, ServiceType } from '../models';

@Pipe({
	name: 'marketUpdateAuctionServiceTypeFilter'
})
export class MarketUpdateAuctionServiceTypeFilterPipe implements PipeTransform {
	transform(serviceTypes: ServiceType[], auctions: MarketUpdateAuction[], agentId: string): ServiceType[] {
		return serviceTypes
			? serviceTypes.filter(s => auctions.some(a => a.serviceTypeId === s.serviceTypeId && (!agentId || agentId === a.agentId)))
			: serviceTypes;
	}
}

import { State } from './state';
import { UtilityZipCodeMap } from './utility-zip-code-map';

export class ZipCode {

	public stateId: string;
	public cities: string[];

	public state: State;
	public utilityZipCodeMaps: UtilityZipCodeMap[];

	constructor(zipCode: ZipCode) {
		Object.assign(this, zipCode);
	}
}

export class UserSurveyInput {

	public id: number;
	public recommendScore: number;
	public respondantType: string;
	public scoreReason: string;
	public improveReason: string;
	public canContact: boolean;
	public bestEmail: string;
	public closedSurveyWithButton: boolean;
	public closedSurveyWithoutButton: boolean;
	public userSurveyCategoryId: string | number;
	public userSurveyCategoryTypeId: number;
	public isComplete: boolean;
	public ticketId: number;
	public ticketCategoryId: string;
	public contractId: string;
	public startDate: Date;
	public endDate: Date;

	constructor(userSurveyInput?: UserSurveyInput) {
		Object.assign(this, userSurveyInput);

		this.canContact = null;
	}
}

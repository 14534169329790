import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { HelperService } from 'src/app/shared/helper.service';
import { CompanyCalendlySetting, EducationSearchResult, FaqSearchResult } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-faq-search-modal-feedback',
	templateUrl: './faq-search-modal-feedback.component.html',
	styleUrls: ['./faq-search-modal-feedback.component.scss'],
})
export class FaqSearchModalFeedbackComponent extends AbstractPageForm {

	@Input() modalView: 'search' | 'related-articles' | 'feedback';
	@Input() selectedFeedback: 'positive' | 'negative';
	@Input() criteria: string;
	@Input() faqResult: {
		siteSectionSearchResultsGrouped: { question: string; answers: FaqSearchResult[] }[];
		faqAuditId: number;
		faqSearchResults: FaqSearchResult[];
		educationAuditId: number;
		educationSearchResults: EducationSearchResult[];
	};
	@Output() backClicked = new EventEmitter<void>();

	public calendlyOptions: CompanyCalendlySetting[];

	public submittedFeedback: boolean;

	constructor(
		public activeModal: BsModalRef,
		private fb: FormBuilder,
		private helperService: HelperService,
		private graphqlService: GraphqlService,
	) {
		super();
		this.submitAudit = 'faq-search-modal:submitFeedback';
	}

	public async loadPageData(): Promise<void> {
		this.calendlyOptions = await this.helperService.getCalendlyOptions();
	}

	public getForm() {
		return this.fb.group({
			body: ['', [Validators.required, Validators.maxLength(400)]],
		});
	}

	get charactersLeft(): number {
		return 400 - (this.form.body.value?.length || 0);
	}

	protected async onFormSubmitted() {
		await this.graphqlService.createFeedback(this.getFeedbackDTO());
		this.submittedFeedback = true;
	}

	private getFeedbackDTO() {
		return {
			body: JSON.stringify({
				search: this.criteria,
				faqs: this.faqResult ? [
					..._.flatMap(this.faqResult.siteSectionSearchResultsGrouped, g => g.answers).map(a => a.id),
					...this.faqResult.faqSearchResults.map(f => f.id),
				] : [],
				feedback: this.form.body.value,
			}),
			userId: this.loggedInUser.userId,
			appId: 1,
			rating: 0,
			canContact: false,
			isReviewed: true,
		};
	}

	public back() {
		this.backClicked.emit();
	}
}

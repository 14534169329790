export class SupplierCommissionMapState {

	public id: number;
	public supplierCommissionMapId: number;
	public stateId: string;

	constructor(supplierCommissionMapState?: SupplierCommissionMapState) {
		Object.assign(this, supplierCommissionMapState);
	}
}

export class KeyTextExtractionResults {
	public id: number;
	public attachmentId: string;
	public contractId: string;
	public isAccountNumberMatched!: boolean;
	public accountNumberMatchedResult!: string;
	public accountNumberConfidentScore!: number;
	public accountNumberSimilarity!: number;
	public isLENMatched!: boolean;
	public LENMatchedResult!: string;
	public LENConfidentScore!: number;
	public LENSimilarity!: number;
	public isAddressMatched!: boolean;
	public addressMatchedResult!: string;
	public addressConfidentScore!: number;
	public addressSimilarity!: number;
	public addDate: Date;

	constructor(keyTextExtractionResults?: Subset<KeyTextExtractionResults>) {
		Object.assign(this, keyTextExtractionResults);
	}

}

export class AgentActivityStatisticsVelocityGraphStat {

	public monthYearDate: string | Date;
	public avgDaysDiff: number;
	public nonOutlierAvgDaysDiff: number;

	constructor(agentActivityStatisticsVelocityGraphStat?: AgentActivityStatisticsVelocityGraphStat) {
		Object.assign(this, agentActivityStatisticsVelocityGraphStat);
	}
}

import { Acknowledgement } from './acknowledgement';

export class SpecialNotice {

	public specialNoticeId: number;
	public heading: string;
	public body: string;

	public acknowledgements: Acknowledgement[];

	constructor(specialNotice: SpecialNotice) {
		Object.assign(this, specialNotice);
	}
}

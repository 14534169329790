
export class ComplianceMonitorAgentDetailContractRisk {
	public commodity: string;
	public total: number;
	public high: number;
	public highPercentage: number;
	public medium: number;
	public mediumPercentage: number;
	public low: number;
	public lowPercentage: number;
}

<header class="container-fluid">
	<ng-container *ngIf="!isPopupWindow">
		<div class="row top-row" [ngClass]="envName">
			<div class="col main-logo" [ngClass]="{ 'pl-0': loading }">
				<a [routerLink]="['/']" *ngIf="!isDirect">
					<pk-broker-loader [show]="loading" color="white"></pk-broker-loader>
					<img
						*ngIf="showBranding"
						[attr.src]="
							environment.baseUrl +
							'/attachments/' +
							(user?.agent?.darkDisplayLogo?.attachmentId || defaultNavbarLogo.attachmentId) +
							'/view?access_token=' +
							environment.apiKey
						" />
				</a>
				<span *ngIf="isDirect">
					<pk-broker-loader [show]="loading" color="white"></pk-broker-loader>
					<img
						*ngIf="showBranding && (agent?.darkDisplayLogo?.attachmentId || defaultNavbarLogo)"
						[attr.src]="
							environment.baseUrl +
							'/attachments/' +
							(user?.agent?.darkDisplayLogo?.attachmentId || defaultNavbarLogo.attachmentId) +
							'/view?access_token=' +
							environment.apiKey
						" />
				</span>
			</div>
			<div class="col-auto top-nav" [ngClass]="{ 'navbar-preload': loading }">
				<ul class="float-right">
					<ng-container *ngIf="!loading && !isDirect && user">
						<li *ngIf="showFinancialProfile">
							<a
								class="collapse-link collapsed"
								href="javascript:void(0)"
								(click)="isFinancialProfileCollapsed = !isFinancialProfileCollapsed"
								[attr.aria-expanded]="!isFinancialProfileCollapsed">
								<span><fa-icon [icon]="['fas', 'dollar-sign']"></fa-icon></span>
								<span class="d-none d-md-inline"> Financial Profile</span>
							</a>
						</li>
						<li *ngIf="!user.supplierId">
							<ng-container *ngIf="!user.agent || user.agent.isActive">
								<a href="javascript:void(0)" (click)="openFaqSearch()" *ngIf="useSupportSystem">
									<fa-icon [icon]="['fas', 'question']"></fa-icon>
									<span class="d-none d-md-inline"> Support</span>
								</a>
								<a href="mailto:{{ supportEmail }}" *ngIf="!useSupportSystem && supportEmail">
									<fa-icon [icon]="['fas', 'question']"></fa-icon>
									<span class="d-none d-md-inline"> Support</span>
								</a>
							</ng-container>
							<a [routerLink]="['ticket']" *ngIf="user.agent && !user.agent.isActive">
								<fa-icon [icon]="['fas', 'question']"></fa-icon>
								<span class="d-none d-md-inline"> Support</span>
							</a>
						</li>
						<li>
							<a [routerLink]="['security', 'edit']">
								<span><fa-icon [icon]="['fas', 'user']"></fa-icon></span>
								<span class="d-none d-md-inline"> Account</span>
							</a>
						</li>
					</ng-container>
					<li *ngIf="!isDirect">
						<a (click)="logout()" href="javascript:void(0)">
							<fa-icon [icon]="['fas', 'sign-out-alt']"></fa-icon>
							<span class="d-none d-md-inline"> Logout</span>
						</a>
					</li>
				</ul>
			</div>
			<div class="col-auto top-nav" *ngIf="!loading && isDirect && user">
				<ul class="float-right">
					<li>
						<a [attr.href]="'tel:' + user.phone">
							<fa-icon [icon]="['fas', 'phone']"></fa-icon>
							{{ user.phone | phone }}
						</a>
					</li>
					<br *ngIf="isMobile" />
					<li>
						<a [attr.href]="'mailto:' + user.email">
							<fa-icon [icon]="['fas', 'envelope']"></fa-icon>
							{{ user.email }}
						</a>
					</li>
				</ul>
			</div>
			<div
				*ngIf="!loading && !isDirect && user"
				class="col-12 pl-4 pl-xl-5 pr-4 pb-5 financial-profile"
				[collapse]="isFinancialProfileCollapsed"
				[isAnimated]="true"
				id="freqBroker">
				<pk-broker-navbar-financial-profile
					*ngIf="user && agentDiscountPrograms && user.agent && !user.agent.isEnterprise"
					(toggledFinancialProfileProgram)="toggleFinancialProfileProgram($event)"
					[user]="user"
					[agentDiscountPrograms]="agentDiscountPrograms">
				</pk-broker-navbar-financial-profile>
			</div>
		</div>
		<div class="row bottom-row" *ngIf="!isDirect && user">
			<div class="d-none d-sm-block col breadcrumb-nav">
				<nav aria-label="breadcrumb">
					<ol class="breadcrumb" *ngIf="!loading">
						<li *ngFor="let breadcrumb of breadcrumbs; let i = index" class="breadcrumb-item" [ngClass]="{ active: i === breadcrumbs.length - 1 }">
							<ng-container *ngIf="i !== breadcrumbs.length - 1">
								<a [routerLink]="breadcrumb.route" *ngIf="i !== breadcrumbs.length - 1">{{ breadcrumb.text }}</a>
							</ng-container>
							<ng-container *ngIf="i === breadcrumbs.length - 1">
								{{ breadcrumb.text }}
							</ng-container>
						</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-auto search-nav">
				<ul class="float-right mb-2" *ngIf="!loading">
					<li class="d-lg-inline pl-0" *ngIf="user && !user.supplierId && searchForm && (!user || !user.agent || user.agent.isActive)">
						<form id="searchForm" [formGroup]="searchForm" class="form-inline d-inline-block mb-0" (ngSubmit)="onSubmit()">
							<fa-icon class="mr-1" [icon]="['fas', 'search']"></fa-icon>
							<input class="form-control" type="text" placeholder="Search" aria-label="Search" formControlName="search" />
						</form>
					</li>
					<ng-container *ngIf="isMenuCollapsed">
						<li class="d-none d-lg-inline" *ngIf="user && showPricingAnalytics && (!user || !user.agent || user.agent.isActive)">
							<a [routerLink]="['/supplier', 'pricing-analytics']">Pricing Copilot</a>
						</li>
						<li class="d-none d-lg-inline" *ngIf="user && !user.supplierId && showReports"><a [routerLink]="['/report', 'home']">Reports</a></li>
						<li class="d-none d-lg-inline" *ngIf="user && !user.supplierId && showReferralExchange && (!user || !user.agent || user.agent.isActive)">
							<a [routerLink]="['/referral-exchange']">Referral Exchange</a>
						</li>
						<li class="d-none d-lg-inline" *ngIf="user && !user.supplierId && showNewsResources && (!user || !user.agent || user.agent.isActive)">
							<a [routerLink]="['/news']">News &amp; Resources</a>
						</li>
						<li class="d-none d-lg-inline" *ngIf="user && !user.supplierId && (!user || !user.agent || user.agent.isActive)">
							<a [routerLink]="['/cPanel', 'home']">Setup</a>
						</li>
					</ng-container>
					<li class="main-nav-link" *ngIf="!user || !user.agent || user.agent.isActive">
						<a
							class="collapse-link collapsed"
							(click)="toggleMenu()"
							href="javascript:void(0)"
							[attr.aria-expanded]="!isMenuCollapsed"
							id="mainNavCollapseLink">
							<span class="collapse-menu-text" *ngIf="isMenuCollapsed">Menu<fa-icon [icon]="['fas', 'bars']"></fa-icon></span>
							<span class="collapse-close-text" *ngIf="!isMenuCollapsed">Close<fa-icon [icon]="['fas', 'times']"></fa-icon></span>
						</a>
					</li>
				</ul>
			</div>
			<div class="col-12 collapse main-nav" [collapse]="isMenuCollapsed" [isAnimated]="true" id="mainNavCollapse" *ngIf="!loading">
				<div class="row py-3">
					<div *ngFor="let menu of menus" class="col-12 col-sm-6 col-md-4">
						<p>
							<a *ngIf="!menu.queryString" [routerLink]="menu.eventName" (click)="collapseMenu()" class="mainNavLink" [attr.id]="menu.idName">
								<fa-icon [icon]="[menu.className ? menu.className : 'fas', menu.iconClassName]"></fa-icon>{{ menu.name }}
							</a>
							<a *ngIf="menu.queryString" href="javascript:void(0)" (click)="runMethod(menu.eventName)" class="mainNavLink" [attr.id]="menu.idName">
								<fa-icon [icon]="[menu.className ? menu.className : 'fas', menu.iconClassName]"></fa-icon>{{ menu.name }}
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="isPopupWindow">
		<div class="row top-row">
			<div class="col main-logo" style="min-height: 50px" [ngClass]="{ 'pl-0': loading }">
				<span>
					<pk-broker-loader [show]="loading" color="white"></pk-broker-loader>
					<img
						*ngIf="showBranding"
						[attr.src]="
							environment.baseUrl +
							'/attachments/' +
							(user?.agent?.darkDisplayLogo?.attachmentId || defaultNavbarLogo.attachmentId) +
							'/view?access_token=' +
							environment.apiKey
						" />
				</span>
			</div>
		</div>
	</ng-container>
</header>

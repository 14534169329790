import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { Contract, Note, NoteType } from 'src/app/shared/models';
import { GraphqlService } from '../../../graphql/graphql.service';
import { AbstractPageForm } from '../../../shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-contract-note-add',
	templateUrl: './contract-note-add.component.html',
	styleUrls: ['./contract-note-add.component.scss'],
})
export class ContractNoteAddModalComponent extends AbstractPageForm {

	// inputs
	public contract: Contract;

	// outputs
	public onSubmit: Subject<Note> = new Subject();

	public noteTypes: NoteType[];

	constructor(
		public activeModal: BsModalRef,
		private graphqlService: GraphqlService,
	) {
		super();
		this.submitAudit = 'contract-note-add:submitNote';
	}

	public async loadPageData(): Promise<void> {
		const result = await this.graphqlService.getNoteTypes({ roleId: this.loggedInUser.roleId }, 'name');
		this.noteTypes = result.data.noteTypes.message;
	}

	public onSubmitNote(event: { note: Note }): void {
		this.onSubmit.next(event.note);
		this.activeModal.hide();
	}
}

import { EmailPreference } from './email-preference';

export class CustomerEmailPreference {

	public customerId: string;
	public emailPreferenceId: number;

	public emailPreference: EmailPreference;

	constructor(customerEmailPreference?: CustomerEmailPreference) {
		Object.assign(this, customerEmailPreference);
	}
}

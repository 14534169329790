/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
import * as moment from 'moment-timezone';

export enum BusinessPipelineReportDisplayType {
	USAGE = 'USAGE',
	COUNT = 'COUNT',
	TERMVALUE = 'TERMVALUE'
}

export enum BusinessPipelineReportDisplayTypeName {
	USAGE = 'Load Size',
	COUNT = 'Contracts',
	TERMVALUE = 'Term Value'
}

export enum BusinessPipelineReportDetailView {
	AGGREGATE = 'AGGREGATE',
	DETAIL = 'DETAIL'
}

export enum BusinessPipelineReportCategory {
	AUCTION = 'AUCTION',
	MATRIX = 'MATRIX',
	RESIDENTIAL = 'RESIDENTIAL'
}

export class BusinessPipelineReport {

	public date: Date;
	public contractType: string;
	public agentName: string;
	public isRenewal: boolean;
	public commodity: string;
	public awarded: string | number;
	public confirmed: string | number;
	public ended: string | number;
	public quote: string | number;
	public extended1: string | number;
	public extended2: string | number;
	public extended3: string | number;
	public initiated: string | number;
	public rejected: string | number;
	public released: string | number;
	public signed: string | number;
	public fraud: string | number;
	public dropped: string | number;
	public pendingdeposit: string | number;

	public get totalValue() {
		return (+this.awarded) + (+this.confirmed) + (+this.ended) + (+this.quote) + (+this.extended1) + (+this.extended2) +
			(+this.extended3) + (+this.initiated) + (+this.rejected) + (+this.released) + (+this.signed) + (+this.fraud) +
			(+this.dropped) + (+this.pendingdeposit);
	}

	constructor(businessPipelineReport?: Partial<BusinessPipelineReport>) {
		Object.assign(this, businessPipelineReport);

		this.date = this.date ? new Date(moment(this.date).format('MMM DD, YYYY 00:00:00 a')) : this.date;
	}
}

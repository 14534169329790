import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'minusSignToParens'
})
export class MinusSignToParensPipe implements PipeTransform {
	transform(value: string | number): string {
		const strValue = String(value);
		return strValue.charAt(0) === '-' ?
			'(' + strValue.substring(1, strValue.length) + ')' :
			strValue;
	}
}

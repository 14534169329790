/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input } from '@angular/core';
import { CONSTANTS } from '@pk/powerkioskutils';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { Feedback, Ticket, User } from 'src/app/shared/models';

@Component({
	selector: 'pk-broker-feedback-row',
	templateUrl: './feedback-row.component.html',
	styleUrls: ['./feedback-row.component.scss'],
})
export class FeedbackRowComponent {
	@Input() feedback: Feedback;
	@Input() loggedInUser: User;

	public loadingStatus = false;

	public statusActions = { 'Mark as In Progress': () => this.markInProgress(), 'Mark as Resolved': () => this.markResolved() };

	constructor(private graphqlService: GraphqlService) { }

	public async markInProgress(): Promise<void> {
		this.loadingStatus = true;
		const ticket = this.buildInProgressTicketDTO();
		await this.trackFeedback(ticket);
		this.loadingStatus = false;
	}

	public async markResolved(): Promise<void> {
		this.loadingStatus = true;
		if (this.feedback.ticketId) {
			const ticket = await this.graphqlService.updateFeedbackTicket(this.feedback.ticketId, { ticketStatusId: 5 } as Ticket);
			this.feedback.ticket = new Ticket(ticket.data.updateTicket);
		} else {
			const ticket = this.buildInCompleteTicketDTO();
			await this.trackFeedback(ticket);
		}
		this.loadingStatus = false;
	}

	private async trackFeedback(ticket: Ticket): Promise<void> {
		const createdTicket = await this.graphqlService.createFeedbackTicket(ticket);
		await this.graphqlService.updateFeedback(this.feedback.id, { ticketId: createdTicket.data.createTicket.id } as Feedback);
		this.feedback.ticketId = createdTicket.data.createTicket.id;
		this.feedback.ticket = createdTicket.data.createTicket;
	}

	private buildInProgressTicketDTO(): Ticket {
		const ticket = this.buildTicketDTO();
		ticket.ticketStatusId = 2;
		return ticket;
	}

	private buildInCompleteTicketDTO(): Ticket {
		const ticket = this.buildTicketDTO();
		ticket.ticketStatusId = 5;
		return ticket;
	}

	private buildTicketDTO(): Ticket {
		return {
			email: this.feedback.user.email,
			subject: `${this.feedback.type.name} on ${this.feedback.page}`,
			body: this.feedback.body,
			assignedToUserId: this.loggedInUser.userId,
			ticketCategoryId: CONSTANTS.ticketCategories.feedback,
			priority: 1,
		} as Ticket;
	}
}

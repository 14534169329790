import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'shrink'
})
export class ShrinkPipe implements PipeTransform {

	transform(value: any, maxLen: number = 50): string {
		if (!value) { return ''; }
		value = String(value).trim();

		if (value.length <= maxLen) {
			return value;
		}

		const sideLen = Math.floor((maxLen - 3) / 2);

		let result = value.substr(0, sideLen).trim() + '...' + value.substr(value.length - sideLen, sideLen).trim();
		if (value.includes('*') && !result.includes('*')) {
			result += '*';
		}

		return result;
	}
}

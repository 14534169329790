<section style="min-height: calc(100% - 61px)">
	<section class="container-fluid support-center-container p-0" style="padding-top: 7em !important; min-height: unset !important">
		<pk-broker-loader [show]="loading"></pk-broker-loader>
		<div class="row d-flex justify-content-center align-items-center top-search-header main-page-big w-100" *ngIf="!loading">
			<div class="col-12 col-lg-8">
				<h1>How can we help you?</h1>
				<form [formGroup]="faqService.formGroup" (submit)="search()" autocomplete="off">
					<div class="input-group main-search-faq">
						<input
							type="text"
							class="form-control"
							id="faqSearchForm"
							placeholder="Start typing your search..."
							aria-label="Main FAQ Search"
							aria-describedby="mainSearchFAQ"
							formControlName="criteria"
							(keyup)="search()" />
						<div class="input-group-append">
							<button class="btn btn-info" type="button" id="mainSearchFAQ"><fa-icon [icon]="['fas', 'search']"></fa-icon></button>
						</div>
					</div>
					<p class="mb-0 mt-1">Please use at least three characters in your search</p>
				</form>
			</div>
		</div>
	</section>
	<section class="container-fluid support-center-container" style="padding-top: 0px !important" *ngIf="!loading">
		<div class="row">
			<div
				class="col-12 offset-xxl-1 offset-xxxl-2 faq-left"
				[ngClass]="{
					'col-lg-5 col-xxl-4 col-xxxl-3':
						!faqResult && !faqCategory && !faqArticle && !loadingFaqSearch && !loadingFaqCategorySearch && !loadingArticle,
					'col-lg-9 col-xxl-8 col-xxxl-7': faqResult || faqCategory || faqArticle || loadingFaqSearch || loadingFaqCategorySearch || loadingArticle
				}">
				<router-outlet></router-outlet>
			</div>
			<div
				class="col-12 faq-right"
				[ngClass]="{
					'col-12 col-lg-7 col-xxl-6 col-xxxl-5':
						!faqResult && !faqCategory && !faqArticle && !loadingFaqSearch && !loadingFaqCategorySearch && !loadingArticle,
					'col-12 col-lg-3 col-xxl-3 col-xxxl-3':
						faqResult || faqCategory || faqArticle || loadingFaqSearch || loadingFaqCategorySearch || loadingArticle
				}">
				<ng-container *ngIf="!faqResult && !faqArticle && !loadingFaqSearch && !loadingFaqCategorySearch && !loadingArticle">
					<h2 class="mb-4 no-underline gray-text">Brokers like you also viewed these answers:</h2>
					<p *ngFor="let defaultFaq of defaultFaqs">
						<a href="javascript:void(0)" id="faqDefaultQuestion" (click)="showFaqArticle(defaultFaq)" class="semibold-text">{{
							defaultFaq.question
						}}</a
						><br />
						<span [innerHtml]="defaultFaq.answer | faqShortAnswer"></span></p
				></ng-container>

				<ng-container *ngIf="loadingFaqSearch || loadingFaqCategorySearch || loadingArticle || faqResult || faqArticle">
					<div class="row">
						<div class="col mt-4 mb-3">
							<h4 class="no-underline gray-text">FAQ Categories:</h4>
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-sm-6 col-lg-12 mb-4" *ngFor="let faqCategory of faqCategories">
							<a class="icon-link" id="faqSideCategory" [routerLink]="['/faq', 'category', faqCategory.id]">
								<span class="icon"> <fa-icon [icon]="['fas', faqCategory.iconClassName]" [classes]="['mr-1']"></fa-icon> </span>{{ faqCategory.name }}
							</a>
						</div>
					</div>
					<div class="row">
						<div class="col mb-3">
							<h4 class="no-underline gray-text">Can't find an answer?</h4>
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-sm-6 col-lg-12 mb-4">
							<a [routerLink]="['/ticket']" id="faqSideSupportTicket" target="_blank" class="icon-link"
								><span class="icon"><fa-icon [icon]="['fas', 'laptop-medical']"></fa-icon></span>Submit a Support Ticket</a
							>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</section>
</section>

<pk-broker-modal customFooterClass="justify-content-end">
	<ng-template #headerTemplate>
		<h5 class="modal-title">Search for ESIID</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<pk-broker-loader [show]="loading"></pk-broker-loader>
		<div class="container-fluid" *ngIf="!loading">
			<form [formGroup]="formGroup">
				<div class="row">
					<div class="col">
						<p class="semibold-text">Avoid a potential rejection with our handy tool.</p>
						<ol>
							<li>Enter in an address or part of an ESIID in the blank.</li>
							<li>Select the correct Address/ESIID that appears in the list.</li>
							<li>Press Submit to pull your location information into the sign-up form.</li>
						</ol>
					</div>
				</div>
				<div class="row">
					<div class="col form-element-container" [class.invalid]="err.esiidOrAddress">
						<div class="col-12 form-element-container">
							<pk-broker-typeahead
								formControlName="esiidOrAddress"
								[isRequired]="true"
								placeholder="Street Address or ESIID"
								[options]="esiidsAndAddresses"
								[maxDropdownItemCount]="-1">
							</pk-broker-typeahead>
							<p class="mb-0 invalid-feedback" *ngIf="err.esiidOrAddress">Street Address or ESIID is required</p>
						</div>
					</div>
				</div>
			</form>
		</div>

		<pk-broker-page-warnings [warnings]="warnings"></pk-broker-page-warnings>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" class="btn btn-link" (click)="activeModal.hide()" [disabled]="submitDisabled">Cancel</button>
		<button type="button" class="btn btn-primary" (click)="submit()" [disabled]="submitDisabled">{{ submitText }}</button>
	</ng-template>
</pk-broker-modal>

import { CONSTANTS } from '@pk/powerkioskutils';

import * as moment from 'moment-timezone';

import { Attachment } from './attachment';
import { RfqSession } from './rfq-session';
import { RfqSessionBid } from './rfq-session-bid';
import { CustomRfqSession } from './rfq-session-custom';
import { RfqSessionOptOutReasonType } from './rfq-session-opt-out-reason-type';
import { Supplier } from './supplier';

export class RfqSessionSupplier {

	public id: string;
	public rfqSessionId: string;
	public supplierId: string;
	public note: string;
	public isOptOut: boolean;
	public optOutReasonNote: string;
	public rfqSessionOptOutReasonTypeId: number;
	public rfqSessionExtendAuctionReasonTypeId: number;
	public extendAuctionReasonNote: string;
	public sampleBillRequestDate: Date;
	public loaRequestDate: Date;
	public loeRequestDate: Date;
	public clickedRFPEmailLink: boolean;
	public clickedRFPDashboardLink: boolean;
	public openedBrokerInfo: boolean;
	public downloadedSampleBill: boolean;
	public downloadedLOA: boolean;
	public downloadedLOE: boolean;
	public hasVisitedAuction: boolean;
	public noteAddDate: Date;
	public optOutReasonNoteAddDate: Date;
	public extendAuctionReasonNoteAddDate: Date;
	public loaRequestNote: string;
	public loeRequestNote: string;
	public sampleBillRequestNote: string;
	public pendingAddition: boolean;

	public supplier: Supplier;
	public rfqSession: RfqSession;
	public optOutReasonType: RfqSessionOptOutReasonType;
	public viewSupplierDashboard: CustomRfqSession;
	public bids: RfqSessionBid[];

	constructor(rfqSessionSupplier?: RfqSessionSupplier) {
		Object.assign(this, rfqSessionSupplier);

		this.supplier = this.supplier ? new Supplier(this.supplier) : this.supplier;
		this.rfqSession = this.rfqSession ? new RfqSession(this.rfqSession) : this.rfqSession;
		this.bids = this.bids ? this.bids.map(b => new RfqSessionBid(b)) : this.bids;

		this.noteAddDate = this.noteAddDate ? new Date(moment(this.noteAddDate).format('MMM DD, YYYY hh:mm:ss a')) : this.noteAddDate;
		this.optOutReasonNoteAddDate = this.optOutReasonNoteAddDate
			? new Date(moment(this.optOutReasonNoteAddDate).format('MMM DD, YYYY hh:mm:ss a')) : this.optOutReasonNoteAddDate;
		this.extendAuctionReasonNoteAddDate = this.extendAuctionReasonNoteAddDate
			? new Date(moment(this.extendAuctionReasonNoteAddDate).format('MMM DD, YYYY hh:mm:ss a')) : this.extendAuctionReasonNoteAddDate;

		this.viewSupplierDashboard = this.viewSupplierDashboard
			? new CustomRfqSession(this.viewSupplierDashboard, true)
			: this.viewSupplierDashboard;
	}

	get hasNote(): boolean {
		return !!this.note || !!this.loaRequestNote || !!this.loeRequestNote || !!this.sampleBillRequestNote;
	}

	public setDocumentRequestNotes(attachments: Attachment[]): void {
		if (this.loaRequestDate && !attachments?.some(a => a.attachmentTypeId === CONSTANTS.attachmentTypes.loa)) {
			this.loaRequestNote = 'Requested LOA';
		}

		if (this.loeRequestDate && !attachments?.some(a => a.attachmentTypeId === CONSTANTS.attachmentTypes.loe)) {
			this.loeRequestNote = 'Requested LOE';
		}

		if (this.sampleBillRequestDate && !attachments?.some(a => a.attachmentTypeId === CONSTANTS.attachmentTypes.sampleBill)) {
			this.sampleBillRequestNote = 'Requested Bill';
		}
	}
}

import * as moment from 'moment-timezone';

export class ViewPendingFlowCheck {
	public id: number;
	public supplierId: string;
	public contractId: string;
	public contractNum: string;
	public contractLocationId: string;
	public supplierAccountNumber: string;
	public supplierStatus: string;
	public currentStatus: number;
	public status: number;
	public currentDisplayStatus: string;
	public supplierDropDate: Date;
	public dropDate: Date;
	public supplierStatusDescription: string;
	public supplierReinstatementDate: Date;
	public reinstatementDate: Date;
	public pendingFlowCheckStatusId: number;
	public supplierCustomerName: string;
	public errors: string;
	public units: string;
	public effectiveDate: Date;
	public expirationDate: Date;
	public accountDisplayStatus: string;
	public utilityAccountNums: string;

	public selectedForProcess: boolean;

	constructor(viewPendingFlowCheck?: ViewPendingFlowCheck) {
		Object.assign(this, viewPendingFlowCheck);

		this.supplierDropDate = this.supplierDropDate
			? new Date(moment(this.supplierDropDate).format('MMM DD, YYYY 00:00:00 a')) : this.supplierDropDate;
		this.dropDate = this.dropDate
			? new Date(moment(this.dropDate).format('MMM DD, YYYY 00:00:00 a')) : this.dropDate;
		this.supplierReinstatementDate = this.supplierReinstatementDate
			? new Date(moment(this.supplierReinstatementDate).format('MMM DD, YYYY 00:00:00 a')) : this.supplierReinstatementDate;
		this.reinstatementDate = this.reinstatementDate
			? new Date(moment(this.reinstatementDate).format('MMM DD, YYYY 00:00:00 a')) : this.reinstatementDate;
		this.effectiveDate = this.effectiveDate
			? new Date(moment(this.effectiveDate).format('MMM DD, YYYY 00:00:00 a')) : this.effectiveDate;
		this.expirationDate = this.expirationDate
			? new Date(moment(this.expirationDate).format('MMM DD, YYYY 00:00:00 a')) : this.expirationDate;
	}
}

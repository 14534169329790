<ul>
	<li>
		You can add/delete product plan as you set up the pricing session. If you choose product "fixed all in" then you only need to select the terms for
		each product you add.
	</li>
	<li class="mt-2">
		Start/End date&amp;time are the period that Pricing Session is running. Please make sure to sign the contract on the same day that pricing session
		ends to guarantee the offering rate. You have up to 48 hours to sign the contract, but after the same day, the prices are subject to change based
		on market movement.
	</li>
	<li class="mt-2">
		The Extend Session feature is limited to 2 times per week. If not possible, then you can extend the pricing session to get the refreshed custom
		rates. The rates are subject to change upon extending the pricing session.
	</li>
	<li class="mt-2">Add any special request on the box for "Special instruction."</li>
	<li class="mt-2">
		For most Pricing Sessions, an LOA is required by the supplier to pull up the usage info from the utility. Please send the LOA to be signed from
		"generate LOA" before starting the pricing session.
	</li>
	<li class="mt-2">
		Please use pricing sessions only for large accounts with good load factor. You can learn how to calculate the load factor from our weekly training
		video or from education link to load factor.
	</li>
	<li class="mt-2">You can add multiple locations for a customer within the same utility to start a custom pricing session.</li>
	<li class="mt-2">
		When a Pricing Session is in progress/complete you can click on each arrow under "Bids" to see all the previous or new bids in that product plan.
	</li>
</ul>

<div class="row">
	<div class="col">
		<h2 class="no-underline mb-1 font-size-125 blue-text">Our Market Recommendations</h2>
	</div>
</div>
<div class="row mb-4">
	<div class="col shadow-sm market-recommendations">
		<div class="row">
			<div class="col-12 col-xxl">
				<h3 class="no-underline mb-0 font-size-1" id="marketRecTitle">Consider utilizing layered purchasing strategies.</h3>
				<p id="marketRecCopy">
					Optimize your customer's rate and unlock additional margin potential through a layered purchasing strategy. Learn more about your options by
					contacting <a [href]="'mailto:' + email" class="semibold-text">{{ email }}</a
					>.
				</p>
			</div>
		</div>
	</div>
</div>

import { AgentThemeCategory } from './agent-theme-category';

export class AgentTheme {

	public id: number;
	public agentThemeCategoryId: number;
	public primary: string;
	public secondary: string;
	public tertiary1: string;
	public tertiary2: string;
	public tertiary3: string;
	public logoAttachmentId: string;
	public useParentNameAsCompanyNameForProposal: boolean;

	public agentThemeCategory?: AgentThemeCategory;

	constructor(agentTheme?: AgentTheme) {
		Object.assign(this, agentTheme);
	}
}

import { FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

/** Products must all be unique and have at least one chosen on the form */

export const auctionAtLeastOneSupplierValidator: ValidatorFn = (control: FormGroup<any>): ValidationErrors | null => {
	const supplierControls = control.get('suppliers') as FormArray<any>;
	const otherSupplierControls = control.get('otherSuppliers') as FormArray<any>;
	const suppliers = supplierControls.value.concat(otherSupplierControls.value);

	const atLeastOneSelected = suppliers.some(s => s.supplierSelected);

	return !atLeastOneSelected ? { noSuppliers: true } : null;
};

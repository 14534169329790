import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { CsatToastComponent } from './csat-toast/csat-toast.component';
import { CsatNotificationModalComponent } from './modals/csat-notification-modal/csat-notification-modal.component';
import { CsatSurveyModalComponent } from './modals/csat-survey-modal/csat-survey-modal.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
	],
	declarations: [
		CsatSurveyModalComponent,
		CsatToastComponent,
		CsatNotificationModalComponent,
	],
})
export class CsatSurveyModule { }

import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

import { Rate } from '../models';

@Pipe({
	name: 'filterGreenPercentages',
})
export class FilterGreenPercentagesPipe implements PipeTransform {
	transform(greenPercentages: number[], rates: Rate[]): any {
		const greenPercentageList = _.uniq(rates.map(r => r.greenPercentage));
		return greenPercentages && greenPercentageList ? greenPercentages.filter(g => greenPercentageList.includes(g)) : greenPercentages;
	}
}

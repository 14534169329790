import { Component } from '@angular/core';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { ConfirmationConstructor } from 'src/app/notification/services/notification.service';
import { UserSurvey } from 'src/app/shared/models';
import { CsatSurveyModalComponent } from '../csat-survey-modal/csat-survey-modal.component';

@Component({
	selector: 'pk-broker-csat-notification-modal',
	templateUrl: './csat-notification-modal.component.html',
	styleUrls: ['./csat-notification-modal.component.scss']
})
export class CsatNotificationModalComponent {

	// inputs
	public title: string;
	public body: string;
	public body2: string;
	public confirmationType: ConfirmationConstructor;
	public userSurveyCategoryId: number;
	public csatSurvey: UserSurvey;

	constructor(
		private activeModal: BsModalRef,
		public modalService: BsModalService,
		public graphqlService: GraphqlService,
	) { }

	public close(): void {
		this.activeModal.hide();
	}

	showCsatFormModal() {
		this.close();
		this.graphqlService.updateUserSurvey(this.csatSurvey.id, { clickedInitialLink: true });
		this.modalService.show(CsatSurveyModalComponent, {
			class: 'modal-dialog-centered nps-csat-modal',
			initialState: {
				csatSurvey: this.csatSurvey,
				userSurveyCategoryId: this.userSurveyCategoryId
			}
		});
	}
}

import { State } from './state';

export class CommercialEcl {

	public id: number;
	public accountUuid: string;
	public utilityId: string;
	public serviceTypeId: string;
	public isReserved: boolean;
	public dbaName1: string;
	public dbaName2: string;
	public address1: string;
	public address2: string;
	public city: string;
	public stateId: string;
	public stateShort: string;
	public zipCode: string;
	public serviceAddress: string;
	public billingAddress1: string;
	public billingAddress2: string;
	public billingCity: string;
	public billingStateId: string;
	public billingStateShort: string;
	public billingZipCode: string;
	public billingAddress: string;
	public isUsingDefaultService: boolean;
	public utilityAccountNum: string;
	public agentId: string;
	public note: string;
	public hasAssociatedContract: boolean;
	public annualUsage: number;
	public plc: number;
	public networkPlc: number;
	public loadFactor: number;
	public totalAccounts: number;

	public state: State;
	public billingState: State;

	constructor(commercialEcl: CommercialEcl) {
		Object.assign(this, commercialEcl);
	}
}

export class MarketUpdateRateSummary {

	public todayRate: number;
	public todayRateFmt: string;
	public serviceTypeId: string;
	public stateShort: string;
	public utilityId: string;
	public commodity: string;
	public stateId: string;
	public utility: string;
	public rateClass: string;
	public lastWeekRate: number;
	public lastWeekRateFmt: string;
	public changeInPercent: number;
	public difference: number;
	public differenceFmt: string;
	public units: string;
	public units2: string;

	constructor(marketUpdateRateSummary: MarketUpdateRateSummary) {
		Object.assign(this, marketUpdateRateSummary);
	}
}

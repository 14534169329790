<pk-broker-modal>
	<ng-template #headerTemplate>
		<h5 class="modal-title">Browser Not Supported</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<p>We have detected that you are using Internet Explorer -- this browser is not supported.</p>

		<p>To continue using our platform, please use the latest version of Chrome, Firefox, Edge, Safari, or Opera.</p>
	</ng-template>
</pk-broker-modal>

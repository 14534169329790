import { CONSTANTS, NumberUtils, RateUtils } from '@pk/powerkioskutils';

import * as moment from 'moment-timezone';

import { HelperService } from '../helper.service';
import { Contract } from './contract';

export class ViewContract extends Contract {

	public rate: number;
	public serviceTypeId: string;
	public hasCommercial: boolean;
	public commodity: string;
	public sampleBillRequested: boolean;
	public droppedTermValue: number;
	public rejectedTermValue: number;
	public term: number;
	public annualUsage: number;
	public dbaName: string;
	public agentId: string;
	public agentName: string;
	public contractDate: Date;
	public status: number;
	public customerId: string;
	public parentName: string;
	public contractNum: string;
	public loaRequested: boolean;
	public notifications: string;
	public hasLVM: boolean;
	public displayStatus: string;
	public expirationDate: Date;
	public effectiveDate: Date;
	public auctionEndDate: Date;
	public hasIssueFound: boolean;
	public brotherId: string;
	public brotherName: string;
	public supplierName: string;
	public contractId: string;
	public hasHappyCustomer: boolean;
	public parentId: string;
	public isResidential: boolean;
	public loeRequested: boolean;
	public hasOthers: boolean;
	public underwriterName: string;
	public stateId: string;
	public auctionStartDate: Date;
	public isOnHold: boolean;
	public isRenewal: boolean;
	public previousContractId: string;
	public previousContractNum: string;
	public previousContractRate: number;
	public confirmationDate: string;
	public modifiedDate: string;
	public customerFname: string;
	public customerMiddle: string;
	public customerLname: string;
	public customerPhone: string;
	public customerEmail: string;
	public customerTitle: string;
	public awardDate: Date;
	public stateLong: string;
	public units: string;
	public confirmationQueueStatus: string;
	public confirmationErrorMessage: string;
	public commissionPayPlanName: string;
	public signedAnnualUsage: number;
	public payableAnnualUsage: number;
	public depositRequiredAmount: number;
	public utilityAccountNumList: string;
	public esignIsComplete: boolean;
	public submissionStatus: number;
	public latestDropDate: Date;
	public isNewBrokerAuction: boolean;
	public agentIsNewBroker: boolean;
	public dropDate: string;
	public signIpFraudScore: number;
	public callNoResponseDate: Date;
	public fraudulentDate: Date;
	public hideContractDetailsFromBrother: boolean;
	public isFailedEnrollment: boolean;
	public submissionStatusAutoSubmittedDate: string;

	public updating: boolean;
	public processedRow: boolean;

	public displayContractNum: string;
	public displayAgentName: string;

	constructor(contract: any) {
		super(contract);

		if (this.auctionEndDate && this.status === CONSTANTS.statuses.ainp) {
			setInterval(() => {
				if (moment(this.auctionEndDate) <= moment()) {
					this.status = CONSTANTS.statuses.acomp;
					this.displayStatus = CONSTANTS.statuses[CONSTANTS.statuses.acomp];
				}
			}, 1000);
		}

		this.displayContractNum = HelperService.showAsteriskForContractFirstImpression(Number(this.status), this.isNewBrokerAuction)
			? this.contractNum + '*'
			: this.contractNum;

		this.displayAgentName = HelperService.showAsteriskForBrokerFirstImpression(this.agentIsNewBroker)
			? this.agentName + '*'
			: this.agentName;
	}

	get showDepositRequiredMessage(): boolean {
		return this.status !== CONSTANTS.statuses.confirmed && !!this.depositRequiredAmount;
	}

	get showHoldSubmissionStatus() {
		return this.displayStatus === 'Signed' && this.submissionStatus === CONSTANTS.submissionStatus.onHold;
	}

	get showAutoSubmissionStatus() {
		return this.displayStatus === 'Signed'
			&& this.submissionStatus === CONSTANTS.submissionStatus.submitted
			&& this.submissionStatusAutoSubmittedDate;
	}

	public getContactFullName(): string {
		const customerMiddle = this.customerMiddle && this.customerMiddle.trim() ? this.customerMiddle.trim() + ' ' : '';
		return `${this.customerFname} ${customerMiddle}${this.customerLname}`;
	}

	public getDisplayRate(): string {
		if (([CONSTANTS.statuses.ainp, CONSTANTS.statuses.dr].includes(this.status))
			|| (this.status === CONSTANTS.statuses.acomp && !this.awardDate)) {
			return '';
		}

		// - this only affects the case where an auction contract picks
		// a matrix price, so there is already a buffer margin attached
		// - if status is quote, then don't include buffer margin
		// once it's signed/confirmed/acomp, then it's go back to showing it, which will
		// give it the same effect as if setBufferMargin() was called
		const includeBufferMargin = (this.status === CONSTANTS.statuses.acomp && !!this.awardDate)
			|| this.status === CONSTANTS.statuses.signed
			|| !!this.confirmationDate;

		const rate = NumberUtils.rateToUnits(RateUtils
			.adjustedRateWithTax(Math.round((this.rate + this.getTotalMargin(includeBufferMargin, true, true)) * 1e12) / 1e12, this.stateId,
				this.salesTax, this.supplierId, this.annualUsage),
			this.units);

		return rate.toString();
	}

	public getDisplayAnnualUsage(): string {
		const annualUsage = NumberUtils.amountToUnits(this.annualUsage, this.units);

		return Math.round(annualUsage).toString();
	}

	public getDisplayPayableAnnualUsage(): string {
		const payableAnnualUsage = NumberUtils.amountToUnits(this.payableAnnualUsage, this.units);

		return Math.round(payableAnnualUsage).toString();
	}

	public getDisplayPreviousRate(): string {
		const rate = NumberUtils.rateToUnits(this.previousRate, this.units);

		return rate.toString();
	}
}

export class PendingCommissionTimeLog {
	public id: number;
	public startDate: Date;
	public endDate: Date;
	public addDate: Date;
	public modifiedDate: string;
	public userId: string;

	constructor(pendingCommissionTimeLog?: PendingCommissionTimeLog) {
		Object.assign(this, pendingCommissionTimeLog);
	}
}

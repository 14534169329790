<div
	class="feedback-float"
	id="feedbackDiv"
	*ngIf="user?.agent && !user.agent.parentId && user.agent.isActive"
	[ngClass]="{ full: !isMinimized, minimized: isMinimized }">
	<p class="feedback-minimize" id="feedbackMin" (click)="minimizeFeedback()">
		<fa-icon [icon]="['fas', 'times']"></fa-icon>
	</p>
	<p class="feedback-button">
		<a href="javascript:void(0)" (click)="openFeedback()">
			<fa-icon [icon]="['fas', 'bullhorn']"></fa-icon><br />
			<span class="feedback-btn-text">Feedback</span>
		</a>
	</p>
</div>

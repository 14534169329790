import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

import { HelperService } from '../helper.service';

export const bidAmountValidator: ValidatorFn = (formGroup: FormGroup<any>): ValidationErrors | null => {
	const bidAmountValue = formGroup.get('bidAmount').value;
	let productId = formGroup.get('entity')
		? formGroup.get('entity').value.productId
		: formGroup.get('type').value;

	// to compensate for the fact that create-bid.component uses product.id
	// and auction-details.component uses product for sweet spot products
	if (productId && typeof productId !== 'string') {
		productId = productId.hasOwnProperty('id') ? productId.id : productId.productId;
	}

	const units = formGroup.get('units').value?.text || '';
	const serviceTypeId = formGroup.get('serviceTypeId').value;
	const stateId = formGroup.get('stateId').value;

	if (!bidAmountValue) {
		return null;
	}

	if (isNaN(bidAmountValue)) {
		return { multipleDecimals: true };
	}

	const [minBidAmount, maxBidAmount] = [
		HelperService.getProductMinBidAmount(productId, serviceTypeId, units),
		HelperService.getProductMaxBidAmount(productId, serviceTypeId, stateId, units),
	];

	if (Number(bidAmountValue) <= Number(minBidAmount) || Number(bidAmountValue) >= Number(maxBidAmount)) {
		return { bidAmountMinMax: true };
	}

	return null;
};

import * as moment from 'moment-timezone';

import { RfqSessionProduct } from './rfq-session-product';
import { Supplier } from './supplier';

export class RfqSessionBid {

	public id: string;
	public bandwidthPercentage: number;
	public salesTax: number;
	public supplierId: string;
	public ipAddress: string;
	public billingMethod: string;
	public rfqSessionId: string;
	public debug: string;
	public addDate: string | Date;
	public amount: number;
	public addDate2: string | Date;
	public rfqSessionProductId: string;
	public isGRTIncluded: boolean;
	public units: string;
	public bufferMargin: number;

	public rfqSessionProduct: RfqSessionProduct;
	public supplier: Supplier;

	public removing: boolean;

	constructor(rfqSessionBid?: RfqSessionBid) {
		Object.assign(this, rfqSessionBid);

		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
		this.addDate2 = this.addDate2 ? new Date(moment(this.addDate2).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate2;
	}

	get rate(): number {
		return this.amount + this.bufferMargin;
	}
}

export class Zone {

	public name: string;
	public description: string;
	public stateId: string;
	public serviceTypeId: string;
	public isActive: boolean;

	constructor(zone: Zone) {
		Object.assign(this, zone);
	}
}

import { EmailAccount } from './email-account';
import { EmailCriteriaType } from './email-criteria-type';

export class EmailCriteria {
	public emailCriteriaId: string;
	public attachment: string;
	public folderName: string;
	public addDate: string;
	public subject: string;
	public modifiedDate: string;
	public from: string;
	public emailAccountId: string;
	public isActive: boolean;
	public name: string;
	public currentIndex: number;
	public supplierId: string;
	public emailCriteriaTypeId: number;

	public emailAccount: EmailAccount;
	public emailCriteriaType: EmailCriteriaType;

	constructor(emailCriteria?: EmailCriteria) {
		Object.assign(this, emailCriteria);
	}
}

import { LendingModuleSettingQuickPayMarginThreshold } from './lending-module-setting-margin-threshold';
import { LendingModuleSettingQuickPayUpfrontThreshold } from './lending-module-setting-upfront-threshold';

export class LendingModuleSetting {

	public id: number;
	public borrowingRate: number;
	public baseFee: number;
	public minRate: number;
	public maxRate: number;
	public maxUpfrontDropRatio: number;
	public maxUpfrontPayment: number;
	public maxAdvanceMonths: number;
	public defaultBrokerDropRatio: number;
	public newBrokerMaxFlowedTermValue: number;
	public newBrokerMaxTotalTermValue: number;
	public newBrokerMaxActiveMonths: number;
	public defaultMaxQuickPayLag: number;

	public quickPayMarginThresholds: LendingModuleSettingQuickPayMarginThreshold[];
	public quickPayUpfrontThresholds: LendingModuleSettingQuickPayUpfrontThreshold[];

	constructor(lendingModuleSetting: LendingModuleSetting) {
		Object.assign(this, lendingModuleSetting);
	}
}

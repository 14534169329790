export class RateMatrixBenchmark {

	public id: number;
	public serviceTypeId: string;
	public stateId: string;
	public utilityId: string;
	public zone: string;
	public rateClass: string;
	public minUsage: number;
	public maxUsage: number;
	public effectiveDate: string | Date;
	public minRate: number;
	public maxRate: number;
	public avgRate: number;
	public displayMinRate: number;
	public displayMaxRate: number;
	public displayAvgRate: number;
	public addDate: string;

	constructor(rateMatrixBenchmark?: RateMatrixBenchmark) {
		Object.assign(this, rateMatrixBenchmark);

		this.displayAvgRate = this.avgRate ? this.avgRate : null;
		this.displayMinRate = this.minRate ? this.minRate : null;
		this.displayMaxRate = this.maxRate ? this.maxRate : null;
		if (this.effectiveDate && typeof this.effectiveDate === 'string') {
			const dateParts = this.effectiveDate.split('-');
			this.effectiveDate = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2].split('T')[0]), 0, 0, 0);
		}
	}
}

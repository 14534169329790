import { Role } from './role';

export class SecurityRule {

	public securityRuleId: string;
	public whiteList: string;
	public secureList: string;
	public roles: string;
	public permissions: string;
	public redirect: string;
	public match: string;
	public sortOrder: number;
	public isActive: boolean;
	public addDate: string;
	public modifiedDate: string;

	public role: Role;

	constructor(securityRule?: SecurityRule) {
		Object.assign(this, securityRule);
	}
}

<pk-broker-modal>
	<ng-template #headerTemplate>
		<h5 class="modal-title" id="rejectDialogHeader">{{ contract.displayContractNum }} has been {{ contract.displayStatus }}</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<p *ngIf="!contract.showDepositRequiredMessage">
			{{ contract.rejectReason ? contract.rejectReason : 'Sample Bill required.' }}
		</p>
		<p *ngIf="contract.showDepositRequiredMessage" class="deposit-required">
			A {{ contract.depositRequiredAmount | currency: 'USD':'symbol':'1.0-0' }} deposit is required in order to complete this contract.
		</p>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" class="btn btn-secondary" (click)="activeModal.hide()">Close</button>
	</ng-template>
</pk-broker-modal>

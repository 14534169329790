<div class="modal-header">
	<h5 class="modal-title">Select a Hero Image</h5>
	<button type="button" class="close" (click)="close()">
		<span aria-hidden="true">
			<fa-icon [icon]="['fas', 'times']" aria-hidden="true"></fa-icon>
		</span>
	</button>
</div>
<div class="modal-body pb-0">
	<pk-broker-loader [show]="loading"></pk-broker-loader>
	<div class="container-fluid" *ngIf="!loading">
		<form [formGroup]="formGroup">
			<div class="row">
				<div class="col">
					<p>Select an image from our library or upload one of your own.</p>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<h4 class="add-underline">Image Library</h4>
				</div>
			</div>
			<div class="row mb-4">
				<div class="col-6 col-md-3 mb-2" *ngFor="let standardImage of standardImages">
					<a
						href="javascript:void(0)"
						class="hero-img-pick"
						(click)="setStandardImage(standardImage)"
						[class.active]="form.standardImage.value?.includes(standardImage.name)">
						<img src="https://static.powerkiosk.com/email/ce/{{ standardImage.name }}" class="img-fluid" [alt]="standardImage.alt" />
					</a>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<h4 class="add-underline">Upload your own</h4>
					<p>
						Please use a JPG or PNG sized 600x300.
						<a href="https://static.powerkiosk.com/test/pkc-11735/assets/img/email-hero-template.png" target="_blank" class="semibold-text"
							>Download our template</a
						>
						for the bottom angles.
					</p>
				</div>
			</div>
			<div class="row">
				<div class="col form-element-container" [class.invalid]="err.group">
					<div class="input-group">
						<pk-broker-file-selector
							formControlName="customImage"
							accept="image/*"
							[formData]="{ attachmentTypeId: CONSTANTS.attachmentTypes.image }"
							title="Edit Email"
							helperText="PNG or JPG, 600x300 pixels">
						</pk-broker-file-selector>
						<p class="mb-0 invalid-feedback" *ngIf="err.group">
							<ng-container *ngIf="err.group.required">Image is required</ng-container>
						</p>
					</div>
				</div>
			</div>
		</form>

		<pk-broker-page-warnings [warnings]="warnings"></pk-broker-page-warnings>
	</div>
</div>
<div class="modal-footer justify-content-end">
	<button type="button" class="btn btn-primary" (click)="submit()" [disabled]="submitDisabled">{{ submitText }}</button>
</div>

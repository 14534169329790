import { Component, OnInit } from '@angular/core';

import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { Agent, AgentBiddingStats, RfqSessionSupplier } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-agent-auction-info-modal',
	templateUrl: './agent-auction-info-modal.component.html',
	styleUrls: ['./agent-auction-info-modal.component.scss'],
})
export class AgentAuctionInfoModalComponent extends AbstractPageForm implements OnInit {

	// input
	public rfqSessionSupplierId: string; // from bidding session
	public agentId: string;

	public stats: AgentBiddingStats;
	public totalAuctionsClosed: number;
	public agent: Agent;
	public sinceDate: moment.Moment;

	constructor(
		private toastrService: ToastrService,
		private graphqlService: GraphqlService,
	) { super(); }

	ngOnInit(): void {
		this.sinceDate = moment().subtract(1, 'year');
		this.getBrokerBidInfo();
	}

	get title() {
		return this.loggedInUser && this.agent && (this.loggedInUser.isAdmin || this.loggedInUser.agentId)
			? this.agent.displayName
			: 'Broker Info';
	}

	get timeWithName() {
		return this.loggedInUser && this.agent && this.agent.parent && (this.loggedInUser.isAdmin || this.loggedInUser.agentId)
			? this.agent.parent.displayName
			: 'Power Kiosk';
	}

	private async getBrokerBidInfo(): Promise<void> {
		try {
			if (this.rfqSessionSupplierId) {
				this.graphqlService.updateRfqSessionSupplier(this.rfqSessionSupplierId, {
					openedBrokerInfo: true,
				} as RfqSessionSupplier);
			}

			const result = await this.graphqlService.getBrokerBidInfo(this.agentId);
			this.agent = new Agent(result.data.agent);
			this.stats = this.agent.stats;
			this.totalAuctionsClosed = _.sumBy(this.stats.auctionsClosed, s => s.numAuctionsClosed);
		} catch (e) {
			this.toastrService.warning(
				'There was a problem loading the broker info. We have been notified and are working to fix the issue. '
				+ 'Please check back again in 30 minutes.', 'Broker Info');
		}
		this.loading = false;
	}

	public getAuctionsClosed(serviceTypeId: string): number {
		if (!this.stats || !this.stats.auctionsClosed || !this.stats.auctionsClosed.length) { return 0; }
		const auctionsClosed = this.stats.auctionsClosed.find(a => a.serviceTypeId === serviceTypeId);
		return auctionsClosed ? auctionsClosed.numAuctionsClosed : 0;
	}
}

<div class="home-page-takeover-modal">
	<pk-broker-modal
		customFooterClass="justify-content-center"
		[customHeaderStyle]="{ padding: 0, 'justify-content': 'center' }"
		[customCloseButtonStyle]="{ 'margin-top': 0, 'margin-right': 0 }"
		customCloseButtonIcon="circle-xmark"
		[useDuotoneCloseButtonIcon]="true"
		[showFooter]="popUp.callToActionDestinationUrl">
		<ng-template #headerTemplate>
			<img [src]="environment.baseUrl + '/attachments/' + popUp.imageAttachmentId + '/view?access_token=' + environment.apiKey" class="img-fluid" />
		</ng-template>
		<ng-template #bodyTemplate>
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<h5>{{ popUp.headline }}</h5>
						<div [innerHtml]="popUp.content"></div>
					</div>
				</div>
			</div>
		</ng-template>
		<ng-template #footerTemplate>
			<button type="button" class="btn btn-secondary" (click)="submit()">{{ popUp.callToActionButtonText }}</button>
		</ng-template>
	</pk-broker-modal>
</div>

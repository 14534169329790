import { Contract } from './contract';
import { Note } from './note';
import { PurchasingLayerStrip } from './purchasing-layer-strip';

export class PurchasingLayer {

	public id: number;
	public defensiveSensitivityPercentage: number;
	public opportunistSensitivityPercentage: number;
	public defensiveQuantityPercentage: number;
	public opportunistQuantityPercentage: number;
	public targetPrice: number;
	public deletedAt: Date;

	public contract: Contract;
	public strips: PurchasingLayerStrip[];
	public notes: Note[];

	constructor(purchasingLayer?: PurchasingLayer) {
		Object.assign(this, purchasingLayer);
	}
}

import { State } from './state';
import { Utility } from './utility';

export class UtilityState {
	public utilityId: string;
	public stateId: string;

	public utility: Utility;
	public state: State;
}

import * as moment from 'moment-timezone';

export class ViewAgentSalesReportContract {
	public contractId: string;
	public contractNum: string;
	public customerId: string;
	public dbaName: string;
	public agentId: string;
	public agentName: string;
	public brotherId: string;
	public isResidential: boolean;
	public serviceTypeId: string;
	public commodity: string;
	public annualUsage: number;
	public units: string;
	public effectiveDate: Date;
	public term: number;
	public confirmationDate: Date;
	public supplierPaymentDate: Date;
	public supplierPayment: number;

	constructor(viewAgentSalesReportContract: ViewAgentSalesReportContract) {
		Object.assign(this, viewAgentSalesReportContract);

		this.effectiveDate = this.effectiveDate
			? new Date(moment(this.effectiveDate).format('MMM DD, YYYY 00:00:00 a')) : this.effectiveDate;
		this.confirmationDate = this.confirmationDate
			? new Date(moment(this.confirmationDate).format('MMM DD, YYYY 00:00:00 a')) : this.confirmationDate;
		this.supplierPaymentDate = this.supplierPaymentDate
			? new Date(moment(this.supplierPaymentDate).format('MMM DD, YYYY 00:00:00 a')) : this.supplierPaymentDate;
	}
}

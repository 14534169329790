import * as moment from 'moment-timezone';

import { FeedbackStatus } from './feedback-status';
import { FeedbackType } from './feedback-type';
import { Supplier } from './supplier';
import { Ticket } from './ticket';
import { User } from './user';

export class Feedback {

	public id: number;
	public contractId: string;
	public supplierId: string;
	public userId: string;
	public rating: number;
	public body: string;
	public appId: number;
	public addDate: Date;
	public page: string;
	public feedbackTypeId: number;
	public isReviewed: boolean;
	public canContact: boolean;
	public url: string;
	public ticketId: number;

	public user: User;
	public supplier: Supplier;
	public type: FeedbackType;
	public ticket: Ticket;

	public status: string;
	public feedbackStatuses: FeedbackStatus[];

	constructor(feedback?: Feedback) {
		Object.assign(this, feedback);

		this.addDate = this.addDate ? moment(feedback.addDate).toDate() : this.addDate;
		this.user = this.user ? new User(this.user) : this.user;
		this.type = this.type ? new FeedbackType(this.type) : this.type;
		this.ticket = this.ticket ? new Ticket(this.ticket) : this.ticket;

		this.feedbackStatuses = [
			{
				text: 'Unresolved', icon: 'exclamation-circle', colorClass: 'red',
				statusCheck: (ticket: Ticket) => !ticket,
			},
			{
				text: 'In Progress', icon: 'clock', colorClass: '',
				statusCheck: (ticket: Ticket) => ticket && ticket.ticketStatusId !== 1 && ticket.ticketStatusId !== 5,
			},
			{
				text: 'Resolved', icon: 'check-circle', colorClass: 'dark-gray',
				statusCheck: (ticket: Ticket) => ticket && ticket.ticketStatusId === 5,
			},
		];
	}

	public getStatus(): FeedbackStatus {
		return this.feedbackStatuses.find(f => f.statusCheck(this.ticket));
	}
}

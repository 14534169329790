export class EmailAnalyticsDetailRow {

	public id: string;
	public groupName: string;
	public recipientType: string;
	public numEmailTypes: number;
	public detailType: string;
	public name: string;
	public openRate: number;
	public clickRate: number;
	public unsubscribeRate: number;
	public signRate: number;

	constructor(emailAnalyticsDetailRow?: EmailAnalyticsDetailRow) {
		Object.assign(this, emailAnalyticsDetailRow);
	}
}

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { FileSelectorComponent } from 'src/app/shared/components/form/file-selector/file-selector.component';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';
import { Attachment, CommentView, ContactMethod, Ticket, TicketCategory, User } from '../../../shared/models';

@Component({
	selector: 'pk-broker-comment-block',
	templateUrl: './comment-block.component.html',
	styleUrls: ['./comment-block.component.scss'],
})
export class CommentBlockComponent extends AbstractPageForm {

	@ViewChild(FileSelectorComponent) fileSelector: FileSelectorComponent;

	@Input() comment: CommentView;
	@Input() ticket: Ticket;
	@Input() ticketCategories: TicketCategory[];
	@Input() users: User[];
	@Input() contactMethods: ContactMethod[];

	@Output() submitted: EventEmitter<any> = new EventEmitter<any>();

	public isEditingComment = false;

	constructor(
		private toastrService: ToastrService,
		private fb: FormBuilder,
		private graphqlService: GraphqlService,
	) {
		super();
		this.submitText = 'Edit';
		this.submittingText = 'Saving...';
	}

	public async loadPageData(): Promise<void> { }

	public getForm() {
		return this.fb.group({
			isInternal: this.comment.isInternal,
			body: [this.comment.body, [Validators.required]],
			attachments: this.fb.control(this.comment.attachments as Attachment[]),
		});
	}

	protected async onFormSubmitted() {
		if (this.securityService.isImpersonating) {
			this.toastrService
				.warning('You are still impersonating, please logout and log back in so you can edit this ticket.', 'Tickets');
			return;
		}
		await this.graphqlService.updateTicketComment(this.comment.id, {
			isInternal: this.form.isInternal.value,
			body: this.form.body.value,
		});
		await this.fileSelector.applyChanges({
			ticketId: String(this.ticket.id),
			ticketCommentId: String(this.comment.id),
			attachmentTypeId: this.CONSTANTS.attachmentTypes.document,
		});
		this.submitted.emit(true);
		this.toggleEditComment();

		this.toastrService.success('Successfully Saved Comment', 'Tickets');
	}

	public matchesUser(info: CommentView): boolean {
		return (info.currUserId === info.userId) && !!info.id;
	}

	public isCommentEditable(info: CommentView): boolean {
		return (this.matchesUser(info) && info.id !== -1 && (info?.isEditable || this.loggedInUser.isAdmin));
	}

	public toggleEditComment() {
		this.isEditingComment = !this.isEditingComment;
		this.reloadForm();
	}

	public getUserName(info: CommentView): string {
		if (!this.loggedInUser.isAdmin && info.userIsAdmin) {
			return this.environment.powerKioskSupportTeamName;
		}

		return info.user;
	}
}

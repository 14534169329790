<pk-broker-loader [show]="loading"></pk-broker-loader>
<div class="text-center" *ngIf="!rfqSession && !loading">
	<strong>Sorry no data found for requested Pricing Session</strong>
</div>
<pk-broker-page-warnings [warnings]="warnings"></pk-broker-page-warnings>
<form [formGroup]="formGroup" *ngIf="rfqSession && !loading">
	<pk-broker-loader [show]="choosingMarketSuggestion" text="Saving selection..." extraClasses="mb-3"></pk-broker-loader>
	<pk-broker-auction-market-suggestions
		*ngIf="!choosingMarketSuggestion && (rfqSession.marketSuggestions.length > 1 || showMarketSuggestionSuccess)"
		[rfqSession]="rfqSession"
		[showMarketSuggestionSuccess]="showMarketSuggestionSuccess"
		(selectedMarketSuggestion)="onSelectedMarketSuggestion($event)"></pk-broker-auction-market-suggestions>
	<ng-container *ngIf="startValidation">
		<div class="alert alert-warning" *ngIf="formGroup.errors?.noSuppliers">At least one supplier is required</div>
		<div class="alert alert-warning" *ngIf="formGroup.errors?.productAmount">At least one product is required.</div>
		<div class="alert alert-warning" *ngIf="formGroup.errors?.productUnique">All products must be unique.</div>
	</ng-container>
	<div class="row">
		<div class="col-md-12 mb-4">
			<div class="card px-2 pt-2 pb-1">
				<div class="row">
					<div class="col-sm-3" *ngIf="loggedInUser.isAdmin">
						<div class="form-element-container">
							<label class="title required font-weight-bold" for="isOnHold">On Hold</label>
							<pk-broker-form-dropdown [isRequired]="true" formControlName="isOnHold" [options]="[true, false]" [textMap]="yesNoTextMap">
							</pk-broker-form-dropdown>
						</div>
					</div>
					<div class="col-sm-3">
						<div class="form-element-container">
							<label class="title font-weight-bold">Max Bids per Product</label>
							<ng-container *ngIf="showOperationalView">
								<input type="number" class="form-control" formControlName="maxBids" />
							</ng-container>
							<ng-container *ngIf="!showOperationalView">
								<p class="value mb-0">{{ rfqSession.maxBids }}</p>
							</ng-container>
						</div>
					</div>
					<div class="col-sm-6">
						<div class="form-element-container d-inline-block ml-4">
							<label class="title font-weight-bold">End</label>
							<p class="value mb-0">
								{{ rfqSession.formatEndDate(isSupplierUser) }} CT
								<a
									*ngIf="![CONSTANTS.statuses.confirmed, CONSTANTS.statuses.dropped].includes(rfqSession.contract.status)"
									href="javascript:void(0)"
									class="ml-2 font-weight-bold"
									(click)="showAuctionExtend()"
									[tooltip]="getExtendSessionMsg()"
									container="body">
									Extend Session
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12 mb-4">
			<div class="modal-table table-responsive">
				<table class="table table-sm table-bordered table-gray">
					<thead>
						<tr>
							<th class="w-75">Bids</th>
							<th>
								Invited Suppliers
								<a
									*ngIf="loggedInUser.hasPermission('auction-send-supplier-message')"
									href="javascript:void(0)"
									class="d-inline float-right"
									title="Send Message"
									(click)="showAuctionSendMessageModal()">
									<fa-icon [classes]="['mb-0']" [icon]="['fas', 'paper-plane']"></fa-icon>
								</a>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td class="pt-2 valign-top">
								<table class="table table-sm table-bordered table-white">
									<thead>
										<tr class="bg-white">
											<th class="w-33">{{ rfqSession.contract.serviceType.name }} Product(s)</th>
											<th class="w-10">Green %</th>
											<th>Best Bid</th>
											<th class="w-15">Best Bid Supplier</th>
											<th *ngIf="rfqSession.contract.serviceTypeId === NATURAL_GAS_ID">Initial Fixed Usage</th>
											<th *ngIf="loggedInUser.hasPermission('auction-remove-product')">&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										<ng-container *ngFor="let rfqSessionProduct of rfqSessionProducts">
											<tr class="product2">
												<td class="valign-top">
													<div
														class="collapse-group collapsed product"
														style="cursor: pointer"
														[ngClass]="{ collapsed: rfqSessionProduct.shown }"
														(click)="rfqSessionProduct.shown = !rfqSessionProduct.shown">
														<fa-icon [icon]="['fas', 'angle-right']" [rotate]="rfqSessionProduct.shown ? '90' : ''" class="font-weight-bold mr-2">
														</fa-icon>
														<span container="body" tooltip="Click here to see bid history">
															{{ rfqSessionProduct.description }}
														</span>
													</div>
												</td>
												<td class="valign-top">{{ rfqSessionProduct.greenPercentage || 0 }}%</td>
												<td class="valign-top">
													<span>
														{{
															(rfqSessionProduct.bestBids && rfqSessionProduct.bestBids.length
																? (rfqSessionProduct.bestBids[0].bestBid | convertBidUnits: rfqSessionProduct.bestBids[0].units)
																: 0.0
															) | number: '1.6-6'
														}}
														{{ rfqSessionProduct.bestBids && rfqSessionProduct.bestBids.length ? rfqSessionProduct.bestBids[0].units : '' }}
													</span>
													<div class="cell-highlight collapse" [ngClass]="{ show: rfqSessionProduct.shown }">
														<p class="m-0 p-0" *ngFor="let bid of rfqSessionProduct.bids">
															{{ bid.rate + rfqSession.contract.getTotalMargin(false, true, false) | convertBidUnits: bid.units | number: '1.6-6' }}
															{{ bid.units }} &#64; {{ bid.addDate | date: environment.dateTimeFormat }} CT
														</p>
														<ng-container *ngIf="!rfqSessionProduct.bids || !rfqSessionProduct.bids.length">N/A</ng-container>
													</div>
												</td>
												<td class="valign-top">
													<span>{{ (rfqSessionProduct.bestBids?.length ? rfqSessionProduct.bestBids[0].supplierName : 'N/A') | shrink: 19 }}</span>
													<div class="cell-highlight collapse" [ngClass]="{ show: rfqSessionProduct.shown }">
														<p class="m-0 p-0" *ngFor="let bid of rfqSessionProduct.bids">
															{{ bid.supplier.name | shrink: 19 }}
														</p>
														<ng-container *ngIf="!rfqSessionProduct.bids?.length">N/A</ng-container>
													</div>
												</td>
												<td class="valign-top" *ngIf="rfqSession.contract.serviceTypeId === NATURAL_GAS_ID">
													{{ rfqSessionProduct.initialFixedUsage || 0 }}%
												</td>
												<td *ngIf="loggedInUser.hasPermission('auction-remove-product')">
													<a href="javascript:void(0)" class="removeProduct2" (click)="removeRfqSessionProduct(rfqSessionProduct)">
														<fa-icon [icon]="['fas', 'times-circle']" [classes]="['text-danger', 'mb-0']" size="lg"></fa-icon>
													</a>
												</td>
											</tr>
										</ng-container>
									</tbody>
								</table>
								<div class="footer text-left">
									<button
										*ngIf="loggedInUser.hasPermission('view-session-info-data')"
										href="javascript:void(0)"
										class="btn btn-link p-0 ml-2 mb-2"
										tooltip="Downloads all the contract and customer information into an excel file."
										container="body"
										(click)="downloadSessionBidData()"
										[disabled]="sessionBidDataButtonDisabled">
										<fa-icon [icon]="['fas', 'download']"></fa-icon> {{ sessionBidDataButtonText }}
									</button>
									<a
										*ngIf="
											hasBestBid &&
											![CONSTANTS.statuses.confirmed, CONSTANTS.statuses.dropped].includes(rfqSession.contract.status) &&
											!isEnterpiseAgent
										"
										(click)="goCreateQuote()"
										style="color: white !important"
										class="btn btn-success px-4 ml-2"
										id="btnCreateQuote">
										Create Quote
									</a>
								</div>
							</td>
							<td class="pt-2 valign-top">
								<p class="supplier m-0 p-0" *ngFor="let rfqSessionSupplier of rfqSession.suppliers; let i = index">
									<span style="flex-grow: 1">
										<ng-container *ngIf="loggedInUser.hasPermission('auction-enter-manual-bids')">
											<a
												style="color: #0000ff"
												class="supplier-name"
												href="javascript:void(0)"
												[ngClass]="{ 'line-through': rfqSessionSupplier.isOptOut }"
												(click)="showAuctionSupplierCreateBids(rfqSessionSupplier)">
												{{ rfqSessionSupplier.supplier.name }}
											</a>
										</ng-container>
										<ng-container *ngIf="!loggedInUser.hasPermission('auction-enter-manual-bids')">
											<span
												[ngClass]="{ 'line-through': rfqSessionSupplier.isOptOut }"
												[attr.title]="rfqSessionSupplier.supplier.name + rfqSessionSupplier.isOptOut ? ' - Opt out' : ''">
												{{ rfqSessionSupplier.supplier.name }}
											</span>
										</ng-container>
										<ng-container *ngIf="rfqSessionSupplier.isOptOut">
											<span class="text-muted pl-1">(Opt Out)</span>
										</ng-container>
										({{ rfqSessionSupplier.bids?.length ?? 0 }} bids
										<a
											*ngIf="loggedInUser.isAdmin && rfqSessionSupplier.bids?.length"
											style="margin-left: 5px"
											href="javascript:void(0)"
											(click)="showAuctionSupplierBids(rfqSessionSupplier)">
											<fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon> </a
										>)
									</span>
									<ng-container *ngIf="loggedInUser.isAdmin">
										<a
											*ngIf="!rfqSessionSupplier.loading && !rfqSessionSupplier.isOptOut"
											href="javascript:void(0)"
											class="d-inline"
											[attr.title]="'Opt-Out ' + rfqSessionSupplier.supplier.name"
											(click)="showOptOutModal(rfqSessionSupplier)">
											<fa-icon [icon]="['fas', 'user-slash']" [classes]="['text-danger']" size="sm"></fa-icon>
										</a>
										<a *ngIf="rfqSessionSupplier.loading && !rfqSessionSupplier.isOptOut">
											<fa-icon [icon]="['fas', 'spinner']" [spin]="true" size="sm"></fa-icon>
										</a>
									</ng-container>
									<ng-container *ngIf="rfqSession.contract.status !== 95 && loggedInUser.hasPermission('auction-delete-supplier')">
										<a
											*ngIf="!rfqSessionSupplier.loading"
											href="javascript:void(0)"
											class="d-inline"
											[attr.title]="'Remove ' + rfqSessionSupplier.supplier.name"
											(click)="removeRfqSessionSupplier(rfqSessionSupplier, i)">
											<fa-icon [icon]="['fas', 'times-circle']" [classes]="['text-danger']" size="sm"></fa-icon>
										</a>
										<a *ngIf="rfqSessionSupplier.loading"><fa-icon [icon]="['fas', 'spinner']" [spin]="true" size="sm"></fa-icon></a>
										<div class="clearfix"></div>
									</ng-container>
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<ng-container *ngIf="loggedInUser.hasPermission('auction-update-session')">
		<div class="row">
			<div class="col-sm-12 mb-4">
				<div class="modal-table table-responsive" style="overflow-x: unset">
					<table class="table table-sm table-bordered table-gray">
						<thead>
							<tr>
								<th class="w-75">{{ rfqSession.contract.serviceType.name }} Products</th>
								<th>Other Suppliers</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="pt-2 valign-top">
									<div class="modal-table table-responsive" style="overflow-x: unset">
										<table class="table table-sm table-bordered table-white">
											<thead>
												<tr class="bg-white">
													<th width="250">Type</th>
													<th>Term</th>
													<th>Green %</th>
													<ng-container *ngIf="rfqSession.contract.serviceTypeId === ELECTRICITY_ID && hasBlockProduct">
														<th>Lag</th>
														<th>Block Type</th>
														<th width="100">Block Size</th>
													</ng-container>
													<th *ngIf="rfqSession.contract.serviceTypeId === NATURAL_GAS_ID">Initial Fixed Usage</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												<ng-container formArrayName="products" *ngFor="let product of form.products; let i = index">
													<tr class="product" [formGroupName]="i" *ngIf="!product.hidden.value">
														<td>
															<div class="form-element-container mb-0">
																<pk-broker-form-dropdown
																	formControlName="type"
																	[options]="products"
																	textKey="name"
																	valueKey="id"
																	(selection)="productChanged(product)">
																</pk-broker-form-dropdown>
															</div>
														</td>
														<td>
															<div class="form-element-container mb-0">
																<pk-broker-form-dropdown formControlName="term" [options]="terms" [textMap]="termTextMap"></pk-broker-form-dropdown>
															</div>
														</td>
														<td>
															<div class="form-element-container mb-0">
																<pk-broker-form-dropdown
																	formControlName="greenPercentage"
																	[options]="greenPercentages"
																	[textMap]="greenPercentageTextMap">
																</pk-broker-form-dropdown>
															</div>
														</td>
														<ng-container *ngIf="rfqSession.contract.serviceTypeId === ELECTRICITY_ID">
															<td *ngIf="!product.lag.disabled">
																<div class="form-element-container mb-0">
																	<pk-broker-form-dropdown
																		formControlName="lag"
																		[options]="lags"
																		[textMap]="lagTextMap"
																		nullOption="- Select -"
																		[nullOptionSelectable]="false">
																	</pk-broker-form-dropdown>
																</div>
															</td>
															<td *ngIf="product.lag.disabled && hasBlockProduct"></td>
															<td *ngIf="!product.blockType.disabled">
																<div class="form-element-container mb-0">
																	<pk-broker-form-dropdown
																		formControlName="blockType"
																		[options]="blockTypes"
																		textKey="name"
																		valueKey="name"
																		nullOption="- Select -"
																		[nullOptionSelectable]="false">
																	</pk-broker-form-dropdown>
																</div>
															</td>
															<td *ngIf="product.blockType.disabled && hasBlockProduct"></td>
															<td *ngIf="!product.blockSize.disabled">
																<div class="form-element-container mb-0">
																	<input type="text" class="form-control min-width" formControlName="blockSize" maxlength="10" />
																</div>
															</td>
															<td *ngIf="product.blockSize.disabled && hasBlockProduct"></td>
														</ng-container>
														<td *ngIf="rfqSession.contract.serviceTypeId === NATURAL_GAS_ID">
															<div class="form-element-container mb-0">
																<pk-broker-form-dropdown
																	formControlName="initialFixedUsage"
																	[options]="initialFixedUsages"
																	[textMap]="initialFixedUsageTextMap">
																</pk-broker-form-dropdown>
															</div>
														</td>
														<td>
															<a href="javascript:void(0)" class="removeProduct" (click)="form.products.remove(product)">
																<fa-icon [icon]="['fas', 'times-circle']" [classes]="['text-danger', 'mb-0']" size="lg"></fa-icon>
															</a>
														</td>
													</tr>
												</ng-container>
											</tbody>
										</table>
									</div>
									<div class="footer">
										<a class="btn btn-link py-0 px-2 mb-2" id="addProduct" href="javascript:void(0)" (click)="addProduct()">
											<fa-icon [icon]="['fas', 'plus-circle']"></fa-icon> Add a row
										</a>
									</div>
								</td>
								<td class="pt-2 valign-top enhance-checkboxes" *ngIf="loggedInUser?.isAdmin">
									<label class="font-weight-bold">Outside Usage Threshold</label><br />
									<ng-container formArrayName="otherSuppliers" *ngFor="let supplier of form.otherSuppliers; let i = index">
										<div class="form-element-container p-0 mt-0" [formGroupName]="i" *ngIf="!supplier.hidden.value">
											<div class="custom-control custom-checkbox">
												<input
													class="custom-control-input"
													type="checkbox"
													id="suppliers-{{ supplier.supplierId.value }}"
													formControlName="supplierSelected" />
												<label class="custom-control-label" for="suppliers-{{ supplier.supplierId.value }}">
													{{ supplier.name.value }}
												</label>
											</div>
										</div>
									</ng-container>
									<label class="font-weight-bold">Opted-Out Suppliers</label><br />
									<ng-container formArrayName="optedOutSuppliers" *ngFor="let supplier of form.optedOutSuppliers; let i = index">
										<div class="form-element-container p-0 mt-0" [formGroupName]="i" *ngIf="!supplier.hidden.value">
											<div class="custom-control custom-checkbox">
												<input
													class="custom-control-input"
													type="checkbox"
													id="suppliers-{{ supplier.supplierId.value }}"
													formControlName="supplierSelected" />
												<label class="custom-control-label" for="suppliers-{{ supplier.supplierId.value }}">
													{{ supplier.name.value }}
												</label>
											</div>
										</div>
									</ng-container>
									<label class="font-weight-bold">Not Invited Suppliers</label><br />
									<ng-container formArrayName="suppliers" *ngFor="let supplier of form.suppliers; let i = index">
										<div class="form-element-container p-0 mt-0" [formGroupName]="i" *ngIf="!supplier.hidden.value">
											<div class="custom-control custom-checkbox">
												<input
													class="custom-control-input"
													type="checkbox"
													id="suppliers-{{ supplier.supplierId.value }}"
													formControlName="supplierSelected" />
												<label class="custom-control-label" for="suppliers-{{ supplier.supplierId.value }}">
													{{ supplier.name.value }}
												</label>
											</div>
										</div>
									</ng-container>
								</td>
								<td class="pt-2 valign-top enhance-checkboxes" *ngIf="!loggedInUser?.isAdmin">
									<ng-container formArrayName="suppliers" *ngFor="let supplier of form.suppliers; let i = index">
										<div class="form-element-container p-0 mt-0" [formGroupName]="i" *ngIf="!supplier.hidden.value">
											<div class="custom-control custom-checkbox">
												<input
													class="custom-control-input"
													type="checkbox"
													id="suppliers-{{ supplier.supplierId.value }}"
													formControlName="supplierSelected" />
												<label class="custom-control-label" for="suppliers-{{ supplier.supplierId.value }}">
													{{ supplier.name.value }}
												</label>
											</div>
										</div>
									</ng-container>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12 mb-3">
				<div class="modal-table table-responsive">
					<table class="table table-sm table-bordered table-gray">
						<thead>
							<tr>
								<th colspan="2">Special Instructions for Suppliers</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="w-75 pt-2 valign-top">
									<div class="form-element-container">
										<textarea
											class="form-control"
											style="background-color: #ffffff !important"
											name="instructions"
											id="instructions"
											rows="3"
											maxlength="1500"
											formControlName="instructions">
										</textarea>
										<span class="form-text special-instructions-form-text">
											i.e. Customer Requests for Single Bill, Customer Needs 100% Green Energy, This is a Proxy Usage, or etc.
										</span>
									</div>
								</td>
								<td class="pt-2 valign-top enhance-checkboxes">
									<div class="form-element-container mt-0">
										<div class="custom-control custom-checkbox" *ngIf="!form.isCustomerInvited.disabled">
											<input class="custom-control-input" type="checkbox" id="isCustomerInvited" formControlName="isCustomerInvited" />
											<label class="custom-control-label" for="isCustomerInvited">Invite Customer</label>
										</div>
										<div *ngIf="form.isCustomerInvited.disabled"><label>Invited Customer!</label></div>
										<small>Will receive emails for when the pricing session begins, extends, and completes.</small>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="modal-table table-responsive">
					<table class="table table-sm table-bordered table-striped">
						<thead>
							<tr>
								<th colspan="2">Special Instructions for Admin</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="w-75 pt-2 valign-top">
									<div class="form-element-container">
										<textarea
											class="form-control"
											style="background-color: #ffffff !important"
											name="instructionsAdmin"
											id="instructionsAdmin"
											rows="3"
											maxlength="1500"
											formControlName="instructionsAdmin">
										</textarea>
										<span class="form-text">i.e. Specific Supplier Instructions, Pricing Targets, etc.</span>
									</div>
								</td>
								<td class="pt-2 valign-top"></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<h6 class="supplier-note-header">Supplier Notes</h6>
			</div>
		</div>
		<ng-container *ngFor="let rfqSessionSupplier of rfqSession.suppliers">
			<div class="row" *ngIf="rfqSessionSupplier.hasNote || rfqSessionSupplier.optOutReasonType">
				<div class="col-sm-12">
					<div class="form-element-container">
						<label class="title supplier-note-title">
							{{ rfqSessionSupplier.supplier.name }} <ng-container *ngIf="rfqSessionSupplier.isOptOut">(Opt Out)</ng-container>
						</label>
						<div>
							<ng-container *ngIf="rfqSessionSupplier.loaRequestNote">
								<span class="p-0 supplier-note-body">
									{{ rfqSessionSupplier.loaRequestNote }} ({{ rfqSessionSupplier.loaRequestDate | date: 'MM/dd/yyyy' }})
								</span>
								<ng-container *ngIf="loggedInUser.isAdmin">
									<a
										href="javascript:void(0)"
										class="pl-2"
										(click)="showAuctionSupplierNoteEdit(rfqSessionSupplier, 'loaRequestNote', 'LOA Request Note')">
										<fa-icon [icon]="['fas', 'pencil-alt']" style="font-size: 14px"></fa-icon>Edit Note
									</a>
									<br /><br />
								</ng-container>
							</ng-container>
							<ng-container *ngIf="rfqSessionSupplier.loeRequestNote">
								<span class="p-0 supplier-note-body">
									{{ rfqSessionSupplier.loeRequestNote }} ({{ rfqSessionSupplier.loeRequestDate | date: 'MM/dd/yyyy' }})
								</span>
								<ng-container *ngIf="loggedInUser.isAdmin">
									<a
										href="javascript:void(0)"
										class="pl-2"
										(click)="showAuctionSupplierNoteEdit(rfqSessionSupplier, 'loeRequestNote', 'LOE Request Note')">
										<fa-icon [icon]="['fas', 'pencil-alt']" style="font-size: 14px"></fa-icon>Edit Note
									</a>
									<br /><br />
								</ng-container>
							</ng-container>
							<ng-container *ngIf="rfqSessionSupplier.sampleBillRequestNote">
								<span class="p-0 supplier-note-body">
									{{ rfqSessionSupplier.sampleBillRequestNote }} ({{ rfqSessionSupplier.sampleBillRequestDate | date: 'MM/dd/yyyy' }})
								</span>
								<ng-container *ngIf="loggedInUser.isAdmin">
									<a
										href="javascript:void(0)"
										class="pl-2"
										(click)="showAuctionSupplierNoteEdit(rfqSessionSupplier, 'sampleBillRequestNote', 'Bill Request Note')">
										<fa-icon [icon]="['fas', 'pencil-alt']" style="font-size: 14px"></fa-icon>Edit Note
									</a>
									<br /><br />
								</ng-container>
							</ng-container>
							<span class="p-0 supplier-note-body" *ngIf="rfqSessionSupplier.note">
								{{ rfqSessionSupplier.note }} ({{ rfqSessionSupplier.noteAddDate | date: 'MM/dd/yyyy' }})
							</span>
							<ng-container *ngIf="loggedInUser.isAdmin">
								<a
									*ngIf="rfqSessionSupplier.note"
									href="javascript:void(0)"
									class="pl-2"
									(click)="showAuctionSupplierNoteEdit(rfqSessionSupplier, 'note', 'Note')">
									<fa-icon [icon]="['fas', 'pencil-alt']" style="font-size: 14px"></fa-icon>Edit Note
								</a>
								<a
									*ngIf="!rfqSessionSupplier.note"
									href="javascript:void(0)"
									(click)="showAuctionSupplierNoteEdit(rfqSessionSupplier, 'note', 'Note')">
									<fa-icon [icon]="['fas', 'plus']" style="font-size: 14px"></fa-icon>Add Note
								</a>
								<br /><br />
							</ng-container>
							<span class="p-0 supplier-note-body" *ngIf="rfqSessionSupplier.optOutReasonType">
								{{ rfqSessionSupplier.supplier.name }} has opted out of the pricing session: {{ rfqSessionSupplier.optOutReasonType.name }}:
								{{ rfqSessionSupplier.optOutReasonNote }} ({{ rfqSessionSupplier.optOutReasonNoteAddDate | date: 'MM/dd/yyyy' }})
								<ng-container *ngIf="!rfqSessionSupplier.isOptOut">
									<br /><br />
									{{ rfqSessionSupplier.supplier.name }} has opted back into the pricing session.
								</ng-container>
							</span>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</ng-container>
</form>

export class FaqSearchResult {

	public id!: number;
	public faqCategoryId!: number;
	public question!: string;
	public answer!: string;
	public brokerRoute!: string;
	public adminRoute!: string;
	public isDefault!: boolean;
	public userId!: string;
	public modifiedUserId!: string;
	public addDate!: string;
	public modifiedDate!: string;
	public amt!: number;
	public terms!: string;

	constructor(faqSearchResult?: FaqSearchResult) {
		Object.assign(this, faqSearchResult);
	}
}

import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

import { Market } from '../models';

@Pipe({
	name: 'marketOverviewUtilityFilter'
})
export class MarketOverviewUtilityFilterPipe implements PipeTransform {

	transform(markets: Market[], stateId: string, serviceTypeId: string): { name: string; utilityId: string }[] {
		return _.chain(markets)
			.filter(m => (!stateId || m.state.stateId === stateId) && (!serviceTypeId || m.serviceType.serviceTypeId === serviceTypeId))
			.map(m => m.utility)
			.uniqBy(m => m.utilityId)
			.sortBy(m => m.name)
			.value();
	}

}

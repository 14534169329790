<pk-broker-page-warnings [warnings]="warnings"></pk-broker-page-warnings>
<div class="alert alert-warning" *ngIf="agentIsOnHold">Account on Hold - Under Compliance Review</div>
<form id="createAuction" [formGroup]="formGroup" *ngIf="!loading && !agentIsOnHold">
	<ng-container *ngIf="startValidation">
		<div class="alert alert-warning" *ngIf="formGroup.errors?.productAmount">At least one product is required.</div>
		<div class="alert alert-warning" *ngIf="formGroup.errors?.productUnique">All products must be unique.</div>
		<div class="alert alert-warning" *ngIf="formGroup.errors?.noSuppliers">At least one supplier is required</div>
		<div class="alert alert-warning" *ngIf="formGroup.errors?.endDateInThePast">Your pricing session end date must be in the future.</div>
		<div class="alert alert-warning" *ngIf="formGroup.errors?.endDateOnWeekend">Your pricing session end date must be a weekday.</div>
		<div class="alert alert-warning" *ngIf="formGroup.errors?.endDateOnHoliday">Your pricing session cannot end on a holiday.</div>
		<div class="alert alert-warning" *ngIf="formGroup.errors?.endDateAfterMaxDate">
			Please select an end date for your pricing session within 30 days from today.
		</div>
		<div class="alert alert-warning" *ngIf="formGroup.errors?.endDateBeforeMinDate">
			Please select an end date for your pricing session after {{ contract.minAuctionEndDate.date | date: 'MM/dd/yyyy' }}
		</div>
	</ng-container>
	<div class="row">
		<div class="col-md-12 mb-4">
			<div class="card px-2">
				<div class="row">
					<div class="col-sm-2" *ngIf="loggedInUser.hasPermission('auction-set-on-hold')">
						<div class="form-element-container">
							<pk-broker-form-dropdown
								label="On Hold"
								[isRequired]="true"
								formControlName="isOnHold"
								[options]="[true, false]"
								[textMap]="yesNoTextMap">
							</pk-broker-form-dropdown>
							<p class="mb-0 invalid-feedback" *ngIf="err.isOnHold">
								<ng-container *ngIf="err.isOnHold.required">On hold is required</ng-container>
							</p>
						</div>
					</div>
					<div class="col-sm-2">
						<div class="form-element-container">
							<pk-broker-form-dropdown
								label="Max Bids"
								[isRequired]="true"
								formControlName="maxBids"
								[options]="maxBidsList"
								[textMap]="maxBidsTextMap">
							</pk-broker-form-dropdown>
						</div>
						<p class="mb-0 invalid-feedback" *ngIf="err.maxBids">
							<ng-container *ngIf="err.maxBids.required">Max bids is required</ng-container>
						</p>
					</div>
					<div class="col-sm-2" *ngIf="!isEnterpiseAgent">
						<div class="form-element-container">
							<pk-broker-form-dropdown
								label="Margin"
								[isRequired]="true"
								formControlName="margin"
								[options]="margines"
								[textMap]="marginesTextMap"
								nullOption="- Select -"
								[nullOptionSelectable]="false">
							</pk-broker-form-dropdown>
							<p class="mb-0 invalid-feedback" *ngIf="err.margin">
								<ng-container *ngIf="err.margin.required">Margin is required</ng-container>
							</p>
						</div>
					</div>
					<div class="col-sm-3">
						<div class="form-element-container">
							<label class="title required" for="datepickerend">
								End Date
								<a
									tooltip="In order to maximize supplier participation, {{
										contract.minAuctionEndDate.businessDays
									}} business days are strongly encouraged for this utility. Please email deals@powerkiosk.com for any issues or concerns."
									container="body"
									[adaptivePosition]="false"
									href="javascript:void(0)"
									placement="top">
									<fa-icon [icon]="['fas', 'question-circle']"></fa-icon>
								</a>
							</label>
							<dx-date-box
								formControlName="endDate"
								type="date"
								dateSerializationFormat="yyyy-MM-ddT00:00:00"
								[disabledDates]="disableAuctionDates"
								[max]="maxAuctionDate"
								dateOutOfRangeMessage="Please select an end date for your auction within 30 days from today."
								placeholder="End Date">
							</dx-date-box>
							<p class="mb-0 invalid-feedback" *ngIf="err.endDate">
								<ng-container *ngIf="err.endDate.required">End date is required</ng-container>
							</p>
						</div>
					</div>
					<div class="col-sm-3">
						<div class="form-element-container">
							<pk-broker-form-dropdown
								label="End Time"
								[isRequired]="true"
								formControlName="endTime"
								[options]="endTimes"
								[textMap]="endTimeTextMap"
								nullOption="-- Select End Time --"
								[nullOptionSelectable]="false">
							</pk-broker-form-dropdown>
							<p class="mb-0 invalid-feedback" *ngIf="err.endTime">
								<ng-container *ngIf="err.endTime.required">End time is required</ng-container>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row" *ngIf="products">
		<div class="col-md-12 mb-4">
			<div class="modal-table table-responsive" style="overflow-x: unset">
				<table class="table table-sm table-bordered table-gray">
					<thead>
						<tr>
							<th class="w-75">{{ contract.serviceType.name }} Products</th>
							<th>Suppliers</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td class="pt-2 valign-top">
								<div class="modal-table table-responsive" style="overflow-x: unset">
									<table class="table table-sm table-bordered table-gray">
										<thead>
											<tr class="bg-white">
												<th width="250">Type</th>
												<th>Term</th>
												<th>Green %</th>
												<ng-container *ngIf="contract.serviceTypeId === ELECTRICITY_ID && hasBlockProduct">
													<th>Lag</th>
													<th>Block Type</th>
													<th width="100">Block Size</th>
												</ng-container>
												<th *ngIf="contract.serviceTypeId === NATURAL_GAS_ID">Initial Fixed Usage</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<ng-container formArrayName="products" *ngFor="let product of form.products; let i = index">
												<tr class="product" [formGroupName]="i">
													<td>
														<div class="form-element-container mb-0">
															<pk-broker-form-dropdown
																formControlName="type"
																[options]="products"
																textKey="name"
																valueKey="id"
																(selection)="productChanged(product)">
															</pk-broker-form-dropdown>
														</div>
													</td>
													<td>
														<div class="form-element-container mb-0">
															<pk-broker-form-dropdown formControlName="term" [options]="terms" [textMap]="termTextMap"></pk-broker-form-dropdown>
														</div>
													</td>
													<td>
														<div class="form-element-container mb-0">
															<pk-broker-form-dropdown
																formControlName="greenPercentage"
																[options]="greenPercentages"
																[textMap]="greenPercentageTextMap">
															</pk-broker-form-dropdown>
														</div>
													</td>
													<ng-container *ngIf="contract.serviceTypeId === ELECTRICITY_ID">
														<td *ngIf="!product.lag.disabled">
															<div class="form-element-container mb-0">
																<pk-broker-form-dropdown
																	formControlName="lag"
																	[options]="lags"
																	[textMap]="lagTextMap"
																	nullOption="- Select -"
																	[nullOptionSelectable]="false">
																</pk-broker-form-dropdown>
															</div>
														</td>
														<td *ngIf="product.lag.disabled && hasBlockProduct"></td>
														<td *ngIf="!product.blockType.disabled">
															<div class="form-element-container mb-0">
																<pk-broker-form-dropdown
																	formControlName="blockType"
																	[options]="blockTypes"
																	textKey="name"
																	valueKey="name"
																	nullOption="- Select -"
																	[nullOptionSelectable]="false">
																</pk-broker-form-dropdown>
															</div>
														</td>
														<td *ngIf="product.blockType.disabled && hasBlockProduct"></td>
														<td *ngIf="!product.blockSize.disabled">
															<div class="form-element-container mb-0">
																<input type="text" class="form-control min-width" formControlName="blockSize" maxlength="10" />
															</div>
														</td>
														<td *ngIf="product.blockSize.disabled && hasBlockProduct"></td>
													</ng-container>
													<td *ngIf="contract.serviceTypeId === NATURAL_GAS_ID">
														<div class="form-element-container mb-0">
															<pk-broker-form-dropdown
																formControlName="initialFixedUsage"
																[options]="initialFixedUsages"
																[textMap]="initialFixedUsageTextMap">
															</pk-broker-form-dropdown>
														</div>
													</td>
													<td>
														<a href="javascript:void(0)" class="removeProduct" (click)="form.products.remove(product)">
															<fa-icon [classes]="['text-danger', 'mb-0']" [icon]="['fas', 'times-circle']" size="lg"></fa-icon>
														</a>
													</td>
												</tr>
												<tr *ngIf="err.products[i]">
													<td [attr.colspan]="contract.serviceTypeId === ELECTRICITY_ID && hasBlockProduct ? '7' : '5'">
														<div class="alert alert-warning" *ngIf="err.products[i].lag?.required">Please choose a lag for the block product</div>
														<div class="alert alert-warning" *ngIf="err.products[i].blockType?.required">
															Please choose a block type for the block product
														</div>
														<div class="alert alert-warning" *ngIf="err.products[i].blockSize?.required">
															Please enter a block size for the block product
														</div>
														<div class="alert alert-warning" *ngIf="err.products[i].initialFixedUsage?.required">
															Please enter an initial fixed usage for this natural gas product
														</div>
													</td>
												</tr>
											</ng-container>
										</tbody>
									</table>
								</div>
								<div class="footer">
									<a class="btn btn-link py-0 px-2" href="javascript:void(0)" id="addProduct" (click)="addProduct()">
										<fa-icon [icon]="['fas', 'plus-circle']"></fa-icon> Add a row
									</a>
								</div>
							</td>
							<td class="pt-2 valign-top enhance-checkboxes">
								<ng-container formArrayName="suppliers" *ngFor="let supplier of form.suppliers; let i = index">
									<div class="form-element-container p-0 mt-0" [formGroupName]="i">
										<div class="custom-control custom-checkbox">
											<input class="custom-control-input" type="checkbox" id="suppliers-{{ i }}" formControlName="supplierSelected" />
											<label class="custom-control-label" for="suppliers-{{ i }}">
												{{ supplier.name.value }}
											</label>
										</div>
									</div>
								</ng-container>
								<ng-container *ngIf="loggedInUser?.isAdmin">
									<label class="font-weight-bold">Outside Usage Threshold</label><br />
									<ng-container formArrayName="otherSuppliers" *ngFor="let supplier of form.otherSuppliers; let i = index">
										<div class="form-element-container p-0 mt-0" [formGroupName]="i">
											<div class="custom-control custom-checkbox">
												<input
													class="custom-control-input"
													type="checkbox"
													id="suppliers-{{ supplier.supplierId.value }}"
													formControlName="supplierSelected" />
												<label class="custom-control-label" for="suppliers-{{ supplier.supplierId.value }}">
													{{ supplier.name.value }}
												</label>
											</div>
										</div>
									</ng-container>
								</ng-container>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12 mb-4">
			<div class="modal-table table-responsive">
				<table class="table table-sm table-bordered table-striped">
					<thead>
						<tr>
							<th colspan="2">Special Instructions for Suppliers</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td class="w-75 pt-2 valign-top">
								<div class="form-element-container">
									<textarea
										class="form-control"
										style="background-color: #ffffff !important"
										name="instructions"
										id="instructions"
										rows="3"
										maxlength="1500"
										formControlName="instructions"></textarea>
									<span class="form-text">
										i.e. Customer Requests for Single Bill, Customer Needs 100% Green Energy, This is a Proxy Usage, or etc.
									</span>
								</div>
							</td>
							<td class="pt-2 valign-top">
								<div class="form-element-container mt-0">
									<div class="custom-control custom-checkbox">
										<input
											class="custom-control-input"
											type="checkbox"
											name="isCustomerInvited"
											id="isCustomerInvited"
											formControlName="isCustomerInvited" />
										<label class="custom-control-label" for="isCustomerInvited">Invite Customer</label>
									</div>
									<small>Will receive emails for when the auction begins, extends, and completes.</small>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="modal-table table-responsive">
				<table class="table table-sm table-bordered table-striped">
					<thead>
						<tr>
							<th colspan="2">Special Instructions for Admin</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td class="w-75 pt-2 valign-top">
								<div class="form-element-container">
									<textarea
										class="form-control"
										style="background-color: #ffffff !important"
										name="instructionsAdmin"
										id="instructionsAdmin"
										rows="3"
										maxlength="1500"
										formControlName="instructionsAdmin"></textarea>
									<span class="form-text">i.e. Specific Supplier Instructions, Pricing Targets, etc.</span>
								</div>
							</td>
							<td class="pt-2 valign-top"></td>
						</tr>
					</tbody>
				</table>
			</div>
			<ng-container *ngIf="!contract.isSaveSessionAllowed(loggedInUser)">
				<p
					class="text-danger mb-1"
					*ngIf="
						!contract.hasLOA() &&
						(contract.getUtilityMarketSetting()?.requireLoaForPricingSession || contract.getUtilityMarketSetting()?.requireLoaOrBillOrManyLocations)
					">
					LOA is Required to Start a Pricing Session {{ !contract.hasLOA() ? '(Pending LOA Signature)' : '' }}
				</p>
				<p class="text-danger mb-1">LOE is Required to Start a Pricing Session {{ !contract.hasLOE() ? '(Pending LOE Signature)' : '' }}</p>
			</ng-container>
		</div>
	</div>
	<ng-container *ngIf="!contract.isSaveSessionAllowed(loggedInUser)">
		<div class="row">
			<div class="col-sm-12 mb-4">
				<button
					*ngIf="
						(!contract.hasLOA() &&
							(contract.getUtilityMarketSetting()?.requireLoaForPricingSession ||
								contract.getUtilityMarketSetting()?.requireLoaOrBillOrManyLocations)) ||
						(!contract.hasLOE() && contract.getUtilityMarketSetting()?.requireLoeForPricingSession)
					"
					class="btn btn-primary"
					[disabled]="uploadingDocument || generatingLoaLoe"
					(click)="generateLOALOE()">
					{{ generatingLoaLoe ? 'Generating...' : 'Generate LOA/LOE' }}
				</button>
				<span
					*ngIf="
						!contract.hasLOA() &&
						(contract.getUtilityMarketSetting()?.requireLoaForPricingSession || contract.getUtilityMarketSetting()?.requireLoaOrBillOrManyLocations)
					"
					class="btn btn-file btn-primary ml-2"
					[ngClass]="{ disabled: uploadingDocument || generatingLoaLoe }">
					{{ uploadingDocument ? 'Uploading...' : 'Upload LOA' }}
					<input type="file" [disabled]="uploadingDocument || generatingLoaLoe" (change)="uploadLOA($event, 'LOA', CONSTANTS.attachmentTypes.loa)" />
				</span>
				<span
					*ngIf="!contract.hasLOE() && contract.getUtilityMarketSetting()?.requireLoeForPricingSession"
					class="btn btn-file btn-primary ml-2"
					[ngClass]="{ disabled: uploadingLoe || generatingLoe }">
					{{ uploadingLoe ? 'Uploading...' : 'Upload LOE' }}
					<input type="file" [disabled]="uploadingLoe || generatingLoe" (change)="uploadLOE($event, 'LOE', CONSTANTS.attachmentTypes.loe)" />
				</span>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12 mb-4"></div>
		</div>
	</ng-container>
</form>


export class OhepNotice {

	public id: number;
	public heading: string;
	public body: string;

	constructor(ohepNotice: OhepNotice) {
		Object.assign(this, ohepNotice);
	}
}

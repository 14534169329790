<pk-broker-loader [show]="loadingArticle"></pk-broker-loader>
<ng-container *ngIf="!loadingArticle && faqArticle">
	<h2 class="mb-4">{{ faqArticle.question }}</h2>
	<span [innerHtml]="faqArticle.answer"></span>
	<p *ngIf="loadingRelatedArticles"><pk-broker-loader [show]="loadingRelatedArticles"></pk-broker-loader></p>
	<h4 class="mt-5 no-underline gray-text" *ngIf="relatedArticles?.length">Related Information</h4>
	<ul>
		<li *ngFor="let article of relatedArticles">
			<a href="javascript:void(0)" id="faqSuggestedQuestion" (click)="showFaqArticle(article)">{{ article.question }}</a>
		</li>
	</ul>
</ng-container>

<pk-broker-loader [show]="loading"></pk-broker-loader>
<pk-broker-page-warnings [warnings]="warnings"></pk-broker-page-warnings>
<form id="submitBid" [formGroup]="formGroup" *ngIf="!loading">
	<div class="row">
		<div class="col-md-12 mb-4">
			<div class="card px-2 py-1">
				<div class="row">
					<div class="col-sm-4">
						<div class="form-element-container">
							<label class="title font-weight-bold">Max Bids per Product</label>
							<p class="value m-0">{{ maxBids }}</p>
						</div>
					</div>
					<div class="col-sm-3">
						<div class="form-element-container">
							<label class="title font-weight-bold">Start</label>
							<p class="value m-0">{{ rfqSession.startDate | date: 'MM/dd/yyyy' }} {{ rfqSession.startTime | date: 'hh:mm a' }} CT</p>
						</div>
					</div>
					<div class="col-sm-5">
						<div class="form-element-container">
							<label class="title font-weight-bold">End</label>
							<p class="value m-0">{{ rfqSession.formatEndDate(isSupplierUser) }} CT</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<div class="table-responsive" style="overflow-x: unset">
				<table class="table table-sm table-bordered table-striped table-hover">
					<thead>
						<tr>
							<th class="text-center">Bandwidth</th>
							<th class="text-center">Billing Method</th>
							<th class="text-center">Units</th>
							<th class="text-center" *ngIf="showIsGRTIncluded">Is GRT Included?</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td class="text-center">
								<pk-broker-form-dropdown
									formControlName="bandwidthPercentage"
									[options]="bandwidthPercentages"
									[textMap]="bandwidthPercentageTextMap"
									valueKey="value"
									nullOption="Select Bandwidth">
								</pk-broker-form-dropdown>
							</td>
							<td class="text-center">
								<pk-broker-form-dropdown
									formControlName="billingMethod"
									[options]="billingMethods"
									valueKey="value"
									nullOption="Select Billing Method">
								</pk-broker-form-dropdown>
							</td>
							<td class="text-center">
								<pk-broker-form-dropdown formControlName="units" [options]="bidMeasureUnits"></pk-broker-form-dropdown>
							</td>
							<td class="text-center" *ngIf="showIsGRTIncluded">
								<pk-broker-form-dropdown
									formControlName="isGRTIncluded"
									[options]="[true, false]"
									[textMap]="yesNoTextMap"
									valueKey="value"
									nullOption="Is GRT Included?">
								</pk-broker-form-dropdown>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="table-responsive" style="overflow-x: unset">
				<table class="table table-sm table-bordered table-striped table-hover" id="bidTable2">
					<thead>
						<tr>
							<th>{{ rfqSession.contract.serviceType.name }} Product(s)</th>
							<th nowrap="nowrap">Green %</th>
							<th nowrap="nowrap">Best Bid</th>
							<th nowrap="nowrap">My Last Bid</th>
							<th nowrap="nowrap">Rank</th>
							<th>Bids Remaining</th>
							<th nowrap="nowrap">Bid Amount&nbsp;{{ form.units.value.text }}</th>
						</tr>
					</thead>
					<tbody>
						<ng-container formArrayName="productBids" *ngFor="let productBid of form.productBids; let i = index">
							<tr [formGroupName]="i">
								<td>
									<span class="product" [tooltip]="productBid.entity.value.product.description" placement="top">
										{{ getProductBidText(productBid) }}
									</span>
								</td>
								<td>{{ productBid.entity.value.greenPercentage || 0 }}%</td>
								<td>
									{{
										productBid.entity.value.supplierBestBid?.bestBid
											? (productBid.entity.value.supplierBestBid.bestBid | convertBidUnits: form.units.value.text | number: '1.6-6')
											: 'N/A'
									}}
									{{ productBid.entity.value.supplierBestBid?.bestBid ? productBid.entity.value.supplierBestBid?.units : '' }}
								</td>
								<td>
									{{
										productBid.entity.value.supplierBestBid?.lastBid
											? (productBid.entity.value.supplierBestBid.lastBid | convertBidUnits: form.units.value.text | number: '1.6-6')
											: 'N/A'
									}}
									{{ productBid.entity.value.supplierBestBid?.lastBid ? productBid.entity.value.supplierBestBid?.units : '' }}
								</td>
								<td>
									{{ productBid.entity.value.supplierBestBid?.rank || 'N/A' }}
								</td>

								<td>
									{{ getBidsRemaining(productBid) }}
								</td>
								<td>
									<div style="color: #ff0000; font-weight: bold" *ngIf="getBidsRemaining(productBid) === 0">Out of Bids!</div>
									<input
										class="form-control form-control-white"
										type="number"
										step="0.00001"
										[max]="getProductMaxBidAmount(productBid.entity.value.productId)"
										[min]="getProductMinBidAmount(productBid.entity.value.productId)"
										*ngIf="getBidsRemaining(productBid) > 0"
										formControlName="bidAmount" />
								</td>
							</tr>
							<tr>
								<td colspan="7">
									<div class="alert alert-warning" *ngIf="err.productBids[i].group?.bidAmountMinMax || err.productBids[i].bidAmount?.min">
										{{ generateErrorMessage(productBid.entity.value.productId) }}
									</div>
									<div class="alert alert-warning" *ngIf="err.productBids[i].group?.multipleDecimals">
										Please enter a valid number with one or fewer decimals.
									</div>
								</td>
							</tr>
						</ng-container>
					</tbody>
					<thead>
						<tr>
							<td colspan="7">Add Your Sweet Spot Product</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td class="pt-2 valign-top" colspan="8">
								<div class="modal-table table-responsive" style="overflow-x: unset">
									<table class="table table-sm table-bordered table-striped" id="products">
										<thead>
											<tr class="bg-white">
												<th width="250">Type</th>
												<th>Term</th>
												<th>Green %</th>
												<ng-container *ngIf="rfqSession.contract.serviceTypeId === ELECTRICITY_ID">
													<th>Lag</th>
													<th>Block Type</th>
													<th width="100">Block Size</th>
												</ng-container>
												<th *ngIf="rfqSession.contract.serviceTypeId === NATURAL_GAS_ID">Initial Fixed Usage</th>
												<th>Bid Amount {{ form.units.value.text }}</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<ng-container formArrayName="sweetSpotProducts" *ngFor="let sweetSpotProduct of form.sweetSpotProducts; let i = index">
												<tr class="product" [formGroupName]="i" *ngIf="!sweetSpotProduct.hidden.value">
													<td>
														<div class="form-element-container mb-0">
															<pk-broker-form-dropdown
																formControlName="type"
																[options]="products"
																textKey="name"
																valueKey="id"
																(selection)="sweetSpotProductChanged(sweetSpotProduct)">
															</pk-broker-form-dropdown>
														</div>
													</td>
													<td>
														<div class="form-element-container mb-0">
															<pk-broker-form-dropdown formControlName="term" [options]="terms" [textMap]="termTextMap"></pk-broker-form-dropdown>
														</div>
													</td>
													<td>
														<div class="form-element-container mb-0">
															<pk-broker-form-dropdown
																formControlName="greenPercentage"
																[options]="greenPercentages"
																[textMap]="greenPercentageTextMap">
															</pk-broker-form-dropdown>
														</div>
													</td>
													<ng-container *ngIf="rfqSession.contract.serviceTypeId === ELECTRICITY_ID">
														<td>
															<div class="form-element-container mb-0">
																<pk-broker-form-dropdown
																	formControlName="lag"
																	[options]="lags"
																	[textMap]="lagTextMap"
																	nullOption="- Select -"
																	[nullOptionSelectable]="false">
																</pk-broker-form-dropdown>
															</div>
														</td>
														<td>
															<div class="form-element-container mb-0">
																<pk-broker-form-dropdown
																	formControlName="blockType"
																	[options]="blockTypes"
																	textKey="name"
																	valueKey="name"
																	nullOption="- Select -"
																	[nullOptionSelectable]="false">
																</pk-broker-form-dropdown>
															</div>
														</td>
														<td>
															<div class="form-element-container mb-0">
																<input type="text" class="form-control min-width" formControlName="blockSize" maxlength="10" />
															</div>
														</td>
													</ng-container>
													<td *ngIf="rfqSession.contract.serviceTypeId === NATURAL_GAS_ID">
														<div class="form-element-container mb-0">
															<pk-broker-form-dropdown
																formControlName="initialFixedUsage"
																[options]="initialFixedUsages"
																[textMap]="initialFixedUsageTextMap">
															</pk-broker-form-dropdown>
														</div>
													</td>
													<td>
														<div class="form-element-container mb-0">
															<input
																type="number"
																step="0.00001"
																[max]="getProductMaxBidAmount(sweetSpotProduct.type.value)"
																[min]="getProductMinBidAmount(sweetSpotProduct.type.value)"
																class="form-control form-control-white"
																formControlName="bidAmount"
																maxlength="10" />
														</div>
													</td>
													<td>
														<a href="javascript:void(0)" class="removeProduct" (click)="form.sweetSpotProducts.remove(sweetSpotProduct)">
															<fa-icon [icon]="['fas', 'times-circle']" [classes]="['text-danger', 'mb-0']" size="lg"></fa-icon>
														</a>
													</td>
												</tr>
												<tr
													*ngIf="
														err.sweetSpotProducts[i].group?.bidAmountMinMax ||
														err.sweetSpotProducts[i].group?.multipleDecimals ||
														err.sweetSpotProducts[i].bidAmount?.required ||
														err.sweetSpotProducts[i].greenPercentage?.required
													">
													<td colspan="8">
														<div class="alert alert-warning" *ngIf="err.sweetSpotProducts[i].group?.bidAmountMinMax">
															{{ generateErrorMessage(sweetSpotProduct.type.value) }}
														</div>
														<div class="alert alert-warning" *ngIf="err.sweetSpotProducts[i].group?.multipleDecimals">
															Please enter a valid number with one or fewer decimals.
														</div>
														<div class="alert alert-warning" *ngIf="err.sweetSpotProducts[i].bidAmount?.required">Bid Amount is required.</div>
														<div class="alert alert-warning" *ngIf="err.sweetSpotProducts[i].greenPercentage?.required">
															Green Percentage is required.
														</div>
													</td>
												</tr>
											</ng-container>
										</tbody>
									</table>
								</div>
								<div class="alert alert-warning" *ngIf="startValidation && formGroup.errors?.productUnique">All products must be unique.</div>
								<div class="footer">
									<a class="btn btn-link py-0 px-2" href="javascript:void(0)" id="addProduct" (click)="addSweetSpotProduct()">
										<fa-icon [icon]="['fas', 'plus-circle']"></fa-icon> Add a row
									</a>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</form>

<div class="row">
	<div class="col-sm-12 mb-4">
		<ul>
			<li class="text-danger">Broker Margin need to be included in all bids. Broker margin information can be found in the RFP info Excel Sheet</li>
			<li class="text-danger">For PA, GRT needs to be included for &lt;150,000 kwh deals, and should not be included for &gt;150,000 kwh deals.</li>
			<li class="text-danger">For NY & NJ, GRT and SUT need to be included.</li>
			<li class="text-danger">Please select the Bandwidth and Billing Method for your bids.</li>
			<li class="text-danger">For Index Adder Product, please list the items included and not included in the note area</li>
			<li class="text-danger">
				Please put notes in the note area, e.g. "Credit pending", "Dual billing only", "One of the accounts is removed", "start date has changed to
				xxx"
			</li>
		</ul>
		<div class="modal-table table-responsive">
			<table class="table table-sm table-bordered table-striped">
				<thead>
					<tr>
						<th>Special Instructions</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td class="w-75 pt-2 valign-top">
							<span class="text-success font-weight-bold">{{ rfqSession.instructions || 'N/A' }}</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<pk-broker-auction-supplier-create-bids-note [rfqSessionSupplier]="rfqSessionSupplier" [rfqSessionId]="rfqSession.id">
		</pk-broker-auction-supplier-create-bids-note>
	</div>
</div>

<div class="row" *ngIf="rfqSession.contract.awardDate && rfqSession.contract.supplierId === supplierId">
	<div class="col-md-12">
		<pk-broker-auction-supplier-create-bids-attachment [rfqSession]="rfqSession" [supplierId]="supplierId">
		</pk-broker-auction-supplier-create-bids-attachment>
	</div>
</div>
<div class="alert alert-warning" *ngIf="startValidation && form.billingMethod.invalid">Billing Method is required.</div>
<div class="alert alert-warning" *ngIf="startValidation && form.bandwidthPercentage.invalid">Bandwidth Percentage is required.</div>
<div class="alert alert-warning" *ngIf="startValidation && form.isGRTIncluded.invalid">Please select if GRT is included.</div>
<div class="alert alert-warning" *ngIf="startValidation && form.productBids.control.invalid">
	There is a problem with your product bids, please check above to validate all your fields.
</div>
<div class="alert alert-warning" *ngIf="startValidation && (form.sweetSpotProducts.control.invalid || formGroup.errors?.productUnique)">
	There is a problem with your sweet spot products, please check above to validate all your fields.
</div>
<div id="myDiv"></div>

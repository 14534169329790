export class CompanyCalendlySetting {

	public id: number;
	public companyId: string;
	public name: string;
	public link: string;
	public sortOrder: number;

	constructor(companyCalendlySetting: CompanyCalendlySetting) {
		Object.assign(this, companyCalendlySetting);
	}
}

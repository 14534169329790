<pk-broker-modal>
	<ng-template #headerTemplate>
		<h5 class="modal-title">Market Opportunities</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<pk-broker-loader [show]="loading"></pk-broker-loader>
		<ng-container *ngIf="!loading">
			<div class="container-fluid">
				<p>Utility prices to compare have increased in the following utilities:</p>
				<ul class="large-bullets">
					<li *ngFor="let marketOpportunity of marketOpportunities" [ngClass]="{ 'urgent-market-utility': hasMarket(marketOpportunity) }">
						{{ marketOpportunity.utilityName }} ({{ marketOpportunity.stateShort }})
					</li>
				</ul>
				<p>If you have customers in one or more of these markets, <strong>ACT NOW!</strong></p>
			</div>
		</ng-container>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" class="btn btn-secondary" (click)="close()">Dismiss</button>
	</ng-template>
</pk-broker-modal>

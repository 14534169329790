export class ParsedAddress {

	number: string;
	prefix: string;
	street: string;
	type: string;
	city: string;
	state: string;
	zip: string;

	constructor(parsedAddress?: ParsedAddress) {
		Object.assign(this, parsedAddress);
	}
}

import { Role } from './role';

export class CpanelPermission {

	public name: string;
	public description: string;
	public permissionId: string;

	public roles: Role[];

	constructor(cpanelPermission?: CpanelPermission) {
		Object.assign(this, cpanelPermission);
	}
}

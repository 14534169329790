<pk-broker-modal>
	<ng-template #headerTemplate>
		<h5 class="modal-title" id="modal-auction-label" *ngIf="!loading">{{ rfqSession.contract?.getModalTitle() }}</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<div
			class="modal-top-actions-bar mb-3"
			*ngIf="![CONSTANTS.statuses.signed, CONSTANTS.statuses.confirmed, CONSTANTS.statuses.dropped].includes(rfqSession?.contract?.status)">
			<button
				*ngIf="!loading"
				href="javascript:void(0)"
				class="btn btn-secondary py-1 px-4 float-left"
				(click)="auctionView = 'help'"
				id="customQuoteHelpBtn">
				<fa-icon [icon]="['fas', 'question']"></fa-icon> Help
			</button>
			<span class="modal-top-actions">
				<a type="button" class="btn btn-link ml-2 px-4" [ngClass]="{ disabled: !!auction && auction.submitDisabled }" (click)="cancel()">Cancel</a>
				<button
					*ngIf="!loading && !!auction && !auction.loading && auctionView === 'auction'"
					type="button"
					class="btn btn-primary ml-2 px-4"
					id="btnButton"
					(click)="updateRfqSession()"
					[disabled]="!!auction && auction.submitDisabled">
					{{ auction.submitText }}
				</button>
				<button *ngIf="!loading && auctionView !== 'auction'" type="button" class="btn btn-primary ml-2 px-4" (click)="backToSession()">
					Go Back to Session
				</button>
				<button
					*ngIf="!loading && !!auctionSupplierNoteEdit && !auctionSupplierNoteEdit.loading && auctionView === 'supplierNoteEdit'"
					type="button"
					class="btn btn-primary ml-2 px-4"
					(click)="submitSupplierNote()"
					[disabled]="!!auctionSupplierNoteEdit && auctionSupplierNoteEdit.submitDisabled">
					{{ auctionSupplierNoteEdit.submitText }}
				</button>
				<button
					*ngIf="!loading && !!auctionSupplierCreateBids && !auctionSupplierCreateBids.loading && auctionView === 'supplierCreateBids'"
					type="button"
					class="btn btn-primary ml-2 px-4"
					(click)="submitBids()"
					[disabled]="!!auctionSupplierCreateBids && auctionSupplierCreateBids.submitDisabled">
					{{ auctionSupplierCreateBids.submitText }}
				</button>
				<button
					*ngIf="
						!loading &&
						!!auctionExtend &&
						!auctionExtend.loading &&
						auctionView === 'extend' &&
						(rfqSession.maxExtends || !loggedInUser.agentId) &&
						!agentIsOnHold
					"
					type="button"
					class="btn btn-primary ml-2 px-4"
					(click)="extendSession()"
					[disabled]="!!auctionExtend && auctionExtend.submitDisabled">
					{{ auctionExtend.submitText }}
				</button>
			</span>
		</div>
		<pk-broker-loader [show]="loading"></pk-broker-loader>
		<div [ngClass]="{ 'd-none': auctionView === 'requirement' }">
			<pk-broker-page-warnings [warnings]="warnings"></pk-broker-page-warnings>
			<pk-broker-auction-in-progress
				*ngIf="!loading"
				[ngClass]="{ 'd-none': auctionView !== 'auction' }"
				[rfqSession]="rfqSession"
				[products]="products"
				[blockTypes]="blockTypes"
				[suppliers]="suppliers"
				[otherSuppliers]="otherSuppliers"
				[isSupplierUser]="!loggedInUser || loggedInUser.supplierId"
				[rfqSessionProducts]="rfqSessionProducts"
				(auctionUpdateCompleted)="onAuctionUpdateCompleted($event)"
				(showAuctionSupplierBidsClicked)="onShowAuctionSupplierBidsClicked($event)"
				(showAuctionSupplierCreateBidsClicked)="onShowAuctionSupplierCreateBidsClicked($event)"
				(showAuctionSupplierNoteEditClicked)="onShowAuctionSupplierNoteEditClicked($event)"
				(showAuctionHelpClicked)="onShowAuctionHelpClicked($event)"
				(showAuctionExtendClicked)="onShowAuctionExtendClicked($event)"
				(updateAuctionView)="onUpdateAuctionView($event)">
			</pk-broker-auction-in-progress>
			<pk-broker-auction-supplier-bids
				*ngIf="!loading && auctionView === 'supplierBids'"
				[rfqSession]="rfqSession"
				[supplierId]="bidsSupplierId"
				(deletedBid)="onDeletedBid($event)">
			</pk-broker-auction-supplier-bids>
			<pk-broker-auction-supplier-create-bids
				*ngIf="!loading && auctionView === 'supplierCreateBids'"
				[rfqSession]="rfqSession"
				[supplierId]="bidsSupplierId"
				[products]="products"
				[blockTypes]="blockTypes"
				[isSupplierUser]="!loggedInUser || loggedInUser.supplierId"
				(bidsSubmitted)="onBidsSubmitted($event)">
			</pk-broker-auction-supplier-create-bids>
			<pk-broker-auction-supplier-note-edit
				*ngIf="!loading && auctionView === 'supplierNoteEdit'"
				[rfqSession]="rfqSession"
				[supplierId]="bidsSupplierId"
				[noteKey]="noteKey"
				[noteKeyDisplay]="noteKeyDisplay">
			</pk-broker-auction-supplier-note-edit>
			<pk-broker-auction-help *ngIf="!loading && auctionView === 'help'"></pk-broker-auction-help>
			<pk-broker-auction-extend
				*ngIf="!loading && auctionView === 'extend'"
				[rfqSession]="rfqSession"
				[suppliers]="suppliers"
				[otherSuppliers]="otherSuppliers"
				[holidays]="holidays"
				(auctionExtendCompleted)="onAuctionExtendCompleted($event)">
			</pk-broker-auction-extend>
		</div>
		<pk-broker-auction-requirement *ngIf="!loading && auctionView === 'requirement'" [contract]="rfqSession.contract">
		</pk-broker-auction-requirement>
		<button
			*ngIf="!loading && auctionView === 'requirement' && loggedInUser.isAdmin"
			type="button"
			class="btn btn-primary px-4"
			(click)="backToSessionAndIgnoreDocuments()"
			[disabled]="auction?.submitDisabled">
			Ignore Required Documents
		</button>
	</ng-template>
</pk-broker-modal>

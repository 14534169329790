import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { Contract } from '../../../shared/models';

@Component({
	selector: 'pk-broker-contract-reject-reason',
	templateUrl: './contract-reject-reason.component.html',
	styleUrls: ['./contract-reject-reason.component.scss'],
})
export class ContractRejectReasonModalComponent {

	// input
	public contract: Contract;

	constructor(public activeModal: BsModalRef) { }
}

import { AgentEmailPreferenceSetting } from './agent-email-preference-setting';
import { EmailSection } from './email-section';
import { EmailSectionGroup } from './email-section-group';

export class EmailPreference {

	public id: number;
	public name: string;
	public slug: string;
	public description: string;
	public sortOrder: number;
	public recipientType: string;
	public emailPreferenceSettingId: number;
	public emailCategoryId: number;
	public isUSOnly: boolean;
	public isAdminOnly: boolean;
	public emailCampaignId?: number;

	public agentEmailPreferenceSetting: AgentEmailPreferenceSetting;
	public emailSections: EmailSection[];

	// internal grouping
	public emailSectionGroups?: EmailSectionGroup[];

	constructor(emailPreference?: EmailPreference) {
		Object.assign(this, emailPreference);

		this.agentEmailPreferenceSetting = this.agentEmailPreferenceSetting
			? new AgentEmailPreferenceSetting(this.agentEmailPreferenceSetting) : this.agentEmailPreferenceSetting;
		this.emailSections = this.emailSections
			? this.emailSections.map(es => new EmailSection(es)) : this.emailSections;
	}
}

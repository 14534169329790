import { Pipe, PipeTransform } from '@angular/core';
import { CONSTANTS } from '@pk/powerkioskutils';

import * as _ from 'lodash';

import { Agent } from '../models';
import { LicensePlan } from '../models/license-plan';

@Pipe({
	name: 'licensePlanNameFilter',
})
export class LicensePlanNameFilterPipe implements PipeTransform {
	// do not show unless already assigned it
	private showBusinessLite(licensePlan: LicensePlan, agent: Agent) {
		const licensePlanId = Number(licensePlan.id);
		return (CONSTANTS.licensePlans.businessFullIds.includes(licensePlanId) || // return business full
			(CONSTANTS.licensePlans.businessLiteIds.includes(licensePlanId) && (agent.hasSeparateBusinessLite())));
	}

	transform(licensePlans: LicensePlan[], agent: Agent): any {
		if (!licensePlans) { return licensePlans; }

		return _.uniqBy(licensePlans, l => l.name).filter(l => !l.parents?.length && this.showBusinessLite(l, agent));
	}
}

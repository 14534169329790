
export class AgentAdvancedState {

	public stateId: string;
	public stateShort: string;

	constructor(agentAdvancedState: AgentAdvancedState) {
		Object.assign(this, agentAdvancedState);
	}
}

import { ServiceType } from './service-type';
import { Utility } from './utility';

export class UtilityServiceType {

	public utilityId: string;
	public serviceTypeId: string;

	public utility: Utility;
	public serviceType: ServiceType;

	constructor(utilityServiceType: UtilityServiceType) {
		Object.assign(this, utilityServiceType);
	}
}

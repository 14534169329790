

import { ServiceType } from './service-type';
import { State } from './state';

export class UtilityServiceFee {

	public id: number;
	public stateId: string;
	public serviceTypeId: string;
	public utilityId: string;
	public amount: number;

	public state: State;
	public serviceType: ServiceType;

	constructor(utilityServiceFee?: Subset<UtilityServiceFee>) {
		Object.assign(this, utilityServiceFee);
	}
}

import { Pipe, PipeTransform } from '@angular/core';

import { User } from '../../shared/models';
import { Note } from '../models/note';

@Pipe({
	name: 'internalNotes'
})
export class InternalNotesPipe implements PipeTransform {

	transform(notes: Note[], user: User): any {
		if (user && user.hasPermission('view-internal-notes')) { return notes; }

		return notes && notes.length ? notes.filter(n => !n.isInternal) : notes;
	}

}

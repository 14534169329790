import { Component, ElementRef, Input, ViewChild } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';

import { TopBannerAlertLearnMoreModalComponent } from '../../modals/top-banner-alert-learn-more-modal/top-banner-alert-learn-more-modal.component';
import { Contract } from '../../models';
import { AbstractPageForm } from '../../pages/page-form.abstract';

@Component({
	selector: 'pk-broker-top-banner-alert',
	templateUrl: './top-banner-alert.component.html',
	styleUrls: ['./top-banner-alert.component.scss'],
})
export class TopBannerAlertComponent extends AbstractPageForm {
	@Input() type: number;
	@Input() contract: Contract;

	@ViewChild('alert') private alert: ElementRef;

	public show = true;

	constructor(private modalService: BsModalService) { super(); }

	get callToAction(): string {
		switch (this.type) {
			case this.CONSTANTS.bannerAlertTypes.brokerReferral:
				return `Earn up to <span class="semibold-text">$1,500</span> for each referred qualified broker that enrolled with us!`;
			case this.CONSTANTS.bannerAlertTypes.annualMembership:
				return `<span class="semibold-text">Upgrade your membership</span> and receive up to `
					+ `<span class="font-size-15 bold-text align-middle d-inline-block">$300 off</span> `
					+ `your license fees.`;
			case this.CONSTANTS.bannerAlertTypes.leadLink:
				return `Get access to <span class="semibold-text">millions</span> of `
					+ `<span class="bold-text">energy contracting leads</span>.`;
			case this.CONSTANTS.bannerAlertTypes.customerReferral:
				return `Earn up to <span class="semibold-text">$2,000</span> for each `
					+ `referred qualified business that signs a contract with us!`;
		}

		return '';
	}

	public showLearnMoreModal(): void {
		this.modalService.show(TopBannerAlertLearnMoreModalComponent, {
			class: 'pk-modal modal-dialog-centered',
			initialState: {
				type: this.type,
				contract: this.contract,
			},
		});
	}

	public hideAlert(): void {
		this.show = false;
		setTimeout(() => {
			this.alert.nativeElement.remove();
		}, 1000);
	}
}

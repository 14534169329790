import * as moment from 'moment-timezone';

export class MonthlyUsage {

	public addDate: Date;
	public supplierPayment: number;

	constructor(monthlyUsage: MonthlyUsage) {
		Object.assign(this, monthlyUsage);

		this.addDate = this.addDate ? new Date(moment(this.addDate).add(1, 'year').format('MMM DD, YYYY hh:mm:ss a')) : null;
	}
}

import * as moment from 'moment-timezone';

import { Timezone } from './';
import { User } from './user';

export class CustomerAvailability {

	public id: number;
	public addDate: Date;
	public modifiedDate: Date;
	public userId: string;
	public modifiedUserId: string;
	public timezoneId: number;
	public mondayFrom: number;
	public mondayTo: number;
	public tuesdayFrom: number;
	public tuesdayTo: number;
	public wednesdayFrom: number;
	public wednesdayTo: number;
	public thursdayFrom: number;
	public thursdayTo: number;
	public fridayFrom: number;
	public fridayTo: number;

	public user: User;
	public modifiedUser: User;
	public timezone: Timezone;

	constructor(customerAvailability?: CustomerAvailability) {
		Object.assign(this, customerAvailability);

		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
		this.modifiedDate = this.modifiedDate ? new Date(moment(this.modifiedDate).format('MMM DD, YYYY hh:mm:ss a')) : this.modifiedDate;
		this.user = this.user ? new User(this.user) : this.user;
		this.modifiedUser = this.modifiedUser ? new User(this.modifiedUser) : this.modifiedUser;
		this.timezone = this.timezone ? new Timezone(this.timezone) : this.timezone;
	}

	get showCustomerAvailability(): boolean {
		return (
			this.mondayFrom ||
			this.mondayTo ||
			this.tuesdayFrom ||
			this.tuesdayTo ||
			this.wednesdayFrom ||
			this.wednesdayTo ||
			this.thursdayFrom ||
			this.thursdayTo ||
			this.fridayFrom ||
			this.fridayTo
		) ? true : false;
	}
}

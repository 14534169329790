export class FeatureTourSlide {

	public id: number;
	public featureTourId: number;
	public sortOrder: number;
	public imageUrl: string;
	public videoUrl: string;
	public caption: string;

	constructor(featureTourSlide?: FeatureTourSlide) {
		Object.assign(this, featureTourSlide);
	}
}

import { SupplierCommissionMap } from './supplier-commission-map';

export class SupplierCommissionTypeMap {

	public id: number;
	public supplierCommissionMapId: string;
	public commissionTypeId: string;
	public commissionTypeColumn: string;
	public commissionTypeColumn2: string;
	public commissionTypeColumn3: string;
	public accountStatus: string;

	public supplierCommissionMap: SupplierCommissionMap;

	constructor(supplierCommissionTypeMap?: SupplierCommissionTypeMap) {
		Object.assign(this, supplierCommissionTypeMap);

		this.supplierCommissionMap
			= this.supplierCommissionMap ? new SupplierCommissionMap(this.supplierCommissionMap) : this.supplierCommissionMap;
	}
}

import { Agent } from './agent';
import { Attachment } from './attachment';
import { EmailSection } from './email-section';

export class AgentEmailSection {

	public id: number;
	public agentId: string;
	public emailSectionId: number;
	public content: string;
	public attachmentId: string;

	public agent: Agent;
	public emailSection: EmailSection;
	public attachment: Attachment;

	constructor(agentEmailSection?: AgentEmailSection) {
		Object.assign(this, agentEmailSection);
	}
}

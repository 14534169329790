import { State } from './state';

export class AgentReferralService {
	public id: number;
	public agentReferralServiceProfileId: string;
	public referralServiceId: number;
	public title: string;
	public description: string;

	public states: State[];
}

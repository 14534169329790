export class AgentCommissionPayPlanOption {
	public id: number;
	public agentId: string;
	public supplierUpfrontPercentage: number;
	public commissionPayPlanTypeId: number;
	public minLag: number;
	public maxLag: number;
	public minTerm: number;
	public maxTerm: number;
	public rate: number;
	public isOverride: boolean;
	public modifiedUserId: string;
}

import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

import { CommissionPayable } from '../models';

@Pipe({
	name: 'agentCommissionPayables',
})
export class AgentCommissionPayablesPipe implements PipeTransform {

	transform(commissions: CommissionPayable[], parentId: string, brotherId: string): CommissionPayable[] {
		return commissions?.length ? _.orderBy(commissions, [
			c => c.date ? c.date.valueOf() : 0,
			c => c.agentId === parentId ? 2 : c.agentId === brotherId ? 3 : 1],
			['asc', 'asc', 'asc']) : [];
	}
}

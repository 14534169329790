import { Contract } from './contract';
import { PurchasingLayer } from './purchasing-layer';

export class PurchasingLayerStrip {

	public id: number;
	public contractId: string;
	public purchasingLayerId: number;
	public layerStripIndex: number;
	public meanPrice: number;
	public startDate: string;
	public endDate: string;
	public defensiveTriggeredDate: string;
	public opportunistTriggeredDate: string;

	public contract: Contract;
	public layer: PurchasingLayer;

	constructor(purchasingLayerStrip?: PurchasingLayerStrip) {
		Object.assign(this, purchasingLayerStrip);
	}
}

import { Component } from '@angular/core';

import { LoadOptions } from 'devextreme/data';
import dxDataGrid from 'devextreme/ui/data_grid';

import { ContractCriteria, PagedEntity, ViewOperationsContractActivityDashboard } from 'src/app/shared/models';
import { AbstractContractTableComponent } from '../contract-table.component.abstract';

declare const $: any;

@Component({
	selector: 'pk-broker-operations-contract-activity-table',
	templateUrl: '../contract-table.component.html',
	styleUrls: ['../contract-table.component.scss'],
})
export class OperationsContractActivityTableComponent extends AbstractContractTableComponent<ViewOperationsContractActivityDashboard> {

	public async listContracts(loadOptions: LoadOptions, dataGrid: dxDataGrid):
		Promise<PagedEntity<ViewOperationsContractActivityDashboard>> {
		const criteria: ContractCriteria = { ...this.extraCriteria };
		const loadOptionsSort = (Array.isArray(loadOptions.sort) ? loadOptions.sort : [loadOptions.sort]).filter(Boolean);
		const sort: string[] = loadOptionsSort?.length
			? loadOptionsSort.map(s => `${s['selector']} ${(s['desc'] ? 'desc' : 'asc')}`)
			: [];

		const result = await this.graphqlService
			.viewOperationsContractActivityDashboard(criteria, sort.join(', '), loadOptions.take, loadOptions.skip);
		return {
			data: result.data.viewOperationsContractActivityDashboard.message.map(c => new ViewOperationsContractActivityDashboard(c)),
			totalCount: result.data.viewOperationsContractActivityDashboard.total,
		};
	}
}

import * as moment from 'moment-timezone';

import { HolidayType } from './holiday-type';

export class Holiday {

	public id: number;
	public date: Date;
	public holidayTypeId: number;

	public type: HolidayType;

	constructor(holiday?: Holiday) {
		Object.assign(this, holiday);

		this.date = this.date ? new Date(moment(this.date).format('MMM DD, YYYY hh:mm:ss a')) : this.date;
	}
}

import { CONSTANTS, StringUtils } from '@pk/powerkioskutils';

import * as _ from 'lodash';
import * as moment from 'moment-timezone';

import { HelperService } from '../helper.service';
import { CommissionOption } from './commission-option';
import { Customer } from './customer';
import { PlanLoadFactorSetting } from './plan-load-factor-setting';
import { Product } from './product';
import { Supplier } from './supplier';

export class Rate {

	public addDate: Date;
	public bandwidthPercentage: number;
	public baseRate: number;
	public billingMethod: string;
	public customerId: string;
	public displayRate: string;
	public displayRateRaw: number;
	public displayRateNoSalesTax: string;
	public logo: string;
	public originalLogo: string;
	public name: string;
	public planName: string;
	public productId: string;
	public productName: string;
	public rfqSessionId: string;
	public rate: number;
	public savings: number;
	public supplierId: string;
	public term: number;
	public termName: string;
	public units: string;
	public usageAdjustment: number;
	public tosPath: string;
	public greenPercentage: number;
	public phone3: string;
	public rateClass: string;
	public eflPath: string;
	public yracPath: string;
	public summaryPath: string;
	public puct: string;
	public disclaimerPath: string;
	public extra: any;
	public salesTax: number;
	public rateCodeId: string;
	public loadFactor: number;
	public planId: string;
	public rateAddDate: Date;
	public premium: number;
	public monthlyServiceFee: number;
	public dailyServiceFee: number;
	public isPrepaid: boolean;
	public commission: number;
	public cancellationPenaltyDesc: string;
	public supplierName: string;
	public originalRate: Rate;
	public matrixPrice: boolean;
	public isGRTIncluded: boolean;
	public hasGRTIncluded: boolean;
	public loadFactorNote: string;
	public contractAgentFinalMargin: number;
	public showDepositDisclaimer: boolean;
	public depositDisclaimerNote: string;
	public showTaxId: boolean;
	public effectiveDate: string | Date;
	public rateMatrixId: string;
	public rfqSessionBidId: string;
	public commissionOptions: CommissionOption[];
	public utilityId: string;
	public stateId: string;
	public serviceTypeId: string;
	public maxNumLocations: number;
	public restrictionsNote: string;
	public isAffectedByMarginCapSplit: boolean;
	public amountMarginAffectedByCapSplit: number;
	public contractAgentMarginSplitCapAdjustment: number;
	public upfrontPaymentRuleDisplay: string;
	public residualPaymentRuleDisplay: string;
	public paymentAmountDisplay: string;
	public marginSplitRule: string;
	public marginCapRule: string;
	public compensationRuleDescriptionDisplay: string;
	public allowMatrixRenewal: boolean;
	public isUsingAgentMarginCap: boolean;
	public displayAgentMarginCap: string;

	// 'extra' column fields
	public productDescription: string;
	public productHeader: string;
	public tdspKwhCharge: number;
	public tdspBaseCharge: string;
	public baseFee1Dollars: string;
	public energyRate: number;
	public bucketOriginalRate: number;
	public bucketRate: number;
	public bucketMargin: number;
	public usageDisclaimer: string;

	// internal
	public planName2: string; // calculated at runtime for display
	public planName2Class: string;
	public originalTosPath: string;
	public originalEflPath: string;
	public originalYracPath: string;
	public originalSummaryPath: string;
	public originalDisclaimerPath: string;
	public loadFactorSetting: PlanLoadFactorSetting;
	public margin: number;
	public bufferMargin: number;
	public originalMargin: number;
	public annualUsage: number;

	public supplier: Supplier;
	public product: Product;
	public customer: Customer;

	constructor(rate: Rate) {
		Object.assign(this, HelperService.toFriendlyRate(rate));

		this.originalRate = HelperService.toFriendlyRate(rate);
		this.originalLogo = _.clone(this.logo);
		this.logo = this.logo ? HelperService.getPath(this.logo, true) : '';
		this.originalTosPath = _.clone(this.tosPath);
		this.tosPath = this.tosPath ? HelperService.getPath(this.tosPath) : '';
		this.originalEflPath = _.clone(this.eflPath);
		this.eflPath = this.eflPath ? HelperService.getPath(this.eflPath) : '';
		this.originalYracPath = _.clone(this.yracPath);
		this.yracPath = this.yracPath ? HelperService.getPath(this.yracPath) : '';
		this.originalSummaryPath = _.clone(this.summaryPath);
		this.summaryPath = this.summaryPath ? HelperService.getPath(this.summaryPath) : '';
		this.originalDisclaimerPath = _.clone(this.disclaimerPath);
		this.disclaimerPath = this.disclaimerPath ? HelperService.getPath(this.disclaimerPath) : '';
		if (this.isPrepaid && !this.planName.includes('PrePay') && this.planName.includes('Pre-Pay')) {
			this.planName += ' - PrePay';
		}
		this.effectiveDate = this.effectiveDate
			? new Date(moment(this.effectiveDate).format('MMM DD, YYYY 00:00:00 a'))
			: this.effectiveDate;
		this.term = this.term !== undefined ? Number(this.term) : this.term;

		this.hasGRTIncluded = typeof this.isGRTIncluded === 'boolean' || (!!this.isGRTIncluded && typeof this.isGRTIncluded === 'string');

		if (typeof this.isGRTIncluded === 'string') {
			this.isGRTIncluded = StringUtils.toBoolean(this.isGRTIncluded);
		}

		if (typeof this.effectiveDate === 'object') {
			this.effectiveDate = new Date((this.effectiveDate as Date).getFullYear(), (this.effectiveDate as Date).getMonth(), 1);
		}

		// if we're not fixed all in, don't calc savings
		if (![
			CONSTANTS.products.fixedAllInElectric,
			CONSTANTS.products.fixedAllInGas,
			CONSTANTS.products.renewable100Electric,
		].includes(this.productId)) {
			this.savings = 0;
		}

		if (this.matrixPrice === null || this.matrixPrice === undefined) {
			this.matrixPrice = true;
		}
	}

	get isResidential(): boolean {
		return this.rateClass?.toLowerCase() === 'rs';
	}

	// allow: residential, bids, and without any load factor to compare
	get isLoadFactorEligible(): boolean {
		return this.isResidential || !this.matrixPrice || !this.loadFactorSetting || this.loadFactorSetting.allowsSignup;
	}

	get rateLabel(): string {
		if ([CONSTANTS.serviceTypes.electricity.serviceTypeId, CONSTANTS.serviceTypes.communitySolar.serviceTypeId]
			.includes(this.serviceTypeId) &&
			this.stateId === CONSTANTS.states.texas &&
			this.isResidential) {
			return '1000 kWh Rate';
		}

		return `per ${this.units}`;
	}

	public showPhone3(): boolean {
		return this.isResidential && !!this.phone3;
	}

	public bandwidthStatement(): string {
		if (this.bandwidthPercentage === 0) {
			return 'In case your current year usage deviates from the last year usage, ' +
				'the additional usage will be purchased at the market rate and will be a variable bill.';
		} else if (this.bandwidthPercentage === 100) {
			return 'No Bandwidth Clause applies.';
		} else {
			return 'This price will remain fixed as long as the consumption doesn\'t go below '
				+ (100 - this.bandwidthPercentage) +
				'% or above '
				+ (100 + this.bandwidthPercentage) +
				'% of customer\'s past year\'s usage.';
		}
	}

	public serviceFee(): number {
		if (this.dailyServiceFee && this.dailyServiceFee > 0) {
			return this.dailyServiceFee;
		} else if (this.monthlyServiceFee > 0) {
			return this.monthlyServiceFee;
		}
	}

	public serviceFeeSuffix(): string {
		if (this.dailyServiceFee && this.dailyServiceFee > 0) {
			return 'per day';
		} else if (this.monthlyServiceFee > 0) {
			return 'per mo.';
		}
	}

	public showAverageMonthlyUsageText(stateId: string): boolean {
		return this.rateClass === 'RS' &&
			stateId === CONSTANTS.states.texas &&
			this.supplierId === CONSTANTS.suppliers.directEnergy;
	}

	public asRateInput(): Rate {
		return {
			addDate: this.addDate,
			bandwidthPercentage: this.bandwidthPercentage,
			baseRate: this.baseRate,
			billingMethod: this.billingMethod,
			customerId: this.customerId,
			displayRate: this.displayRate,
			displayRateRaw: this.displayRateRaw,
			logo: this.originalLogo,
			name: this.name,
			planName: this.planName,
			productId: this.productId,
			rfqSessionId: this.rfqSessionId,
			rate: this.rate,
			savings: this.savings,
			supplierId: this.supplierId,
			term: this.term,
			termName: this.termName,
			units: this.units,
			usageAdjustment: this.usageAdjustment,
			tosPath: this.originalTosPath,
			greenPercentage: this.greenPercentage,
			phone3: this.phone3,
			rateClass: this.rateClass,
			eflPath: this.originalEflPath,
			yracPath: this.originalYracPath,
			puct: this.puct,
			disclaimerPath: this.originalDisclaimerPath,
			extra: this.extra,
			salesTax: this.salesTax,
			rateCodeId: this.rateCodeId,
			loadFactor: this.loadFactor,
			planId: this.planId,
			rateAddDate: this.rateAddDate,
			premium: this.premium,
			monthlyServiceFee: this.monthlyServiceFee,
			dailyServiceFee: this.dailyServiceFee,
			isPrepaid: this.isPrepaid,
			commission: this.commission,
			cancellationPenaltyDesc: this.cancellationPenaltyDesc,
			supplierName: this.supplierName,
			matrixPrice: this.matrixPrice,
		} as Rate;
	}

	public getSupplierNote(serviceTypeId: string, stateId: string): string {
		return HelperService.getSupplierNote(serviceTypeId, stateId, this.supplierId);
	}

	public setLoadFactorSetting(contractLoadFactor: number): void {
		this.loadFactorSetting = new PlanLoadFactorSetting(contractLoadFactor, this.loadFactor);
	}
}

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
export enum FaqPageVideo {
	HomePage = 1,
	InstantQuote = 2,
	MatrixPrice = 3,
	SupplierCompensationStructure = 4,
	LicenseManagement = 6,
	BrandedPortal = 7,
	ProductTypes = 8,
	CommissionForecast = 9,
	ActualPayables = 10,
}

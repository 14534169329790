export class UserSurvey {

	public id: number;
	public recommendScore: number;
	public respondantType: string;
	public scoreReason: string;
	public improveReason: string;
	public canContact: boolean;
	public bestEmail: string;
	public closedSurveyWithButton: boolean;
	public closedSurveyWithoutButton: boolean;
	public clickedInitialLink: boolean;
	public isComplete: boolean;
	public addDate: string;
	public userId: string;
	public fullName: string;
	public userSurveyCategoryId: number;
	public surveyCategory: string;
	public userSurveyCategoryTypeId: number;
	public surveyCategoryType: string;
	public ticketId: number;
	public contractId: string;
	public contractNum: string;
	public ticketCategoryId: number;
	public parentBrokerage: string;

	constructor(userSurvey?: UserSurvey) {
		Object.assign(this, userSurvey);

		this.canContact = null;
	}
}

import { Pipe, PipeTransform } from '@angular/core';

import { ServiceType } from '../models';
import { ISO } from '../models/iso';
import { State } from '../models/state';
import { Utility } from '../models/utility';

@Pipe({
	name: 'utilityFilter',
})
export class UtilityFilterPipe implements PipeTransform {
	transform(utilities: Utility[], state: State | State[], iso: ISO | ISO[], serviceType: ServiceType | ServiceType[]): Utility[] {
		const stateList = Array.isArray(state) ? state : [state];
		const isoList = Array.isArray(iso) ? iso : [iso];
		const serviceTypeList = Array.isArray(serviceType) ? serviceType : [serviceType];

		const states = state ? stateList.map(s => s.stateId).filter(Boolean) : null;
		const isos = iso ? isoList.map(i => i.id).filter(id => id || id === 0) : null;
		const serviceTypes = serviceType ? serviceTypeList.map(i => i.serviceTypeId.toString()).filter(Boolean) : null;

		utilities = utilities?.length && states?.length
			? utilities.filter(u => (u.utilityId === null)
				|| u.utilityStates && u.utilityStates.some(s => states.includes(s.stateId)))
			: utilities;

		utilities = utilities?.length && serviceTypes?.length
			? utilities.filter(u => u.utilityServiceTypes && u.utilityServiceTypes.some(s => serviceTypes.includes(s.serviceTypeId)))
			: utilities;

		utilities = utilities?.length && isos?.length
			? utilities.filter(u => isos.includes(u.isoId))
			: utilities;

		return utilities;
	}
}

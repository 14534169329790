

export class LeadStatusReasonType {
	public id: number;
	public name: string;
	public leadStatusId: number;
	public isNoteRequired: boolean;
	public sortOrder: number;

	constructor(leadStatusReasonType: Partial<LeadStatusReasonType>) {
		Object.assign(this, leadStatusReasonType);
	}
}

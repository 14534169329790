import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment-timezone';

@Pipe({
	name: 'auditReportFriendlyDateColumn'
})
export class AuditReportFriendlyDateColumnPipe implements PipeTransform {
	transform(date: string, isQuarters: boolean): string {
		if (!date) { return date; }
		return !isQuarters
			? moment(date, 'MM/DD/YYYY').format('MMM DD, YYYY')
			: moment(date, 'MM/DD/YYYY').format('[Q]Q YYYY');
	}
}

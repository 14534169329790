import * as moment from 'moment-timezone';

export class GuaranteedPayment {
	public accountAdjustmentId: number;
	public amount: number;
	public isProcessed: boolean;
	public addDate: Date;
	public usage: number;
	public margin: number;
	public agentId: string;
	public agentName: string;
	public contractId: string;
	public contractNum: string;
	public customerId: string;
	public dbaName: string;
	public supplierId: string;
	public supplierName: string;

	constructor(guaranteePayment: GuaranteedPayment) {
		Object.assign(this, guaranteePayment);

		this.addDate = this.addDate
			? new Date(moment(this.addDate).format('MMM DD, YYYY 00:00:00 a')) : this.addDate;
	}
}

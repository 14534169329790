export class SalesTeamPerformance {

	public termValueBookedYTD!: number;
	public thisMonthTermValueBooked!: number;
	public nextMonthContractsExpiring!: number;

	constructor(salesTeamPerformance?: SalesTeamPerformance) {
		Object.assign(this, salesTeamPerformance);
	}
}

import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'loadFactor'
})
export class LoadFactorPipe implements PipeTransform {

	constructor(private decimalPipe: DecimalPipe) { }

	transform(loadFactor: number): string {
		if (loadFactor > 100) {
			return '> 100%';
		} else if (loadFactor) {
			return `${this.decimalPipe.transform(loadFactor, '1.2-2')}%`;
		}

		return 'N/A';
	}
}

export class ViewInvoiceInput {

	public invoiceStatusId: string;
	public supplierId: string;
	public startDateGTE: string;
	public endDateLTE: string;

	constructor(viewInvoiceInput?: ViewInvoiceInput) {
		Object.assign(this, viewInvoiceInput);
	}
}

export class InvoiceSupplier {

	public supplierId: string;
	public supplierName: string;
	public totalAmount: number;
	public numInvoices: number;
	public latestSentDate: number;
	public latestInvoiceId: number;
	public latestInvoiceNum: string;

	constructor(invoiceSupplier?: InvoiceSupplier) {
		Object.assign(this, invoiceSupplier);
	}
}

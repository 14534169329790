import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

import { EmailPreference } from '../models';

@Pipe({
	name: 'emailPreferenceFilter',
})
export class EmailPreferenceFilterPipe implements PipeTransform {
	transform(emailPreferences: EmailPreference[], prefix: string): EmailPreference[] {
		return _.orderBy((emailPreferences && emailPreferences.length && prefix
			? emailPreferences.filter(e => e.slug.startsWith(prefix))
			: emailPreferences), e => e.sortOrder);
	}
}

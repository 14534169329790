import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'pk-broker-welcome',
	templateUrl: './welcome.component.html',
	styleUrls: ['./welcome.component.scss']
})
export class WelcomeModalComponent {

	constructor(
		private activeModal: BsModalRef,
	) { }

	public close(): void {
		this.activeModal.hide();
	}

}

import { Component, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { ErcotZipCodeAddress } from '../../models';
import { AbstractPageForm } from '../../pages/page-form.abstract';

@Component({
	selector: 'pk-broker-esiid-lookup',
	templateUrl: './esiid-lookup.component.html',
	styleUrls: ['./esiid-lookup.component.scss'],
})
export class EsiidLookupComponent extends AbstractPageForm {

	// inputs
	public zipCode: string;

	// outputs
	public onSubmit: EventEmitter<{ address: string; esiid: string }> = new EventEmitter();

	public zipCodeAddresses: ErcotZipCodeAddress[];
	public esiidsAndAddresses: string[];

	constructor(
		public activeModal: BsModalRef,
		public graphqlService: GraphqlService,
		public fb: FormBuilder,
	) { super(); }

	public async loadPageData(): Promise<void> {
		const pageResult = await this.graphqlService.getErcotZipCodeAddresses(this.zipCode.trim().replace(/ /g, ''));
		this.zipCodeAddresses = pageResult.data.ercotZipCodeAddresses;
		this.esiidsAndAddresses = [
			...pageResult.data.ercotZipCodeAddresses.map(z => z.accountNumber),
			...pageResult.data.ercotZipCodeAddresses.map(z => z.address),
		];
	}

	public getForm() {
		return this.fb.group({
			esiidOrAddress: this.fb.control(null as string, Validators.required),
		});
	}

	protected async onFormSubmitted() {
		const esiidOrAddress = this.form.esiidOrAddress.value;

		if (isNaN(Number(esiidOrAddress))) {
			this.onSubmit.emit({
				address: esiidOrAddress,
				esiid: this.zipCodeAddresses.find(z => z.address === esiidOrAddress).accountNumber,
			});
		} else {
			this.onSubmit.emit({
				address: this.zipCodeAddresses.find(z => z.accountNumber === esiidOrAddress).address,
				esiid: esiidOrAddress,
			});
		}

		this.activeModal.hide();
	}
}

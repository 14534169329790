export class UserGridConfig {

	public id: number;
	public name: string;
	public userId: string;
	public gridId: string;
	public isDefault: boolean;
	public isGlobal: boolean;
	public states: string;
	public sortOrder: number;
	public addDate: string | Date;

	constructor(userGridConfig?: UserGridConfig) {
		Object.assign(this, userGridConfig);
	}
}

import { EmailSection } from './email-section';

export class EmailSectionVariable {

	public emailSectionId: number;
	public displayName: string;
	public pugName: string;

	public emailSection: EmailSection;

	constructor(emailSectionVariable?: EmailSectionVariable) {
		Object.assign(this, emailSectionVariable);
	}
}

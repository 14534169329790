export class UtilityAccountNumValidationRequest {
	public utilityAccountNum: string;
	public utilityReferenceNum: string;
	public contractId?: string;
	public serviceTypeId?: string;
}

export class UtilityAccountNumValidationResponse {
	public utilityAccountNum: string;
	public utilityReferenceNum: string;
	public hasError: boolean;
	public errorMessage: string;
}

export class UtilityAccountNumValidation {
	request: UtilityAccountNumValidationRequest;
	response: UtilityAccountNumValidationResponse;
}

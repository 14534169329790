import { CpanelLicensePlanCoupon } from './cpanel-license-plan-coupon';
import { CpanelLicensePlanParent } from './cpanel-license-plan-parent';
import { CpanelLicensePlanType } from './cpanel-license-plan-type';

export class CpanelLicensePlan {

	public name: string;
	public description: string;
	public id: number;
	public stripeId: string;
	public monthlyFee: number;
	public upfrontFee: number;
	public years: number;
	public noChargeStripeId: string;
	public licensePlanTypeId: number;

	public hasChildren: boolean;
	public parents: CpanelLicensePlanParent[];
	public coupons: CpanelLicensePlanCoupon[];
	public licensePlanType: CpanelLicensePlanType;

	constructor(cpanelLicensePlan?: CpanelLicensePlan) {
		Object.assign(this, cpanelLicensePlan);
	}
}

import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StringUtils } from '@pk/powerkioskutils';

import { alert, confirm } from 'devextreme/ui/dialog';
import * as moment from 'moment-timezone';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { NotificationService } from 'src/app/notification/services/notification.service';
import { GraphqlService } from '../../graphql/graphql.service';
import { HelperService } from '../../shared/helper.service';
import { BlockType, Product, RfqSession, RfqSessionMarketSuggestion, RfqSessionProduct, RfqSessionSupplier, Supplier } from '../../shared/models';
import { AbstractPageForm } from '../../shared/pages/page-form.abstract';
import { productsValidator } from '../../shared/validators/products.validator';
import { AuctionSupplierOptOutModalComponent } from '../modals/auction-supplier-opt-out-modal/auction-supplier-opt-out-modal.component';

@Component({
	selector: 'pk-broker-auction-in-progress',
	templateUrl: './auction-in-progress.component.html',
	styleUrls: ['./auction-in-progress.component.scss'],
})
export class AuctionInProgressComponent extends AbstractPageForm {

	@Input() rfqSession: RfqSession;
	@Input() rfqSessionProducts: RfqSessionProduct[];
	@Input() products: Product[];
	@Input() blockTypes: BlockType[];
	@Input() suppliers: Supplier[];
	@Input() otherSuppliers: Supplier[];
	@Input() isSupplierUser: boolean;

	@Output() auctionUpdateCompleted: EventEmitter<RfqSession> = new EventEmitter();
	@Output() showAuctionSupplierBidsClicked: EventEmitter<string> = new EventEmitter();
	@Output() showAuctionSupplierCreateBidsClicked: EventEmitter<string> = new EventEmitter();
	@Output() showAuctionSupplierNoteEditClicked: EventEmitter<{
		supplierId: string; noteKey: string; noteKeyDisplay: string;
	}> = new EventEmitter();
	@Output() showAuctionExtendClicked: EventEmitter<boolean> = new EventEmitter();
	// eslint-disable-next-line max-len
	@Output() updateAuctionView: EventEmitter<'auction' | 'help' | 'requirement' | 'supplierCreateBids' | 'supplierBids' | 'supplierNoteEdit' | 'extend'> = new EventEmitter();

	public sessionBidDataButtonDisabled = false;
	public sessionBidDataButtonText = 'Session Bid Data';
	public isERCOT = false;
	public ignoreRequiredDocuments = false;
	public showMarketSuggestionSuccess = false;
	public choosingMarketSuggestion = false;

	public oldIsOnHold: boolean;
	public terms: number[];
	public lags: number[];
	public greenPercentages: number[];
	public initialFixedUsages: number[];

	constructor(
		private router: Router,
		private graphqlService: GraphqlService,
		private modalService: BsModalService,
		private toastrService: ToastrService,
		private notificationService: NotificationService,
		private datePipe: DatePipe,
		private helperService: HelperService,
		private fb: FormBuilder,
	) {
		super();
		this.submitText = 'Update Session';
		this.submitAudit = 'auction-in-progress:updateRfqSession';
	}

	get isEnterpiseAgent(): boolean {
		return this.loggedInUser?.agent && this.loggedInUser?.agent?.isEnterprise;
	}

	public yesNoTextMap = (option: boolean) => option ? 'Yes' : 'No';
	public termTextMap = (option: number) => `${option} month${option === 1 ? '' : 's'}`;
	public greenPercentageTextMap = (option: number) => `${option}%`;
	public lagTextMap = (option: number) => `+${option} month${option === 1 ? '' : 's'}`;
	public initialFixedUsageTextMap = (option: number) => `${option}%`;

	public async loadPageData(): Promise<void> {
		this.isSupplierUser = !!this.loggedInUser.supplierId;
		this.oldIsOnHold = this.rfqSession.isOnHold;
		this.terms = this.helperService.generateNumberList(1, 59)
			.concat(this.helperService.generateNumberList(60, 120, 12));
		this.lags = this.helperService.generateNumberList(0, 60);
		this.greenPercentages = this.helperService.generateNumberList(0, 100, 10);
		this.initialFixedUsages = this.helperService.generateNumberList(0, 100, 5);
	}

	public getForm() {
		return this.fb.group({
			maxBids: this.rfqSession.maxBids,
			margin: this.rfqSession.contract.margin,
			startDate: [this.rfqSession.startDate, [Validators.required]],
			startTime: [this.rfqSession.startTime, [Validators.required]],
			endDate: [this.rfqSession.endDate, [Validators.required]],
			endTime: [this.rfqSession.endTime, [Validators.required]],
			instructions: this.rfqSession.instructions,
			instructionsAdmin: this.rfqSession.instructionsAdmin,
			isCustomerInvited: this.rfqSession.isCustomerInvited,
			isOnHold: this.rfqSession.isOnHold,
			rfqSessionBids: this.fb.array(this.rfqSession.bids?.map(
				b => this.fb.group({
					addDate: b.addDate,
					billingMethod: b.billingMethod,
					rfqSessionId: b.rfqSessionId,
					rate: b.rate,
					supplierId: b.supplierId,
				}),
			) ?? []),
			products: this.fb.array(this.rfqSession.products?.map(p => this.getProductGroup(p, true)), [Validators.required]),
			suppliers: this.fb.array(this.getSupplierGroups(this.suppliers)),
			otherSuppliers: this.fb.array(this.getSupplierGroups(this.otherSuppliers)),
			optedOutSuppliers: this.fb.array(this.rfqSession.suppliers?.filter(s => s.isOptOut).map(
				s => this.fb.group({
					id: s.id,
					name: s.supplier.name,
					supplierId: s.supplierId,
					supplierSelected: false,
					hidden: false,
				}),
			) ?? []),
		}, { validators: productsValidator });
	}

	private getSupplierGroups(suppliers: Supplier[]) {
		return suppliers.map(s => this.fb.group({
			name: s.name,
			supplierId: s.supplierId,
			supplierSelected: false,
			hidden: !!this.rfqSession.suppliers.find(rs => rs.supplierId === s.supplierId),
		}));
	}

	private getDefaultProduct(): Product {
		let defaultProduct = this.products.find(p => p.name.toLowerCase().includes('fixed'));
		if (!defaultProduct) {
			defaultProduct = this.products[0];
		}

		return defaultProduct;
	}

	private getProductGroup(product: Subset<RfqSessionProduct> = {}, hidden: boolean) {
		return this.fb.group({
			type: [product.product?.id ?? this.getDefaultProduct().id, [Validators.required]],
			term: [product.term ?? 12, [Validators.required]],
			lag: [product.lag ?? this.lags[0], [Validators.required]],
			blockType: [product.blockType ?? this.blockTypes[0].name, [Validators.required]],
			blockSize: [product.blockSize ?? '', [Validators.required]],
			greenPercentage: [product.greenPercentage ?? this.greenPercentages[0], [Validators.required]],
			initialFixedUsage: [product.initialFixedUsage ?? this.initialFixedUsages[0], [Validators.required]],
			hidden,
		});
	}

	public addProduct() {
		const group = this.getProductGroup(this.getDefaultProduct(), false);
		this.setProductRowState(group);
		this.form.products.control.push(group);
	}

	private setProductRowState(product: this['form']['products'][0]['control']): void {
		switch (product.value.type) {
			case this.CONSTANTS.products.block: {
				product.get('lag').setValue(this.lags[0]);
				product.get('lag').enable();
				product.get('blockType').setValue(this.blockTypes[0].name);
				product.get('blockType').enable();
				product.get('blockSize').enable();

				product.get('initialFixedUsage').setValue(0);
				product.get('initialFixedUsage').disable();
				break;
			}
			case this.CONSTANTS.products.nymexPlus: {
				product.get('initialFixedUsage').setValue(this.initialFixedUsages[0]);
				product.get('initialFixedUsage').enable();

				product.get('lag').setValue(0);
				product.get('lag').disable();
				product.get('blockType').setValue('0');
				product.get('blockType').disable();
				product.get('blockSize').setValue('');
				product.get('blockSize').disable();
				break;
			}
			default: {
				product.get('lag').setValue(0);
				product.get('lag').disable();
				product.get('blockType').setValue('0');
				product.get('blockType').disable();
				product.get('blockSize').setValue('');
				product.get('blockSize').disable();
				product.get('initialFixedUsage').setValue(0);
				product.get('initialFixedUsage').disable();
			}
		}
	}

	public productChanged(product: this['form']['products'][0]): void {
		this.setProductRowState(product.control);
	}

	get hasBlockProduct() {
		return this.form.products.some(c => c.type.value === this.CONSTANTS.products.block && c.hidden.value === false);
	}

	public async onFormLoaded(): Promise<void> {
		// disable this session once invited
		if (this.rfqSession.isCustomerInvited) {
			this.form.isCustomerInvited.disable();
		}

		this.form.products.forEach(p => this.setProductRowState(p.control));
	}

	protected async onFormSubmitted() {
		if (this.ignoreRequiredDocuments) {
			await this.updateRfqSession();
			return;
		}

		// even admins should be shown the pricing session requirements
		if (this.rfqSession.contract.isSaveSessionAllowed()) {
			await this.updateRfqSession();
		} else {
			this.updateAuctionView.emit('requirement');
		}
	}

	private async updateRfqSession(): Promise<void> {
		const isReleasingAuction = !StringUtils.toBoolean(this.form.isOnHold.value) && this.oldIsOnHold;
		if (!this.rfqSession.contract.isSaveSessionAllowed(this.loggedInUser) && isReleasingAuction) {
			const loaMessage = 'LOA is Required to Release a Pricing Session';
			await alert(loaMessage, 'Session');
			this.userAuditService.captureEvent(
				this.CONSTANTS.userAuditTypes.formValidation,
				loaMessage,
				'auction-in-progress:submit');
		} else {
			const result = !isReleasingAuction || await this.notificationService
				.confirm({
					title: 'Update Session',
					body: 'You are about to notify the selected suppliers.  Do you want to continue?',
				});
			if (result) {
				const rfqSessionResult = await this.graphqlService.updateRfqSession(this.rfqSession.id, this.rfqSessionDTO());

				// Create all new suppliers
				for (const supplier of [...this.form.suppliers, ...this.form.otherSuppliers]
					.filter(c => c.supplierSelected.value === true)
					.map(s => ({ supplierId: s.supplierId.value, rfqSessionId: this.rfqSession.id, pendingAddition: false }))) {
					await this.graphqlService.createRfqSessionSupplier(supplier);
				}

				// Opt back in any opted out suppliers checked
				for (const supplier of this.form.optedOutSuppliers
					.filter(c => c.supplierSelected.value === true)) {
					await this.graphqlService.updateRfqSessionSupplier(supplier.id.value, { isOptOut: false });
				}

				// Create new products
				for (const product of this.form.products
					.filter(p => p.hidden.value === false)
					.map(p => ({
						productId: p.type.value,
						term: Number(p.term.value),
						greenPercentage: Number(p.greenPercentage.value),
						lag: Number(p.lag.value) || undefined,
						blockType: p.blockType.value || undefined,
						blockSize: p.blockSize.value || undefined,
						initialFixedUsage: Number(p.initialFixedUsage.value) || undefined,
						rfqSessionId: this.rfqSession.id,
					}))) {
					await this.graphqlService.createRfqSessionProduct(product);
				}

				this.toastrService.success('Auction Successully Updated!', 'Pricing Session');
				this.auctionUpdateCompleted.emit(new RfqSession(rfqSessionResult.data.updateRfqSession));
			}
		}
	}

	private rfqSessionDTO() {
		const form = this.formGroup.value;
		return {
			startDate: this.datePipe.transform(form.startDate, 'yyyy-MM-ddT00:00:00.000'),
			startTime: this.datePipe.transform(form.startTime, 'yyyy-MM-ddTHH:mm:ss.SSS'),
			endDate: this.datePipe.transform(form.endDate, 'yyyy-MM-ddT00:00:00.000'),
			endTime: this.datePipe.transform(form.endTime, 'yyyy-MM-ddTHH:mm:ss.SSS'),
			maxBids: form.maxBids,
			margin: form.margin,
			isCustomerInvited: form.isCustomerInvited,
			instructions: form.instructions,
			instructionsAdmin: form.instructionsAdmin,
			agentId: this.rfqSession.contract.agentId,
			isOnHold: form.isOnHold,
		};
	}

	public getExtendSessionMsg(): string {
		return 'Extend Session - Limited to 2 per week';
	}

	get hasBestBid() { return this.rfqSession?.products?.some(p => !!p.bestBids); }

	get showOperationalView(): boolean {
		return this.loggedInUser.isAdmin || this.loggedInUser.isOwner || this.loggedInUser.isFinance || this.loggedInUser.isOperations;
	}

	public showAuctionExtend(): void {
		this.showAuctionExtendClicked.next(true);
	}

	public showAuctionSupplierBids(rfqSessionSupplier: RfqSessionSupplier): void {
		this.showAuctionSupplierBidsClicked.next(rfqSessionSupplier.supplierId);
	}

	public showAuctionSupplierCreateBids(rfqSessionSupplier: RfqSessionSupplier): void {
		if (!this.rfqSession.products.length) {
			this.toastrService.warning('Please add at least one product to be able to submit a bid!');
			return;
		}

		this.showAuctionSupplierCreateBidsClicked.next(rfqSessionSupplier.supplierId);
	}

	public showAuctionSupplierNoteEdit(rfqSessionSupplier: RfqSessionSupplier, noteKey: string, noteKeyDisplay: string): void {
		this.showAuctionSupplierNoteEditClicked.next({ supplierId: rfqSessionSupplier.supplierId, noteKey, noteKeyDisplay });
	}

	public showAuctionSendMessageModal(): void {
		const queryParams = `rfqSessionId=${this.rfqSession.id}` +
			`&supplierId=${this.rfqSession.suppliers.map(s => s.supplierId).join(',')}`;
		window.open(`${window.location.origin}/auction/sendMessage?${queryParams}`,
			'targetWindow', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600');
	}

	public showOptOutModal(rfqSessionSupplier: RfqSessionSupplier): void {
		const modalRef = this.modalService.show(AuctionSupplierOptOutModalComponent, {
			class: 'pk-modal details-modal',
			initialState: {
				rfqSessionSupplierId: rfqSessionSupplier.id,
				contractNum: this.rfqSession.contract.displayContractNum,
				dbaName: this.rfqSession.contract.customer.dbaName,
			},
		});
		modalRef.content.updated.subscribe(() => {
			rfqSessionSupplier.isOptOut = true;
		});
	}

	public async removeRfqSessionSupplier(rfqSessionSupplier: RfqSessionSupplier, index: number): Promise<void> {
		this.warnings = [];
		try {
			const dialogResult =
				await confirm('Are you sure you want to remove this supplier?\nThis action cannot be reversed!', 'Confirm');
			if (dialogResult) {
				rfqSessionSupplier['loading'] = true;
				await this.graphqlService.deleteRfqSessionSupplier(rfqSessionSupplier.id);
				this.toastrService.success('Successfully Removed Supplier', 'Contract Pricing Session');
				this.rfqSession.suppliers.splice(index, 1);
				this.form.suppliers.control.push(this.fb.group({
					name: rfqSessionSupplier.supplier.name,
					supplierId: rfqSessionSupplier.supplierId,
					supplierSelected: false as boolean,
					hidden: false as boolean,
				}));
			}
		} catch (e) {
			this.toastrService.warning(`There was a problem removing the supplier. We have been `
				+ `notified and are working to fix the issue. Please check back again in 30 minutes.`, 'Contract Pricing Session');
		}
	}

	public async removeRfqSessionProduct(rfqSessionProduct: RfqSessionProduct): Promise<void> {
		this.warnings = [];
		try {
			const dialogResult = await confirm('Are you sure you want to remove this product?\nThis action cannot be reversed!', 'Confirm');
			if (dialogResult) {
				await this.graphqlService.deleteRfqSessionProduct(rfqSessionProduct.id);
				this.toastrService.success('Successfully Removed Product', 'Contract Pricing Session');
				this.rfqSession.products.splice(this.rfqSession.products.findIndex(p => p.id === rfqSessionProduct.id), 1);
			}
		} catch (e) {
			this.toastrService.warning(`There was a problem removing the product. We have been `
				+ `notified and are working to fix the issue. Please check back again in 30 minutes.`, 'Contract Pricing Session');
		}
	}

	public async downloadSessionBidData(): Promise<void> {
		try {
			this.sessionBidDataButtonDisabled = true;
			this.sessionBidDataButtonText = 'Processing...';
			const res = await this.graphqlService.getRfqSessionInfo({
				contractId: this.rfqSession.contract.contractId,
				rfqSessionId: this.rfqSession.id,
			});
			if (res && res.data.rfqSessionInfo) {
				const link = document.createElement('a');
				const prefix = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
				link.href = prefix + res.data.rfqSessionInfo.response;
				link.download = 'Session Bid Data.xlsx';
				document.body.appendChild(link);
				link.click();
				link.remove();
			}
		} catch (e) {
			this.warnings = HelperService.getErrorMessage(e);
		}

		this.sessionBidDataButtonDisabled = false;
		this.sessionBidDataButtonText = 'Session Bid Data';
	}

	public async goCreateQuote(): Promise<void> {
		const url = this.router.serializeUrl(this.router
			.createUrlTree(['./rates/quote/', this.rfqSession.contract.contractId, 'rfqSession', this.rfqSession.id]));
		window.open(url, '_blank');
	}

	public async onSelectedMarketSuggestion(marketSuggestion: RfqSessionMarketSuggestion): Promise<void> {
		try {
			this.choosingMarketSuggestion = true;
			await this.graphqlService.chooseMarketSuggestion(this.rfqSession.id, {
				effectiveDate: moment(marketSuggestion.effectiveDate).format('YYYY-MM-DDT00:00:00'),
			});
			if (marketSuggestion.percentChange !== 0) {
				this.showMarketSuggestionSuccess = true;
			}
			this.rfqSession.marketSuggestionEffectiveDate = marketSuggestion.effectiveDate;
			this.rfqSession.contract.effectiveDate = marketSuggestion.effectiveDate;
		} catch (e) {
			this.toastrService.warning(`There was a problem choose the market suggestion, We have been `
				+ `notified and are working to fix the issue. Please check back again in 30 minutes.`, 'Contract Pricing Session');
		}
		this.choosingMarketSuggestion = false;
	}
}

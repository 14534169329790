import { Supplier } from './supplier';

export class FlowCheck {

	public id: number;
	public slug: string;
	public description: string;
	public schedule: string;
	public recipient: string;
	public isActive: boolean;
	public isIndividualRecipientsOnly: boolean;
	public suppliers: Supplier[];

	// internal only
	public saving = false;
	public manualRunning = false;
	public manualRecipientsOnlyRunning = false;

	constructor(flowCheck?: FlowCheck) {
		Object.assign(this, flowCheck);
	}
}


export class ContractAdvancedAgent {

	public id: string;
	public name: string;
	public parentId: string;
	public brotherId: string;

	constructor(contractAdvancedAgent: ContractAdvancedAgent) {
		Object.assign(this, contractAdvancedAgent);
	}
}

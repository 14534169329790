import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

import { Breadcrumb, User } from '../shared/models';

@Injectable({
	providedIn: 'root',
})
export class AppStateService {
	public onAgentUpdated = new Subject<string>(); // agentId
	public onUserUpdated = new Subject<User>();
	public onSetBreadcrumbs = new BehaviorSubject<Breadcrumb[]>(null);
	public onReady = new Subject<{ app: boolean; direct: boolean; isPopupWindow: boolean }>();
	public onSetDefault = new Subject<string>(); // companyId
	public onOpenContractPageTab = new Subject<number>();
	public onShowFeedback = new Subject<string>();

	public setBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
		this.onSetBreadcrumbs.next(breadcrumbs);
	}

	public setReady(ready: { app: boolean; direct: boolean; isPopupWindow: boolean }): void {
		this.onReady.next(ready);
	}

	public showFeedback(page: string): void {
		this.onShowFeedback.next(page);
	}
}


export class ContractAdvancedStatus {

	public timems: number;
	public rid: string;

	constructor(contractAdvancedStatus: ContractAdvancedStatus) {
		Object.assign(this, contractAdvancedStatus);
	}
}

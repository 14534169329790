export class EducationSearchResult {

	public id!: number;
	public educationTypeId!: number;
	public subTitle!: string;
	public thumbnailAttachmentId!: string;
	public path!: string;
	public agentId!: string;
	public isDefault!: boolean;
	public isGlobal!: boolean;
	public pageId!: string;
	public userId!: string;
	public modifiedUserId!: string;
	public addDate!: string;
	public modifiedDate!: string;
	public amt!: number;
	public terms!: string;

	constructor(educationSearchResult?: EducationSearchResult) {
		Object.assign(this, educationSearchResult);
	}
}

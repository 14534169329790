import { EmailPreferenceType } from './email-preference-type';
import { HolidayType } from './holiday-type';

export class AgentEmailPreferenceSetting {

	public id: number;
	public emailPreferenceTypeId: number;
	public emailPreferenceSettingTargetDateOptionId: number;
	public sendDelay: number;
	public sendFrequency: number;
	public holidayTypeId: number;

	public holidayType: HolidayType;
	public emailPreferenceType: EmailPreferenceType;

	constructor(agentEmailPreferenceSetting?: AgentEmailPreferenceSetting) {
		Object.assign(this, agentEmailPreferenceSetting);
	}
}

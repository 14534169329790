import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { HelperService } from '../helper.service';

// fileSize in MB
export const isFileSize = (fileSizeMB: number): ValidatorFn => (c: AbstractControl): ValidationErrors | null => {
	if (!HelperService.isFileSize(c.value, fileSizeMB)) {
		return { fileSize: true };
	}

	return null;
};

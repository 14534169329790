export class UserAudit {

	public id: number;
	public userAuditErrorTypeId: number;
	public details: string;
	public page: string;
	public action: string;

	constructor(userAudit?: UserAudit) {
		Object.assign(this, userAudit);
	}
}

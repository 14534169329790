import { Country } from './country';
import { StateServiceType } from './state-service-type';
import { UserRfqSetting } from './user-rfq-setting';

export class State {

	public stateId: string;
	public stateShort: string;
	public stateLong: string;
	public isActive: boolean;
	public countryId: number;
	public prefix: string;

	public userRfqSettings: UserRfqSetting[];
	public settings: UserRfqSetting[];
	public country: Country;
	public serviceTypes: StateServiceType[];

	// internal
	public isoId: number;

	constructor(state?: State) {
		Object.assign(this, state);
	}
}

import { CONSTANTS } from '@pk/powerkioskutils';

import { HelperService } from '../helper.service';
import { MarginAdjustment } from './margin-adjustment';
import { Product } from './product';
import { Supplier } from './supplier';
import { Utility } from './utility';
import { UtilityServiceType } from './utility-service-type';

export class ServiceType {
	public static defaultCommodityUtility = [{
		utilityId: null,
		serviceTypeId: '',
		utility: {
			utilityId: null,
			name: '-- Any Utility --',
		} as Utility,
	} as UtilityServiceType];

	public serviceTypeId: string;
	public name: string;
	public description: string;
	public isActive: boolean;
	public showPackages: boolean;
	public multiplier: number;
	public units: string;
	public units2: string;
	public abbreviation: string;
	public defaultUsage: number;
	public defaultUsageCanada: number;
	public blockSizeUnit: string;
	public requiresResidentialEnrollment: boolean;
	public sortOrder: number;

	public products: Product[];
	public utilityServiceTypes: UtilityServiceType[];
	public suppliers: Supplier[];
	public unit: string;
	public marginAdjustments: MarginAdjustment[];

	constructor(serviceType?: any, useDefaults = true) {
		Object.assign(this, serviceType);

		if (this.utilityServiceTypes && useDefaults) {
			ServiceType.defaultCommodityUtility[0].serviceTypeId = this.serviceTypeId;
			this.utilityServiceTypes = ServiceType.defaultCommodityUtility.concat(this.utilityServiceTypes);
		}
	}

	get energyIcon(): string {
		return HelperService.getEnergyIcon(this.serviceTypeId);
	}

	public getExtraMultiplier(): number {
		if ([CONSTANTS.serviceTypes.electricity.serviceTypeId, CONSTANTS.serviceTypes.communitySolar.serviceTypeId]
			.includes(this.serviceTypeId)) {
			return 10;
		} else {
			return 1;
		}
	}
}

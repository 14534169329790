import { LeadStatus, LeadStatusChangeRequirements } from './lead-status';
import { ViewLead } from './view-lead';

export class LeadStatusCategory {
	public id: number;
	public name: string;
	public leadTypeId: number;
	public sortOrder: number;
	public isActive: boolean;
	public canAddTo: boolean;

	public leads: ViewLead[];
	public leadStatuses: LeadStatus[];

	constructor(leadStatusCategory?: LeadStatusCategory) {
		Object.assign(this, leadStatusCategory);

		this.leadStatuses = this.leadStatuses ? this.leadStatuses.map(l => new LeadStatus(l)) : this.leadStatuses;
	}

	get changeRequirements(): LeadStatusChangeRequirements {
		if (!this.leadStatuses?.length) {
			return {
				primaryLeadContactBasic: false,
				leadBasic: false,
				leadExtended: false,
				primaryLeadContactAllExcludeEmail: false,
				primaryLeadContactAll: false,
			};
		}

		return {
			primaryLeadContactBasic: this.leadStatuses
				.some(l => typeof l.details === 'object' && l.details.changeRequirements?.primaryLeadContactBasic),
			leadBasic: this.leadStatuses
				.some(l => typeof l.details === 'object' && l.details.changeRequirements?.leadBasic),
			leadExtended: this.leadStatuses
				.some(l => typeof l.details === 'object' && l.details.changeRequirements?.leadExtended),
			primaryLeadContactAllExcludeEmail: this.leadStatuses
				.some(l => typeof l.details === 'object' && l.details.changeRequirements?.primaryLeadContactAllExcludeEmail),
			primaryLeadContactAll: this.leadStatuses
				.some(l => typeof l.details === 'object' && l.details.changeRequirements?.primaryLeadContactAll),
		};
	}
}

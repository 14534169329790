import * as moment from 'moment-timezone';

export class MarketTrendRate {
	public date: Date;
	public rate: number;
	public abbreviation: string;

	constructor(marketTrendRate: MarketTrendRate) {
		Object.assign(this, marketTrendRate);

		this.date = this.date
			? new Date(moment(this.date).format('MMM DD, YYYY 00:00:00 a')) : this.date;
	}
}

export class MarketTrendMetaData {
	public max: number;
	public min: number;
	public average: number;
	public stdDeviation: number;
	public pricePercentile: number;
}

export class MarketTrendTermMetaData extends MarketTrendMetaData {
	public term: number;
}

export class MarketTrends {
	public term12Rates: MarketTrendRate[];
	public term24Rates: MarketTrendRate[];
	public term36Rates: MarketTrendRate[];
	public term12MetaData: MarketTrendTermMetaData;
	public term24MetaData: MarketTrendTermMetaData;
	public term36MetaData: MarketTrendTermMetaData;
	public overallMetaData: MarketTrendMetaData;

	constructor(marketTrends: MarketTrends) {
		Object.assign(this, marketTrends);

		this.term12Rates = this.term12Rates ? this.term12Rates.map(s => new MarketTrendRate(s)) : this.term12Rates;
		this.term24Rates = this.term24Rates ? this.term24Rates.map(s => new MarketTrendRate(s)) : this.term24Rates;
		this.term36Rates = this.term36Rates ? this.term36Rates.map(s => new MarketTrendRate(s)) : this.term36Rates;
	}
}

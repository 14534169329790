<pk-broker-modal>
	<ng-template #headerTemplate>
		<h5 class="modal-title">Address Validation</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<div class="container-fluid">
			<div class="row">
				<div class="col">
					<p class="semibold-text">Please verify the addresses you entered.</p>
				</div>
			</div>
			<ng-container *ngIf="showContact && enteredContactAddress">
				<div class="row">
					<div class="col">
						<h2 class="no-underline">Customer Information</h2>
					</div>
				</div>
				<div class="row mb-4">
					<div class="col">
						<p [ngClass]="{ 'address-not-selected': chooseContactSuggested }">
							<span class="semibold-text">You entered:</span><br />
							<span [innerHtml]="enteredContactAddress"></span>
							<a
								*ngIf="!chooseContactEntered && !chooseContactSuggested"
								href="javascript:void(0)"
								class="semibold-text"
								(click)="handleResponse('contact', 'override')"
								>Use this one</a
							>
						</p>
					</div>
					<div class="col">
						<p [ngClass]="{ 'address-not-selected': chooseContactEntered }">
							<span class="semibold-text">Suggested address:</span><br />
							<ng-container *ngIf="suggestedContactAddress">
								<span [innerHtml]="suggestedContactAddress"></span>
								<a
									*ngIf="!chooseContactEntered && !chooseContactSuggested"
									href="javascript:void(0)"
									class="semibold-text"
									(click)="handleResponse('contact', 'accept')"
									>Use this one</a
								>
							</ng-container>
						</p>
						<span *ngIf="!suggestedContactAddress">No suggestion</span>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="showBilling && enteredBillingAddress">
				<div class="row">
					<div class="col">
						<h2 class="no-underline">Customer Billing Information</h2>
					</div>
				</div>
				<div class="row mb-4">
					<div class="col">
						<p [ngClass]="{ 'address-not-selected': chooseBillingSuggested }">
							<span class="semibold-text">You entered:</span><br />
							<span [innerHtml]="enteredBillingAddress"></span>
							<a
								*ngIf="!chooseBillingEntered && !chooseBillingSuggested"
								href="javascript:void(0)"
								class="semibold-text"
								(click)="handleResponse('billing', 'override')"
								>Use this one</a
							>
						</p>
					</div>
					<div class="col">
						<p [ngClass]="{ 'address-not-selected': chooseBillingEntered }">
							<span class="semibold-text">Suggested address:</span><br />
							<ng-container *ngIf="suggestedBillingAddress">
								<span *ngIf="suggestedBillingAddress" [innerHtml]="suggestedBillingAddress"></span>
								<a
									*ngIf="!chooseBillingEntered && !chooseBillingSuggested"
									href="javascript:void(0)"
									class="semibold-text"
									(click)="handleResponse('billing', 'accept')"
									>Use this one</a
								>
							</ng-container>
						</p>
						<span *ngIf="!suggestedBillingAddress">No suggestion<br /></span>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="locations && locations.length && atLeastOneInvalidLocation">
				<ng-container *ngFor="let location of locations; let i = index">
					<ng-container *ngIf="location && location.enteredLocationAddress">
						<div class="row">
							<div class="col">
								<h2 class="no-underline" *ngIf="i === 0">Locations/Accounts</h2>
								<p class="mb-1 semibold-text add-underline">Location #{{ i + 1 }}</p>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<p [ngClass]="{ 'address-not-selected': location.chooseSuggested }">
									<span class="semibold-text">You entered:</span><br />
									<span [innerHtml]="location.enteredLocationAddress"></span>
									<a
										*ngIf="!location.chooseEntered"
										href="javascript:void(0)"
										class="semibold-text"
										(click)="handleResponse('location', 'override', i)"
										>Use this one</a
									>
								</p>
							</div>
							<div class="col">
								<p [ngClass]="{ 'address-not-selected': location.chooseEntered }">
									<span class="semibold-text">Suggested address:</span><br />
									<ng-container *ngIf="location.suggestedLocationAddress">
										<span *ngIf="location.suggestedLocationAddress" [innerHtml]="location.suggestedLocationAddress"></span>
										<a
											*ngIf="!location.chooseSuggested"
											href="javascript:void(0)"
											class="semibold-text"
											(click)="handleResponse('location', 'accept', i)"
											>Use this one</a
										>
									</ng-container>
								</p>
								<span *ngIf="!location.suggestedLocationAddress">No suggestion<br /></span>
							</div>
						</div>
					</ng-container>
				</ng-container>
			</ng-container>
		</div>
	</ng-template>
</pk-broker-modal>

import { Contract } from './contract';

export class PurchasingLayerIndicativeHedgedPrice {

	public id: number;
	public emailId: string;
	public contractId: string;
	public effectiveDate: Date;
	public term: number;
	public price: number;
	public quantity: number;
	public addDate: Date;

	public contract: Contract;

	constructor(purchasingLayerIndicativeHedgedPrice?: PurchasingLayerIndicativeHedgedPrice) {
		Object.assign(this, PurchasingLayerIndicativeHedgedPrice);
	}
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filterLoaderGroups'
})
export class FilterLoaderGroupsPipe implements PipeTransform {

	transform(loaderGroups: { isActive: boolean; color: string; loaders: any }[], isActive: boolean): any[] {
		return loaderGroups && loaderGroups.length ? loaderGroups.filter(l => l.isActive === isActive) : loaderGroups;
	}

}

import { AfterViewChecked, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { LoadOptions } from 'devextreme/data';
import CustomStore from 'devextreme/data/custom_store';
import DxDataGrid from 'devextreme/ui/data_grid';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { HelperService } from 'src/app/shared/helper.service';
import { Agent, AgentAdvanced, User } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';
import { GraphqlService } from '../../../graphql/graphql.service';

@Component({
	selector: 'pk-broker-user-agent-lookup',
	templateUrl: './user-agent-lookup.component.html',
	styleUrls: ['./user-agent-lookup.component.scss'],
})
export class UserAgentLookupModalComponent extends AbstractPageForm implements AfterViewChecked {

	// inputs
	public isActive: boolean;

	public userAgentsTotal: number;
	public userAgents: AgentAdvanced[];

	private dataGrid: DxDataGrid;
	public userAgentsDataSource: any = {};

	public onSubmit: Subject<User> = new Subject();
	public selectingUser = false;

	constructor(
		private graphqlService: GraphqlService,
		private activeModal: BsModalRef,
		private helperService: HelperService,
		private fb: FormBuilder,
	) {
		super();
	}

	public async loadPageData(): Promise<void> { }

	ngAfterViewChecked(): void {
		const emptyRows = document.getElementsByClassName('dx-freespace-row');
		Array.from(emptyRows).forEach(node => node.remove());
	}

	public getForm() {
		return this.fb.group({
			criteria: ['', [Validators.required]],
		});
	}

	public onUserAgentsDataGridInit(event: any): void {
		this.dataGrid = event.component;
	}

	public async onFormLoaded(): Promise<void> {
		this.form.criteria.valueChanges.subscribe(() => {
			this.submit();
		});

		this.userAgentsDataSource.store = new CustomStore({
			key: 'agentId',
			load: (loadOptions: LoadOptions) => {
				if (loadOptions['dataField']) {
					return Promise.resolve({ data: this.userAgents, totalCount: this.userAgentsTotal });
				}

				const type = 'lucene';
				let search = this.helperService
					.getAgentSearchQuery({
						search: this.form.criteria.value,
						defaultSearch: `*`,
					});

				if (!_.isNil(this.isActive)) {
					search += ' AND is_active:(' + this.isActive ? '1' : '0' + ')';
				}

				// hasLogins: true
				search += ' AND role_id:*';

				// remove from the beginning if it has the "search all" operator
				if (search.startsWith('* AND ')) {
					search = search.replace('* AND ', '');
				}

				const loadOptionsSort = (Array.isArray(loadOptions.sort) ? loadOptions.sort : [loadOptions.sort]).filter(Boolean);
				const sort: string[] = loadOptionsSort?.length
					? loadOptionsSort.map(s => `${s['selector']} ${(s['desc'] ? 'desc' : 'asc')}`)
					: ['name asc'];

				return this.graphqlService.getUserAgentsListData({ search, type }, sort.join(', '), loadOptions.take, loadOptions.skip)
					.then(agentsResult => {
						this.userAgents = agentsResult.data.agentsAdvanced.message.map(a => new AgentAdvanced(a));
						this.userAgentsTotal = agentsResult.data.agentsAdvanced.total;
						return {
							data: this.userAgents,
							totalCount: this.userAgentsTotal,
						};
					});
			},
		});
	}

	protected async onFormSubmitted() {
		if (this.form.criteria.value?.length > 3 || !this.form.criteria.value) {
			const state = this.dataGrid.state();
			this.dataGrid.state(state);
		}
	}

	public async selectUser(agent: Agent): Promise<void> {
		this.selectingUser = true;
		const result = await this.graphqlService.getUserData(agent.logins[0].userId);
		this.selectingUser = false;
		this.onSubmit.next(new User(result.data.user));
		this.activeModal.hide();
	}

	public getEmail(logins: any[]): string {
		if (logins.length && logins.some(l => l.emails.length)) {
			return logins.filter(l => l.emails.length)[0].emails[0].email;
		}

		return '';
	}

	public close(): void {
		this.activeModal.hide();
	}
}

import * as moment from 'moment-timezone';

export class ContractPrice {

	public date: string | Date;
	public price: number;
	public componentPrice: number;
	public hedgedPrice: number;
	public componentHedgedPrice: number;

	constructor(contractPrice?: ContractPrice) {
		Object.assign(this, contractPrice);

		this.date = this.date
			? new Date(moment(this.date).format('MMM DD, YYYY 00:00:00 a')) : this.date;
	}
}

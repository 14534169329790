export class EmailAccount {

	public emailAccountId: string;
	public addDate: string;
	public port: number;
	public password: string;
	public server: string;
	public secure: boolean;
	public username: string;
	public modifiedDate: string;
	public isActive: boolean;
	public companyId: string;

	constructor(emailAccount?: EmailAccount) {
		Object.assign(this, emailAccount);
	}
}

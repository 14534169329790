import { Contract } from './contract';

export class CustomerSignupResult {

	public errorMessage: string;
	public contract: Contract;

	constructor(customerSignupResult: CustomerSignupResult) {
		Object.assign(this, customerSignupResult);
	}
}

import { Injectable } from '@angular/core';
import { CONSTANTS } from '@pk/powerkioskutils';

import { Contract } from '../shared/models';
import { LoadFactorPipe } from '../shared/pipes/load-factor.pipe';

@Injectable({
	providedIn: 'root',
})
export class LoadFactorService {

	constructor(private loadFactorPipe: LoadFactorPipe) { }

	public getAdvice(contract: Contract, key: 'loadFactor' | 'networkLoadFactor' = 'loadFactor'): string {
		const contractLoadFactor = contract[key];

		if (!contract.customLoadFactor && contractLoadFactor <= 0) {
			return '';
		}

		const loadFactor = this.loadFactorPipe.transform(contractLoadFactor);
		const largeElecAnnualUsage = contract.annualUsage > 1000000
			&& [CONSTANTS.serviceTypes.electricity.serviceTypeId, CONSTANTS.serviceTypes.communitySolar.serviceTypeId]
				.includes(contract.serviceTypeId);

		if ((!contract.customLoadFactor && contractLoadFactor < 50) || contract.customLoadFactor === 'Low Load Factor') {
			if (largeElecAnnualUsage) {
				const suggestionSuffix = contract.locations.length > 1
					? ` We suggest separating the accounts into two or more contracts and use matrix pricing.`
					: '';
				const suggestion = `and the total Annual Usage is ${contract.annualUsage} kWh, `
					+ `which is above 1,000,000 kWh and therefore does not qualify for Matrix Pricing,` + suggestionSuffix;
				return contractLoadFactor
					? `The Load Factor of the Contract is ${loadFactor} ${suggestion}`
					: `Because of the low load factor of this contract ${suggestion}`;
			}

			const loadFactorStatement = contractLoadFactor
				? `The Load Factor of the Contract is ${loadFactor}. ` +
				`We recommend Matrix Pricing.`
				: 'Based on the load factor of this contract, we recommend Matrix Pricing.';

			return loadFactorStatement + ' Custom Pricing results in higher prices than matrix price for Low Load Factor customers.';
		}

		if (contract.isAboveUsageThresholdForRecommendation) {
			if ((!contract.customLoadFactor && contractLoadFactor > 60) ||
				contract.customLoadFactor === 'High Load Factor' || contract.customLoadFactor === 'No Demand') {
				if (contractLoadFactor) {
					return `The Load Factor of the Contract is ${loadFactor}. ` +
						`We recommend Custom Pricing this account using our reverse auction.`;
				} else {
					return 'Based on the load factor of this contract, we recommend Custom Pricing this account using our reverse auction.';
				}
			}

			if (contractLoadFactor) {
				return `The Load Factor of the Contract is ${loadFactor}. ` +
					`We recommend to leave the top two matrix offer suppliers out and start an auction.`;
			} else {
				return `Based on the load factor of this contract, we recommend to leave the top two matrix offer `
					+ `suppliers out and start an auction.`;
			}
		} else {
			return `Based on the load factor and usage of this contract, we recommend Matrix Pricing. Please `
				+ `choose the offers that are acceptable.`;
		}
	}
}

import { GroupedMarketSupplier } from './grouped-market-supplier';
import { GroupedMarketUtility } from './grouped-market-utility';

export class GroupedMarketState {
	stateId: string;
	stateLong: string;
	stateShort: string;

	utilities?: GroupedMarketUtility[];
	suppliers?: GroupedMarketSupplier[];
}


export class ContractAdvancedSupplier {

	public id: string;
	public name: string;

	constructor(contractAdvancedSupplier: ContractAdvancedSupplier) {
		Object.assign(this, contractAdvancedSupplier);
	}
}

import { AfterViewInit, Component, Input } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const CanvasJS: any;

@Component({
	selector: 'pk-broker-agent-auction-info-modal-graph',
	templateUrl: './agent-auction-info-modal-graph.component.html',
	styleUrls: ['./agent-auction-info-modal-graph.component.scss'],
})
export class AgentAuctionInfoGraphComponent implements AfterViewInit {

	@Input() elecAuctionsClosed: number;
	@Input() gasAuctionsClosed: number;

	ngAfterViewInit(): void {
		const chart = new CanvasJS.Chart('commodityDoughnut', {
			animationEnabled: true,
			backgroundColor: '#ebebeb',
			data: [{
				type: 'doughnut',
				startAngle: 60,
				innerRadius: 40,
				indexLabel: '{null}',
				toolTipContent: '<b>{label}:</b> (#percent%)',
				dataPoints: [
					{ y: this.elecAuctionsClosed, color: '#FC8F3B', label: 'Electricity' },
					{ y: this.gasAuctionsClosed, color: '#1A2574', label: 'Natural Gas' },
				],
			}],
		});
		chart.render();
	}
}

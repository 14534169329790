import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'warningsFilter'
})
export class WarningsFilterPipe implements PipeTransform {

	transform(warnings: string[], length: number): any { // length used as hack to force change detection
		return warnings ? warnings.filter(c => c) : warnings;
	}

}

import { EducationTopic } from './education-topic';

export class EducationCategory {

	public id: number;
	public name: string;
	public iconClassName: string;
	public sortOrder: number;

	public topics: EducationTopic[];

	constructor(educationCategory?: EducationCategory) {
		Object.assign(this, educationCategory);
	}
}

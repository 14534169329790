import { EclPackageDistribution } from './ecl-package-distribution';
import { ServiceType } from './service-type';
import { State } from './state';

export class EclPackage {

	public id: number;
	public serviceTypeId: string;
	public stateId: string;
	public name: string;
	public numLeads: number;
	public minUsage: number;
	public maxUsage: number;
	public cost: number;
	public stripeId: string;
	public imgPath: string;

	public serviceType: ServiceType;
	public state: State;
	public distributions: EclPackageDistribution[];

	constructor(eclPackage?: EclPackage) {
		Object.assign(this, eclPackage);
	}
}

import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { Feedback } from 'src/app/shared/models';

@Component({
	selector: 'pk-broker-feedback-delete-modal',
	templateUrl: './feedback-delete-modal.component.html',
	styleUrls: ['./feedback-delete-modal.component.scss']
})
export class FeedbackDeleteModalComponent {

	private id: number;
	public submitDisabled = false;
	public submitText = 'Yes';

	public deleted: Subject<boolean> = new Subject();

	constructor(private graphqlService: GraphqlService,
		public activeModal: BsModalRef,
		private toastrService: ToastrService) { }

	public init(id: number): void {
		this.id = id;
	}

	public async deleteFeedback(): Promise<void> {
		this.submitDisabled = true;
		this.submitText = 'Deleting...';
		await this.graphqlService.updateFeedback(this.id, { isReviewed: true } as Feedback);
		this.deleted.next(true);
		this.toastrService.success('Successfully deleted feedback.');
		this.activeModal.hide();
	}
}

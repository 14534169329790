import { CONSTANTS, StringUtils } from '@pk/powerkioskutils';

import * as moment from 'moment-timezone';

import { environment } from 'src/environments/environment';
import { HelperService } from '../helper.service';

export class CustomRfqSession {

	public rfqSessionId: string;
	public startDate: Date;
	public endDate: Date;
	public supplierId: string;
	public note: string;
	public contractId: string;
	public contractNum: string;
	public contractStatus: string;
	public annualUsage: string | number;
	public customerId: string;
	public dbaName: string;
	public lowerDbaName: string;
	public utilityAccountNumList: string | string[];
	public utilityIdList: string | string[];
	public utilityNameList: string | string[];
	public serviceTypeId: string;
	public serviceTypeName: string;
	public sampleBillAttachmentIdList: string | string[];
	public hudAttachmentIdList: string | string[];
	public loaAttachmentIdList: string | string[];
	public loaEsignIdList: string | string[];
	public loaHasEsignList: string | boolean[];
	public loeAttachmentIdList: string | string[];
	public loeEsignIdList: string | string[];
	public loeHasEsignList: string | boolean[];
	public effectiveDate: Date;
	public uploadDate: Date;
	public awardDate: Date;
	public units: string;
	public rfqSessionSupplierId: string;
	public isOptOut: boolean;
	public sampleBillRequestDate: Date;
	public loaRequestDate: Date;
	public loeRequestDate: Date;
	public stateId: string;
	public agentId: string;
	public loadFactor: number;
	public hasUsage: boolean;
	public displayAnnualUsage: number;
	public isSaas: boolean;

	public isCollapsed = true;

	public isSupplierUser: boolean;
	public timeRemainingDisplay: string;
	public sessionDisplayStatus: string;
	public sessionStatusColor: string;
	public sessionTimeLeftColor: string;
	public formattedStartDate: string;
	public formattedEndDate: string;
	public timeRemaining: { days: number; hours: number; minutes: number; seconds: number };

	constructor(rfqSession: CustomRfqSession, isSupplierUser = false) {
		Object.assign(this, rfqSession);

		this.startDate = this.startDate ? new Date(moment(this.startDate).format('MMM DD, YYYY hh:mm:ss a')) : this.startDate;
		this.endDate = this.endDate ? new Date(moment(this.endDate).format('MMM DD, YYYY hh:mm:ss a')) : this.endDate;
		this.effectiveDate = this.effectiveDate
			? new Date(moment(this.effectiveDate).format('MMM DD, YYYY 00:00:00 a')) : this.effectiveDate;
		this.uploadDate = this.uploadDate ? new Date(moment(this.uploadDate).format('MMM DD, YYYY hh:mm:ss a')) : this.uploadDate;
		this.awardDate = this.awardDate ? new Date(moment(this.awardDate).format('MMM DD, YYYY hh:mm:ss a')) : this.awardDate;
		this.sampleBillRequestDate = this.sampleBillRequestDate
			? new Date(moment(this.sampleBillRequestDate).format('MMM DD, YYYY hh:mm:ss a')) : this.sampleBillRequestDate;
		this.loeRequestDate = this.loeRequestDate
			? new Date(moment(this.loeRequestDate).format('MMM DD, YYYY hh:mm:ss a')) : this.loeRequestDate;
		this.loaRequestDate = this.loaRequestDate
			? new Date(moment(this.loaRequestDate).format('MMM DD, YYYY hh:mm:ss a')) : this.loaRequestDate;
		this.isSupplierUser = isSupplierUser;
		this.utilityIdList = this.utilityIdList ? (this.utilityIdList as string).split(',') : [];
		this.utilityNameList = this.utilityNameList ? (this.utilityNameList as string).split(',') : [];
		this.utilityAccountNumList = this.utilityAccountNumList ? (this.utilityAccountNumList as string).split(',') : [];
		this.sampleBillAttachmentIdList = this.sampleBillAttachmentIdList ? (this.sampleBillAttachmentIdList as string).split(',') : [];
		this.hudAttachmentIdList = this.hudAttachmentIdList ? (this.hudAttachmentIdList as string).split(',') : [];
		this.loaAttachmentIdList = this.loaAttachmentIdList ? (this.loaAttachmentIdList as string).split(',') : [];
		this.loaEsignIdList = this.loaEsignIdList ? (this.loaEsignIdList as string).split(',') : [];
		this.loaHasEsignList = this.loaHasEsignList ? (this.loaHasEsignList as string).split(',').map(e => StringUtils.toBoolean(e)) : [];
		this.loeAttachmentIdList = this.loeAttachmentIdList ? (this.loeAttachmentIdList as string).split(',') : [];
		this.loeEsignIdList = this.loeEsignIdList ? (this.loeEsignIdList as string).split(',') : [];
		this.loeHasEsignList = this.loeHasEsignList ? (this.loeHasEsignList as string).split(',').map(e => StringUtils.toBoolean(e)) : [];
		this.lowerDbaName = this.dbaName ? this.dbaName.toLowerCase() : this.dbaName;

		this.formattedStartDate = this.formatStartDate();
		this.formattedEndDate = this.formatEndDate();
		this.setupSessionStatus();

		// as long as we're not completed, update every second
		if (this.timeRemainingDisplay !== '0d 0h 0m') {
			const interval = setInterval(() => {
				this.setupSessionStatus();
				if (this.timeRemainingDisplay === '0d 0h 0m') {
					clearInterval(interval);
				}
			}, 1000);
		}
	}

	get energyIcon(): string {
		return HelperService.getEnergyIcon(this.serviceTypeId);
	}

	private setupSessionStatus(): void {
		this.timeRemaining = this.getTimeRemaining();
		this.timeRemainingDisplay = this.getTimeRemainingDisplay();
		const sessionStatus = this.getSessionStatus();
		this.sessionDisplayStatus = sessionStatus.status;
		this.sessionStatusColor = sessionStatus.statusColor;
		this.sessionTimeLeftColor = sessionStatus.timeLeftColor;
	}

	private getTimeRemaining(): { days: number; hours: number; minutes: number; seconds: number } {
		if (!this.endDate) { return null; }

		const now = moment();
		const endDate = moment(this.endDate);

		const offset = moment().utcOffset();
		moment.tz.setDefault();
		const localOffset = moment().utcOffset();
		moment.tz.setDefault(environment.timezone);

		const offsetDiff = offset - localOffset;
		endDate.subtract(offsetDiff, 'minutes');

		if (this.isSupplierUser && !this.isSaas) {
			endDate.hours(endDate.hours() + CONSTANTS.supplierAuctionEndDateOffset);
		}

		// never convert to before 10am
		if (endDate.hours() < 10) {
			endDate.hours(10);
		}

		let dateDifference = (endDate.valueOf() - now.valueOf()) / 1000;
		const multiplier = dateDifference < 0 ? -1 : 1;

		dateDifference = Math.abs(dateDifference);

		const days = Math.floor(dateDifference / 86400) * multiplier;
		dateDifference -= days * 86400 * multiplier;

		const hours = Math.floor(dateDifference / 3600) % 24 * multiplier;
		dateDifference -= hours * 3600 * multiplier;

		const minutes = Math.floor(dateDifference / 60) % 60 * multiplier;
		dateDifference -= minutes * 60 * multiplier;

		const seconds = dateDifference % 60 * multiplier;

		return { days: Math.max(days, 0), hours: Math.max(hours, 0), minutes: Math.max(minutes, 0), seconds: Math.max(seconds, 0) };
	}

	private getTimeRemainingDisplay(): string {
		if ((this.timeRemaining.days > 0 || this.timeRemaining.hours > 0 ||
			this.timeRemaining.minutes > 0 || this.timeRemaining.seconds > 0) &&
			![CONSTANTS.statuses.acomp, CONSTANTS.statuses.signed, CONSTANTS.statuses.confirmed]
				.includes(Number(this.contractStatus))) {
			return `${this.timeRemaining.days}d ${this.timeRemaining.hours}h ${this.timeRemaining.minutes}m`;
		}

		return '0d 0h 0m';
	}

	private formatEndDate(): string {
		const endDate = moment(this.endDate);
		if (this.isSupplierUser && !this.isSaas) {
			endDate.hours(endDate.hours() + CONSTANTS.supplierAuctionEndDateOffset);
		}

		// never convert to before 10am
		if (endDate.hours() < 10) {
			endDate.hours(10);
		}

		return endDate.format('ddd, MMM DD, YYYY hh:mm a') + ' CT';
	}

	private formatStartDate(): string {
		return moment(this.startDate).format('ddd, MMM DD, YYYY hh:mm a') + ' CT';
	}

	private getSessionStatus(): { status: string; statusColor: string; timeLeftColor: string } {
		const now = moment();
		const endDate = moment(this.endDate);
		const isSameDate = now.isSame(endDate, 'day');
		const isTomorrow = HelperService.isTomorrow(this.endDate, now.toDate());

		if ((!this.timeRemaining.days && !this.timeRemaining.hours &&
			!this.timeRemaining.minutes && !this.timeRemaining.seconds) ||
			[CONSTANTS.statuses.acomp, CONSTANTS.statuses.signed, CONSTANTS.statuses.confirmed]
				.includes(Number(this.contractStatus))) {
			const extra = [CONSTANTS.statuses.signed, CONSTANTS.statuses.confirmed, CONSTANTS.statuses.dropped]
				.includes(Number(this.contractStatus)) ? ' (Deal Closed)' : '';
			return { status: 'Completed' + extra, statusColor: '', timeLeftColor: '' };
		} else if (isSameDate) {
			return { status: 'Ending Today', statusColor: 'red', timeLeftColor: 'red' };
		} else if (isTomorrow) {
			return { status: 'Ending in One Day', statusColor: 'orange', timeLeftColor: 'orange' };
		}

		return { status: 'In Progress', statusColor: 'green', timeLeftColor: '' };
	}
}

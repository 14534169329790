export class ServiceTypeUnit {
	public id: number;
	public units: string;
	public marginUnits: string;
	public stateId: string;
	public utilityId: string;
	public serviceTypeId: string;

	constructor(serviceTypeUnit?: Subset<ServiceTypeUnit>) {
		Object.assign(this, serviceTypeUnit);
	}
}

export class UserSurveyCategory {

	public id: number;
	public name: string;
	public description: string;
	public distributionStart: number;
	public distributionEnd: number;
	public userSurveyCategoryTypeId: number;
	public userId: string;
	public modifiedUserId: string;
	public addDate: Date;
	public modifiedDate: Date;
	public showsWithInitialLink: boolean;

	constructor(userSurveyCategory?: UserSurveyCategory) {
		Object.assign(this, userSurveyCategory);
	}
}

import { Pipe, PipeTransform } from '@angular/core';

import { Agent } from '../models';

@Pipe({
	name: 'agentsWithLogins'
})
export class AgentsWithLoginsPipe implements PipeTransform {
	transform(agents: Agent[]): any {
		return agents ? agents.filter(a => a.logins && a.logins.length) : agents;
	}
}

export class AgentActivityStatisticsInput {

	public startDate: Date | string;
	public endDate: Date | string;
	public includeOnlyActiveAgents: boolean;
	public brotherId?: string;
	public percentileRank?: number;
	public companyId: string;

	//Sort variable
	public column?: string;

	constructor(agentActivityStatisticsInput?: AgentActivityStatisticsInput) {
		Object.assign(this, agentActivityStatisticsInput);
	}
}

export class BlockType {

	public id: string;
	public name: string;
	public isActive: boolean;

	constructor(blockType: BlockType) {
		Object.assign(this, blockType);
	}
}

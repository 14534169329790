import { AgentEngagementEventCategoryNoteType } from './agent-engagement-event-category-suggested-note-type';

export class AgentEngagementEventCategory {
	public id: number;
	public name: string;
	public sortOrder: number;
	public imageIconPrefix: string;
	public imageIcon: string;

	public agentEngagementEventCategorySuggestedNoteTypes: AgentEngagementEventCategoryNoteType[];
}

import { Pipe, PipeTransform } from '@angular/core';

import { UserRfqSetting } from '../models';

@Pipe({
	name: 'rfqSettingsFilter'
})
export class RfqSettingsFilterPipe implements PipeTransform {

	transform(rfqSettings: UserRfqSetting[], serviceTypeId?: string, stateId?: string, utilityId?: string): UserRfqSetting[] {
		if (!rfqSettings?.length) { return rfqSettings; }

		return rfqSettings.filter(r =>
			(!serviceTypeId || (r.serviceTypeId === serviceTypeId)) &&
			(!stateId || (r.stateId === stateId)) &&
			(!utilityId || (r.utilityId === utilityId))
		);
	}
}

import * as moment from 'moment-timezone';

import { User } from './user';

export class Package {

	public id: string;
	public type: string;
	public name: string;
	public plan: string;
	public price: string;
	public userId: string;
	public addDate: Date;

	public user: User;

	constructor(contractPackage?: Package) {
		Object.assign(this, contractPackage);

		this.user = this.user ? new User(this.user) : this.user;
		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
	}
}

import { Pipe, PipeTransform } from '@angular/core';

import { State } from '../models/state';
import { UtilityRateClass } from '../models/utility-rate-class';

@Pipe({
	name: 'rateClassFilter',
})
export class RateClassFilterPipe implements PipeTransform {

	transform(rateClasses: UtilityRateClass[], state: State): any {
		return rateClasses?.length && state?.stateId
			? rateClasses.filter(z => z.name === null || z.stateId === state.stateId)
			: rateClasses;
	}

}

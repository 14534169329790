<label *ngIf="label !== null" [class.required]="isRequired" [class.d-block]="headerLabelDisplayBlock">{{ label }}</label>
<div
	dropdown
	#formDropdown="bs-dropdown"
	class="dropdown pseudo-form-control"
	[ngClass]="dropdownClasses"
	[class.disabled]="disabled"
	[insideClick]="insideClick"
	[isDisabled]="disabled"
	(isOpenChange)="openChanged($event)"
	(onShown)="dropdownShown()"
	(keydown)="textTyped($event)">
	<a
		dropdownToggle
		class="dropdown-toggle"
		[ngClass]="dropdownToggleClasses"
		href="javascript:void(0)"
		role="button"
		[tooltip]="tooltipText"
		aria-haspopup="true"
		aria-expanded="false">
		<ng-container [ngTemplateOutlet]="customDropdownToggleTextContainerTemplate" [ngTemplateOutletContext]="{ $implicit: option }"></ng-container>
		<span *ngIf="!customDropdownToggleTextContainerTemplate" class="dropdown-toggle-text-container" [attr.alt]="dropdownToggleText">
			<ng-container [ngTemplateOutlet]="nameTemplate" [ngTemplateOutletContext]="{ $implicit: option }"></ng-container>
			<ng-container *ngIf="dropdownToggleText"><span [innerHTML]="dropdownToggleText"></span></ng-container>
			<ng-container
				*ngIf="!nameTemplate && option !== null && !templateOnlyMenu && !dropdownToggleText"
				[ngTemplateOutlet]="rowRef"
				[ngTemplateOutletContext]="{ $implicit: option }">
			</ng-container>
		</span>
	</a>
	<div *dropdownMenu class="dropdown-menu" [ngClass]="dropdownMenuClasses">
		<ng-container [ngTemplateOutlet]="header"></ng-container>
		<ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
		<div #scrollingArea class="row scrolling-area" [class.medium-scrolling-area]="scrollAreaHeight === 'medium'" [ngClass]="dropdownMenuRowClasses">
			<div class="col container-column">
				<ng-container *ngIf="nullOptionSelectable && nullOption">
					<a (click)="onSelect(null, $event)" class="dropdown-item" [class.selected-item]="option === null" href="javascript:void(0)">
						{{ nullOption }}
					</a>
				</ng-container>
				<a
					#optionsRef
					*ngFor="let rowOption of visibleOptions"
					(click)="onSelect(rowOption, $event)"
					class="dropdown-item"
					[ngClass]="dropdownItemCssClassKey ? rowOption[dropdownItemCssClassKey] : ''"
					[class.selected-item]="rowOption === option"
					href="javascript:void(0)">
					<span *ngIf="!rowRef" [innerHTML]="boldMap(textMap(rowOption))"></span>
					<ng-container [ngTemplateOutlet]="rowRef" [ngTemplateOutletContext]="{ $implicit: rowOption }"></ng-container>
				</a>
			</div>
		</div>
		<ng-container [ngTemplateOutlet]="footer"></ng-container>
	</div>
</div>

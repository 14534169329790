export class UserEmail {

	public id: number;
	public userId: string;
	public email: string;

	constructor(userEmail?: UserEmail) {
		Object.assign(this, userEmail);
	}
}

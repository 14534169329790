export class EclAnnualUsageBracket {

	public id: number;
	public name: string;
	public minUsage: number;
	public maxUsage: number;
	public sortOrder: number;

	constructor(eclAnnualUsageBracket?: EclAnnualUsageBracket) {
		Object.assign(this, eclAnnualUsageBracket);
	}
}

<footer class="container-fluid sticky-footer d-none d-sm-block">
	<div class="row justify-content-center justify-content-sm-between">
		<div class="col footer-logo" *ngIf="showBranding">
			<a [routerLink]="['/contract']" *ngIf="!isDirect">
				<img
					[attr.src]="
						environment.baseUrl +
						'/attachments/' +
						(agent?.darkDisplayLogo?.attachmentId || defaultFooterLogo.attachmentId) +
						'/view?access_token=' +
						environment.apiKey
					"
					height="45" />
			</a>
			<span *ngIf="isDirect">
				<img
					*ngIf="agent?.darkDisplayLogo?.attachmentId || defaultFooterLogo"
					[attr.src]="
						environment.baseUrl +
						'/attachments/' +
						(agent?.darkDisplayLogo?.attachmentId || defaultFooterLogo.attachmentId) +
						'/view?access_token=' +
						environment.apiKey
					"
					height="45" />
			</span>
		</div>
		<div *ngIf="showBranding" class="col-auto footer-copy">Copyright &copy; {{ year }} {{ companyName }}</div>
	</div>
</footer>

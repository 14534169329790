<pk-broker-modal>
	<ng-template #headerTemplate>
		<h5 *ngIf="!alreadyCompleted" class="modal-title openAnim1" id="csatInitialHeader" [ngClass]="{ 'd-none': closedForm, closeAnim: closingForm }">
			How did we do?
		</h5>
		<h5
			class="modal-title"
			id="csatConfirmationHeader"
			[ngClass]="{ openAnim2: closedForm || alreadyCompleted, 'd-none': !closedForm && !alreadyCompleted }">
			Thank you for your feedback!
		</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<ng-container *ngIf="alreadyCompleted">
			<div class="container-fluid">
				<div class="row">
					<div class="col">
						<p class="label-style">You have already taken this survey for us, and we greatly appreciate your feedback. Thank you again!</p>
						<p class="label-style">You can close this window by clicking the X above.</p>
					</div>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="!alreadyCompleted">
			<pk-broker-loader [show]="loading"></pk-broker-loader>
			<div *ngIf="!loading" class="container-fluid">
				<div class="row openAnim2" id="csatQuestion01" [ngClass]="{ 'd-none': closedForm, closeAnim: closingForm }">
					<div class="col">
						<div class="btn-group btn-group-toggle d-flex">
							<label class="btn btn-light detractor" id="csatDetractor" [ngClass]="{ active: csatSurvey.recommendScore === 6 }">
								<input type="radio" name="csatRating" id="radioRating00" (click)="updateCsatSurvey(6)" />
								<fa-icon [icon]="['far', 'frown']"></fa-icon>
							</label>
							<label class="btn btn-light passive" id="csatPassive" [ngClass]="{ active: csatSurvey.recommendScore === 8 }">
								<input type="radio" name="csatRating" id="radioRating01" (click)="updateCsatSurvey(8)" />
								<fa-icon [icon]="['far', 'meh']"></fa-icon>
							</label>
							<label class="btn btn-light promoter" id="csatPromoter" [ngClass]="{ active: csatSurvey.recommendScore === 10 }">
								<input type="radio" name="csatRating" id="radioRating02" (click)="updateCsatSurvey(10)" />
								<fa-icon [icon]="['far', 'grin-stars']"></fa-icon>
							</label>
						</div>
					</div>
				</div>
				<div
					class="row"
					id="csatQuestion02"
					[ngClass]="{
						'd-none': !selectedScore || closedForm,
						openAnim1: selectedScore && !closingForm,
						closeAnim: closingForm
					}">
					<div class="col mt-4 form-element-container">
						<label for="reasons">What are your primary reasons for the score you gave us?</label>
						<textarea class="form-control" id="reasons" rows="2" maxlength="1000" [(ngModel)]="csatSurvey.scoreReason"></textarea>
					</div>
				</div>
				<div
					class="row"
					id="csatQuestion03"
					[ngClass]="{
						'd-none': !selectedScore || closedForm,
						openAnim2: selectedScore
					}">
					<div class="col mt-4 form-element-container">
						<label for="roomForImprovement">Is there anything specific {{ parentBrokerage }} can do to improve this experience?</label>
						<textarea class="form-control" id="roomForImprovement" rows="2" maxlength="1000" [(ngModel)]="csatSurvey.improveReason"></textarea>
					</div>
				</div>
				<div
					class="row"
					id="csatQuestion04"
					[ngClass]="{
						'd-none': !selectedScore || closedForm,
						openAnim3: selectedScore
					}">
					<div class="col mt-4">
						<p class="label-style">Would it be okay for us to follow up with you about your responses?</p>
					</div>
				</div>
				<div
					class="row"
					id="csatChoices04"
					[ngClass]="{
						'd-none': !selectedScore || closedForm,
						openAnim3: selectedScore
					}">
					<div class="col form-element-container">
						<div class="custom-control custom-radio">
							<input
								type="radio"
								id="yesToContact"
								name="okToContact"
								class="custom-control-input"
								[value]="true"
								[(ngModel)]="csatSurvey.canContact" />
							<label class="custom-control-label" for="yesToContact">Yes</label>
						</div>
					</div>
					<div class="col form-element-container">
						<div class="custom-control custom-radio">
							<input
								type="radio"
								id="noToContact"
								name="okToContact"
								class="custom-control-input"
								[value]="false"
								[(ngModel)]="csatSurvey.canContact"
								(click)="closeBestEmail()" />
							<label class="custom-control-label" for="noToContact">No</label>
						</div>
					</div>
				</div>
				<div
					class="row"
					id="csatQuestion05"
					[ngClass]="{
						'd-none': (!csatSurvey.canContact && !closingBestEmail) || closedForm,
						openAnim2: csatSurvey.canContact,
						closeAnim: closingBestEmail
					}">
					<div class="col mt-3 form-element-container">
						<label for="emailAddress">What's the best email adress to reach you?</label>
						<input type="text" class="form-control" id="emailAddress" placeholder="name@email.com" [(ngModel)]="csatSurvey.bestEmail" />
					</div>
				</div>
				<div
					class="row"
					id="csatSubmitDiv"
					[ngClass]="{
						'd-none': !selectedScore || closedForm,
						openAnim4: selectedScore && !closingForm,
						closeAnim: closingForm
					}">
					<div class="col mt-3 text-right">
						<button type="button" class="btn btn-primary" id="csatSubmitBtn" (click)="submit()" [disabled]="submitDisabled">{{ submitText }}</button>
					</div>
				</div>
				<!-- Confirmation message -->
				<div class="row" id="csatConfirmationMsg" [ngClass]="{ openAnim2: closedForm, 'd-none': !closedForm }">
					<div class="col">
						<p class="label-style">We take every opinion seriously and will be reaching out if you wanted us to. Thank you again!</p>
						<p class="label-style">You can close this window by clicking the X above.</p>
					</div>
				</div>
			</div>
		</ng-container>
	</ng-template>
</pk-broker-modal>

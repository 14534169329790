import * as moment from 'moment-timezone';

import { HelperService } from '../helper.service';
import { PayableIssue } from './payable-issue';

export class UnpaidPayable {
	public id: number;
	public commissionId: string;
	public commissionIds: string; //ids separated by commas (if grouped)
	public commissionIdRates: string; //commissionId|rate separate by commas (if grouped)
	public accountAdjustmentId: number;
	public accountAdjustmentIds: string;
	public agentId: string;
	public agentName: string;
	public dbaName: string;
	public contractId: string;
	public contractNum: string;
	public utilityAccountNum: string;
	public confirmedAnnualUsage: number;
	public date: Date;
	public confirmationDate: Date;
	public effectiveDate: Date;
	public payablePlan: string;
	public amount: number;
	public totalAmount: number;
	public commissionTypeId: number;
	public commissionTypeName: string;
	public contractLocationId: string;
	public cycleStartDate: Date;
	public cycleEndDate: Date;
	public margin: number;
	public usage: number;
	public rate: number;
	public units: string;
	public symbol: string;
	public commissionPayPlanTypeId: number;
	public upfrontPercentage: number;
	public description: string;
	public commissionPayableIssueTypeId: number;
	public commissionPayableIssueTypeName: string;

	public payableIssues: PayableIssue[];

	public commissionPayableStatusTypeId: number;
	public commissionPayableStatusTypeName: string;
	public addressLine: string;
	public commissionPayableId: number;
	public receivableAddDate: Date;
	public receivableUsage: number;
	public accountUsage: number;
	public totalAgentPaymentAmount: number;
	public totalReceivableUpfrontPaymentNum: number;
	public totalReceivableResidualPaymentNum: number;
	public totalPayableUpfrontPaymentNum: number;
	public totalPayableResidualPaymentNum: number;
	public ruleId: number;
	public ruleTierId: number;
	public accountUsageCheckId: number;
	public receivableUsageCheckId: number;
	public issues: string;

	// internal
	public groupings: UnpaidPayable[];
	public usageVerification: {
		accountUsage: {
			label: string;
			text: string;
			iconPrefix: string;
			icon: string;
			classes: string;
		};
		receivableUsage: {
			label: string;
			text: string;
			iconPrefix: string;
			icon: string;
			classes: string;
		};
	};

	constructor(unpaidPayable: Partial<UnpaidPayable>) {
		Object.assign(this, unpaidPayable);

		this.cycleStartDate = this.cycleStartDate
			? new Date(moment(this.cycleStartDate).format('MMM DD, YYYY 00:00:00 a')) : this.cycleStartDate;
		this.cycleEndDate = this.cycleEndDate
			? new Date(moment(this.cycleEndDate).format('MMM DD, YYYY 00:00:00 a')) : this.cycleEndDate;

		this.usageVerification = HelperService.getUsageVerification(this);
	}

	get hasIssues(): boolean {
		return (this.issues || this.usageVerification.accountUsage || this.usageVerification.receivableUsage) ? true : false;
	}
}

import * as moment from 'moment-timezone';

export class RfqSessionMarketSuggestion {

	public effectiveDate: Date;
	public minRate: number;
	public percentChange: number;

	constructor(rfqSessionMarketSuggestion?: RfqSessionMarketSuggestion) {
		Object.assign(this, rfqSessionMarketSuggestion);

		this.effectiveDate = this.effectiveDate
			? new Date(moment(this.effectiveDate).format('MMM DD, YYYY hh:mm:ss a'))
			: this.effectiveDate;
	}
}

import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

import { CommissionPayablesSchedule } from '../models/commission-payables-schedule';

@Pipe({
	name: 'agentCommissionPayableSchedules',
})
export class AgentCommissionPayableSchedulesPipe implements PipeTransform {

	transform(commissions: CommissionPayablesSchedule[], agentId: string, parentId?: string, brotherId?: string): any {
		return _.orderBy(commissions && agentId
			? commissions.filter(c => c.agentId === agentId && c.isPaid)
			: commissions.filter(c => !c.isForecast), [
			c => c.date ? c.date.valueOf() : 0,
			c => c.agentId === parentId ? 2 : c.agentId === brotherId ? 3 : 1],
			['asc', 'asc', 'asc']);
	}

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'phone',
})
export class PhonePipe implements PipeTransform {

	transform(value: string | number, countryId: number): string {
		if (!value) { return ''; }

		const phone = String(value);

		if (phone.match(/[a-z]/i)) {
			return phone;
		}

		const splitPhone = phone
			.replace(/[-\.\(\)]/g, '')
			.replace(/ /g, '')
			.split('');

		// 3454 040506
		if (countryId === 229) {
			return splitPhone[0] + splitPhone[1] + splitPhone[2] + splitPhone[3] + ' '
				+ splitPhone[4] + splitPhone[5] + splitPhone[6] + splitPhone[7] + splitPhone[8]
				+ splitPhone[9];
		}

		// (333) 444-5555
		return '(' + splitPhone[0] + splitPhone[1] + splitPhone[2] + ') '
			+ splitPhone[3] + splitPhone[4] + splitPhone[5] + '-'
			+ splitPhone[6] + splitPhone[7] + splitPhone[8] + splitPhone[9];
	}

}

<pk-broker-modal>
	<ng-template #headerTemplate>
		<h5 class="modal-title">Agent Lookup</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<form [formGroup]="searchForm" *ngIf="searchForm" (submit)="onSearch()">
			<div class="input-group">
				<input
					type="text"
					class="form-control"
					placeholder="Search - (Minimum of 4 characters required)"
					formControlName="search"
					required
					(keyup)="onSearch()" />
				<div class="input-group-append">
					<button type="button" class="btn btn-default" [disabled]="!searchForm.valid" (click)="searchForm.reset()">
						<fa-icon [icon]="['fas', 'times']"></fa-icon>
					</button>
					<button type="button" class="btn btn-default" [disabled]="!searchForm.valid" (click)="onSearch()">
						<fa-icon [icon]="['fas', 'search']"></fa-icon>
					</button>
				</div>
			</div>
		</form>
		<dx-data-grid
			id="gridContainer"
			[dataSource]="agentsDataSource"
			[allowColumnResizing]="true"
			[columnAutoWidth]="true"
			[showBorders]="true"
			[remoteOperations]="true"
			[rowAlternationEnabled]="true"
			[hoverStateEnabled]="true"
			(onInitialized)="onAgentsDataGridInit($event)">
			<dxo-scrolling [useNative]="true"></dxo-scrolling>
			<dxo-header-filter [visible]="true"></dxo-header-filter>
			<dxi-column
				caption="Name"
				cellTemplate="nameCellTemplate"
				dataField="name"
				[allowHeaderFiltering]="false"
				sortOrder="asc"
				width="250"></dxi-column>
			<dxi-column caption="City" dataField="city" dataType="string" [allowHeaderFiltering]="false" width="200"></dxi-column>
			<dxi-column
				caption="State"
				cellTemplate="stateCellTemplate"
				dataField="state_short"
				dataType="string"
				[allowHeaderFiltering]="false"></dxi-column>
			<dxi-column caption="Phone" dataField="phone" dataType="string" [allowHeaderFiltering]="false"></dxi-column>
			<dxi-column caption="Email" dataField="email" dataType="string" [allowHeaderFiltering]="false" width="250"></dxi-column>
			<dxi-column caption="Active" dataType="boolean" cellTemplate="isActiveCellTemplate" dataField="is_active" [allowSorting]="true">
				<dxo-header-filter [dataSource]="activeSelections"></dxo-header-filter>
			</dxi-column>
			<dxi-column caption="Actions" cellTemplate="actionsCellTemplate" [allowHeaderFiltering]="false"></dxi-column>
			<dxo-paging [pageSize]="25"></dxo-paging>
			<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
			<div *dxTemplate="let d of 'nameCellTemplate'">
				<div class="chart-cell">
					{{ d.data.displayName }}
				</div>
			</div>
			<div *dxTemplate="let d of 'stateCellTemplate'">
				<div class="chart-cell">
					{{ d.data.state ? d.data.state.stateShort : '' }}
				</div>
			</div>
			<div *dxTemplate="let d of 'isActiveCellTemplate'">
				<div class="chart-cell">
					<fa-icon [icon]="['fas', 'check']" *ngIf="d.data.isActive"></fa-icon>
				</div>
			</div>
			<div *dxTemplate="let d of 'actionsCellTemplate'">
				<div class="btn-group" role="group">
					<a class="btn btn-primary" href="javascript:void(0)" (click)="selectAgent(d.data)">Select</a>
				</div>
			</div>
		</dx-data-grid>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" class="btn btn-secondary" (click)="close()">Close</button>
	</ng-template>
</pk-broker-modal>

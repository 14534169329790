export class MarketUpdateCompetitiveMatrixRate {

	public state: string;
	public serviceTypeId: string;
	public zone: string;
	public rateClass: string;
	public utility: string;
	public bestRate: number;
	public bestRateFmt: string;
	public ptc: number;
	public ptcFmt: string;
	public difference: number;
	public differenceFmt: string;
	public units: string;
	public units2: string;
	public effectiveDate: string;
	public term: number;
	public minUsage: number;

	constructor(marketUpdateCompetitiveMatrixRate: MarketUpdateCompetitiveMatrixRate) {
		Object.assign(this, marketUpdateCompetitiveMatrixRate);
	}
}

import { ContractLocation } from './contract-location';

export class ReportBillingConsumption {

	public addDate: string;
	public amount: number;
	public billPeriodEndDate: string;
	public billPeriodStartDate: string;
	public contractLocationId: string;
	public dueDate: string;
	public id: number;
	public invoiceDate: string;
	public isPaid: boolean;
	public modifiedDate: string;
	public usage: string;

	public contractLocation: ContractLocation;

	constructor(report?: ReportBillingConsumption) {
		Object.assign(this, report);
	}
}

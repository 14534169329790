<ng-container *ngIf="controlType === 'filter'">
	<p class="compact-select-label font-size-085 mb-0">
		{{ label }} <a *ngIf="contract?.contractId" [routerLink]="['/contract', contract.contractId, 'info']" target="_blank">(Show Full Details)</a>
		<a href="javascript:void(0)" class="ml-1 dark-blue-link semibold-text" (click)="showLookupContract()">
			{{ contract?.contractNum ?? nullContractText }}
		</a>
		<a *ngIf="contract" href="javascript:void(0)" (click)="clearContract()">
			<fa-icon [classes]="['text-danger', 'ml-2']" [icon]="['fas', 'times']"></fa-icon>
		</a>
	</p>
</ng-container>

<ng-container *ngIf="controlType === 'form'">
	<label class="title" [for]="inputId">
		{{ label }}
		<a *ngIf="contract?.contractId" [routerLink]="['/contract', contract.contractId, 'info']" target="_blank">(Show Full Details)</a>
	</label>
	<div class="input-group">
		<input type="text" class="form-control" [id]="inputId" [value]="contract?.contractNum ?? nullContractText" readonly />
		<div class="input-group-append">
			<button type="button" class="btn btn-default" (click)="clearContract()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon>
			</button>
			<button type="button" class="btn btn-default" (click)="showLookupContract()">
				<fa-icon [icon]="['fas', 'search']"></fa-icon>
			</button>
		</div>
	</div>
</ng-container>

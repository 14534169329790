

import { ReportCommissionBrokerPaid } from './report-commission-broker-paid';

export class ReportAgentCommissionBrokerPaid {

	public name: string;
	public agentId: string;
	public isActive: boolean;
	public isBrother: boolean;
	public commissionCheckMethod: boolean;
	public commissionTotal: number;
	public adjustmentTotal: number;
	public previousNegativeBalance: number;
	public currentNegativeBalance: number;
	public payment: number;
	public commissions: ReportCommissionBrokerPaid[];
	public commissionsAggregate: ReportCommissionBrokerPaid[];

	constructor(reportAgentCommissionBrokerPaid: ReportAgentCommissionBrokerPaid) {
		Object.assign(this, reportAgentCommissionBrokerPaid);

		this.commissions = this.commissions ? this.commissions.map(c => new ReportCommissionBrokerPaid(c)) : this.commissions;
	}
}

import * as moment from 'moment-timezone';

export class EmailAnalytic {

	public id: number;
	public isOpen: number;
	public isClicked: number;
	public unsubscribeDate: Date;

	constructor(emailAnalytic?: EmailAnalytic) {
		Object.assign(this, emailAnalytic);

		this.unsubscribeDate = this.unsubscribeDate
			? new Date(moment(this.unsubscribeDate).format('MMM DD, YYYY hh:mm:ss a')) : this.unsubscribeDate;
	}
}

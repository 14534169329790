export class DisconnectionStatus {

	public contractLocationId: string;
	public disconnectionDate: Date;
	public unpaidAmount: number;

	constructor(disconnectionStatus?: DisconnectionStatus) {
		Object.assign(this, disconnectionStatus);
	}
}

import * as moment from 'moment-timezone';

import { User } from 'src/app/shared/models';
import { Agent } from './agent';
import { Contract } from './contract';

export class AccountAdjustment {

	public id: number;
	public paidDate: Date;
	public agentId: string;
	public contractId: string;
	public userId: string;
	public modifiedUserId: string;
	public amount: number;
	public description: string;
	public addDate: string;
	public modifiedDate: string;
	public isOnHold: boolean;
	public queueStatus: string;

	public modifiedUser: User;
	public user: User;
	public agent: Agent;
	public contract: Contract;

	constructor(accountAdjustment?: AccountAdjustment) {
		Object.assign(this, accountAdjustment);

		this.paidDate = this.paidDate
			? new Date(moment(this.paidDate).format('MMM DD, YYYY 00:00:00 a')) : this.paidDate;
		this.contract = this.contract ? new Contract(this.contract) : this.contract;
		this.agent = this.agent ? new Agent(this.agent) : this.agent;
		this.user = this.user ? new User(this.user) : this.user;
	}
}

import { DatePipe } from '@angular/common';
import { CONSTANTS } from '@pk/powerkioskutils';

import * as _ from 'lodash';
import * as moment from 'moment-timezone';

import { ServiceLocator } from '../service-locator.service';
import { Contract } from './contract';
import { Rate } from './rate';
import { RfqSessionBid } from './rfq-session-bid';
import { RfqSessionMarketSuggestion } from './rfq-session-market-suggestion';
import { RfqSessionProduct } from './rfq-session-product';
import { RfqSessionStat } from './rfq-session-stat';
import { RfqSessionSupplier } from './rfq-session-supplier';

export class RfqSession {

	public id: string;
	public contractId: string;
	public agentId: string;
	public isActive: boolean;
	public maxBids: number;
	public maxExtends: number;
	public startDate: Date | string;
	public startTime: Date | string;
	public endDate: Date | string;
	public endTime: Date | string;
	public instructions: string;
	public instructionsAdmin: string;
	public isOnHold: boolean;
	public startDate2: Date;
	public endDate2: Date;
	public releasedDate: Date;
	public isCustomerInvited: boolean;
	public pendingExtension: boolean;
	public userId: string;
	public addDate: Date;
	public marketSuggestionEffectiveDate: Date;

	public margin: number;
	public uploadDate: Date | string;
	public lastBidTime: Date;
	public isSaas: boolean; // rolled-up from contract

	public suppliers: RfqSessionSupplier[];
	public bids: RfqSessionBid[];
	public products: RfqSessionProduct[];
	public contract: Contract;
	public bestBids: RfqSessionStat[];
	public lastBidDays: { [key: string]: string[] }; // for now max out at 4
	public productBidsAnalysis: RfqSessionProduct[];
	public marketSuggestions: RfqSessionMarketSuggestion[];

	public rates: Rate[];

	constructor(rfqSession?: RfqSession) {
		Object.assign(this, rfqSession);

		this.contract = this.contract ? new Contract(this.contract) : this.contract;
		this.startDate = this.startDate ? new Date(moment(this.startDate).format('MMM DD, YYYY hh:mm:ss a')) : this.startDate;
		this.startTime = this.startTime ? new Date(moment(this.startTime).format('MMM DD, YYYY hh:mm:ss a')) : this.startTime;
		this.endDate = this.endDate ? new Date(moment(this.endDate).format('MMM DD, YYYY hh:mm:ss a')) : this.endDate;
		this.endTime = this.endTime ? new Date(moment(this.endTime).format('MMM DD, YYYY hh:mm:ss a')) : this.endTime;
		this.endDate2 = this.endDate2 ? new Date(moment(this.endDate2).format('MMM DD, YYYY hh:mm:ss a')) : this.endDate2;
		this.startDate2 = this.startDate2 ? new Date(moment(this.startDate2).format('MMM DD, YYYY hh:mm:ss a')) : this.startDate2;

		this.products = this.products ? this.products.map(p => new RfqSessionProduct(p)) : this.products;
		this.suppliers = this.suppliers ? this.suppliers.map(s => new RfqSessionSupplier(s)) : this.suppliers;
		this.bids = this.bids ? this.bids.map(b => new RfqSessionBid(b)) : this.bids;
		this.marketSuggestions = this.marketSuggestions
			? this.marketSuggestions.map(s => new RfqSessionMarketSuggestion(s))
			: this.marketSuggestions;

		this.setupProductsAndBids();
	}

	public setupProductsAndBids(): void {
		const datePipe = ServiceLocator.injector.get(DatePipe);
		this.lastBidDays = {};
		if (this.bids && this.bids.length) {
			this.bids.forEach(bid => {
				bid.rfqSessionProduct = this.products && this.products.length
					? this.products.find(p => p.id === bid.rfqSessionProductId)
					: null;
				if (!this.lastBidTime || ((bid.addDate as Date).valueOf() > this.lastBidTime.valueOf())) {
					this.lastBidTime = bid.addDate as Date;
				}
			});

			// sort the bids by addDate then add them to the lastbiddays list
			_.chain(this.bids)
				.groupBy(b => b.supplierId)
				.toPairs()
				.map(g => {
					if (!this.lastBidDays[g[0]]) {
						this.lastBidDays[g[0]] = [];
					}
					_.orderBy(g[1], [b => b.addDate.valueOf()], ['desc']).forEach(b => {
						const bidDate = datePipe.transform(b.addDate, 'MMM dd, yyyy');
						if (!this.lastBidDays[g[0]].includes(bidDate) && this.lastBidDays[g[0]].length < 4) {
							this.lastBidDays[g[0]].push(bidDate);
						}
					});
					this.lastBidDays[g[0]] = _.reverse(this.lastBidDays[g[0]]);
				})
				.value();
		}

		if (this.products && this.products.length) {
			this.products.forEach(product => {
				product.bestBids = this.bestBids && this.bestBids.length
					? _.orderBy(this.bestBids.filter(b => b.rfqSessionProductId === product.id), 'rank')
					: null;
				product.bids = this.bids && this.bids.length
					? _.orderBy(this.bids.filter(b => b.rfqSessionProductId === product.id), [b => b.addDate.valueOf()], ['desc'])
					: [];

				// grab the best rate for each of the last bid days for the product
				product.bidsAnalysis = {};
				_.chain(product.bids)
					.groupBy(b => b.supplierId)
					.toPairs()
					.map(g => {
						_.chain(g[1])
							.groupBy(b => datePipe.transform(b.addDate, 'MMM dd, yyyy'))
							.toPairs()
							.filter(b => this.lastBidDays[g[0]].includes(b[0]))
							.map(gb => {
								if (!product.bidsAnalysis[g[0]]) {
									product.bidsAnalysis[g[0]] = {};
								}
								product.bidsAnalysis[g[0]][gb[0]] = _.orderBy(gb[1], a => a.rate)[0];
							})
							.value();
					})
					.value();

				// grab the latest rates per supplier per product
				product.latestBids = {};
				product.latestBidDays = {};
				_.chain(product.bids)
					.groupBy(b => b.supplierId)
					.toPairs()
					.map(g => {
						_.chain(g[1])
							.groupBy(b => datePipe.transform(b.addDate, 'MMM dd, yyyy'))
							.toPairs()
							.orderBy(gb => new Date(gb[0]).getTime(), 'desc')
							.map(gb => {
								if (!product.latestBids[g[0]]) {
									product.latestBidDays[g[0]] = [gb[0]];
									product.latestBids[g[0]] = { [gb[0]]: gb[1][0] };
								} else {
									if (product.latestBidDays[g[0]].length < 3) {
										product.latestBidDays[g[0]].unshift(gb[0]);
										product.latestBids[g[0]][gb[0]] = gb[1][0];
									}
								}
							})
							.value();
					})
					.value();
			});
		}

		if (this.suppliers && this.suppliers.length) {
			this.suppliers.forEach(supplier => {
				supplier.bids = this.bids && this.bids.length ? this.bids.filter(b => b.supplierId === supplier.supplierId) : [];
			});
		}
	}

	public formatEndDate(isSupplierUser = false, isSaas = false): string {
		const datePipe = ServiceLocator.injector.get(DatePipe);

		const endDate = isSupplierUser && !isSaas
			? new Date(new Date(moment(this.endDate2).format('MMM DD, YYYY hh:mm:ss a'))
				.setHours(this.endDate2.getHours() + CONSTANTS.supplierAuctionEndDateOffset))
			: this.endDate2;

		// never convert to before 10am
		if (endDate.getHours() < 10) {
			endDate.setHours(10);
		}

		return datePipe.transform(endDate, 'MM/dd/yyyy hh:mm a');
	}

	public getSupplierLastBidTime(supplierId: string): number {
		if (!this.bids || !this.bids.length) { return null; }

		const filteredBids = this.bids.filter(bid => bid.supplierId === supplierId);

		return filteredBids
			.reduce((lastBidTime, currBid) =>
				new Date(currBid.addDate).valueOf() > lastBidTime ? new Date(currBid.addDate).valueOf() : lastBidTime, 0,
			);
	}

	public hasSupplierBid(supplierId: string): boolean {
		if (!this.bids || !this.bids.length) { return false; }

		const filteredBids = this.bids.filter(bid => bid.supplierId === supplierId);

		return !!filteredBids.length;
	}
}

import * as moment from 'moment-timezone';

export class StripeCard {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	public exp_month: number;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	public exp_year: number;
	public last4: string;
	public brand: string;
	public id: string;

	constructor(card: StripeCard) {
		Object.assign(this, card);
	}
}

export class StripeCardsWrapper {
	public data: StripeCard[];
	public object: string;
}

export class StripeLineItem {
	public description: string;
}

export class StripeLineItemWrapper {
	public data: StripeLineItem[];
}

export class StripeCharge {
	public amount: number;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	public amount_refunded: number;
}

export class StripeInvoice {

	public number: string;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	public due_date: string | Date;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	public amount_due: number;
	public created: string | Date;
	public status: string;
	public charge: StripeCharge;
	public lines: StripeLineItemWrapper;

	constructor(invoice: StripeInvoice) {
		Object.assign(this, invoice);

		this.created = this.created
			? new Date(moment.unix(Number(this.created)).format('MMM DD, YYYY 00:00:00 a')) : this.created;
		this.due_date = this.due_date
			? new Date(moment.unix(Number(this.due_date)).format('MMM DD, YYYY 00:00:00 a')) : this.due_date;
	}
}

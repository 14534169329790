export class UserAPIKey {

	public id: number;
	public modifiedDate: string;
	public userId: string;
	public key: string;
	public description: string;
	public referrer: string;
	public addDate: string;
	public limit: number;
	public allowsGraphQL: boolean;
	public allowsLogin: boolean;

	constructor(userApiKey?: UserAPIKey) {
		Object.assign(this, userApiKey);
	}
}

<div class="row" *ngFor="let currentDay of daysOfWeek; let i = index">
	<div [ngClass]="{ 'col-sm-6 col-md-5': pageType === 1, 'col-6 mb-3': pageType === 2 }">
		<label *ngIf="pageType === 1">{{ currentDay }}</label>
		<div *ngIf="pageType !== 1">{{ currentDay }}</div>
	</div>
	<div [ngClass]="{ 'col-sm-6 col-md-7': pageType === 1, 'col-6 mb-3 semibold-text': pageType === 2 }">
		<span *ngIf="pageType === 1" class="semibold-text black-text">{{ getWeekdayAvailability(i) }}</span>
		<div *ngIf="pageType !== 1">{{ getWeekdayAvailability(i) }}</div>
	</div>
</div>

import { State } from './state';
import { Supplier } from './supplier';
import { Utility } from './utility';

export class UtilityRateClassMap {

	public id: number;
	public modifiedUserId: string;
	public stateId: string;
	public supplierId: string;
	public userId: string;
	public utilityId: string;
	public zone: string;
	public oldRateClass: string;
	public newRateClass: string;
	public description: string;
	public isActive: boolean;
	public addDate: string;
	public modifiedDate: string;

	public utility: Utility;
	public state: State;
	public supplier: Supplier;

	constructor(utilityRateClassMap?: UtilityRateClassMap) {
		Object.assign(this, utilityRateClassMap);
	}
}

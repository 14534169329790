

export class ContractOptimalPartitionLocation {
	public address1: string;
	public address2: string;
	public city: string;
	public stateShort: string;
	public zipCode: string;
	public annualUsage: number;
	public loadFactor: number;

	constructor(contractOptimalPartitionLocation: ContractOptimalPartitionLocation) {
		Object.assign(this, contractOptimalPartitionLocation);
	}
}

import { ServiceType } from './service-type';
import { State } from './state';
import { Supplier } from './supplier';

export class Product {

	public id: string;
	public productId: string;
	public serviceTypeId: string;
	public name: string;
	public term: number;
	public description: string;
	public isActive: boolean;
	public sortOrder: number;
	public productTypeId: number;

	public serviceType: ServiceType;
	public states: State[];
	public suppliers: Supplier[];

	constructor(product?: any) {
		Object.assign(this, product);
	}
}

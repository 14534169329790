
export class CustomerEditRequirements {

	public showSsn: boolean;
	public showDob: boolean;

	constructor(customerEditRequirements?: CustomerEditRequirements) {
		Object.assign(this, customerEditRequirements);
	}
}

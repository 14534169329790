<label *ngIf="label !== null" [class.required]="isRequired">{{ label }}</label>
<input
	[(ngModel)]="queryText"
	(ngModelChange)="updateDropdownItems($event)"
	[typeahead]="dropdownItems"
	[adaptivePosition]="true"
	[optionsListTemplate]="listTemplate"
	typeaheadOptionField="name"
	(typeaheadOnSelect)="onSelect($event.item.value)"
	[typeaheadMinLength]="typeaheadMinLength"
	[placeholder]="placeholder"
	class="form-control"
	[class.disabled]="disabled"
	[disabled]="disabled" />

<ng-template #listTemplate let-visibleRows="matches" let-typeaheadTemplateMethods>
	<ng-container [ngTemplateOutlet]="header"></ng-container>
	<div class="row scrolling-area medium-scrolling-area">
		<div class="col container-column">
			<div
				*ngFor="let row of visibleRows"
				class="typeahead-item"
				[class.active]="typeaheadTemplateMethods.isActive(row)"
				[class.selected-item]="row.item.value === option"
				(click)="typeaheadTemplateMethods.selectMatch(row, $event)"
				(mouseenter)="typeaheadTemplateMethods.selectActive(row)"
				href="javascript:void(0)">
				<span *ngIf="!rowRef" [innerHTML]="row.item.name"></span>
				<ng-container [ngTemplateOutlet]="rowRef" [ngTemplateOutletContext]="{ $implicit: row.item.value }"></ng-container>
			</div>
		</div>
	</div>
	<ng-container [ngTemplateOutlet]="footer"></ng-container>
</ng-template>

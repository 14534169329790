export class CommissionSplitType {
	public id: number;
	public name: string;
	public sortOrder: number;
	public description: string;

	constructor(commissionSplitType?: CommissionSplitType) {
		Object.assign(this, commissionSplitType);
	}
}

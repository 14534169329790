import { AgentEmailSection } from './agent-email-section';
import { EmailPreference } from './email-preference';
import { EmailSectionGroup } from './email-section-group';
import { EmailSectionType } from './email-section-type';
import { EmailSectionVariable } from './email-section-variable';
import { LeadEmailSection } from './lead-email-section';

export class EmailSection {

	public id: number;
	public emailPreferenceId: number;
	public emailSectionTypeId: number;
	public sortOrder: number;
	public displayName: string;
	public helpText: string;
	public pugName: string;
	public isOptional: boolean;
	public emailSectionGroupId: number;

	public emailPreference: EmailPreference;
	public emailSectionGroup: EmailSectionGroup;
	public emailSectionType: EmailSectionType;
	public defaultAgentEmailSection: AgentEmailSection;
	public defaultLeadEmailSection: LeadEmailSection;
	public emailSectionVariables: EmailSectionVariable[];
	public agentEmailSection: AgentEmailSection;
	public leadEmailSection: LeadEmailSection;

	public get emailSectionVariableNames() {
		return this.emailSectionVariables?.map(v => v.displayName) ?? [];
	}

	constructor(emailSection?: EmailSection) {
		Object.assign(this, emailSection);
	}
}



export class PendingCommissionIssueSubjectType {

	public id: number;
	public name: string;

	constructor(pendingCommissionIssueSubjectType?: PendingCommissionIssueSubjectType) {
		Object.assign(this, pendingCommissionIssueSubjectType);

	}
}

export class PricingAnalyticsSupplierSetting {

	public id: number;
	public supplierId: string;
	public serviceTypeId: string;
	public settings: string;
	public emailRecipients: string;
	public ccRecipients: string;

	public settingsParsed: {
		riskAppetite: 'careEqually' | 'prioritizeMargin' | 'prioritizeContracts';
		yourSimulatedPayableStructure: 'residual' | 'annualUpfront' | 'halfTermUpfrontWithResidual';
		terms: number[];
		electricityUsages: number[];
		naturalGasUsages: number[];
		filterByRateClasses: boolean;
		rateClasses: { rateClass: string[]; stateId: string; zone: string; utilityId: string }[];
		effectiveDateRanges: { lengthOfTime: number; weight: number }[];
		coveragePeriod: {
			coverageType: '1week' | '2week' | '1month' | 'custom';
			customStartDate: string | Date;
			customEndDate: string | Date;
		};
		showAllEffectiveDates: boolean;
		showAllZones: boolean;
		showAsList: boolean;
		showRank: boolean;
		showClusterScore: boolean;
		showClusterOptimizedPrices: boolean;
		emailPkFormattedReport: boolean;
	};

	constructor(pricingAnalyticsSupplierSetting?: PricingAnalyticsSupplierSetting) {
		Object.assign(this, pricingAnalyticsSupplierSetting);

		this.settingsParsed = pricingAnalyticsSupplierSetting?.settings ? JSON.parse(pricingAnalyticsSupplierSetting.settings) : null;
	}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { SupportCenterSupportCallComponent } from './modals/support-center-support-call/support-center-support-call.component';
import { SupportCenterRoutingModule } from './support-center-routing.module';
import { SupportCenterComponent } from './support-center/support-center.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		SupportCenterRoutingModule,
	],
	declarations: [
		SupportCenterComponent,
		SupportCenterSupportCallComponent,
	],
	exports: [
		SupportCenterSupportCallComponent,
	],
})
export class SupportCenterModule { }

import { AlertType, Contract, Ticket } from './';

export class Alert {

	public id: number;
	public modifiedDate: Date;
	public isRead: boolean;
	public addDate: Date;
	public contractId: string;
	public foldedMessage: string;
	public unfoldedMessage: string;
	public expirationDate: Date;
	public alertTypeId: number;
	public ticketId: number;
	public isOpened: boolean;
	public isViewed: boolean;

	public contract: Contract;
	public alertType: AlertType;
	public ticket: Ticket;

	public isCollapsed = true;
	public dismissing: boolean;

	constructor(alert: Alert) {
		Object.assign(this, alert);

		this.contract = this.contract ? new Contract(this.contract) : this.contract;
		this.ticket = this.ticket ? new Ticket(this.ticket) : this.ticket;

		if (this.contract) {
			if (this.alertType.name === 'contract-expiring' ||
				this.alertType.name === 'market-drop-ai') {
				const diffTime = (this.contract.expirationDate as Date).valueOf() - new Date().valueOf();
				const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
				this.foldedMessage = this.foldedMessage.replace(/(expir.*)\b(\d+)\b/, `$1${String(diffDays >= 0 ? diffDays : 0)}`);
				this.unfoldedMessage = this.unfoldedMessage.replace(/(expir.*)\b(\d+)\b/, `$1${String(diffDays >= 0 ? diffDays : 0)}`);
			}
		}
	}

	get panelClass(): string {
		// using isOpened here is correct
		if (!this.isOpened) {
			return 'new';
		}

		return '';
	}
}

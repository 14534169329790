export class PostProcessingCommissionAuditStatus {
	public id: number;
	public postProcessingCommissionAuditStatusTypeId: number;
	public receivableAuditIssueId: number;
	public pendingCommissionId: string;
	public contractLocationId: string;
	public commissionTypeId: number;
	public forecastedMeterStartDate: Date | string;
	public forecastedMeterEndDate: Date | string;
	public description: string;

	constructor(postProcessingCommissionAuditStatus: PostProcessingCommissionAuditStatus) {
		Object.assign(this, postProcessingCommissionAuditStatus);
	}
}

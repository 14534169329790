

export class CompanySupplierReportOverview {

	public totalReports: number;
	public totalActuals: number;
	public receivableDeviation: number;
	public hasPendingReport: boolean;
	public hasFlaggedReport: boolean;
	public hasHighReceivableDeviation: boolean;
	public forecastedAmount: number;
	public reportDeviation: number;

	constructor(companySupplierReportOverview?: CompanySupplierReportOverview) {
		Object.assign(this, companySupplierReportOverview);
	}
}

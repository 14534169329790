export class PlanLoadFactorSetting {
	public statement: string;
	public colorClass: string;
	public sortOrder: number;
	public allowsSignup: boolean;
	public loadFactorDifference: number;

	constructor(private contractLoadFactor: number, private supplierMinimumLoadFactor: number) {
		this.setupLoadFactorSettings();
	}

	private setupLoadFactorSettings(): void {
		if (this.contractLoadFactor >= Number(this.supplierMinimumLoadFactor)) {
			this.statement = 'Meets the Load Factor Requirement.';
			this.sortOrder = 1;
			this.colorClass = 'green-text';
			this.allowsSignup = true;
		} else if (this.contractLoadFactor >= 0.9 * Number(this.supplierMinimumLoadFactor)) {
			this.statement = `Slightly below the Load Factor Requirement. `
				+ `Your margin may be lowered or the contract may be rejected by the supplier.`;
			this.sortOrder = 2;
			this.colorClass = 'orange-text';
			this.allowsSignup = true;
		} else if (this.contractLoadFactor) {
			this.statement = 'Below the Load Factor Requirement.';
			this.sortOrder = 3;
			this.colorClass = 'red-text';
			this.allowsSignup = false;
		} else {
			this.allowsSignup = true;
		}

		this.loadFactorDifference = Math.round(Number(this.contractLoadFactor) - Number(this.supplierMinimumLoadFactor));
	}
}

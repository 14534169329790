export class OwnerTermValueBookedSummary {

	public totalTermValue!: number;
	public termValueBookedYTD!: number;
	public termValueBookedLastMonth!: number;

	constructor(ownerTermValueBookedSummary?: OwnerTermValueBookedSummary) {
		Object.assign(this, ownerTermValueBookedSummary);
	}
}


import { ContractAdvanced } from './contract-advanced';
import { ContractAdvancedStatus } from './contract-advanced-status';

export class ContractsAdvanced {

	public contracts: ContractAdvanced[];
	public status: ContractAdvancedStatus;

	constructor(contractsAdvanced: ContractsAdvanced) {
		Object.assign(this, contractsAdvanced);

		this.contracts = this.contracts ? this.contracts.map(c => new ContractAdvanced(c)) : this.contracts;
		this.status = this.status ? new ContractAdvancedStatus(this.status) : this.status;
	}
}

export class OwnerCompanySnapshot {

	public collectionsYTD!: number;
	public currMonthEstimatedCommission!: number;
	public lastMonthPaidCommission!: number;
	public netRevenue!: number;
	public lifetimeValue!: number;

	constructor(ownerCompanySnapshot?: OwnerCompanySnapshot) {
		Object.assign(this, ownerCompanySnapshot);
	}
}

export class Permission {

	public permissionId: string;
	public roleId: string;
	public name: string;

	constructor(permission: any) {
		Object.assign(this, permission);
	}
}

export class AgentActivityRamping {

	public companyId: string;
	public companyName: string;
	public agentId: string;
	public agentName: string;
	public parentAgentId: string;
	public parentName: string;
	public contactName: string;
	public phone: string;
	public phoneCountryId: number;
	public phoneExt: string;
	public email: string;
	public isActive: boolean;
	public bookingBefore: number;
	public bookingNow: number;
	public bookingChange: number;
	public lastConfirmedDeal: Date;
	public pctRank: number;

	constructor(agentActivityRamping?: AgentActivityRamping) {
		Object.assign(this, agentActivityRamping);
	}
}

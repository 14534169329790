import { Component, Input, OnInit } from '@angular/core';

import { Contract, UtilityMarketSetting } from '../../shared/models';
import { AbstractPageForm } from '../../shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-auction-requirement',
	templateUrl: './auction-requirement.component.html',
	styleUrls: ['./auction-requirement.component.scss'],
})
export class AuctionRequirementComponent extends AbstractPageForm implements OnInit {

	@Input() contract: Contract;

	public utilityMarketSetting: UtilityMarketSetting;
	public requiresOneOf: boolean;

	ngOnInit() {
		this.utilityMarketSetting = this.contract.getUtility()?.utilityMarketSettings
			.find(ust => ust.serviceTypeId === this.contract.serviceTypeId && ust.stateId === this.contract.stateId);
	}
}

<label *ngIf="label !== null" [for]="textInputId" [class.required]="isRequired">{{ label }}</label>
<div class="input-group" *ngIf="!attachments.length">
	<input class="form-control" [id]="textInputId" (change)="onFileChange($event)" [disabled]="disabled" />
	<div class="input-group-append">
		<button class="btn btn-default btn-with-label" type="button" [class.disabled]="disabled">
			<label [for]="fileInputId" [class.disabled]="disabled">{{ loading ? 'Uploading...' : 'Browse' }}</label>
			<input type="file" [accept]="accept" [multiple]="multiple" [id]="fileInputId" (change)="onFileChange($event)" [disabled]="disabled" />
		</button>
	</div>
	<p *ngIf="helperText" class="mb-0 helper-text">{{ helperText }}</p>
</div>
<div class="row" *ngIf="attachments.length">
	<div class="col d-flex align-items-center" *ngFor="let attachment of attachments">
		<a
			*ngIf="attachment.attachmentId"
			[href]="environment.baseUrl + '/attachments/' + attachment.attachmentId + '/view?access_token=' + environment.apiKey"
			class="semibold-text"
			target="_blank">
			{{ attachment.originalName || attachment.name }}
		</a>
		<span *ngIf="!attachment.attachmentId">{{ attachment.originalName || attachment.name }}</span>
		<a class="ml-1 mr-3 no-right-margin bigger-icon" [class.disabled]="disabled" (click)="onClearFile(attachment)">
			<pk-broker-loader [show]="loading"></pk-broker-loader>
			<fa-icon *ngIf="!loading" [icon]="['fas', 'times-circle']" [classes]="['text-danger']"></fa-icon>
		</a>
		<a
			*ngIf="preview"
			class="btn btn-link py-0 px-2 ml-2"
			[attr.href]="environment.baseUrl + '/attachments/' + attachment.attachmentId + '/preview?access_token=' + environment.apiKey"
			target="_blank"
			tooltip="Preview file">
			<fa-icon [icon]="['fas', 'eye']"></fa-icon>
		</a>
	</div>
</div>

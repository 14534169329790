import { RejectReasonSubCategory } from './reject-reason-sub-category';

export class RejectReasonCategory {

	public id: number;
	public name: string;
	public sortOrder: number;

	public subCategories: RejectReasonSubCategory[];

	constructor(rejectChangeCategory?: RejectReasonCategory) {
		Object.assign(this, rejectChangeCategory);

		this.subCategories =
			this.subCategories?.filter(sc => !sc.supplierId).map(sc => new RejectReasonSubCategory(sc, this.subCategories)) ?? [];
	}
}

import { ServiceType } from './service-type';

export class StateServiceType {

	public id: string;
	public stateId: string;
	public serviceTypeId: string;
	public isDeregulated: boolean;

	public serviceType: ServiceType;

	constructor(stateServiceType?: StateServiceType) {
		Object.assign(this, stateServiceType);
	}
}

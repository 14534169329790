export class FaqAudit {

	public id: number;
	public criteria: string;
	public matches: number;
	public terms: string;
	public matchedArticles: string;

	constructor(faqAudit?: FaqAudit) {
		Object.assign(this, faqAudit);
	}
}

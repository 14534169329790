
export class ResidentialMarketRequirement {

	public showSsn: boolean;
	public showDob: boolean;

	constructor(residentialMarketRequirement?: ResidentialMarketRequirement) {
		Object.assign(this, residentialMarketRequirement);
	}
}

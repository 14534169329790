export class EmailAnalyticsInput {

	public agentId: string;
	public startSendDate: string;
	public endSendDate: string;
	public startSendTime: string;
	public endSendTime: string;

	constructor(emailAnalyticsInput?: EmailAnalyticsInput) {
		Object.assign(this, emailAnalyticsInput);
	}
}

<div
	#alert
	class="alert pk-alert-banner alert-dismissible fade text-center"
	[ngClass]="{
		show: show,
		'customer-alert': type === CONSTANTS.bannerAlertTypes.customerReferral,
		'half-blue-banner': [
			CONSTANTS.bannerAlertTypes.brokerReferral,
			CONSTANTS.bannerAlertTypes.leadLink,
			CONSTANTS.bannerAlertTypes.customerReferral
		].includes(type),
		'green-banner': type === CONSTANTS.bannerAlertTypes.annualMembership
	}"
	role="alert">
	<p class="mb-0 font-size-1">
		<span [innerHtml]="callToAction"></span>
		<a href="javascript:void(0)" role="button" class="btn btn-banner btn-sm mx-2" (click)="showLearnMoreModal()" id="topBannerAlertLearnMoreBtn"
			>Learn More</a
		>
	</p>
	<button type="button" class="close align-self-center" (click)="hideAlert()" id="topBannerAlertCloseBtn">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

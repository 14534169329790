

import * as moment from 'moment-timezone';

export class ViewPayableForecast {
	public id: number;
	public contractId: string;
	public customerId: string;
	public contractLocationId: string;
	public commissionTypeId: number;
	public agentId: string;
	public companyId: string;
	public isDirectSale: boolean;
	public contractNum: string;
	public dbaName: string;
	public utilityAccountNum: string;
	public amount: number;
	public releaseDate: Date;
	public date: Date;
	public commissionTypeName: string;
	public confirmationDate: Date;
	public effectiveDate: Date;
	public cycleStartDate: Date;
	public cycleEndDate: Date;
	public isProcessed: boolean;
	public agentName: string;
	public usage: number;
	public confirmedAnnualUsage: number;
	public margin: number;
	public rate: number;
	public units: string;
	public symbol: string;
	public commissionPayableIssueTypeId: number;
	public commissionPayableStatusTypeId: number;
	public commissionPayableStatusTypeName: string;
	public commissionPayableIssueTypeName: string;
	public description: string;
	public receivableAddDate: Date;
	public dateOffset: number;
	public commissionPayPlanTypeId: number;
	public issues: string;
	public exchangeRate: number;

	constructor(viewPayableForecast?: ViewPayableForecast) {
		Object.assign(this, viewPayableForecast);

		this.cycleStartDate = this.cycleStartDate
			? new Date(moment(this.cycleStartDate).format('MMM DD, YYYY 00:00:00 a')) : this.cycleStartDate;
		this.cycleEndDate = this.cycleEndDate
			? new Date(moment(this.cycleEndDate).format('MMM DD, YYYY 00:00:00 a')) : this.cycleEndDate;
		this.releaseDate = this.releaseDate
			? new Date(moment(this.releaseDate).format('MMM DD, YYYY 00:00:00 a')) : this.releaseDate;
		this.date = this.date
			? new Date(moment(this.date).format('MMM DD, YYYY 00:00:00 a')) : this.date;
		this.confirmationDate = this.confirmationDate
			? new Date(moment(this.confirmationDate).format('MMM DD, YYYY 00:00:00 a')) : this.confirmationDate;
		this.effectiveDate = this.effectiveDate
			? new Date(moment(this.effectiveDate).format('MMM DD, YYYY 00:00:00 a')) : this.effectiveDate;
		this.receivableAddDate = this.receivableAddDate
			? new Date(moment(this.receivableAddDate).format('MMM DD, YYYY 00:00:00 a')) : this.receivableAddDate;
	}
}

export class MarketUpdateLogMap {

	public json: string;
	public logMap: { [id: number]: Date };

	constructor(marketUpdateLogMap: MarketUpdateLogMap) {
		Object.assign(this, marketUpdateLogMap);

		this.logMap = this.json ? JSON.parse(this.json) : null;
		if (this.logMap) {
			// eslint-disable-next-line guard-for-in
			for (const id in this.logMap) {
				this.logMap[id] = new Date(this.logMap[id]);
			}
		}
	}
}

import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { Customer } from 'src/app/shared/models';
import { AddressValidation, AddressValidationReqResObj } from '../../models/address-validation';

@Component({
	selector: 'pk-broker-address-validation',
	templateUrl: './address-validation.component.html',
	styleUrls: ['./address-validation.component.scss']
})
export class AddressValidationModalComponent {
	public onResponse: Subject<{ addressType: string; response: string; locationIndex?: number; isComplete: boolean }> = new Subject();

	public showContact = true;
	public chooseContactEntered = false;
	public chooseContactSuggested = false;

	public showBilling = true;
	public chooseBillingEntered = false;
	public chooseBillingSuggested = false;

	public enteredContactAddress: string;
	public suggestedContactAddress: string;

	public enteredBillingAddress: string;
	public suggestedBillingAddress: string;

	public locations: {
		enteredLocationAddress: string;
		suggestedLocationAddress: string;
		chooseEntered: boolean;
		chooseSuggested: boolean;
	}[];

	public atLeastOneInvalidLocation: boolean;

	constructor(public activeModal: BsModalRef) { }

	public init(addressObj: AddressValidationReqResObj) {
		[this.enteredContactAddress, this.suggestedContactAddress] = this.formatAddressValidationObj(addressObj.contact);
		[this.enteredBillingAddress, this.suggestedBillingAddress] = this.formatAddressValidationObj(addressObj.billing);

		this.locations = addressObj.locations
			? addressObj.locations.map(this.formatLocationsArr.bind(this))
			: null;

		this.atLeastOneInvalidLocation = this.locations && this.locations.some(obj => obj.enteredLocationAddress);
	}

	public handleResponse(addressType: string, response: string, locationIndex?: number) {
		if (addressType === 'location') {
			if (response === 'override') {
				this.locations[locationIndex].chooseEntered = true;
			} else {
				this.locations[locationIndex].chooseSuggested = true;
			}
		}

		if (addressType === 'billing') {
			if (response === 'override') {
				this.chooseBillingEntered = true;
			} else {
				this.chooseBillingSuggested = true;
			}
		}

		if (addressType === 'contact') {
			if (response === 'override') {
				this.chooseContactEntered = true;
			} else {
				this.chooseContactSuggested = true;
			}
		}

		let isComplete = false;

		// If nothing would display in the modal because they accepted or overrided everything, close the modal
		if ((!this.locations || this.locations.every(el => el.chooseEntered || el.chooseSuggested || el.enteredLocationAddress === null)) &&
			(!this.enteredBillingAddress || this.chooseBillingEntered || this.chooseBillingSuggested) &&
			(!this.enteredContactAddress || this.chooseContactEntered || this.chooseContactSuggested)
		) {
			isComplete = true;
			this.activeModal.hide();
		}

		this.onResponse.next({ addressType, response, locationIndex, isComplete });
	}

	private formatLocationsArr(obj) {
		const [
			enteredLocationAddress,
			suggestedLocationAddress
		] = this.formatAddressValidationObj(obj);

		return { enteredLocationAddress, suggestedLocationAddress };
	}

	private formatAddressValidationObj(obj: AddressValidation): string[] {
		if (!obj) {
			return [null, null];
		}

		const enteredAddress = Customer.prototype.getFullAddressLine.call({
			...obj.request,
			state: { stateShort: obj.request.state },
			zipCode: obj.request.zip?.split('-')[0]
		}, true);

		const suggestedAddress = Customer.prototype.getFullAddressLine.call({
			...obj.response,
			zipCode: obj.response.zip?.split('-')[0]
		}, true);

		return [enteredAddress, suggestedAddress];
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { CONSTANTS } from '@pk/powerkioskutils';

import * as _ from 'lodash';

import { ViewAgentEngagementEvent } from '../models';

@Pipe({
	name: 'viewAgentEngagementEventsSort',
})
export class ViewAgentEngagementEventsSortPipe implements PipeTransform {
	transform(
		viewAgentEngagementEvents: ViewAgentEngagementEvent[],
		agentEngagementEventCategoryId: number,
		dateSort: { text: string; column: string; direction: string }
	): ViewAgentEngagementEvent[] {

		if (agentEngagementEventCategoryId === CONSTANTS.agentEngagementEventCategories.potentialChurn) {
			return _.orderBy(
				viewAgentEngagementEvents,
				[
					e => (e.followUpDate ?? new Date()).valueOf(),
					e => e.churnStatusSort,
					e => e.eventDetailsJson.isDormant
						? e.agentAddDate
						: e.eventDetailsJson.churnPrediction || 0,
					dateSort.column,
					e => e.agentTotalTermValue || 0,
				],
				[
					'asc',
					'asc', // churnPrediction value is modified in view-agent-engagement.ts so that 'asc' always works as expected
					'asc',
					dateSort.direction === 'desc' ? 'desc' : 'asc',
					'desc',
				],
			);
		}

		if (agentEngagementEventCategoryId === 11) {
			return _.take(_.orderBy(
				viewAgentEngagementEvents,
				[
					e => (e.followUpDate ?? new Date()).valueOf(),
					dateSort.column,
					e => e.contractTotalTermValue || 0,
				],
				[
					'asc',
					dateSort.direction === 'desc' ? 'desc' : 'asc',
					'desc',
				],
			), 25);
		}

		return _.orderBy(
			viewAgentEngagementEvents,
			[
				e => (e.followUpDate ?? new Date()).valueOf(),
				dateSort.column,
				e => e.agentTotalTermValue || 0,
			],
			[
				'asc',
				dateSort.direction === 'desc' ? 'desc' : 'asc',
				'desc',
			]
		);
	}
}

export class EducationType {

	public id: number;
	public name: string;
	public sortOrder: number;

	constructor(educationType?: EducationType) {
		Object.assign(this, educationType);
	}
}

import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { User } from '../models';

declare const pkDataLayer: any;

@Injectable({
	providedIn: 'root',
})
export class GoogleTagManagerService {

	public sendPageEvent(action: string, user: User, title: string): void {
		if (user && user.agentId) {
			this.fireTagEvent({
				event: title,
				'agent-id': user.agentId,
				action,
			});
		}
	}

	public fireTagEvent(args: { event: string; 'agent-id'?: string; value?: string; action?: string }) {
		if (pkDataLayer && environment.production && !environment.demo) {
			pkDataLayer.push(args);
		}
	}
}

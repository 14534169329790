import * as moment from 'moment-timezone';

import { AgentEngagementEvent, Lead, NoteType, PendingCommission, User } from './';

export class Note {

	public contractId: string;
	public noteId: string;
	public agentId: string;
	public subject: string;
	public body: string;
	public isInternal: boolean;
	public addDate: Date;
	public userId: string;
	public noteTypeId: string;
	public pendingCommissionId: number;
	public supplierCommissionAttachmentId: number;
	public invoiceId: number;
	public agentEngagementEventId: number;
	public leadId: number;

	public user: User;
	public noteType: NoteType;
	public pendingCommission: PendingCommission;
	public agentEngagementEvent: AgentEngagementEvent;
	public lead: Lead;

	// internal
	public deleting: boolean;

	constructor(note?: Note) {
		Object.assign(this, note);

		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
		this.user = this.user ? new User(this.user) : this.user;
		this.noteType = this.noteType ? new NoteType(this.noteType) : this.noteType;
		this.pendingCommission = this.pendingCommission
			? new PendingCommission(this.pendingCommission) : this.pendingCommission;
		this.agentEngagementEvent = this.agentEngagementEvent
			? new AgentEngagementEvent(this.agentEngagementEvent) : this.agentEngagementEvent;
		this.lead = this.lead
			? new Lead(this.lead) : this.lead;
	}
}

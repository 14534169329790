import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

@Pipe({
	name: 'filterPricingAnalyticsTerms',
})
export class FilterPricingAnalyticsTermsPipe implements PipeTransform {

	transform(terms: number[],
		stateId: string,
		utilityId: string,
		allFields: { effectiveDate: string; term: number; usage: number; stateId: string; utilityId: string }[],
		effectiveDate?: string, usage?: number,
	): unknown {

		return _.chain(allFields)
			.filter(f =>
				(!effectiveDate || f.effectiveDate === effectiveDate)
				&& (!usage || f.usage === usage)
				&& (!utilityId || f.utilityId === utilityId)
				&& (!stateId || f.stateId === stateId))
			.map(f => f.term).uniq().orderBy(t => t).value();
	}

}

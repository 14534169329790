export class ReportCommissionPaid {

	public dbaName: string;
	public accountAdjustmentId: string;
	public actualUsage: number;
	public addDate: Date;
	public adjustmentDescription: string;
	public amount: number;
	public className: string;
	public commissionCheckMethod: number;
	public commissionId: string;
	public commodity: string;
	public contractId: string;
	public contractNum: string;
	public accountUnderAudit: boolean;
	public description: string;
	public estimatedUsage: number;
	public isActive: number;
	public margin: number;
	public meterEndDate: Date;
	public meterStartDate: Date;
	public promoCode: string;
	public stateLong: string;
	public status: number;
	public supplierName: string;
	public term: number;
	public units: string;
	public utilityAccountNum: number;

	constructor(reportCommissionPaid: ReportCommissionPaid) {
		Object.assign(this, reportCommissionPaid);
	}
}

import { Component, Input } from '@angular/core';

@Component({
	selector: 'pk-broker-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {

	@Input() show = false;
	@Input() color = 'black';
	@Input() text: string;
	@Input() display = 'flex';
	@Input() extraClasses = '';
	@Input() textAlign = 'text-center';

}

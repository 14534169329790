import { Pipe, PipeTransform } from '@angular/core';

import { MarketUpdateAuction } from '../models';

@Pipe({
	name: 'marketUpdateAuctionFilter'
})
export class MarketUpdateAuctionFilterPipe implements PipeTransform {
	transform(auctions: MarketUpdateAuction[], serviceTypeId: string): unknown {
		return auctions ? auctions.filter(a => a.serviceTypeId === serviceTypeId) : auctions;
	}
}

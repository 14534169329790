import { PricingAnalyticsUtilityPerformancesChartPoint } from './pricing-analytics-utility-performances-chart-point';

export class PricingAnalyticsUtilityPerformancesChartType {
	public name: string;
	public businessPerformancesChart: PricingAnalyticsUtilityPerformancesChartPoint;

	constructor(pricingAnalyticsUtilityPerformancesChartType: PricingAnalyticsUtilityPerformancesChartType) {
		Object.assign(this, pricingAnalyticsUtilityPerformancesChartType);

		this.businessPerformancesChart = this.businessPerformancesChart
			? new PricingAnalyticsUtilityPerformancesChartPoint(this.businessPerformancesChart) : this.businessPerformancesChart;
	}
}

import { PricingAnalyticsUtilityPerformances } from './pricing-analytics-utility-performances';
import { PricingAnalyticsUtilityPerformancesChartType } from './pricing-analytics-utility-performances-chart-type';
import { ViewPricingAnalyticsUtilityPerformance } from './view-pricing-analytics-utility-performance';

export class PricingAnalyticsCompetitiveUtilityPerformances {
	businessPerformances: PricingAnalyticsUtilityPerformances;
	businessPerformancesCharts: PricingAnalyticsUtilityPerformancesChartType[];
	mostOptimalUtilities: ViewPricingAnalyticsUtilityPerformance[];
	leastOptimalUtilities: ViewPricingAnalyticsUtilityPerformance[];

	constructor(pricingAnalyticsCompetitiveUtilityPerformances: PricingAnalyticsCompetitiveUtilityPerformances) {
		Object.assign(this, pricingAnalyticsCompetitiveUtilityPerformances);

		this.businessPerformances = this.businessPerformances
			? new PricingAnalyticsUtilityPerformances(this.businessPerformances) : this.businessPerformances;
		this.businessPerformancesCharts = this.businessPerformancesCharts
			? this.businessPerformancesCharts.map(b => new PricingAnalyticsUtilityPerformancesChartType(b))
			: this.businessPerformancesCharts;
		this.mostOptimalUtilities = this.mostOptimalUtilities
			? this.mostOptimalUtilities.map(b => new ViewPricingAnalyticsUtilityPerformance(b))
			: this.mostOptimalUtilities;
		this.leastOptimalUtilities = this.leastOptimalUtilities
			? this.leastOptimalUtilities.map(b => new ViewPricingAnalyticsUtilityPerformance(b))
			: this.leastOptimalUtilities;
	}
}

import { EmailPreference } from './email-preference';

export class EmailCampaign {

	public id: number;
	public name: string;
	public description: string;
	public leadTypeId: number;
	public emailCampaignTypeId: number;

	public emailPreferences: EmailPreference[];

	constructor(emailCampaign: EmailCampaign) {
		Object.assign(this, emailCampaign);

		this.emailPreferences = this.emailPreferences ? this.emailPreferences.map(e => new EmailPreference(e)) : this.emailPreferences;
	}
}

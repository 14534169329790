import * as moment from 'moment-timezone';

export class ViewSupplierCommissionAttachmentDeposit {

	public id: number;
	public depositId: number;
	public supplierCommissionAttachmentId: number;
	public isApproved: boolean;
	public paymentAmount: number;
	public depositDate: Date;
	public depositAmount: number;
	public reportDate: Date;
	public reportAmount: number;

	//Variables not in sql table
	public displayDate: Date;

	constructor(viewSupplierCommissionAttachmentDeposit?: ViewSupplierCommissionAttachmentDeposit) {
		Object.assign(this, viewSupplierCommissionAttachmentDeposit);

		this.depositDate = this.depositDate ? new Date(moment(this.depositDate).format('MMM DD, YYYY hh:mm:ss a')) : this.depositDate;
		this.reportDate = this.reportDate ? new Date(moment(this.reportDate).format('MMM DD, YYYY hh:mm:ss a')) : this.reportDate;
	}
}

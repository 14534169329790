import { Education } from './education';
import { EducationCategory } from './education-category';

export class EducationTopic {

	public educationId: number;
	public educationCategoryId: number;

	public category: EducationCategory;
	public education: Education;

	constructor(educationTopic?: EducationTopic) {
		Object.assign(this, educationTopic);
	}
}

import { Component, Input, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Agent, Logo, User } from '../../shared/models';

@Component({
	selector: 'pk-broker-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

	@Input() user: User;
	@Input() agent: Agent;
	@Input() isDirect: boolean;
	@Input() useDefault: boolean;
	@Input() defaultFooterLogo: Logo;

	public environment = environment;
	public year: number;

	ngOnInit(): void {
		this.year = new Date().getFullYear();
	}

	get showBranding(): boolean {
		return (!this.isDirect && (
			(!!this.user || !!this.defaultFooterLogo)) ||
			(this.isDirect && (!!this.agent || !!this.defaultFooterLogo))
		);
	}

	get companyName(): string {
		if (!this.user || !this.user.agent) { return 'Power Kiosk'; }

		return this.user.agent.parent
			? this.user.agent.parent.customerCompany
			: this.user.agent.customerCompany;
	}
}

import { Component, EventEmitter } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';

import { ConfirmationConstructor } from '../../services/notification.service';

@Component({
	selector: 'pk-broker-notification-modal',
	templateUrl: './notification-modal.component.html',
	styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent {

	// inputs
	public title: string;
	public body: string;
	public body2: string;
	public confirmationType: ConfirmationConstructor;
	public customConfirmationButtonText = 'Confirm';

	// outputs
	public onConfirm: EventEmitter<boolean> = new EventEmitter();
	public onCancel: EventEmitter<boolean> = new EventEmitter();

	constructor(
		private modalService: BsModalService
	) { }

	get isConfirm(): boolean {
		return this.confirmationType === 'confirm';
	}

	get isYesNo(): boolean {
		return this.confirmationType === 'yesNo';
	}

	get isOk(): boolean {
		return this.confirmationType === 'ok';
	}

	public close(): void {
		this.modalService.hide('notification');
	}

	public confirm(): void {
		this.onConfirm.emit(true);
		this.close();
	}

	public cancel(): void {
		this.onCancel.emit(true);
		this.close();
	}
}

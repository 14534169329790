export class EmailAnalyticsTopStats {

	public openRate: number;
	public clickRate: number;
	public unsubscribeRate: number;

	constructor(emailAnalyticsTopStats?: EmailAnalyticsTopStats) {
		Object.assign(this, emailAnalyticsTopStats);
	}
}

import { ReportAgentCommissionPaid } from './report-agent-commission-paid';

export class ReportParentCommissionPaid {

	public name: string;
	public agents: ReportAgentCommissionPaid[];

	constructor(reportCommissionAudit: ReportParentCommissionPaid) {
		Object.assign(this, reportCommissionAudit);

		this.agents = this.agents ? this.agents.map(c => new ReportAgentCommissionPaid(c)) : this.agents;
	}
}

import { RfqSessionSupplier } from './rfq-session-supplier';

export class SupplierAuctionEvents {

	public timeToTracking: string;
	public rfqSessionId: string;
	public supplierId?: string;
	public releasedDate: Date;

	public notVisited: RfqSessionSupplier[];
	public needsRefreshedPricing: RfqSessionSupplier[];
	public notDownloadedRFP: RfqSessionSupplier[];
	public waitingOnDocs: RfqSessionSupplier[];
	public waitingOnLOAs: RfqSessionSupplier[];
	public waitingOnLOEs: RfqSessionSupplier[];
	public waitingOnSampleBills: RfqSessionSupplier[];
	public openedBrokerInfo: RfqSessionSupplier[];
	public noBid: RfqSessionSupplier[];
	public lastBidTimes: Array<{ supplier: RfqSessionSupplier; lastBidTime: Date }>;
	public requestedDocuments: Array<{ supplier: RfqSessionSupplier; document: string; requestedDate: Date }>;

	constructor(supplierAuctionEvents?: SupplierAuctionEvents) {
		Object.assign(this, supplierAuctionEvents);
	}
}

import { FeatureTourLocation, FeatureTourSlide } from './';

export class FeatureTour {

	public id: number;
	public isActive: boolean;
	public name: string;
	public headline: string;
	public description: string;

	public locations: FeatureTourLocation[];
	public slides: FeatureTourSlide[];

	constructor(featureTour?: FeatureTour) {
		Object.assign(this, featureTour);

		this.locations = this.locations ? this.locations.map(l => new FeatureTourLocation(l)) : this.locations;
		this.slides = this.slides ? this.slides.map(s => new FeatureTourSlide(s)) : this.slides;
	}
}

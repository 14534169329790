import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

import { Market } from '../models';

@Pipe({
	name: 'marketOverviewStateFilter'
})
export class MarketOverviewStateFilterPipe implements PipeTransform {

	transform(markets: Market[], serviceTypeId: string, utilityId: string): { stateLong: string; stateId: string }[] {
		return _.chain(markets)
			.filter(m =>
				(!serviceTypeId || m.serviceType.serviceTypeId === serviceTypeId)
				&& (!utilityId || m.utility.utilityId === utilityId)
			)
			.map(m => m.state)
			.uniqBy(m => m.stateId)
			.sortBy(m => m.stateLong)
			.value();
	}

}

import { CompanySupplier } from './company-supplier';
import { CompanySupplierPortalType } from './company-supplier-portal-type';

export class CompanySupplierPortal {

	public id: number;
	public companySupplierId: number;
	public companySupplierPortalTypeId: number;
	public username: string;
	public password: string;
	public url: string;

	public companySupplier: CompanySupplier;
	public companySupplierPortalType: CompanySupplierPortalType;

	constructor(companySupplierPortal: CompanySupplierPortal) {
		Object.assign(this, companySupplierPortal);
	}
}

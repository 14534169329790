export class SupplierAuctionMonitorEmail {

	public id: number;
	public supplierId: string;
	public clickedLoginButton: boolean;

	constructor(supplierAuctionMonitorEmail?: SupplierAuctionMonitorEmail) {
		Object.assign(this, supplierAuctionMonitorEmail);
	}
}

export class SupplierListReport {

	public supplierId: string;
	public name: string;
	public email: string;
	public isActive: boolean;
	public overallAuctionParticipationRatio: number;
	public dailyAuctionParticipationRatio: number;
	public optOutRatio: number;
	public loaRequestRatio: number;
	public noBidRatio: number;
	public numAuctionsWon: number;
	public numAuctionsInvited: number;

	constructor(supplierListReport: SupplierListReport) {
		Object.assign(this, supplierListReport);
	}
}

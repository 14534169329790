export class SupplierListReportTotals {

	public overallAuctionParticipationRatio: number;
	public dailyAuctionParticipationRatio: number;
	public optOutRatio: number;
	public loeRequestRatio: number;

	constructor(supplierListReportTotals?: SupplierListReportTotals) {
		Object.assign(this, supplierListReportTotals);
	}
}

import * as moment from 'moment-timezone';

export class CommissionSummaryMonth {
	public date: string;
	public payment: number;

	constructor(commissionSummaryMonth?: CommissionSummaryMonth) {
		Object.assign(this, commissionSummaryMonth);

		this.date = moment(this.date).format('MMM YYYY');
	}
}

export class CommissionSummary {

	public months: CommissionSummaryMonth[];

	constructor(commissionSummary?: CommissionSummary) {
		Object.assign(this, commissionSummary);

		this.months = this.months.map(m => new CommissionSummaryMonth(m));
	}
}

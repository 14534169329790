
export class AgentAdvancedStatus {

	public timems: number;
	public rid: string;

	constructor(agentAdvancedStatus: AgentAdvancedStatus) {
		Object.assign(this, agentAdvancedStatus);
	}
}

import { UntypedFormArray, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

/** Products must all be unique and have at least one chosen on the form */

export const supplierProductsValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
	const productBids = control.get('productBids') as UntypedFormArray;
	const sweetSpotProducts = control.get('sweetSpotProducts') as UntypedFormArray;

	const products = productBids.controls
		.map(c => ({
			id: c.get('entity').value.productId,
			term: c.get('entity').value.term,
			greenPercentage: c.get('entity').value.greenPercentage || 0,
			initialFixedUsage: c.get('entity').value.initialFixedUsage || 0,
		}))
		.concat(sweetSpotProducts.controls.map(s => ({
			id: typeof s.value.type === 'string' ? s.value.type : s.value.type?.id,
			term: s.value.term,
			greenPercentage: s.value.greenPercentage || 0,
			initialFixedUsage: s.value.initialFixedUsage || 0,
		})));

	if (products.length === 0) {
		return { productAmount: true };
	}

	let allUnique = true;
	products.forEach(item => {
		const product = item;
		const matchedProduct = products
			.filter(c => c !== item)
			.find(c => c && product
				&& c.id === product.id
				&& c.term === product.term
				&& c.greenPercentage === product.greenPercentage
				&& c.initialFixedUsage === product.initialFixedUsage);
		if (matchedProduct) {
			allUnique = false;
		}
	});

	return !allUnique ? { productUnique: true } : null;
};

import { Directive, HostListener } from '@angular/core';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[pk-broker-click-stop-propagation]'
})
export class ClickStopPropagationDirective {
	@HostListener('click', ['$event'])
	public onClick(event: any): void {
		event.stopPropagation();
	}
}

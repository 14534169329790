import * as moment from 'moment-timezone';

export class AccountUsage {
	public usage: number;
	public period: Date | string;
	public utilityAccountNum: string;
	public utilityId: string;
	public serviceTypeId: string;
	public meterStartDate: Date | string;
	public meterEndDate: Date | string;

	// internal use
	public periodAsMoment: moment.Moment;

	constructor(accountUsage: AccountUsage) {
		Object.assign(this, accountUsage);

		this.period = this.period
			? new Date(moment(this.period).format('MMM DD, YYYY hh:mm:ss a'))
			: this.period;
		this.meterStartDate = this.meterStartDate
			? new Date(moment(this.meterStartDate).format('MMM DD, YYYY hh:mm:ss a'))
			: this.meterStartDate;
		this.meterEndDate = this.meterEndDate
			? new Date(moment(this.meterEndDate).format('MMM DD, YYYY hh:mm:ss a'))
			: this.meterEndDate;

		if (this.period) {
			this.periodAsMoment = moment(this.period);
		}
	}
}

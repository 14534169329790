export class AttachmentType {

	public attachmentTypeId: string;
	public name: string;
	public description: string;
	public slug: string;
	public isActive: boolean;
	public addDate: Date;

	constructor(attachmentType?: AttachmentType) {
		Object.assign(this, attachmentType);
	}
}

export class LoadFactorType {

	public id: number;
	public name: string;
	public description: string;
	public sortOrder: number;

	constructor(loadFactorType?: LoadFactorType) {
		Object.assign(this, loadFactorType);
	}
}

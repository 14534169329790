import { Pipe, PipeTransform } from '@angular/core';

import { ISO, State, Utility } from '../models';

@Pipe({
	name: 'statesFilter'
})
export class StatesFilterPipe implements PipeTransform {
	transform(states: State[], utilities: Utility[], iso: ISO | ISO[]): State[] {
		const isoList = Array.isArray(iso) ? iso : [iso];
		const isos = iso ? isoList.map(i => i.id).filter(id => id || id === 0) : [];

		if (!states?.length || !isos?.length) { return states; }

		const stateIds = states.map(st => st.stateId);

		return states.filter(st => utilities.some(u => u.utilityStates
			.some(s => stateIds.includes(s.stateId) && s.stateId === st.stateId) &&
			isos.includes(u.isoId)));
	}

}

import * as moment from 'moment-timezone';

import { AgentEngagementEventCategory } from './';

export class AgentEngagementEvent {
	public id: number;
	public agentId: string;
	public ticketId: number;
	public eventDetail: string;
	public agentEngagementEventCategoryId: number;
	public agentEngagementEventStatusId: number;
	public followUpDate: Date;
	public addDate: Date;

	public agentEngagementEventCategory: AgentEngagementEventCategory;

	constructor(agentEngagementEvent: AgentEngagementEvent) {
		Object.assign(this, agentEngagementEvent);
		this.followUpDate = this.followUpDate
			? new Date(moment(this.followUpDate).format('MMM DD, YYYY 00:00:00 a')) : this.followUpDate;
		this.addDate = this.addDate
			? new Date(moment(this.addDate).format('MMM DD, YYYY 00:00:00 a')) : this.addDate;
	}
}

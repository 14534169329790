import { Injectable } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';

import { NotificationModalComponent } from '../modals/notification-modal/notification-modal.component';

export type ConfirmationConstructor = 'confirm' | 'yesNo' | 'ok' | 'cancelProcess';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {

	constructor(private modalService: BsModalService) { }

	public info(
		initialState: { title: string; body: string; body2?: string },
		confirmationType: ConfirmationConstructor = 'confirm',
		modalClass: string = 'pk-modal modal-lg'): void {
		this.modalService.show(NotificationModalComponent, {
			id: 'notification',
			class: modalClass,
			backdrop: 'static',
			initialState: {
				confirmationType,
				...initialState,
			},
		});
	}

	public async confirm(
		initialState: { title: string; body: string; body2?: string; customClass?: string; customConfirmationButtonText?: string },
		confirmationType: ConfirmationConstructor = 'confirm',
		modalClass: string = 'pk-modal modal-lg'): Promise<boolean> {
		return new Promise((resolve, reject) => {
			try {
				const modalRef = this.modalService.show(NotificationModalComponent, {
					id: 'notification',
					class: modalClass,
					backdrop: 'static',
					initialState: {
						confirmationType,
						...initialState,
					},
				});
				modalRef.content.onConfirm.subscribe(() => {
					resolve(true);
				});
				modalRef.content.onCancel.subscribe(() => {
					resolve(false);
				});
			} catch (e) {
				reject(e.message);
			}
		});
	}
}

import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import * as moment from 'moment-timezone';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { HelperService } from 'src/app/shared/helper.service';
import { CompanyCalendlySetting } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-support-center-support-call',
	templateUrl: './support-center-support-call.component.html',
	styleUrls: ['./support-center-support-call.component.scss'],
})
export class SupportCenterSupportCallComponent extends AbstractPageForm {

	public step = 1;

	public calendlyOptions: CompanyCalendlySetting[];

	public supportOptions = [
		{
			name: 'I am with a customer and need assistance to sign a contract.',
			modalTitle: 'I need assistance on a contract signing.',
		},
		{
			name: 'I need assistance with a pricing session that ends or ended today.',
			modalTitle: 'I need assistance on a pricing session.',
		},
		{
			name: 'My customer is out of power. I need immediate start of service.',
			modalTitle: 'My customer is out of power.',
		},
	];

	constructor(
		public activeModal: BsModalRef,
		private toastrService: ToastrService,
		private graphqlService: GraphqlService,
		private helperService: HelperService,
		private fb: FormBuilder,
	) {
		super();
		this.submitText = 'Call Me';
		this.submittingText = 'Sending Request...';
		this.submitAudit = 'support-center-support-call:submit';
	}

	public async loadPageData(): Promise<void> {
		this.calendlyOptions = await this.helperService.getCalendlyOptions();
	}

	public getForm() {
		return this.fb.group({
			supportOption: this.fb.control(null as this['supportOptions'][0], [Validators.required]),
			contractNum: ['', [Validators.required]],
			description: ['', [Validators.required]],
			phone: [this.loggedInUser.phone, [Validators.required, Validators.pattern(this.CONSTANTS.validators.phoneUS)]],
		});
	}

	public async onFormLoaded(): Promise<void> {

	}

	public chooseSupportOption(option: { name: string; modalTitle: string }): void {
		this.form.supportOption.setValue(option);
		this.step++;
	}

	get title(): string {
		switch (this.step) {
			case 1:
				return 'Do you need direct support?';
			case 2:
				return this.form.supportOption.value.modalTitle;
			case 3:
				return this.form.supportOption.value.modalTitle;
		}
	}

	get availability(): { class: string; text: string; confirmationEnding: string } {
		const now = moment();

		// If it's M-F between 9AM and 4PM CT, show them the green message.
		if (![0, 6].includes(now.day()) && now.hour() > 9 && now.hour() < 16) {
			return { class: 'green', text: 'Available until 5PM CT.', confirmationEnding: 'today by 5PM CT.' };
		}

		// If it's M-TH between 4PM and 9AM CT, show them the red message saying available at 9AM tomorrow.
		if (![0, 5, 6].includes(now.day()) && (now.hour() < 9 || now.hour() > 16)) {
			return { class: 'red', text: 'Available at 9AM CT Tomorrow.', confirmationEnding: 'tomorrow morning after 9AM CT.' };
		}

		// If it's after 4PM on Friday and before 9AM on Monday, then show them the available Monday message.
		return { class: 'red', text: 'Available at 9AM CT Monday.', confirmationEnding: 'Monday morning after 9AM CT.' };
	}

	protected async onFormSubmitted() {
		const urgentCallRequest = {
			supportOption: this.form.supportOption.value.modalTitle,
			description: this.form.description.value,
			contractNum: this.form.contractNum.value,
			phone: this.form.phone.value,
			fname: this.loggedInUser.fname,
			lname: this.loggedInUser.lname,
		};
		await this.graphqlService.sendUrgentCallRequestEmail(urgentCallRequest);
		this.toastrService.success('Successfully sent request!', 'Support Call');
		this.step++;
	}
}

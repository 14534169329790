

export class ProductType {

	public id: number;
	public name: string;

	constructor(productType?: ProductType) {
		Object.assign(this, productType);
	}
}

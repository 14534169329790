

export class HolidayType {

	public id: number;
	public name: string;

	constructor(holidayType?: HolidayType) {
		Object.assign(this, holidayType);
	}
}

import { AfterViewChecked, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { LoadOptions } from 'devextreme/data';
import CustomStore from 'devextreme/data/custom_store';
import DxDataGrid from 'devextreme/ui/data_grid';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { User } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';
import { GraphqlService } from '../../../graphql/graphql.service';

@Component({
	selector: 'pk-broker-user-lookup',
	templateUrl: './user-lookup.component.html',
	styleUrls: ['./user-lookup.component.scss'],
})
export class UserLookupModalComponent extends AbstractPageForm implements AfterViewChecked {

	// inputs
	public agentId: string;
	public isActive: boolean;

	public usersTotal: number;
	public users: User[];

	private dataGrid: DxDataGrid;
	public usersDataSource: any = {};

	public onSubmit: Subject<User> = new Subject();

	constructor(
		private graphqlService: GraphqlService,
		public activeModal: BsModalRef,
		private fb: FormBuilder,
	) {
		super();
	}

	public async loadPageData(): Promise<void> { }

	ngAfterViewChecked(): void {
		const emptyRows = document.getElementsByClassName('dx-freespace-row');
		Array.from(emptyRows).forEach(node => node.remove());
	}

	public getForm() {
		return this.fb.group({
			criteria: ['', [Validators.required]],
		});
	}

	public onUsersDataGridInit(event: any): void {
		this.dataGrid = event.component;
	}

	public async onFormLoaded(): Promise<void> {
		this.form.criteria.valueChanges.subscribe(() => {
			this.submit();
		});

		this.usersDataSource.store = new CustomStore({
			key: 'userId',
			load: (loadOptions: LoadOptions) => {
				if (loadOptions['dataField']) {
					return Promise.resolve({ data: this.users, totalCount: this.usersTotal });
				}

				const search = this.form.criteria.value?.replace(/ /g, '%') ?? '';
				const criteria = {
					search: `%${search}%`,
					isActive: this.isActive,
					agentOrParentOrBrotherId: this.agentId ?? undefined,
				};

				const loadOptionsSort = (Array.isArray(loadOptions.sort) ? loadOptions.sort : [loadOptions.sort]).filter(Boolean);
				const sort: string[] = loadOptionsSort?.length
					? loadOptionsSort.map(s => `${s['selector']} ${(s['desc'] ? 'desc' : 'asc')}`)
					: [];

				return this.graphqlService.getUserListData(criteria, sort.join(', '), loadOptions.take, loadOptions.skip)
					.then(usersResult => {
						this.users = usersResult.data.users.message.map(a => new User(a));
						this.usersTotal = usersResult.data.users.total;
						return {
							data: this.users,
							totalCount: this.usersTotal,
						};
					});
			},
		});
	}

	protected async onFormSubmitted() {
		if (this.form.criteria.value?.length > 3 || !this.form.criteria.value) {
			const state = this.dataGrid.state();
			this.dataGrid.state(state);
		}
	}

	public selectUser(user: User): void {
		this.onSubmit.next(user);
		this.activeModal.hide();
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { StringUtils } from '@pk/powerkioskutils';

@Pipe({
	name: 'filterControl',
	pure: false
})
export class FilterControlPipe implements PipeTransform {
	transform(controls: AbstractControl[], key: string, value: string | number | boolean, length: number): any {
		return controls ? controls.filter(p => StringUtils.toBoolean(p.value[key]) === value) : controls;
	}
}

import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NumberUtils } from '@pk/powerkioskutils';
import { EditorComponent } from '@tinymce/tinymce-angular';

import { RawEditorOptions } from 'tinymce';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { SecurityService } from 'src/app/security/security.service';
import { TinyMceCategory, TinyMceTemplate } from 'src/app/shared/models';
import { environment } from '../../../../../environments/environment';

@Component({
	selector: 'pk-broker-html-editor',
	templateUrl: './html-editor.component.html',
	styleUrls: ['./html-editor.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: HtmlEditorComponent,
	}],
})
export class HtmlEditorComponent implements ControlValueAccessor, AfterViewInit, OnInit {

	@ViewChild('editor') private editor: EditorComponent;

	@Input() invalid = false;
	@Input() placeholderItems: string[] = [];
	@Input() toolbar = 'undo redo | bold italic underline strikethrough | fontfamily fontsize | alignleft aligncenter alignright | '
		+ 'forecolor backcolor | removeformat | bullist numlist | indent outdent | image link unlink charmap';
	@Input() formatItems = 'bold italic underline strikethrough superscript subscript | fontfamily fontsize align '
		+ '| forecolor backcolor | language | removeformat';
	@Input() showTemplates: number;
	@Input() aiGeneratedResponseId: number;

	@Output() loaded: EventEmitter<boolean> = new EventEmitter();

	public environment = environment;
	public editorConfig: RawEditorOptions;

	private formControlChanged: (text: string) => void;
	private formControlTouched: () => void;
	private value: string;
	private isDisabled: boolean;
	private showCustomCategories = false;
	private registeredEditorChange = false;

	constructor(
		private securityService: SecurityService,
		private graphqlService: GraphqlService,
	) { }

	get loggedInUser() { return this.securityService.authFields?.loggedInUser; }
	get isAdmin() { return this.loggedInUser?.isAdmin; }
	get showAdvTemplate() { return this.isAdmin && this.showTemplates; }

	ngOnInit(): void {
		const toolbar = this.toolbar +
			(this.placeholderItems?.length ? ' | placeholderItem' : '') +
			(this.showAdvTemplate ? ' | inserttemplate addtemplate' : '');

		this.editorConfig = {
			/* eslint-disable @typescript-eslint/naming-convention */
			plugins: 'advlist autolink charmap code emoticons image insertdatetime link lists searchreplace wordcount advtemplate',
			menubar: 'edit insert format tools',
			menu: {
				edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
				insert: { title: 'Insert', items: 'image link | charmap hr | insertdatetime' },
				format: {
					title: 'Format',
					items: this.formatItems,
				},
				tools: { title: 'Tools', items: 'code wordcount' },
			},
			font_family_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; '
				+ 'Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; '
				+ 'Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Montserrat=Montserrat,arial; Symbol=symbol; '
				+ 'Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; '
				+ 'Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings',
			toolbar,
			toolbar_mode: 'wrap',
			setup: editor => {
				if (this.placeholderItems?.length) {
					editor.ui.registry.addMenuButton('placeholderItem', {
						tooltip: 'Insert a placeholder item that indicates where dynamic data is inserted.',
						text: 'Insert placeholder',
						fetch: success => {
							success(this.placeholderItems.map(item => ({
								type: 'menuitem',
								text: item,
								onAction: () => {
									editor.insertContent(`@${item}@`);
									editor.dom.select('');
								},
							})));
						},
					});
				}
			},
			noneditable_regexp: /@\S+@/g,
			noneditable_class: 'placeholder-item',
			content_style: `
				@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
				body {
					font-family: Montserrat;
				}

				span.placeholder-item {
					border: dashed 1px rgb(144, 164, 174);
					padding: 0.25rem;
					position: relative;
					background-color: rgba(207, 216, 220, 0.2);
					cursor: grab !important;
				}`,
			elementpath: false,
			branding: false,
			images_upload_handler: async blobInfo => {
				try {
					if (NumberUtils.bytesToMegabytes(blobInfo.blob().size) > 20) {
						throw new Error('Image must be no more than 20 MB.');
					}

					return `data:image/png;base64,${blobInfo.base64()}`;
				} catch (err) {
					// eslint-disable-next-line no-throw-literal
					throw {
						message: `Failed to add image: ${err?.message}`,
						remove: true,
					};
				}
			},
			// implement these
			advtemplate_list: this.advtemplateList.bind(this),
			advtemplate_get_template: this.advtemplateGetTemplate.bind(this),
			advtemplate_create_category: this.advtemplateCreateCategory.bind(this),
			advtemplate_create_template: this.advtemplateCreateTemplate.bind(this),
			advtemplate_rename_category: this.advtemplateRenameCategory.bind(this),
			advtemplate_rename_template: this.advtemplateRenameTemplate.bind(this),
			advtemplate_delete_category: this.advtemplateDeleteCategory.bind(this),
			advtemplate_delete_template: this.advtemplateDeleteTemplate.bind(this),
			advtemplate_move_category_items: this.advtemplateMoveTemplateBulk.bind(this),
			advtemplate_move_template: this.advtemplateMoveTemplate.bind(this),
			/* eslint-enable @typescript-eslint/naming-convention */
		};

		if (this.showAdvTemplate) {
			this.editorConfig.contextmenu = 'advtemplate';
		}
	}

	public ngAfterViewInit(): void {
		this.editor.writeValue(this.value);
		if (!this.value) {
			this.registerEditorChange();
		}
		this.editor.registerOnTouched(this.formControlTouched);
		this.editor.setDisabledState(this.isDisabled);

		this.editor.onBeforeExecCommand.subscribe(e => {
			// on this scenario we do want our system to return back
			// an "Uncategorized" category because for some reason
			// TinyMCE won't list the templates if they're uncategorized
			if (e.event.command === 'AdvTemplateInsertDialog') {
				this.showCustomCategories = true;
			}
		});
		this.editor.onPostRender.subscribe(e => {
			this.loaded.emit(true);
		});
		this.editor.onFocus.subscribe(e => {
			this.registerEditorChange();
		});
	}

	public registerEditorChange(): void {
		if (!this.registeredEditorChange) {
			this.editor.registerOnChange(this.formControlChanged);
			this.registeredEditorChange = true;
		}
	}

	public registerOnChange(fn: (text: string) => void): void {
		this.formControlChanged = fn;
		this.editor?.registerOnChange(fn);
	}

	public registerOnTouched(fn: () => void): void {
		this.formControlTouched = fn;
		this.editor?.registerOnTouched(fn);
	}

	public writeValue(value: string | null): void {
		this.value = value;
		this.editor?.writeValue(value);
	}

	public setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
		this.editor?.setDisabledState(isDisabled);
	}

	public async advtemplateList(e: any) {
		const result = await this.graphqlService.getTinyMceCategories();
		const categories = result.data.tinyMceCategories.message.map(c => new TinyMceCategory(c));
		if (result.data.tinyMceTemplates.message.length && this.showCustomCategories) {
			// have to use the string 'uncategorized' because tinymce doesn't like null as the id or empty string
			categories.unshift(new TinyMceCategory({
				id: 'uncategorized',
				title: 'Uncategorized',
				items: result.data.tinyMceTemplates.message,
			}));
		}

		if (this.aiGeneratedResponseId && this.showCustomCategories) {
			categories.unshift(new TinyMceCategory({
				id: 'oc-generated-responses',
				title: 'OC Generated Responses',
				items: [new TinyMceTemplate({
					id: 'oc-generated-response/' + this.aiGeneratedResponseId,
					title: 'Generated Response',
				})],
			}));
		}

		// reset for next use
		this.showCustomCategories = false;

		return categories;
	}

	public async advtemplateGetTemplate(id: number | string) {
		const result = await this.graphqlService.getTinyMceTemplate(id);
		return new TinyMceTemplate(result.data.tinyMceTemplate);
	}

	public async advtemplateCreateCategory(title: string) {
		const result = await this.graphqlService.createTinyMceCategory({ title });
		this.showCustomCategories = true;
		return new TinyMceCategory(result.data.createTinyMceCategory);
	}

	public async advtemplateCreateTemplate(title: string, content: string, categoryId: number) {
		const result = await this.graphqlService.createTinyMceTemplate({ title, content, categoryId });
		return new TinyMceTemplate(result.data.createTinyMceTemplate);
	}

	public async advtemplateRenameCategory(id: number | string, title: string) {
		await this.graphqlService.updateTinyMceCategory(id, { title });
		this.showCustomCategories = true;
		return {};
	}

	public async advtemplateRenameTemplate(id: number | string, title: string) {
		await this.graphqlService.updateTinyMceTemplate(id, { title });
		this.showCustomCategories = true;
		return {};
	}

	public async advtemplateDeleteTemplate(id: number | string) {
		await this.graphqlService.deleteTinyMceTemplate(id);
		this.showCustomCategories = true;
		return {};
	}

	public async advtemplateDeleteCategory(id: number | string) {
		await this.graphqlService.deleteTinyMceCategory(id);
		this.showCustomCategories = true;
		return {};
	}

	public async advtemplateMoveTemplateBulk(id: number | string, categoryId: number | string) {
		if (!categoryId) { categoryId = 'uncategorized'; }
		await this.graphqlService.updateTinyMceTemplatesByCategory({ oldCategoryId: id, newCategoryId: categoryId });
		this.showCustomCategories = true;
		return {};
	}

	public async advtemplateMoveTemplate(id: number | string, categoryId: number | string) {
		if (!categoryId) { categoryId = 'uncategorized'; }
		await this.graphqlService.updateTinyMceTemplate(id, { categoryId });
		this.showCustomCategories = true;
		return {};
	}
}

import { BankAccountType } from './';
import { BankAccountVendorType } from './bank-account-vendor-type';

export class BankAccount {

	public id: number;
	public agentId: string;
	public bankName: string;
	public routingNum: string;
	public accountNum: string;
	public swiftCode: string;
	public bankAccountTypeId: number;
	public bankAccountVendorTypeId: number;
	public isInvalid: boolean;

	public bankAccountType: BankAccountType;
	public bankAccountVendorType: BankAccountVendorType;

	// internal use
	public accountNumConfirmation: string;
	public routingNumConfirmation: string;
	public swiftCodeConfirmation: string;

	constructor(bankAccount?: Subset<BankAccount>) {
		Object.assign(this, bankAccount);
	}
}

import { Attachment } from './attachment';
import { EmailSection } from './email-section';
import { Lead } from './lead';

export class LeadEmailSection {

	public id: number;
	public leadId: number;
	public emailSectionId: number;
	public content: string;
	public attachmentId: string;

	public lead: Lead;
	public emailSection: EmailSection;
	public attachment: Attachment;

	constructor(leadEmailSection?: LeadEmailSection) {
		Object.assign(this, leadEmailSection);
	}
}

import * as moment from 'moment-timezone';

export class ReportSalesReport {

	public date: string;
	public month: string;
	public year: number;
	public termValue: number;
	public numOfDeals: number;

	constructor(reportSalesReport: ReportSalesReport, displayColumnsBy: string) {
		Object.assign(this, reportSalesReport);

		if (displayColumnsBy === 'years') {
			this.date = String(this.year);
		}
		if (displayColumnsBy === 'months') {
			this.date = moment(new Date(this.month)).format('MM/YYYY');
		}
	}
}

export class AgentEcl {

	public agentId: string;
	public accountUuid: string;
	public isReserved: boolean;
	public note: string;

	constructor(agentEcl: AgentEcl) {
		Object.assign(this, agentEcl);
	}
}

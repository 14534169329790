/* eslint-disable @typescript-eslint/naming-convention */
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { CONSTANTS, StringUtils } from '@pk/powerkioskutils';

import { BsModalService } from 'ngx-bootstrap/modal';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { SecurityService } from 'src/app/security/security.service';
import { UserAuditService } from 'src/app/user-audit/user-audit.service';
import { environment } from '../../../environments/environment';
import { HelperService } from '../helper.service';
import { FeatureTourModalComponent } from '../modals/feature-tour-modal/feature-tour-modal.component';
import { FeatureTour, User } from '../models';
import { ServiceLocator } from '../service-locator.service';

/**
 * The return type of `AbstractPageForm.buildForm(form)`.
 * This is an object containing the form controls of `form`.
 */
export type FormControlObj<T extends AbstractControl<any, any>> =
	T extends FormControl ? T :
	T extends FormArray ? FormControlObj<T['controls'][0]>[] & {
		/** The `FormArray` object. */
		control: T;
		/** Removes an element from the `FormArray` object. */
		remove: (element: FormControlObj<T['controls'][0]>) => void;
	} :
	T extends FormGroup ? { [key in keyof T['controls']]: FormControlObj<T['controls'][key]> } & {
		/** The `FormGroup` object. */
		control: T;
	} :
	never;

/**
 * The return type of `AbstractPageForm.buildErr(form)`.
 * This is an object containing the `ValidationErrors` of `form`.
 */
type FormControlErrObj<T extends AbstractControl<any, any>> =
	T extends FormControl ? T['errors'] :
	T extends FormArray ? FormControlErrObj<T['controls'][0]>[] & { group: T['errors'] } :
	T extends FormGroup ? { [key in keyof T['controls']]: FormControlErrObj<T['controls'][key]> } & { group: T['errors'] } :
	never;

export abstract class AbstractPageForm<T extends FormGroup<any> = FormGroup<any>> {

	public readonly ELECTRICITY_ID = CONSTANTS.serviceTypes.electricity.serviceTypeId;
	public readonly COMMUNITY_SOLAR_ID = CONSTANTS.serviceTypes.communitySolar.serviceTypeId;
	public readonly NATURAL_GAS_ID = CONSTANTS.serviceTypes.naturalGas.serviceTypeId;

	public warnings = [];
	public environment = environment;
	public CONSTANTS = CONSTANTS;
	public StringUtils = StringUtils;
	public Math = Math;
	public loading = true;
	public startValidation = false;
	public invalidWarning = 'Some form fields have invalid values; no changes have been made.';
	public submitDisabled = false;
	/** Default: "Submit" */
	public submitText = 'Submit';
	/** Default: "Submitting..." */
	public submittingText = 'Submitting...';
	/** Set this when `userAuditService.captureEvent` should be called when the form is submitted. */
	public submitAudit: string = null;
	/** Set this when a feature tour could appear on this page. */
	public featureTourLocationId: number = null;
	public loggedInUser: User;

	private loadPageDataImplemented = true; // TODO: will become unnecessary when implementation is required

	public formGroup: ReturnType<this['getForm']>;
	/**
	 * An object containing all the form controls inside `formGroup`.
	 */
	public form: FormControlObj<typeof this.formGroup>;
	/**
	 * An object containing the `ValidationErrors` of all the form controls inside `formGroup`.
	 * `ValidationErrors` always resolve to `null` if `this.startValidation` is false.
	 */
	public err: FormControlErrObj<typeof this.formGroup>;

	protected userAuditService: UserAuditService;
	protected securityService: SecurityService;

	constructor() {
		this.userAuditService = ServiceLocator.injector.get(UserAuditService);
		this.securityService = ServiceLocator.injector.get(SecurityService);
		this.loggedInUser = new User(this.securityService.authFields?.loggedInUser);
		setTimeout(() => this.initializeForm(), 0); // Initialize after child page constructor runs
	}

	public setSubmitText(startText: string, processingText: string): void {
		this.submitText = startText;
		this.submittingText = processingText;
	}

	private async initializeForm() {
		try {
			await this.loadPageData();
			if (this.featureTourLocationId && this.loggedInUser?.agentId) {
				const result = await ServiceLocator.injector.get(GraphqlService).getNextFeatureTour(this.featureTourLocationId);
				if (result.data.nextFeatureTour) {
					ServiceLocator.injector.get(BsModalService).show(FeatureTourModalComponent, {
						class: 'pk-modal',
						initialState: {
							featureTour: new FeatureTour(result.data.nextFeatureTour),
						},
					});
				}
			}
			this.formGroup = this.getForm() as any;
			this.form = this.buildForm(this.formGroup);
			this.err = this.buildErr(this.formGroup);
			await this.onFormLoaded();
		} catch (err) {
			if (!environment.production || this.loggedInUser?.isAdmin) {
				console.error(err);
			}
			this.warnings = ['There was a problem loading the page. We have been notified and are working to fix the issue. '
				+ 'Please check back again in 30 minutes.'];
		}
		if (this.loadPageDataImplemented) {
			this.loading = false;
		}
	}

	public async reloadPageData() {
		this.loading = true;
		try {
			await this.loadPageData();
		} catch (err) {
			if (!environment.production || this.loggedInUser?.isAdmin) {
				console.error(err);
			}
			this.warnings = ['There was a problem loading the page. We have been notified and are working to fix the issue. '
				+ 'Please check back again in 30 minutes.'];
		}
		this.loading = false;
	}

	public async reloadForm() {
		try {
			this.formGroup = this.getForm() as any;
			this.form = this.buildForm(this.formGroup);
			this.err = this.buildErr(this.formGroup);
			await this.onFormLoaded();
		} catch (err) {
			if (!environment.production || this.loggedInUser?.isAdmin) {
				console.error(err);
			}
			this.warnings = ['There was a problem loading the page. We have been notified and are working to fix the issue. '
				+ 'Please check back again in 30 minutes.'];
		}
	}

	/** Called before `getForm()`. */
	protected async loadPageData() { this.loadPageDataImplemented = false; }
	/** Returns the `FormGroup` for this page. */
	public getForm(): T { return false as any as T; }
	/** Called after `this.formGroup`, `this.form`, and `this.err` are initialized. */
	protected async onFormLoaded() { }
	/** Called after a valid form is submitted. */
	protected async onFormSubmitted() { }

	public setTouched(form: FormGroup): void {
		if (!form) { return; }
		const controlKeys = Object.keys(form.controls);
		for (const key of controlKeys) {
			form.controls[key].markAsTouched();

			if (form.controls[key] instanceof FormArray) {
				const formArray = form.controls[key] as FormArray;
				const formArrayKeys = Object.keys(formArray.controls);
				for (const formArrayKey of formArrayKeys) {
					if (formArray.controls[formArrayKey].controls) {
						this.setTouched(formArray.controls[formArrayKey] as FormGroup);
					}
				}
			}
		}
		form.markAsTouched();
	}

	public resetTouched(form: FormGroup = this.formGroup): void {
		if (!form) { return; }
		const controlKeys = Object.keys(form.controls);
		for (const key of controlKeys) {
			form.controls[key].markAsPristine();
			form.controls[key].markAsUntouched();
			form.controls[key].updateValueAndValidity();

			if (form.controls[key] instanceof FormArray) {
				const formArray = form.controls[key] as FormArray;
				const formArrayKeys = Object.keys(formArray.controls);
				for (const formArrayKey of formArrayKeys) {
					this.resetTouched(formArray.controls[formArrayKey] as FormGroup);
				}
			}
		}
		form.markAsPristine();
		form.markAsUntouched();
		form.updateValueAndValidity();
	}

	/**
	 * @returns an object containing all the form controls inside `formGroup`.
	 */
	private buildForm(formGroup: ReturnType<this['getForm']>) {
		const form = {};

		this._buildForm(formGroup, 'root', form);

		return form['root'] as FormControlObj<ReturnType<this['getForm']>>;
	}

	private _buildForm(abstractControl: AbstractControl, parentKey: string | number, parentForm: any) {
		if (abstractControl instanceof FormControl) {
			parentForm[parentKey] = abstractControl;
		} else if (abstractControl instanceof FormArray) {
			parentForm[parentKey] = [];
			parentForm[parentKey].control = abstractControl;
			parentForm[parentKey].remove =
				(element: any) => abstractControl.removeAt(abstractControl.controls.indexOf(element.control || element));
			let oldLength = abstractControl.controls.length;
			abstractControl.valueChanges.subscribe(arr => { // Updates the object if the length of the FormArray changes.
				if (oldLength !== arr.length) {
					oldLength = arr.length;
					parentForm[parentKey] = [];
					parentForm[parentKey].control = abstractControl;
					parentForm[parentKey].remove =
						(element: any) => abstractControl.removeAt(abstractControl.controls.indexOf(element.control || element));
					for (let i = 0; i < abstractControl.controls.length; i++) {
						this._buildForm(abstractControl.controls[i], i, parentForm[parentKey]);
					}
				}
			});

			for (let i = 0; i < abstractControl.controls.length; i++) {
				this._buildForm(abstractControl.controls[i], i, parentForm[parentKey]);
			}
		} else if (abstractControl instanceof FormGroup) {
			parentForm[parentKey] = {};
			parentForm[parentKey].control = abstractControl;

			for (const property of Object.keys(abstractControl.controls)) {
				this._buildForm(abstractControl.controls[property], property, parentForm[parentKey]);
			}
		}
	}

	/**
	 * @returns an object containing the `ValidationErrors` of all the form controls inside `formGroup`.
	 * `ValidationErrors` always resolve to `null` if `this.startValidation` is false.
	 */
	private buildErr(formGroup: ReturnType<this['getForm']>) {
		const err = {};

		this._buildErr(formGroup, 'root', err);

		return err['root'] as FormControlErrObj<ReturnType<this['getForm']>>;
	}

	private _buildErr(abstractControl: AbstractControl, parentKey: string | number, parentErr: any) {
		if (abstractControl instanceof FormControl) {
			Object.defineProperty(parentErr, parentKey, {
				get: () => this.startValidation ? abstractControl.errors : null,
			});
		} else if (abstractControl instanceof FormArray) {
			parentErr[parentKey] = [];
			let oldLength = abstractControl.controls.length;
			abstractControl.valueChanges.subscribe(arr => { // Updates the object if the length of the FormArray changes.
				if (oldLength !== arr.length) {
					oldLength = arr.length;
					parentErr[parentKey] = [];
					for (let i = 0; i < abstractControl.controls.length; i++) {
						this._buildErr(abstractControl.controls[i], i, parentErr[parentKey]);
					}
				}
			});

			for (let i = 0; i < abstractControl.controls.length; i++) {
				this._buildErr(abstractControl.controls[i], i, parentErr[parentKey]);
			}
			Object.defineProperty(parentErr[parentKey], 'group', {
				get: () => this.startValidation ? abstractControl.errors : null,
			});
		} else if (abstractControl instanceof FormGroup) {
			parentErr[parentKey] = {};

			for (const property of Object.keys(abstractControl.controls)) {
				this._buildErr(abstractControl.controls[property], property, parentErr[parentKey]);
			}
			Object.defineProperty(parentErr[parentKey], 'group', {
				get: () => this.startValidation ? abstractControl.errors : null,
			});
		}
	}

	public preSubmit(): void {
		this.setTouched(this.formGroup);
		this.startValidation = true;
	}

	/**
	 * Call this when the form is submitted.
	 */
	public async submit(): Promise<void> {
		if (this.submitDisabled) { return; } // protect against multi-submits

		const previousSubmitText = this.submitText;
		try {
			this.preSubmit();
			if (this.formGroup.valid) {
				this.submitText = this.submittingText;
				this.submitDisabled = true;
				this.warnings = [];
				await this.onFormSubmitted();
				this.startValidation = false;
			} else {
				if (!environment.production || this.loggedInUser?.isAdmin) {
					console.error('Validation Errors:', this.getFormErrors(this.formGroup));
				}
				if (this.invalidWarning) {
					this.warnings = [this.invalidWarning];
				}
				if (this.submitAudit) {
					this.userAuditService.captureEvent(
						this.CONSTANTS.userAuditTypes.formValidation,
						this.formGroup,
						this.submitAudit,
						await this.getExtraUserAuditMessage(),
					);
				}
			}
		} catch (err) {
			if (!environment.production || this.loggedInUser?.isAdmin) {
				console.error(err);
			}
			if (this.submitAudit) {
				this.userAuditService.captureEvent(this.CONSTANTS.userAuditTypes.code, err.message, this.submitAudit);
			}
			this.warnings = HelperService.getErrorMessage(err);
		}

		this.submitText = previousSubmitText;
		this.submitDisabled = false;
	}

	/** Returns the `extraMessage` parameter of `userAuditService.captureEvent()` when an invalid form is submitted. */
	protected async getExtraUserAuditMessage() { return undefined; }

	getFormErrors(form: AbstractControl) {
		if (form instanceof FormGroup || form instanceof FormArray) {
			const formErrors = form.errors ? { group: form.errors } : {};
			Object.keys(form.controls).forEach(key => {
				const error = this.getFormErrors(form.get(key));
				if (error !== null) {
					formErrors[key] = error;
				}
			});
			return Object.keys(formErrors).length > 0 ? formErrors : null;
		} else {
			return form.errors ?? null;
		}
	}

	/**
	 * `There was a problem ${action}. We have been notified and are working to fix the issue. Please check back again in 30 minutes.`
	 */
	getErrorMessage(action: string) {
		return `There was a problem ${action}. We have been notified and are working to fix the issue. `
			+ 'Please check back again in 30 minutes.';
	}
}

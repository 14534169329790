import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { HelperService } from 'src/app/shared/helper.service';
import { Customer, CustomerAvailability } from 'src/app/shared/models';

@Component({
	selector: 'pk-broker-customer-availability-view',
	templateUrl: './customer-availability-view.component.html',
	styleUrls: ['./customer-availability-view.component.scss']
})
export class CustomerAvailabilityViewComponent implements OnInit {

	@Input() customer: Customer = null;
	@Input() customerForm: UntypedFormGroup = null;

	//Type 1: Showing contract info page
	//Type 2: Showing signup page
	@Input() pageType: number;

	public daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
	public timezones = [];
	public abbreviation = 'CT';

	constructor(
		private helperService: HelperService,
		private graphqlService: GraphqlService,
	) { }

	async ngOnInit(): Promise<void> {
		this.timezones = (await this.graphqlService.getAllTimezones())?.data?.timezones?.message;
		if (!this.customerForm && !this.customer) { return; }
		if (!this.customer) {
			this.customer = { customerAvailability: this.buildCustomerAvailabilityDTO() } as Customer;
		}
	}

	private buildCustomerAvailabilityDTO(): CustomerAvailability {
		const customerTimezone = this.customerForm.get('availability.timezone').value;
		const filteredTimezone = this.timezones.filter(t => t.id === customerTimezone);
		this.abbreviation = filteredTimezone.length ? filteredTimezone[0].abbreviation : 'CT';
		const weekAvailability = (this.customerForm.get('availability.availableTime') as UntypedFormArray).controls;
		return ({
			timezoneId: customerTimezone,
			mondayFrom: weekAvailability[0].get('from').value ? weekAvailability[0].get('from').value : null,
			mondayTo: weekAvailability[0].get('to').value ? weekAvailability[0].get('to').value : null,
			tuesdayFrom: weekAvailability[1].get('from').value ? weekAvailability[1].get('from').value : null,
			tuesdayTo: weekAvailability[1].get('to').value ? weekAvailability[1].get('to').value : null,
			wednesdayFrom: weekAvailability[2].get('from').value ? weekAvailability[2].get('from').value : null,
			wednesdayTo: weekAvailability[2].get('to').value ? weekAvailability[2].get('to').value : null,
			thursdayFrom: weekAvailability[3].get('from').value ? weekAvailability[3].get('from').value : null,
			thursdayTo: weekAvailability[3].get('to').value ? weekAvailability[3].get('to').value : null,
			fridayFrom: weekAvailability[4].get('from').value ? weekAvailability[4].get('from').value : null,
			fridayTo: weekAvailability[4].get('to').value ? weekAvailability[4].get('to').value : null,
		} as CustomerAvailability);
	}

	public getWeekdayAvailability(dayOfWeek: number): string {
		if (!this.customer) { return 'Not Available'; }
		return this.helperService.getWeekdayAvailability(dayOfWeek, this.customer, this.abbreviation);
	}
}

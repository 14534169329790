import { Agent } from './agent';
import { ServiceType } from './service-type';
import { User } from './user';
import { Utility } from './utility';

export class AgentRateMatrix {
	public id: number;
	public version: number;
	public agentId: string;
	public serviceTypeId: string;
	public utilityId: string;
	public minUsage: number;
	public maxUsage: number;
	public rate: number;
	public addDate: string;
	public userId: string;

	public agent: Agent;
	public serviceType: ServiceType;
	public utility: Utility;
	public user: User;

	constructor(agentRateMatrix: AgentRateMatrix) {
		Object.assign(this, agentRateMatrix);
	}
}

import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const generateLoaLoeValidator: ValidatorFn = (formGroup: FormGroup<any>): ValidationErrors | null => {
	const addLoa = formGroup.get('addLOA').value;
	const addLoe = formGroup.get('addLOE').value;

	if (!addLoa && !addLoe) {
		return { atLeastOneSelected: true };
	}

	return null;
};

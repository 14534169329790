import { Component, OnInit } from '@angular/core';

import { omit } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { UserSurvey } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-csat-survey-modal',
	templateUrl: './csat-survey-modal.component.html',
	styleUrls: ['./csat-survey-modal.component.scss']
})
export class CsatSurveyModalComponent extends AbstractPageForm implements OnInit {

	// inputs
	public alreadyCompleted = false;
	public initialScore: number = null;
	public csatSurvey: Partial<UserSurvey>;
	public contractId: string = null;
	public ticketId: number = null;
	public userSurveyCategoryId: number;

	public closingBestEmail = false;
	public closingForm = false;
	public closedForm = false;

	public submitDisabled = false;
	public submitText = 'Submit';

	constructor(private graphqlService: GraphqlService,
		private activeModal: BsModalRef) {
		super();
	}

	async ngOnInit(): Promise<void> {

		if (!this.csatSurvey.bestEmail) {
			this.csatSurvey.bestEmail = this.loggedInUser.emails[0]?.email;
		}

		const npsSub = this.activeModal.onHidden.subscribe(async () => {
			npsSub.unsubscribe();
			if (!this.csatSurvey.closedSurveyWithButton) {
				this.csatSurvey.closedSurveyWithoutButton = true;
				this.csatSurvey.isComplete = true;
			}
			if (!this.csatSurvey.canContact) {
				this.csatSurvey.bestEmail = null;
			}

			this.updateUserSurveySetting();
			this.updateCsatSurvey();
		});

		if (this.initialScore) {
			await this.updateCsatSurvey(this.initialScore);
		}

		this.loading = false;
	}

	private async updateUserSurveySetting() {
		const csatSurveySetting = this.loggedInUser.getSurveySetting(this.userSurveyCategoryId, true);
		await this.graphqlService.updateUserSurveySetting(csatSurveySetting.id, { show: false });
	}

	get parentBrokerage(): string {
		return this.loggedInUser.agent?.parent
			? this.loggedInUser.agent?.parent.displayName
			: 'Power Kiosk';
	}

	get selectedScore(): boolean {
		return !!this.csatSurvey.recommendScore || this.csatSurvey.recommendScore === 0;
	}

	public closeBestEmail(): void {
		if (this.csatSurvey.canContact === true) {
			this.closingBestEmail = true;
			setTimeout(() => {
				this.closingBestEmail = false;
			}, 600);
		}
	}

	public closeForm(): void {
		this.closingForm = true;
		setTimeout(() => {
			this.closingForm = false;
			this.closedForm = true;
		}, 600);
	}

	public async submit(): Promise<void> {
		this.submitDisabled = true;
		this.submitText = 'Submitting...';
		this.csatSurvey.isComplete = true;
		this.closeForm();
	}

	public async updateCsatSurvey(recommendScore: number = null): Promise<void> {
		if (recommendScore !== null) {
			this.csatSurvey.recommendScore = recommendScore;
		}
		if (!this.csatSurvey.canContact) {
			this.csatSurvey.bestEmail = null;
		}
		if (!this.csatSurvey.bestEmail) {
			this.csatSurvey.canContact = false;
		}
		try {
			await this.graphqlService.updateUserSurvey(this.csatSurvey.id, omit(this.csatSurvey, 'id'));
		} catch (e) {
			// for now we don't show anything
		}
	}

	public hide(): void {
		this.csatSurvey.closedSurveyWithButton = true;
		this.csatSurvey.isComplete = true;
		this.activeModal.hide();
	}
}

import * as moment from 'moment-timezone';

import { Attachment } from './attachment';
import { Product } from './product';
import { State } from './state';
import { Supplier } from './supplier';
import { Utility } from './utility';

export class Document {

	public name: string;
	public description: string;
	public documentId: string;
	public minUsage: number;
	public maxUsage: number;
	public isEsign: boolean;
	public isActive: boolean;
	public isInkSign: boolean;
	public attachmentId: string;
	public documentBase64: string;
	public status: number;
	public companyId: string;

	public modifiedDate: Date;
	public addDate: Date;

	public attachment: Attachment;
	public products: Product[];
	public states: State[];
	public utilities: Utility[];
	public suppliers: Supplier[];

	constructor(document?: Subset<Document>) {
		Object.assign(this, document);

		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
	}
}

import * as moment from 'moment-timezone';

export class SupplierMonthlyAuctionPerformanceStats {

	public date: string | Date;
	public numAuctionsInvited: number;
	public numAuctionsParticipated: number;
	public numAuctionsWon: number;

	constructor(supplierMonthlyAuctionPerformanceStats: SupplierMonthlyAuctionPerformanceStats) {
		Object.assign(this, supplierMonthlyAuctionPerformanceStats);

		this.date = this.date ? new Date(moment(this.date).startOf('month').format('MMM DD, YYYY hh:mm:ss a')) : this.date;
	}
}

export class MarginChangeReasonType {

	public id: number;
	public name: string;
	public sortOrder: number;

	constructor(marginChangeReasonType?: MarginChangeReasonType) {
		Object.assign(this, marginChangeReasonType);
	}
}

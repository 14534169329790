export class CommissionPayPlanType {

	public id: number;
	public name: string;
	public description: string;
	public header: string;
	public details: string;

	constructor(commissionPayPlanType?: CommissionPayPlanType) {
		Object.assign(this, commissionPayPlanType);
	}
}

<ul class="page-numbers" *ngIf="pageCount">
	<li class="left-chevron" *ngIf="currentPage !== 0">
		<a href="javascript:void(0)" (click)="changePage(currentPage - 1)">
			<fa-icon [icon]="['fas', 'chevron-left']"></fa-icon>
		</a>
	</li>
	<!-- first one always displayed -->
	<li class="page-number" [ngClass]="{ 'designated-page': currentPage === 0 }">
		<a href="javascript:void(0)" (click)="changePage(0)">1</a>
	</li>
	<!-- left side ellipsis -->
	<li class="page-number ellipsis" *ngIf="!isMiddlePage(1) && pageCount > 3">
		<fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
	</li>
	<!-- middle pagers -->
	<li class="page-number" *ngFor="let middlePage of middlePages" [ngClass]="{ 'designated-page': currentPage === middlePage }">
		<a href="javascript:void(0)" (click)="changePage(middlePage)">{{ middlePage + 1 }}</a>
	</li>
	<!-- right side ellipsis -->
	<li class="page-number ellipsis" *ngIf="!isMiddlePage(pageCount - 2) && pageCount > 3">
		<fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
	</li>
	<!-- last one always displayed -->
	<li class="page-number" *ngIf="pageCount > 1" [ngClass]="{ 'designated-page': currentPage === pageCount - 1 }">
		<a href="javascript:void(0)" (click)="changePage(pageCount - 1)">{{ pageCount }}</a>
	</li>
	<li class="right-chevron" *ngIf="currentPage !== pageCount - 1">
		<a href="javascript:void(0)" (click)="changePage(currentPage + 1)">
			<fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
		</a>
	</li>
</ul>

import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

@Pipe({
	name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
	transform(items: any[], orderBy: string, direction?: 'asc' | 'desc'): any {
		return items ? _.orderBy(items, orderBy, direction) : items;
	}
}

export class UserRfqSettingRestrictionType {

	public id: number;
	public name: string;
	public sortOrder: number;

	constructor(userRfqSettingRestrictionType?: UserRfqSettingRestrictionType) {
		Object.assign(this, userRfqSettingRestrictionType);
	}
}



import * as moment from 'moment-timezone';

import { HelperService } from '../helper.service';
import { PayableIssue } from './payable-issue';

export class ViewPayableForecastDashboard {
	public id: number;
	public contractId: string;
	public contractNum: string;
	public dbaName: string;
	public customerId: string;
	public utilityAccountNum: string;
	public confirmedAnnualUsage: number;
	public contractLocationId: string;
	public date: Date;
	public confirmationDate: Date;
	public effectiveDate: Date;
	public commissionTypeName: string;
	public commissionTypeId: number;
	public amount: number;
	public agentId: string;
	public agentName: string;
	public margin: number;
	public usage: number;
	public rate: number;
	public commissionPayableStatusTypeId: number;
	public commissionPayableStatusTypeName: string;
	public description: string;
	public cycleStartDate: string;
	public cycleEndDate: string;
	public commissionPayableIssueTypeId: number;
	public commissionPayableIssueTypeName: string;

	public payableIssues: PayableIssue[];

	public receivableAddDate: Date;
	public units: string;
	public symbol: string;
	public commissionPayPlanTypeId: number;
	public upfrontPercentage: number;
	public receivableUsage: number;
	public accountUsage: number;
	public totalAgentPaymentAmount: number;
	public totalReceivableUpfrontPaymentNum: number;
	public totalReceivableResidualPaymentNum: number;
	public totalPayableUpfrontPaymentNum: number;
	public totalPayableResidualPaymentNum: number;
	public ruleId: number;
	public ruleTierId: number;
	public accountUsageCheckId: number;
	public receivableUsageCheckId: number;
	public issues: string;

	// internal
	public groupings: ViewPayableForecastDashboard[];
	public usageVerification: {
		accountUsage: {
			label: string;
			text: string;
			iconPrefix: string;
			icon: string;
			classes: string;
		};
		receivableUsage: {
			label: string;
			text: string;
			iconPrefix: string;
			icon: string;
			classes: string;
		};
	};

	constructor(viewPayableForecastDashboard?: Partial<ViewPayableForecastDashboard>) {
		Object.assign(this, viewPayableForecastDashboard);

		this.date = this.date
			? new Date(moment(this.date).format('MMM DD, YYYY 00:00:00 a')) : this.date;
		this.confirmationDate = this.confirmationDate
			? new Date(moment(this.confirmationDate).format('MMM DD, YYYY 00:00:00 a')) : this.confirmationDate;
		this.effectiveDate = this.effectiveDate
			? new Date(moment(this.effectiveDate).format('MMM DD, YYYY 00:00:00 a')) : this.effectiveDate;
		this.receivableAddDate = this.receivableAddDate
			? new Date(moment(this.receivableAddDate).format('MMM DD, YYYY 00:00:00 a')) : this.receivableAddDate;

		this.usageVerification = HelperService.getUsageVerification(this);
	}

	get hasIssues(): boolean {
		return (this.issues || this.usageVerification.accountUsage || this.usageVerification.receivableUsage) ? true : false;
	}
}

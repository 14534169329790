export class EmailPreferenceSettingTargetDateOption {

	public id: number;
	public name: string;
	public emailPreferenceTypeId: number;
	public sortOrder: number;
	public isDefault: boolean;
	public sendDelayDirection: number;

	constructor(emailPreferenceSettingTargetDateOption?: EmailPreferenceSettingTargetDateOption) {
		Object.assign(this, emailPreferenceSettingTargetDateOption);
	}
}

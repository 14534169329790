export class InvoiceItem {
	public id: number;
	public invoiceId: number;
	public description1: string;
	public description2: string;
	public amount: number;

	constructor(invoiceItem?: any) {
		Object.assign(this, invoiceItem);
	}
}

import { Pipe, PipeTransform } from '@angular/core';

import { RateMatrixLoader } from '../models';

@Pipe({
	name: 'filterLoaders'
})
export class FilterLoadersPipe implements PipeTransform {

	transform(loaders: RateMatrixLoader[], searchQuery: string): RateMatrixLoader[] {
		return loaders && loaders.length
			? loaders.filter(l => !searchQuery || l.assemblyName.toLowerCase().includes(searchQuery.toLowerCase()))
			: loaders;
	}

}

<section class="container-fluid support-center-container">
	<pk-broker-loader [show]="loading"></pk-broker-loader>
	<div class="row d-flex justify-content-center align-items-stretch px-lg-3 px-xl-0 width-limit support-center-buckets" *ngIf="!loading">
		<div class="d-flex col-12 col-md-4 order-0 order-md-1 px-3 pb-2 px-md-1 text-center">
			<a [routerLink]="['/faq']" class="w-100 align-self-stretch main-bucket-link">
				<p class="bucket-icon"><fa-icon [icon]="['fas', 'question-circle']"></fa-icon></p>
				<h2>Frequently Asked Questions</h2>
				<p>Solve many problems instantly by browsing our FAQs.</p>
				<p class="btn btn-light">See FAQs</p>
			</a>
		</div>
		<div class="d-flex col-12 col-md-4 order-1 order-md-0 px-3 pb-2 px-md-1 text-center" *ngIf="useSupportSystem || supportEmail">
			<a class="w-100 align-self-stretch main-bucket-link" [routerLink]="['/ticket']" *ngIf="useSupportSystem">
				<p class="bucket-icon"><fa-icon [icon]="['fas', 'laptop-medical']"></fa-icon></p>
				<h2>Request Support</h2>
				<p>If you're still having issues, you can submit a ticket and get support.</p>
				<p class="btn btn-light">Submit a Ticket</p>
			</a>
			<a class="w-100 align-self-stretch main-bucket-link" [attr.href]="'mailto:' + supportEmail" *ngIf="!useSupportSystem && supportEmail">
				<p class="bucket-icon"><fa-icon [icon]="['fas', 'laptop-medical']"></fa-icon></p>
				<h2>Request Support</h2>
				<p>If you're still having issues, you can contact your manager by clicking here.</p>
				<p class="btn btn-light">Contact Manager</p>
			</a>
		</div>
		<div class="d-flex col-12 col-md-4 order-3 px-3 pb-2 px-md-1 text-center" *ngIf="useSupportSystem">
			<a
				[routerLink]="['/report', 'supplierCompensationStructure', '8a25800564cc09470164cc1a5a970000']"
				class="w-100 align-self-stretch main-bucket-link">
				<p class="bucket-icon"><fa-icon [icon]="['fas', 'hand-holding-usd']"></fa-icon></p>
				<h2>Supplier Compensation</h2>
				<p>View our partner suppliers and how they pay out deals to you.</p>
				<p class="btn btn-light">See More</p>
			</a>
		</div>
		<div class="d-flex col-12 col-md-4 order-4 px-3 pb-2 px-md-1 text-center" *ngIf="showEducation">
			<a [routerLink]="['/education']" class="w-100 align-self-stretch main-bucket-link">
				<p class="bucket-icon"><fa-icon [icon]="['fas', 'photo-video']"></fa-icon></p>
				<h2>Education</h2>
				<p>Learn all about the dashboard with our library of educational materials.</p>
				<p class="btn btn-light">See More</p>
			</a>
		</div>
	</div>
</section>

export class Savings {
	customerRate: Saving[];
	termRate: Saving[];
	savings: Saving[];
	totalSavings: number;
}

export class Saving {
	value: number;
	usage: number;
	units: number;
	date: string;
}

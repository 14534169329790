<pk-broker-loader [show]="loading"></pk-broker-loader>
<form [formGroup]="formGroup" *ngIf="!loading">
	<ng-container *ngIf="controlType === 'filter'">
		<p class="compact-select-label font-size-085 mb-0" [class.required]="isRequired">
			{{ label }}
			<a href="javascript:void(0)" class="ml-1 dark-blue-link semibold-text" (click)="showLookupUser()">
				{{ user?.getFullNameLastFirst() ?? nullUserText }}
			</a>
			<a *ngIf="user" href="javascript:void(0)" (click)="clearUser()">
				<fa-icon [classes]="['text-danger', 'ml-2']" [icon]="['fas', 'times']"></fa-icon>
			</a>
		</p>
	</ng-container>

	<ng-container *ngIf="controlType === 'form'">
		<div class="form-row">
			<div class="col form-element-container pb-0">
				<label class="title" [for]="inputId" [class.required]="isRequired">{{ label }}</label>
			</div>
		</div>
		<div class="form-row d-flex align-items-end">
			<div class="col-12 col-lg-5 col-xl-4">
				<div class="form-row" *ngIf="isTicketDashboard && !loggedInUser.agentId">
					<div class="col form-element-container pb-0">
						<label for="searchAdmin">Choose Searching Target</label>
					</div>
				</div>
				<div class="form-row">
					<!-- Search Admin -->
					<div class="col form-element-container">
						<div class="custom-control custom-radio">
							<input class="custom-control-input" [value]="true" formControlName="searchAdmin" type="radio" id="searchAdmin" />
							<label class="custom-control-label" for="searchAdmin">Search Admin</label>
						</div>
					</div>
					<!-- Search Agent -->
					<div class="col form-element-container">
						<div class="custom-control custom-radio">
							<input class="custom-control-input" [value]="false" formControlName="searchAdmin" type="radio" id="searchAgent" />
							<label class="custom-control-label" for="searchAgent">Search Agent</label>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-7 col-xl-8 form-element-container">
				<div class="input-group">
					<input
						type="text"
						id="agentSearch"
						class="form-control"
						aria-label="Agent"
						aria-describedby="button-addon"
						[value]="getDisplayName()"
						readonly />
					<div class="input-group-append">
						<button class="btn btn-default" type="button" (click)="clearUser()">
							<fa-icon [icon]="['fas', 'times']"></fa-icon>
						</button>
						<button class="btn btn-default" type="button" (click)="showLookupUser()">
							<fa-icon [icon]="['fas', 'search']"></fa-icon>
						</button>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</form>

import { Faq } from './faq';

export class FaqCategory {

	public id: number;
	public name: string;
	public iconClassName: string;
	public sortOrder: number;
	public addDate: string;
	public modifiedDate: string;

	public faqs: Faq[];

	constructor(faqCategory?: FaqCategory) {
		Object.assign(this, faqCategory);
	}
}

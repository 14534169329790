import { Pipe, PipeTransform } from '@angular/core';
import { CONSTANTS } from '@pk/powerkioskutils';

import * as _ from 'lodash';

import { HelperService } from '../helper.service';
import { Rate } from '../models/rate';

@Pipe({
	name: 'filterBestPlans',
})
export class FilterBestPlansPipe implements PipeTransform {

	transform(plans: Rate[], isAutomatedRenewal: boolean, previousContractTerm: number): Rate[] {
		if (!plans || !plans.length) { return []; }

		const sortedPlans = _.orderBy(plans
			.filter(p => [CONSTANTS.products.fixedAllInElectric, CONSTANTS.products.fixedAllInGas].includes(p.productId)
				&& p.isLoadFactorEligible),
			'displayRateRaw');

		if (isAutomatedRenewal) {
			return this.getAutomatedRenewalBestPlans(sortedPlans, previousContractTerm);
		}

		return this.getBestPlans(sortedPlans);
	}

	private getBestPlans(sortedPlans: Rate[]): Rate[] {
		const bestPlans = [];

		const bestOverallPlan = sortedPlans.filter(p => p.term >= 12)[0];
		if (bestOverallPlan) {
			bestOverallPlan.planName2 = `Best<br>Overall`;
			bestOverallPlan.planName2Class = 'best-overall'; // this value used in rates-rate-actions.component.ts
		}

		if (bestOverallPlan?.term >= 12 && bestOverallPlan?.term <= 18) {
			bestPlans.push(bestOverallPlan);
		} else {
			const bestShortTermPlan = sortedPlans.filter(p => p.term >= 12 && p.term <= 18)[0];

			if (bestShortTermPlan) {
				bestShortTermPlan.planName2 = 'Best<br>Short Term';
				bestShortTermPlan.planName2Class = 'best-plan';
				bestPlans.push(bestShortTermPlan);
			}
		}

		if (bestOverallPlan?.term >= 19 && bestOverallPlan?.term <= 30) {
			bestPlans.unshift(bestOverallPlan);
		} else {
			const bestMediumTermPlan = sortedPlans.filter(p => p.term >= 19 && p.term <= 30)[0];

			if (bestMediumTermPlan) {
				bestMediumTermPlan.planName2 = 'Best<br>Medium Term';
				bestMediumTermPlan.planName2Class = 'best-plan';
				bestPlans.push(bestMediumTermPlan);
			}
		}

		if (bestOverallPlan?.term >= 31) {
			bestPlans.unshift(bestOverallPlan);
		} else {
			const bestLongTermPlan = sortedPlans.filter(p => p.term > 31)[0];

			if (bestLongTermPlan) {
				bestLongTermPlan.planName2 = `Best<br>Long Term`;
				bestLongTermPlan.planName2Class = 'best-plan';
				bestPlans.push(bestLongTermPlan);
			}
		}

		return bestPlans;
	}

	private getAutomatedRenewalBestPlans(sortedPlans: Rate[], previousContractTerm: number): Rate[] {
		const bestPlans = [];
		const popularTerms = HelperService.popularTerms;
		const popularTermsReversed = _.reverse(_.clone(HelperService.popularTerms));
		const usedTerms = [];

		// choose the best rates among the popular terms
		// if no matching terms, fallback to just choosing the best rates
		const bestOverallPlan = sortedPlans.filter(p => p.term === previousContractTerm)[0]
			|| sortedPlans.filter(p => p.term >= 6)[0];
		if (bestOverallPlan) {
			bestOverallPlan.planName2 = `Best<br>Overall`;
			bestOverallPlan.planName2Class = 'best-overall';
		}

		bestPlans.push(bestOverallPlan);
		usedTerms.push(bestOverallPlan.term);

		let nextPopularTermUp = popularTerms.find(p => p > previousContractTerm);
		let nextPopularTermDown = popularTermsReversed.find(p => p < previousContractTerm);
		let nextBestPlan = sortedPlans.find(p => p.term === nextPopularTermUp)
			|| sortedPlans.find(p => p.term > nextPopularTermUp && popularTerms.includes(p.term))
			|| sortedPlans.find(p => p.term === nextPopularTermDown)
			|| sortedPlans.find(p => p.term < nextPopularTermDown && popularTerms.includes(p.term))
			|| (sortedPlans.length ? sortedPlans.find(p => !usedTerms.includes(p.term)) : null);
		if (nextBestPlan) {
			nextBestPlan.planName2 = `Best<br>${nextBestPlan.term} Months`;
			nextBestPlan.planName2Class = 'best-plan';
			bestPlans.push(nextBestPlan);

			nextPopularTermUp = popularTerms.find(p => p > nextBestPlan.term && !usedTerms.includes(p));
			nextPopularTermDown = popularTermsReversed.find(p => p < nextBestPlan.term && !usedTerms.includes(p));
			usedTerms.push(nextBestPlan.term);
		}

		nextBestPlan = sortedPlans.find(p => p.term === nextPopularTermUp)
			|| sortedPlans.find(p => p.term > nextPopularTermUp &&
				popularTerms.filter(pt => pt >= 12).includes(p.term) && !usedTerms.includes(p.term))
			|| sortedPlans.find(p => p.term === nextPopularTermDown && !usedTerms.includes(p.term))
			|| sortedPlans.find(p => p.term < nextPopularTermDown && popularTerms.includes(p.term) && !usedTerms.includes(p.term))
			|| (sortedPlans.length ? sortedPlans.find(p => !usedTerms.includes(p.term)) : null);
		if (nextBestPlan) {
			nextBestPlan.planName2 = `Best<br>${nextBestPlan.term} Months`;
			nextBestPlan.planName2Class = 'best-plan';
			bestPlans.push(nextBestPlan);
		}

		return bestPlans;
	}

}

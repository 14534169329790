export class CpanelPriceElement {

	public itemRatio: number;
	public name: string;
	public description: string;
	public serviceTypeId: string;
	public priceElementId: string;
	public sortOrder: number;
	public isActive: boolean;
	public element: number;

	constructor(cpanelPriceElement?: CpanelPriceElement) {
		Object.assign(this, cpanelPriceElement);
	}
}

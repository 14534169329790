import { Component, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { RfqSessionOptOutReasonType } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-supplier-opt-out-modal',
	templateUrl: './auction-supplier-opt-out-modal.component.html',
	styleUrls: ['./auction-supplier-opt-out-modal.component.scss'],
})
export class AuctionSupplierOptOutModalComponent extends AbstractPageForm {

	// input
	public rfqSessionSupplierId: string;
	public contractNum: string;
	public dbaName: string;

	// output
	public updated: EventEmitter<void> = new EventEmitter();

	public optOutReasons: RfqSessionOptOutReasonType[];

	constructor(
		public activeModal: BsModalRef,
		private graphqlService: GraphqlService,
		private fb: FormBuilder,
		private toastrService: ToastrService,
	) {
		super();
		this.submitText = 'Opt Out';
		this.submittingText = 'Opting out...';
	}

	public async loadPageData(): Promise<void> {
		const pageResult = await this.graphqlService.getRfqSessionOptOutReasonTypeListData();
		this.optOutReasons = pageResult.data.rfqSessionOptOutReasonTypes.message;
	}

	public getForm() {
		return this.fb.group({
			optOutReason: [null as RfqSessionOptOutReasonType, [Validators.required]],
			note: '',
		});
	}

	public async onFormLoaded(): Promise<void> {
		this.form.optOutReason.valueChanges.subscribe(value => {
			if (value?.id === 19) {
				this.form.note.setValidators([Validators.required]);
			} else {
				this.form.note.setValidators([]);
			}
			this.form.note.updateValueAndValidity();
		});
	}

	protected async onFormSubmitted() {
		await this.graphqlService.updateRfqSessionSupplier(this.rfqSessionSupplierId, this.createOptOutDTO());
		this.toastrService.success('Successfully opted out', 'Opt Out');
		this.activeModal.hide();
		this.updated.emit();
	}

	private createOptOutDTO() {
		return {
			isOptOut: true,
			rfqSessionOptOutReasonTypeId: this.form.optOutReason.value.id,
			optOutReasonNote: this.form.note.value,
		};
	}
}

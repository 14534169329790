<div class="container-fluid search-panel" *ngIf="faqArticle">
	<div class="row">
		<div class="col">
			<h4 class="blue-text">{{ faqArticle.question }}</h4>
			<span [innerHtml]="faqArticle.answer"></span>
			<p *ngIf="loading"><pk-broker-loader [show]="loading"></pk-broker-loader></p>
			<ng-container *ngIf="!loading && relatedArticles.length">
				<h4>Related Information</h4>
				<ul>
					<li *ngFor="let article of relatedArticles">
						<a href="javascript:void(0)" (click)="chooseFaqArticle(article)">{{ article.question }}</a>
					</li>
				</ul>
			</ng-container>
		</div>
	</div>
</div>

export class ReportTicket {

	public number: number;
	public subject: string;
	public category: string;
	public status: string;
	public reporter: string;
	public assignee: string;
	public completedBy: string;
	public completeDate: Date;
	public addDate: Date;
	public timeToFirstContact: Date;
	public resolutionTime: Date;

	constructor(reportTicket: ReportTicket) {
		Object.assign(this, reportTicket);
	}
}

<ng-container *ngIf="controlType === 'filter'">
	<p class="compact-select-label font-size-085 mb-0">
		{{ label }}
		<a href="javascript:void(0)" class="ml-1 dark-blue-link semibold-text" (click)="showLookupAgent()">
			{{ agent?.agentLabel ?? nullAgentText }}
		</a>
		<a *ngIf="agent && agent.agentId !== securityService.authFields.loggedInUser.agent?.agentId" href="javascript:void(0)" (click)="clearAgent()">
			<fa-icon [classes]="['text-danger', 'ml-2']" [icon]="['fas', 'times']"></fa-icon>
		</a>
	</p>
</ng-container>

<ng-container *ngIf="controlType === 'form'">
	<label class="title" [class.required]="isRequired" [for]="inputId">{{ label }}</label>
	<div class="input-group">
		<input type="text" class="form-control" [id]="inputId" [value]="agent?.agentLabel ?? nullAgentText" readonly />
		<div class="input-group-append">
			<button type="button" class="btn btn-default" (click)="clearAgent()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon>
			</button>
			<button type="button" class="btn btn-default" (click)="showLookupAgent()">
				<fa-icon [icon]="['fas', 'search']"></fa-icon>
			</button>
		</div>
	</div>
</ng-container>

import { Hub } from './hub';
import { State } from './state';
import { Utility } from './utility';

export class UtilityHub {

	public id: number;
	public utilityId: string;
	public stateId: string;
	public hubId: number;

	public state: State;
	public utility: Utility;
	public hub: Hub;

	constructor(utilityHub: UtilityHub) {
		Object.assign(this, utilityHub);
	}
}

export class AiGeneratedContent {

	public id: number;
	public ticketId: number;
	public content: string;

	constructor(aiGeneratedContent: AiGeneratedContent) {
		Object.assign(this, aiGeneratedContent);
	}
}

import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

import { Rate, Supplier } from '../models';

@Pipe({
	name: 'filterSuppliers'
})
export class FilterSuppliersPipe implements PipeTransform {
	transform(suppliers: Supplier[], rates: Rate[]): any {
		const supplierIds = _.uniq(rates.map(r => r.supplierId));
		return supplierIds && suppliers ? suppliers.filter(s => supplierIds.includes(s.supplierId)) : suppliers;
	}
}


export class ComplianceMonitorAgentDetailTermValue {
	confirmedTermValue: number;
	flowedTermValue: number;
	droppedTermValue: number;
	rejectedTermValue: number;
	reversibleCommission: number;
	futureCommissionAmount: number;
	totalCommissionPaid: number;
	totalCommissionClawedBack: number;
}

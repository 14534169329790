import { SafeUrl } from '@angular/platform-browser';

import * as moment from 'moment-timezone';

import { AttachmentBase64Response } from './attachment-base64-response';
import { AttachmentType } from './attachment-type';
import { Company } from './company';
import { Contract } from './contract';
import { EsignDocument } from './esign-document';
import { KeyTextExtractionResults } from './key-text-extraction-results';
import { Supplier } from './supplier';
import { User } from './user';

export class Attachment {

	public attachmentId: string;
	public contractId: string;
	public userId: string;
	public name: string;
	public originalName: string;
	public description: string;
	public attachmentTypeId: string;
	public addDate: Date;
	public path: string;
	public size: number;
	public supplierId: string;
	public utilityId: string;
	public deleteType: string;
	public deleteUrl: string;
	public extension: string;
	public thumbnailUrl: string;
	public url: string;
	public id: string;
	public companyId: string;
	public contentType: string;
	public contentSubType: string;
	public ticketId: number;
	public ticketCommentId: number;
	public signatureDate: Date;
	public pageCount: number;

	public base64: SafeUrl;

	public company: Company;
	public attachmentType: AttachmentType;
	public keyTextExtractionResults: KeyTextExtractionResults;
	public user: User;
	public supplier: Supplier;
	public contract: Contract;
	public esignDocument: EsignDocument;
	public attachmentBase64: AttachmentBase64Response;

	// internal
	public deleting = false;

	constructor(attachment?: Subset<Attachment>) {
		Object.assign(this, attachment);

		this.addDate = this.addDate
			? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
		this.signatureDate = this.signatureDate
			? new Date(moment(this.signatureDate).format('MMM DD, YYYY hh:mm:ss a')) : this.signatureDate;
		this.attachmentType = this.attachmentType ? new AttachmentType(this.attachmentType) : this.attachmentType;
		this.user = this.user ? new User(this.user) : this.user;
		this.contract = this.contract ? new Contract(this.contract) : this.contract;
		this.supplier = this.supplier ? new Supplier(this.supplier) : this.supplier;
		this.company = this.company ? new Company(this.company) : this.company;
	}

	get nameWithDateLabel(): string {
		return `${this.originalName} (${moment(this.addDate).format('MM/DD/YYYY')})`;
	}
}

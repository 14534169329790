import { Component, Input, Output } from '@angular/core';

import { confirm } from 'devextreme/ui/dialog';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { GraphqlService } from '../../graphql/graphql.service';
import { RfqSession, RfqSessionBid } from '../../shared/models';

@Component({
	selector: 'pk-broker-auction-supplier-bids',
	templateUrl: './auction-supplier-bids.component.html',
	styleUrls: ['./auction-supplier-bids.component.scss'],
})
export class AuctionSupplierBidsComponent {

	@Output() deletedBid: Subject<string> = new Subject();

	@Input() rfqSession: RfqSession;
	@Input() supplierId: string;

	public warnings = [];
	public environment = environment;

	constructor(
		private graphqlService: GraphqlService,
		private toastrService: ToastrService,
	) { }

	public async removeBid(bid: RfqSessionBid): Promise<void> {
		this.warnings = [];
		try {
			const result = await confirm('Are you sure you want to remove this bid?', 'Confirm');
			if (result) {
				bid.removing = true;
				const rfqSessionResult = await this.graphqlService.deleteRfqSessionBid(bid.id);
				if (rfqSessionResult) {
					this.rfqSession.bids.splice(this.rfqSession.bids.findIndex(b => b.id === bid.id), 1);
					this.rfqSession.bids = _.clone(this.rfqSession.bids); // force pipe
					this.deletedBid.next(bid.id);
					this.toastrService.success('Successfully removed!', 'Pricing Session');
				} else {
					this.warnings = ['There was a problem deleting the bid. We have been notified and are working to fix the issue. Please check back again in 30 minutes.'];
				}
			}
		} catch (e) {
			this.warnings = ['There was a problem deleting the bid. We have been notified and are working to fix the issue. Please check back again in 30 minutes.'];
		}
		bid.removing = false;
	}
}

import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { SecurityService } from 'src/app/security/security.service';
import { HelperService } from 'src/app/shared/helper.service';
import { Timezone } from 'src/app/shared/models';

@Component({
	selector: 'pk-broker-customer-availability',
	templateUrl: './customer-availability.component.html',
	styleUrls: ['./customer-availability.component.scss'],
})
export class CustomerAvailabilityComponent implements OnInit {
	@Input() form: UntypedFormGroup;

	public loading = true;
	public currentCustomerTimezone;
	public currentCustomerAvailabilityId = null;
	public timezones: Timezone[] = [];
	public daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
	public fromTimes = [];
	public toTimes = [];
	public fullDayTimes = [];
	public fromInterval = { start: 9, end: 16 };
	public toInterval = { start: 10, end: 17 };
	public defaultFromTime = '9:00 AM CT';
	public defaultToTime = '5:00 PM CT';
	public timezoneAbbreviation = '';
	public defaultTimezone = null;

	constructor(
		private graphqlService: GraphqlService,
		private toastrService: ToastrService,
		private helperService: HelperService,
		private securityService: SecurityService,
	) { }

	get customerTimezone() { return this.form?.get('availability.timezone'); }
	get customerAvailableTime(): UntypedFormArray { return this.form?.get('availability.availableTime') as UntypedFormArray; }
	get customerAvailabilityId() { return this.form?.get('customerAvailabilityId'); }
	get loggedInUser() { return this.securityService.authFields?.loggedInUser; }

	async ngOnInit(): Promise<void> {
		if (!this.form) {
			this.toastrService.warning('Form not initialized', 'Customer Edit');
			return;
		}
		await this.getTimezonesData();
		this.currentCustomerTimezone = this.form.get('availability.timezone').value ?
			this.form.get('availability.timezone').value :
			this.defaultTimezone.id;
		this.currentCustomerAvailabilityId = this.form.get('customerAvailabilityId').value;
		this.adjustTimezoneInterval(this.customerTimezone.value);
		this.populateTimes();

		this.customerTimezone.valueChanges.subscribe(() => {
			if (!this.customerTimezone.value) { return; }
			this.updateTable();
		});

		this.loading = false;
	}

	public updateTable() {
		this.adjustTimezoneInterval(this.customerTimezone.value);
		this.populateTimes();
		let offset = 0;
		//Checks if searched customer / updating table with a different customer
		if (this.customerAvailabilityId.value === this.currentCustomerAvailabilityId) {
			offset = this.helperService.calculateTimezoneOffset(this.currentCustomerTimezone, this.customerTimezone.value, this.timezones);
		}
		this.adjustAvailabilityTime(offset);
		this.currentCustomerAvailabilityId = this.customerAvailabilityId.value;
		this.currentCustomerTimezone = this.customerTimezone.value;
	}

	public async adjustAvailabilityTime(offset: number) {
		await Promise.all([...this.customerAvailableTime.controls.entries()].map(async ([i, t]) => {
			const currentDay = (t as UntypedFormGroup).controls;
			if (currentDay?.from?.value) {
				const fromValue = ((t.get('from').value) + offset);
				//wait a frame so that options get updated in from-dropdown
				await new Promise(resolve => setTimeout(resolve, 0));
				currentDay.from.setValue(fromValue);
			}
			if (currentDay?.to?.value) {
				const toValue = ((t.get('to').value) + offset);
				//wait a frame so that options get updated in from-dropdown
				await new Promise(resolve => setTimeout(resolve, 0));
				currentDay.to.setValue(toValue);
			}
		}));
	}

	public adjustTimezoneInterval(timezone: number) {
		const defaultStartTime = 9;
		const defaultEndTime = 16;
		if (!timezone) { return; }
		const paramTimezone = this.timezones.filter(t => t.id === timezone);
		if (paramTimezone?.length) {
			const offset = this.helperService.calculateTimezoneOffset(this.defaultTimezone.id, paramTimezone[0].id, this.timezones);
			this.fromInterval.start = defaultStartTime + offset;
			this.fromInterval.end = defaultEndTime + offset;
			this.timezoneAbbreviation = paramTimezone[0].abbreviation;
			this.adjustTimeAttributes();
		} else {
			this.toastrService.warning('Cannot find timezone.', 'Internal Error');
		}
	}

	private adjustTimeAttributes() {
		this.toInterval.start = this.fromInterval.start + 1;
		this.toInterval.end = this.fromInterval.end + 1;
		this.defaultFromTime = this.helperService.convertTwentyFourHourToTwelveHour(this.fromInterval.start) +
			' ' +
			this.timezoneAbbreviation;
		this.defaultToTime = this.helperService.convertTwentyFourHourToTwelveHour(this.toInterval.end) +
			' ' +
			this.timezoneAbbreviation;
	}

	public async getTimezonesData(): Promise<void> {
		try {
			const result = await this.graphqlService.getAllTimezones();
			this.timezones = result.data.timezones.message;
			this.defaultTimezone = this.timezones.filter(t => t.id === 1)[0];	//id for US/Central is 1
			const isEditingCustomerInfo = this.form?.get('availability.timezone')?.value ? true : false;
			if (!isEditingCustomerInfo) {
				if (this.defaultTimezone) {
					this.customerTimezone.setValue(this.defaultTimezone.id);
					this.timezoneAbbreviation = this.defaultTimezone.abbreviation;
				} else {
					this.customerTimezone.setValue(this.timezones[0].id);
					this.timezoneAbbreviation = this.timezones[0].abbreviation;
					this.defaultTimezone = this.timezones[0];
				}
			} else {
				this.timezoneAbbreviation = this.timezones.filter(t => t.id === this.customerTimezone.value)[0].abbreviation;
			}
		} catch (e) {
			this.toastrService.warning('Failed to retrieve timezones.', 'Internal Error');
		}
	}

	public populateTimes() {
		this.fromTimes = [];
		this.toTimes = [];
		this.fullDayTimes = [];
		for (let i = 0; i < 24; i++) {
			let twelveHourTime = '';
			if (i === 0) {
				twelveHourTime = '12:00 AM ' + this.timezoneAbbreviation;
			} else if (i < 12) {
				twelveHourTime = (i) + ':00 AM ' + this.timezoneAbbreviation;
			} else if (i === 12) {
				twelveHourTime = (i) + ':00 PM ' + this.timezoneAbbreviation;
			} else {
				twelveHourTime = (i - 12) + ':00 PM ' + this.timezoneAbbreviation;
			}

			this.fromTimes.push({ text: twelveHourTime, value: i });
			this.toTimes.push({ text: twelveHourTime, value: i });

			this.fromTimes = this.fromTimes.filter(t => t.value >= this.fromInterval.start && t.value <= this.fromInterval.end);
			this.toTimes = this.toTimes.filter(t => t.value >= this.toInterval.start && t.value <= this.toInterval.end);
			this.fullDayTimes.push({ text: twelveHourTime, value: i });
		}
	}

	public setAvailabilityValidators(index: number, defaultFromTime: number, defaultToTime: number) {
		const available = this.customerAvailableTime.controls[index].get('available').value;
		const fromForm = this.customerAvailableTime.controls[index].get('from');
		const toForm = this.customerAvailableTime.controls[index].get('to');

		if (available) {
			fromForm.enable();
			fromForm.setValidators([Validators.required, this.nullValidator()]);
			fromForm.setValue(defaultFromTime);
			toForm.enable();
			toForm.setValidators([Validators.required, this.nullValidator()]);
			toForm.setValue(defaultToTime);

		} else {
			fromForm.disable();
			fromForm.setValidators([]);
			fromForm.setValue(null);
			toForm.disable();
			toForm.setValidators([]);
			toForm.setValue(null);
		}
		toForm.updateValueAndValidity();
		fromForm.updateValueAndValidity();
	}


	public nullValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => (!control.value && control.enabled) ? { scheduleNull: true } : null;
	}


	public onAvailableChecked(index: number) {
		this.setAvailabilityValidators(index, this.fromInterval.start, this.toInterval.end);
	}
}

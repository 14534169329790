import { Pipe, PipeTransform } from '@angular/core';
import { NumberUtils } from '@pk/powerkioskutils';

import { RfqSessionBid, RfqSessionStat } from '../models';

@Pipe({
	name: 'convertBidUnits',
})
export class ConvertBidUnitsPipe implements PipeTransform {

	transform(bid: number | RfqSessionBid | RfqSessionStat, units: string): unknown {
		const amount = bid instanceof RfqSessionBid // bid
			? bid.rate
			: bid instanceof RfqSessionStat // stat
				? bid.bestBid
				: bid; // number
		return NumberUtils.rateToUnits(amount, units.split('/')[1]);
	}
}

import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';

import * as moment from 'moment-timezone';

import { ServiceLocator } from '../service-locator.service';
import { Contract } from './contract';

export class ViewCommissionDashboard {

	public id: number;
	public description: string;
	public customerName: string;
	public contractId: string;
	public contractLocationId: string;
	public supplierName: string;
	public commodity: string;
	public accountNumber: string;
	public meterStartDate: Date | string;
	public meterEndDate: Date | string;
	public usage: number | string;
	public margin: number | string;
	public supplierPayment: number | string;
	public subjects: string;
	public subjectIds: string;
	public isDirectSale: boolean;
	public isProcessed: boolean;
	public isArchived: boolean;
	public totalIssues: number;
	public totalNotes: number;
	public hasCriticalPriority: boolean;
	public hasHighPriority: boolean;
	public hasMediumPriority: boolean;
	public hasLowPriority: boolean;
	public sentToOps: boolean;
	public commissionTypeId: number;
	public processTimeType: string;
	public fileName: string;
	public attachmentId: string;
	public status: string;
	public commissionPayPlanName: string;
	public commissionTypeName: string;
	public contractNum: string;
	public contractUnits: string;
	public addDate: string;
	public companyId: string;
	public archiveDate: string;
	public hasMatchedAccounts: boolean;
	public externalId: string;

	// internal use
	public selectedForProcess: boolean;
	public rawPendingCommission: ViewCommissionDashboard;
	public contract: Contract;

	constructor(viewCommissionDashboard: ViewCommissionDashboard) {
		Object.assign(this, viewCommissionDashboard);

		this.rawPendingCommission = viewCommissionDashboard;

		this.meterStartDate = this.meterStartDate
			? new Date(moment(this.meterStartDate).format('MMM DD, YYYY 00:00:00 a')) : this.meterStartDate;
		this.meterEndDate = this.meterEndDate
			? new Date(moment(this.meterEndDate).format('MMM DD, YYYY 00:00:00 a')) : this.meterEndDate;

		const decimalPipe = ServiceLocator.injector.get(DecimalPipe);
		const currencyPipe = ServiceLocator.injector.get(CurrencyPipe);
		const datePipe = ServiceLocator.injector.get(DatePipe);

		this.usage = decimalPipe.transform(this.usage);
		this.margin = decimalPipe.transform(this.margin, '1.6-6');
		this.supplierPayment = this.supplierPayment && typeof this.supplierPayment === 'string'
			? this.supplierPayment
			: currencyPipe.transform(this.supplierPayment);
		this.addDate = datePipe.transform(this.addDate, 'MM/dd/y');
		try { this.archiveDate = datePipe.transform(this.archiveDate, 'MM/dd/yyyy'); } catch (e) { /* not a date */ }
	}
}

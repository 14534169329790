<h3>Select Contract</h3>
<form [formGroup]="searchForm" (submit)="searchContracts()">
	<div class="input-group mb-3">
		<input
			type="text"
			class="form-control"
			placeholder="Search - (Minimum of 4 characters required)"
			formControlName="search"
			required
			(keyup)="searchContracts()" />
		<div class="input-group-append">
			<button type="button" class="btn btn-default" [disabled]="!searchForm.valid" (click)="searchForm.reset()">
				<fa-icon [icon]="['fas', 'times']"></fa-icon>
			</button>
			<button type="button" class="btn btn-default" [disabled]="!searchForm.valid" (click)="searchContracts()">
				<fa-icon [icon]="['fas', 'search']"></fa-icon>
			</button>
		</div>
	</div>
</form>
<pk-broker-loader [show]="isInitialLoad"></pk-broker-loader>
<dx-data-grid
	id="gridContainer"
	class="table-sm"
	[dataSource]="contractsDataSource"
	[allowColumnResizing]="true"
	[columnAutoWidth]="true"
	[showBorders]="true"
	[remoteOperations]="true"
	[rowAlternationEnabled]="true"
	[hoverStateEnabled]="true"
	(onInitialized)="onLookupContractGridInit($event)">
	<dxo-scrolling [useNative]="true"></dxo-scrolling>
	<dxo-header-filter [visible]="true"></dxo-header-filter>
	<dxi-column
		caption="Actions"
		[fixed]="true"
		fixedPosition="left"
		cellTemplate="actionsTemplate"
		[allowSorting]="false"
		[allowHeaderFiltering]="false"></dxi-column>
	<dxi-column
		caption="Contract #"
		[fixed]="true"
		fixedPosition="left"
		dataField="contractNum"
		sortOrder="asc"
		dataType="string"
		cellTemplate="contractNumTemplate"
		[allowHeaderFiltering]="false"></dxi-column>
	<dxi-column caption="DBA Name" dataField="dbaName" dataType="string" cellTemplate="dbaNameCellTemplate" [allowHeaderFiltering]="false"></dxi-column>
	<dxi-column caption="Status" dataField="displayStatus" dataType="string" [allowHeaderFiltering]="false"></dxi-column>
	<dxi-column caption="Commodity" dataField="commodity" dataType="string" [allowHeaderFiltering]="false"></dxi-column>
	<dxi-column caption="Supplier" dataField="supplierName" dataType="string" [allowHeaderFiltering]="false"></dxi-column>
	<dxi-column caption="Eff. Date" dataField="effectiveDate" dataType="date" [allowHeaderFiltering]="false"></dxi-column>
	<dxi-column caption="Exp. Date" dataField="expirationDate" dataType="date" [allowHeaderFiltering]="false"></dxi-column>
	<dxi-column caption="Usage" dataField="annualUsage" dataType="string" cellTemplate="usageCellTemplate" [allowHeaderFiltering]="false"></dxi-column>
	<dxi-column
		caption="Margin"
		dataField="margin"
		dataType="string"
		cellTemplate="marginCellTemplate"
		[allowHeaderFiltering]="false"
		[visible]="!isEnterpiseAgent"></dxi-column>
	<dxi-column caption="Agent" dataField="agentName" dataType="string" cellTemplate="agentCellTemplate" [allowHeaderFiltering]="false"></dxi-column>
	<dxo-paging [pageSize]="10"></dxo-paging>
	<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 15, 20]"></dxo-pager>
	<div *dxTemplate="let d of 'dbaNameCellTemplate'">
		<div class="chart-cell">
			<a href="javascript:void(0)" [tooltip]="d.data.dbaName" placement="top" container="body" [adaptivePosition]="false" target="_blank">
				{{ d.data.dbaName | shrink: 19 }}
			</a>
		</div>
	</div>
	<div *dxTemplate="let d of 'agentCellTemplate'">
		<div class="chart-cell">
			{{ d.data.displayAgentName }}
			<ng-container *ngIf="d.data.parentName"> <br />{{ d.data.parentName }} </ng-container>
			<ng-container *ngIf="d.data.brotherName"> <br />{{ d.data.brotherName }} </ng-container>
		</div>
	</div>
	<div *dxTemplate="let d of 'marginCellTemplate'">
		<div class="chart-cell">{{ d.data.getTotalMargin(false, true, false, d.data.units) | currency: 'USD':'symbol':'1.5-5' }} / {{ d.data.units }}</div>
	</div>
	<div *dxTemplate="let d of 'usageCellTemplate'">
		<div class="chart-cell">
			{{ d.data.isResidential ? 'Residential' : (d.data.getDisplayAnnualUsage() | number: '1.0-0') + ' (' + d.data.units + ')' }}
		</div>
	</div>
	<div *dxTemplate="let d of 'actionsTemplate'">
		<div class="chart-cell">
			<div class="btn-group" role="group">
				<a class="btn btn-primary" (click)="select(d.data)">Select</a>
			</div>
		</div>
	</div>
	<div *dxTemplate="let d of 'contractNumTemplate'">
		<div class="chart-cell">
			<a [routerLink]="['/contract', d.data.contractId, 'info']" target="_blank" container="body">
				{{ d.data.displayContractNum }}
			</a>
		</div>
	</div>
</dx-data-grid>

import { CONSTANTS } from '@pk/powerkioskutils';

import * as moment from 'moment-timezone';

import { Contract } from './contract';
import { TaskType } from './task-type';
import { User } from './user';

export class Task {

	public contractId: string;
	public taskId: string;
	public isComplete: boolean;
	public title: string;
	public body: string;
	public dueDate: Date | string;
	public addDate: Date | string;
	public completeDate: Date | string;
	public assignedToUserId: string;
	public isDismissed: boolean;
	public isRead: boolean;
	public taskTypeId: number;

	public assignedToUser: User;
	public contract: Contract;
	public taskType: TaskType;

	public isCollapsed = true;
	public completing = false;
	public dismissing = false;

	constructor(task?: Task) {
		Object.assign(this, task);

		this.assignedToUser = this.assignedToUser ? new User(this.assignedToUser) : this.assignedToUser;
		this.dueDate = this.dueDate ? new Date(moment(this.dueDate).format('MMM DD, YYYY hh:mm:ss a')) : this.dueDate;
		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
		this.completeDate = this.completeDate ? new Date(moment(this.completeDate).format('MMM DD, YYYY hh:mm:ss a')) : this.completeDate;
		this.contract = this.contract ? new Contract(this.contract) : this.contract;
	}

	public getRowClass(asOfDate: Date = new Date()): string {
		if (this.dueDate == null || this.isComplete == null) { return ''; }
		if (this.isComplete) { return ''; }

		if ((this.dueDate as Date).valueOf() < asOfDate.valueOf()) {
			return 'error';
		}
		if ((this.dueDate as Date).valueOf() - asOfDate.valueOf() < CONSTANTS.sixDays) {
			return 'warning';
		}

		return '';
	}

	get panelClass(): string {
		if (!this.isRead && !this.isComplete) {
			return 'new';
		}

		if (this.isComplete) {
			return 'complete';
		}

		return '';
	}

	get isRenewal(): boolean {
		return this.title.toLowerCase().includes('renewal');
	}

	get isAuctionComplete(): boolean {
		return this.title.toLowerCase().includes('auction complete');
	}
}

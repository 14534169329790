import { Component, Input } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';

import { BsModalService } from 'ngx-bootstrap/modal';

import { SecurityService } from 'src/app/security/security.service';
import { HelperService } from 'src/app/shared/helper.service';
import { User } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';
import { UserAgentLookupModalComponent } from 'src/app/user/modals/user-agent-lookup/user-agent-lookup.component';
import { UserLookupModalComponent } from 'src/app/user/modals/user-lookup/user-lookup.component';

@Component({
	selector: 'pk-broker-user-selector',
	templateUrl: './user-selector.component.html',
	styleUrls: ['./user-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			useExisting: UserSelectorComponent,
		},
	],
})

export class UserSelectorComponent extends AbstractPageForm implements ControlValueAccessor {
	public user: User = null;
	public inputId: string;

	public disabled = false;
	private touched = false;

	@Input() label = 'User';
	@Input() isRequired = false;
	@Input() nullUserText = '-- None --';
	@Input() controlType: 'form' | 'filter' = 'form';
	@Input() isTicketDashboard = false;
	@Input() defaultSearchAdmin = true;

	public loggedInUser: User;

	constructor(
		private modalService: BsModalService,
		public securityService: SecurityService,
		private fb: FormBuilder,
	) {
		super();
		this.loggedInUser = this.securityService.authFields?.loggedInUser;
		this.inputId = HelperService.getUniqueId();
	}

	public async loadPageData(): Promise<void> { }

	public getForm() {
		return this.fb.group({
			searchAdmin: this.loggedInUser.agentId ? false : this.defaultSearchAdmin,
		});
	}

	onChange = (_user: User) => { /* placeholder for interface */ };
	onTouched = () => { /* placeholder for interface */ };

	public showLookupUser(): void {
		let modalRef;
		if (this.form.searchAdmin.value) {
			modalRef = this.modalService.show(UserLookupModalComponent, {
				class: 'pk-modal modal-xl',
				initialState: {
					agentId: this.loggedInUser.agentId,
				},
			});
		} else {
			modalRef = this.modalService.show(UserAgentLookupModalComponent, {
				class: 'pk-modal modal-xl',
			});
		}

		modalRef.content.onSubmit.subscribe((user: User) => {
			this.markAsTouched();
			this.user = user;
			this.onChange(this.user);
		});
	}

	public clearUser(): void {
		this.markAsTouched();
		this.user = null;
		this.onChange(this.user);
	}

	public getDisplayName() {
		if (this.user) {
			if (this.user.agentId) {
				return this.user.agent.name;
			} else {
				return this.user.getFullNameLastFirst() ?? this.nullUserText;
			}
		}

		return this.nullUserText;
	}

	writeValue(user: User) {
		this.user = user;
	}

	registerOnChange(onChange: (user: User) => void) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: () => void) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}
}

<pk-broker-loader [show]="loading"></pk-broker-loader>
<div class="alert alert-warning" *ngIf="agentIsOnHold">Account on Hold - Under Compliance Review</div>
<ng-container *ngIf="!loading && !agentIsOnHold">
	<ng-container *ngIf="rfqSession.maxExtends || !loggedInUser.agentId">
		<!-- Extend Auction - Limited to 2 per week -->
		<form id="extendAuction" [formGroup]="formGroup" *ngIf="!loading">
			<div class="row">
				<div class="col">
					<div class="mb-0">
						<div class="form-element-container">
							<label class="title required font-weight-bold">Set New End Date/Time</label>
							<dx-date-box
								formControlName="endDate"
								type="date"
								[disabledDates]="disableAuctionDates"
								[min]="minDate"
								placeholder="End Date"
								dateSerializationFormat="yyyy-MM-ddT00:00:00">
							</dx-date-box>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="form-element-container mb-0">
						<label>&nbsp;</label>
						<pk-broker-form-dropdown
							[isRequired]="true"
							formControlName="endTime"
							[options]="endTimes"
							[textMap]="endTimeTextMap"
							nullOption="-- Select End Time --"
							[nullOptionSelectable]="false">
						</pk-broker-form-dropdown>
					</div>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-sm-12">
					<label>Select the suppliers who will be reinvited</label>
					<div class="form-element-container my-2">
						<div class="custom-control custom-checkbox">
							<input class="custom-control-input" type="checkbox" id="selectAll" formControlName="selectAll" />
							<label class="custom-control-label" for="selectAll">Select All</label>
						</div>
					</div>
					<hr />
				</div>
			</div>
			<div class="row mt-0">
				<div class="col-sm-12">
					<ng-container formArrayName="suppliers" *ngFor="let supplier of form.suppliers; let i = index">
						<div class="form-element-container my-2 p-0" [formGroupName]="i">
							<div class="custom-control custom-checkbox">
								<input class="custom-control-input" type="checkbox" id="extend-suppliers-{{ i }}" formControlName="supplierSelected" />
								<label class="custom-control-label" for="extend-suppliers-{{ i }}">
									{{ supplier.name.value }}
									<ng-container *ngIf="supplier.isOptOut.value"> (Opted-Out)</ng-container>
									<ng-container *ngIf="supplier.outsideThreshold.value"> (Outside Usage Threshold)</ng-container>
									<ng-container *ngIf="supplier.notInvited.value"> (Not Originally Invited)</ng-container>
								</label>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</form>
	</ng-container>
	<ng-container *ngIf="!rfqSession.maxExtends && loggedInUser.agentId">
		<p class="text-danger">
			Sorry, you have extended twice this week. If you need additional help, please contact
			<a href="mailto:Deals@powerkiosk.com">Deals@powerkiosk.com</a>
			and
			<a href="mailto:{{ emergencyRequestEmail }}"> {{ emergencyRequestEmail }}</a
			>.
		</p>
		<p class="text-danger">
			For questions on using the platform please contact
			<a href="mailto:{{ emergencyRequestEmail }}">{{ emergencyRequestEmail }}</a
			>.
		</p>
	</ng-container>
	<div class="alert alert-warning" *ngIf="err.endTime">
		<ng-container *ngIf="form.endTime.errors?.required">End Time is required.</ng-container>
	</div>
	<div class="alert alert-warning" *ngIf="err.endDate">
		<ng-container *ngIf="form.endDate.errors?.required">End Date is required.</ng-container>
	</div>
	<div class="alert alert-warning" *ngIf="startValidation && form.suppliers.control.errors">
		<ng-container *ngIf="startValidation && form.suppliers.control.errors?.required">At least one supplier is required.</ng-container>
	</div>
</ng-container>

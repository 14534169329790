import { State } from './state';
import { Supplier } from './supplier';
import { Utility } from './utility';

export class UtilitySupplierMap {
	public id: number;
	public oldName: string;
	public oldName2: string;
	public supplierId: string;
	public serviceTypeId: string;
	public zone: string;
	public utilityId: string;
	public stateId: string;
	public billingMethod: string;
	public unit: string;
	public isActive: boolean;
	public userId: string;
	public modifiedUserId: string;
	public addDate: string;
	public modifiedDate: string;
	public isResidential: boolean;
	public zipCode: string;

	public utility: Utility;
	public state: State;
	public supplier: Supplier;

	constructor(utilitySupplierMap?: UtilitySupplierMap) {
		Object.assign(this, utilitySupplierMap);
	}
}

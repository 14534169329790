import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { SecurityService } from 'src/app/security/security.service';
import { Faq, FaqAudit, FaqAuditArticle, FaqCategory, FaqSearchResult } from 'src/app/shared/models';

@Injectable({
	providedIn: 'root',
})
export class FaqService {

	public formGroup: ReturnType<this['getForm']>;
	public loadingFaqSearch = false;
	public loadingFaqCategorySearch = false;
	public loadingRelatedArticles = false;
	public faqResult: { faqSearchResults: FaqSearchResult[] };
	public faqData: Subject<any> = new Subject();

	public faqCategory: FaqCategory;
	public faqArticle: Faq;
	public relatedArticles: Faq[];
	public loadingArticle = false;

	public faqCategories: FaqCategory[];
	public defaultFaqs: Faq[];

	public showFeedback = false;
	public isPositiveFeedback: boolean;
	public submittedFeedback: boolean;

	private faqAuditId: number;

	constructor(
		private graphqlService: GraphqlService,
		private toastrService: ToastrService,
		private fb: FormBuilder,
		private securityService: SecurityService,
	) { }

	public async getFaqPageData(): Promise<void> {
		try {
			const result = await this.graphqlService.getFaqPageData();

			this.faqCategories = result.data.faqCategories.message.filter(f => f.id !== 1);
			this.defaultFaqs = result.data.faqs.message;

			this.formGroup = this.getForm() as any;
			this.setupFaqSearchListener();
		} catch (e) {
			this.toastrService.warning('There was a problem getting the page data. We have been notified and are working to fix the issue. '
				+ 'Please check back again in 30 minutes.', 'FAQ');
		}
	}

	public getForm() {
		return this.fb.group({
			criteria: '',
		});
	}

	private setupFaqSearchListener(): void {
		this.faqData.pipe(
			tap(() => {
				this.loadingFaqSearch = true;
				this.faqArticle = null;
				this.relatedArticles = null;
				this.faqResult = null;
				this.faqCategory = null;
				this.faqAuditId = null;
			}),
			debounceTime(1200),
			switchMap(criteria =>
				this.graphqlService.getFaqSearchSub(criteria)),
		).subscribe(async res => {
			this.faqResult = {
				faqSearchResults: res.result.data.faqSearchResults.message.map(f => new FaqSearchResult(f)),
			};
			if (res.criteria.search && !this.securityService.authFields.loggedInUser.isAdmin) {
				const faqAuditResult = await this.graphqlService.createFaqAudit({
					criteria: res.criteria.search.trim(),
					matches: res.result.data.faqSearchResults.message.length,
					terms: _.uniq(_.flatMap(res.result.data.faqSearchResults.message.map(f => f.terms.split(',')))).join(','),
					matchedArticles: _.uniq(res.result.data.faqSearchResults.message.map(m => m.question)).join(','),
				} as FaqAudit);
				this.faqAuditId = faqAuditResult.data.createFaqAudit.id;
			}

			this.loadingFaqSearch = false;
		});
	}

	get criteria() { return this.formGroup.get('criteria'); }

	public async chooseFaqCategory(id: string): Promise<void> {
		this.faqCategory = null;
		this.faqArticle = null;
		this.faqResult = null;
		this.relatedArticles = null;
		this.faqAuditId = null;
		this.criteria.setValue('');
		this.loadingFaqCategorySearch = true;
		const result = await Promise.all([
			this.graphqlService.getFaqCategoryData(Number(id)),
			this.graphqlService.getFaqsByCategory(Number(id), 0),
		]);
		this.faqCategory = result[0].data.faqCategory;
		this.faqResult = { faqSearchResults: result[1].data.faqs.message as any[] };
		this.loadingFaqCategorySearch = false;
	}

	public async getFaqArticle(faqId: string): Promise<void> {
		this.faqArticle = null;
		if (this.faqAuditId) {
			this.graphqlService.createFaqAuditArticle(this.faqAuditId, {
				faqId: Number(faqId),
			} as FaqAuditArticle);
		}
		this.loadingArticle = true;
		const faqResult = await this.graphqlService.getFaqData(Number(faqId));
		this.faqArticle = faqResult.data.faq;
		if (!this.relatedArticles) {
			this.loadingRelatedArticles = true;
			const result = await this.graphqlService.getFaqsByCategory(this.faqArticle.faqCategoryId, 5);
			this.relatedArticles = result.data.faqs.message.filter(f => f.id !== this.faqArticle.id);
			this.loadingRelatedArticles = false;
		}

		this.loadingArticle = false;
	}
}

import { Utility } from './utility';

export class EdiUtility {

	public id: number;
	public utilityId: string;
	public stateId: string;
	public serviceTypeId: string;
	public ldcDUNSNumber: string;
	public ldcName: string;
	public ldcN1Qualifier: string;
	public espDUNSNumber: string;
	public espName: string;
	public espN1Qualifier: string;
	public requestKey: string;
	public espAccountNumber: string;

	public utility: Utility;

	constructor(ediUtility?: EdiUtility) {
		Object.assign(this, ediUtility);
	}
}

export class RejectReasonSubCategory {

	public id: number;
	public name: string;
	public supplierId: string;
	public rejectReasonAutoFill: string;

	public supplierRejectReasonSubCategories: RejectReasonSubCategory[];

	constructor(rejectReasonSubCategory?: RejectReasonSubCategory, otherRejectReasonSubCategories?: RejectReasonSubCategory[]) {
		Object.assign(this, rejectReasonSubCategory);

		this.supplierRejectReasonSubCategories =
			otherRejectReasonSubCategories?.filter(sc => sc.id !== this.id && sc.name === this.name) ?? [];
	}
}

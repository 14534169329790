<pk-broker-modal customBodyClass="pt-0 px-0">
	<ng-template #headerTemplate>
		<h5 class="modal-title">{{ featureTour.headline }}</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<div class="container-fluid">
			<div class="row">
				<div class="col-12 pt-3">
					<div id="featureTourCarousel" class="carousel slide feature-tour-carousel" data-ride="carousel" data-interval="false">
						<div class="carousel-inner">
							<div class="carousel-item" *ngFor="let slide of featureTour.slides; let i = index" [class.active]="i === activeIndex">
								<video
									#videoPlayer
									*ngIf="slide.videoUrl"
									class="video-js"
									controls
									preload="auto"
									width="1920"
									height="1080"
									[poster]="slide.imageUrl"
									data-setup='{"fluid": true}'>
									<source [src]="slide.videoUrl" type="video/mp4" />
									<p class="vjs-no-js">
										To view this video please enable JavaScript, and consider upgrading to a web browser that
										<a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
									</p>
								</video>
								<img *ngIf="!slide.videoUrl" [src]="slide.imageUrl" class="d-block w-100" alt="Slide {{ i + 1 }}" />
								<div class="carousel-caption">
									<p [innerHTML]="slide.caption"></p>
								</div>
							</div>
						</div>
						<a class="carousel-control-prev" href="javascript:void(0)" (click)="previous()" role="button">
							<span class="carousel-control-prev-icon" aria-hidden="true"></span>
							<span class="sr-only">Previous</span>
						</a>
						<a class="carousel-control-next" href="javascript:void(0)" (click)="next()" role="button">
							<span class="carousel-control-next-icon" aria-hidden="true"></span>
							<span class="sr-only">Next</span>
						</a>
						<ol class="carousel-indicators">
							<li *ngFor="let slide of featureTour.slides; let i = index" [class.active]="i === activeIndex" (click)="activeIndex = i"></li>
						</ol>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</pk-broker-modal>

export class RfqSessionSupplierAuctionMonitorEmailAudit {

	public id: number;
	public rfqSessionSupplierId: string;
	public sentNeedsRefreshedPricing: boolean;
	public clickedNeedsRefreshedPricingSection: boolean;
	public sentNoBids: boolean;
	public clickedNoBidsSection: boolean;
	public sentNotVisited: boolean;
	public clickedNotVisitedSection: boolean;
	public sentNotDownloadedRFP: boolean;
	public clickedNotDownloadedRFPSection: boolean;
	public sentWaitingOnLOEs: boolean;
	public clickedWaitingOnLOEsSection: boolean;
	public sentWaitingOnLOAs: boolean;
	public clickedWaitingOnLOAsSection: boolean;
	public sentWaitingOnSampleBills: boolean;
	public clickedWaitingOnSampleBillsSection: boolean;

	constructor(rfqSessionSupplierAuctionMonitorEmailAudit?: RfqSessionSupplierAuctionMonitorEmailAudit) {
		Object.assign(this, rfqSessionSupplierAuctionMonitorEmailAudit);
	}
}

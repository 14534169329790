import { PricingAnalyticsSupplierSetting } from './pricing-analytics-supplier-setting';

export class PricingAnalyticsSupplierSettingDashboard {

	public setting: PricingAnalyticsSupplierSetting;

	constructor(pricingAnalyticsSupplierSettingDashboard?: PricingAnalyticsSupplierSettingDashboard) {
		Object.assign(this, pricingAnalyticsSupplierSettingDashboard);
	}
}

import { State } from './state';

export class AddressValidationRequest {
	public address1: string;
	public address2: string;
	public city: string;
	public state: string;
	public zip: string;
}

export class AddressValidationResponse {
	public zip: string;
	public hasError: boolean;
	public errorMessage: string;
	public city: string;
	public countyName: string;
	public countyCode: string;
	public address1: string;
	public address2: string;

	public state: State;
}

export class AddressValidation {
	request: AddressValidationRequest;
	response: AddressValidationResponse;
}

export class AddressValidationReqResObj {
	contact?: AddressValidation;
	billing?: AddressValidation;
	locations?: AddressValidation[];
}

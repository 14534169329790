<button *ngIf="options.closeButton" (click)="remove()" class="toast-close-button" aria-label="Close">
	<span aria-hidden="true">&times;</span>
</button>
<div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
	{{ title }} <ng-container *ngIf="duplicatesCount">[{{ duplicatesCount + 1 }}]</ng-container>
</div>
<div *ngIf="message && options.enableHtml" role="alertdialog" aria-live="polite" [class]="options.messageClass" [innerHTML]="message"></div>
<div *ngIf="message && !options.enableHtml" role="alertdialog" aria-live="polite" [class]="options.messageClass" [attr.aria-label]="message">
	{{ message }}
	<br /><br />
	<a href="javascript:void(0)" id="csatModalLink" (click)="showCsatFormModal()">
		<fa-icon [icon]="['far', 'comments']"></fa-icon>
		How did we do?
	</a>
</div>
<div *ngIf="options.progressBar">
	<div class="toast-progress" [style.width]="width + '%'"></div>
</div>

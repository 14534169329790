import { User } from './user';

export class LossHistory {

	public id: string;
	public lossDate: Date;
	public addDate: Date;
	public userId: string;

	public user: User;

	constructor(lossHistory: LossHistory) {
		Object.assign(this, lossHistory);

		this.user = this.user ? new User(this.user) : this.user;
	}
}

import { CommunitySolarSetting } from './community-solar-setting';
import { Company } from './company';
import { CompanySupplierPortal } from './company-supplier-portal';
import { LendingModuleSetting } from './lending-module-setting';
import { Supplier } from './supplier';
import { SupplierCommissionRule } from './supplier-commission-rules';

export class CompanySupplier {
	public id: number;
	public companyId: string;
	public supplierId: string;
	public contactFname: string;
	public contactMiddle: string;
	public contactLname: string;
	public phone: string;
	public phone2: string;
	public phone3: string;
	public fax: string;
	public mobile: string;
	public email: string;
	public contractSubmissionEmail: string;
	public contractSubmissionEmail2: string;
	public commercialElectricCommissionEmail: string;
	public commercialGasCommissionEmail: string;
	public residentialCommissionEmail: string;
	public residentialContractSubmissionEmail: string;
	public shouldCommissionAutoImport: boolean;
	public commissionIsDirectSale: boolean;
	public shouldCommissionAutoErrorCheck: boolean;
	public communitySolarSettingId: number;
	public lendingModuleSettingId: number;
	public flowCheckEmail: string;
	public reinstatementEmail: string;
	public brokerId: string;
	public purchasingLayerIndicativeHedgedPricingEmail: string;

	public company: Company;
	public supplier: Supplier;
	public commissionRules: SupplierCommissionRule[];
	public communitySolarSetting: CommunitySolarSetting;
	public lendingModuleSetting: LendingModuleSetting;
	public portals: CompanySupplierPortal[];

	constructor(companySupplier?: CompanySupplier) {
		Object.assign(this, companySupplier);
	}
}

import { ServiceType } from './service-type';
import { State } from './state';
import { Utility } from './utility';

export class UtilityZipCodeMap {

	public zipCode: string;
	public stateId: string;
	public utilityId: string;
	public serviceTypeId: string;
	public zone: string;

	public utility: Utility;
	public state: State;
	public serviceType: ServiceType;

	constructor(utilityZipCodeMap: UtilityZipCodeMap) {
		Object.assign(this, utilityZipCodeMap);
	}
}

import { LeadStatusReasonType } from './lead-status-reason-type';

// trying this for now instead of building a whole logic engine
export class LeadStatusChangeRequirements {
	// (AND) (fName, lName, ((OR) mobile, email))
	// hasPrimaryLeadContactBasic
	public primaryLeadContactBasic: boolean;
	// (AND) (dbaName)
	// hasLeadBasic
	public leadBasic: boolean;
	// (AND) (address1, city, stateId, zipCode)
	// hasLeadExtended
	public leadExtended: boolean;
	// (AND) (fName, lName, mobile)
	// hasPrimaryLeadContactAllExcludeEmail
	public primaryLeadContactAllExcludeEmail: boolean;
	// (AND) (fName, lName, mobile, email)
	// hasPrimaryLeadContactAll
	public primaryLeadContactAll: boolean;
}

export class LeadStatusDetails {
	public changeRequirements: LeadStatusChangeRequirements;
}

export class LeadStatus {
	public id: number;
	public name: string;
	public leadStatusCategoryId: number;
	public cssClass: string;
	public sortOrder: number;
	public details: LeadStatusDetails;

	public leadStatusReasonTypes: LeadStatusReasonType[];

	constructor(leadStatus?: LeadStatus) {
		Object.assign(this, leadStatus);

		if (leadStatus.details && typeof leadStatus.details === 'string') {
			this.details = JSON.parse(leadStatus.details);
		}
	}

	get itemCssClass(): string {
		return this.cssClass + '-item';
	}

	get statusCssClass(): string {
		return this.cssClass + '-status';
	}
}

<pk-broker-loader [show]="loadingFaqSearch || loadingFaqCategorySearch"></pk-broker-loader>
<ng-container *ngIf="!faqArticle && (faqCategory || faqResult)">
	<h2 class="mb-4" *ngIf="faqCategory">Questions about {{ faqCategory.name }}</h2>
	<h2 class="mb-4" *ngIf="!faqCategory && faqResult?.faqSearchResults?.length">Potential answers to your question</h2>
	<p *ngFor="let faqSearch of faqResult.faqSearchResults">
		<a href="javascript:void(0)" id="faqSearchResultArticle" (click)="showFaqArticle(faqSearch)" class="semibold-text">{{ faqSearch.question }}</a
		><br />
		<span [innerHtml]="faqSearch.answer | faqShortAnswer"></span>
	</p>
</ng-container>
<ng-container *ngIf="(!usedQueryCriteria || !faqResult) && !faqArticle && !faqCategory && !loadingFaqSearch && !loadingFaqCategorySearch">
	<div class="row">
		<div class="col mb-3">
			<h4 class="no-underline gray-text">Or choose one of our categories of FAQs:</h4>
		</div>
	</div>
	<div class="row">
		<div class="col-12 col-sm-6 col-lg-12 mb-4" *ngFor="let faqCategory of faqCategories" [ngClass]="{ 'col-xl-6': faqCategory.id !== 7 }">
			<a class="icon-link" id="faqMainPageCategory" [routerLink]="['/faq', 'category', faqCategory.id]">
				<span class="icon"> <fa-icon [icon]="['fas', faqCategory.iconClassName]" [classes]="['mr-1']"></fa-icon> </span>{{ faqCategory.name }}
			</a>
		</div>
	</div>
</ng-container>

import { environment } from 'src/environments/environment';
import { FaqCategory } from './faq-category';
import { FaqKeyword } from './faq-keyword';

export class Faq {

	public id: number;
	public faqCategoryId: number;
	public question: string;
	public answer: string;
	public brokerRoute: string;
	public adminRoute: string;
	public isDefault: boolean;
	public isParentOnly: boolean;
	public isSubAgentOnly: boolean;
	public addDate: string;
	public modifiedDate: string;
	public isActive: boolean;

	public category: FaqCategory;
	public keywords: FaqKeyword[];

	constructor(faq?: Faq) {
		Object.assign(this, faq);

		this.answer = this.answer ? this.replaceAnswerLinks(this.answer) : this.answer;
	}

	public replaceAnswerLinks(answer: string): string {
		return answer
			.replace(/src=\"\/includes/g, 'src="' + environment.baseUrl + '/includes')
			.replace(/src=\"includes/g, 'src="' + environment.baseUrl + '/includes');
	}
}

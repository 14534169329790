import { State } from './state';
import { Utility } from './utility';

export class UtilityRateClass {

	public utilityRateClassId: string;
	public name: string;
	public description: string;
	public utilityId: string;
	public stateId: string;
	public addDate: string;
	public isDefault: boolean;
	public isActive: boolean;
	public isContractDefault: boolean;

	public state: State;
	public utility: Utility;

	constructor(utilityRateClass: UtilityRateClass) {
		Object.assign(this, utilityRateClass);
	}
}

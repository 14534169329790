import * as moment from 'moment-timezone';

export class ViewAgentEngagementEventAction {
	public id: number;
	public message: string;
	public details: string;
	public subTitle: string;
	public agentId: string;
	public agentEngagementEventAddDate: Date;
	public agentEngagementEventId: number;
	public agentEngagementEventCategoryName: string;
	public noteTypeId: string;
	public noteTypeCategoryId: number;
	public noteTypeName: string;
	public noteTypeDescription: string;
	public noteTypeImageIconPrefix: string;
	public noteTypeImageIcon: string;
	public addDate: Date;

	constructor(viewAgentEngagementEventAction: ViewAgentEngagementEventAction) {
		Object.assign(this, viewAgentEngagementEventAction);

		this.agentEngagementEventAddDate = this.agentEngagementEventAddDate
			? new Date(moment(this.agentEngagementEventAddDate).format('MMM DD, YYYY 00:00:00 a')) : this.agentEngagementEventAddDate;
		this.addDate = this.addDate
			? new Date(moment(this.addDate).format('MMM DD, YYYY 00:00:00 a')) : this.addDate;
	}
}

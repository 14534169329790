import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CONSTANTS } from '@pk/powerkioskutils';

import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

import { AppStateService } from '../core/app-state.service';
import { GraphqlService } from '../graphql/graphql.service';
import { AuthFieldsData } from '../graphql/models/responses';
import { GoogleTagManagerService } from '../shared/google/google-tag-manager.service';
import { AuthFields, User } from '../shared/models';

@Injectable({
	providedIn: 'root',
})
export class SecurityService {

	public isDirect: boolean;
	public authFields: AuthFields;
	public authFieldsSetEvent: Subject<AuthFields> = new Subject<AuthFields>();

	private refreshTokenPromise: Promise<AuthFields>;
	private jwtHelperService: JwtHelperService;

	constructor(private graphqlService: GraphqlService,
		private router: Router,
		private toastrService: ToastrService,
		private modalService: BsModalService,
		private appStateService: AppStateService,
		private googleTagManager: GoogleTagManagerService) {
		this.jwtHelperService = new JwtHelperService();
	}

	public async login(username: string, password: string, rememberMe = false): Promise<ApolloQueryResult<AuthFieldsData>> {
		const res = await this.graphqlService.authenticateUser(username, password);
		if (res && res.data && res.data.authenticateUser) {
			if (res.data.authenticateUser.loggedInUser.agentId) {
				this.googleTagManager.fireTagEvent({
					event: 'sign-in',
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'agent-id': res.data.authenticateUser.loggedInUser.agentId,
				});
			}
			this.setAuthFields(
				res.data.authenticateUser.token,
				res.data.authenticateUser.refreshToken,
				res.data.authenticateUser.loggedInUser,
			);
			if (rememberMe) {
				localStorage.setItem(CONSTANTS.storageKeys.rememberedUsername, username);
				localStorage.setItem(CONSTANTS.storageKeys.rememberedPassword, password);
			} else {
				localStorage.removeItem(CONSTANTS.storageKeys.rememberedUsername);
				localStorage.removeItem(CONSTANTS.storageKeys.rememberedPassword);
			}
			this.isDirect = false;
		}
		return res;
	}

	public resetAuthFields(resetRememberMe = false): void {
		localStorage.removeItem(CONSTANTS.storageKeys.authFields);
		localStorage.removeItem('isImpersonating');
		this.authFields = null;
		this.refreshTokenPromise = null;
		if (resetRememberMe) { // usually called during impersonation
			localStorage.removeItem(CONSTANTS.storageKeys.rememberedUsername);
			localStorage.removeItem(CONSTANTS.storageKeys.rememberedPassword);
		}
		this.authFieldsSetEvent.next(null);
	}

	public async logout(returnUrl?: string): Promise<boolean> {
		const activeToast = this.toastrService.info('Logging out...');
		const tokens = [];
		const storedAuthFields = localStorage.getItem(CONSTANTS.storageKeys.authFields);
		const authFields = storedAuthFields ? JSON.parse(storedAuthFields) as AuthFields : null;
		if (authFields && authFields.token) { tokens.push(authFields.token); }
		if (authFields && authFields.refreshToken) { tokens.push(authFields.refreshToken); }
		if (tokens.length) {
			try {
				await this.graphqlService.logoutUser(tokens);
			} catch (e) {
				// token problem, redirect anyway
			}
		}
		this.resetAuthFields();
		this.hideAllModals();
		await this.router.navigate(['/security'], { queryParams: { returnUrl } });
		this.toastrService.remove(activeToast.toastId);
		this.appStateService.setReady({ app: false, direct: false, isPopupWindow: false });
		return true;
	}

	private hideAllModals(): void {
		// @ts-ignore: Accessing private variable as workaround for missing feature
		this.modalService.loaders.forEach(loader => { loader.instance.hide(); });
	}

	get isImpersonating(): boolean {
		return !!localStorage.getItem('isImpersonating');
	}

	public setIsImpersonating(): void {
		localStorage.setItem('isImpersonating', '1');
	}

	public async updateAuthFieldsUser(loggedInUser: User): Promise<void> {
		const authFields = await this.getAuthFields();
		authFields.loggedInUser = loggedInUser;
		this.setAuthFields(authFields.token, authFields.refreshToken, authFields.loggedInUser);
	}

	public setAuthFields(token: string, refreshToken: string, user: User | any): void {
		const userSet = typeof user !== 'string' ? JSON.stringify(user) : user;
		this.authFields = new AuthFields(token, refreshToken, JSON.parse(userSet));
		localStorage.setItem(CONSTANTS.storageKeys.authFields, JSON.stringify(this.authFields));
		this.authFieldsSetEvent.next(this.authFields);
	}

	public getAuthFields(useExisting = false): Promise<AuthFields> {
		const storedAuthFields = localStorage.getItem(CONSTANTS.storageKeys.authFields);
		const authFields = storedAuthFields ? JSON.parse(storedAuthFields) as AuthFields : null;
		const token = authFields ? authFields.token : null;

		// useExistingToken is for when doing the actual refresh
		// and direct pages should never use token anyway
		if (!useExisting && !this.isDirect) {
			if (token && this.isExpired(token)) {
				// reuse the promise just in case other requests are
				// also trying to refresh the token
				if (this.refreshTokenPromise) {
					return this.refreshTokenPromise;
				} else {
					// setup the promise first and then return it
					// so that it can be reused above
					this.refreshTokenPromise = this.refreshToken();
					return this.refreshTokenPromise;
				}
			}
		}

		// once we have the token, we can reset the refresh
		this.refreshTokenPromise = null;

		return Promise.resolve(authFields ? authFields : new AuthFields());
	}

	private isExpired(token: string): boolean {
		if (!token) {
			return true;
		}

		const date = this.jwtHelperService.getTokenExpirationDate(token);
		if (!date) {
			return true;
		}

		return date.valueOf() < new Date().valueOf();
	}

	public async refreshToken(): Promise<AuthFields> {
		try {
			const refreshResult = await this.graphqlService.refreshToken();
			this.setAuthFields(refreshResult.data.refreshToken.token, refreshResult.data.refreshToken.refreshToken,
				refreshResult.data.refreshToken.loggedInUser);
			return this.authFields;
		} catch (e) {
			this.resetAuthFields();
			if (e.message.toLowerCase().includes('unauthorized')) {
				const username = localStorage.getItem(CONSTANTS.storageKeys.rememberedUsername);
				const password = localStorage.getItem(CONSTANTS.storageKeys.rememberedPassword);
				if (username && password) {
					await this.login(username, password, true);
					return this.authFields;
				}
				await this.logout();
			}
			return new AuthFields();
		}
	}

	public async impersonateUser(login: User): Promise<void> {
		try {
			login['impersonationLoading'] = true;
			const res = await this.graphqlService.impersonateUser(login.userId);
			if (res && res.data) {
				this.resetAuthFields(true);
				this.setAuthFields(res.data.impersonateUser.token,
					res.data.impersonateUser.refreshToken,
					res.data.impersonateUser.loggedInUser);
				this.setIsImpersonating();
				this.appStateService.onUserUpdated.next(login);
				this.router.navigate(['/'])
					.then(() => {
						window.scroll(0, 0);
					});
			} else {
				this.toastrService.warning('There was a problem impersonating the user.', 'Impersonation');
			}
		} catch (e) {
			this.toastrService.warning('There was a problem impersonating the user.', 'Impersonation');
		}
	}
}

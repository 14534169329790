export class FeatureTourLocation {

	public id: number;
	public featureTourId: number;
	public name: string;
	public route: string;

	constructor(featureTourLocation?: FeatureTourLocation) {
		Object.assign(this, featureTourLocation);
	}
}

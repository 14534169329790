export class CpanelLicensePlanCoupon {

	public name: string;
	public id: number;
	public percentage: number;
	public stripeId: string;

	constructor(cpanelLicensePlanCoupon?: CpanelLicensePlanCoupon) {
		Object.assign(this, cpanelLicensePlanCoupon);
	}
}

export class TaskType {

	public name: string;
	public isBanner: boolean;
	public ribbonClass: string;
	public ribbonText: string;
	public buttonText: string;
	public routeType: string;

	constructor(taskType?: TaskType) {
		Object.assign(this, taskType);
	}
}

export class ViewCompanyRateMatrixLoaderAudit {

	public id: number;
	public assemblyName: string;
	public description: string;
	public isActive: boolean;
	public isAPI: boolean;
	public amount: number;
	public errors: string;
	public warnings: string;
	public finishedAt: string;
	public isProcessed: boolean;

	// internal
	public color: string;
	public defaultSort: number;

	constructor(viewCompanyRateMatrixLoaderAudit?: ViewCompanyRateMatrixLoaderAudit) {
		Object.assign(this, viewCompanyRateMatrixLoaderAudit);
	}
}

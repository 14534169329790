export class CommissionPaymentApproval {

	public id: number;
	public agentId: string;
	public bankAccountId: number;
	public monthStartDate: string;
	public approvingUser1Id: string;
	public approvingUser1Name: string;
	public approvingUser2Id: string;
	public approvingUser2Name: string;

	constructor(commissionPaymentApproval?: Partial<CommissionPaymentApproval>) {
		Object.assign(this, commissionPaymentApproval);
	}
}

export class RenewalRatio {

	yearlyTermValueRatio: number;
	rolling13MonthContractsRatio: number;
	rolling13MonthTermValueRatio: number;
	contractsRatio: { month: Date; percentage: number }[];
	termValueRatio: { month: Date; percentage: number }[];

	constructor(renewalRatio: any) {
		Object.assign(this, renewalRatio);
	}
}

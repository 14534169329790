import { Supplier } from './supplier';

export class Audit {

	public id: number;
	public slug: string;
	public description: string;
	public endpoint: string;
	public schedule: string;
	public recipient: string;
	public isActive: boolean;
	public sendToSupplier: boolean;

	public suppliers: Supplier[];

	public saving = false;

	constructor(audit?: Audit) {
		Object.assign(this, audit);
	}
}

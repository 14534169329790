<ng-container [formGroup]="parentForm">
	<ng-template #tooltipTemplate>
		<span [innerHtml]="tooltipHtmlInput"></span>
	</ng-template>
	<label for="{{ key }}" [ngClass]="{ required: required }"
		>{{ name }}
		<a *ngIf="tooltipHtmlInput" href="javascript:void(0)" [tooltip]="tooltipTemplate"><fa-icon [icon]="['fas', 'question-circle']"></fa-icon></a>
	</label>
	<ng-container *ngIf="showValidationMarker">
		<fa-icon
			*ngIf="parentForm.get(key).value && !parentForm.get(key).invalid"
			[classes]="['text-success', 'ml-1']"
			[icon]="['fas', 'check']"></fa-icon>
		<fa-icon *ngIf="parentForm.get(key).value && parentForm.get(key).invalid" [classes]="['text-danger', 'ml-1']" [icon]="['fas', 'times']"></fa-icon>
	</ng-container>
	<!-- make sure to change each switch case if making modifications -->
	<!-- angular reactive forms isn't returning number values when dynamically -->
	<!-- passing the input type attribute, so it has to be manual like this -->
	<ng-container [ngSwitch]="type" *ngIf="!isTextArea">
		<ng-container *ngSwitchCase="'text'">
			<input
				type="text"
				class="form-control"
				placeholder="{{ placeholder }}"
				*ngIf="!textMask"
				[formControlName]="key"
				[attr.maxlength]="maxlength"
				(change)="onChange($event)"
				[attr.autocomplete]="autocomplete"
				[readonly]="readonly" />
			<input
				type="text"
				class="form-control"
				placeholder="{{ placeholder }}"
				*ngIf="textMask"
				[formControlName]="key"
				[attr.maxlength]="maxlength"
				(change)="onChange($event)"
				[attr.autocomplete]="autocomplete"
				[readonly]="readonly"
				[textMask]="textMask" />
		</ng-container>
		<ng-container *ngSwitchCase="'number'">
			<input
				type="number"
				class="form-control"
				placeholder="{{ placeholder }}"
				*ngIf="!textMask"
				[formControlName]="key"
				[attr.max]="max"
				(change)="onChange($event)"
				[attr.autocomplete]="autocomplete"
				[readonly]="readonly" />
			<input
				type="number"
				class="form-control"
				placeholder="{{ placeholder }}"
				*ngIf="textMask"
				[formControlName]="key"
				[attr.max]="max"
				(change)="onChange($event)"
				[attr.autocomplete]="autocomplete"
				[readonly]="readonly"
				[textMask]="textMask" />
		</ng-container>
		<ng-container *ngSwitchCase="'email'">
			<input
				type="email"
				class="form-control"
				placeholder="{{ placeholder }}"
				*ngIf="!textMask"
				[formControlName]="key"
				[attr.maxlength]="maxlength"
				(change)="onChange($event)"
				[attr.autocomplete]="autocomplete"
				[readonly]="readonly" />
			<input
				type="email"
				class="form-control"
				placeholder="{{ placeholder }}"
				*ngIf="textMask"
				[formControlName]="key"
				[attr.maxlength]="maxlength"
				(change)="onChange($event)"
				[attr.autocomplete]="autocomplete"
				[readonly]="readonly"
				[textMask]="textMask" />
		</ng-container>
	</ng-container>
	<textarea
		class="form-control"
		placeholder="{{ placeholder }}"
		*ngIf="isTextArea"
		[formControlName]="key"
		[rows]="rows"
		[attr.maxlength]="maxlength"
		(change)="onChange($event)"
		[readonly]="readonly"></textarea>
	<p class="mb-0 helper-text" [ngClass]="helperTextClass" *ngIf="helperText">
		{{ helperText }}
		<a *ngIf="helpLink && helpLinkText" class="text-uppercase bold-text" [href]="helpLink" target="_blank">{{ helpLinkText }} </a>
	</p>
	<p class="mb-0 invalid-feedback" *ngIf="isInvalid">
		<ng-container *ngIf="requiredError && !customRequiredErrorMessage">You must enter a {{ name }}.</ng-container>
		<ng-container *ngIf="requiredError && customRequiredErrorMessage">{{ customRequiredErrorMessage }}</ng-container>
		<ng-container *ngIf="maxlengthError">{{ name }} cannot be more than {{ maxlength }} characters.</ng-container>
		<ng-container *ngIf="!requiredError && !maxlengthError">Please enter a valid value.</ng-container>
	</p>
	<p class="mb-0 invalid-feedback" *ngIf="!isInvalid && isSecondaryInvalid">You must enter a valid {{ name }}.</p>
	<p class="mb-0 invalid-feedback" *ngIf="customAlwaysOnErrorMessage">
		{{ customAlwaysOnErrorMessage }}
	</p>
</ng-container>

import { Attachment } from './attachment';

export class PendingCommissionStats {
	public supplierTotalPayments: { supplierName: string; totalPayment: number }[];
	public numTotal: number;
	public numIsArchived: number;
	public numHasCriticalPriority: number;
	public numHasHighPriority: number;
	public numHasMediumPriority: number;
	public numHasLowPriority: number;
	public numHasNoIssue: number;
	public fileAddDates: string;
	public attachments: Attachment[];

	constructor(pendingCommissionStats: PendingCommissionStats) {
		Object.assign(this, pendingCommissionStats);

		this.attachments = this.attachments ? this.attachments.map(a => new Attachment(a)) : this.attachments;
	}
}

import * as moment from 'moment-timezone';

export class ContractAdvancedRfqSession {

	public endDate2: Date;

	constructor(contractAdvancedRfqSession: ContractAdvancedRfqSession) {
		Object.assign(this, contractAdvancedRfqSession);

		this.endDate2 = this.endDate2 ? new Date(moment(this.endDate2).format('MMM DD, YYYY 00:00:00 a')) : this.endDate2;
	}
}

import { DatePipe } from '@angular/common';
import { CONSTANTS } from '@pk/powerkioskutils';

import * as _ from 'lodash';
import * as moment from 'moment-timezone';

import { ServiceLocator } from '../../shared/service-locator.service';
import { Agent } from './agent';
import { Company } from './company';
import { ContactMethod } from './contact-method';
import { Dashboard } from './dashboard';
import { Role } from './role';
import { Supplier } from './supplier';
import { UserAPIKey } from './user-api-key';
import { UserEmail } from './user-email';
import { UserGridConfig } from './user-grid-config';
import { UserRfqSetting } from './user-rfq-setting';
import { UserSurveySetting } from './user-survey-setting';

export class User {

	public userId: string;
	public agentId: string;
	public username: string;
	public fname: string;
	public middle: string;
	public lname: string;
	public roleId: string;
	public lastLogin: Date;
	public phone: string;
	public phone2: string;
	public fax: string;
	public mobile: string;
	public phoneCountryId: number;
	public phone2CountryId: number;
	public faxCountryId: number;
	public mobileCountryId: number;
	public supervisorId: string;
	public supervisor2Id: string;
	public subscribeToErrorNotifications: boolean;
	public password: string;
	public confirmpassword: string;
	public customerId: string;
	public supplierId: string;
	public isActive: boolean;
	public addDate: Date;
	public stripeCustomerId: string;
	public ipAddress: string;
	public isPasswordExpired: boolean;
	public showTermsOfService: boolean;
	public fullName: string;
	public defaultUserGridConfigId: number;
	public defaultElectricityUnit: string;
	public defaultGasUnit: string;
	public isLocked: boolean;
	public showCompliance: boolean;
	public signature: string;
	public defaultRateSort: string;
	public showIdDocument: boolean;
	public defaultDashboardId: number;
	public companyId: string;
	public isSaas: boolean;
	public isAdmin: boolean;
	public isOwner: boolean;
	public isFinance: boolean;
	public isOperations: boolean;
	public idDocumentAttachmentId: string;
	public isCommissionUser: boolean;
	public isAccountManager: boolean;
	public isPrimary: boolean;
	public dob: Date;
	public sendPeakDemandNotification: boolean;

	public supervisor: User;
	public role: Role;
	public agent: Agent;
	public supplier: Supplier;
	public reportAgents: Agent[];
	public supervisors: User[];
	public rfqSettings: UserRfqSetting[];
	public apiKeys: UserAPIKey[];
	public gridConfigs: UserGridConfig[];
	public defaultUserGridConfig: UserGridConfig;
	public emails: UserEmail[];
	public contactMethods: ContactMethod[];
	public surveySettings: UserSurveySetting[];
	public dashboards: Dashboard[];
	public company: Company;

	get email(): string {
		return this.emails && this.emails.length ? this.emails[0].email : '';
	}

	constructor(user?: Subset<User>) {
		if (typeof user === 'string') {
			Object.assign(this, JSON.parse(user));
		} else {
			Object.assign(this, user);
		}

		this.role = this.role ? new Role(this.role) : this.role;
		this.agent = this.agent ? new Agent(this.agent) : this.agent;
		this.lastLogin = this.lastLogin ? new Date(moment(this.lastLogin).format('MMM DD, YYYY hh:mm:ss a')) : this.lastLogin;
		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
		this.reportAgents = this.reportAgents ? this.reportAgents.map(a => new Agent(a)) : this.reportAgents;
		this.gridConfigs = this.gridConfigs
			? _.orderBy(this.gridConfigs.map(p => new UserGridConfig(p)), u => u.sortOrder, ['asc'])
			: this.gridConfigs;
	}

	public isRole(role: string): boolean {
		return this.role && this.role.name.toLowerCase() === role.toLowerCase();
	}

	public hasAtLeastRoleLevel(level: number): boolean {
		return this.role && this.role.level > level;
	}

	public hasPermission(permission: string): boolean {
		return this.role && this.role.permissions && this.role.permissions.map(p => p.name).includes(permission);
	}

	public getContactFullName(): string {
		const contactMiddle = this.middle && this.middle.trim() ? this.middle.trim() + ' ' : '';
		return `${this.fname} ${contactMiddle}${this.lname}`;
	}

	public getFullNameLastFirst(): string {
		return `${this.lname}, ${this.fname} ${(this.middle && this.middle.trim() ? ' ' + this.middle.trim() : '')}`;
	}

	public getLabel(): string {
		return `${this.fname} ${this.lname} [${this.email}]${this.isActive ? '' : ' [Inactive]'}`;
	}

	public getEmails(): string {
		return this.emails.map(e => e.email).join('; ');
	}

	public get calendarOrganizer() {
		return `${this.labelShort}|${this.email}`;
	}

	public get labelShort() {
		return `${this.fname} ${this.lname}`;
	}

	public canImpersonate(): boolean {
		return this.isAdmin || this.isOwner;
	}

	public getDisplayLastLogin(): string {
		const datePipe = ServiceLocator.injector.get(DatePipe);
		if (this.lastLogin) {
			return datePipe.transform(this.lastLogin, 'MMM dd, yyyy hh:mm a') + ' CT';
		}

		return 'Never';
	}

	public getUserEditLink(): string[] {
		if (this.supplierId) {
			return ['/cPanel/users/edit/supplier', this.supplierId, this.userId];
		}

		if (this.agentId) {
			return ['/cPanel/users/edit/agent', this.agentId, this.userId];
		}

		const roleType = this.role.name.toLowerCase();

		return [`/cPanel/users/edit/${roleType}`, this.userId, this.userId];
	}

	public isTrupowur(): boolean {
		return this.agentId === CONSTANTS.agents.trupowur;
	}

	public getSurveySetting(surveyCategoryId: number, includeHidden = false): UserSurveySetting {
		return this.surveySettings?.find(s => s.userSurveyCategoryId === surveyCategoryId && (includeHidden || s.show));
	}

	public showPricingAnalytics(isImpersonating: boolean): boolean {
		return this.supplier &&
			(isImpersonating || this.supplier.licensePlans?.some(l => CONSTANTS.licensePlans.pricingAnalytics.includes(l.id)));
	}

	get hasMatrixPricing(): boolean {
		return !this.isSaas || this.company.licensePlans?.some(l => l.id === CONSTANTS.licensePlans.matrixPricing[0]);
	}

	get hasCustomPricing(): boolean {
		return !this.isSaas || this.company.licensePlans?.some(l => l.id === CONSTANTS.licensePlans.customPricing[0]);
	}

	get hasBufferMargin(): boolean {
		return !this.isSaas || this.company.licensePlans?.some(l => l.id === CONSTANTS.licensePlans.bufferMargin[0]);
	}

	get hasMarketAnalytics(): boolean {
		return !this.isSaas || this.company.licensePlans?.some(l => l.id === CONSTANTS.licensePlans.marketAnalytics[0]);
	}

	get hasLayeredPurchasing(): boolean {
		return !this.isSaas || this.company.licensePlans?.some(l => l.id === CONSTANTS.licensePlans.layeredPurchasing[0]);
	}

	get hasLeadLink(): boolean {
		return !this.isSaas || this.company.licensePlans?.some(l => l.id === CONSTANTS.licensePlans.leadLink[0]);
	}

	get hasAccountUsage(): boolean {
		return !this.isSaas || this.company.licensePlans?.some(l => l.id === CONSTANTS.licensePlans.accountUsage[0]);
	}

	get hasCommission(): boolean {
		return !this.isSaas || this.company.licensePlans?.some(l => l.id === CONSTANTS.licensePlans.commission[0]);
	}
}

import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

import { Utility } from '../models';

@Pipe({
	name: 'filterPricingAnalyticsUtilities',
})
export class FilterPricingAnalyticsUtilitiesPipe implements PipeTransform {
	transform(utilities: Utility[],
		allFields: { effectiveDate?: string; term?: number; usage?: number; stateId: string; utilityId: string; rateClass?: string }[],
		stateId?: string,
	): unknown {

		if (!stateId) { return utilities; }

		const uniqueUtilities = _.sortedUniq(allFields
			.filter(f => (!stateId || f.stateId === stateId))
			.map(f => f.utilityId));
		return utilities.filter(u => uniqueUtilities.includes(u.utilityId));
	}
}

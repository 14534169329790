export class DeleteRateMatrixInput {

	public supplierId: string;
	public serviceTypeId: string;
	public isResidential: boolean | null;
	public stateId: string;
	public utilityId: string;

	constructor(deleteRateMatrixInput?: DeleteRateMatrixInput) {
		Object.assign(this, deleteRateMatrixInput);
	}
}

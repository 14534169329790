export class RateFilter {

	public terms: number[];
	public supplierIds: string[];
	public effectiveDate: Date;
	public productIds: string[];
	public greenPercentages: number[];
	public loadFactorStatements: string[];
	public commissionPayPlanTypes: number[];

	// special filters for automated renewal
	public isAutomatedRenewal: boolean;
	public previousContractTerm: number;

	constructor() {
		this.terms = [];
		this.supplierIds = [];
		this.productIds = [];
		this.greenPercentages = [];
		this.loadFactorStatements = [];
		this.commissionPayPlanTypes = [];
	}
}

import { Contract } from './contract';

export class AgentEngagementEventContract {
	public agentEngagementEventId: number;
	public contractId: string;
	public termValue: number;

	public contract: Contract;

	constructor(agentEngagementEventContract: AgentEngagementEventContract) {
		Object.assign(this, agentEngagementEventContract);

		this.contract = this.contract ? new Contract(this.contract) : this.contract;
	}
}

export class ParsedBill {

	utility: string;
	accountNumber: string;
	meterNumber: string;
	amountDue: string;
	monthlyUsage: string;
	annualUsage: string;
	billingPeriod: string[];
	dueDate: string;
	issueDate: string;
	rate: string;
	rateClass: string;
	zone: string;
	serviceAddress: string;

	constructor(parsedBill?: ParsedBill) {
		Object.assign(this, parsedBill);
	}
}

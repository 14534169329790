import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
	selector: 'pk-broker-action-dropdown',
	templateUrl: './action-dropdown.component.html',
	styleUrls: ['./action-dropdown.component.scss'],
})
export class ActionDropdownComponent {
	@ContentChild('name') public nameTemplate: TemplateRef<void>;
	@ContentChild('header') public header: TemplateRef<void>;
	@ContentChild('row') public rowRef: TemplateRef<{ $implicit: string }>;
	@ContentChild('footer') public footer: TemplateRef<void>;

	@Input() label: string = null;
	@Input() insideClick = false;
	@Input() name: string;
	@Input() actions: { [text: string]: () => void };
	@Input() hideChevron = false;
	@Input() dropdownToggleClasses = '';

	onSelect(action: string) {
		this.actions[action]();
	}

	public get rows() {
		return Object.keys(this.actions).filter(key => this.actions[key] !== undefined);
	}

	public alignRight(dropdown: Element, dropdownMenu: Element) {
		const dropdownBox = dropdown.getBoundingClientRect();
		const dropdownMenuBox = dropdownMenu.getBoundingClientRect();
		return window.innerWidth - dropdownBox.left <= dropdownMenuBox.width + 1;
	}
}

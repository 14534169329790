import { PricingAnalyticsKPIHistoricalViewChartPoint } from './pricing-analytics-kpi-historical-view-chart-point';

export class PricingAnalyticsKPIHistoricalViewChartType {
	public name: string;
	public kpiHistoricalViewChart: PricingAnalyticsKPIHistoricalViewChartPoint;

	constructor(pricingAnalyticsKPIHistoricalViewChartType: PricingAnalyticsKPIHistoricalViewChartType) {
		Object.assign(this, pricingAnalyticsKPIHistoricalViewChartType);

		this.kpiHistoricalViewChart = this.kpiHistoricalViewChart
			? new PricingAnalyticsKPIHistoricalViewChartPoint(this.kpiHistoricalViewChart) : this.kpiHistoricalViewChart;
	}
}

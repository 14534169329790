

import { AgentEngagementEventCategory } from './';

export class AgentEngagementEventBufferMarginModification {
	public id: number;
	public message: string;
	public details: string;
	public subTitle: string;
	public margin: number;

	public agentEngagementEventCategory: AgentEngagementEventCategory;

	constructor(agentEngagementEventBufferMarginModification: AgentEngagementEventBufferMarginModification) {
		Object.assign(this, agentEngagementEventBufferMarginModification);
	}
}

import { ContractOptimalPartitionLocation } from './contract-optimal-partition-location';

export class ContractOptimalPartition {
	public type: string;
	public annualUsage: number;
	public loadFactor: number;
	public contractLocations: ContractOptimalPartitionLocation[];

	constructor(contractOptimalPartition: ContractOptimalPartition) {
		Object.assign(this, contractOptimalPartition);

		this.contractLocations = this.contractLocations
			? this.contractLocations.map(c => new ContractOptimalPartitionLocation(c))
			: this.contractLocations;
	}
}

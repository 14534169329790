export class OperationsTermValueBookedSummary {

	public termValueBookedYTD!: number;
	public customPricingYTD!: number;
	public matrixPricingYTD!: number;
	public totalTermValueDroppedContractsYTD!: number;

	constructor(operationsTermValueBookedSummary?: OperationsTermValueBookedSummary) {
		Object.assign(this, operationsTermValueBookedSummary);
	}
}

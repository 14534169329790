import { AgentAdvanced } from './agent-advanced';
import { AgentAdvancedStatus } from './agent-advanced-status';

export class AgentsAdvanced {

	public agents: AgentAdvanced[];
	public status: AgentAdvancedStatus;

	constructor(agentsAdvanced: AgentsAdvanced) {
		Object.assign(this, agentsAdvanced);

		this.agents = this.agents ? this.agents.map(a => new AgentAdvanced(a)) : this.agents;
		this.status = this.status ? new AgentAdvancedStatus(this.status) : this.status;
	}
}


export class ReportSupplierPaymentAudit {

	public contractId: string;
	public contractNum: string;
	public effectiveDate: Date;
	public expirationDate: Date;
	public dbaName: string;
	public supplierName: string;
	public agentName: string;
	public datePaid: Date;
	public estimatedPayment: number;
	public actualPayment: number;

	constructor(reportSupplierPaymentAudit: ReportSupplierPaymentAudit) {
		Object.assign(this, reportSupplierPaymentAudit);
	}
}

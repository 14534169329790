export class FinanceCompanySnapshot {

	public collectionsYTD!: number;
	public totalTermValueDropped!: number;
	public netRevenue!: number;

	constructor(financeCompanySnapshot?: FinanceCompanySnapshot) {
		Object.assign(this, financeCompanySnapshot);
	}
}

export class PurchasingLayerType {

	public id: number;
	public name: string;
	public description: number;
	public sortOrder: number;

	constructor(purchasingLayerType?: PurchasingLayerType) {
		Object.assign(this, purchasingLayerType);
	}
}

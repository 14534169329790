import { UtilityMarketSetting } from './';
import { Attachment } from './attachment';
import { ServiceType } from './service-type';
import { ServiceTypeUnit } from './service-type-unit';
import { UtilityBaseRate } from './utility-base-rate';
import { UtilityHub } from './utility-hub';
import { UtilityRateClass } from './utility-rate-class';
import { UtilityServiceFee } from './utility-service-fee';
import { UtilityServiceType } from './utility-service-type';
import { UtilityState } from './utility-state';
import { Zone } from './zone';

export class Utility {

	public utilityId: string;
	public abbreviation: string;
	public name: string;
	public leadTime: number;
	public sampleBillAttachmentId: string;
	public sampleBillAttachment: Attachment;
	public email: string;
	public margin: number;
	public margin2: number;
	public showMeterNum: boolean;
	public meterNumFormat: string;
	public meterNumLabel: string;
	public meterNumFormatHelp: string;
	public showReferenceNum: boolean;
	public referenceNumFormat: string;
	public referenceNumLabel: string;
	public referenceNumFormatHelp: string;
	public showNameKey: boolean;
	public nameKeyFormat: string;
	public nameKeyLabel: string;
	public nameKeyFormatHelp: string;
	public accountNumLabel: string;
	public accountNumFormat: string;
	public accountNumFormatHelp: string;
	public isActive: boolean;
	public zones: Zone[];
	public parentId: string;
	public id: string;
	public isoId: number;
	public defaultDaysUntilAuctionEnd: number;

	public serviceTypes: ServiceType[];
	public rateClasses: UtilityRateClass[];
	public utilityStates: UtilityState[];
	public utilityServiceTypes: UtilityServiceType[];
	public baseRates: UtilityBaseRate[];
	public serviceFees: UtilityServiceFee[];
	public hubs: UtilityHub[];
	public serviceTypeUnit: ServiceTypeUnit;
	public utilityMarketSettings: UtilityMarketSetting[];

	constructor(utility?: Utility) {
		Object.assign(this, utility);
	}
}

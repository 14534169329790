import { Component, Input } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { GraphqlService } from '../../graphql/graphql.service';
import { RfqSession, RfqSessionSupplier } from '../../shared/models';
import { AbstractPageForm } from '../../shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-auction-supplier-note-edit',
	templateUrl: './auction-supplier-note-edit.component.html',
	styleUrls: ['./auction-supplier-note-edit.component.scss'],
})
export class AuctionSupplierNoteEditComponent extends AbstractPageForm {

	@Input() rfqSession: RfqSession;
	@Input() supplierId: string;
	@Input() noteKey: string;
	@Input() noteKeyDisplay: string;

	public rfqSessionSupplier: RfqSessionSupplier;
	public supplierNoteForm: UntypedFormGroup;
	public startSupplierNoteValidation = false;

	constructor(
		private graphqlService: GraphqlService,
		private toastrService: ToastrService,
		private fb: FormBuilder,
	) { super(); }

	public async loadPageData(): Promise<void> {
		this.rfqSessionSupplier = this.rfqSession.suppliers.find(s => s.supplierId === this.supplierId);
	}

	public getForm() {
		return this.fb.group({
			supplierName: this.rfqSessionSupplier.supplier.name,
			[this.noteKey]: [this.rfqSessionSupplier[this.noteKey], [Validators.required, Validators.maxLength(1000)]],
		});
	}

	protected async onFormSubmitted() {
		await this.graphqlService.updateRfqSessionSupplier(this.rfqSessionSupplier.id, this.createRfqSessionSupplierDTO());
		this.toastrService.success('Supplier Note Successfully Updated');
	}

	private createRfqSessionSupplierDTO() {
		return {
			[this.noteKey]: this.form.note.value,
		};
	}
}

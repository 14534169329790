<form [formGroup]="form">
	<div class="form-row">
		<div class="col">
			<p class="black-text semibold-text font-size-085 mb-0">Customer Availability</p>
			<p class="font-size-085" *ngIf="loggedInUser && !loggedInUser.isSaas">
				To help assist with compliance and support for your customer, please share their availabiltiy to be contacted.
			</p>
		</div>
	</div>
	<div class="row" formGroupName="availability" *ngIf="!loading">
		<div class="col-12 col-lg-3 col-xl-4 col-xxxl-2 mb-3 mb-lg-0 form-element-container">
			<pk-broker-form-dropdown label="Customer's Time Zone" formControlName="timezone" [options]="timezones" valueKey="id" textKey="name">
			</pk-broker-form-dropdown>
		</div>
		<div class="col-12 col-lg-9 col-xl-8 col-xxl-7">
			<div class="form-row d-flex align-items-center">
				<div class="col-sm-3 col-md-2 d-none d-sm-inline semibold-text">Day</div>
				<div class="col-sm-3 col-md-2 d-none d-sm-inline semibold-text text-center">Available?</div>
				<div class="col-sm-3 col-md-4 d-none d-sm-inline semibold-text">From</div>
				<div class="col-sm-3 col-md-4 d-none d-sm-inline semibold-text">To</div>
			</div>
			<div class="form-row">
				<div class="col d-none d-sm-inline">
					<hr class="form-divider mt-1 mb-2" />
				</div>
			</div>
			<div formArrayName="availableTime">
				<div class="form-row d-flex align-items-center" *ngFor="let item of customerAvailableTime.controls; let i = index">
					<ng-container [formGroupName]="i">
						<div class="col-auto col-sm-3 col-md-2 form-element-container">{{ daysOfWeek[i] }}</div>
						<div class="col-6 col-sm-3 col-md-2 text-sm-center form-element-container">
							<div class="custom-control custom-switch">
								<input
									type="checkbox"
									class="custom-control-input"
									id="dayAvailable_{{ i }}"
									formControlName="available"
									(change)="onAvailableChecked(i)" />
								<label class="custom-control-label custom-switch-label custom-switch-onOff" for="dayAvailable_{{ i }}"><span></span></label>
							</div>
						</div>
						<div class="col-6 col-sm-3 col-md-4 mb-3 mb-sm-0 form-element-container">
							<label for="fromTime" class="d-block d-sm-none">From</label>
							<pk-broker-form-dropdown
								formControlName="from"
								[options]="fromTimes"
								valueKey="value"
								[nullOption]="defaultFromTime"
								[nullOptionSelectable]="false">
							</pk-broker-form-dropdown>
						</div>
						<div class="col-6 col-sm-3 col-md-4 mb-3 mb-sm-0 form-element-container">
							<label for="toTime" class="d-block d-sm-none">To</label>
							<pk-broker-form-dropdown
								formControlName="to"
								[options]="toTimes"
								valueKey="value"
								[nullOption]="defaultToTime"
								[nullOptionSelectable]="false">
							</pk-broker-form-dropdown>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</form>

import { FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

/** Products must all be unique and have at least one chosen on the form */

export const productsValidator: ValidatorFn = (control: FormGroup<any>): ValidationErrors | null => {
	let products = control.get('products') as FormArray<any>;

	if (!products) {
		products = control.get('sweetSpotProducts') as FormArray<any>;
	}


	if (products.length === 0) {
		return { productAmount: true };
	}

	let allUnique = true;
	products.controls.forEach(item => {
		const product = item.value;
		const matchedProduct = products.controls
			.filter(c => c !== item)
			.find(c => c.value && product
				&& c.value.type === product.type
				&& c.value.term === product.term
				&& c.value.greenPercentage === product.greenPercentage
				&& c.value.initialFixedUsage === product.initialFixedUsage);
		if (matchedProduct) {
			allUnique = false;
		}
	});

	return !allUnique ? { productUnique: true } : null;
};

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthResolver } from '../shared/resolvers/auth.resolver';
import { SupportCenterComponent } from './support-center/support-center.component';

const routes: Routes = [{
	path: '',
	redirectTo: 'home',
	pathMatch: 'full',
}, {
	path: 'home',
	component: SupportCenterComponent,
	resolve: { auth: AuthResolver },
	data: { allowInactive: true },
}];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class SupportCenterRoutingModule { }

<article class="row rate-bar-container" *ngIf="rateBarData && rateBarData.minBuffer && !loadingRateBarData && marketText">
	<div class="col-12 p-0">
		<div class="card">
			<h2 class="card-title" style="margin-bottom: 0">
				<a
					href="javascript:void(0)"
					class="d-block"
					(click)="isCollapsed = !isCollapsed"
					role="button"
					id="rate-bar-heading"
					[ngClass]="{ collapsed: isCollapsed }">
					<span class="rate-bar-right-side">
						<span class="details-text">Details</span><fa-icon [icon]="['fas', 'chevron-up']" class="float-right"> </fa-icon>
					</span>
					<span class="rate-bar-left-side">
						What the Market says
						<span class="sub-text" [innerHtml]="marketText"></span>
					</span>
				</a>
			</h2>
			<div id="rate-bar-collapse" [collapse]="isCollapsed" [isAnimated]="true">
				<div class="card-body">
					<div class="row" *ngIf="rateBarData && rateBarData.minBuffer">
						<div class="col-12 col-md-5 mt-4 mt-md-auto">
							<h3>Looking at Historical Data:</h3>
							<p>
								The least expensive plans for similar quotes at this time of year usually cost between
								<strong>
									{{ rateBarData.minBuffer | number: '1.2-2' }}{{ marginUnits === 'dollars' ? symbol : symbol2 }}/{{ units }} to
									{{ rateBarData.maxBuffer | number: '1.2-2' }}{{ marginUnits === 'dollars' ? symbol : symbol2 }}/{{ units }} </strong
								>.
							</p>
						</div>
						<div class="col-12 col-md-7 rate-bar">
							<div class="row lower-numbers" *ngIf="!isCollapsed">
								<div class="col-4 text-center">
									<p>{{ rateBarData.minBuffer | number: '1.2-2' }}{{ marginUnits === 'dollars' ? symbol : symbol2 }}/{{ units }}</p>
								</div>
								<div class="col-4 offset-4 text-center">
									<p>{{ rateBarData.maxBuffer | number: '1.2-2' }}{{ marginUnits === 'dollars' ? symbol : symbol2 }}/{{ units }}</p>
								</div>
							</div>
							<div class="row color-bar">
								<div class="col-2 left-part"></div>
								<div class="col-8 middle-part"></div>
								<div class="col-2 right-part"></div>
							</div>
							<div class="pointer" *ngIf="!isCollapsed" [ngStyle]="{ left: leftPosition }">
								<div class="rate-bar-tooltip text-center">
									{{ rateBarData.bestRate | number: '1.2-2' }}{{ marginUnits === 'dollars' ? symbol : symbol2 }}/{{ units }} is
									{{ rateBarData.barText }}
									<fa-icon [icon]="['fas', 'circle']" class="rate-bar-indicator" size="2x"></fa-icon>
									<fa-icon [icon]="['fas', 'circle']" size="2x"></fa-icon>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</article>

export class Country {

	public id: number;
	public name: string;
	public code: string;
	public sortOrder: number;
	public abbreviation: string;
	public symbol: string;
	public isoCode: string;
	public symbol2: string;
	public zipCodeFormat: string;
	public isActive: boolean;

	constructor(country: Country) {
		Object.assign(this, country);
	}

	public get isInternational(): boolean {
		return this.id ? this.id !== 230 : false;
	}

	public get countryLabel(): string {
		return `${this.name} (${this.code})`;
	}
}



export class PendingCommissionIssueType {

	public id: number;
	public name: string;
	public description: string;
	public className: string;
	public allowOverride: boolean;
	public sortOrder: number;

	constructor(pendingCommissionIssueType?: PendingCommissionIssueType) {
		Object.assign(this, pendingCommissionIssueType);

	}
}

import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

export const brokerReferralValidator = (formGroup: UntypedFormGroup): ValidationErrors | null => {
	const name = formGroup.get('name').value;
	const email = formGroup.get('email').value;
	const phone = formGroup.get('phone').value;

	if (!([name, email, phone].every(Boolean))) {
		return { hasDetail: true };
	}

	return null;
};

import { ReportCommissionProcessingIssuesRow } from './report-commission-processing-issue-types';

export class ReportParentCommissionProcessingIssuesRow {

	public name: string;
	public issuedPayables: ReportCommissionProcessingIssuesRow[];

	constructor(reportParentCommissionProcessingIssuesRow: ReportParentCommissionProcessingIssuesRow) {
		Object.assign(this, reportParentCommissionProcessingIssuesRow);

		// eslint-disable-next-line max-len
		this.issuedPayables = this.issuedPayables ? this.issuedPayables.map(c => new ReportCommissionProcessingIssuesRow(c)) : this.issuedPayables;
	}
}

<div
	dropdown
	#filterDropdown="bs-dropdown"
	class="dropdown compact-select"
	[class.disabled]="disabled"
	[insideClick]="insideClick"
	[isDisabled]="disabled"
	(onShown)="dropdownShown()"
	(keydown)="textTyped($event)">
	<label *ngIf="label" class="compact-select-label" [class.d-block]="headerLabelDisplayBlock" (click)="focusElement($event)">{{ label }}</label>
	<a dropdownToggle #dropdownToggle class="dropdown-toggle" [class.no-chevron]="hideChevron" href="javascript:void(0)" role="button">
		<ng-container [ngTemplateOutlet]="nameTemplate" [ngTemplateOutletContext]="{ $implicit: option }"></ng-container>
		<ng-container *ngIf="!nameTemplate && option === null">{{ nullOption }}</ng-container>
		<ng-container *ngIf="!nameTemplate && option !== null && (!rowRef || templateOnlyMenu)">{{ textMap(option) }}</ng-container>
		<ng-container
			*ngIf="!nameTemplate && option !== null && !templateOnlyMenu"
			[ngTemplateOutlet]="rowRef"
			[ngTemplateOutletContext]="{ $implicit: option }">
		</ng-container>
	</a>
	<a *ngIf="showClear" href="javascript:void(0)" class="ml-1" (click)="onClear()"
		><fa-icon [icon]="['fas', 'times-circle']" [classes]="['text-danger']"></fa-icon
	></a>
	<div *dropdownMenu #dropdownMenu class="dropdown-menu" [class.dropdown-menu-right]="dropdownAlignRight" [ngClass]="{ 'd-none': menuDisabled }">
		<div *ngIf="headerText" class="row">
			<div class="col dropdown-subhead">{{ headerText }}</div>
		</div>
		<ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
		<div #scrollingArea class="row scrolling-area" [ngClass]="{ 'medium-scrolling-area': scrollAreaHeight === 'medium' }">
			<div class="col container-column">
				<ng-container *ngIf="nullOptionSelectable && nullOption">
					<a (click)="onSelect(null, $event)" class="dropdown-item" [class.selected-item]="option === null" href="javascript:void(0)">
						{{ nullOption }}
					</a>
				</ng-container>
				<ng-container *ngIf="hasHeaders">
					<div *ngFor="let header of headers">
						<div class="row m-0">
							<div class="col dropdown-subhead mx-3">{{ header }}</div>
						</div>
						<ng-container [ngTemplateOutlet]="menuRows" [ngTemplateOutletContext]="{ $implicit: options[header] }"></ng-container>
					</div>
				</ng-container>
				<ng-container *ngIf="!hasHeaders">
					<ng-container [ngTemplateOutlet]="menuRows" [ngTemplateOutletContext]="{ $implicit: visibleOptions }"></ng-container>
				</ng-container>
			</div>
		</div>
		<ng-container [ngTemplateOutlet]="footer"></ng-container>
	</div>
</div>

<ng-template #menuRows let-options>
	<div *ngFor="let rowOption of options" class="d-flex">
		<ng-container [ngTemplateOutlet]="beforeLink" [ngTemplateOutletContext]="{ $implicit: rowOption }"></ng-container>
		<a #optionsRef (click)="onSelect(rowOption, $event)" class="dropdown-item" [class.selected-item]="rowOption === option" href="javascript:void(0)">
			<span *ngIf="!rowRef" [innerHTML]="boldMap(textMap(rowOption))"></span>
			<ng-container [ngTemplateOutlet]="rowRef" [ngTemplateOutletContext]="{ $implicit: rowOption }"></ng-container>
		</a>
	</div>
</ng-template>

import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
	selector: 'pk-broker-form-element, [pk-broker-form-element]',
	templateUrl: './form-element.component.html',
	styleUrls: ['./form-element.component.scss'],
})
export class FormElementComponent {

	@HostBinding('class.invalid') get invalid() { return this.isInvalid || this.isSecondaryInvalid; }

	// will update these soon in larger form element refactor
	@Output() changed: EventEmitter<any> = new EventEmitter();

	@Input() public parentForm: UntypedFormGroup;

	// input fields
	@Input() public name: string;
	@Input() public key: string;
	@Input() public secondaryKey: string;
	@Input() public maxlength: number;
	@Input() public type = 'text';
	@Input() public textMask: any;
	@Input() public placeholder: string;
	@Input() public tooltipHtmlInput: string;
	@Input() public readonly: boolean;
	@Input() public helperText: string;
	@Input() public helperTextClass = '';
	@Input() public helpLink: string;
	@Input() public helpLinkText: string;
	@Input() public autocomplete: string;
	@Input() public startValidation: boolean;

	// text area
	@Input() public isTextArea: boolean;
	@Input() public rows: string;

	// validation
	@Input() public customRequiredErrorMessage: string;
	@Input() public customAlwaysOnErrorMessage: string;

	private isRequired: boolean;
	@Input()
	set required(input: boolean) {
		if (input === false) {
			this.isRequired = false;
		} else {
			this.isRequired = true;
		}
	}
	get required(): boolean {
		return this.isRequired;
	}

	private isShowValidationMarket: boolean;
	@Input()
	set showValidationMarker(input: boolean) {
		if (input === false) {
			this.isShowValidationMarket = false;
		} else {
			this.isShowValidationMarket = true;
		}
	}
	get showValidationMarker(): boolean {
		return this.isShowValidationMarket;
	}

	get isInvalid(): boolean {
		return (this.parentForm.get('startValidation')?.value ?? this.startValidation) && this.parentForm.get(this.key).invalid;
	}
	get isSecondaryInvalid(): boolean {
		return this.secondaryKey
			&& (this.parentForm.get('startValidation')?.value ?? this.startValidation)
			&& this.parentForm.get(this.secondaryKey).invalid;
	}
	get requiredError(): boolean { return this.parentForm.get(this.key).errors?.required; }
	get maxlengthError(): boolean { return this.parentForm.get(this.key).errors?.maxlength; }

	public onChange(event: any): void { this.changed.emit(event); }
}

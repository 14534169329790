export class ViewPurchasingLayerAudit {
	public purchasingLayerAuditId: number;
	public contractId: string;
	public date: Date;
	public startDate: Date;
	public endDate: Date;
	public rate: number;
	public defensiveBarRate: number;
	public opportunistBarRate: number;
	public originalDefensiveSensitivityPercentage: number;
	public originalOpportunistSensitivityPercentage: number;
	public dynamicDefensiveSensitivityPercentage: number;
	public dynamicOpportunistSensitivityPercentage: number;
	public defensiveTriggerCount: number;
	public opportunistTriggerCount: number;
	public purchasingLayerPurchaseHistoryId: number;

	constructor(viewPurchasingLayerAudit?: ViewPurchasingLayerAudit) {
		Object.assign(this, viewPurchasingLayerAudit);
	}
}


export class Acknowledgement {

	public acknowledgementID: number;
	public requiresAcknowledgement: boolean;
	public acknowledgedMessage: string;
	public unacknowledgedMessage: string;
	public consumerBillOfRights: string;
	public heading: string;
	public body: string;
	public footer: string;

	constructor(acknowledgement: Acknowledgement) {
		Object.assign(this, acknowledgement);
	}
}

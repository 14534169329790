export class AgentReferralServiceProfile {
	public id: number;
	public agentId: string;
	public dbaName: string;
	public contactFname: string;
	public contactMiddle: string;
	public contactLname: string;
	public contactTitle: string;
	public mobile: string;
	public email: string;
	public summary: string;
}

import { Company, State } from './';
import { Agent } from './agent';
import { Attachment } from './attachment';
import { Utility } from './utility';

export class PopUp {

	public id: number;
	public headline: string;
	public image: Attachment;
	public imageAttachmentId: string;
	public content: string;
	public callToActionButtonText: string;
	public callToActionDestinationUrl: string;
	public priority: number;
	public isActive: boolean;
	public startDate: Date;
	public runIndefinitely: boolean;
	public endDate: Date;
	public displayFrequencyDays: number;
	public showToParentAgents: boolean;
	public showToSubAgents: boolean;
	public showToSpecificAgents: boolean;
	public specificAgents: Agent[];
	public showToAllUtilities: boolean;
	public specificUtilities: Utility[];
	public specificStates: State[];
	public specificCompanies: Company[];

	constructor(popUp?: PopUp) {
		Object.assign(this, popUp);

		this.image = this.image ? new Attachment(this.image) : this.image;
		this.specificAgents = this.specificAgents ? this.specificAgents.map(a => new Agent(a)) : this.specificAgents;
		this.specificUtilities = this.specificUtilities ? this.specificUtilities.map(u => new Utility(u)) : this.specificUtilities;
		this.specificStates = this.specificStates ? this.specificStates.map(s => new State(s)) : this.specificStates;
		this.specificCompanies = this.specificCompanies ? this.specificCompanies.map(c => new Company(c)) : this.specificCompanies;
	}
}

import { HelperService } from '../helper.service';

export class ReportCommissionProcessingIssuesRow {
	public contractNum: string;
	public contractId: string;
	public legalEntityName: string;
	public agentName: string;
	public accountNum: string;
	public commissionDate: string;
	public effectiveDate: string;
	public confirmationDate: string;
	public cycleStartDate: string;
	public cycleEndDate: string;
	public usage: number;
	public units: string;
	public margin: number;
	public payablePlan: string;
	public amount: number;
	public status: string;
	public issueType: string;
	public oldIssueType: string;
	public oldWarning: string;
	public description: string;
	public issues: string;

	public receivableUsage: number;
	public accountUsage: number;
	public confirmedAnnualUsage: number
	public ruleId: number;
	public ruleTierId: number;
	public accountUsageCheckId: number;
	public receivableUsageCheckId: number;

	public usageVerification: {
		accountUsage: {
			label: string;
			text: string;
			iconPrefix: string;
			icon: string;
			classes: string;
		};
		receivableUsage: {
			label: string;
			text: string;
			iconPrefix: string;
			icon: string;
			classes: string;
		};
	};

	constructor(reportCommissionProcessingIssuesRow: ReportCommissionProcessingIssuesRow) {
		Object.assign(this, reportCommissionProcessingIssuesRow);

		this.usageVerification = HelperService.getUsageVerification(this);
	}
}

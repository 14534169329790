import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { CONSTANTS } from '@pk/powerkioskutils';

import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { CsatNotificationModalComponent } from 'src/app/csat-survey/modals/csat-notification-modal/csat-notification-modal.component';
import { NotificationService } from 'src/app/notification/services/notification.service';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';
import { AuctionComponent } from '../../../auction/auction/auction.component';
import { GraphqlService } from '../../../graphql/graphql.service';
import { BlockType, Contract, Holiday, Product, RfqSession, ServiceTypeUnit, Supplier, UserSurvey } from '../../../shared/models';

@Component({
	selector: 'pk-broker-contract-auction',
	templateUrl: './contract-auction.component.html',
	styleUrls: ['./contract-auction.component.scss'],
})
export class ContractAuctionModalComponent extends AbstractPageForm {

	@ViewChild(AuctionComponent, { static: false }) auction: AuctionComponent;

	public warnings: string[];
	public onSuccess = new Subject<RfqSession>();
	public onExtend = new Subject<RfqSession>();
	public generatedLoa = new Subject<boolean>();
	public uploadedLoa = new Subject<boolean>();

	public contractId: string;
	public serviceTypeId: string;
	public stateId: string;
	public contract: Contract;
	public products: Product[];
	public blockTypes: BlockType[];
	public suppliers: Supplier[];
	public otherSuppliers: Supplier[];
	public holidays: Holiday[];
	public defaultProduct: Product;
	public serviceTypeUnit: ServiceTypeUnit;

	public auctionView: 'auction' | 'help' | 'requirement' = 'auction';
	public acceptedLoadFactorRecommendation = false;

	constructor(
		public activeModal: BsModalRef,
		private notificationService: NotificationService,
		private modalService: BsModalService,
		private datePipe: DatePipe,
		private graphqlService: GraphqlService,
	) { super(); }

	public async init(contractId: string, serviceTypeId: string, stateId: string): Promise<void> {
		this.contractId = contractId;
		this.serviceTypeId = serviceTypeId;
		this.stateId = stateId;

		const dateGTE = this.datePipe.transform(new Date(), 'yyyy-MM-ddT00:00:00');

		const auctionResult = await this.graphqlService.getAuctionModalData(
			this.contractId,
			this.serviceTypeId,
			this.stateId,
			dateGTE);
		this.contract = new Contract(auctionResult.data.contract);
		const serviceTypeUnitResult = await this.graphqlService.getServiceTypeUnit(
			this.serviceTypeId,
			this.stateId,
			this.contract.getUtilityId(),
		);
		this.products = auctionResult.data.products.message.map(p => new Product(p));
		this.blockTypes = auctionResult.data.blockTypes?.message.map(b => new BlockType(b)) ?? [];
		this.holidays = auctionResult.data.holidays.message.map(h => new Holiday(h));
		this.serviceTypeUnit = serviceTypeUnitResult.data.serviceTypeUnit;

		const availableSupplierResult = await this.graphqlService.getAvailableSuppliers(this.serviceTypeId, this.stateId,
			this.contract.getUtilityId(), this.contract.annualUsage, this.contract.supplierId,
			moment(this.contract.effectiveDate).format('YYYY-MM-DD'));
		this.suppliers = _.orderBy(availableSupplierResult.data.availableSuppliers.message.map(s => new Supplier(s)), s => s.name);
		const supplierIds = this.suppliers.map(s => s.supplierId);
		const otherSuppliers = availableSupplierResult.data.otherSuppliers.message
			.map(s => new Supplier(s))
			.filter(s => !supplierIds.includes(s.supplierId));
		this.otherSuppliers = _.orderBy(otherSuppliers, s => s.name);
		if (this.contract.productId) {
			this.defaultProduct = {
				id: this.contract.productId,
				term: 12,
			} as Product;
		}

		this.loading = false;
	}

	get agentIsOnHold(): boolean {
		return this.loggedInUser.agent?.isOnHold;
	}

	get showLoadFactorRecommendation(): boolean {
		return !this.loading && this.contract && this.contract.loadFactor && this.contract.loadFactor < 50 &&
			!this.acceptedLoadFactorRecommendation && this.contract.hasUsage;
	}

	public acceptLoadFactorRecommendation(): void {
		this.acceptedLoadFactorRecommendation = !this.acceptedLoadFactorRecommendation;
	}

	public async onAuctionCreateCompleted(rfqSession: RfqSession): Promise<void> {
		this.onSuccess.next(rfqSession);
		this.activeModal.hide();
		const body = `Your pricing session was successfully started. Your pricing session is set to end on `
			+ `${rfqSession.formatEndDate(false)} CT. Since pricing is only valid until close of business the `
			+ `day it's provided, suppliers typically provide pricing the day a pricing session ends to ensure `
			+ `it's valid. Check back for pricing at that time.`;

		const surveySetting = this.loggedInUser.getSurveySetting(CONSTANTS.userSurveyCategories.csatAuction);
		if (surveySetting) {
			surveySetting.show = false;
			const csatSurvey = new UserSurvey((await this.graphqlService.createUserSurvey({
				bestEmail: this.loggedInUser.emails[0]?.email,
				userSurveyCategoryId: CONSTANTS.userSurveyCategories.csatContractCreated,
				closedSurveyWithButton: false,
				closedSurveyWithoutButton: false,
				contractId: this.contractId,
			})).data.createUserSurvey);
			this.modalService.show(CsatNotificationModalComponent, {
				class: 'pk-modal modal-lg',
				backdrop: 'static',
				initialState: {
					title: 'Start Pricing Session',
					body,
					csatSurvey,
					userSurveyCategoryId: CONSTANTS.userSurveyCategories.csatAuction,
				},
			});
		} else {
			this.notificationService.info({ title: 'Start Pricing Session', body }, 'ok');
		}
	}

	public onUpdateAuctionView(auctionView: 'auction' | 'help' | 'requirement'): void {
		this.auctionView = auctionView;
	}

	public backToSessionAndIgnoreDocuments(): void {
		this.auctionView = 'auction';
		this.auction.ignoreRequiredDocuments = this.loggedInUser.isAdmin ? true : false;
	}
}

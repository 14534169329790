import * as moment from 'moment-timezone';

import { ViewLead } from './view-lead';

export class LeadDuplicate {
	public id: number;
	public leadId: number;
	public duplicateLeadId: number;
	public isDismissed: boolean;
	public addDate: Date;

	public systemLead: ViewLead;
	public duplicateLead: ViewLead;

	// internal
	public dismissing?: boolean;

	constructor(leadDuplicate?: Partial<LeadDuplicate>) {
		Object.assign(this, leadDuplicate);

		this.addDate = this.addDate
			? new Date(moment(this.addDate).format('MMM DD, YYYY 00:00:00 a')) : this.addDate;
	}
}

export class PayableIssueType {

	public id: number;
	public name: string;
	public sortOrder: number;
	public description: string;

	public payableIssueId?: number;
	get payableIssueTypeKey() {
		return this.id + (this.name ?? '');
	};

	constructor(payableIssueType?: PayableIssueType) {
		Object.assign(this, payableIssueType);
	}
}

export class CommissionUpfrontRateLevel {

	public minAmount: number;
	public maxAmount: number;
	public rate: number;

	constructor(commissionUpfrontRateLevel: CommissionUpfrontRateLevel) {
		Object.assign(this, commissionUpfrontRateLevel);
	}
}

export class ReportPageLog {

	public changeInOrder: number;
	public currentPage: string;
	public lastPage: string;
	public lastVisitDate: string;
	public visits: number;

	constructor(pageLog?: ReportPageLog) {
		Object.assign(this, pageLog);
	}
}

export class AvailableEclPackage {
	public id!: number;
	public name!: string;
	public commodity!: string;
	public units!: string;
	public stateLong!: string;
	public numLeads!: number;
	public minUsage!: number;
	public maxUsage!: number;
	public originalCost!: number;
	public actualCost!: number;
	public imgPath!: string;
	public numPackages!: number;
	public discountFactor!: number;
	public eclCouponId!: number;
	public eclCount!: number;
}

export class PricingAnalyticsUtilityPerformances {
	optimalScore: number;
	optimalScoreDiff: number;
	marketShare: number;
	marketShareDiff: number;
	lostMargin: number;
	lostMarginDiff: number;
	clusterScore: number;
	clusterScoreDiff: number;

	constructor(pricingAnalyticsUtilityPerformances: PricingAnalyticsUtilityPerformances) {
		Object.assign(this, pricingAnalyticsUtilityPerformances);
	}
}

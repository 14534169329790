export class AgentActivityStatisticsConversionGraphStat {

	public monthYearDate: string | Date;
	public joined: number;
	public converted: number;
	public conversionRate: number;

	constructor(agentActivityStatisticsConversionGraphStat?: AgentActivityStatisticsConversionGraphStat) {
		Object.assign(this, agentActivityStatisticsConversionGraphStat);
	}
}

import * as moment from 'moment-timezone';

export class LeadReminder {
	public id: number;
	public leadId: number;
	public date: Date | string;
	public message: string;
	public details: string;
	public isDismissed: boolean;
	public addDate: Date;

	// internal
	public dismissing?: boolean;

	constructor(leadReminder?: Partial<LeadReminder>) {
		Object.assign(this, leadReminder);

		this.date = this.date
			? new Date(moment(this.date).utc(false).format('MMM DD, YYYY 00:00:00 a')) : this.date;
		this.addDate = this.addDate
			? new Date(moment(this.addDate).format('MMM DD, YYYY 00:00:00 a')) : this.addDate;
	}
}

export class OwnerTopAgents {

	public agentName!: string;
	public month1TotalTermValue!: number;
	public month1ContractCount!: number;
	public month2TotalTermValue!: number;
	public month2ContractCount!: number;
	public month3TotalTermValue!: number;
	public month3ContractCount!: number;
	public totalTotalTermValue!: number;
	public totalContractCount!: number;

	constructor(ownerTopAgents?: OwnerTopAgents) {
		Object.assign(this, ownerTopAgents);
	}
}

import * as moment from 'moment-timezone';

export class ReportCustomerAnalytic {

	public contractId: string;
	public zone: string;
	public zipCode: string;
	public phone: string;
	public commodity: string;
	public state: string;
	public annualUsage: string;
	public term: string;
	public email: string;
	public effectiveDate: string | Date;
	public rateClass: string;
	public dbaName: string;
	public agentName: string;
	public supplierName: string;
	public addDate: string | Date;
	public city: string;
	public contractNum: string;
	public address1: string;
	public utility: string;
	public lastCompleteState: string;

	constructor(customerAnalytic?: ReportCustomerAnalytic) {
		Object.assign(this, customerAnalytic);

		this.effectiveDate = this.effectiveDate
			? new Date(moment(this.effectiveDate).format('MMM DD, YYYY 00:00:00 a')) : this.effectiveDate;
		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
	}
}

export class CpanelSetting {

	public name: string;
	public value: string;
	public settingId: string;
	public userId: string;

	constructor(cpanelSetting?: CpanelSetting) {
		Object.assign(this, cpanelSetting);
	}
}

export class AuctionMonitorEmailReport {

	public supplierName: string;

	public numClickedNeedsRefreshedPricingSection: number;
	public numSentNeedsRefreshedPricing: number;
	public needsRefreshedPricingClickedRatio: number;
	public showRefreshedPriceClickedNA: boolean;

	public numClickedNoBidsSection: number;
	public numSentNoBids: number;
	public noBidsClickedRatio: number;
	public showNoBidsClickedNA: boolean;

	public numClickedNotVisitedSection: number;
	public numSentNotVisited: number;
	public notVisitedClickedRatio: number;
	public showNotVisitedClickedNA: boolean;

	public numClickedNotDownloadedRFPSection: number;
	public numSentNotDownloadedRFP: number;
	public notDownloadedRFPClickedRatio: number;
	public showNotDownloadedRFPClickedNA: boolean;

	public numClickedWaitingOnLOEsSection: number;
	public numSentWaitingOnLOEs: number;
	public waitingOnLOEsClickedRatio: number;
	public showWaitingOnLOEsClickedNA: boolean;

	public numClickedWaitingOnLOAsSection: number;
	public numSentWaitingOnLOAs: number;
	public waitingOnLOAsClickedRatio: number;
	public showWaitingOnLOAsClickedNA: boolean;

	public numClickedWaitingOnSampleBillsSection: number;
	public numSentWaitingOnSampleBills: number;
	public waitingOnSampleBillsClickedRatio: number;
	public showWaitingOnSampleBillsClickedNA: boolean;

	public numSentEmails: number;
	public interactedWithEmailRatio: number;
	public showInteractedWithEmailNA: boolean;
	public numClickedLoginButton: number;
	public numSentLoginButton: number;
	public loginButtonClickedRatio: number;
	public showLoginButtonClickedNA: boolean;

	constructor(auctionMonitorEmailReport?: AuctionMonitorEmailReport) {
		Object.assign(this, auctionMonitorEmailReport);
	}
}

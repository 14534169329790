import { EmailSection } from './email-section';

export class EmailSectionGroup {

	public displayName: string;
	public sortOrder: number;

	// internal mapping
	public emailSections?: EmailSection[];

	constructor(emailSectionGroup?: EmailSectionGroup) {
		Object.assign(this, emailSectionGroup);
	}
}

import * as moment from 'moment-timezone';

export class ReportCommissionAudit {

	public cols: string[];
	public rows: ReportCommissionAuditRow[];

	constructor(reportCommissionAudit: ReportCommissionAudit) {
		Object.assign(this, reportCommissionAudit);
	}
}

export class ReportCommissionAuditRow {

	public dbaName: string;
	public actualCommissions: { [key: string]: number };
	public guaranteedCommissions: { [key: string]: number };
	public actualUsages: { [key: string]: number };
	public actualMargin: { [key: string]: number };
	public agentName: string;
	public annualUsage: number;
	public confirmationDate: string;
	public contractDate: string | Date;
	public contractId: string;
	public commissionPayPlanId: number;
	public commissionPayPlanTypeId: number;
	public supplierId: string;
	public contractNum: string;
	public accountUnderAudit: boolean;
	public effectiveDate: string;
	public estimatedCommissions: { [key: string]: number };
	public dateOffset: { [key: string]: number };
	public expirationDate: string;
	public isResidential: boolean;
	public margin: number;
	public parentAgentName: string;
	public serviceTypeName: string;
	public supplierName: string;
	public tdClasses: { [key: string]: string };
	public layeredPurchasingEstimatedMargin: { [layer: string]: { [date: string]: number } };
	public layeredPurchasingEstimatedCommissions: { [layer: string]: { [date: string]: number } };
	public term: number;
	public utilityAccountNum: string;

	// parent/brother helper property
	public agentActualCommissions: { [key: string]: number };
	public parentActualCommissions: { [key: string]: number };
	public brotherActualCommissions: { [key: string]: number };

	// helper properties
	public columns: string[];
	public isHeader: boolean;
	public caption: string;

	constructor(reportCommissionAuditRow: ReportCommissionAuditRow) {
		Object.assign(this, reportCommissionAuditRow);

		this.contractDate = this.contractDate ? new Date(moment(this.contractDate).format('MMM DD, YYYY hh:mm:ss a')) : this.contractDate;
	}
}

export class ReportCommissionAuditCriteria {

	public startDate: string;
	public endDate: string;
	public agentId: string;
	public agentLabel: string;
	public serviceTypeId: string;
	public stateId: string;
	public supplierId: string;
	public isResidential: number;
	public unpaidThreshold: number;
	public format: string;
	public contractId: string;
	public includeBufferMargin: boolean;

	constructor(reportCommissionAuditCriteria: ReportCommissionAuditCriteria) {
		Object.assign(this, reportCommissionAuditCriteria);
	}
}

export class ReportCommissionAuditSumMapping {
	[key: string]: {
		sumOfEstimatedCommissions: number;
		sumOfActualCommissions: number;
	}
}

import * as moment from 'moment-timezone';

import { LeadContact } from './lead-contact';

export class ViewLead {
	public id: number;
	public dbaName: string;
	public address1: string;
	public address2: string;
	public city: string;
	public stateId: string;
	public stateShort: string;
	public stateLong: string;
	public zipCode: string;
	public website: string;
	public linkedInUrl: string;
	public leadBusinessTypeId: number;
	public leadBusinessTypeName: string;
	public priority: number;
	public tierLevel: number;
	public leadStatusId: number;
	public leadStatusReasonTypeId: number;
	public leadStatusReasonTypeName: string;
	public leadStatusReasonNote: string;
	public leadStatusName: string;
	public cssClass: string;
	public leadStatusCategoryId: number;
	public leadStatusCategoryName: string;
	public profileDescription: string;
	public leadTypeId: number;
	public assignedToAgentId: string;
	public assignedToAgentName: string;
	public reminderCount: number;
	public reminderFutureCount: number;
	public reminderPastCount: number;
	public nextReminderDate: Date;
	public earliestReminderDate: Date;
	public followUpNoteId: string;
	public isIceBoxed: boolean;
	public agentId: string; // null for broker lead
	public agentName: string;
	public contactFname: string;
	public contactLname: string;
	public contactMobile: string;
	public contactMobileCountryId: string;
	public contactEmail: string;
	public amtIsDuplicate: number;
	public amtHasDuplicate: number;
	public addDate: Date;
	public leadCategoryId: number;
	public leadCategoryName: string;
	public leadSource: string;
	public contractEndDate: Date;
	public supplier: string;


	public leadContacts: LeadContact[];

	// internal
	public kanbanSortingIndex: number;

	constructor(viewLead?: ViewLead) {
		Object.assign(this, viewLead);

		this.nextReminderDate = this.nextReminderDate
			? new Date(moment(this.nextReminderDate).format('MMM DD, YYYY 00:00:00 a')) : this.nextReminderDate;
		this.earliestReminderDate = this.earliestReminderDate
			? new Date(moment(this.earliestReminderDate).format('MMM DD, YYYY 00:00:00 a')) : this.earliestReminderDate;
		this.addDate = this.addDate
			? new Date(moment(this.addDate).format('MMM DD, YYYY 00:00:00 a')) : this.addDate;

		this.leadContacts = this.leadContacts ? this.leadContacts.map(l => new LeadContact(l)) : this.leadContacts;
	}

	get itemCssClass(): string {
		return this.cssClass + '-item';
	}

	get statusCssClass(): string {
		return this.cssClass + '-status';
	}

	get leadReminderTooltip(): string {
		if (!this.reminderCount) { return ''; }
		const activeText = this.reminderPastCount
			? `${this.reminderPastCount} active reminder${this.reminderPastCount === 1 ? '' : 's'}` : '';
		const futureText = this.reminderFutureCount
			? `${this.reminderFutureCount} upcoming reminder${this.reminderFutureCount === 1 ? '' : 's'}` : '';

		return `You have ${[activeText, futureText].filter(Boolean).join(', and ')}`;
	}

	get hasPrimaryLeadContactBasic(): boolean {
		return [this.contactFname, this.contactLname].every(Boolean) && [this.contactMobile, this.contactEmail].some(Boolean);
	}

	get hasLeadBasic(): boolean {
		return !!this.dbaName;
	}

	get hasLeadExtended(): boolean {
		return [this.address1, this.city, this.stateId, this.zipCode].every(Boolean);
	}

	get hasPrimaryLeadContactAllExcludeEmail(): boolean {
		return [this.contactFname, this.contactLname, this.contactMobile].every(Boolean);
	}

	get hasPrimaryLeadContactAll(): boolean {
		return [this.contactFname, this.contactLname, this.contactMobile, this.contactEmail].every(Boolean);
	}
}

import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment-timezone';

import { Education, EducationType } from '../models';

@Pipe({
	name: 'filterEducation'
})
export class FilterEducationPipe implements PipeTransform {
	transform(educations: Education[], educationType: EducationType, uploadDate: { asOf: moment.Moment }): unknown {
		return educations
			? educations.filter(e =>
				(!educationType || e.educationTypeId === educationType.id) &&
				(!uploadDate.asOf || moment(e.addDate).isSameOrAfter(uploadDate.asOf)))
			: educations;
	}
}

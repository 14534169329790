import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

import { DxListComponent } from 'devextreme-angular';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

import { User } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-contract-customize-user-grid-config',
	templateUrl: './contract-customize-user-grid-config.component.html',
	styleUrls: ['./contract-customize-user-grid-config.component.scss'],
})
export class ContractCustomizeUserGridConfigModalComponent extends AbstractPageForm implements OnInit {

	@ViewChild(DxListComponent, { static: false }) columnList: DxListComponent;

	public onApplyState: Subject<any> = new Subject();

	public errorMessage = '';
	public startValidation = false;
	public applying = false;
	public applyText = 'Apply';
	public adminColumns = [];

	// inputs
	public state: any;
	public columns: any;
	public user: User;
	public isGridConfig: boolean;

	public stateColumns: any[];
	public sortedColumns: any[];
	public sortedColumnsMap: any;

	constructor(public activeModal: BsModalRef,
		private cd: ChangeDetectorRef,
		private toastrService: ToastrService) { super(); }

	ngOnInit(): void {
		this.adminColumns = [
			'underwriterName',
			'hasHappyCustomer',
			'hasLVM',
			'hasIssueFound',
			'hasCommercial',
			'hasOthers',
		];
		this.stateColumns = _.orderBy(this.state.columns, 'visibleIndex').map(c => {
			const colDef = this.columns.find(co => co.dataField === c.dataField || co.visibleIndex === c.visibleIndex);
			if (!colDef) { return undefined; }
			if (colDef.dataField === 'actionColumn' && !this.isGridConfig) { return undefined; }
			return {
				...c,
				// devextreme looks at this field to show or hide the field in the list... a pain...
				// admin can always see the column, otherwise you cannot see the column if it's an admin column
				visible: this.user.isAdmin || !this.adminColumns.includes(c.dataField),
				columnVisible: c.visible,
				caption: c.caption || colDef.caption || (colDef.dataField === 'actionColumn' ? '[Action Column]' : ''),
			};
		}).filter(Boolean);
		this.sortedColumns = _.orderBy(this.stateColumns
			.filter(c => c.sortOrder)
			.map(c => ({
				dataField: c.dataField,
				sortOrder: c.sortOrder,
				sortIndex: c.sortIndex,
				caption: c.caption,
			})), 'sortIndex');

		this.sortedColumnsMap = _.keyBy(this.sortedColumns, s => s.dataField);
	}

	public onDragStart(e: any): void {
		e.itemData = e.fromData[e.fromIndex];
	}

	public onDragEnd = (e: any): void => {
		if (e.toIndex <= 2) {
			e.cancel = true;
			this.toastrService.warning('You cannot reorder a pinned column', 'Customize Grid Config');
		}
	};

	public toggleVisible(column: any): void {
		column.columnVisible = !column.columnVisible;
	}

	public addSortingLevel(): void {
		this.sortedColumns.push({ sortOrder: 'asc', sortIndex: this.sortedColumns.length });
	}

	public chooseSortedColumn(sortedColumn: any, stateColumn: any): void {
		Object.assign(sortedColumn, stateColumn);
		this.sortedColumnsMap[sortedColumn.dataField] = sortedColumn;
	}

	public sortColumn(sortedColumn: any, sortOrder: string): void {
		sortedColumn.sortOrder = sortOrder;
		this.sortedColumnsMap[sortedColumn.dataField] = sortedColumn;
	}

	public sortedColumnsValid(): boolean {
		return this.sortedColumns.every(c => c.caption);
	}

	public removeSort(index: number): void {
		const [removedColumn] = this.sortedColumns.splice(index, 1);
		delete this.sortedColumnsMap[removedColumn.dataField];
	}

	public async apply(): Promise<void> {
		this.errorMessage = '';
		this.startValidation = true;
		const sortedColumnsValid = this.sortedColumnsValid();
		if (!this.applying && sortedColumnsValid) {
			this.applying = true;
			this.applyText = 'Applying...';
			await new Promise<void>(resolve => setTimeout(() => resolve(), 0));
			const columns = [];
			for (let i = 0; i < this.columnList.items.length; i++) {
				const column = this.columnList.items[i];
				column.visible = column.columnVisible;
				column.visibleIndex = i;
				delete column.sortIndex;
				if (this.sortedColumnsMap[column.dataField]) {
					column.sortOrder = this.sortedColumnsMap[column.dataField].sortOrder;
					column.sortIndex = this.sortedColumnsMap[column.dataField].sortIndex;
				} else {
					delete column.sortOrder;
					delete column.sortIndex;
				}
				columns.push(column);
			}

			this.state.columns = columns;

			// apply to the grid state
			this.activeModal.hide();
			this.onApplyState.next(this.state);
		} else {
			this.errorMessage = 'Please choose a column value for each sorting level to continue.';
		}
		this.applying = false;
		this.applyText = 'Apply';
	}
}

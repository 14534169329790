<pk-broker-modal>
	<ng-template #headerTemplate>
		<h5 class="modal-title">Payment Method</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<div class="container-fluid">
			<p>
				<strong>Congratulations on completing your first Confirmed contract! How would you like to be paid?</strong>
			</p>
			<a [routerLink]="['/security', 'edit']" fragment="manageBankAccounts" (click)="close()">
				<fa-icon [icon]="['fas', 'plus']"></fa-icon> Add Bank Account Information
			</a>
		</div>
	</ng-template>
</pk-broker-modal>

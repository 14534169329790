
export class ContractAdvancedCustomer {

	public id: string;
	public dbaName: string;
	public contractName: string;

	constructor(contractAdvancedCustomer: ContractAdvancedCustomer) {
		Object.assign(this, contractAdvancedCustomer);
	}
}

import { ServiceType } from './service-type';
import { Supplier } from './supplier';

export class SupplierServiceType {

	public supplierId: string;
	public serviceTypeId: string;

	public supplier: Supplier;
	public serviceType: ServiceType;

	constructor(supplierServiceType: SupplierServiceType) {
		Object.assign(this, supplierServiceType);
	}
}

export class Job {
	public id: number;
	public name: string;
	public description: string;
	public url: string;
	public schedule: string;
	public isActive: boolean;
	public addDate: Date;

	constructor(job?: Job) {
		Object.assign(this, job);
	}
}

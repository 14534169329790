import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { alert } from 'devextreme/ui/dialog';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

import { NotificationService } from 'src/app/notification/services/notification.service';
import { GraphqlService } from '../../graphql/graphql.service';
import { HelperService } from '../../shared/helper.service';
import { Holiday, RfqSession, RfqSessionSupplier, Supplier } from '../../shared/models';
import { AbstractPageForm } from '../../shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-auction-extend',
	templateUrl: './auction-extend.component.html',
	styleUrls: ['./auction-extend.component.scss'],
})
export class AuctionExtendComponent extends AbstractPageForm {

	@Input() rfqSession: RfqSession;
	@Input() suppliers: Supplier[];
	@Input() otherSuppliers: Supplier[];
	@Input() holidays: Holiday[];

	@Output() auctionExtendCompleted: EventEmitter<RfqSession> = new EventEmitter();

	public minDate: Date;
	public endTimes: moment.Moment[];
	public maxAuctionDate: moment.Moment;
	public emergencyRequestEmail: string;
	public defaultDaysUntilAuctionEnd: number;
	private minAuctionEndDate: moment.Moment;

	constructor(
		private datePipe: DatePipe,
		private graphqlService: GraphqlService,
		private notificationService: NotificationService,
		private helperService: HelperService,
		private fb: FormBuilder,
	) {
		super();
		this.submitText = 'Extend Session';
		this.submitAudit = 'auction-extend:extendRfqSession';
	}

	get agentIsOnHold(): boolean {
		return this.loggedInUser.agent?.isOnHold;
	}

	public endTimeTextMap = (option: moment.Moment) => `${option.format('hh:mm A')} CT`;

	public async loadPageData(): Promise<void> {
		const pageResult = await this.graphqlService.getCompanyEmailData(this.rfqSession.contract.companyId);

		this.emergencyRequestEmail = pageResult.data.company.emailAddresses
			.find(e => e.emailAddressTypeId === this.CONSTANTS.emailAddressTypes.emergencyRequestEmail).email;
		this.maxAuctionDate = moment().add(30, 'days');
	}

	public getForm() {
		this.endTimes = this.helperService.generateTimesListFromMoment(moment([1990, 1, 1, 10, 0, 0]), moment([1990, 1, 1, 17, 0, 0]), 30);
		this.defaultDaysUntilAuctionEnd = _.max(this.rfqSession.contract.locations.map(l => l.utility.defaultDaysUntilAuctionEnd));

		const today = new Date();
		let initialEndDate = new Date(this.rfqSession.endDate);

		this.minAuctionEndDate = moment(initialEndDate);

		if (initialEndDate.valueOf() < today.valueOf()) {
			if (moment(initialEndDate).isAfter(moment().subtract(2, 'month'))) {
				this.defaultDaysUntilAuctionEnd = 1;
			}

			this.minAuctionEndDate = this.getDefaultEndDateFromStart(moment(today), this.defaultDaysUntilAuctionEnd);
		}

		initialEndDate = this.minAuctionEndDate.toDate();
		this.minDate = initialEndDate;

		if (this.loggedInUser.isAdmin) {
			this.minDate = today;
		}

		const optedOutSuppliers = this.rfqSession.suppliers.filter(s => s.isOptOut);
		const defaultSuppliers = this.suppliers.map(s => s.supplierId);
		const availableSuppliers = this.rfqSession.suppliers.filter(s => defaultSuppliers.includes(s.supplierId) && !s.isOptOut);
		const notInvitedSuppliers = this.suppliers.filter(s => !this.rfqSession.suppliers.some(rs => rs.supplierId === s.supplierId));
		const restOfSuppliers = _.chain([...this.otherSuppliers, ...notInvitedSuppliers])
			.map(s => ({
				id: this.rfqSession.suppliers.find(rs => rs.supplierId === s.supplierId)?.id,
				supplierId: s.supplierId,
				supplier: s,
			}) as RfqSessionSupplier)
			.uniqBy(s => s.supplierId)
			.filter(s => !optedOutSuppliers.find(os => os.supplierId === s.supplierId))
			.concat(this.rfqSession.suppliers.filter(s => s.isOptOut))
			.value();
		const initialEndTime = this.endTimes.find(e =>
			e.hour() === (this.rfqSession.endTime as Date).getHours() &&
			e.minute() === (this.rfqSession.endTime as Date).getMinutes());

		return this.fb.group({
			endTime: [initialEndTime, [Validators.required]],
			endDate: [this.minAuctionEndDate, [Validators.required]],
			suppliers: this.fb.array([
				..._.orderBy(availableSuppliers, s => s.supplier.name)
					.map(s => this.fb.group({
						id: s.id,
						name: s.supplier.name,
						supplierId: s.supplierId,
						isOptOut: false,
						outsideThreshold: false,
						notInvited: false,
						originalSelected: true,
						supplierSelected: true,
					})),
				..._.orderBy(restOfSuppliers, s => s.supplier.name)
					.map(s => this.fb.group({
						id: s.id,
						name: s.supplier.name,
						supplierId: s.supplierId,
						isOptOut: s.isOptOut,
						outsideThreshold: this.otherSuppliers.some(rs => rs.supplierId === s.supplierId),
						notInvited: notInvitedSuppliers.some(rs => rs.supplierId === s.supplierId),
						originalSelected: this.rfqSession.suppliers.some(rs => rs.supplierId === s.supplierId),
						supplierSelected: false,
					})),
			], [(control: FormArray<any>) => control.value.every((s: any) => !s.supplierSelected) ? { required: true } : null]),
			selectAll: !restOfSuppliers.length,
		}, { validators: [this.endDateTimeValidator] });
	}

	public async onFormLoaded(): Promise<void> {
		this.form.selectAll.valueChanges.subscribe(selected => {
			this.form.suppliers.forEach(s => {
				s.supplierSelected.setValue(selected, { emitEvent: false });
				s.supplierSelected.updateValueAndValidity();
			});
		});

		this.form.suppliers.control.valueChanges.subscribe(suppliers => {
			if (suppliers.every(s => s.supplierSelected)) {
				this.form.selectAll.setValue(true, { emitEvent: false });
			} else {
				this.form.selectAll.setValue(false, { emitEvent: false });
			}

			this.form.selectAll.updateValueAndValidity({ emitEvent: false });
		});
	}

	private endDateTimeValidator: ValidatorFn = (control: this['form']['control']): ValidationErrors | null => {
		const endTime = moment(control.get('endTime').value);
		const endDate = moment(control.get('endDate').value).startOf('day');
		endDate.add(endTime.hours(), 'hours');
		endDate.add(endTime.minutes(), 'minutes');
		const now = moment();

		if (endDate.isSameOrBefore(now, 'minutes')) {
			return { endDateInThePast: true };
		}

		if ([0, 6].includes(endDate.day())) {
			return { endDateOnWeekend: true };
		}

		if (this.holidays.find(h => moment(h.date).isSame(endDate, 'day'))) {
			return { endDateOnHoliday: true };
		}
		if (endDate.isAfter(this.maxAuctionDate, 'day')) {
			return { endDateAfterMaxDate: true };
		}

		return null;
	};

	public disableAuctionDates = (dates: any): boolean => {
		const currDateInDates = dates.date as Date;
		const dayOfWeek = currDateInDates.getDay();

		return [0, 6].includes(dayOfWeek)
			|| currDateInDates.getTime() > this.maxAuctionDate.toDate().getTime()
			|| !!this.holidays.find(h => h.date.valueOf() === currDateInDates.valueOf());
	};

	protected async onFormSubmitted() {
		const result = await this.notificationService
			.confirm({
				title: 'Extend Session',
				body: 'You are about to extend the pricing session for the selected suppliers. Do you want to continue?',
			});
		if (result) {
			// Delete Suppliers
			const toDeleteSuppliers = _.chain(this.form.suppliers)
				.filter(s => s.supplierSelected.value === false && !!s.id.value)
				.map(s => s.id.value)
				.uniq()
				.value();
			for (const rfqSessionSupplierId of toDeleteSuppliers) {
				await this.graphqlService.deleteRfqSessionSupplier(rfqSessionSupplierId);
			}

			// Extend Auction
			const extendResult = await this.graphqlService.extendRfqSession(this.rfqSession.id, this.rfqSessionDTO());

			// Create Suppliers
			const toAddSuppliers = _.chain(this.form.suppliers)
				.filter(s => s.supplierSelected.value === true && s.originalSelected.value === false)
				.map(s => ({ supplierId: s.supplierId.value, rfqSessionId: this.rfqSession.id, pendingAddition: true }))
				.uniqBy(r => r.supplierId)
				.value();
			for (const rfqSessionSupplier of toAddSuppliers) {
				await this.graphqlService.createRfqSessionSupplier(rfqSessionSupplier);
			}

			this.auctionExtendCompleted.emit(new RfqSession(extendResult.data.extendRfqSession));
		}
	}

	private rfqSessionDTO() {
		return {
			endDate: moment(this.form.endDate.value).format('YYYY-MM-DDT00:00:00'),
			endTime: this.form.endTime.value.format('YYYY-MM-DDTHH:mm:00'),
			startDate: this.datePipe.transform(this.rfqSession.startDate, 'yyyy-MM-ddT00:00:00.000'),
		};
	}

	public async getExtraUserAuditMessage() {
		const endTime = moment(this.form.endTime.value);
		const endDate = moment(this.form.endDate.value).startOf('day');
		endDate.add(endTime.hours(), 'hours');
		endDate.add(endTime.minutes(), 'minutes');
		const now = moment();
		let message = '';

		if (endDate.isSameOrBefore(now, 'minutes')) {
			message += 'Your pricing session end date must be in the future. ';
		}
		if ([0, 6].includes(endDate.day())) {
			message += 'Your pricing session end date must be a weekday. ';
		}
		if (this.holidays.find(h => moment(h.date).isSame(endDate, 'day'))) {
			message += 'Your pricing session cannot end on a holiday. ';
		}
		if (endDate.isAfter(this.maxAuctionDate, 'day')) {
			message += 'Please select an end date for your pricing session within 30 days from today.';
		}

		if (message) {
			alert(message, 'Extend');
			return message;
		}

		return '';
	}

	private getDefaultEndDateFromStart(start: moment.Moment, defaultDays: number): moment.Moment {
		let daysLeft = defaultDays;
		const daysAhead = start.clone();
		while (daysLeft) {
			if (![0, 6].includes(daysAhead.day())) {
				daysLeft--;
			}
			daysAhead.add(1, 'day');
		}
		this.moveDayOffOfWeekendAndHoliday(daysAhead);
		return daysAhead;
	}

	private moveDayOffOfWeekendAndHoliday(date: moment.Moment): void {
		while ([0, 6].includes(date.day())) {
			date.add(1, 'day');
		}
		while (this.holidays.find(h => moment(h.date).isSame(date, 'day'))) {
			date.add(1, 'day');
		}
	}
}

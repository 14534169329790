import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

import { Market } from '../models';

@Pipe({
	name: 'marketOverviewServiceTypeFilter'
})
export class MarketOverviewServiceTypeFilterPipe implements PipeTransform {

	transform(markets: Market[], stateId: string, utilityId: string): { name: string; serviceTypeId: string }[] {
		return _.chain(markets)
			.filter(m => (!stateId || m.state.stateId === stateId) && (!utilityId || m.utility.utilityId === utilityId))
			.map(m => m.serviceType)
			.uniqBy(m => m.serviceTypeId)
			.sortBy(m => m.name)
			.value();
	}

}

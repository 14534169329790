import { ServiceType } from './service-type';
import { State } from './state';
import { Utility } from './utility';

export class SessionFilter {
	public serviceType: ServiceType;
	public utility: Utility;
	public state: State;
	public hasLOE: { name: string; value: boolean };
}

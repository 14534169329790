<form class="newAttachmentForm" [formGroup]="formGroup" *ngIf="!loading">
	<h3 id="newAttachmentHeader">Upload Contract</h3>
	<div class="form-element-container">
		<label class="title" for="file">File </label>
		<input type="file" name="file" class="required form-control" (change)="onContractFile($event)" required />
	</div>
	<div class="form-element-container">
		<label class="title required" for="description">Description </label>
		<textarea class="required form-control" name="description" id="description" formControlName="description" required></textarea>
	</div>
	<div class="alert alert-warning" *ngIf="err.file">Contract File is required and MUST be no more than <strong>20 MB</strong>.</div>
	<div class="alert alert-warning" *ngIf="err.description">Description is required.</div>
	<div class="footer text-right">
		<button type="button" class="btn btn-primary" (click)="submit()" [disabled]="submitDisabled">{{ submitText }}</button>
	</div>
</form>

import { Utility } from './utility';

export class UtilityWebPortal {

	public id: number;
	public utilityId: string;
	public stateId: string;
	public serviceTypeId: string;
	public isResidential: boolean;
	public isCommercial: boolean;
	public isActive: boolean;
	public username: string;
	public password: string;

	public utility: Utility;

	constructor(utilityWebPortal?: UtilityWebPortal) {
		Object.assign(this, utilityWebPortal);
	}
}

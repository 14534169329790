<pk-broker-modal>
	<ng-template #headerTemplate>
		<h5 class="modal-title" id="modal-auction-label" *ngIf="!loading">{{ contract?.getModalTitle() }}</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<div class="modal-top-actions-bar mb-3" *ngIf="!showLoadFactorRecommendation || acceptedLoadFactorRecommendation">
			<button
				*ngIf="!loading"
				href="javascript:void(0)"
				class="btn btn-secondary py-1 px-4 float-left"
				(click)="auctionView = 'help'"
				id="customQuoteHelpBtn">
				<fa-icon [icon]="['fas', 'question']"></fa-icon> Help
			</button>
			<span class="modal-top-actions">
				<button
					type="button"
					class="btn btn-link px-4"
					[disabled]="!!auction && auction.submitDisabled"
					(click)="activeModal.hide()"
					id="customQuoteCancelBtn">
					Close Window
				</button>
				<button *ngIf="!loading && auctionView !== 'auction'" type="button" class="btn btn-primary ml-2 px-4" (click)="auctionView = 'auction'">
					Go Back to Auction
				</button>
				<button
					*ngIf="!loading && !!auction && !auction.loading && auctionView !== 'requirement' && !agentIsOnHold"
					type="button"
					class="btn btn-primary ml-2 px-4"
					id="btnButton"
					(click)="auction.submit()"
					[disabled]="auction.submitDisabled"
					id="customQuoteCreateBtn">
					{{ auction.submitText }}
				</button>
			</span>
		</div>
		<pk-broker-loader [show]="loading"></pk-broker-loader>
		<div [ngClass]="{ 'd-none': auctionView === 'requirement' }">
			<pk-broker-page-warnings [warnings]="warnings"></pk-broker-page-warnings>
			<pk-broker-notification
				[contract]="contract"
				[hide]="!showLoadFactorRecommendation"
				[isSmall]="true"
				[isLoadFactor]="true"
				[hideCloseButton]="true">
			</pk-broker-notification>
			<p class="text-center" *ngIf="showLoadFactorRecommendation && !acceptedLoadFactorRecommendation">
				<button type="button" class="btn btn-primary px-4" (click)="acceptLoadFactorRecommendation()">OK</button>
			</p>
			<pk-broker-auction
				*ngIf="!loading"
				[ngClass]="{ 'd-none': !(auctionView === 'auction' && (!showLoadFactorRecommendation || acceptedLoadFactorRecommendation)) }"
				[contract]="contract"
				[products]="products"
				[blockTypes]="blockTypes"
				[suppliers]="suppliers"
				[otherSuppliers]="otherSuppliers"
				[holidays]="holidays"
				[defaultProduct]="defaultProduct"
				[serviceTypeUnit]="serviceTypeUnit"
				(auctionCreateCompleted)="onAuctionCreateCompleted($event)"
				(updateAuctionView)="onUpdateAuctionView($event)">
			</pk-broker-auction>
			<pk-broker-auction-help *ngIf="!loading && auctionView === 'help'"></pk-broker-auction-help>
		</div>
		<pk-broker-auction-requirement *ngIf="!loading && auctionView === 'requirement'" [contract]="contract"> </pk-broker-auction-requirement>
		<button
			*ngIf="!loading && !!auction && !auction.loading && auctionView === 'requirement' && loggedInUser.isAdmin"
			type="button"
			class="btn btn-primary px-4"
			(click)="backToSessionAndIgnoreDocuments()"
			[disabled]="auction.submitDisabled">
			Ignore Required Documents
		</button>
	</ng-template>
</pk-broker-modal>

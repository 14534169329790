import * as moment from 'moment-timezone';

import { Attachment } from '../../shared/models';

export class CommentView {

	public id: number;
	public body: string;
	public user: string;
	public userId: string;
	public date: Date;
	public dateStr: string;
	public currUserId: string;
	public isInternal: boolean;
	public isLatest: boolean;
	public attachments: Attachment[];
	public isEditable: boolean;
	public userIsAdmin?: boolean = false;

	constructor(commentView: CommentView) {
		Object.assign(this, commentView);

		this.date = this.date ? new Date(moment(this.date).format('MMM DD, YYYY hh:mm:ss a')) : this.date;
		this.dateStr = moment(this.date).format('MMM DD, YYYY hh:mm:ss a');
		this.attachments = this.attachments ? this.attachments.map(a => new Attachment(a)) : this.attachments;
	}
}

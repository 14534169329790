import * as moment from 'moment-timezone';

import { AgentEngagementEventCategoryNoteType } from './agent-engagement-event-category-suggested-note-type';
import { AgentEngagementEventContract } from './agent-engagement-event-contract';

export class ViewAgentEngagementEvent {
	public id: number;
	public agentId: string;
	public contractId: string;
	public contractNum: string;
	public ticketId: string;
	public agentEngagementEventCategoryId: number;
	public agentEngagementEventStatusId: number;
	public agentEngagementEventCategoryName: string;
	public addDate: Date;
	public agentName: string;
	public agentAddDate: Date;
	public parentName: string;
	public parentContactName: string;
	public parentPhone: string;
	public parentEmail: string;
	public eventDetail: string;
	public followUpDate: Date;
	public isActivated: boolean;
	public agentTotalTermValue: number;
	public lastConfirmationDate: Date;
	public lastDroppedDate: Date;
	public contractStatus: number;
	public isResidential: boolean;
	public numContracts: number;
	public contractTotalTermValue: number;
	public hasAction: boolean;

	public noteTypes: AgentEngagementEventCategoryNoteType[];
	public agentEngagementEventContracts: AgentEngagementEventContract[];

	// internal
	public eventDetailsJson: any;
	public settingToDone: boolean;
	public settingToActive: boolean;
	public churnStatusSort: number;

	constructor(viewAgentEngagementEvent: ViewAgentEngagementEvent) {
		Object.assign(this, viewAgentEngagementEvent);
		this.followUpDate = this.followUpDate
			? new Date(moment(this.followUpDate).format('MMM DD, YYYY 00:00:00 a')) : this.followUpDate;
		this.addDate = this.addDate
			? new Date(moment(this.addDate).format('MMM DD, YYYY 00:00:00 a')) : this.addDate;
		this.lastConfirmationDate = this.lastConfirmationDate
			? new Date(moment(this.lastConfirmationDate).format('MMM DD, YYYY 00:00:00 a')) : this.lastConfirmationDate;
		this.lastDroppedDate = this.lastDroppedDate
			? new Date(moment(this.lastDroppedDate).format('MMM DD, YYYY 00:00:00 a')) : this.lastDroppedDate;
		this.agentAddDate = this.agentAddDate
			? new Date(moment(this.agentAddDate).format('MMM DD, YYYY 00:00:00 a')) : this.agentAddDate;
		const emails = this.parentEmail?.split(';') || [];
		this.parentEmail = emails?.length ? emails[0] : '';
		this.agentEngagementEventContracts = this.agentEngagementEventContracts
			? this.agentEngagementEventContracts.map(c => new AgentEngagementEventContract(c))
			: this.agentEngagementEventContracts;

		this.eventDetailsJson = this.eventDetail ? JSON.parse(this.eventDetail) : null;

		// flip the churn prediction because the orderBy in setTopEvent() cannot have a
		// dynamic ordering direction to separate between the isDormant and isActivated scenarios
		if (this.eventDetailsJson?.hasOwnProperty('churnPrediction')) {
			this.eventDetailsJson.churnPrediction = 100 - this.eventDetailsJson.churnPrediction;
		}

		this.churnStatusSort = this.getChurnSort();

		if (this.numContracts === 1) {
			this.isResidential = Boolean(this.isResidential);
			this.contractStatus = Number(this.contractStatus);
		}

		if (this.eventDetailsJson?.hasOwnProperty('suggestedMargin')) {
			for (const noteType of this.suggestedNoteTypes) {
				noteType.noteType.details = noteType.noteType.details?.replace('{SuggestedMargin}', this.eventDetailsJson.suggestedMargin);
			}
		}
	}

	get suggestedNoteTypes(): AgentEngagementEventCategoryNoteType[] {
		return this.noteTypes?.filter(nt => nt.isSuggested) || [];
	}

	get agentAddDateYears(): number {
		return moment().diff(moment(this.agentAddDate), 'years');
	}

	get agentAddDateMonths(): number {
		return moment().diff(moment(this.agentAddDate), 'months') % 12;
	}

	private getChurnSort(): number {
		if (this.isActivated) {
			return 1;
		} else {
			if (this.eventDetailsJson?.isDormant) {
				return 3;
			}

			return 2;
		}
	}
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'newLinesToBreaks',
})
export class NewLinesToBreaksPipe implements PipeTransform {
	transform(value: string): string {
		return value?.replace(/(?:\r\n|\r|\n)/g, '<br>') || '';
	}
}

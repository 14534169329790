import { Contract } from './contract';

export class Payment {

	public id: string;
	public amount: number;
	public cardName: string;
	public cardType: string;
	public cardNumber: string;
	public authCode: string;
	public contractId: string;
	public expires: string;
	public zipCode: string;

	// eslint-disable-next-line @typescript-eslint/naming-convention
	public expires_year: number;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	public expires_month: number;

	public contract: Contract;

	constructor(payment: any) {
		Object.assign(this, payment);
	}
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'pk-broker-pager',
	templateUrl: './pager.component.html',
	styleUrls: ['./pager.component.scss'],
})
export class PagerComponent {

	@Output() pageChanged: EventEmitter<number> = new EventEmitter();
	/** The number of pages visible on the left and right of the current page */
	@Input() visibleWidth = 2;
	@Input() currentPage: number;
	@Input() pageCount: number;

	public changePage(page: number): void {
		this.pageChanged.emit(page);
	}

	public get middlePages() {
		return [...Array(this.pageCount).keys()].filter(i => this.isMiddlePage(i));
	}

	public isMiddlePage(index: number) {
		if (this.pageCount < 3) {
			return false;
		}

		if (index === 0 || index === this.pageCount - 1) {
			return false;
		}

		if (Math.abs(index - this.currentPage) <= this.visibleWidth) {
			return true;
		}

		if (this.currentPage <= this.visibleWidth && index <= 2 * this.visibleWidth) {
			return true;
		}

		if (this.currentPage >= (this.pageCount - 1) - (this.visibleWidth) && index >= (this.pageCount - 1) - (2 * this.visibleWidth)) {
			return true;
		}

		if (index === 1 && this.isMiddlePage(2) || index === this.pageCount - 2 && this.isMiddlePage(this.pageCount - 3)) {
			return true;
		}

		return false;
	}

}

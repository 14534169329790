

import * as _ from 'lodash';

import { UnpaidPayable } from './unpaid-payable';

export class UnpaidPayableDashboard {

	public result: UnpaidPayable[];
	public filterOptions: {
		agents: { agentId: string; agentName: string }[];
		contracts: { contractId: string; contractNum: string }[];
		utilityAccountNums: { utilityAccountNum: string }[];
	};
	public stats: {
		totalAmount: number;
	};

	constructor(unpaidPayableDashboard: Partial<UnpaidPayableDashboard>) {
		Object.assign(this, unpaidPayableDashboard);

		this.result = this.result?.length
			? unpaidPayableDashboard.result.map(r => new UnpaidPayable(r))
			: [];
		this.filterOptions.agents = this.filterOptions?.agents?.length ? this.filterOptions.agents : [];
		this.filterOptions.contracts = this.filterOptions?.contracts?.length ? this.filterOptions.contracts : [];
		this.filterOptions.utilityAccountNums = this.filterOptions?.utilityAccountNums?.length ? this.filterOptions.utilityAccountNums : [];
		this.stats.totalAmount = _.isNil(this.stats?.totalAmount) ? 0 : this.stats.totalAmount;
	}

}

export class ContractCriteria {
	public companyId?: string;
	public agentId?: string;
	public customView1?: boolean;
	public status?: string | number;
	public displayStatus?: string;
	public isResidential?: boolean;
	public isOnHold?: boolean;
	public isAwarded?: boolean;
	public hasExternalId?: boolean;
	public hasGUId?: boolean;
	public includeParentBrother?: boolean;
	public underwriterId?: string;
	public supplierId?: string;
	public isRenewal?: boolean;
	public effectiveDate?: string;
	public auctionStartDate?: string;
	public auctionEndDate?: string;
	public confirmationDate?: string;
	public contractDate?: string;
	public modifiedDate?: string;
	public dropDate?: string;
	public expirationDate?: string;
	public latestDropDate?: string;
	public serviceTypeId?: string;
	public term?: string;
	public stateId?: string;
	public depositRequiredAmount?: number;
	public esignIsComplete?: boolean;
	public submissionStatus?: number;

	public isUpcomingRenewal?: boolean;
	public upcomingRenewalMonth?: string;
	public upcomingRenewalMonths?: string;
}

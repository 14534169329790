import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { AttachmentService } from 'src/app/attachment/attachment.service';
import { HelperService } from 'src/app/shared/helper.service';
import { RfqSession } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';
import { isFileSize } from 'src/app/shared/validators/file-size.validator';

@Component({
	selector: 'pk-broker-auction-supplier-create-bids-attachment',
	templateUrl: './auction-supplier-create-bids-attachment.component.html',
	styleUrls: ['./auction-supplier-create-bids-attachment.component.scss'],
})
export class AuctionSupplierCreateBidsAttachmentComponent extends AbstractPageForm {

	@Input() rfqSession: RfqSession;
	@Input() supplierId: string;

	constructor(
		private toastrService: ToastrService,
		private attachmentService: AttachmentService,
		private helperService: HelperService,
		private fb: FormBuilder,
	) {
		super();
		this.submitAudit = 'auction-supplier-create-bids:uploadContract';
	}

	public async loadPageData(): Promise<void> { }

	public getForm() {
		return this.fb.group({
			file: [null as File, [Validators.required, isFileSize(20)]],
			description: ['', [Validators.required]],
		});
	}

	public onContractFile(event: EventTarget): void {
		const files = this.helperService.getFilesFromEventTarget(event);
		this.form.file.setValue(files[0]);
	}

	protected async onFormSubmitted() {
		const formData = new FormData();
		formData.append('files', this.form.file.value, this.form.file.value.name);
		formData.append('contractId', this.rfqSession.contract.contractId);
		formData.append('supplierId', this.supplierId);
		formData.append('attachmentTypeId', this.CONSTANTS.attachmentTypes.contract);
		formData.append('description', this.form.description.value);
		const fileUploadUrl = `${this.environment.baseAPIUrl}/contracts/${this.rfqSession.contract.contractId}/attachments`;
		const result = await this.attachmentService.uploadCustom(fileUploadUrl, formData);
		if (!result || result.error) {
			throw new Error('There was a problem submitting the contract.');
		}
		this.toastrService.success('Contract Submitted Successfully.', 'Pricing Session');
		this.startValidation = false;
	}
}

export class ViewAgentReferralService {
	public id: number;
	public serviceId: number;
	public serviceName: string;
	public serviceStates: string;
	public serviceStateIds: string;
	public agentReferralServiceProfileId: number;
	public dbaName: string;
	public summary: string;
	public title: string;
	public description: string;
	public imageIconAttachmentId: string;

	public otherAgentReferralServices: ViewAgentReferralService[];

	constructor(viewAgentReferralService: ViewAgentReferralService) {
		Object.assign(this, viewAgentReferralService);

		this.otherAgentReferralServices = this.otherAgentReferralServices
			? this.otherAgentReferralServices.map(a => new ViewAgentReferralService(a)).filter(a => a.id !== this.id)
			: this.otherAgentReferralServices;
	}
}

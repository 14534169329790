import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CONSTANTS, NumberUtils } from '@pk/powerkioskutils';

import { ServiceType } from '../models';

@Pipe({
	name: 'marginDisplay',
})
export class MarginDisplayPipe implements PipeTransform {

	constructor(private decimalPipe: DecimalPipe) { }

	transform(value: number, serviceType: ServiceType, units: string, marginUnits: string): string {
		let multiplier = 1;

		if (serviceType.serviceTypeId === CONSTANTS.serviceTypes.electricity.serviceTypeId ||
			serviceType.serviceTypeId === CONSTANTS.serviceTypes.communitySolar.serviceTypeId) {
			multiplier *= 1000;
		} else {
			multiplier *= 100;
			multiplier *= NumberUtils.rateToUnits(1, units);
		}

		const valueAdjusted = marginUnits === 'dollars' ? value / 100 : value;

		return this.decimalPipe.transform(valueAdjusted * multiplier, '1.2-2');
	}

}

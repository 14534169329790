export class AgentFinancialProfileBreakdownRow {

	public contractId: string;
	public contractNum: string;
	public dbaName: string;
	public total: number;

	constructor(agentFinancialProfileBreakdownRow: AgentFinancialProfileBreakdownRow) {
		Object.assign(this, agentFinancialProfileBreakdownRow);
	}
}

import * as _ from 'lodash';
import * as moment from 'moment-timezone';

import { EmailCampaign } from './email-campaign';
import { LeadEmailCampaignSchedule } from './lead-email-campaign-schedule';

export class LeadEmailCampaign {
	public id: number;
	public leadId: number;
	public emailCampaignId: number;
	public recipients: string;
	public message: string;
	public details: string;
	public isDismissed: boolean;
	public overrideAnchorDate: Date;

	public leadEmailCampaignSchedules: LeadEmailCampaignSchedule[];
	public emailCampaign: EmailCampaign;

	constructor(leadEmailCampaign?: Partial<LeadEmailCampaign>) {
		Object.assign(this, leadEmailCampaign);

		this.leadEmailCampaignSchedules = this.leadEmailCampaignSchedules
			? this.leadEmailCampaignSchedules.map(l => new LeadEmailCampaignSchedule(l))
			: this.leadEmailCampaignSchedules;
		this.emailCampaign = this.emailCampaign ? new EmailCampaign(this.emailCampaign) : this.emailCampaign;
		this.overrideAnchorDate = this.overrideAnchorDate
			? new Date(moment(this.overrideAnchorDate).format('MMM DD, YYYY 00:00:00 a')) : this.overrideAnchorDate;
	}

	get fromDate() {
		return _.minBy(this.leadEmailCampaignSchedules.map(l => l.date), l => l.valueOf());
	}

	get toDate() {
		return _.maxBy(this.leadEmailCampaignSchedules.map(l => l.date), l => l.valueOf());
	}

	get hasUpcomingSchedule() {
		return moment().isBefore(moment(this.toDate));
	}
}

export class PricingAnalyticsSupplierSale {
	public supplierId: string;
	public saleDate: Date;
	public stateId: string;
	public utilityId: string;
	public minUsage: number;
	public maxUsage: number;
	public effectiveDate: Date;
	public term: number;
	public rateClass: string;
	public zone: string;
	public plannedMargin: number;
	public actualMargin: number;
	public profit: number;
	public dealCount: number;
	public deletedAt: Date;
	public addDate: Date;

	constructor(pricingAnalyticsSupplierSale?: PricingAnalyticsSupplierSale) {
		Object.assign(this, pricingAnalyticsSupplierSale);
	}
}

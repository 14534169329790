import { AdditionalInsured } from './additional-insured';
import { LossHistory } from './loss-history';
import { Pet } from './pet';
import { User } from './user';

export class HomeOwner {

	public id: string;
	public additionalInsureds: AdditionalInsured[];
	public pets: Pet[];
	public lossHistories: LossHistory[];
	public userId: string;

	public user: User;

	constructor(homeowner: HomeOwner) {
		Object.assign(this, homeowner);

		this.user = this.user ? new User(this.user) : this.user;
		this.additionalInsureds = this.additionalInsureds
			? this.additionalInsureds.map(a => new AdditionalInsured(a)) : this.additionalInsureds;
		this.pets = this.pets ? this.pets.map(p => new Pet(p)) : this.pets;
		this.lossHistories = this.lossHistories ? this.lossHistories.map(l => new LossHistory(l)) : this.lossHistories;
	}
}

import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'pk-broker-agent-bank-accounts-reminder-modal',
	templateUrl: './agent-bank-accounts-reminder-modal.component.html',
	styleUrls: ['./agent-bank-accounts-reminder-modal.component.scss'],
})
export class AgentBankAccountsReminderModalComponent {

	constructor(private activeModal: BsModalRef) { }

	public close(): void {
		this.activeModal.hide();
	}
}

export class FaqKeyword {

	public id: number;
	public faqId: number;
	public name: string;
	public addDate: string;
	public modifiedDate: string;

	constructor(faqKeyword?: FaqKeyword) {
		Object.assign(this, faqKeyword);
	}
}

import * as moment from 'moment-timezone';

export class UsageForecastModelResult {
	public contractLocationId: string;
	public month: Date | string;
	public arimaPrediction: number;
	public arimaMape: number;
	public lstmSmallPrediction: number;
	public lstmSmallMape: number;
	public lstmAllPrediction: number;
	public lstmAllMape: number;
	public benchMarkUsage: number;
	public ensembleResult: number;
	public addDate: Date;
	public modifiedDate: Date;

	constructor(usageForecastModelResult?: UsageForecastModelResult) {
		Object.assign(this, usageForecastModelResult);

		this.month = this.month ? moment(this.month).format('YYYY-MM-DD') : this.month;
		this.ensembleResult = this.ensembleResult ? Math.round(this.ensembleResult) : this.ensembleResult;
	}
}

import { CurrencyPipe } from '@angular/common';
import { CONSTANTS } from '@pk/powerkioskutils';

import { ServiceLocator } from '../service-locator.service';
import { LicensePlanType } from './license-plan-type';

export class LicensePlan {

	public id: number;
	public name: string;
	public description: string;
	public stripeId: string;
	public monthlyFee: number;
	public upfrontFee: number;
	public commissionRateFactor: number;
	public commissionRate: number;
	public years: number;

	public parents: LicensePlan[];
	public licensePlanType: LicensePlanType;

	public costDescription: string;

	constructor(licensePlan: LicensePlan) {
		Object.assign(this, licensePlan);

		const currencyPipe = ServiceLocator.injector.get(CurrencyPipe);
		this.costDescription = this.getCostDescription(currencyPipe);
	}

	public isFull(): boolean {
		return CONSTANTS.licensePlans.businessFullIds.includes(this.id) && !this.years;
	}

	public isFullYearly(): boolean {
		return CONSTANTS.licensePlans.businessFullIds.includes(this.id) && this.years >= 1;
	}

	public isLite(): boolean {
		return this.id === 1;
	}

	public isAPI(): boolean {
		return /API/gi.test(this.name);
	}

	public isTurnKey(): boolean {
		return /Turn/gi.test(this.name);
	}

	private getCostDescription(currencyPipe: CurrencyPipe): string {
		const paymentPeriod = this.monthlyFee ? 'mo' : `${this.years > 1 ? (this.years + '-yrs') : 'yr'}`;
		return `(${currencyPipe.transform(this.monthlyFee ? this.monthlyFee : this.upfrontFee)})/${paymentPeriod}`
			+ `${this.name?.includes('Sales Codes') || this.name?.includes('Agent Bundle') ? '/each set of 10' : ''}`;
	}
}

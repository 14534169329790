import * as moment from 'moment-timezone';

export class ReportTask {

	public contract: string;
	public commodity: string;
	public annualUsage: number;
	public className: string;
	public supplier: string;
	public residential: boolean;
	public contractId: string;
	public completeDate: Date;
	public addDate: Date;
	public contractNum: string;
	public assignedTo: string;
	public title: string;
	public body: string;
	public dueDate: Date;
	public agentName: string;
	public parentName: string;
	public licensePlanName: string;

	constructor(reportTask: ReportTask) {
		Object.assign(this, reportTask);

		this.dueDate = this.dueDate ? new Date(moment(this.dueDate).format('MMM DD, YYYY hh:mm:ss a')) : this.dueDate;
	}
}

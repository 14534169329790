export class SupplierMarketAuctionCompetitiveReport {

	public stateLong: string;
	public utilityName: string;
	public averageDelta: number;

	constructor(supplierMarketAuctionCompetitiveReport: SupplierMarketAuctionCompetitiveReport) {
		Object.assign(this, supplierMarketAuctionCompetitiveReport);
	}
}

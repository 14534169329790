import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthResolver } from '../shared/resolvers/auth.resolver';
import { FeedbackComponent } from './feedback/feedback.component';

const routes: Routes = [{
	path: '',
	component: FeedbackComponent,
	resolve: { auth: AuthResolver },
}];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class FeedbackRoutingModule { }

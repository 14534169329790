import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthResolver } from '../shared/resolvers/auth.resolver';
import { AgentInfoComponent } from './agent-info/agent-info.component';

const routes: Routes = [
	{
		path: 'info/:agentId',
		redirectTo: ':agentId/info',
	},
	{
		path: ':agentId/info',
		component: AgentInfoComponent,
		resolve: { auth: AuthResolver },
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AgentRoutingModule { }

export class FinanceCommissionsSummary {

	public totalEstimatedCommission!: number;
	public lastMonthGrossCommission!: number;
	public currentCommissionBalance!: number;
	public lastMonthFinalPayment!: number;

	constructor(financeCommissionsSummary?: FinanceCommissionsSummary) {
		Object.assign(this, financeCommissionsSummary);
	}
}

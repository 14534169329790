import * as moment from 'moment-timezone';

import { Contract } from './contract';

export class PurchasingLayerPurchaseHistory {

	public id: number;
	public contractId: string;
	public date: Date | string;
	public effectiveDate: Date | string;
	public price: number;
	public margin: number;
	public bufferMargin: number;
	public quantity: number;
	public term: number;

	public contract: Contract;

	// internal
	public expirationDate: Date;

	constructor(purchasingLayerPurchaseHistory?: PurchasingLayerPurchaseHistory) {
		Object.assign(this, purchasingLayerPurchaseHistory);

		this.date = this.date
			? new Date(moment(this.date).format('MMM DD, YYYY 00:00:00 a')) : this.date;
		this.effectiveDate = this.effectiveDate
			? new Date(moment(this.effectiveDate).format('MMM DD, YYYY 00:00:00 a')) : this.effectiveDate;
		this.expirationDate = this.effectiveDate && this.term
			? new Date(moment(this.effectiveDate).add(this.term, 'months').format('MMM DD, YYYY 00:00:00 a')) : null;
	}
}

import { Dashboard } from './dashboard';
import { NoteType } from './note-type';
import { Permission } from './permission';

export class Role {

	public loading = true;

	public roleId: string;
	public level: number;
	public name: string;
	public description: string;
	public isActive: boolean;

	public dashboardSelectedByDefaultId: number;

	public noteTypes: NoteType[];
	public permissions: Permission[];
	public dashboards: Dashboard[];
	public dashboardsShownByDefault: Dashboard[];
	public dashboardSelectedByDefault: Dashboard;

	constructor(role?: Role) {
		Object.assign(this, role);

		if (this.permissions) {
			this.permissions = this.permissions.map(p => new Permission(p));
		}
	}
}

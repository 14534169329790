import { AfterViewChecked, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from '@pk/powerkioskutils';

import { LoadOptions } from 'devextreme/data';
import CustomStore from 'devextreme/data/custom_store';
import dxDataGrid from 'devextreme/ui/data_grid';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { AgentAuctionInfoModalComponent } from 'src/app/agent/modals/agent-auction-info-modal/agent-auction-info-modal.component';
import { ContractAuctionInProgressModalComponent } from 'src/app/contract/modals/contract-auction-in-progress/contract-auction-in-progress.component';
import { ContractAuctionModalComponent } from 'src/app/contract/modals/contract-auction/contract-auction.component';
import { ContractNoteAddModalComponent } from 'src/app/contract/modals/contract-note-add/contract-note-add.component';
import { ContractRejectReasonModalComponent } from 'src/app/contract/modals/contract-reject-reason/contract-reject-reason.component';
import { GraphqlService } from 'src/app/graphql/graphql.service';
import { NotificationService } from 'src/app/notification/services/notification.service';
import { GoogleTagManagerService } from '../../google/google-tag-manager.service';
import { HelperService } from '../../helper.service';
import { Agent, ContractCriteria, PagedEntity, RfqSession, ServiceType, State, Supplier, ViewContractDashboard } from '../../models';
import { AbstractPageForm } from '../../pages/page-form.abstract';
import { ServiceLocator } from '../../service-locator.service';

declare const $: any;

@Component({ template: '' })
export abstract class AbstractContractTableComponent<T extends ViewContractDashboard>
	extends AbstractPageForm implements OnInit, AfterViewChecked {

	protected helperService: HelperService;
	protected graphqlService: GraphqlService;

	private modalService: BsModalService;
	private toastrService: ToastrService;
	private notificationService: NotificationService;
	private route: ActivatedRoute;
	private router: Router;
	private googleTagManagerService: GoogleTagManagerService;
	private titleService: Title;

	@ViewChild('contractGridTopScroll', { static: false }) contractGridTopScroll: ElementRef;
	@ViewChild('contractGridTopScrollInner', { static: false }) contractGridTopScrollInner: ElementRef;

	private _gridState: any;
	@Input() set gridState(gridState: any) {
		this._gridState = gridState;
		this.dataGrid?.state(gridState);
	}
	get gridState() {
		return this.dataGrid?.state() ?? this._gridState;
	}
	@Input() fixColumns = false;
	@Input() actionColumnName = '';
	@Input() actionColumnIcon: string[];
	@Input() actionColumnFunction: (contract: T) => void;
	@Input() extraCriteria: ContractCriteria;
	@Input() showFilters = true;
	public filterLists: { [key: string]: { value: (string | number)[]; text: string }[] } = {};

	public contractsDataSource: any = {};

	public dataGrid: dxDataGrid;
	public contracts: T[];
	public contractsTotal: number;

	public agents: Agent[];
	public serviceTypes: ServiceType[];
	public terms: number[];
	public suppliers: Supplier[];
	public states: State[];

	public contractStatusList = [
		'Auction Complete',
		'Auction In Progress',
		'Confirmed',
		'Dropped',
		'Expired',
		'Incomplete',
		'Lost Prospect',
		'Pending',
		'Pending Deposit',
		'Pending Review',
		'Quote',
		'Rejected',
		'Request Price',
		'Signed',
	];

	public isMobile = false;
	public finishedInit = false;
	public auctionContractId: string;
	public showAuction: boolean;
	public showExtend: boolean;

	get columns() {
		const columns = [];
		for (let i = 0; i < this.dataGrid.columnCount(); i++) {
			columns.push(this.dataGrid.columnOption(i));
		}
		return columns;
	}

	constructor(route: ActivatedRoute) {
		super();
		this.route = route;
		this.modalService = ServiceLocator.injector.get(BsModalService);
		this.helperService = ServiceLocator.injector.get(HelperService);
		this.toastrService = ServiceLocator.injector.get(ToastrService);
		this.graphqlService = ServiceLocator.injector.get(GraphqlService);
		this.notificationService = ServiceLocator.injector.get(NotificationService);
		this.router = ServiceLocator.injector.get(Router);
		this.googleTagManagerService = ServiceLocator.injector.get(GoogleTagManagerService);
		this.titleService = ServiceLocator.injector.get(Title);

		this.route.paramMap.subscribe(async () => {
			this.showAuction = StringUtils.toBoolean(this.route.snapshot.queryParamMap.get('showAuction'));
			this.showExtend = StringUtils.toBoolean(this.route.snapshot.queryParamMap.get('showExtend'));
			this.auctionContractId = this.route.snapshot.queryParamMap.get('auctionContractId');

			if (this.showAuction && this.auctionContractId) {
				const result = await this.graphqlService.viewContractDashboard({ contractId: this.auctionContractId }, '', 1, 0);
				this.openAuctionModal(result.data.viewContractDashboard.message[0]);
			}
		});
	}

	async ngOnInit() {
		await this.loadContractTablePageData();
		this.loading = false;

		this.contractsDataSource.store = new CustomStore({
			key: 'contractId',
			load: async (loadOptions: LoadOptions) => {

				const contractsResult = await this.listContracts(loadOptions, this.dataGrid);

				this.contracts = contractsResult ? contractsResult.data : [];
				this.contractsTotal = contractsResult ? contractsResult.totalCount : 0;
				return {
					data: this.contracts,
					totalCount: this.contractsTotal,
				};
			},
		});

		this.isMobile = HelperService.isMobile();
		this.finishedInit = true;
	}

	ngAfterViewChecked(): void {
		const emptyRows = document.getElementsByClassName('dx-freespace-row');
		Array.from(emptyRows).forEach(node => node.remove());

		if (this.dataGrid && this.dataGrid.getScrollable() && $(this.contractGridTopScroll.nativeElement)) {
			$(this.contractGridTopScroll.nativeElement).scrollLeft(this.dataGrid.getScrollable().scrollLeft());
		}
	}

	private displayStatusSuffix(showHoldSubmissionStatus: boolean, showAutoSubmissionStatus: boolean, isResidential: boolean) {
		if (this.loggedInUser && this.loggedInUser.isAdmin) {
			if (showHoldSubmissionStatus && !isResidential) {
				return '(On Hold)';
			}

			if (showAutoSubmissionStatus) {
				return '(Auto)';
			}
		}

		return '';
	}

	public abstract listContracts(loadOptions: LoadOptions, dataGrid: dxDataGrid): Promise<PagedEntity<T>>;

	public async loadContractTablePageData() {
		try {
			const result = await this.graphqlService.getContractTablePageData();

			this.suppliers = result.data.suppliers.message.map(s => new Supplier(s));
			this.serviceTypes = result.data.serviceTypes.message.map(s => new ServiceType(s));
			this.states = result.data.states.message.map(s => new State(s));
			this.agents = this.loggedInUser.agent
				? [this.loggedInUser.agent, ...this.loggedInUser.agent.subAgents.map(a => new Agent(a))]
				: null;

			this.setupFilterLists(this.loggedInUser.agent);
		} catch (err) {
			this.toastrService.warning(`There was a problem loading the page data. We have been `
				+ `notified and are working to fix the issue. Please check back again in 30 minutes.`, 'Contract Info');
		}
	}

	public async refreshData() {
		this.dataGrid.refresh();
	}

	public tableStateLoad = () => this._gridState;

	public onContractDataGridInit(event: any): void {
		this.dataGrid = event.component;
		this.dataGrid.option('stateStoring.ignoreColumnOptionNames', []); // Causes visibility state to work without dxo-column-chooser
	}

	public onContractDataGridReady(event: any): void {
		const $headerRows = $(event.element).find('.dx-header-row');
		$(this.contractGridTopScrollInner.nativeElement).width($($headerRows[$headerRows.length - 1]).width());
		$(this.contractGridTopScroll.nativeElement).scroll(() => {
			this.dataGrid.getScrollable().scrollTo({ left: $(this.contractGridTopScroll.nativeElement).scrollLeft() });
		});

		if (this.securityService.authFields.loggedInUser.agentId) {
			this.dataGrid.columnOption('underwriterName', 'visible', false);
			this.dataGrid.columnOption('hasHappyCustomer', 'visible', false);
			this.dataGrid.columnOption('hasLVM', 'visible', false);
			this.dataGrid.columnOption('hasIssueFound', 'visible', false);
			this.dataGrid.columnOption('hasCommercial', 'visible', false);
			this.dataGrid.columnOption('hasOthers', 'visible', false);
		}

		// Hide columns by default if they don't appear in the current grid config
		for (const col of this.columns) {
			const savedColumns = this._gridState.columns as { dataField: string }[];
			if (!savedColumns.find(savedCol => savedCol.dataField === col.dataField)) {
				this.dataGrid.columnOption(col.dataField, 'visible', false);
			}
		}
	}

	private setupFilterLists(agent?: Agent): void {
		const minYear = agent ? agent.addDate.getFullYear() : new Date().getFullYear() - 5;
		const terms = this.helperService.generateNumberList(0, 60);
		const effectiveDates = this.helperService.generateDatesList(
			new Date(minYear, 0, 1),
			new Date(new Date().getFullYear() + 5, 11, 1),
			'month');
		const expirationDates = this.helperService.generateDatesList(
			new Date(minYear, 0, 1),
			new Date(new Date().getFullYear() + 8, 11, 1),
			'month');
		const shortDateList = this.helperService.generateDatesList(
			new Date(minYear, 0, 1),
			new Date(new Date().getFullYear(), 11, 1),
			'month');

		this.filterLists.effectiveDate = this.helperService.generateDateSelections(effectiveDates);
		this.filterLists.auctionStartDate = this.helperService.generateDateSelections(shortDateList);
		this.filterLists.auctionEndDate = this.helperService.generateDateSelections(shortDateList);
		this.filterLists.confirmationDate = this.helperService.generateDateSelections(shortDateList);
		this.filterLists.contractDate = this.helperService.generateDateSelections(shortDateList);
		this.filterLists.dropDate = this.helperService.generateDateSelections(shortDateList);
		this.filterLists.expirationDate = this.helperService.generateDateSelections(expirationDates);
		this.filterLists.stateLong = this.states.map(s => ({ value: ['stateId', '=', s.stateId], text: s.stateLong }));
		this.filterLists.supplierName = this.suppliers.map(s => ({ value: ['supplierId', '=', s.supplierId], text: s.name }));
		this.filterLists.agentName = this.agents?.map(s => ({ value: ['agentId', '=', s.agentId], text: s.name })) ?? null;
		this.filterLists.isResidential = [
			{ value: ['isResidential', '=', 'Yes'], text: 'Yes' },
			{ value: ['isResidential', '=', 'No'], text: 'No' },
		];
		this.filterLists.commodity = this.serviceTypes.map(s =>
			({ value: ['serviceTypeId', '=', s.serviceTypeId], text: s.name }),
		);
		this.filterLists.term = terms.map(t =>
			({ value: ['term', '=', t], text: `${t} Month${t === 1 ? '' : 's'}` }),
		);
		this.filterLists.isRenewal = [
			{ value: ['isRenewal', '=', 'Yes'], text: 'Yes' },
			{ value: ['isRenewal', '=', 'No'], text: 'No' },
		];
		this.filterLists.displayStatus = this.contractStatusList.map(c =>
			({ value: ['displayStatus', '=', c], text: c }));
	}

	public calculateResidentialDisplay(data: T): string {
		return data.isResidential ? 'Yes' : 'No';
	}

	public calculateRenewalDisplay(data: T): string {
		return data.isRenewal ? 'Yes' : 'No';
	}

	get brokerBidInfoModalVisible() {
		return this.loggedInUser && (
			this.loggedInUser.isAdmin ||
			this.loggedInUser.isOwner ||
			this.loggedInUser.isOperations ||
			this.loggedInUser.isFinance ||
			this.loggedInUser.agent?.isEmployee
		);
	}

	public showBrokerBidInfoModal(contract: T): void {
		this.modalService.show(AgentAuctionInfoModalComponent, {
			class: 'pk-modal details-modal',
			initialState: {
				agentId: contract.agentId,
			},
		});
	}

	public openNoteAddModal(contract: T): void {
		this.modalService.show(ContractNoteAddModalComponent, {
			class: 'pk-modal',
			initialState: { contract },
		});
	}

	public openAuctionModal(contract: ViewContractDashboard): void {
		if (contract.status === this.CONSTANTS.statuses.dr) {
			const modalRef = this.modalService.show(ContractAuctionModalComponent, {
				class: 'pk-modal modal-xl modal-full',
				backdrop: 'static',
			});
			modalRef.content.init(contract.contractId, contract.serviceTypeId, contract.stateId);
			modalRef.content.onSuccess.subscribe(() => {
				this.dataGrid.refresh();
			});
			modalRef.onHidden.subscribe(() => {
				// reset showAuction and showExtend query param on any close event
				this.router.navigate(['.'], { relativeTo: this.route });
				this.showAuction = false;
				this.showExtend = false;
			});
		} else {
			this.router.navigate(['.'], {
				relativeTo: this.route, queryParams: {
					showAuction: true,
					showExtend: this.showExtend,
					auctionContractId: contract.contractId,
				},
			});
			const modalRef = this.modalService.show(ContractAuctionInProgressModalComponent, {
				class: 'pk-modal modal-xl modal-full',
				backdrop: 'static',
			});
			modalRef.content.init(contract.contractId, contract.serviceTypeId, contract.stateId, null, this.route);
			modalRef.content.onSuccess.subscribe(() => {
				this.dataGrid.refresh();
			});
			modalRef.content.onExtend.subscribe((rfqSession: RfqSession) => {
				this.dataGrid.refresh();
				modalRef.hide();
				const body = `Your pricing session has been successfully extended until ${rfqSession.formatEndDate(false)} CT. `
					+ `Since pricing is only valid until close of business the day it's provided, suppliers typically `
					+ `provide pricing the day a pricing session ends to ensure it's valid. Check back for pricing at that time.`;
				this.notificationService.info({ title: 'Extend Session', body });
			});
			modalRef.onHidden.subscribe(() => {
				// reset showAuction and showExtend query param on any close event
				this.router.navigate(['.'], { relativeTo: this.route });
				this.showAuction = false;
				this.showExtend = false;
			});
		}

		this.sendPageEvent('status');
	}

	public showRejectReason(contract: T): void {
		this.modalService.show(ContractRejectReasonModalComponent, {
			class: 'pk-modal',
			initialState: { contract },
		});
		this.sendPageEvent('status');
	}

	public async resendEnrollmentEmail(contract: T) {
		if (confirm('Resend enrollment email?')) {
			try {
				const response = await this.graphqlService.resendEnrollmentEmail(contract.contractId);
				if (response && response.data.resendEnrollmentEmail) {
					this.toastrService.success('Email Sent!', 'Contract Info');
				} else {
					this.toastrService
						// eslint-disable-next-line max-len
						.warning('There was a problem resending the enrollment email. We have been notified and are working to fix the issue. Please check back again in 30 minutes.', 'Contract Info');
				}
			} catch (err) {
				this.toastrService.warning(HelperService.getErrorMessage(err)[0], 'Contract Info');
			}
		}
		this.sendPageEvent('status');
	}

	public sendPageEvent(action: string): void {
		this.googleTagManagerService.sendPageEvent(action, this.loggedInUser, this.titleService.getTitle());
	}
}

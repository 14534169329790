<div class="row mb-4" *ngIf="rfqSession && supplierId">
	<div class="col-md-12">
		<section class="clearfix">
			<div class="card">
				<div class="card-body">
					<div class="table-responsive">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>Supplier</th>
									<th>Product</th>
									<th>Green %</th>
									<th>Amount</th>
									<th>IP</th>
									<th>Date</th>
									<th style="width: 50px">&nbsp;</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let bid of rfqSession.bids | supplierBids: supplierId">
									<td>{{ bid.supplier.name }}</td>
									<td>{{ bid.rfqSessionProduct?.product.name }}</td>
									<td>{{ bid.rfqSessionProduct?.greenPercentage }}%</td>
									<td>{{ bid.rate | number: '1.6-6' }}</td>
									<td>{{ bid.ipAddress }}</td>
									<td>{{ bid.addDate | date: environment.dateTimeFormat }} CT</td>
									<td class="text-center">
										<button href="javascript:void(0)" class="btn btn-danger py-2 px-4" [disabled]="bid.removing" (click)="removeBid(bid)">
											<fa-icon [icon]="['fas', 'trash']"></fa-icon>
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</section>
	</div>
</div>

export class RateInput {
	public serviceTypeId: string;
	public utilityId: string;
	public zipCode: string;
	public stateId: string;
	public rateClass: string;
	public usage: number;
	public zone: string;
	public effectiveDate: string;
	public baseRate: number;
	public margin: number;
	public marginAdjustment: number;
	public addDate: string;
	public addDateGE: string;
	public effectiveDateGE: string;
	public bandwidthPercentage: number;
	public billingMethod: string;
	public cancellationPenaltyDesc: string;
	public commission: number;
	public disclaimerPath: string;
	public displayRate: string;
	public eflPath: string;
	public extra: string;
	public greenPercentage: number;
	public loadFactor: number;
	public logo: string;
	public monthlyServiceFee: number;
	public name: string;
	public phone3: string;
	public planId: string;
	public planName: string;
	public planName2: string;
	public popularity: number;
	public premium: number;
	public productId: string;
	public puct: string;
	public rate: number;
	public rateAddDate: string;
	public rateCodeId: string;
	public salesTax: number;
	public savings: number;
	public supplierId: string;
	public term: number;
	public tosPath: string;
	public usageAdjustment: number;
	public yracPath: string;
	public isPrepaid: boolean;
	public dailyServiceFee: number;
	public contractId: string;
	public rfqSessionId: string;
}

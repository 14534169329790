export class MailServerType {
	public id: number;
	public name: string;
	public server: string;
	public port: number;
	public useSSL: boolean;
	public useTLS: boolean;
	public fromEmailLabel: string;
	public usernameLabel: string;
	public passwordLabel: string;
	public showFromEmail: boolean;
	public defaultUsername: string;
	public hint: string;

	constructor(mailServerType?: MailServerType) {
		Object.assign(this, mailServerType);
	}

	get showServer(): boolean { return !this.server; }
	get showUseSSL(): boolean { return !this.server; }
	get showUseTLS(): boolean { return !this.server; }
}

import { State } from './state';
import { Utility } from './utility';

export class Ecl {

	public id: number;
	public utilityId: string;
	public serviceTypeId: string;
	public accountUuid: string;
	public agentId: string;
	public utilityAccountNum: string;
	public dbaName1: string;
	public dbaName2: string;
	public address1: string;
	public address2: string;
	public city: string;
	public stateId: string;
	public zipCode: string;
	public billingAddress1: string;
	public billingAddress2: string;
	public billingCity: string;
	public billingStateId: string;
	public billingZipCode: string;
	public billingCountryId: number;
	public annualUsage: number;
	public isUsingDefaultService: boolean;
	public plc: number;
	public networkPlc: number;
	public loadFactor: number;
	public rateClass: string;
	public totalBilledKwh: number;
	public totalBilledMonths: number;
	public peakDemand: number;
	public totalOnPeakKwh: number;
	public totalOffPeakKwh: number;
	public usageJson: string;
	public demandJson: string;
	public onPeakUsageJson: string;
	public offPeakUsageJson: string;
	public extraJson: string;

	public state: State;
	public billingState: State;
	public utility: Utility;

	constructor(ecl: Ecl) {
		Object.assign(this, ecl);
	}
}

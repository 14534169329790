<pk-broker-modal customBodyClass="pb-0" customFooterClass="justify-content-center">
	<ng-template #headerTemplate>
		<h5 class="modal-title">Generate LOA/LOE</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<form [formGroup]="formGroup" *ngIf="formGroup">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<p class="black-text">Which of the following documents do you wish to generate and send to your customer?</p>
					</div>
				</div>
				<div class="row">
					<div class="col-12 form-element-container">
						<div class="custom-control custom-checkbox">
							<input class="custom-control-input" type="checkbox" id="addLOA" formControlName="addLOA" />
							<label class="custom-control-label" for="addLOA">{{ contract.hasLOA() ? '(Resend) ' : '' }}Letter of Authorization (LOA)</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 form-element-container">
						<div class="custom-control custom-checkbox">
							<input class="custom-control-input" type="checkbox" id="addLOE" formControlName="addLOE" />
							<label class="custom-control-label" for="addLOE">{{ contract.hasLOE() ? '(Resend) ' : '' }}Letter of Exclusivity (LOE)</label>
						</div>
					</div>
				</div>
				<div class="row" *ngIf="form.addLOE.value">
					<div class="col-12 ml-3 form-element-container">
						<div class="custom-control custom-checkbox">
							<input class="custom-control-input" type="checkbox" id="addBranding" formControlName="addLogo" />
							<label class="custom-control-label" for="addBranding">Attach my branding (logo) to the final LOE document</label>
						</div>
					</div>
				</div>
			</div>
			<div class="alert alert-warning" *ngIf="err.group?.atLeastOneSelected">Please select to send at least the LOA or LOE.</div>
		</form>
		<pk-broker-page-warnings [warnings]="warnings"></pk-broker-page-warnings>
	</ng-template>

	<ng-template #footerTemplate>
		<button type="button" class="btn btn-link" (click)="activeModal.hide()" [disabled]="submitDisabled">Cancel</button>
		<button type="button" class="btn btn-primary" (click)="submit()" [disabled]="submitDisabled">{{ submitText }}</button>
	</ng-template>
</pk-broker-modal>

import { Pipe, PipeTransform } from '@angular/core';

import { Rate } from '../models/rate';
import { RateFilter } from '../models/rate-filter';

@Pipe({
	name: 'filterPlans',
})
export class FilterPlansPipe implements PipeTransform {

	transform(plans: Rate[], filter: RateFilter, useTerm: boolean,
		useSupplier: boolean, useProduct: boolean, useGreenPercentage: boolean, useLoadFactor: boolean,
		useCommissionPayPlanTypes: boolean): Rate[] {
		return plans && filter
			? plans.filter(r =>
				(!useTerm ||
					!(filter.terms && filter.terms.length) || filter.terms.includes(r.term))
				&& (!useSupplier ||
					!(filter.supplierIds && filter.supplierIds.length) || filter.supplierIds.includes(r.supplierId))
				&& (!useProduct ||
					!(filter.productIds && filter.productIds.length) || filter.productIds.includes(r.productId))
				&& (!useGreenPercentage ||
					!(filter.greenPercentages && filter.greenPercentages.length) || filter.greenPercentages.includes(r.greenPercentage))
				&& (!useCommissionPayPlanTypes ||
					!(filter.commissionPayPlanTypes && filter.commissionPayPlanTypes.length) ||
					filter.commissionPayPlanTypes.some(p => r.commissionOptions.some(co => co.commissionPayPlanTypeId === p)))
				&& (!useLoadFactor || !r.matrixPrice ||
					!(filter.loadFactorStatements && filter.loadFactorStatements.length) ||
					filter.loadFactorStatements.includes(r.loadFactorSetting.statement)))
			: plans;
	}

}

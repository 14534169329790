import { Component } from '@angular/core';

import { AbstractPageForm } from '../../pages/page-form.abstract';

@Component({
	selector: 'pk-broker-market-recommendations',
	templateUrl: './market-recommendations.component.html',
	styleUrls: ['./market-recommendations.component.scss']
})
export class MarketRecommendationsComponent extends AbstractPageForm {
	public email = 'sales@powerkiosk.com';
}

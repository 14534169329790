import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import videojs from 'video.js';

declare const $: any;

@Component({
	selector: 'pk-broker-vjs-player',
	template: `<video #target class="video-js"></video>`,
	styleUrls: ['./vjs-player.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class VjsPlayerComponent implements OnInit, OnDestroy {
	@ViewChild('target', { static: true }) target: ElementRef;

	@Input() options?: {
		poster?: string;
		controls?: boolean;
		preload?: string;
		autoplay?: boolean;
		setup?: string;
		width?: number;
		height?: number;
		fluid?: boolean;
		sources?: {
			src: string;
			type?: string;
		}[];
	};
	@Input()
	set videoId(videoId: string) {
		if (videoId) {
			const checkPlayButton = setInterval(() => {
				$('.vjs-big-play-button').attr('id', videoId);
				if ($('.vjs-big-play-button')[0]) {
					clearInterval(checkPlayButton);
				}
			}, 100);
		}
	}

	private player: videojs.Player;

	ngOnInit() {
		this.player = videojs(this.target.nativeElement, this.options);
	}

	ngOnDestroy() {
		if (this.player) {
			this.player.dispose();
		}
	}
}

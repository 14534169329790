import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

import { CustomRfqSession, SessionFilter } from '../models';

@Pipe({
	name: 'sessionFilter'
})
export class SessionFilterPipe implements PipeTransform {

	transform(sessions: CustomRfqSession[], filter: SessionFilter, sort: string): unknown {
		if (!sessions && !filter) { return sessions; }

		const sortParts = sort ? sort.split(' ') : null;
		const propertyName = sortParts ? sortParts[0] : null;
		const direction = this.getDirection(sortParts);
		return _.orderBy(sessions.filter(s =>
			(!filter.state.stateId || filter.state.stateId === s.stateId) &&
			(!filter.utility.utilityId || s.utilityIdList.includes(filter.utility.utilityId)) &&
			(!filter.serviceType.serviceTypeId || filter.serviceType.serviceTypeId === s.serviceTypeId) &&
			(filter.hasLOE.value === null || filter.hasLOE.value === !!s.loeAttachmentIdList.length)),
			propertyName, direction);
	}

	private getDirection(sortParts: string[]): any {
		if (!sortParts) { return null; }

		return sortParts[1] === 'desc' ? 'desc' : 'asc';
	}
}

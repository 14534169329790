<pk-broker-navbar
	*ngIf="appReady || directReady"
	[isDirect]="directReady"
	[isPopupWindow]="isPopupWindow"
	(userUpdated)="onUserUpdated($event)"
	(agentUpdated)="onAgentUpdated($event)"
	(footerLogoUpdated)="onFooterLogoUpdated($event)"
	(useDefaultUpdated)="onUseDefaultUpdated($event)">
</pk-broker-navbar>

<router-outlet></router-outlet>

<pk-broker-footer
	*ngIf="appReady || directReady"
	[isDirect]="directReady"
	[user]="user"
	[agent]="agent"
	[useDefault]="useDefault"
	[defaultFooterLogo]="defaultFooterLogo">
</pk-broker-footer>

<pk-broker-feedback-bubble *ngIf="(appReady || directReady) && feedbackPage && !isPopupWindow" [page]="feedbackPage" [user]="user">
</pk-broker-feedback-bubble>



export class SupplierMarketMatrixRankingCompetitivePlan {
	public utilityId: string;
	public utilityName: string;
	public term: number;
	public nextDelta: number;
	public ptcDelta: number;
	public bestDelta: number;
	public productName: string;
	public rank: string;

	constructor(supplierMarketMatrixRankingCompetitivePlan: SupplierMarketMatrixRankingCompetitivePlan) {
		Object.assign(this, supplierMarketMatrixRankingCompetitivePlan);
	}
}

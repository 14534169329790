import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { Contract } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';
import { generateLoaLoeValidator } from 'src/app/shared/validators/generate-loa-loe.validator';

@Component({
	selector: 'pk-broker-generate-loa-loe-modal',
	templateUrl: './generate-loa-loe-modal.component.html',
	styleUrls: ['./generate-loa-loe-modal.component.scss'],
})
export class GenerateLoaLoeModalComponent extends AbstractPageForm {

	// inputs
	public contract: Contract;

	// outputs
	public submitted: Subject<{
		addLOA: boolean;
		addLOE: boolean;
	}> = new Subject();

	constructor(
		private fb: FormBuilder,
		public activeModal: BsModalRef,
		private graphqlService: GraphqlService,
	) {
		super();
		this.setSubmitText('Generate', 'Generating...');
	}

	public getForm() {
		return this.fb.group({
			addLogo: false,
			addLOA: false,
			addLOE: false,
		}, { validators: generateLoaLoeValidator });
	}

	protected async onFormSubmitted() {
		await this.graphqlService.generateLOALOE({
			contractId: this.contract.contractId,
			addLOA: this.form.addLOA.value,
			addLOE: this.form.addLOE.value,
			addLogo: this.form.addLOE.value && this.form.addLogo.value,
		});
		this.submitted.next({
			addLOA: this.form.addLOA.value,
			addLOE: this.form.addLOE.value,
		});
		this.activeModal.hide();
	}
}

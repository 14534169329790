<div class="home-page-takeover-modal">
	<pk-broker-modal
		customFooterClass="justify-content-center pt-0"
		[customHeaderStyle]="{ padding: 0, 'justify-content': 'center' }"
		[customCloseButtonStyle]="{ 'margin-top': 0, 'margin-right': 0 }"
		customCloseButtonIcon="circle-xmark"
		[useDuotoneCloseButtonIcon]="true">
		<ng-template #headerTemplate>
			<img [src]="headerImageSrc" class="img-fluid" />
		</ng-template>
		<ng-template #bodyTemplate>
			<pk-broker-loader [show]="loading"></pk-broker-loader>
			<div class="container-fluid" *ngIf="!loading">
				<ng-container *ngIf="!showConfirmationMessage">
					<ng-container [ngSwitch]="type">
						<ng-container *ngSwitchCase="CONSTANTS.bannerAlertTypes.brokerReferral">
							<div class="row">
								<div class="col-12">
									<h5>Refer anyone you think would thrive with Power Kiosk, and earn as they sell.</h5>
									<ol class="pl-3">
										<li>Fill out their info.</li>
										<li>Earn $5 off your next bill when they sign up.</li>
										<li>After 90 days of them on the platform, you will receive a bonus depending on their term value.</li>
									</ol>
									<p class="font-size-075 semibold-text mb-0">Rules &amp; Terms</p>
									<ul class="pl-3">
										<li style="font-size: 0.65rem">
											Referred must sign up within 30 days and stay on the platform, paying Business Full, for 3 months for you to receive awards.
										</li>
										<li style="font-size: 0.65rem">
											You can refer unlimited times and receive unlimited bonuses, but only can receive $5 off up to 2 times.
										</li>
										<li style="font-size: 0.65rem">Term value will be determined after 90 days of broker activation date.</li>
									</ul>
								</div>
							</div>
							<form [formGroup]="formGroup" *ngIf="formGroup">
								<div class="row">
									<div class="col-12 form-element-container" [class.invalid]="err.name">
										<label for="brokerName" class="required">Broker Name</label>
										<input formControlName="name" type="text" class="form-control" id="brokerName" maxlength="500" />
										<p class="invalid-feedback mb-1" *ngIf="err.name">
											<ng-container *ngIf="err.name?.maxlength">Please enter 500 characters or less.</ng-container>
										</p>
									</div>
								</div>
								<div class="row">
									<div class="col-12 form-element-container" [class.invalid]="err.email">
										<label for="brokerEmail" class="required">Broker Email</label>
										<input formControlName="email" type="text" class="form-control" id="brokerEmail" maxlength="500" />
										<p class="invalid-feedback mb-1" *ngIf="err.email">
											<ng-container *ngIf="err.email?.maxlength">Please enter 500 characters or less.</ng-container>
										</p>
									</div>
								</div>
								<div class="row">
									<div class="col-12 form-element-container" [class.invalid]="err.phone">
										<label for="brokerPhone" class="required">Broker Phone</label>
										<div class="input-group">
											<div class="input-group-prepend">
												<pk-broker-form-dropdown formControlName="phoneCountryId" [options]="countries" valueKey="id">
													<ng-template #name let-option>{{ option.code }}</ng-template>
													<ng-template #row let-option>{{ option.name }} {{ option.code }}</ng-template>
												</pk-broker-form-dropdown>
											</div>
											<input type="text" class="form-control" id="brokerPhone" formControlName="phone" [textMask]="{ mask: phoneMask }" />
										</div>
										<p class="mb-0 invalid-feedback" *ngIf="err.phone">Valid Phone Number is required</p>
									</div>
								</div>
								<p class="mb-0 invalid-feedback" *ngIf="err.group?.hasDetail">At least one field needs to be filled in to successfully submit.</p>
								<pk-broker-page-warnings [warnings]="warnings"></pk-broker-page-warnings>
							</form>
						</ng-container>
						<ng-container *ngSwitchCase="CONSTANTS.bannerAlertTypes.annualMembership">
							<div class="row">
								<div class="col-12">
									<h5>We now offer discounted memberships upfront.</h5>
									<ul>
										<li>Purchase 2 years upfront and receive a $300 discount.</li>
										<li>Purchase 1 year upfront and receive a $150 discount.</li>
									</ul>
									<p class="semibold-text">Would you like to change your membership?</p>
								</div>
							</div>
						</ng-container>
						<ng-container *ngSwitchCase="CONSTANTS.bannerAlertTypes.leadLink">
							<div class="row">
								<div class="col-12">
									<h5>A monthly subscription gives you millions of utility-verified leads.</h5>
									<p>
										We now offer a complete list of all active utility accounts in OH, PA, and MD (over 15 utilities). For only
										<span class="semibold-text">$500 per Month and a 12-month commitment</span>, you will access a list of all active customers.
									</p>
									<p class="semibold-text">Would you like to know more?</p>
								</div>
							</div>
						</ng-container>
						<ng-container *ngSwitchCase="CONSTANTS.bannerAlertTypes.customerReferral">
							<div class="row">
								<div class="col-12">
									<h5>Tell your friends about us!</h5>
									<p class="semibold-text">
										We are so happy to see that your {{ contract.serviceType.name }} contract generated significant savings on your utility bills.
									</p>
									<p>
										We rely on referrals to continue improving our services. Earn up to $2,000 for each referred qualified business that signed a
										commodity agreement with our platform.
									</p>
									<ul class="pl-3">
										<li>Receive $100 for up to 50,000 kWh of annual usage</li>
										<li>Receive $200 for up to 250,000 kWh of annual usage</li>
										<li>Receive $500 for up to 500,000 kWh of annual usage</li>
										<li>Receive $1000 for up to 1,000,000 kWh of annual usage</li>
										<li>Receive $2000 for any business above 1,000,000 kWh of annual usage</li>
									</ul>
								</div>
							</div>
							<form [formGroup]="formGroup" *ngIf="formGroup">
								<ng-container formArrayName="referrals" *ngFor="let referral of form.referrals; let i = index">
									<ng-container [formGroupName]="i">
										<div class="row">
											<div class="col form-element-container" [ngClass]="{ invalid: err.referrals[i].name }">
												<label for="friendName">Friend's First Name</label>
												<input formControlName="name" type="text" class="form-control" id="friendName" maxlength="500" />
												<p class="invalid-feedback mb-1" *ngIf="err.name">
													<ng-container *ngIf="err.name?.required">Please enter a name.</ng-container>
													<ng-container *ngIf="err.name?.maxlength">Please enter 500 characters or less.</ng-container>
												</p>
											</div>
											<div class="col-auto text-right" *ngIf="form.referrals.length > 1">
												<a href="javascript:void(0)" class="removeCustomerReferral" (click)="form.referrals.remove(referral)">
													<fa-icon [icon]="['fas', 'times-circle']" [classes]="['text-danger', 'mb-0']" size="lg"></fa-icon>
												</a>
											</div>
										</div>
										<div class="row">
											<div class="col-12 form-element-container" [ngClass]="{ invalid: err.referrals[i].email }">
												<label for="friendEmail">Friend's Email</label>
												<input formControlName="email" type="text" class="form-control" id="friendEmail" maxlength="500" />
												<p class="invalid-feedback mb-1" *ngIf="err.email">
													<ng-container *ngIf="err.email?.required">Please enter an email address.</ng-container>
													<ng-container *ngIf="err.email?.maxlength">Please enter 500 characters or less.</ng-container>
												</p>
											</div>
										</div>
									</ng-container>
									<hr *ngIf="i < form.referrals.length - 1" />
								</ng-container>
								<a
									class="btn btn-link py-0 px-2 mb-2"
									id="addCustomerReferral"
									href="javascript:void(0)"
									(click)="addCustomerReferral()"
									*ngIf="form.referrals.length <= 5">
									<fa-icon [icon]="['fas', 'plus-circle']"></fa-icon> Add a referral
								</a>
								<pk-broker-page-warnings *ngIf="warnings?.length" [warnings]="warnings"></pk-broker-page-warnings>
							</form>
						</ng-container>
					</ng-container>
				</ng-container>
				<div class="row" id="confirmMessage" *ngIf="showConfirmationMessage">
					<div class="col-12">
						<hr class="divider1px" />
						<p><span class="semibold-text">Thank you!</span> {{ confirmationMessage }}</p>
					</div>
				</div>
			</div>
		</ng-template>
		<ng-template #footerTemplate>
			<button
				*ngIf="!showConfirmationMessage"
				type="button"
				class="btn btn-secondary"
				[disabled]="submitDisabled"
				(click)="submit()"
				id="topBannerModalSubmitBtn">
				{{ submitText }}
			</button>
		</ng-template>
	</pk-broker-modal>
</div>

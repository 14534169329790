export class CpanelMenuType {

	public name: string;
	public description: string;
	public menuTypeId: string;
	public userId: string;

	constructor(cpanelMenuType?: CpanelMenuType) {
		Object.assign(this, cpanelMenuType);
	}
}

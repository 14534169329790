

export class PurchasingLayerHedgePriceRequest {

	public id: number;
	public details: string;

	constructor(purchasingLayerHedgePriceRequest?: PurchasingLayerHedgePriceRequest) {
		Object.assign(this, purchasingLayerHedgePriceRequest);
	}
}

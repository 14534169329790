import * as moment from 'moment-timezone';

export class MoveInType {

	public text: string;
	public value: string;
	public allowedDates: Date[];

	constructor(moveInType?: MoveInType) {
		Object.assign(this, moveInType);

		this.allowedDates = this.allowedDates
			? this.allowedDates.map(a => new Date(moment(a).format('MMM DD, YYYY 00:00:00 a'))) : this.allowedDates;
	}
}

export class SwitchType {

	public switchTypeCode: string;
	public switchDescription: string;
	public moveInTypes?: MoveInType[];

	constructor(switchType: SwitchType) {
		Object.assign(this, switchType);

		this.moveInTypes = this.moveInTypes ? this.moveInTypes.map(m => new MoveInType(m)) : null;
	}
}

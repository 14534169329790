
export class LetterOfAuthorization {

	public letterOfAuthorizationId: number;
	public heading: string;
	public body: string;

	constructor(letterOfAuthorization: LetterOfAuthorization) {
		Object.assign(this, letterOfAuthorization);
	}
}

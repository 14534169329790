import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import * as _ from 'lodash';
import { Observable, Subscriber } from 'rxjs';
import { compareTwoStrings } from 'string-similarity';

@Component({
	selector: 'pk-broker-typeahead',
	templateUrl: './typeahead.component.html',
	styleUrls: ['./typeahead.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			useExisting: TypeaheadComponent,
		},
	],
})
export class TypeaheadComponent<T> implements OnInit, ControlValueAccessor {
	public option: T;
	public queryText = '';
	public dropdownItems: Observable<{ name: string; value: T }[]>;
	private subscriptions: Subscriber<{ name: string; value: T }[]>[] = [];
	public disabled = false;
	private touched = false;

	@ContentChild('header') public header: TemplateRef<void>;
	@ContentChild('row') public rowRef: TemplateRef<{ $implicit: T }>;
	@ContentChild('footer') public footer: TemplateRef<void>;
	@Output() selection = new EventEmitter<T>();
	@Output() textChanged = new EventEmitter<string>();

	@Input()
	label: string = null;
	@Input()
	placeholder: string;
	@Input()
	isRequired = false;
	@Input()
	set isDisabled(disabled: boolean) { this.setDisabledState(disabled); }
	@Input()
	options: T[];
	@Input()
	initialText: string;
	@Input()
	initialOption: T = null;
	@Input()
	typeaheadMinLength = 4;
	@Input()
	exactMatches = false;
	@Input()
	/** Set to -1 for no max */
	maxDropdownItemCount = 10;

	@Input()
	textKey = 'text';
	/**
	 * If this is defined, ensure that no two options map to the same value.
	 */
	@Input()
	valueKey: string;

	@Input()
	textMap: (option: T) => string = option => String(typeof option === 'object' && this.textKey in option ? option[this.textKey] : option);

	/**
	 * If this is defined, ensure that no two options map to the same value.
	 */
	@Input()
	valueMap: (option: T) => any =
		option => (typeof option === 'object' && option !== null && this.valueKey in option) ? option[this.valueKey] : option;

	onChange = (_value: any) => { /* placeholder for interface */ };
	onTouched = () => { /* placeholder for interface */ };

	ngOnInit() {
		if (!this.placeholder) {
			this.placeholder = this.label ?? '';
		}
		this.dropdownItems = new Observable(subscribe => {
			this.subscriptions.push(subscribe);
			return { unsubscribe: () => this.subscriptions = this.subscriptions.filter(sub => sub !== subscribe) };
		});
		this.queryText = this.initialOption ? this.textMap(this.initialOption) : '';
		if (this.initialText) {
			this.queryText = this.initialText;
		}
	}

	updateDropdownItems(queryText: string) {
		const searchResults = this.searchOptions(queryText).map(option => ({ name: this.textMap(option), value: option }));
		this.subscriptions.forEach(subscription => subscription.next(searchResults));
		this.textChanged.emit(queryText);
	}

	searchOptions(query: string): T[] {
		if (query.length < this.typeaheadMinLength) { return []; }

		if (this.exactMatches) {
			return this.options.filter((option: T) => this.textMap(option).toLowerCase().includes(query.toLowerCase()));
		} else {
			const lowerQuery = query.toLowerCase();
			return _.chain(this.options)
				.map(option => ({ similarity: compareTwoStrings(lowerQuery, this.textMap(option).toLowerCase()), option }))
				.orderBy('similarity', 'desc')
				.slice(0, this.maxDropdownItemCount === -1 ? Number.MAX_SAFE_INTEGER : this.maxDropdownItemCount)
				// Show fewer results if they are much worse than the best match
				.filter((value, _i, options) => value.similarity > options[0].similarity / 4)
				.map(s => s.option)
				.value();
		}
	}

	onSelect(option: T) {
		if (!this.disabled) {
			this.markAsTouched();
			this.option = option;
			this.onChange(this.valueMap(option));
			this.selection.emit(this.valueMap(option));
		}
	}

	writeValue(value: any) {
		this.option = this.options.find(o => this.valueMap(o) === value) ?? null;
	}

	registerOnChange(onChange: (option: T) => void) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: () => void) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}
}

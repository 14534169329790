import { AfterViewChecked, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { LoadOptions } from 'devextreme/data';
import CustomStore from 'devextreme/data/custom_store';
import DxDataGrid from 'devextreme/ui/data_grid';
import * as _ from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { map, tap } from 'rxjs';

import { GraphqlService } from '../../graphql/graphql.service';
import { HelperService } from '../../shared/helper.service';
import { Agent, Breadcrumb, ContactMethod, Role, Ticket, TicketCategory, User, ViewTicketDashboard } from '../../shared/models';
import { AbstractPage } from '../../shared/pages/page.abstract';
import { TicketAddEditModalComponent } from '../modals/ticket-add-edit/ticket-add-edit.component';

declare const $: any;

@Component({
	selector: 'pk-broker-ticket',
	templateUrl: './ticket.component.html',
	styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent extends AbstractPage implements AfterViewChecked {

	private static breadcrumbs = [
		{ text: 'Dashboard', route: '/contract' },
		{ text: 'Tickets', isLast: true },
	] as Breadcrumb[];

	public useSupportSystem = true;

	public resultsLoading = true;
	public ticketsCount: number;
	private tickets: ViewTicketDashboard[];
	public ticketsDataSource: any = {};
	private ticketGrid: DxDataGrid;
	public defaultFilters: { [key: string]: (string | number)[][] } = {};
	public filters: { [key: string]: { value: (string | number)[]; text: string }[] } = {};

	public ticketCategories: TicketCategory[];
	public ticketCategoryIdList: number[] = [];
	public users: User[];
	public roles: Role[];
	public reporterCategoryList: string[] = [];
	public completeUserIdList: string[] = [];
	public contactMethods: ContactMethod[];

	constructor(
		private graphqlService: GraphqlService,
		private modalService: BsModalService,
		private route: ActivatedRoute,
		private router: Router,
		private helperService: HelperService,
		private fb: FormBuilder,
	) {
		super(TicketComponent.breadcrumbs, 'Tickets');
		this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
			const ticketId = Number(paramMap.get('ticketId'));
			if (ticketId) {
				this.router.navigate([ticketId, 'info'], { relativeTo: this.route });
			}
		});
	}

	ngAfterViewChecked(): void {
		const emptyRows = document.getElementsByClassName('dx-freespace-row');
		Array.from(emptyRows).forEach(node => node.remove());

		if (this.ticketGrid?.getScrollable() && $('#ticketGridTopScroll')) {
			$('#ticketGridTopScroll').scrollLeft(this.ticketGrid.getScrollable().scrollLeft());
		}
	}

	public async loadPageData(): Promise<void> {
		const pageResult = await this.graphqlService.getTicketPageData();
		this.ticketCategories = pageResult.data.ticketCategories.message
			.map(t => new TicketCategory(t))
			.filter(t => this.loggedInUser.isAdmin || t.roles.map(r => r.roleId).includes(this.loggedInUser.roleId));
		this.ticketCategoryIdList = this.ticketCategories.map(c => c.id);
		this.users = pageResult.data.users.message.map(u => new User(u));
		this.roles = pageResult.data.roles.message.map(r => new Role(r));
		this.reporterCategoryList = this.roles.map(c => c.name);
		this.reporterCategoryList.push(null);
		this.completeUserIdList = this.users.map(u => u.userId);
		this.contactMethods = pageResult.data.contactMethods.message;

		this.useSupportSystem = true;
		if (this.loggedInUser.agent && this.loggedInUser.agent.parent) {
			this.useSupportSystem = this.loggedInUser.agent.useSupportSystem && this.loggedInUser.agent.parent.useSupportSystem;
		}

		this.setupFilterLists(this.loggedInUser.agent);
	}

	private setupFilterLists(agent: Agent): void {
		const minYear = agent?.addDate.getFullYear() ?? 2016;
		const completeDates = this.helperService.generateDatesList(
			new Date(minYear, 0, 1),
			new Date(new Date().getFullYear() + 5, 11, 1),
			'month');

		this.filters.completeDate = this.helperService.generateDateSelections(completeDates);
		this.filters.addDate = this.helperService.generateDateSelections(completeDates);

		this.filters.ticketCategorySelections = this.ticketCategories.map(c => ({
			value: ['ticketCategoryId', '=', c.id],
			text: c.name,
		}));
		if (this.loggedInUser.isAdmin) {
			this.defaultFilters.defaultTicketCategories = this.ticketCategories
				.filter(c => c.name !== 'Feedback' && c.name !== 'Failed Payment')
				.map(c => ['ticketCategoryId', '=', c.id]);
		} else {
			this.defaultFilters.defaultTicketCategories = this.ticketCategories
				.filter(c => c.name !== 'Missing Price-to-Compare' && c.name !== 'Incorrect Price-to-Compare')
				.map(c => ['ticketCategoryId', '=', c.id]);
		}

		this.filters.reporterRoleSelections = this.roles.map(c => ({
			value: ['reporterRole', '=', c.name],
			text: c.name,
		}));
		this.defaultFilters.defaultReporterRole = this.filters.reporterRoleSelections.map(c => c.value);

		this.filters.prioritySelections = HelperService.ticketPriorities.map(c => ({
			value: ['priority', '=', c.id],
			text: c.name,
		}));
		this.defaultFilters.defaultPriorities = this.filters.prioritySelections.map(c => c.value);

		if (this.loggedInUser.isAdmin) {
			this.filters.statusSelections = HelperService.ticketStatuses.map(c => ({
				value: ['statusId', '=', c.id],
				text: c.name,
			}));
			this.defaultFilters.defaultStatusCategories =
				HelperService.ticketStatuses.filter(c => c.name !== 'Complete').map(c => ['statusId', '=', c.id]);
		} else {
			this.filters.statusSelections = HelperService.ticketStatusesBroker.map(c => ({
				value: ['statusId', '=', c.id],
				text: c.name,
			}));
			this.defaultFilters.defaultStatusCategories = HelperService.ticketStatusesBroker.map(c => ['statusId', '=', c.id]);
		}

		this.filters.assignedToUserSelections = this.users.map(u => ({
			value: ['assignedToUserId', '=', u.userId],
			text: new User(u).getLabel(),
		}));
		this.filters.completeUserSelections = this.users.map(u => ({
			value: ['completeUserId', '=', u.userId],
			text: new User(u).getLabel(),
		}));
	}

	public getForm() {
		return this.fb.group({
			search: '',
		});
	}

	public async onFormLoaded(): Promise<void> {
		this.ticketsDataSource.store = new CustomStore({
			key: 'id',
			load: this.helperService.throttledLoader((loadOptions: LoadOptions) => this.listTickets(loadOptions)),
			onLoading: () => this.resultsLoading = true,
			onLoaded: () => this.resultsLoading = false,
		});

		this.form.search.valueChanges.subscribe(() => this.refreshDataGrid());
	}

	public refreshDataGrid() {
		const state = this.ticketGrid.state();
		this.ticketGrid.state(state);
	}

	public get statusText() {
		if (this.resultsLoading) {
			return this.isSearchDefined ? 'Searching...' : 'Tickets';
		}

		if (this.isSearchDefined) {
			return `We found ${this.ticketsCount} ticket${this.ticketsCount === 1 ? '' : 's'} matching your search`;
		} else {
			return `Tickets (${this.ticketsCount})`;
		}
	}

	public get isSearchDefined(): boolean {
		return this.form.search?.value.length > 3;
	}

	public onTicketDataGridReady(event: any): void {
		$('#ticketGridTopScrollInner').width($($(event.element).find('.dx-header-row')[1]).width());
		$('#ticketGridTopScroll').scroll(() => {
			this.ticketGrid.getScrollable().scrollTo({ left: $('#ticketGridTopScroll').scrollLeft() });
		});
	}

	private listTickets(loadOptions: LoadOptions) {
		const criteria = {} as any;
		const loadOptionsSort = (Array.isArray(loadOptions.sort) ? loadOptions.sort : [loadOptions.sort]).filter(Boolean);
		const sort: string[] = loadOptionsSort?.length
			? loadOptionsSort.map(s => `${s['selector']} ${(s['desc'] ? 'desc' : 'asc')}`)
			: ['priority desc, isHighImpactAgent desc, addDate desc'];
		const filterMap = this.helperService.buildFilterMap(this.ticketGrid, this.ticketGrid.getCombinedFilter(), {});

		if (this.isSearchDefined) {
			criteria.search = `%${this.form.search.value.trim()}%`;
		}

		if (filterMap.hasOwnProperty('!ticketCategoryId')) {
			const notList: number[] = _.flatten([filterMap['!ticketCategoryId']]);
			filterMap['ticketCategoryId'] = _.chain(this.ticketCategoryIdList).pullAll(notList).value();
		}
		if (filterMap.hasOwnProperty('ticketCategoryId')) {
			criteria.ticketCategoryId = _.flatten([filterMap['ticketCategoryId']]).join(',');
		}

		if (filterMap.hasOwnProperty('!priority')) {
			const notList: number[] = _.flatten([filterMap['!priority']]);
			filterMap['priority'] = _.chain(HelperService.ticketPriorities).map(s => s.id).pullAll(notList).value();
		}
		if (filterMap.hasOwnProperty('priority')) {
			criteria.priority = _.flatten([filterMap['priority']]).join(',');
		}

		if (filterMap.hasOwnProperty('!assignedToUserId')) {
			const notList: string[] = _.flatten([filterMap['!assignedToUserId']]);
			filterMap['assignedToUserId'] = _.chain(this.users).map(s => s.userId).pullAll(notList).value();
		}
		if (filterMap.hasOwnProperty('assignedToUserId')) {
			criteria.assignedToUserId = _.flatten([filterMap['assignedToUserId']]).join(',');
		}

		if (filterMap.hasOwnProperty('!completeUserId')) {
			const notList: string[] = _.flatten([filterMap['!completeUserId']]);
			filterMap['completeUserId'] = _.chain(this.completeUserIdList).pullAll(notList).value();
		}
		if (filterMap.hasOwnProperty('completeUserId')) {
			criteria.completeUserId = _.flatten([filterMap['completeUserId']]).join(',');
		}

		if (filterMap.hasOwnProperty('!reporterRole')) {
			const notList: string[] = _.flatten([filterMap['!reporterRole']]);
			filterMap['reporterRole'] = _.chain(this.reporterCategoryList).pullAll(notList).value();
		}
		if (filterMap.hasOwnProperty('reporterRole')) {
			criteria.reporterRole = _.flatten([filterMap['reporterRole']]).join(',');
		}

		if (filterMap.hasOwnProperty('!statusId')) {
			const notList: number[] = _.flatten([filterMap['!statusId']]);
			filterMap['statusId'] = _.chain(HelperService.ticketStatuses).map(s => s.id).pullAll(notList).value();
		}
		if (filterMap.hasOwnProperty('statusId')) {
			criteria.statusId = _.flatten([filterMap['statusId']]).join(',');
			if (!this.loggedInUser.isAdmin && criteria.statusId.split(',').includes('2')) {
				criteria.statusId += ',4';
			}
		}

		if (filterMap.hasOwnProperty('completeDate')) {
			criteria.completeDate = JSON.stringify(filterMap['completeDate']);
		}

		if (filterMap.hasOwnProperty('addDate')) {
			criteria.addDate = JSON.stringify(filterMap['addDate']);
		}

		return this.graphqlService.viewTicketDashboardSub(criteria, sort.join(', '), loadOptions.take, loadOptions.skip).pipe(
			tap(res => {
				this.tickets = res.data.viewTicketDashboard.message.map(c => new ViewTicketDashboard(c));
				this.ticketsCount = res.data.viewTicketDashboard.total;
			}),
			map(() => ({
				data: this.tickets,
				totalCount: this.ticketsCount,
			})),
		);
	}

	public calculateCompleteDateDisplay(data: Ticket) {
		return data.completeDate ? data.completeDate : '';
	}

	public calculateStatusDisplay(data: ViewTicketDashboard): string {
		if (!this.loggedInUser.isAdmin) {
			return data.brokerStatus;
		}
		return data.status;
	}

	public calculateStatusColorDisplay(status: string): string {
		switch (status) {
			case 'Complete': {
				return 'status-complete';
			}
			case 'To Do': {
				return 'status-todo';
			}
			case 'Submitted': {
				return 'status-todo';
			}
			case 'Waiting On Reporter': {
				return 'status-waiting-reporter';
			}
			case 'In Progress': {
				return 'status-in-progress';
			}
			case 'New Response': {
				return 'status-new-response';
			}
			default: {
				return 'status-todo';
			}
		}
	}

	public onTicketDblClick(row: any): void {
		const id = row.data.id;
		const url = this.router.serializeUrl(this.router.createUrlTree([id, 'info'], { relativeTo: this.route }));
		window.open(url, '_blank');
	}

	public openTicketAddModal(): void {
		const newTicket = new Ticket({
			user: this.loggedInUser,
			email: this.loggedInUser.email,
			body: '',
			agent: this.loggedInUser.agent,
		} as Ticket);
		const modalRef = this.modalService.show(TicketAddEditModalComponent, {
			class: 'pk-modal modal-xl',
			backdrop: 'static',
			initialState: {
				ticket: newTicket,
				ticketCategories: this.ticketCategories
					.filter(c => c.id !== this.CONSTANTS.ticketCategories.reactivationRequest),
				users: this.users,
				contactMethods: this.contactMethods,
				isAddingNewTicket: true,
			},
		});
		modalRef.content.onSubmit.subscribe(() => {
			this.ticketGrid.refresh();
		});
		modalRef.onHidden.subscribe(() => { this.router.navigate(['.'], { relativeTo: this.route }); });
	}

	public openTicketEditModal(ticket: Ticket): void {
		this.router.navigate(['.'], { relativeTo: this.route, queryParams: { ticketId: ticket.id } });
		const modalRef = this.modalService.show(TicketAddEditModalComponent, {
			class: 'pk-modal modal-xl',
			backdrop: 'static',
			initialState: {
				ticket,
				ticketCategories: this.ticketCategories.filter(c =>
					ticket.ticketCategoryId === this.CONSTANTS.ticketCategories.reactivationRequest ||
					c.id !== this.CONSTANTS.ticketCategories.reactivationRequest
				),
				users: this.users,
				contactMethods: this.contactMethods,
				isAddingNewTicket: false,
			},
		});
		modalRef.content.onSubmit.subscribe(() => {
			this.ticketGrid.refresh();
		});
		modalRef.onHidden.subscribe(() => { this.router.navigate(['.'], { relativeTo: this.route }); });
	}
}

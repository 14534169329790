<pk-broker-modal>
	<ng-template #headerTemplate>
		<h5 class="modal-title" id="reassignContractsModalLabel">Reassign Contracts</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<form [formGroup]="formGroup" *ngIf="!loading">
			<div class="row">
				<div class="col-md-12">
					<div class="form-element-container">
						<label class="title required" for="oldAgentName">Old Agent</label>
						<input type="text" class="form-control" id="oldAgentName" formControlName="oldAgentName" readonly />
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="form-element-container">
						<label class="title required" for="oldAgentName">New Agent</label>
						<input type="text" class="form-control" id="newAgentName" formControlName="newAgentName" readonly />
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<form [formGroup]="searchForm" *ngIf="searchForm" (submit)="onSearch()">
						<div class="input-group mb-1">
							<input
								type="text"
								id="newAgentName"
								class="form-control"
								placeholder="Search - (Minimum of 4 characters required)"
								formControlName="search"
								required
								(keyup)="onSearch()" />
							<div class="input-group-append">
								<button type="button" class="btn btn-default" [disabled]="!searchForm.valid" (click)="searchForm.reset()">
									<fa-icon [icon]="['fas', 'times']"></fa-icon>
								</button>
								<button type="button" class="btn btn-default" [disabled]="!searchForm.valid" (click)="onSearch()">
									<fa-icon [icon]="['fas', 'search']"></fa-icon>
								</button>
							</div>
						</div>
					</form>
					<dx-data-grid
						id="gridContainer"
						[dataSource]="agentsDataSource"
						[allowColumnResizing]="true"
						[columnAutoWidth]="true"
						[showBorders]="true"
						[remoteOperations]="true"
						[rowAlternationEnabled]="true"
						[hoverStateEnabled]="true"
						(onInitialized)="onAgentsDataGridInit($event)">
						<dxo-scrolling [useNative]="true"></dxo-scrolling>
						<dxo-header-filter [visible]="true"></dxo-header-filter>
						<dxi-column caption="Name" cellTemplate="nameCellTemplate" dataField="name" dataType="string" [allowHeaderFiltering]="false"></dxi-column>
						<dxi-column caption="Active" dataType="boolean" cellTemplate="isActiveCellTemplate" dataField="is_active" [allowSorting]="true">
							<dxo-header-filter [dataSource]="activeSelections"></dxo-header-filter>
						</dxi-column>
						<dxi-column caption="Actions" cellTemplate="actionsCellTemplate" [allowHeaderFiltering]="false"></dxi-column>
						<dxo-paging [pageSize]="5"></dxo-paging>
						<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 25, 50, 100]"></dxo-pager>
						<div *dxTemplate="let d of 'nameCellTemplate'">
							<div class="chart-cell">
								{{ d.data.displayName }}
							</div>
						</div>
						<div *dxTemplate="let d of 'isActiveCellTemplate'">
							<div class="chart-cell">
								<fa-icon [icon]="['fas', 'check']" *ngIf="d.data.isActive"></fa-icon>
							</div>
						</div>
						<div *dxTemplate="let d of 'actionsCellTemplate'">
							<div class="btn-group" role="group">
								<button class="btn btn-primary" [disabled]="d.data.name === form.newAgentName.value" (click)="selectAgent(d.data)">
									{{ d.data.name === form.newAgentName.value ? 'Selected' : 'Select' }}
								</button>
							</div>
						</div>
					</dx-data-grid>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<p class="text-danger">Important - This action is irreversible.</p>
		<button type="button" class="btn btn-secondary" (click)="activeModal.hide()">Close</button>
		<button type="button" class="btn btn-primary" (click)="submit()" [disabled]="submitDisabled">{{ submitText }}</button>
	</ng-template>
</pk-broker-modal>

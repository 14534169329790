import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

import { Product, Rate } from '../models';

@Pipe({
	name: 'filterProducts'
})
export class FilterProductsPipe implements PipeTransform {
	transform(products: Product[], rates: Rate[]): any {
		const productTypeList = _.uniq(rates.map(r => r.productId));
		return products && productTypeList ? products.filter(p => productTypeList.includes(p.id)) : products;
	}
}

export class OperationsAuctionsEndingToday {

	public rfqSessionID!: string;
	public contractID!: string;
	public contractNum!: string;
	public dbaName!: string;
	public serviceTypeID!: string;
	public serviceTypeName!: string;
	public serviceTypeUnits!: string;
	public stateID!: string;
	public annualUsage!: number;
	public suppliersThatHaveBid!: number;
	public suppliersInvited!: number;

	constructor(operationsAuctionsEndingToday?: OperationsAuctionsEndingToday) {
		Object.assign(this, operationsAuctionsEndingToday);
	}
}

<div class="row my-4">
	<div class="col shadow-sm market-recommendations" [class.green-confirm]="showMarketSuggestionSuccess">
		<div class="row" *ngIf="showMarketSuggestionSuccess">
			<div class="col">
				<h3 class="no-underline mb-0">Effective Date changed to {{ rfqSession.marketSuggestionEffectiveDate | date: 'MMMM yyyy' }}.</h3>
				<p>Please note choosing a later date from your contract's expiration might result in hold over rate for the extended months.</p>
			</div>
		</div>
		<div class="row d-flex align-items-xxxl-center" *ngIf="!showMarketSuggestionSuccess">
			<div class="col-12 col-xxxl">
				<h3 class="no-underline mb-0">Get the best return on this session.</h3>
				<p>
					Based on current data for fixed-rate plans, you have the potential to get better rates if you change your effective date. Please note
					choosing a later date might result in hold over rate for the extension.
				</p>
			</div>
			<div class="col-12 col-md-6 col-lg col-xxxl-auto" *ngFor="let marketSuggestion of rfqSession.marketSuggestions; let i = index">
				<div class="rate-suggestion-card mb-3" (click)="chooseMarketSuggestion(marketSuggestion)">
					<div class="rate-suggestion-body text-center p-3">
						<p class="mb-0 font-size-085 semibold-text">
							{{ marketSuggestion.effectiveDate | date: 'MMMM yyyy' }}: <ng-container *ngIf="i === 0">Current Selection</ng-container
							><ng-container *ngIf="i !== 0"
								><strong>Up to {{ marketSuggestion.percentChange * -1 }}% lower</strong></ng-container
							>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

export class EmailAnalyticsMasterRow {

	public id: string;
	public groupName: string;
	public recipientType: string;
	public numEmailTypes: number;
	public openRate: number;
	public clickRate: number;
	public unsubscribeRate: number;
	public signRate: number;
	public hasDetail: boolean;
	public hasOpenRate: boolean;
	public hasClickRate: boolean;
	public hasUnsubscribeRate: boolean;
	public hasSignRate: boolean;

	constructor(emailAnalyticsMasterRow?: EmailAnalyticsMasterRow) {
		Object.assign(this, emailAnalyticsMasterRow);
	}
}

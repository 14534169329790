export class CpanelMenu {

	public menuId: string;
	public name: string;
	public description: string;
	public eventName: string;
	public queryString: string;
	public permissions: string;
	public parentId: string;

	constructor(cpanelMenu: CpanelMenu) {
		Object.assign(this, cpanelMenu);
	}
}



export class ViewUtilityDefaultRateClass {

	public utilityId: string;
	public stateId: string;
	public serviceTypeId: string;
	public defaultRateClass: string;

	constructor(viewUtilityDefaultRateClass: ViewUtilityDefaultRateClass) {
		Object.assign(this, viewUtilityDefaultRateClass);
	}
}

export class ViewInvoice {

	public id: number;
	public invoiceNum: string;
	public supplierId: string;
	public supplierName: string;
	public serviceTypeId: string;
	public commodity: string;
	public totalAmount: number;
	public noteCount: number;
	public addDate: string;
	public sentDate: string;
	public sender: string;
	public supplierEmail: string;
	public invoiceStatusId: number;
	public invoiceStatus: string;
	public symbol: string;
	public rate: number;
	public brokerId: string;
	public claimReferenceNumber: string;

	constructor(viewInvoice?: ViewInvoice) {
		Object.assign(this, viewInvoice);
	}
}

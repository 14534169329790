/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */

export enum SupplierMarketAuctionRankAggregateType {
	STATE = 'STATE',
	UTILITY = 'UTILITY'
}

export class SupplierMarketAuctionRankReport {

	public stateLong: string;
	public utilityName: string;
	public totalBidsSubmitted: number;
	public averageDelta: number;
	public firstPlace: number;
	public secondPlace: number;
	public thirdPlace: number;
	public otherPlace: number;

	constructor(supplierMarketAuctionRankReport: SupplierMarketAuctionRankReport) {
		Object.assign(this, supplierMarketAuctionRankReport);
	}
}

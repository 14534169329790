import { Component, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { Attachment } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-select-image-modal',
	templateUrl: './select-image-modal.component.html',
	styleUrls: ['./select-image-modal.component.scss']
})
export class SelectImageModalComponent extends AbstractPageForm {

	public standardImages = [
		{ name: 'auction-emails-customer-hero.jpg', alt: 'Auction' },
		{ name: 'ce-happy-holidays-email-hero.gif', alt: 'Holiday Animated GIF' },
		{ name: 'ce-happy-holidays-email-hero.jpg', alt: 'Happy Holidays' },
		{ name: 'ce-labor-day-email-hero.jpg', alt: 'Labor Day' },
		{ name: 'ce-memorial-day-email-hero.jpg', alt: 'Memorial Day' },
		{ name: 'ce-independence-day-email-hero.gif', alt: 'Independence Day' },
		{ name: 'ce-new-years-email-hero.jpg', alt: 'New Years' },
		{ name: 'ce-thanksgiving-email-hero.jpg', alt: 'Thanksgiving' },
		{ name: 'contract-expiration-notice-hero.jpg', alt: 'Energy Meter' },
		{ name: 'contract-performance-customer-hero.jpg', alt: 'Contract Performance' },
		{ name: 'contract-renewal-hero.jpg', alt: 'Power Outlet' },
		{ name: 'layered-hedging-hero.jpg', alt: 'Power Button' },
		{ name: 'market-timing-customer-hero.jpg', alt: 'Plug into Outlet' },
		{ name: 'new-agent-login-hero.jpg', alt: 'Lightbulbs' },
		{ name: 'peak-demand-dayOf-main-hero-marquee.jpg', alt: 'Electrical Lines' },
		{ name: 'peak-demand-main-hero-marquee.jpg', alt: 'Electrical Lines' },
		{ name: 'strategy-training-hero.jpg', alt: 'Electrical Light' },
		{ name: 'renewal-pricing-customer-hero.jpg', alt: 'Lightbulb' },
		{ name: 'supplier-bankruptcy-hero.jpg', alt: 'Plug and Outlet' },
	];

	// inputs
	public content: string;
	public attachment: Attachment;

	// outputs
	public submitted: EventEmitter<this['formGroup']['value']> = new EventEmitter();

	constructor(
		private activeModal: BsModalRef,
		private fb: FormBuilder,
	) {
		super();
		this.submitText = 'Place';
	}

	public async loadPageData(): Promise<void> { }

	public getForm() {
		return this.fb.group({
			standardImage: this.content,
			customImage: this.attachment,
		}, {
			validators: (formGroup: FormGroup): ValidationErrors | null => {
				const value = formGroup.value;
				if (!value.standardImage && !value.customImage) {
					return { required: true };
				} else {
					return null;
				}
			},
		});
	}

	public async onFormLoaded(): Promise<void> {
		this.form.customImage.valueChanges.subscribe(value => {
			if (value) {
				this.form.standardImage.setValue(null);
			}
		});
		this.form.standardImage.valueChanges.subscribe(value => {
			if (value) {
				this.form.customImage.setValue(null);
			}
		});
	}

	protected async onFormSubmitted() {
		this.submitted.emit(this.formGroup.value);
		this.close();
	}

	public setStandardImage(image: this['standardImages'][0]) {
		this.form.standardImage.setValue(`https://static.powerkiosk.com/email/ce/${image.name}`);
	}

	public close(): void {
		this.activeModal.hide();
	}
}

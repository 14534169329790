import * as moment from 'moment-timezone';

export class ContractUsage {
	public date: Date;
	public usage: number;

	constructor(contractUsage?: ContractUsage) {
		Object.assign(this, contractUsage);

		this.date = this.date
			? new Date(moment(this.date).format('MMM DD, YYYY 00:00:00 a')) : this.date;
	}
}

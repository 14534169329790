import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { BsModalService } from 'ngx-bootstrap/modal';

import { AgentLookupModalComponent } from 'src/app/agent/modals/agent-lookup/agent-lookup.component';
import { SecurityService } from 'src/app/security/security.service';
import { HelperService } from 'src/app/shared/helper.service';
import { Agent } from 'src/app/shared/models';

@Component({
	selector: 'pk-broker-agent-selector',
	templateUrl: './agent-selector.component.html',
	styleUrls: ['./agent-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			useExisting: AgentSelectorComponent,
		},
	],
})
export class AgentSelectorComponent implements ControlValueAccessor {
	public agent: Agent = null;
	public inputId: string;

	public disabled = false;
	private touched = false;

	@Output() selection = new EventEmitter<Agent>();

	@Input()
	label = 'Agent';
	@Input()
	isRequired = false;
	@Input()
	nullAgentText = '-- None --';
	@Input()
	includeHouseAccounts: boolean;
	@Input()
	includeSalesManagers: boolean;
	@Input()
	includeIsInHouseIndirectSale: boolean;
	@Input()
	shownRoleId: string;
	@Input()
	controlType: 'form' | 'filter' = 'form';
	@Input()
	companyId: string;

	constructor(
		private modalService: BsModalService,
		public securityService: SecurityService,
	) {
		this.inputId = HelperService.getUniqueId();
	}

	onChange = (_agent: Agent) => { /* placeholder for interface */ };
	onTouched = () => { /* placeholder for interface */ };

	showLookupAgent() {
		const modalRef = this.modalService.show(AgentLookupModalComponent, {
			class: 'pk-modal modal-xl',
			initialState: {
				includeHouseAccounts: this.includeHouseAccounts,
				includeSalesManagers: this.includeSalesManagers,
				includeIsInHouseIndirectSale: this.includeIsInHouseIndirectSale,
				shownRoleId: this.shownRoleId,
				companyId: this.companyId,
			},
		});
		modalRef.content.onSubmit.subscribe((agent: Agent) => {
			this.markAsTouched();
			this.agent = agent ?? this.securityService.authFields.loggedInUser.agent;
			this.onChange(this.agent);
			this.selection.emit(this.agent);
		});
	}

	public clearAgent(): void {
		this.markAsTouched();
		this.agent = this.securityService.authFields.loggedInUser.agent;
		this.onChange(this.agent);
		this.selection.emit(this.agent);
	}

	writeValue(agent: Agent) {
		this.agent = agent ?? this.securityService.authFields.loggedInUser.agent;
	}

	registerOnChange(onChange: (agent: Agent) => void) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: () => void) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}
}

export class TinyMceTemplate {
	public id: number | string;
	public title: string;
	public content: string;
	public categoryId: number | string;

	public oldCategoryId?: number | string;
	public newCategoryId?: number | string;

	constructor(tinyMceTemplate?: Partial<TinyMceTemplate>) {
		Object.assign(this, tinyMceTemplate);

		this.id = this.id ? String(this.id) : this.id;
	}
}

import { EmailPreference } from './email-preference';

export class AgentEmailPreference {

	public agentId: string;
	public emailPreferenceId: number;

	public emailPreference: EmailPreference;

	constructor(agentEmailPreference?: AgentEmailPreference) {
		Object.assign(this, agentEmailPreference);
	}
}

import * as moment from 'moment-timezone';

export class MarketUpdateContract {

	public isRenewal: boolean;
	public contractId: string;
	public contractNum: string;
	public previousContractNum: string;
	public utility: string;
	public effectiveDate: Date;
	public agentId: string;
	public dbaName: string;

	constructor(marketUpdateContract: MarketUpdateContract) {
		Object.assign(this, marketUpdateContract);

		this.effectiveDate = this.effectiveDate
			? new Date(moment(this.effectiveDate).format('MMM DD, YYYY 00:00:00 a')) : this.effectiveDate;
	}
}

<pk-broker-modal>
	<ng-template #headerTemplate>
		<h5 class="modal-title">User Lookup</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<form [formGroup]="formGroup" *ngIf="!loading" (submit)="submit()">
			<div class="input-group mb-3">
				<input type="text" class="form-control" placeholder="Search - (Minimum of 4 characters required)" formControlName="criteria" required />
				<div class="input-group-append">
					<button type="button" class="btn btn-default" [disabled]="!formGroup.valid" (click)="formGroup.reset()">
						<fa-icon [icon]="['fas', 'times']"></fa-icon>
					</button>
					<button type="button" class="btn btn-default" [disabled]="!formGroup.valid" (click)="submit()">
						<fa-icon [icon]="['fas', 'search']"></fa-icon>
					</button>
				</div>
			</div>
		</form>
		<dx-data-grid
			*ngIf="!loading"
			id="gridContainer"
			[dataSource]="usersDataSource"
			[allowColumnResizing]="true"
			[columnAutoWidth]="true"
			[showBorders]="true"
			[remoteOperations]="true"
			[rowAlternationEnabled]="true"
			[hoverStateEnabled]="true"
			(onInitialized)="onUsersDataGridInit($event)">
			<dxo-scrolling [useNative]="true"></dxo-scrolling>
			<dxo-header-filter [visible]="true"></dxo-header-filter>
			<dxi-column
				caption="Name"
				cellTemplate="nameCellTemplate"
				dataField="fname,middle,lname"
				[allowHeaderFiltering]="false"
				sortOrder="asc"></dxi-column>
			<dxi-column caption="Email" dataField="email" dataType="string" [allowHeaderFiltering]="false"></dxi-column>
			<dxi-column caption="Active" cellTemplate="isActiveCellTemplate" [allowHeaderFiltering]="false"></dxi-column>
			<dxi-column caption="Actions" cellTemplate="actionsCellTemplate" [allowHeaderFiltering]="false"></dxi-column>
			<dxo-paging [pageSize]="25"></dxo-paging>
			<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
			<div *dxTemplate="let d of 'nameCellTemplate'">
				<div class="chart-cell">
					{{ d.data.fullName }}
				</div>
			</div>
			<div *dxTemplate="let d of 'isActiveCellTemplate'">
				<div class="chart-cell">
					<fa-icon [icon]="['fas', 'check']" *ngIf="d.data.isActive"></fa-icon>
				</div>
			</div>
			<div *dxTemplate="let d of 'actionsCellTemplate'">
				<div class="btn-group" role="group">
					<a class="btn btn-primary" (click)="selectUser(d.data)">Select</a>
				</div>
			</div>
		</dx-data-grid>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" class="btn btn-secondary" (click)="activeModal.hide()">Close</button>
	</ng-template>
</pk-broker-modal>

import { Pipe, PipeTransform } from '@angular/core';

import { RfqSessionBid } from '../models/rfq-session-bid';

@Pipe({
	name: 'supplierBids'
})
export class SupplierBidsPipe implements PipeTransform {

	transform(bids: RfqSessionBid[], supplierId: string): any {
		return bids && supplierId ? bids.filter(b => b.supplierId === supplierId) : bids;
	}

}

<section class="container-fluid feedback-container">
	<pk-broker-loader [show]="loading || !listResult"></pk-broker-loader>
	<ng-container *ngIf="!loading && listResult">
		<div class="row">
			<div class="col-12">
				<h2 class="add-underline">User Feedback</h2>
			</div>
		</div>
		<div class="row d-flex mb-4">
			<div class="col-12 col-sm-6 col-lg-3 col-lg-auto flex-fill">
				<pk-broker-filter-dropdown
					label="Feedback Type"
					[options]="feedbackTypes"
					textKey="name"
					[initialOption]="defaultFeedbackType"
					(selection)="selectFeedbackTypeOption($event)">
				</pk-broker-filter-dropdown>
			</div>
			<div class="col-12 col-sm-6 col-lg-3 col-lg-auto flex-fill">
				<pk-broker-filter-dropdown
					label="Post Date"
					[options]="sortOptions"
					textKey="name"
					(selection)="selectSortOption($event)"
					[initialOption]="sortOptions[1]">
				</pk-broker-filter-dropdown>
			</div>
			<div class="col-12 col-sm-6 col-lg-3 col-lg-auto flex-fill">
				<pk-broker-filter-dropdown
					label="Can Contact"
					[options]="[true, false]"
					nullOption="Show All"
					(selection)="selectCanContactOption($event)"
					[textMap]="canContactTextMap">
				</pk-broker-filter-dropdown>
			</div>
			<div class="col-12 col-sm-6 col-lg-3 col-lg-auto flex-fill">
				<pk-broker-filter-dropdown label="Status" [options]="statusOptions" nullOption="Show All" (selection)="selectStatusOption($event)">
				</pk-broker-filter-dropdown>
			</div>
		</div>

		<div class="row" *ngIf="(!loadingFeedback && !listResult.data.length) || (listResult.data.length && !listResult.data.length)">
			<div class="col-md-12">
				<div class="alert alert-primary text-center" role="alert" *ngIf="!listResult.data.length">
					We were not able to find any feedback. Please check back later.
				</div>
			</div>
		</div>

		<div class="row mb-3">
			<div class="col-12 text-center">
				<pk-broker-pager [currentPage]="currentPage" [pageCount]="pageCount" (pageChanged)="goToPage($event)"> </pk-broker-pager>
			</div>
		</div>

		<pk-broker-loader [show]="loadingFeedback"></pk-broker-loader>
		<ng-container *ngIf="!loadingFeedback">
			<pk-broker-feedback-row *ngFor="let feedback of listResult.data" [feedback]="feedback" [loggedInUser]="loggedInUser"> </pk-broker-feedback-row>
		</ng-container>
	</ng-container>
</section>

import { CommonModule } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { InMemoryCache } from '@apollo/client/core';

import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { environment } from '../../environments/environment';
import { SharedModule } from '../shared/shared.module';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		ApolloModule,
	],
	providers: [
		{
			provide: APOLLO_OPTIONS,
			useFactory: (httpLink: HttpLink) => {
				const headers = new HttpHeaders({
					'apollographql-client-name': 'Broker Web',
					'apollographql-client-version': '1.0.0'
				});
				return {
					cache: new InMemoryCache({
						addTypename: false
					}),
					link: httpLink.create({
						uri: environment.graphQLEndpoint,
						headers,
					}),
					name: 'Broker Web',
					version: '1.0.0',
					defaultOptions: {
						query: {
							fetchPolicy: 'no-cache',
						},
						mutate: {
							fetchPolicy: 'no-cache',
						},
					}
				};
			},
			deps: [HttpLink]
		},
	],
	declarations: []
})
export class GraphqlModule {

}

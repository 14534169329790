import { Pipe, PipeTransform } from '@angular/core';

import { ReportCommissionPaid } from '../models';

@Pipe({
	name: 'filterReportCommissionPaid'
})
export class FilterReportCommissionPaidPipe implements PipeTransform {
	transform(commissions: ReportCommissionPaid[], onlyNegativeAmounts: boolean): ReportCommissionPaid[] {
		return commissions ? commissions.filter(c => !onlyNegativeAmounts || c.amount < 0) : commissions;
	}
}

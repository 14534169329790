/* eslint-disable @typescript-eslint/naming-convention */
import * as moment from 'moment-timezone';

import { PendingCommissionIssueType } from './pending-commission-issue-type';

export class PendingCommissionIssue {

	public id: number;
	public pendingCommissionId: number;
	public pendingCommissionIssueTypeId: number;
	public overrideUserId: string;
	public subject: string;
	public note: string;
	public addDate: Date;
	public modifiedDate: Date;
	public deletedAt: Date;
	public includeInPayables: boolean;

	public pendingCommissionIssueType: PendingCommissionIssueType;

	constructor(pendingCommissionIssue?: PendingCommissionIssue) {
		Object.assign(this, pendingCommissionIssue);

		this.addDate = this.addDate && !moment.isDate(this.addDate)
			? new Date(moment(this.addDate).format('MMM DD, YYYY 00:00:00 a')) : this.addDate;
		this.modifiedDate = this.modifiedDate && !moment.isDate(this.modifiedDate)
			? new Date(moment(this.modifiedDate).format('MMM DD, YYYY 00:00:00 a')) : this.modifiedDate;
		this.pendingCommissionIssueType = this.pendingCommissionIssueType
			? new PendingCommissionIssueType(this.pendingCommissionIssueType)
			: this.pendingCommissionIssueType;
	}
}

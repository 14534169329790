<div class="container-fluid feedback-panel">
	<pk-broker-loader [show]="loading"></pk-broker-loader>
	<ng-container *ngIf="!loading">
		<div class="row modal-top">
			<div class="col pt-3 pb-2 pl-3">
				<a href="javascript:void(0)" class="go-back" (click)="back()"><fa-icon [icon]="['fas', 'chevron-left']"></fa-icon>Back</a>
				<button type="button" class="close" id="closeButton" (click)="activeModal.hide()" aria-label="Close">
					<span aria-hidden="true"><fa-icon [icon]="['fas', 'times']"></fa-icon></span>
				</button>
			</div>
		</div>
		<div class="row feedback-content">
			<div class="col" *ngIf="selectedFeedback === 'positive'">
				<form [formGroup]="formGroup" *ngIf="!submittedFeedback" autocomplete="off">
					<h4>We're so happy we could help you!</h4>
					<p>If you have any feedback or suggestions we would love to hear about it.</p>
					<div class="form-group">
						<textarea class="form-control" id="additionalFeedback" formControlName="body" maxlength="400" rows="4" [class.invalid]="err.body">
						</textarea>
						<span>characters left {{ charactersLeft }}/400</span>
						<div class="invalid-feedback" *ngIf="err.body">Please add a message for your feedback.</div>
					</div>
					<button type="button" class="btn btn-secondary" (click)="submit()" [disabled]="submitDisabled">{{ submitText }}</button>
					<p *ngIf="submitDisabled"><pk-broker-loader [show]="submitDisabled"></pk-broker-loader></p>
				</form>
				<ng-container *ngIf="submittedFeedback">
					<h4>Thank you!</h4>
					<p>We appreciate your feedback.</p>
				</ng-container>
			</div>
			<div class="col" *ngIf="selectedFeedback === 'negative'">
				<h4>Do you need further support?</h4>
				<p>
					The best action you can take is to <a [routerLink]="['/ticket']" target="_blank">submit a support ticket</a> so we can address your issue
					fully.
				</p>
				<p class="semibold-text ml-3 mb-5">
					<a [routerLink]="['/ticket']" target="_blank" class="blue-gray-icon">
						<fa-icon [icon]="['fas', 'laptop-medical']"></fa-icon>Request Support
					</a>
				</p>
				<h4>Do you need urgent support?</h4>
				<p>You can schedule a 10-minute call with one of our staff to hopefully give you the answers you want <em>(powered by Calendly)</em>.</p>
				<p><em>Please select a category:</em></p>
				<ul>
					<li *ngFor="let option of calendlyOptions">
						<a [href]="option.link" target="_blank" class="semibold-text">{{ option.name }}</a>
					</li>
				</ul>
			</div>
		</div>
	</ng-container>
</div>

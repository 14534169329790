export class RateMatrixBenchmarkInput {
	public serviceTypeId: string;
	public stateId: string;
	public utilityId: string;
	public usage: number;
	public effectiveDate: string;
	public addDate: string;
	public addDateGE: string;
	public rateClass: string;
	public zone: string;
	public productId: string;

	constructor(rateMatrixBenchmarkInput: RateMatrixBenchmarkInput) {
		Object.assign(this, rateMatrixBenchmarkInput);
	}
}

export class ContractLocationStatusReasonType {

	public id: number;
	public name: string;
	public sortOrder: number;
	public contractLocationStatus: number;
	public isActive: boolean;

	constructor(contractLocationStatusReasonType?: ContractLocationStatusReasonType) {
		Object.assign(this, contractLocationStatusReasonType);
	}
}

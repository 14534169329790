import { EmailAnalyticsDetailRow } from './email-analytics-detail-row';
import { EmailAnalyticsMasterRow } from './email-analytics-master-row';
import { EmailAnalyticsTopStats } from './email-analytics-top-stats';

export class EmailAnalytics {

	public topStats: EmailAnalyticsTopStats;
	public masterStats: EmailAnalyticsMasterRow[];
	public detailStats: EmailAnalyticsDetailRow[];

	constructor(emailAnalytics?: EmailAnalytics) {
		Object.assign(this, emailAnalytics);

		this.topStats = this.topStats ? new EmailAnalyticsTopStats(this.topStats) : this.topStats;
		this.masterStats = this.masterStats ? this.masterStats.map(m => new EmailAnalyticsMasterRow(m)) : this.masterStats;
		this.detailStats = this.detailStats ? this.detailStats.map(d => new EmailAnalyticsDetailRow(d)) : this.detailStats;
	}
}

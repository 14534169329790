export class ExpectedProcurementBasedOnNewBusiness {

	public directSaleTermValue: { month: Date; contractCount: number; termValue: number }[];
	public legacyBrokerTermValue: { month: Date; contractCount: number; termValue: number }[];
	public newBrokerTermValue: { month: Date; contractCount: number; termValue: number }[];
	public totalTermValue: { month: Date; contractCount: number; termValue: number }[];

	constructor(expectedProcurementBasedOnNewBusiness: any) {
		Object.assign(this, expectedProcurementBasedOnNewBusiness);
	}
}

export class ViewAgentCommissionPayPlanOption {
	public id: string;
	public companyId: string;
	public agentId: string;
	public agentName: string;
	public commissionPayPlanTypeId: string;
	public commissionPayPlanTypeName: string;
	public lag: number;
	public isOverride: boolean;
	public rate: number;
	public isActive: boolean;

	// internal
	public overriddenCommissionPayPlanTypes: { [key: string]: number };

	constructor(viewAgentCommissionPayPlanOption?: ViewAgentCommissionPayPlanOption) {
		Object.assign(this, viewAgentCommissionPayPlanOption);
	}
}

<form id="noteForm" [formGroup]="formGroup" *ngIf="!loading">
	<div class="row">
		<div class="col-md-12">
			<div class="form-element-container">
				<label class="title" for="note">Note</label>
				<textarea class="form-control" name="note" id="note" maxlength="1000" formControlName="note"></textarea>
				<span class="form-text text-danger">
					Please <b>do not</b> enter margin info in this box. Margin info is according to the most updated RFP excel and will be confirmed when you
					submit bids.
				</span>
			</div>
		</div>
	</div>
	<button type="button" class="btn btn-primary" (click)="submit()" [disabled]="submitDisabled">{{ submitText }}</button>
</form>

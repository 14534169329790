import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { Contract } from 'src/app/shared/models';

@Component({
	selector: 'pk-broker-contract-selector-modal',
	templateUrl: './contract-selector-modal.component.html',
	styleUrls: ['./contract-selector-modal.component.scss'],
})
export class ContractSelectorModalComponent {

	// input
	public initialValue: string;
	public initialCriteria: any;

	public onSubmit: Subject<Contract> = new Subject();

	constructor(private activeModal: BsModalRef) { }

	public hide(): void {
		this.activeModal.hide();
	}

	public selectContract(contract: Contract): void {
		this.onSubmit.next(contract);
		this.activeModal.hide();
	}
}

import * as moment from 'moment-timezone';

export class ISO {

	public id: number;
	public name: string;

	public addDate: Date;
	public modifiedDate: Date;
	public userId: string;
	public modifiedUserId: string;

	constructor(iso?: any) {
		Object.assign(this, iso);
		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
		this.modifiedDate = this.modifiedDate ? new Date(moment(this.modifiedDate).format('MMM DD, YYYY hh:mm:ss a')) : this.modifiedDate;
	}
}

<pk-broker-page-warnings [warnings]="warnings"></pk-broker-page-warnings>
<form [formGroup]="formGroup" *ngIf="!loading">
	<div class="row">
		<div class="col-md-12">
			<div class="form-element-container">
				<label class="title required" for="supplierName">Supplier</label>
				<input class="form-control" id="supplierName" formControlName="supplierName" readonly />
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<div class="form-element-container" [class.invalid]="err[noteKey]">
				<label class="title required" for="note">{{ noteKeyDisplay }}</label>
				<textarea class="form-control" id="note" [formControlName]="noteKey" maxlength="1000"></textarea>
				<p class="mb-0 invalid-feedback" *ngIf="err[noteKey]?.required">{{ noteKeyDisplay }} is required</p>
			</div>
		</div>
	</div>
</form>

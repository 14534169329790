import { Pipe, PipeTransform } from '@angular/core';

import { EducationTopic } from '../models';

@Pipe({
	name: 'filterEducationCategoryTopics'
})
export class FilterEducationCategoryTopicsPipe implements PipeTransform {
	transform(topics: EducationTopic[], hasAgent: boolean): EducationTopic[] {
		return topics ? topics.filter(t => !t.education || (!!t.education.agentId === hasAgent)) : topics;
	}
}

import { Pipe, PipeTransform } from '@angular/core';

import { ReportParentCommissionBrokerPaid } from '../models';
import { FilterReportCommissionPaidPipe } from './filter-report-commission-paid.pipe';

@Pipe({
	name: 'filterReportCommissionPaidParent'
})
export class FilterReportCommissionPaidParentPipe implements PipeTransform {
	constructor(private commissionFilter: FilterReportCommissionPaidPipe) { }

	transform(parent: ReportParentCommissionBrokerPaid, onlyNegativeAmounts: boolean): boolean {
		return parent
			? parent.agents
				.some(a => !onlyNegativeAmounts || !!this.commissionFilter.transform(a.commissionsAggregate, onlyNegativeAmounts)?.length)
			: false;
	}
}

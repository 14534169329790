export class CommissionUpfrontRate {

	public id: number;
	public agentId: string;
	public commissionPayPlanId: number;
	public minAmount: number;
	public maxAmount: number;
	public rate: number;

	constructor(commissionUpfrontRate: CommissionUpfrontRate) {
		Object.assign(this, commissionUpfrontRate);
	}
}

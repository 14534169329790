import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import * as _ from 'lodash';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { HelperService } from '../../helper.service';
import { Contract, Country } from '../../models';
import { AbstractPageForm } from '../../pages/page-form.abstract';
import { brokerReferralValidator } from '../../validators/broker-referral-validator';

@Component({
	selector: 'pk-broker-top-banner-alert-learn-more-modal',
	templateUrl: './top-banner-alert-learn-more-modal.component.html',
	styleUrls: ['./top-banner-alert-learn-more-modal.component.scss'],
})
export class TopBannerAlertLearnMoreModalComponent extends AbstractPageForm {

	// inputs
	public type: number; // this.CONSTANTS.bannerAlertTypes (convert to enum?)
	public contract: Contract;

	public showConfirmationMessage = false;

	public countries: Country[];
	public phoneMask = this.CONSTANTS.masks.phoneUS;

	constructor(
		private fb: FormBuilder,
		private graphqlService: GraphqlService,
		private helperService: HelperService,
	) {
		super();
	}

	public async loadPageData(): Promise<void> {
		const result = await this.graphqlService.getTopBannerAlertLearnMoreModalData();

		this.countries = result.data.countries.message.map(c => new Country(c));
		const usIndex = result.data.countries.message.findIndex(c => c.id === this.CONSTANTS.countries.unitedStates);
		const US = result.data.countries.message[usIndex];
		this.countries = [US].concat(result.data.countries.message.filter(c => c.id !== this.CONSTANTS.countries.unitedStates));
	}

	get headerImageSrc(): string {
		switch (this.type) {
			case this.CONSTANTS.bannerAlertTypes.brokerReferral:
				return 'https://static.powerkiosk.com/test/pku-298/assets/attachments/broker-referral-modal.jpg';
			case this.CONSTANTS.bannerAlertTypes.annualMembership:
				return 'https://static.powerkiosk.com/test/pku-25/assets/attachments/annual-membership-modal.jpg';
			case this.CONSTANTS.bannerAlertTypes.leadLink:
				return 'https://static.powerkiosk.com/test/pku-25/assets/attachments/leadlink-modal.jpg';
			case this.CONSTANTS.bannerAlertTypes.customerReferral:
				return 'https://static.powerkiosk.com/test/pku-25/assets/attachments/customer-referral-modal.jpg';
		}

		return '';
	}

	get confirmationMessage(): string {
		switch (this.type) {
			case this.CONSTANTS.bannerAlertTypes.brokerReferral:
				return 'We will reach out to your colleague and send your reward if he/she signs up as a qualified broker.';
			case this.CONSTANTS.bannerAlertTypes.annualMembership:
				return 'One of our experts will be reaching out to you within the week.';
			case this.CONSTANTS.bannerAlertTypes.leadLink:
				return 'One of our experts will be reaching out to you within the week.';
			case this.CONSTANTS.bannerAlertTypes.customerReferral:
				return `We will send an email introducing ourselves to your friend${this.form['referrals'].length > 1 ? 's' : ''}.`;
		}

		return '';
	}

	public getForm() {
		switch (this.type) {
			case this.CONSTANTS.bannerAlertTypes.brokerReferral:
				this.setSubmitText('Submit', 'Submitting...');
				break;
			case this.CONSTANTS.bannerAlertTypes.annualMembership:
				this.setSubmitText(`I'm Interested`, 'Submitting...');
				break;
			case this.CONSTANTS.bannerAlertTypes.leadLink:
				this.setSubmitText(`I'm Interested`, 'Submitting...');
				break;
			case this.CONSTANTS.bannerAlertTypes.customerReferral:
				this.setSubmitText(`Send Email`, 'Sending...');
				break;
		}

		switch (this.type) {
			case this.CONSTANTS.bannerAlertTypes.brokerReferral:
				return this.fb.group({
					// set arbitrary max lengths just so they cannot kill our system
					name: [null, [Validators.maxLength(500)]],
					email: [null, [Validators.maxLength(500)]],
					phoneCountryId: this.CONSTANTS.countries.unitedStates,
					phone: null,
				}, { validators: [brokerReferralValidator] });
			case this.CONSTANTS.bannerAlertTypes.customerReferral:
				return this.fb.group({
					referrals: this.fb.array([this.getCustomerReferralFormGroup()], [Validators.required]),
				});
			default:
				return this.fb.group({});
		}
	}

	public async onFormLoaded(): Promise<void> {
		this.setupPhoneMaskAndValidators();
	}

	private setupPhoneMaskAndValidators(): void {
		if (this.CONSTANTS.bannerAlertTypes.brokerReferral) {
			this.form['phone'].setValidators([
				Validators.pattern(HelperService.phoneValidatorMap[this.form['phoneCountryId'].value] || this.CONSTANTS.validators.phoneUS),
			]);

			this.form['phoneCountryId'].valueChanges.subscribe(value => {
				this.form['phone'].setValidators([Validators.required, Validators.pattern(HelperService.phoneValidatorMap[value])]);
				this.phoneMask = HelperService.phoneMasksMap[value] || this.CONSTANTS.masks.phoneUS;
			});
		}
	}

	private getCustomerReferralFormGroup() {
		return this.fb.group({
			// set arbitrary max lengths just so they cannot kill our system
			name: [null, [Validators.required, Validators.maxLength(500)]],
			email: [null, [Validators.required, Validators.maxLength(500)]],
			contractId: this.contract.contractId,
		});
	}

	public addCustomerReferral(): void {
		this.form['referrals'].control.push(this.getCustomerReferralFormGroup());
	}

	public async onFormSubmitted(): Promise<void> {
		switch (this.type) {
			case this.CONSTANTS.bannerAlertTypes.brokerReferral:
				try {
					await this.graphqlService.sendBrokerReferralEmail(_.omit(this.formGroup.value, 'phoneCountryId'));
				} catch (e) {
					const message = this.helperService.sanitizeApolloError(e.message);
					if (message.includes('already')) {
						this.warnings = [message];
					} else {
						this.warnings = [`There was a problem submitting the referral. We have been `
							+ `notified and are working to fix the issue. Please check back again in 30 minutes.`];
					}
				}
				break;
			case this.CONSTANTS.bannerAlertTypes.annualMembership:
				await this.graphqlService.sendAnnualMembershipEmail();
				break;
			case this.CONSTANTS.bannerAlertTypes.leadLink:
				await this.graphqlService.sendLeadLinkEmail();
				break;
			case this.CONSTANTS.bannerAlertTypes.customerReferral:
				await this.graphqlService.sendCustomerReferralEmail(this.formGroup.value);
				break;
		}

		if (!this.warnings?.length) {
			this.showConfirmationMessage = true;
		}
	}
}

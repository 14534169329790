

export class PostProcessingCommissionAuditStatusType {
	public id: number;
	public name: string;

	constructor(postProcessingCommissionAuditStatusType: PostProcessingCommissionAuditStatusType) {
		Object.assign(this, postProcessingCommissionAuditStatusType);
	}
}

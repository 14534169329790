import { TinyMceTemplate } from './tinymce-template';

export class TinyMceCategory {
	public id: number | string;
	public title: string;

	public items: TinyMceTemplate[];

	constructor(tinyMceCategory?: Partial<TinyMceCategory>) {
		Object.assign(this, tinyMceCategory);

		this.id = this.id ? String(this.id) : this.id;
		this.items = this.items ? this.items.map(i => new TinyMceTemplate(i)) : this.items;
	}
}
